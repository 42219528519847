import React, { Component } from 'react'
import { flow } from 'lodash'
import { Breadcrumbs, Button, Card, Typography } from '@material-ui/core'

import ConversationForm from '../../../conversations/Form'
import ConversationFormSynth from '../../../conversations/Edit/FormSynth'
import ManageGuests from '../../../conversations/Edit/ManageGuests'
import ConversationNotificationsEdit from '../../../conversation-notifications/Edit'
import PremiumEventForm from '../../../conversations/Edit/PremiumEventForm'
import withTranslation from '../../../hocs/withTranslation'
import AppTabs from '../../../shared/AppTabs'
import { inject, observer } from 'mobx-react'
import { IStore } from '../../../../store/models'
import { withRouter } from 'react-router'
import PageLoadingAnimation from '../../../shared/PageLoadingAnimation'
import SummaryContainer from '../partials/SummaryContainer'
import AdminMetaTags from '../../../shared/AdminMetaTags'
import { Link } from 'react-router-dom'
import Sponsors from './Sponsors'
import HostTools from '../../../conversations/Edit/HostTools'

export class EditConversationPage extends Component<FIXME, FIXME> {
  componentDidUpdate(prevProps: FIXME) {
    const { conversation, changeLanguage } = this.props

    if (conversation && conversation !== prevProps.conversation && conversation.locale) {
      changeLanguage(conversation.locale)
    }
  }

  render() {
    const {
      topic,
      conversation,
      params,
      showPremiumEventTab,
      t,
      match: {
        params: { tab }
      },
      history
    } = this.props
    // if (loading || isUndefined(conversation) || isUndefined(theme)) {
    // return <PageLoadingAnimation />
    // }

    if (!topic || !conversation) {
      return <PageLoadingAnimation />
    }
    if (!conversation.canEdit) return null

    const editPage = `/admin/conversations/${conversation.id}/edit`
    const tab_names = ['', 'guests', 'tab_names', ...(showPremiumEventTab ? ['sponsors', 'premium', 'emails'] : [])]
    let activeTab = tab_names.indexOf(tab)
    activeTab = activeTab === -1 ? 0 : activeTab

    const handleTabChange = (tabIndex: number) => {
      history.push(`${editPage}/t/${tab_names[tabIndex]}`)
    }

    return (
      <>
        <AdminMetaTags title='Conversations' pageType='Edit' slug={conversation?.slug} />

        <Breadcrumbs aria-label='breadcrumb'>
          <Link color='primary' to='/admin/conversations'>
            Conversations
          </Link>
          <Typography color='textPrimary'>Edit Conversation</Typography>
        </Breadcrumbs>
        <SummaryContainer
          title={conversation.title}
          topRight={
            <Button
              rel='noopener'
              href={`/${conversation?.slug}/conversations/${conversation?.id}`}
              target='_blank'
              variant='contained'
              startIcon={<i className='far fa-external-link-square'></i>}
            >
              View Saved Page
            </Button>
          }
        >
          <AppTabs
            index={activeTab}
            onChange={handleTabChange}
            tabs={[
              {
                label: t('conversation.generalDetails'),
                content: (
                  <>
                    <h1 className='page-header'>{t('conversation.generalDetails')}</h1>
                    <Card className='no-padding xtra-huge-bottom no-shadow'>
                      {conversation.synthetic ? (
                        <ConversationFormSynth conversation={conversation} isHost={conversation.isHost} page='edit' topic={topic} />
                      ) : (
                        <ConversationForm conversation={conversation} isHost={conversation.isHost} page='edit' />
                      )}
                    </Card>
                  </>
                )
              },
              ...(conversation.synthetic
                ? []
                : [
                    {
                      label: t('conversation.manageGuests'),
                      content: (
                        <>
                          <ManageGuests params={params} conversation={conversation} />
                        </>
                      )
                    }
                  ]),
              {
                label: 'Host Tools',
                content: (
                  <>
                    <h1 className='page-header'>Host Tools</h1>
                    <HostTools params={params} conversation={conversation} />
                  </>
                )
              },
              ...(showPremiumEventTab
                ? [
                    {
                      label: t('conversation.sponsors'),
                      content: (
                        <>
                          <h1 className='page-header reg-bottom'>{t('conversation.sponsors')}</h1>
                          <Sponsors conversation={conversation} />
                        </>
                      )
                    },
                    {
                      label: 'Premium Features',
                      content: (
                        <>
                          <h1 className='page-header reg-bottom'>Premium Features</h1>
                          <PremiumEventForm conversation={conversation} />
                        </>
                      )
                    },
                    {
                      label: t('conversation.customEmails'),
                      content: (
                        <>
                          <h1 className='page-header reg-bottom'>{t('conversation.customEmails')}</h1>
                          <ConversationNotificationsEdit conversation={conversation} />
                        </>
                      )
                    }
                  ]
                : [])
            ]}
            contentStyle={{ height: 'calc(100vh - 17em)', overflowY: 'auto' }}
          />
        </SummaryContainer>
      </>
    )
  }
}

export default flow(
  withRouter,
  withTranslation,
  observer,
  inject<FIXME, FIXME>(
    (
      {
        mst: {
          auth: { user, loaded },
          topics,
          conversations,
          dialogs: { showDialog }
        }
      }: { mst: IStore },
      {
        match: {
          params: { conversationId: conversationIdString }
        },
        history
      }
    ) => {
      const id = parseInt(conversationIdString, 10)

      const conversation = conversations.list.find((d) => d.id === id)
      !conversation && conversations.shouldLoad(id, () => conversations.loadById(id))

      const topic = conversation && topics.list.find((d) => d.slug === conversation.slug)
      conversation && !topic && topics.shouldLoad(conversation.slug, () => topics.loadBySlug(conversation.slug))

      if (loaded && conversation && !conversation?.canEdit) {
        history.push('/login')
      }

      return {
        showPremiumEventTab: conversation?.isPremium && user?.isPartnerAdmin(),
        user,
        conversation,
        topic,
        showDialog
      }
    }
  )
)(EditConversationPage)
