import React from 'react'
import { createMuiTheme, ThemeOptions, ThemeProvider } from '@material-ui/core/styles'
import { defaulTypography, cocoFontFamily as fontFamily } from './partials'
import palette from './palette'
import { merge } from 'lodash'

export const cocoTheme: ThemeOptions = {
  palette: {
    primary: {
      light: palette.white,
      main: palette.white,
      dark: palette.white,
      contrastText: palette.cocoGreen
    },
    secondary: {
      light: palette.cocoGreen,
      main: palette.cocoGreen,
      dark: palette.cocoGreen,
      contrastText: palette.white
    }
  },
  overrides: {
    MuiLink: {
      root: {
        color: palette.cocoLightBlue
      }
    },
    MuiTypography: {
      root: {
        fontFamily: '"OptimisticText", Arial, sans-serif'
      },
      h1: {
        fontFamily
      },
      h2: {
        fontFamily
      },
      h3: {
        fontFamily
      },
      h4: {
        fontFamily
      },
      h5: {
        fontFamily
      },
      h6: {
        fontFamily
      },
      subtitle1: {
        fontFamily
      },
      subtitle2: {
        fontFamily,
        fontWeight: 500,
        fontSize: '1rem',
        lineHeight: '1rem'
      },
      body1: {
        fontFamily
      },
      body2: {
        fontFamily
      },
      button: {
        fontFamily
      },
      caption: {
        fontFamily
      },
      overline: {
        fontFamily
      }
    },
    MuiButton: {
      textSizeSmall: {
        fontSize: '14px',
        lineHeight: '22px'
      },
      textSizeLarge: {
        fontSize: '16px',
        lineHeight: '26px'
      },
      root: {
        color: palette.teal,
        backgroundColor: palette.grey50
      },
      text: {
        color: palette.grey800,
        backgroundColor: palette.grey50
      },
      outlined: {
        color: `${palette.teal} !important`,
        boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px'
      },
      contained: {
        boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
        color: palette.grey800,
        '&.coco-green-text': {
          color: palette.grey800,
          backgroundColor: palette.grey50
        },
        // color: palette.teal,
        backgroundColor: palette.grey50,
        borderRadius: 2,
        // boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
        '&:hover': {
          backgroundColor: 'transparent'
        },
        '&$disabled': {
          color: palette.grey,
          backgroundColor: palette.lightestgrey
        },
        '&.link': {
          color: palette.white,
          backgroundColor: palette.blue500,
          '&:hover': {
            backgroundColor: palette.blue700
          },
          '&:focus': {
            backgroundColor: palette.blue900
          }
        }
      },
      label: {
        fontFamily,
        textTransform: 'none',
        fontWeight: 500,
        letterSpacing: 0
      }
    },
    MuiDialogTitle: {
      root: {
        backgroundColor: palette.cocoGreen,
        color: palette.white,
        textAlign: 'center',
        fontSize: '1.4em',
        fontWeight: 700,
        borderBottom: '1px solid rgb(224, 224, 224)'
      }
    },

    MuiCircularProgress: {
      colorPrimary: {
        color: palette.cocoLightBlue
      }
    },

    MuiStepIcon: {
      active: {
        fill: palette.cocoGreen
      },
      completed: {
        fill: palette.cocoGreen
      },
      text: {
        fill: palette.white
      }
    }
  }
}

export const CocoTheme = createMuiTheme(merge({}, { defaulTypography }, cocoTheme))

export const CocoThemeProvider = ({ children }) => <>{window.coco ? <ThemeProvider theme={CocoTheme}>{children}</ThemeProvider> : children}</>
