import React, { useEffect } from 'react'
import ResponsiveLineCard from './ResponsiveLineCard'
import palette from '../../../../themes/palette'
import { flow } from 'lodash'
import { inject, observer } from 'mobx-react'
import { IStore } from '../../../../store/models'
import { IGraphData } from '../../../../store/models/GraphData'
import { getSnapshot } from 'mobx-state-tree'
import DateFilterAction from '../partials/DateFilterAction'

interface Props {
  selectedPartnerId?: number
  graphData: IGraphData[]
  totalStats: any
  inlineStats: any
  scopedBy?: string
  loading?: boolean
  loadAnalyticsData(val?: any, opt?: any)
  setScopedBy(val: string)
  setStartsAt(val: string)
  setEndsAt(val: string)
}

const ConversationsOverTime = ({ selectedPartnerId, graphData, totalStats, inlineStats, loadAnalyticsData, setScopedBy, scopedBy, setStartsAt, setEndsAt, loading }: Props) => {
  useEffect(() => {
    loadAnalyticsData()
  }, [loadAnalyticsData])

  useEffect(() => {
    loadAnalyticsData({}, { reload: true })
  }, [loadAnalyticsData, selectedPartnerId])

  const handleClose = (menu_name: string) => (event) => {
    setScopedBy(menu_name)
    loadAnalyticsData(undefined, { reload: true })
  }

  const handleCustomDate = () => {
    setScopedBy('date_range')
    loadAnalyticsData(undefined, { reload: true })
  }
  return (
    <>
      {graphData && (
        <ResponsiveLineCard
          title='Conversations Over Time'
          graphData={[{ id: 'Conversations', data: graphData }]}
          totalStats={totalStats}
          inLineStats={inlineStats}
          chartColor={palette.lightDarkOrange}
          yLabel='Conversations'
          headerAction={<DateFilterAction {...{ scopedBy, handleClose, setStartsAt, setEndsAt, handleCustomDate }} />}
          loading={loading}
          id={'ic-admin-line-chart-conversations'}
        />
      )}
    </>
  )
}

export default flow(
  observer,
  inject<FIXME, FIXME>(
    ({
      mst: {
        analytics: { conversationsLine },
        partners: { selectedPartnerId }
      }
    }: {
      mst: IStore
    }) => {
      const { graphData, totalStats, inlineStats, loadAnalyticsData, setScopedBy, scopedBy, setStartsAt, setEndsAt, getDataName, loadingIds } = conversationsLine
      return {
        graphData: getSnapshot(graphData),
        inlineStats,
        totalStats,
        loadAnalyticsData,
        setScopedBy,
        scopedBy,
        setStartsAt,
        setEndsAt,
        selectedPartnerId,
        loading: loadingIds.includes(getDataName())
      }
    }
  )
)(ConversationsOverTime)
