import { types, Instance, getParent } from 'mobx-state-tree'
import ConversationBase from './ConversationBase'

import { Alertable } from '../helpers/Alertable'
import { Dialogable } from '../helpers/Dialogable'
import { LoadableStatus } from '../helpers/LoadableStatus'
import ConversationNotificationsApi from '../../../api/ConversationNotificationsApi'
import TestEmailsApi from '../../../api/TestEmailsApi'
import Editable from '../helpers/Editable'
import { camelify, snakify } from '../../../decorators/textTools'
import i18next from 'i18next'

const ConversationNotification = types
  .compose(
    'ConversationNotification',
    Editable,
    Alertable,
    types.model({
      id: types.identifierNumber,
      name: types.maybeNull(types.string),
      subject: types.maybeNull(types.string),
      label: types.maybeNull(types.string),
      content: types.maybeNull(types.string),
      active: types.maybeNull(types.boolean),
      description: types.maybeNull(types.string),
      type: types.maybeNull(types.string),
      notificationId: types.maybeNull(types.number),
      conversationId: types.maybe(types.number),
      sendDate: types.maybeNull(types.string)
    })
  )
  .actions((self) => ({
    update: (data, { dirty = false, alert = i18next.t('conversationNotifications.emailUpdated') } = {}) => {
      return ConversationNotificationsApi.update({ id: self.id, ...snakify(data) }).then(({ response: { ok }, json }) => {
        if (ok) {
          const newData = camelify(json)
          self.updateMe(newData, { dirty })
          alert && self.showAlert(alert as string)
          return { data: newData }
        }
      })
    },
    sendTestEmail: (data: { testSubject: string; testContent: string }) => {
      const parent_id = self.conversationId ? { conversation_id: self.conversationId } : { topic_id: (getParent(self, 2) as any).topicId }

      return TestEmailsApi.create({ name: self.name, ...data, ...parent_id }).then(({ response: { ok }, json }) => {
        if (ok) {
          self.showAlert(i18next.t('conversationNotifications.testEmailSuccess'))
        } else {
          self.showAlert(i18next.t('conversationNotifications.testEmailFail'))
        }
      })
    }
  }))

const ConversationNotifications = types
  .compose(
    'ConversationNotifications',
    ConversationBase,
    Dialogable,
    types.model({
      notifications: types.optional(types.array(ConversationNotification), [])
    })
  )
  .actions((self) => ({
    setNotifications(val: any) {
      self.notifications.replace(val)
    }
  }))
  .actions((self) => ({
    loadConversationNotifications: () => {
      const listName = 'notifications'
      if (self.isLoading(listName)) return

      self.startLoading(listName)
      return ConversationNotificationsApi.getAll(self.id).then(({ response: { ok }, json }) => {
        if (ok) {
          self.setNotifications(json.notifications)
        }
        self.stopLoading(listName)
      })
    },
    createConversationNotification: (newConversationNotification, conversationId) => {
      return ConversationNotificationsApi.create(newConversationNotification, conversationId).then(({ response: { ok }, json }) => {
        if (ok) {
          const newData = camelify(json)
          self.showAlert(i18next.t('conversationNotifications.emailUpdated'))
          return { data: newData }
        }
      })
    }
  }))

export interface IConversationNotification extends Instance<typeof ConversationNotification> {}
export interface IConversationNotifications extends Instance<typeof ConversationNotifications> {}
export default ConversationNotifications
