import React, { useEffect, useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import withTranslation from '../hocs/withTranslation'
import { flow } from 'lodash'
import { withRouter } from 'react-router'

const CookieFooter = ({ t, location }: FIXME) => {
  const [open, setOpen] = useState(false)
  const consentExists = () => {
    function getCookie(name) {
      const value = `; ${window.document.cookie}`
      const parts = value.split(`; ${name}=`)
      if (parts.length === 2) return parts[parts.length - 1].split(';').shift()
    }

    return !!getCookie('cd_cc')
  }

  useEffect(() => {
    if (!consentExists()) {
      setOpen(true)
    }
  }, [])

  const FooterWrapper = () => {
    return consentExists() || open === false ? null : <Footer />
  }

  const inAdmin = !!location.pathname.match(/^\/admin/)

  const Footer = () => (
    <>
      {!inAdmin && (
        <section className='pane darkgrey-pane fixed-bottom thin-bottom-padding max-width relative' style={{ zIndex: 9999 }}>
          <div style={{ paddingRight: '5rem' }}>
            <div className='absolute no-right thin-right-padding' style={{ marginRight: '5rem' }}>
              <IconButton className='fal fa-times opacity-8 white-text' onClick={() => setOpen(false)} />
            </div>
            <div className='row reg-row-xs thin-top'>
              <div className='col-xs-12 center-xs'>
                <div className='box'>
                  <p className='header-14' dangerouslySetInnerHTML={{ __html: t('cookie.cookieFooter') }} />
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  )

  return <FooterWrapper />
}

export default flow(withRouter, withTranslation)(CookieFooter)
