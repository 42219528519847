import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import { flow } from 'lodash'

import { observer } from 'mobx-react'

import { useForm } from 'react-hook-form'
import { RadioGroupControl } from '../utils'

import { Link, Paper, Typography } from '@material-ui/core'
import { useParamTopic } from '../Providers'
import { Prompt } from 'react-router'
import { UNSAVED_FORM_WARNING } from '../utils/constants'
import InviteHostTestSentDialog from './partials/InviteHostTestSentDialog'

const HostingEdit = () => {
  const { topic } = useParamTopic()
  const { handleSubmit, formState, control, reset } = useForm({ mode: 'onBlur' })
  const { isDirty, isSubmitting } = formState
  const [showSentDialog, setshowSentDialog] = useState(false)
  if (!topic) {
    return <></>
  }

  const handleSave = handleSubmit((data: any, e) => {
    topic.updateTopic(data).then(() => {
      reset(data)
    })
  })
  const handleTestEmail = (e) => {
    topic.inviteHostTest().then(() => setshowSentDialog(true))
  }
  return (
    <div>
      <InviteHostTestSentDialog
        open={showSentDialog}
        dialog={{
          closeDialog: () => {
            setshowSentDialog(false)
          }
        }}
        topic={topic}
      />
      <form onSubmit={handleSave}>
        <Typography variant='body1' className='fat-bottom'>
          Manage access to create and host a conversation.
        </Typography>
        <Typography variant='subtitle2' className='fat-bottom'>
          Send Invitations to Create and Host a Conversation
        </Typography>
        <Typography variant='body1' className='fat-bottom'>
          Send invitations by email to the people you’d like to host your events. Recipients can access a direct link to create a new conversation for this topic alongside other
          helpful resources. Looking to invite a large group at once? Visit our <Link>help page</Link> for more info.
        </Typography>
        <div className='fat-bottom'>
          <Button variant='contained' className='reg-right' color='primary' onClick={() => topic.showDialog('topicInvitHost', { topic })}>
            Send Invitation to Host
          </Button>
          <Button variant='outlined' onClick={handleTestEmail}>
            Email Me a Test Invitation
          </Button>
        </div>
        <Paper style={{ padding: '1rem' }}>
          <Typography variant='body1'>Who can Create and Host a Conversation?</Typography>

          <Typography variant='caption' className='fat-bottom'>
            Choose one
          </Typography>
          <RadioGroupControl
            name='privateHosting'
            control={control}
            className='fat-top'
            defaultValue={topic.privateHosting}
            options={[
              {
                label: 'Anyone',
                value: false,
                className: 'reg-bottom'
              },
              {
                label: 'Only Administrators and Invited Hosts',
                value: true,
                helperText:
                  'Recommended for admins who want full control over who can create and host conversations: admins must create conversations themselves or invite other hosts to do so. '
              }
            ]}
          />
        </Paper>
        <Button disabled={!isDirty || isSubmitting} type='submit' color='secondary' variant='contained' className='fat-top'>
          Save Changes
        </Button>
        <Prompt when={isDirty || isSubmitting} message={UNSAVED_FORM_WARNING} />
      </form>
    </div>
  )
}

export default flow(observer)(HostingEdit)
