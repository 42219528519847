import TopicLmsCoursesApi from '../../../api/topics/LmsCoursesApi'
import LmsCoursesApi from '../../../api/LmsCoursesApi'
import LmsItemsApi from '../../../api/LmsItemsApi'
import { types, Instance, isAlive, getParent, getRoot } from 'mobx-state-tree'
import { removeNullStringValue } from '../utils'
import TopicBase from './TopicBase'
import { camelify, snakify } from '../../../decorators/textTools'
import { Alertable } from '../helpers/Alertable'
import Editable from '../helpers/Editable'
import Document from '../base/Document'
import { ImageUrl } from '../base'
import UserLmsItemsApi from '../../../api/UserLmsItemsApi'

const TopicLmsItem = types
  .compose(
    'TopicLmsItem',
    Alertable,
    Editable,
    types.model({
      id: types.identifierNumber,

      name: types.maybeNull(types.string),
      description: types.maybeNull(types.string),
      status: types.maybeNull(types.string),
      sortIndex: types.maybeNull(types.number),
      file: types.maybe(ImageUrl)
    })
  )
  .actions((self) => ({
    updateLmsItem: (data) => {
      return LmsItemsApi.update(snakify({ id: self.id, ...data })).then(({ response: { ok, status, statusText }, json }) => {
        if (ok) {
          self.updateMe(camelify(json))
          self.showAlert('Video successfully updated')
        }
      })
    },
    deleteLmsItem: () => {
      return LmsItemsApi.destroy(self.id).then(({ response: { ok, status, statusText }, json }) => {
        if (ok) {
          self.showAlert('Video successfully removed')
        }
      })
    },
    createUserLmsItem: (options?: { status?: string; progress?: number }) => {
      const {
        auth: { user }
      } = getRoot(self)
      return UserLmsItemsApi.create({ lmsItemId: self.id, userId: user.id, ...options }).then(({ response: { ok }, json }) => {
        if (ok) {
          // self.setUserActions(json)
          user.loadUserLmsItems({ reload: true })
        }
      })
    }
  }))

const TopicLmsCourse = types
  .compose(
    'TopicLmsCourse',
    Alertable,
    Editable,
    types.model({
      id: types.identifierNumber,

      name: types.maybeNull(types.string),
      description: types.maybeNull(types.string),
      status: types.maybeNull(types.string),
      lmsItems: types.optional(types.array(TopicLmsItem), [])
    })
  )
  .actions((self) => ({
    addToLmsItems: (data) => {
      self.lmsItems.push(data)
    }
  }))
  .actions((self) => ({
    updateLmsCourse: (data) => {
      return LmsCoursesApi.update(snakify({ id: self.id, ...data })).then(({ response: { ok, status, statusText }, json }) => {
        if (ok) {
          self.updateMe(camelify(json))
          self.showAlert('LmsCourse successfully updated')
        }
      })
    },
    createLmsItem: (data) => {
      return LmsItemsApi.create(snakify({ ...data, lms_course_id: self.id })).then(({ response: { ok, status, statusText }, json }) => {
        if (ok) {
          self.addToLmsItems(camelify(json))
          self.showAlert('Lms Item successfully created')
        }
      })
    }
  }))

export const TopicLmsCourses = types
  .compose(
    'TopicLmsCourses',
    TopicBase,
    types.model({
      allowVideos: types.maybeNull(types.boolean),
      topicLmsCourses: types.optional(types.array(TopicLmsCourse), []),
      topicLmsCourse: types.maybeNull(TopicLmsCourse)
    })
  )
  .actions((self) => ({
    setTopicLmsCourses(val: any[]) {
      val.forEach((d) => removeNullStringValue(d))
      self.topicLmsCourses.replace(val)
      self.topicLmsCourse = val[0]
    },
    addToLmsCourses: (data) => {
      self.topicLmsCourses.push(data)
    },
    removeFromLmsCourses: (id) => {
      self.topicLmsCourses.replace(self.topicLmsCourses.filter((ca) => ca.id !== id))
    }
  }))
  .actions((self) => ({
    loadTopicLmsCourses: () => {
      if (self.isLoading('topicLmsCourses')) {
        return
      }
      self.startLoading('topicLmsCourses')
      return TopicLmsCoursesApi.getAll(self.id).then((res) => {
        const { ok } = res.response
        if (!isAlive(self)) return
        if (ok) {
          self.setTopicLmsCourses(res.json)
        }
        self.stopLoading('topicLmsCourses')
      })
    },
    createLmsCourse: (data) => {
      return LmsCoursesApi.create(snakify({ ...data, topicId: self.id })).then(({ response: { ok, status, statusText }, json }) => {
        if (ok) {
          self.addToLmsCourses(camelify(json))
          self.showAlert('LmsCourse successfully created')
        }
      })
    },
    deleteLmsCourse: (id: number) => {
      return LmsCoursesApi.destroy({ id }).then(({ response: { ok, status, statusText }, json }) => {
        if (ok) {
          self.removeFromLmsCourses(id)
          self.showAlert('LmsCourse successfully removed')
        }
      })
    }
  }))

export interface ITopicLmsItem extends Instance<typeof TopicLmsItem> {}
export interface ITopicLmsCourse extends Instance<typeof TopicLmsCourse> {}
export interface ITopicLmsCourses extends Instance<typeof TopicLmsCourses> {}
export default TopicLmsCourses
