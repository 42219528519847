import React, { useState } from 'react'
import { flow } from 'lodash'
import { observer } from 'mobx-react'
import { Button, createStyles, Grid, Icon, IconButton, makeStyles, Paper, Theme, Typography } from '@material-ui/core'

import { ITopic, ITopicAction } from '../../store'

import ActionForm from './Form'

interface Props {
  topic: ITopic
  action?: ITopicAction
}

const ActionsEdit = ({ topic, action }: Props) => {
  const [showForm, setshowForm] = useState(false)
  const buildLink = (link, text) => {
    if (link && !text) {
      return (
        <a href={link} className='light-grey-text underline' target='_blank' rel='noopener noreferrer'>
          ({link})
        </a>
      )
    } else if (link && text) {
      return (
        <a href={link} className='light-grey-text underline' target='_blank' rel='noopener noreferrer'>
          ({text})
        </a>
      )
    } else {
      return null
    }
  }
  const handleEdit = () => {
    setshowForm(true)
  }

  const handleClose = () => {
    setshowForm(false)
  }

  const Action = () => {
    const classes = useStyles()
    if (!action) {
      return <></>
    }
    const link = buildLink(action.resourceLink, action.resourceText)

    return (
      <Grid container alignItems='center'>
        <Grid item xs={11}>
          <Paper variant='outlined' className={classes.root}>
            <Typography variant='subtitle1' className={classes.title}>
              {action.title}
            </Typography>
            <Typography variant='body2' className={classes.description}>
              {action.description}
            </Typography>
            {action.resourceText && (
              <Button size='large' variant='contained' classes={{ root: classes.actionButton }} href={action.resourceLink || '#'} disabled={!action.resourceLink} target='blank'>
                {action.resourceText}
              </Button>
            )}
          </Paper>
        </Grid>
        <Grid item xs={1}>
          <IconButton classes={{ root: classes.iconButton }} onClick={handleEdit}>
            <Icon className='fas fa-pen' classes={{ root: classes.icon }} />
          </IconButton>
        </Grid>
      </Grid>
    )
  }

  return <div>{showForm && action ? <ActionForm action={action} topic={topic} closeForm={handleClose} /> : <Action />}</div>
}

export default flow(observer)(ActionsEdit)
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2em',
      margin: '0'
    },
    actionButton: {
      backgroundColor: '#F7BF14',
      ['&:hover']: {
        backgroundColor: '#F7BF14'
      }
    },
    title: {
      marginBottom: '.5em'
    },
    description: {
      marginBottom: '1em'
    },
    iconButton: {
      backgroundColor: theme.palette.primary.main,
      color: '#ffffff',
      marginLeft: '.5em',
      boxShadow: '0px 1px 4px 1px rgb(0 0 0 / 12%)'
    },
    icon: {
      fontSize: '1em'
    }
  })
)
