import { Tab, Tabs } from '@material-ui/core'
import { flow } from 'lodash'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { IStore, ITopic } from '../../store'
import ConversationsTable from '../admin/v2/Conversations/ConversationsTable'

type Props = {
  topic: ITopic
}

interface InjectedProps {
  conversations_total: number
  hosts_total: number
  guests_total: number
  topicId?: number
  uniq_guests_total: number

  setTimeScope(val: string | undefined)
  loadFilter(arg?: FIXME)
  setSortedPriority(val: boolean)
  setSortingId(val: string)
  setTopicId(val: number | undefined)
}

const TopicConversations = ({ topic, setSortingId, setTimeScope, setSortedPriority, loadFilter, setTopicId, topicId }: Props & InjectedProps) => {
  useEffect(() => {
    if (topic) {
      setTimeScope(undefined)
      setSortingId('startsAt')
      setSortedPriority(true)
      setTopicId(topic.id)
      loadFilter({})
    }
    return () => {
      setTopicId(undefined)
      setTimeScope('upcoming')
      setSortingId('startsAt')
      setSortedPriority(true)
      loadFilter({ reset: true, clear: true })
    }
  }, [topic, setTopicId, setTimeScope, setSortedPriority, setSortingId, loadFilter])

  return <div>{topic && topicId && <ConversationsTable height='calc(100vh - 32em)' noDataMsg={`There have been no ${topic.eventTypePlural} yet using this Topic.`} />}</div>
}
// export default TopicConversations
export default flow(
  observer,
  inject<InjectedProps, Props>(
    ({
      mst: {
        auth: { user },
        partners: { conversations_total, hosts_total, guests_total, uniq_guests_total, loadStats, upcoming_conversations, past_conversations },
        conversations: { setTimeScope, loadFilter, createCsvExport, setSortedPriority, setSortingId, setTopicId, topicId }
      }
    }: {
      mst: IStore
    }) => {
      return {
        user,
        loadFilter,
        setTimeScope,
        conversations_total,
        hosts_total,
        guests_total,
        uniq_guests_total,
        loadStats,
        createCsvExport,
        setSortedPriority,
        setSortingId,
        setTopicId,
        topicId
      }
    }
  )
)(TopicConversations)
