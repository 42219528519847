import { createSelector } from 'reselect'
import { filter } from 'lodash'

export const getSectionsRaw = (state) => state.sections

export const getCurrentSectionId = (state, props) => props.section.id

export const getSectionableId = (state, props) => props.sectionable.id

const getSectionsList = createSelector(getSectionsRaw, (s) => s.list)

export const getSections = createSelector(getSectionsList, (sections) => {
  return sections
})

export const getLargeSections = createSelector(getSections, (sections) => {
  return filter(sections, (s) => s.size === 'large')
})

export const getMediumSections = createSelector(getSections, (sections) => {
  return filter(sections, (s) => s.size === 'medium')
})

export const getSmallSections = createSelector(getSections, (sections) => {
  return filter(sections, (s) => s.size === 'small')
})

export const getErrorSections = createSelector(getSectionsRaw, (s) => s.error)

export const getLoadingSections = createSelector(getSectionsRaw, (s) => s.loading)
