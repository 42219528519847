import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import { flow, sortBy } from 'lodash'
import withTranslation from '../hocs/withTranslation'
import { withRouter } from 'react-router'
import { observer } from 'mobx-react'
import { useForm } from 'react-hook-form'
import { getSnapshot, isStateTreeNode } from 'mobx-state-tree'
import { TextFieldControl, WysiwygControl, SelectControl } from '../utils'
import { Card, CircularProgress, FormControlLabel, InputLabel, Switch } from '@material-ui/core'

const TopicNotificationsForm = (props: FIXME) => {
  const { handleSubmit, errors, formState, control, watch, reset } = useForm({ mode: 'onBlur' })
  const { isDirty, isSubmitting } = formState
  const { notification, t, onSave, topic } = props

  const [inProgress, setInProgress] = useState(false)
  useEffect(() => {
    notification?.setDirty(isDirty)
  }, [notification, isDirty])

  const handleSave = handleSubmit((data: any, e) => {
    const updateData = { ...(isStateTreeNode(notification) ? getSnapshot(notification) : notification || {}), ...data }

    notification?.updateTopicNotification(updateData).then(({ data: updatedData }) => {
      reset(updatedData, {
        errors: false, // errors will not be reset
        dirtyFields: false, // dirtyFields will not be reset
        isDirty: false, // dirty will not be reset
        isSubmitted: true,
        touched: false,
        isValid: true,
        submitCount: true
      })
      onSave && onSave()
    })
  })

  const subjectValue = watch('subject') || notification?.subject
  const contentValue = watch('content') || notification?.content
  const currentActiveState = watch('active') !== undefined ? watch('active') : notification?.active

  return (
    <Card key={notification.id} className='huge-bottom no-shadow'>
      <form onSubmit={handleSave}>
        <TextFieldControl
          name='subject'
          control={control}
          defaultValue={notification?.subject?.toString() || ''}
          error={errors && errors.subject}
          label={t('convoNotifications.subjectLabel')}
        />

        {notification.sendDate && (
          <SelectControl
            autoWidth
            name='sendDate'
            label={t('convoNotifications.sendDateLabel')}
            className='reg-top'
            control={control}
            defaultValue={notification.sendDate}
            error={errors && errors.sendDate}
            options={[
              { value: '18_hours', label: '1 day' },
              { value: '3_days', label: '3 days' },
              { value: '1_week', label: '1 week' },
              { value: '2_weeks', label: '2 weeks' },
              { value: '3_weeks', label: '3 weeks' },
              { value: '1_month', label: '1 month' },
              { value: '2_months', label: '2 months' }
            ]}
          />
        )}

        <div className='row no-gutters fat-top'>
          <WysiwygControl name='content' control={control} defaultValue={notification?.content || ''} error={errors && errors.content} dynamicTags={true} />
        </div>
        <div className='row no-gutters reg-top thin-left'>
          <div className='col-xs-4 no-gutters'>
            <FormControlLabel
              disabled={inProgress}
              control={
                <Switch
                  checked={notification?.active}
                  disabled={inProgress}
                  onChange={(e) => {
                    setInProgress(true)
                    notification
                      ?.updateTopicNotification(
                        { active: e.currentTarget.checked },
                        { dirty: notification.dirty, alert: `Email ${e.currentTarget.checked ? 'activated' : 'disabled'}` }
                      )
                      .then(() => setInProgress(false))
                      .catch(() => setInProgress(false))
                  }}
                />
              }
              label={
                inProgress ? (
                  <CircularProgress size='small' />
                ) : (
                  <InputLabel>{currentActiveState ? t('convoNotifications.activated') : t('convoNotifications.notActivated')}</InputLabel>
                )
              }
            />
          </div>
          <div className='col-xs-8 no-gutters end-xs'>
            <Button
              onClick={() =>
                topic.sendTestEmail({
                  name: notification.name,
                  testSubject: subjectValue,
                  testContent: contentValue
                })
              }
              variant='text'
            >
              {t('convoNotifications.sendTestEmail')}
            </Button>
            <Button disabled={!isDirty || isSubmitting} type='submit' className='reg-left' color='secondary' variant='contained'>
              {t('common.save')}
            </Button>
          </div>
        </div>
      </form>
    </Card>
  )
}

export default flow(observer, withTranslation, withRouter)(TopicNotificationsForm)
