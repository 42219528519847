import { createSelector, createStructuredSelector } from 'reselect'
import { reverse, orderBy } from 'lodash'
import { getLoadingTopics, getTopicsList } from './topicGetters'

function sortByRankAndCreatedNewest(topics) {
  return reverse(orderBy(topics, ['rank', 'createdAt'], ['desc', 'desc']))
}

const getTopics = createSelector(getTopicsList, (topics) => {
  return sortByRankAndCreatedNewest(topics)
})

export default createStructuredSelector({
  items: getTopics,
  itemsLoading: getLoadingTopics
})
