import { get, put } from './utils'

class TopicNotificationsApi {
  static update(formData) {
    return put(`/topic_notifications/${formData.id}`, {
      body: JSON.stringify({ topic_notification: formData })
    })
  }

  static getAll(slug) {
    return get(`/topic_notifications?slug=${slug}`)
  }
}

export default TopicNotificationsApi
