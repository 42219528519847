import React, { useEffect, useState } from 'react'
import { flow } from 'lodash'

import Dialog from '../../../DialogWrapper'
import { observer } from 'mobx-react'
import { Button, Container, Grid, IconButton, Paper, TextField, Tooltip } from '@material-ui/core'
import DataTable, { IColData, ValueLabel } from '../../../shared/DataTableFilterable'
import { useParamConversation, useUsers } from '../../../Providers'
import { IUser } from '../../../../store'

const HostAssignDialog = observer(({ open, onClose }: { open: boolean; onClose?(u?: IUser) }) => {
  const { users, filterable, usersLoading } = useUsers()
  const { conversation } = useParamConversation()

  const columnData: IColData[] = [
    { name: 'firstName', title: 'First Name' },
    { name: 'lastName', title: 'Last Name' },
    { name: 'email', title: 'Email' },

    { name: 'actions', title: 'Actions', align: 'center', width: 100 }
  ]
  return (
    <Dialog dialog={{ closeDialog: () => onClose && onClose() }} open={open} name='hostAssign' maxWidth='md' title='Host Assign'>
      <section className='pane big-top-padding big-bottom-padding'>
        <div className='row reg-bottom'>
          <div className='col-xs'>
            <Paper elevation={0}>
              {users && (
                <DataTable
                  data={users}
                  columns={columnData}
                  loading={usersLoading}
                  filterable={filterable}
                  rightColumns={['actions']}
                  height={'calc(100vh - 23em)'}
                  searchPlaceholder='Search (First Name, Last Name, Email)'
                  getCellValue={(row: IUser, columnName: string) => {
                    switch (columnName) {
                      case 'firstName':
                        return <ValueLabel value={row?.firstName} />
                      case 'lastName':
                        return <ValueLabel value={row?.lastName} />
                      case 'email':
                        return <ValueLabel value={row?.email} />
                      case 'city':
                        return <ValueLabel value={row?.city} />
                      case 'stateLevel':
                        return <ValueLabel value={row?.stateLevel} />

                      case 'actions':
                        return (
                          <MemberActions
                            user={row}
                            onClick={(e, u) => {
                              // m.removeMembership()
                              conversation && conversation.assignHost(u).then(() => onClose && onClose(u))
                            }}
                          />
                        )
                      default:
                        return <ValueLabel value={row[columnName]} />
                    }
                  }}
                />
              )}
            </Paper>
          </div>
        </div>
      </section>
    </Dialog>
  )
})

const MemberActions = observer(({ user, onClick }: { user: IUser; onClick?(e, m: IUser) }) => {
  return (
    <Grid container direction='row' justify='space-around' alignItems='center'>
      <Tooltip title='Assign'>
        <IconButton size='small' onClick={(e) => onClick && onClick(e, user)}>
          <i className='fas fa-user opacity-8'></i>
        </IconButton>
      </Tooltip>
    </Grid>
  )
})

export default HostAssignDialog
