export function parseGenericObject<T>(object: T, form?: FormData, namespace?: string): FormData {
  const formData = form || new FormData()
  for (const property in object) {
    const isPropertyExist = property in object

    if (!isPropertyExist) {
      continue
    }

    const contextProperty = object[property]
    const formKey = namespace ? `${namespace}[${property}]` : property

    if (typeof contextProperty === 'object' && !(contextProperty instanceof File)) {
      parseGenericObject<any>(contextProperty, formData, formKey)
    } else {
      formData.append(formKey, String(contextProperty))
    }
  }
  return formData
}

export default parseGenericObject
