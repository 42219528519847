import { Grid } from '@material-ui/core'
import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import AdminMetaTags from '../../../shared/AdminMetaTags'
import PartnersSummary from '../Partners/PartnersSummary'
import OrganizationsSummary from '../Organizations/OrganizationsSummary'

const Settings = () => {
  return (
    <>
      <AdminMetaTags title='Settings' />
      <PartnersSummary />
      <OrganizationsSummary />
    </>
  )
}

export default withRouter(Settings)
