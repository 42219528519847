import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import { forEach, isUndefined } from 'lodash'
import { getTimeZone } from '../actions/timeZoneActions'

export default class AddressAutocomplete extends Component {
  static propTypes = {
    value: PropTypes.string,
    label: PropTypes.string,
    helperText: PropTypes.string,
    updateForm: PropTypes.func
  }
  state = { value: '' }

  componentDidMount() {
    this.setState({ value: this.props.value }, () => this.init())
  }

  init = () => {
    const { updateForm, formName, types = ['address'], venueName, isVirtual } = this.props
    if (!updateForm) throw new Error('you must provide an `updateForm` prop to use the AddressAutocomplete component')

    const input = document.getElementById(`addressAutocompleteField-${formName}`)
    const options = {
      types
    }

    if (isUndefined(window.google)) {
      return null
    }

    const geoAutocomplete = new window.google.maps.places.Autocomplete(input, options)
    geoAutocomplete.addListener('place_changed', () => {
      const selectedPlace = geoAutocomplete.getPlace()
      const selectedSuggest = extractAddressDetails(selectedPlace)

      // Populate fields
      const {
        name,
        place_id,
        formatted_address,
        utc_offset_minutes,
        geometry: {
          location: { lng, lat }
        }
      } = selectedPlace
      const {
        street_number,
        route,
        neighborhood,
        sublocality_level_1,
        country,
        administrative_area_level_1: adminArea,
        locality: city,
        postal_code: postcode,
        postal_town
      } = selectedSuggest

      const resetFields = {
        name: null,
        place_id: null,
        venue_name: null,
        address: null,
        neighborhood: null,
        city: null,
        postal_town: null,
        state_level: null,
        postcode: null,
        country: null,
        full_place_response: null,
        lat: null,
        lng: null,
        coordinates: null,
        formatted_address: null,
        utc_offset_minutes: null
      }

      forEach(resetFields, updateForm)
      const fields = {
        name, //TODO : check this one was used in FormStepTwo
        venue_name: venueName ? venueName : isVirtual ? null : name.replace(/^\d[^\s]*/, ''),
        place_id,
        address: street_number ? `${street_number} ${route}` : route,
        neighborhood: neighborhood || sublocality_level_1 || postal_town || city,
        city: city ? city : postal_town,
        state_level: city === adminArea ? null : adminArea,
        postcode,
        country,
        formatted_address,
        utc_offset_minutes,
        full_place_response: JSON.stringify(selectedPlace),
        lat: lat(),
        lng: lng(),
        coordinates: [lat(), lng()]
      }

      getTimeZone(`${lat()},${lng()}`, updateForm)

      forEach(fields, updateForm)
    })
  }

  render() {
    const { label, helperText, defaultValue, classes, inputProps, formName, onChange, onBlur, onFocus } = this.props
    const customId = `addressAutocompleteField-${formName}`

    return (
      <TextField
        id={customId}
        helperText={helperText}
        placeholder={label}
        defaultValue={defaultValue}
        fullWidth
        classes={classes}
        InputProps={{ ...inputProps }}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
      />
    )
  }
}

function extractAddressDetails(selectedPlace) {
  const componentForm = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'short_name',
    country: 'long_name',
    postal_code: 'short_name',
    neighborhood: 'long_name',
    sublocality_level_1: 'long_name',
    postal_town: 'long_name'
  }

  // Get each component of the address from the place details
  let selectedSuggest = {}

  for (let addressComponent of selectedPlace.address_components) {
    const addressType = addressComponent.types[0]
    if (componentForm[addressType]) {
      selectedSuggest[addressType] = addressComponent[componentForm[addressType]]
    }
  }

  return selectedSuggest
}
