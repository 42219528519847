import React from 'react'
import { AddressAutocompleteControl, CheckboxControl, TextFieldControl } from '../../utils'
import { required, minAttendance } from '../../validations/fieldLevelValidations'
import { StepProps } from './NewStepper'
import withTranslation, { WithTranslation } from '../../hocs/withTranslation'

const PublicLocationStep = ({ t, control, watch, errors, setValue }: StepProps & WithTranslation) => {
  const venue = watch('venueType') === 'restaurant' ? 'Restaurant' : 'Venue'
  const isRestaurantConversation = watch('venueType') === 'restaurant'

  return (
    <>
      <div>
        <AddressAutocompleteControl
          types={['address']}
          name='location'
          control={control}
          label={t('conversation.publicLocationLabel', { venue })}
          onChange={(val) => {
            setValue('city', val?.city, { shouldDirty: true })
            setValue('venueName', val?.venue_name, { shouldDirty: true })
            setValue('placeId', val?.place_id, { shouldDirty: true })
          }}
        />

        <TextFieldControl name='venueName' control={control} error={errors && errors.venueName} label={t('conversation.publicVenueNameLabel', { venue })} defaultValue={''} />

        {isRestaurantConversation && (
          <CheckboxControl
            className='reg-top'
            name='venueAgreement'
            control={control}
            label={t('conversation.venueAgreementLabel', {
              venue: venue.toLowerCase()
            })}
          />
        )}

        <div className='reg-top'>
          <TextFieldControl
            label={t('conversation.maxAttendeesLabel')}
            rules={{ validate: { required, minAttendance } }}
            name='maxAttendees'
            control={control}
            defaultValue={8}
            type='number'
            className='reg-top'
            error={errors && errors.maxAttendees}
          />
        </div>
      </div>
    </>
  )
}

export default withTranslation(PublicLocationStep)
