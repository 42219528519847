import { Button, Grid } from '@material-ui/core'
import { isEmpty, identity, pick, pickBy, camelCase } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { IDocument } from '../../store/models/Topic/TopicDocuments'
import { FileButtonControl, SelectControl, TextFieldControl } from '../utils'
import * as validations from '../validations/fieldLevelValidations'
import { ITopicLmsCourse, ITopicLmsItem } from '../../store/models/Topic/TopicLmsCourses'

interface Props {
  lmsItem?: ITopicLmsItem
  lmsCourse?: ITopicLmsCourse
  onSubmit(data: any): Promise<any>
  onCancel?()
  onDirty?(val: boolean)
}

export const ItemForm = ({ lmsItem, onSubmit, onCancel, onDirty }: Props) => {
  const { handleSubmit, errors, formState, control, watch, setError, reset, clearErrors } = useForm({ mode: 'onBlur', shouldUnregister: false })
  const { isDirty, isSubmitting, dirtyFields } = formState

  const displayError = (error) => {
    if (error) {
      for (const fieldName in error) {
        setError(camelCase(fieldName), {
          type: 'manual',
          message: error[fieldName]
        })
      }
    }
  }

  const handleSave = handleSubmit((data: any, e) => {
    const updateData = { ...(lmsItem ? pick(data, Object.keys(pickBy(dirtyFields, identity))) : data) }

    onSubmit(updateData).then(afterSave)
  })

  const afterSave = async ({ error = undefined }: { error?: any; data?: any } = {}) => {
    if (error) {
      displayError(error)
    } else {
      reset({ file: null })
    }
    onDirty && onDirty(false)
  }

  return (
    <div className='fat-bottom'>
      Video Form
      <form onSubmit={handleSave}>
        <Grid container>
          <Grid item xs={12}>
            <FileButtonControl
              rules={{ validate: validations.required }}
              className='reg-bottom'
              name='file'
              accept='.mp4'
              contentType='video/mp4'
              sizeLimit={250 * 1048576}
              control={control}
              defaultValue={lmsItem?.file}
              error={errors && errors.file}
            />
          </Grid>

          <Grid item xs={12} className='reg-left'>
            <TextFieldControl defaultValue={lmsItem?.name} name='name' control={control} error={errors && errors.name} label={'Name'} rules={{ validate: validations.required }} />
          </Grid>
          <Grid item xs={12} className='reg-left'>
            <TextFieldControl
              name='description'
              rows={3}
              multiline={true}
              defaultValue={lmsItem?.description}
              control={control}
              error={errors && errors.description}
              label={'Description'}
              rules={{ validate: validations.required }}
            />
          </Grid>
          <Grid item xs={12} className='reg-left'>
            <TextFieldControl name='sortIndex' control={control} defaultValue={lmsItem?.sortIndex || 0} error={errors && errors.sortIndex} label={'Ranking'} type='number' />
          </Grid>
        </Grid>

        <div className={!isDirty ? 'hide' : undefined}>
          <Button type='submit' className='reg-top' color='secondary' variant='contained'>
            Submit
          </Button>
          <Button
            className='reg-top thin-left'
            color='secondary'
            onClick={() => {
              reset({ file: null })
              onCancel && onCancel()
            }}
          >
            Cancel
          </Button>
        </div>
      </form>
      <br />
    </div>
  )
}
