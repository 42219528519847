import React from 'react'
import { flow } from 'lodash'
import Card from '@material-ui/core/Card'

import { connectTopicTheme } from '../../connectTheme.js'
import { observer } from 'mobx-react'
import Button from '@material-ui/core/Button'
import { IConversation } from '../../store/index.js'

const ListCard = (props: { conversation: IConversation; theme: FIXME }) => {
  const { conversation, theme } = props

  if (!conversation) return <></>

  const Icon = () => (conversation?.icon?.primary?.url ? <img className='img-responsive' src={conversation.icon.primary.url} alt='icon' /> : <></>)

  return (
    <Card className='fat-bottom'>
      <div className={'row no-gutters black-text'}>
        <div
          className='col-xs-12 col-sm-3 no-gutters flex-center'
          style={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText
          }}
        >
          <div className='center-margin'>
            <div className='convo-icon-xs center-margin'>
              <Icon />
            </div>
          </div>
        </div>

        <div className='row flat-left middle-xs no-gutters flat-right-padding'>
          <div className='col-xs-12'>
            <div className='box'>
              <p className='header-20 flat-top flat-bottom'>{conversation.title}</p>
              {/* <p className='header-14 light-grey-text italic flat-top flat-bottom'>{conversation?.cardBanner}</p> */}
            </div>
          </div>
        </div>

        <div className='col-xs-12 col-sm-3 center-xs around-xs reg-top-padding'>
          <Button className='reg-bottom thin-right-xs' onClick={() => conversation.attend()}>
            Attend
          </Button>
        </div>
      </div>
    </Card>
  )
}

export default flow(connectTopicTheme, observer)(ListCard)
