import React from 'react'
import { AddressAutocompleteControl, RadioGroupControl, TextFieldControl } from '../../utils'
import { required, http, maxLength2000, maxDailyCoAttendance, minAttendance } from '../../validations/fieldLevelValidations'
import { StepProps } from './NewSynthStepper'
import withTranslation, { WithTranslation } from '../../hocs/withTranslation'

const SynthLocationStep = ({ t, control, watch, errors, setValue, dirtyFields, clearErrors }: StepProps & WithTranslation) => {
  const customVirtualType = watch('virtualType') === 'custom'
  const showAddressHint = dirtyFields.location && !dirtyFields.placeId

  return (
    <>
      <div>
        <AddressAutocompleteControl
          types={['(regions)']}
          name='location'
          control={control}
          label={'What city was this hosted in?'}
          className='reg-top'
          onChange={(val) => {
            setValue('city', val?.city, { shouldDirty: true })
            setValue('placeId', val?.place_id, { shouldDirty: true })
          }}
        />

        {showAddressHint ? (
          <p
            className='material-error'
            dangerouslySetInnerHTML={{
              __html: t('signup.addressHint', {
                url: 'mailto:support@inclusivv.co'
              })
            }}
          />
        ) : null}

        <div className='reg-top'>
          <TextFieldControl
            label={'Total Registered'}
            rules={{ validate: { ...(customVirtualType ? { required, minAttendance } : { required, minAttendance }) } }}
            name='attendeeCount'
            control={control}
            defaultValue={8}
            type='number'
            className='reg-top'
            error={errors && errors.attendedCount}
          />
          <TextFieldControl
            label={'Total Attended'}
            rules={{ validate: { ...(customVirtualType ? { required, minAttendance } : { required, minAttendance }) } }}
            name='attendedCount'
            control={control}
            defaultValue={8}
            type='number'
            className='reg-top'
            error={errors && errors.attendedCount}
          />
        </div>
      </div>
    </>
  )
}

export default withTranslation(SynthLocationStep)
