import React, { useEffect, useState } from 'react'
import { sortBy } from 'lodash'
import { Button, createStyles, Icon, makeStyles, Popover, Theme, Typography } from '@material-ui/core'

import ActionFormItem from './ActionFormItem'
import ActionForm from './Form'
import { observer } from 'mobx-react'
import { Prompt } from 'react-router'
import { UNSAVED_FORM_WARNING } from '../utils/constants'
import { ITopic } from '../../store'

interface Props {
  topic: ITopic
}

const ActionsEdit = (props: Props) => {
  const { topic } = props
  useEffect(() => {
    topic?.loadTopicActions()
  }, [topic])
  const actions = sortBy(topic?.topicActions, ['rank']) || []
  const [newForm, setnewForm] = useState(false)
  const [dirty, setdirty] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const classes = useStyles()
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  return (
    <div className='no-padding xtra-huge-bottom no-shadow'>
      <Prompt when={!!actions.find((a) => a.dirty) || dirty} message={UNSAVED_FORM_WARNING} />

      <Typography variant='body1' className='fat-bottom'>
        Empower conversation attendees to explore the topic further through specific, measurable, and achievable actions. Provide links to sign a petition, donate to a worthy
        cause, read/watch/listen to related resources, and more! We recommend 3-5 actions, but you can add up to 7.
        <Icon
          aria-describedby={id}
          className={'fas fa-question-circle'}
          fontSize='inherit'
          color={open ? 'primary' : undefined}
          style={{ ...(open ? {} : { color: '#6D6E71' }) }}
          onClick={handleClick}
        />
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          elevation={3}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
        >
          <Typography className={classes.popoverContent}>
            Actions can only be accessed by event attendees and will appear on the conversation page. Drive engagement by adding actions to your conversation emails using dynamic
            tags.
          </Typography>
        </Popover>
      </Typography>
      <Typography variant='subtitle1' className='fat-bottom'>
        Actions List
      </Typography>

      {actions
        .sort((b, a) => (!a.rank ? -1 : !b.rank ? 1 : a.rank < b.rank ? 1 : -1))
        .map((action, index) => (
          <ActionFormItem topic={topic} action={action} key={action.id} index={action.rank} />
        ))}

      {newForm ? (
        <ActionForm topic={topic} closeForm={() => setnewForm(false)} onDirty={(val) => setdirty(val)} index={actions.length + 1} />
      ) : (
        <Button onClick={() => setnewForm(true)} variant='contained' color='primary' className='fat-top'>
          Create A New Action
        </Button>
      )}
    </div>
  )
}

export default observer(ActionsEdit)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popoverContent: { maxWidth: '12rem', margin: '.7rem', fontSize: '12px', lineHeight: '18px', color: '#212121' }
  })
)
