import { get, omit } from 'lodash'
import { normalize } from 'normalizr'

import { USER_INVITATION_REQUESTS_LOADING, LOAD_USER_INVITATION_REQUESTS_FAILED, LOAD_USER_INVITATION_REQUESTS_SUCCESS, REMOVE_USER_INVITATION_REQUESTS } from './actionTypes.js'
import usersApi from '../api/UsersApi.js'
import UserInvitationRequestsApi from '../api/users/UserInviationRequestsApi.js'
import { showDialog, closeDialog } from './dialogActions.js'
import { showAlertMessage } from './alertMessageActions'
import ConversationInvitationRequestsApi from '../api/ConversationInvitationRequestsApi.js'
import { conversationInvitationRequestsSchema } from '../normalizers/conversationInvitationRequestNormalizers.js'
import mst from '../store/models'

export const userInvitationRequestsLoading = () => ({
  type: USER_INVITATION_REQUESTS_LOADING
})

export const loadUserInvitationRequestsFailed = (json) => ({
  type: LOAD_USER_INVITATION_REQUESTS_FAILED,
  data: json
})

export const loadUserInvitationRequestsSuccess = (json) => ({
  type: LOAD_USER_INVITATION_REQUESTS_SUCCESS,
  ...json
})

export const removeUserInvitationRequests = () => ({
  type: REMOVE_USER_INVITATION_REQUESTS
})

function showAlert(dispatch, message) {
  dispatch(showAlertMessage({ message }))
}

export function createTopicInvitationRequest(data) {
  return function (dispatch) {
    ConversationInvitationRequestsApi.create(data)
      .then((response) => {
        if (response.response.ok) {
          const flatData = normalize(response.json, conversationInvitationRequestsSchema).entities

          dispatch(showDialog('topicInvitationRequestSent'))

          dispatch(loadUserInvitationRequestsSuccess(flatData))
        } else {
          showAlert(dispatch, response.json.errors)
        }
      })
      .catch((error) => {
        throw error
      })
  }
}

export function createConversationInvitationRequest(formData) {
  return function (dispatch) {
    return usersApi
      .update(formData)
      .then((response) => {
        // dispatch(closeDialog('conversationInvitationRequest'))
        mst.dialogs.closeDialog('conversationInvitationRequest')

        if (response.response.ok) {
          var invitationData = omit(formData, ['id'])

          ConversationInvitationRequestsApi.create(invitationData).then((response) => {
            if (response.response.ok) {
              mst.auth.user.loadUserInvitationRequests()
              const flatData = normalize(response.json, conversationInvitationRequestsSchema).entities

              if (response.json.conversation === true) {
                dispatch(showDialog('specificConversationInvitationRequestSentDialog'))

                dispatch(loadUserInvitationRequestsSuccess(flatData))

                return
              }

              dispatch(showDialog('conversationInvitationRequestSent'))

              dispatch(loadUserInvitationRequestsSuccess(flatData))
            } else {
              showAlert(dispatch, response.json.errors)
            }
          })
        } else {
          showAlert(dispatch, response.json.errors)
        }
      })
      .catch((error) => {
        throw error
      })
  }
}

export function updateConversationInvitationRequest(data) {
  return function (dispatch) {
    var string = get(data, 'invite_accepted', false)

    var accepted = string === 'true'

    var dialog = accepted ? 'invite_accepted' : 'invite_denied'

    return ConversationInvitationRequestsApi.update(data)
      .then((response) => {
        if (response.response.ok) {
          dispatch(closeDialog('login'))

          dispatch(showDialog(dialog))

          const flatData = normalize(response.json, conversationInvitationRequestsSchema).entities

          dispatch(loadUserInvitationRequestsSuccess(flatData))
        } else {
          dispatch(closeDialog('login'))
          showAlert(dispatch, `${response.json.errors}`)
        }
      })
      .catch((error) => {
        throw error
      })
  }
}

export function loadUserInvitationRequests(userId) {
  return function (dispatch) {
    dispatch(userInvitationRequestsLoading())

    return UserInvitationRequestsApi.getAll(userId)
      .then((res) => {
        const { ok, status, statusText } = res.response

        if (ok) {
          const flatData = normalize(res.json, conversationInvitationRequestsSchema).entities
          dispatch(loadUserInvitationRequestsSuccess(flatData))
        } else {
          dispatch(loadUserInvitationRequestsFailed({ status, statusText }))
        }
      })
      .catch((error) => {
        throw error
      })
  }
}
