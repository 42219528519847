import React, { Component } from 'react'
import { ReactTitle } from 'react-meta-tags'
import { withRouter } from 'react-router'

import InfiniteLocationList from '../shared/InfiniteLocationList'
import getConversationIndexProps from '../../getters/conversationIndexGetter'
import { loadConversations, loadNewConversations } from '../../actions/conversationActions'
import ConversationFilters, { formName } from './ConversationFilters'
import withTranslation from '../hocs/withTranslation'
import { capitalize } from '../../decorators/textTools'

export class ConversationsPage extends Component {
  componentDidMount() {
    this.props.changeLanguage('en_US')
  }

  render() {
    const { t } = this.props
    const { locationSlug } = this.props.match.params

    return (
      <div>
        <ReactTitle title={`${capitalize(global.siteTitle)} - ${t('meta.allConversations')}`} />

        <InfiniteLocationList
          limit={9}
          initQuery={(args) => loadNewConversations(args)}
          query={(args) => loadConversations(args)}
          itemType='conversation'
          itemLabel={global.coco ? t('common.topic') : t('common.conversation')}
          header={global.coco ? t('user.upcomingTopics') : t('common.findConversation')}
          transparentHeader
          formName={formName}
          filters={ConversationFilters}
          getter={getConversationIndexProps}
          locationSlug={locationSlug}
        />
      </div>
    )
  }
}

export default withTranslation(withRouter(ConversationsPage))
