import React, { useCallback, useEffect } from 'react'
import ResponsiveLineCard from './ResponsiveLineCard'
import palette from '../../../../themes/palette'
import { flow } from 'lodash'
import { inject, observer } from 'mobx-react'
import { IStore } from '../../../../store/models'
import { getSnapshot } from 'mobx-state-tree'
import DateFilterAction from '../partials/DateFilterAction'

interface Props {
  selectedPartnerId?: number
  graphData: any
  totalStats: any
  inlineStats: any
  scopedBy?: string
  loading?: boolean
  loadAnalyticsData(val?: any, opt?: any)
  setScopedBy(val: string)
  setStartsAt(val: string)
  setEndsAt(val: string)
}

const AttendeesOverTime = ({ selectedPartnerId, graphData, totalStats, inlineStats, loadAnalyticsData, setScopedBy, scopedBy, setStartsAt, setEndsAt, loading }: Props) => {
  useEffect(() => {
    loadAnalyticsData()
  }, [loadAnalyticsData])

  useEffect(() => {
    loadAnalyticsData({}, { reload: true })
  }, [loadAnalyticsData, selectedPartnerId])

  const handleClose = useCallback(
    (menu_name: string) => () => {
      setScopedBy(menu_name)
      loadAnalyticsData(undefined, { reload: true })
    },
    [setScopedBy, loadAnalyticsData]
  )

  const handleCustomDate = useCallback(() => {
    setScopedBy('date_range')
    loadAnalyticsData(undefined, { reload: true })
  }, [setScopedBy, loadAnalyticsData])

  return (
    <ResponsiveLineCard
      title='Participants Over Time'
      graphData={graphData}
      totalStats={totalStats}
      inLineStats={inlineStats}
      chartColor={[palette.lightDarkOrange, palette.darkBlue, palette.red300, palette.lightBlue]}
      headerAction={<DateFilterAction {...{ scopedBy, handleClose, setStartsAt, setEndsAt, handleCustomDate }} />}
      loading={loading}
      id={'ic-admin-line-chart-attendees'}
    />
  )
}

export default flow(
  observer,
  inject<FIXME, FIXME>(
    ({
      mst: {
        analytics: { attendeesLine },
        partners: { selectedPartnerId }
      }
    }: {
      mst: IStore
    }) => {
      const { graphData, totalStats, inlineStats, loadAnalyticsData, setScopedBy, scopedBy, setStartsAt, setEndsAt, getDataName, loadingIds } = attendeesLine
      return {
        graphData: getSnapshot(graphData),
        inlineStats,
        totalStats,
        loadAnalyticsData,
        setScopedBy,
        scopedBy,
        setStartsAt,
        setEndsAt,
        selectedPartnerId,
        loading: loadingIds.includes(getDataName())
      }
    }
  )
)(AttendeesOverTime)
