import React, { Component, useEffect, useState } from 'react'
import { flow, has, omit, get, isEmpty, isUndefined, truncate, includes } from 'lodash'
import queryString from 'query-string'
import { connectTopicTheme } from '../../../../connectTheme'
import PageLoadingAnimation from '../../../shared/PageLoadingAnimation'
import withTranslation from '../../../hocs/withTranslation'
import CustomMetaTags from '../../../shared/CustomMetaTags'
import { withRouter } from 'react-router'
import Sections from '../../../sections/Sections'
import { inject, observer } from 'mobx-react'
import { IConversation, IStore } from '../../../../store/models'
// import HeroPane from './HeroPane'
// import InfoPane from './InfoPane'
// import CallToAction from './CallToAction'
// import AboutPane from './AboutPane'
import ActionPaneSwitcher from '../ActionPaneSwitcher'
import GuestList from './GuestListNew'
import WhatToExpectPane from '../WhatToExpectPane'
import FaqPane from '../FaqPane'
import MapPane from '../MapPane'
import { PartnerLogoPane, SchedulePane, DescriptionPane } from '../BasicPanes'
// import SharePane from './SharePane'
import { ConversationContext } from '../../../Providers/ConversationProvider'
import { NpsHandler } from '../../../shared'
import { Stories } from '../../../topics/Show/sections/Stories'
import moment from 'moment-timezone'
import { Link as MuiLink, AppBar, Avatar, Button, Container, createStyles, Divider, Grid, Icon, makeStyles, Theme, Toolbar, Typography, useScrollTrigger } from '@material-ui/core'
import { useAuth, useParamConversation, useParamTopic } from '../../../Providers'
import ConversationBanner from './ConversationBanner'
import LoadingAnimation from '../../../shared/LoadingAnimation'
import DOMPurify from 'dompurify'
import { Link } from 'react-router-dom'
import UserAvatar from '../../../users/Avatar'
import { useTranslation } from 'react-i18next'
import ConversationActionsBar from './ConversationActionsBar'
import HowToJoin from './HowToJoin'
import CalendarDropdownLink from '../../partials/CalendarDropdownLink'
import { capitalize } from '../../../../decorators/textTools'
import { DefaultThemeProvider } from '../../../../themes'
import PopoverIcon from '../../../shared/PopoverIcon'
import { ConversationLocation } from './ConversationLocation'
import FoodAndDrinks from './FoodAndDrinks'
import NoteFromHost from './NoteFromHost'
import ZoomApi from '../../../../api/ZoomApi'
import FavButton from '../../../users/MyConversations/FavButton'
import TagManager from 'react-gtm-module'
import { AnalyticsTracker } from '../../../partners/AnalyticsTracker'

const HostAvatar = () => {
  const { conversation, conversationLoading } = useParamConversation()
  const classes = useStyles()
  const host = conversation?.host
  if (!conversation || conversationLoading || !host) return <LoadingAnimation />
  return (
    <Grid container spacing={1}>
      <Grid item>
        <Link to={`/users/${host.id}`}>
          {/* <UserAvatar {...host} size='medium' /> */}
          {/* <div className={classes.hostedByAvatar}>
            <i className='fal fa-user-circle' style={{ fontSize: '2.5rem' }}></i>
          </div> */}
          {host?.profileImage?.thumb?.url && (
            <Avatar className={classes.hostedByAvatar} src={host?.profileImage?.thumb?.url} style={{ backgroundColor: '#ffffff' }}>
              <i className='fal fa-user-circle' style={{ fontSize: '2.5rem' }}></i>
            </Avatar>
          )}
          {/* {host?.profileImage?.thumb?.url ? (
            <Avatar className={classes.hostedByAvatar} src={host?.profileImage?.thumb?.url}>
              <i className='fal fa-user-circle'></i>
            </Avatar>
          ) : (
            <Avatar className={classes.hostedByAvatar}>
              <i className='fal fa-user-circle'></i>
            </Avatar>
          )} */}
        </Link>
      </Grid>
      <Grid item>
        <Typography variant='body2'>Hosted by</Typography>
        <Typography variant='body2'>
          <Link to={`/users/${host.id}`}>{host.firstName}</Link>
        </Typography>
      </Grid>
    </Grid>
  )
}

const Hero = () => {
  const classes = useStyles()
  const { conversation, conversationLoading, isHost } = useParamConversation()
  const { t } = useTranslation()
  if (!conversation || conversationLoading) return <LoadingAnimation />

  const editConversationPath = conversation.slug
    ? `/${conversation.slug}/conversations/${conversation.id}/edit`
    : `/topics/${conversation.topicId}/conversations/${conversation.id}/edit`

  return (
    <div className={classes.hero} style={{ background: `linear-gradient(83.91deg, ${conversation.themeColor} 56.78%, ${conversation.themeColor}aa 92.71%)` }}>
      {conversation.full && (
        <Button
          disabled
          style={{
            color: '#000000',
            width: '39px',
            height: '24px',
            left: '8px',
            top: '8px',

            background: '#CDCFD2',
            borderRadius: '4px'
          }}
        >
          Full
        </Button>
      )}
      <div className={classes.heroContent}>
        {conversation && (
          <Grid container justify='center' alignItems='center'>
            {conversation?.icon?.primary?.url && (
              <Grid item>
                <Typography variant='h4'>
                  <img src={conversation?.icon?.primary?.url} style={{ maxWidth: '5rem', maxHeight: '5rem' }} />
                </Typography>
              </Grid>
            )}
            <Grid item>
              {!conversation.isPremium && (
                <Typography variant='h4' className={classes.heroTitle}>
                  {conversation.title}
                </Typography>
              )}
            </Grid>
            {isHost && (
              <Grid item xs={12} className={classes.manageGuestsSection}>
                <Link to={editConversationPath} target='_blank'>
                  <Button variant='contained' size='small' className='white-btn' startIcon={<i className='fa fa-user-friends'></i>}>
                    {t('conversation.manageDetailsAndGuests')}
                  </Button>
                </Link>
              </Grid>
            )}
          </Grid>
        )}
      </div>
      <div style={{ float: 'right' }}>
        <FavButton likableId={conversation.id} likableType='Conversation' />
      </div>
    </div>
  )
}

/* eslint-disable-next-line */
const NewConversationPage = observer(() => {
  const classes = useStyles()
  const { user } = useAuth()
  const { conversation, conversationLoading, isGuest, isHost } = useParamConversation()
  const { t } = useTranslation()
  const { topic, topicLoading } = useParamTopic()
  const host = conversation?.host
  // const redirState = encodeURIComponent(`conversation_id=${conversation?.id}&user_id=${user?.id}`)
  // const redirState = Buffer.from(`conversation_id=${conversation?.id}&user_id=${user?.id}`, 'binary').toString('base64')

  if (!conversation) {
    return <LoadingAnimation />
  }
  const active = !conversation.expired
  const withAction = topic && topic?.topicActions.length > 0
  const activeGuest = isGuest && active
  const activeHost = isHost && active
  const showBanner = /*!(!active && !withAction && isHost) &&*/ !(active && !withAction && !isGuest) && !(active && withAction && !activeGuest && !activeHost)

  return (
    <div className={classes.root} style={showBanner ? { marginTop: '9rem' } : undefined}>
      <AnalyticsTracker />
      <DefaultThemeProvider>
        {(conversationLoading || topicLoading) && <LoadingAnimation />}

        {conversation && (
          <Container>
            <ConversationBanner />
            <ConversationActionsBar />
            <Grid container>
              {conversation?.isPremium && conversation?.backgroundImage?.url ? (
                <Grid container justify='center' alignItems='center'>
                  <Grid item>
                    <Typography variant='h4'>
                      <img src={conversation?.backgroundImage?.url} className='img-responsive thin-top' />
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <Grid item xs={12} className={classes.heroGrid}>
                  <Hero />
                </Grid>
              )}

              <Grid item xs={12}>
                <Typography
                  style={{
                    fontFamily: 'Helvetica Neue',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: '12px',
                    lineHeight: '16px',
                    color: '#212121',
                    marginTop: '1em',
                    letterSpacing: '1.25px',
                    textTransform: 'uppercase'
                  }}
                >
                  {conversation.startsAt && moment(conversation.startsAt).format('ddd, MMM DD, YYYY')}
                </Typography>
              </Grid>
              {conversation.isPremium && (
                <Grid item xs={12}>
                  <Typography variant='h4' className={classes.heroTitle}>
                    {conversation.title}
                  </Typography>
                </Grid>
              )}
              {conversation.expired && (
                <Grid item xs={12}>
                  <ActionPaneSwitcher id='conversationActions' />
                </Grid>
              )}
              <Grid item xs={12}>
                {topic?.description && (
                  <Typography
                    variant='h6'
                    className='reg-bottom'
                    style={{
                      marginTop: '1rem',
                      letterSpacing: '0.15px',
                      fontStyle: 'normal',
                      fontWeight: 'normal',
                      fontSize: '20px',
                      lineHeight: '24px',
                      color: '#212121'
                    }}
                  >
                    {conversation?.description && (
                      <div
                        style={{ marginTop: '0' }}
                        className={classes.subtitle}
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(conversation?.description)
                        }}
                      />
                    )}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={4} className={classes.leftGrid}>
                <HostAvatar />
                {conversation.approvalOnly && (
                  <Typography
                    variant='body1'
                    className='reg-top'
                    style={{
                      color: '#212121'
                    }}
                  >
                    <Grid container>
                      <Grid item>Invitation Only </Grid>
                      <Grid
                        item
                        style={{
                          paddingTop: '3px',
                          marginLeft: '.5rem'
                        }}
                      >
                        <PopoverIcon iconClass='fas fa-question-circle' message='Invite only events require host approval before you can become a confirmed guest.' />
                      </Grid>
                    </Grid>
                  </Typography>
                )}
                <Typography variant='body1' className='reg-top'>
                  {conversation.startsAt && moment(conversation.startsAt).format('ddd, MMM DD, YYYY')}
                </Typography>
                <Typography variant='body1'>
                  {conversation.startsAtLocally} - {conversation.endsAtLocally} {conversation.timeZoneAbbr}
                </Typography>
                {conversation.timezone !== moment.tz.guess() && (
                  <Typography variant='caption' style={{ color: 'grey' }}>
                    {conversation.startsAt && moment(conversation.startsAt).format('LT')} - {conversation.endsAt && moment(conversation.endsAt).format('LT')}
                    {moment().tz(moment.tz.guess()).format(' zz')}
                  </Typography>
                )}
                {(isGuest || isHost) && <CalendarDropdownLink conversation={conversation} />}
                <Typography variant='body2' style={{ display: 'flex', margin: '.5em 0' }}>
                  {conversation.isVirtual && (
                    <>
                      <Icon
                        className='fas fa-video opacity-3 thin-right inline-block tiny-bottom-padding'
                        style={{
                          fontSize: '16px',
                          marginTop: '2px'
                        }}
                      />{' '}
                      Online
                    </>
                  )}
                </Typography>

                {(isGuest || isHost) && (
                  <>
                    {conversation.showVirtualLink && (
                      <Button variant='contained' color='primary' href={conversation.userVirtualLink} target='_blank'>
                        Join {capitalize(conversation.virtualEventTypeSingular)}
                      </Button>
                    )}
                  </>
                )}
                <ConversationLocation />
                {conversation.partnerLogo?.primary?.url && (
                  <div className='reg-right fat-top'>
                    <Typography variant='body2'>In partnership with</Typography>
                    <br />
                    {topic?.partnerLogoLink ? (
                      <Link to={`/${topic.partnerLogoLink}`} target='_blank'>
                        <img src={conversation.partnerLogo?.primary?.url} className='img-responsive thin-top reg-right' />
                      </Link>
                    ) : (
                      <img src={conversation.partnerLogo?.primary?.url} className='img-responsive thin-top reg-right' />
                    )}
                  </div>
                )}
              </Grid>
              <Grid item xs={12} sm={8} className={classes.rightGrid}>
                {conversation?.about && (
                  <Typography
                    variant='body2'
                    className='fat-bottom'
                    component='div'
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(conversation?.about)
                    }}
                  />
                )}

                <FoodAndDrinks />
                <HowToJoin />

                <Divider className='fat-bottom' />
                <Typography variant='subtitle1' className='reg-bottom'>
                  Meet The Host
                </Typography>
                <Typography variant='body2'>
                  <Grid container>
                    <Grid item>
                      {host?.profileImage?.thumb?.url && (
                        <Avatar className={classes.meetHostAvatar} src={host?.profileImage?.thumb?.url} style={{ backgroundColor: '#ffffff' }}>
                          <i className='fal fa-user-circle' style={{ fontSize: '3.5rem' }}></i>
                        </Avatar>
                      )}
                    </Grid>
                    {host && (
                      <Grid item>
                        <Link to={`/users/${host.id}`}>
                          <Typography variant='body2' className={classes.meetHostAvatarName}>
                            {host?.firstName}
                          </Typography>
                          <Typography variant='body2' className={classes.meetHostAvatarName}>
                            {host?.lastName}
                          </Typography>
                        </Link>
                      </Grid>
                    )}
                    <Grid item xs={12} className='reg-bottom'>
                      <Typography variant='body2' className='reg-top reg-bottom'>
                        {host?.bio}
                      </Typography>
                      {isHost && host && <Link to={`/users/${host.id}/edit`}>Edit Bio</Link>}
                      <NoteFromHost />
                      {!isHost && (
                        <Button
                          variant='outlined'
                          className='reg-top'
                          onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            conversation.messageHost()
                          }}
                        >
                          Message Host
                        </Button>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <GuestList conversation={conversation} />
                    </Grid>
                    <Grid item xs={12} className='reg-bottom'>
                      {isHost && (
                        <Button
                          variant='outlined'
                          className='reg-top'
                          onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            conversation.emailGuests()
                          }}
                        >
                          Message Guests
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              {conversation.isPremium && (
                <Grid item xs={12}>
                  <MapPane conversation={conversation} topic={topic} />
                  <FaqPane conversation={conversation} topic={topic} />
                  <SchedulePane conversation={conversation} topic={topic} />
                </Grid>
              )}

              {topic?.showHowItWorks && (
                <Grid item xs={12}>
                  <WhatToExpectPane conversation={conversation} topic={topic} />
                </Grid>
              )}
              <Grid item xs={12}>
                <Stories />
              </Grid>

              {!conversation.expired && (
                <Grid item xs={12}>
                  <ActionPaneSwitcher id='conversationActions' />
                </Grid>
              )}

              <Grid item xs={12}>
                {conversation.isPremium && <Sections sectionable={conversation} />}
                {conversation.isPremium && conversation.showTopicSponsors && <Sections sectionable={topic} showSectionHeader={isEmpty(conversation.sections)} />}
              </Grid>
            </Grid>
          </Container>
        )}
      </DefaultThemeProvider>
    </div>
  )
})

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '4em auto 0 auto',
      minHeight: 'calc(100vh - 22em)',
      color: '#000000',
      maxWidth: '50rem'
    },
    heroGrid: {
      boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.14)',

      margin: '1.5rem 0 .5rem 0',
      [theme.breakpoints.down('xs')]: {
        margin: '2rem 0 .5rem 0'
      }
    },
    subtitle: {
      ['& p']: {
        marginTop: '0'
        // backgroundColor: '#ff0000'
      }
    },

    heroContent: {
      margin: '3.5rem auto 1rem auto',
      color: '#ffffff'
    },
    hero: {
      borderRadius: '8px',
      display: 'flex',
      minHeight: '10rem'
    },
    heroTitle: {
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center'
      }
    },
    manageGuestsSection: {
      textAlign: 'center',
      // border: '1px solid black',
      marginTop: '1em'
    },
    leftGrid: {
      // backgroundColor: '#aaaaaa'
    },
    rightGrid: {
      // backgroundColor: '#eeeeee'
    },
    hostedByAvatar: {
      // color: '#ffffff',
      // backgroundColor: '#ffffff',
      width: theme.spacing(5),
      height: theme.spacing(5)
      // marginRight: theme.spacing(2)
    },
    meetHostAvatar: {
      width: theme.spacing(8),
      height: theme.spacing(8),
      marginRight: theme.spacing(2)
    },
    meetHostAvatarName: {
      // color: '#0E75BB',
      fontSize: '1.25rem',
      lineHeight: '2rem'
    }
  })
)

class ConversationPage extends Component<FIXME, FIXME> {
  state = {
    loginProcess: false
  }
  actionSectionsRef = null

  checkForNps() {
    const { conversation, history, location, userLoaded, topic, user } = this.props
    if (userLoaded && location.pathname === `/${conversation.slug}/conversations/${conversation.id}/nps` && topic && conversation && topic) {
      if (user) {
        conversation.showDialog('nps', {
          nps_question_id: 1,
          score: undefined,
          conversation_id: conversation?.id,
          topic_id: conversation?.topicId,
          attendeeSurveyLink: topic?.attendeeSurveyLink,
          partner_id: topic?.partnerId,
          user_id: user?.id
        })
      } else {
        if (conversation.synthetic) {
          conversation.showDialog('npsUser', {
            nps_question_id: 1,
            score: undefined,
            conversation_id: conversation?.id,
            topic_id: conversation?.topicId,
            attendeeSurveyLink: topic?.attendeeSurveyLink,
            partner_id: topic?.partnerId,
            user_id: user?.id
          })
        }
      }
      history.replace(location.pathname.replace('/nps', ''))
    }
  }
  componentDidUpdate(prevProps) {
    const {
      conversation,
      changeLanguage,
      match: {
        params: { action }
      },
      history,
      location,
      userLoaded,
      topic,
      user
    } = this.props

    if (userLoaded && conversation) {
      this.handleInviteParams()
      this.handleApprovalParams()
      this.checkForNps()
    }

    if (conversation && conversation !== prevProps.conversation && conversation.locale) {
      changeLanguage(conversation.locale)
    }

    if (action && conversation && userLoaded) {
      switch (action) {
        case 'invite':
          conversation.invite()
          history.replace(location.pathname.replace('/a/invite', ''))

          break
        case 'request_invite':
          conversation.requestInvite()
          history.replace(location.pathname.replace('/a/request_invite', ''))
          break
        case 'attend':
          conversation.attend()
          history.replace(location.pathname.replace('/a/attend', ''))
          break
        case 'accept_invitation':
          this.handleAcceptInvitation()
          history.replace(location.pathname.replace('/a/accept_invitation', ''))
          break
        case 'confirm':
          conversation?.showDialog('createdConversation', { conversation, step: 1 })
          history.replace(location.pathname.replace('/a/confirm', ''))
          break
        case 'message_host':
          conversation.messageHost()
          break

        default:
          break
      }
    }
  }

  handleApprovalParams = () => {
    const {
      location,
      history,
      conversation,
      match: {
        params: { conversationId }
      }
    } = this.props

    const { approval_token, approved_conversation } = queryString.parse(location.search)

    if (!conversation.verifiedApprovalToken && approval_token) {
      // verify approved for this conversation
      if (approved_conversation !== conversationId || !conversation) return

      // verify token
      conversation.verifyApprovalToken({ approvalToken: approval_token })

      history.replace(location.pathname, null)
    }
  }

  handleInviteParams() {
    // TODO move this logic to a separate component
    const { location, userLoaded, conversation, history, showDialog, user } = this.props

    if (location.search) {
      const params = queryString.parse(location.search)

      if (has(params, 'invite_accepted') && has(params, 'dir_id') && has(params, 'guest_id')) {
        if (conversation === null) return

        if (!this.state.loginProcess && !user && userLoaded) {
          showDialog('login', { inviteLink: true })
          this.setState({ loginProcess: true })
          return
        }
        const isHost = conversation.isHost

        if (!isHost) return

        const nextParams = omit(params, ['invite_accepted', 'dir_id', 'guest_id'])
        const nextSearch = queryString.stringify(nextParams)
        history.replace({
          pathname: location.pathname,
          search: nextSearch
        })

        conversation.updateConversationInvitationRequest({
          userId: params.guest_id,
          invite_accepted: params.invite_accepted,
          id: params.dir_id,
          conversationId: conversation.id
        })
      }
    }
  }

  handleAcceptInvitation = () => {
    const { conversation, user } = this.props
    const approvalToken = get(JSON.parse(localStorage.getItem('approval') || '{}'), 'approvalToken')
    user && approvalToken && conversation && conversation.acceptInvitation({ approvalToken, userId: user.id })
  }

  render() {
    const { conversation, theme, t, topic, userLoaded, user } = this.props

    if (!topic || !conversation || !conversation.host || isUndefined(conversation) || isUndefined(theme)) {
      return <PageLoadingAnimation />
    }

    const { isPremium, showTopicSponsors } = conversation

    const currentTime = moment()
    const beforeConversation = moment((conversation as IConversation).startsAt) > currentTime
    const afterConversation = moment((conversation as IConversation).endsAt) < currentTime
    const isGuest = (conversation as IConversation).isGuest()

    return (
      <div className={`${conversation.slug} conversations-show`}>
        <NpsHandler />
        <ConversationContext.Consumer>
          {({ conversation: contextConversation, setConversation }) => {
            if (!contextConversation || contextConversation.id !== conversation.id) {
              setConversation(conversation)
            }
            return <></>
          }}
        </ConversationContext.Consumer>

        <CustomMetaTags record={conversation} />
        <NewConversationPage />

        {/* <PartnerLogoPane conversation={conversation} />

        <HeroPane conversation={conversation} />

        <DescriptionPane conversation={conversation} />

        {conversation.expired && <ActionPaneSwitcher id='conversationActions' />}

        <CallToAction conversation={conversation} user={user} userLoaded={userLoaded} />

        <InfoPane conversation={conversation} />

        <SharePane conversation={conversation} />

        <AboutPane conversation={conversation} />

        <SchedulePane conversation={conversation} />

        <WhatToExpectPane conversation={conversation} topic={topic} />

        <MapPane conversation={conversation} topic={topic} />

        <FaqPane conversation={conversation} topic={topic} />

        <GuestList conversation={conversation} />

        <Stories />
        {!conversation.expired && <ActionPaneSwitcher id='conversationActions' />}

        {isPremium && <Sections sectionable={conversation} />}
        {isPremium && showTopicSponsors && <Sections sectionable={topic} showSectionHeader={isEmpty(conversation.sections)} />}

        <CallToAction conversation={conversation} /> */}
      </div>
    )
  }
}

export default flow(
  connectTopicTheme,
  observer,
  inject<FIXME, FIXME>(
    (
      {
        mst: {
          auth: { user, loaded: userLoaded },
          topics,
          conversations,
          dialogs: { showDialog }
        }
      }: { mst: IStore },
      {
        match: {
          params: { slug, conversationId }
        }
      }
    ) => {
      const id = parseInt(conversationId, 10)
      const topic = topics.list.find((d) => d.slug === slug)
      const conversation = conversations.list.find((d) => d.id === id)
      // const topicActions = topic && topic.topicActions

      !conversation && conversations.shouldLoad(id, () => conversations.loadById(id))
      !topic && topics.shouldLoad(slug, () => topics.loadBySlug(slug))
      topic && topic.shouldLoad('topicActions', topic.loadTopicActions)
      user && user.shouldLoad('userActions', user.loadUserActions)

      return {
        user,
        userLoaded,
        topic,
        conversation,

        showDialog,
        changes: [topic?.topicActions.length, user?.userActions.length]
      }
    }
  ),
  withRouter,
  withTranslation
)(ConversationPage)
