import React, { Component, useEffect } from 'react'
import { isUndefined, debounce, flow } from 'lodash'
import DOMPurify from 'dompurify'

import { connectTopicTheme } from '../../connectTheme'
import PageLoadingAnimation from '../shared/PageLoadingAnimation.js'
import InfiniteLocationList from '../shared/InfiniteLocationListMst'
import StatsModule from './StatsModule'
import withTranslation from '../hocs/withTranslation'
import CustomMetaTags from '../shared/CustomMetaTags'
import queryString from 'query-string'
import { inject, observer } from 'mobx-react'
import { IStore } from '../../store/models/index'
import Sections from '../sections/Sections'
import { MembershipManager } from './partials/MembershipManager'
// import PartnerTracksProvider, { usePartnerTracks } from '../Providers/PartnerTracksProvider'
import { useHistory } from 'react-router'

const JourneyRedirect = () => {
  const { partner } = useParamPartner()
  const history = useHistory()
  useEffect(() => {
    if (partner?.isJourney) {
      history.push(`/${partner?.slug}/journey`)
    }
  }, [partner, history])
  return <></>
}

import { useAuth, useParamPartner } from '../Providers'
import { AppBar, Button, createStyles, makeStyles, Paper, Theme, Toolbar, Typography, Link as MuiLink, Backdrop } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import PartnerTracksProvider, { usePartnerTracks } from '../Providers/PartnerTracksProvider'
import FavButton from '../users/MyConversations/FavButton'
import { AnalyticsTracker } from './AnalyticsTracker'
class PartnerPage extends Component<FIXME, FIXME> {
  state = { inviteToken: null }
  _bg_ref

  componentDidUpdate(prevProps) {
    this.setBackgroundImage()
    this.handleInviteParams()

    if (this.props.partner && this.props.partner !== prevProps.partner && this.props.partner.locale) {
      this.props.changeLanguage(this.props.partner.locale)
    }
  }

  setBackgroundImage = () => {
    const { partner } = this.props
    if (!partner) return null
    const { backgroundImage } = partner
    const { primary } = backgroundImage || {}
    if (!backgroundImage || !primary || !primary.url) return null

    if (this._bg_ref) {
      this._bg_ref.style.backgroundImage = `url(${primary.url || ''})`
    }
  }

  showSignup = debounce(() => {
    const { currentUserId, showDialog } = this.props
    if (currentUserId === null) {
      showDialog('signup', { inviteLink: true })
    }
  }, 500)

  handleInviteParams = () => {
    const { history, location, partner, currentUserId, loaded, user, showDialog } = this.props
    if (loaded) {
      const { token } = queryString.parse(location.search)
      if (!token && location.search !== '') {
        history.replace(location.pathname, null)
      }
      if (!user && token && partner) {
        showDialog('login')
        return
      }
      if (token && partner && location.search) {
        if (currentUserId && partner) {
          partner.acceptInvitation(token).then(() => {
            window.location.href = '/admin'
          })
          history.replace(location.pathname, null)
        } else {
          this.showSignup()
        }
      }
    }
  }

  render() {
    const { partnerLoading, topicsLoading, conversationsLoading, error, partner, t, topics, conversations, featuredConversationsLoading } = this.props

    if (error) {
      return <div className='alert alert-danger'>{t('partners.error', { message: error.statusText })} </div>
    }

    if (partnerLoading || isUndefined(partner)) return <PageLoadingAnimation />

    const { name, about, slug, website, facebookLink, twitterLink, instagramLink, profileImage } = partner

    const EditStyle = { marginLeft: 'calc(100% - 88px)' }

    const isTlc = slug === 'thelovablecity'

    const ManualProfileImage = () => {
      switch (slug) {
        case 'ARC':
          return <img className='img-responsive' alt='partner logo' src='https://civicdinners-manual-storage.s3.amazonaws.com/arc_logo_480.jpg' />
        case 'NetImpact':
          return <img className='img-responsive' alt='partner logo' src='https://civicdinners-manual-storage.s3.amazonaws.com/netimpact-profile-image.jpg' />
        case 'handsonatlanta':
          return <img className='img-responsive' alt='partner logo' src='https://civicdinners-manual-storage.s3.amazonaws.com/partners/handsonatlanta/profile_image.png' />
        case 'DecaturGA':
          return <img className='img-responsive' alt='partner logo' src='https://civicdinners-manual-storage.s3.amazonaws.com/partners/DecaturGA/profile_image.png' />
        case 'GA-DFCS':
          return <img className='img-responsive' alt='partner logo' src='https://civicdinners-manual-storage.s3.amazonaws.com/partners/GA-DFCS/profile_image.png' />
        case 'BCT':
          return <img className='img-responsive' alt='partner logo' src='https://civicdinners-manual-storage.s3.amazonaws.com/partners/BCT/profile_image.png' />
        case 'SDDCELL':
          return <img className='img-responsive' alt='partner logo' src='https://civicdinners-manual-storage.s3.amazonaws.com/partners/SDDCELL/profile_image.png' />
        case 'ConsciousCap':
          return <img className='img-responsive' alt='partner logo' src='https://civicdinners-manual-storage.s3.amazonaws.com/partners/ConsciousCap/profile-image_2.jpg' />
        case 'Miami-DadeTPO':
          return <img className='img-responsive' alt='partner logo' src='https://civicdinners-manual-storage.s3.amazonaws.com/partners/Miami-DadeTPO/profile-400.jpg' />
        case 'up-to-us':
          return <img className='img-responsive' alt='partner logo' src='https://civicdinners-manual-storage.s3.amazonaws.com/partners/up-to-us/profile_image.jpg' />
        case 'sage':
          return <img className='img-responsive' alt='partner logo' src='https://civicdinners-manual-storage.s3.amazonaws.com/partners/sage/profile-image.png' />
        case 'NAMI-GA':
          return <img className='img-responsive' alt='partner logo' src='https://civicdinners-manual-storage.s3.amazonaws.com/partners/NAMI-GA/profile_image.jpg' />

        default:
          return null
      }
    }

    const ProfileImage = () => {
      if (profileImage.primary.url === null) return <ManualProfileImage />
      return <img src={profileImage.primary.url} className='img-responsive' alt='partner logo' />
    }

    const FacebookLink = () => {
      if (!facebookLink || facebookLink === 'null') return null
      return (
        <div className='col-xs-2 col-xs-offset-2 col-sm-4 col-sm-offset-0 align-right'>
          <a href={facebookLink} target='_blank' rel='noopener noreferrer'>
            <i className='fab fa-facebook gold-text header-42 ' />
          </a>
        </div>
      )
    }

    const TwitterLink = () => {
      if (!twitterLink || twitterLink === 'null') return null
      return (
        <div className='col-xs-2 col-sm-4'>
          <a href={twitterLink} target='_blank' rel='noopener noreferrer'>
            <i className='fa fa-twitter gold-text header-42 ' />
          </a>
        </div>
      )
    }

    const InstagramLink = () => {
      if (!instagramLink || instagramLink === 'null') return null
      return (
        <div className='col-xs-2 col-sm-4'>
          <a href={instagramLink} target='_blank' rel='noopener noreferrer'>
            <i className='fa fa-instagram gold-text header-42 ' />
          </a>
        </div>
      )
    }

    return (
      <div className={`${slug} partners-show`}>
        <AnalyticsTracker />
        <PartnerTracksProvider>
          <JourneyRedirect />
          <CustomMetaTags record={partner} />
          <div style={{ float: 'right' }}>
            <FavButton likableId={partner.id} likableType='Partner' />
          </div>
          <section
            className='pane hero-pane yellow-pane no-gutters'
            id='background'
            ref={(ref) => {
              this._bg_ref = ref
              this.setBackgroundImage()
            }}
          >
            <div className='row pane thin-pane-sm skinny-pane-xs pane-darkened infinity-sides name-pane'>
              <div className='col-xs-12 col-sm-4 xs-hide sm-show relative'>
                <div className='partner-profile-image absolute'>
                  <ProfileImage />
                </div>
              </div>
              <div className='col-xs-12 col-sm-8'>
                <h1 className='header-28 hero-header flat-bottom'>{name}</h1>
              </div>
            </div>
          </section>
          <section className='pane clear-pane relative no-gutters'>
            <div className='row infinity-sides'>
              <div className='col-xs-12 col-sm-4 reg-column-sm minimal-grey-pane no-right-gutter-sm'>
                <StatsModule partner={partner} />
                <div className='xtra-fat-top'>
                  <div className='center-margin width-240-sm'>
                    <div className='row no-gutters xtra-fat-bottom middle-xs'>
                      <div className='col-xs-4 align-right'>
                        <i className='fal fa-browser header-24' />
                      </div>
                      <div className='col-xs-8 no-right-gutter no-left-gutter header-14'>
                        <a href={website} target='blank'>
                          {website}
                        </a>
                      </div>
                    </div>

                    <div className='row no-gutters middle-xs xtra-fat-bottom xtra-fat-bottom-padding-sm'>
                      <FacebookLink />

                      <TwitterLink />

                      <InstagramLink />
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-xs-12 col-sm-8 reg-column reg-left-padding-sm header-18 reg-pane'>
                <div className='flat-top-xs' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(about) }} />
              </div>
            </div>
          </section>

          <PartnerContent />
        </PartnerTracksProvider>
      </div>
    )
  }
}

const PartnerContent = observer(() => {
  const { partner } = useParamPartner()
  const EditStyle = { marginLeft: 'calc(100% - 88px)' }
  const { t } = useTranslation()
  const { tracks, reloadTracks, trackLoaded, trackLoading } = usePartnerTracks()
  const { user } = useAuth()
  const classes = useStyles()

  useEffect(() => {
    !trackLoaded && !trackLoading && partner && reloadTracks(partner.id)
  }, [trackLoaded, trackLoading, partner, reloadTracks])

  if (!partner || !trackLoaded || trackLoading) {
    return <></>
  }
  const topicsLoading = partner?.loadingIds.includes('topics')
  const topics = partner && Array.from(partner.topics.filter((c) => !c.hidden))
  const featuredConversationsLoading = partner && partner.loadingIds.includes('featuredConversationsList')
  const conversations = partner && Array.from(partner.conversations)
  const conversationsLoading = partner && partner.loadingIds.includes('conversations')
  const isTlc = partner.slug === 'thelovablecity'
  const featuredTopicHeader = partner.privateLabel
    ? partner.featuredTopicHeader
    : isTlc
    ? t('partners.findCity')
    : partner?.featuredConversationsTitle || t('partners.featuredConvo')
  const featuredConversationHeader = partner.privateLabel ? partner.featuredConversationHeader : t('common.conversation')
  const upcomingConversationHeader = partner.privateLabel ? partner.upcomingConversationHeader : t('user.upcomingConversations')
  const supportedByHeader = partner.privateLabel ? partner.supportedByHeader : t('convos.supportBy')
  return (
    <>
      <>
        <InfiniteLocationList
          limit={9}
          initQuery={(args) => {
            if (partner.hasMoreTopics) {
              partner.loadTopics({ scope: 'active', ...args })
            }
          }}
          query={(args) => {
            partner.loadTopics({ scope: 'active', ...args }, { add: true })
          }}
          formName={'TopicForm'}
          itemType='topic'
          itemLabel={t('common.topic')}
          header={featuredTopicHeader}
          items={topics}
          hasMoreItems={partner.hasMoreTopics}
          itemsLoading={topicsLoading}
        />

        {partner.featuredConversations && (
          <InfiniteLocationList
            limit={9}
            initQuery={(args) => {
              if (partner.hasMoreFeaturedConversations) {
                partner.loadFeaturedConversations({ partner_featured: true, ...args })
              }
            }}
            query={(args) => {
              partner.loadFeaturedConversations({ partner_featured: true, ...args }, { add: true })
            }}
            hideOnEmpty={true}
            slug={partner.slug}
            formName={'ConversationForm'}
            itemType='conversation'
            header={partner.featuredConversationsTitle}
            itemLabel={featuredConversationHeader}
            items={partner.featuredConversationsList}
            hasMoreItems={partner.hasMoreFeaturedConversations}
            itemsLoading={featuredConversationsLoading}
            featured
          />
        )}

        <span id='findaconversation' />
        <InfiniteLocationList
          limit={9}
          initQuery={(args) => {
            if (partner.hasMoreConversations) {
              partner.loadConversations({ ...(partner.featuredConversations ? { partner_featured: false } : {}), ...args })
            }
          }}
          query={(args) => {
            if (partner.hasMoreConversations) {
              partner.loadConversations({ ...(partner.featuredConversations ? { partner_featured: false } : {}), ...args }, { add: true })
            }
          }}
          slug={partner.slug}
          formName={'ConversationForm'}
          itemType='conversation'
          header={upcomingConversationHeader}
          itemLabel={window.coco ? t('common.topic') : t('common.conversation')}
          items={conversations}
          hasMoreItems={partner.hasMoreConversations}
          itemsLoading={conversationsLoading}
        />

        <Sections sectionable={partner} title={supportedByHeader} />
      </>
    </>
  )
})

export default flow(
  connectTopicTheme,
  observer,
  inject<FIXME, FIXME>(
    (
      {
        mst: {
          auth: { user, loaded },
          partners,
          partners: { timeScope, setTimeScope },
          dialogs: { showDialog }
        }
      }: { mst: IStore },
      {
        match: {
          params: { slug: rawSlug }
        }
      }
    ) => {
      const slug = rawSlug.toLowerCase()
      const partnerLoading = partners.loadingIds.includes(slug)
      const partner = partners.list.find((d) => d.slug.toLowerCase() === slug)
      if (!partner && !partnerLoading) {
        partners.loadBySlug(slug)
      }

      return {
        currentUserId: user?.id,
        user,
        loaded,
        partner,
        showDialog,
        partnerLoading,
        topics: partner && Array.from(partner.topics.filter((c) => !c.hidden)),
        topicsLoading: partner?.loadingIds.includes('topics'),
        conversations: partner && Array.from(partner.conversations),
        conversationsLoading: partner && partner.loadingIds.includes('conversations'),
        featuredConversationsLoading: partner && partner.loadingIds.includes('featuredConversationsList')
      }
    }
  ),
  withTranslation
)(PartnerPage)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      top: 'auto',
      bottom: 0,
      height: '48vh',
      background: 'linear-gradient(76.7deg, #EE413E 0%, #B32D73 55.76%, #5D3A92 100%)',
      boxShadow: '0px -2px 16px rgba(0, 0, 0, 0.1), 0px -2px 13px rgba(0, 0, 0, 0.25)'
    },
    paper: {
      maxWidth: '685px',
      textAlign: 'center',
      padding: '2rem',
      margin: '73px auto 1rem auto',
      borderRadius: '24px'
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff'
    }
  })
)
