import React, { useContext, useState } from 'react'
import Dialog from '../DialogWrapper'
import { flow, map, range, pick, pickBy, identity } from 'lodash'
import withTranslation, { WithTranslation } from '../hocs/withTranslation'
import { withRouter } from 'react-router'
import { IDialog } from '../../store/models/Dialog'
import { TextFieldControl, NpsControlGroup } from '../utils'
import { required } from '../validations/fieldLevelValidations'
import { useForm } from 'react-hook-form'
import { Button, createStyles, DialogActions, Grid, makeStyles, Typography } from '@material-ui/core'
import palette from '../../themes/palette'
import { inject, observer } from 'mobx-react'
import { IStore } from '../../store'
interface InjectedProps {
  saveNps(val): Promise<any>
  attendeeSurveyLink?: string
  showDialog(val: any, data: any)
}
interface Props {
  dialog: IDialog
  history: any
}

const Nps3Dialog = ({ saveNps, dialog, t, i18n, history, showDialog }: Props & InjectedProps & WithTranslation) => {
  const { handleSubmit, errors, formState, control } = useForm({ mode: 'onBlur', shouldUnregister: false })
  const [submitted, setSubmitted] = useState(false)
  const { score, attendeeSurveyLink } = dialog.data as any

  const saveSingleNps = (nps_question_id, npsScore) => {
    return saveNps({ ...dialog.data, nps_question_id, score: npsScore })
  }

  const nextStep = () => {
    setSubmitted(true)
    if (attendeeSurveyLink) {
      window.open(attendeeSurveyLink)
    }
  }

  const handleSave = handleSubmit(({ reason }: any, e) => {
    setSubmitted(true)
    if (reason) {
      saveNps({ ...dialog.data, nps_question_id: 9, score: 0, reason }).then(nextStep)
    } else {
      nextStep()
    }
  })

  const npsOptions = () =>
    map(range(5), (i) => {
      return { label: i + 1, value: (i + 1).toString() }
    })

  const npsOptionsData = npsOptions()

  const classes = Styles()

  const Success = () => (
    <div>
      <section className={classes.headerContainer}>
        <Typography variant='subtitle1'>{t('common.thankYou')}</Typography>
      </section>
      <section className={classes.bodyContainer2}>
        <div className={classes.imgContainer}>
          <img className='img-responsive' src='https://civicdinners-manual-storage.s3.amazonaws.com/celebrate+icon.png' />
        </div>
        <Typography variant='h6'>{t('nps.successHeader')}</Typography>
      </section>
      {attendeeSurveyLink && (
        <section className={classes.actionContainer2}>
          <Typography variant='body2'>{t('nps.successCaption1')}</Typography>
          <Typography variant='body2'>{t('nps.successCaption2')}</Typography>
          <a href={attendeeSurveyLink || '#'} target='_blank' rel='noopener noreferrer'>
            <Button type='submit' variant='contained' color='primary' fullWidth className={classes.submit}>
              {t('nps.takeSurvey')}
            </Button>
          </a>
        </section>
      )}
    </div>
  )

  const surveyOptions = [
    { nps_question_id: 7, label: 'I would recommend this topic' },
    { nps_question_id: 8, label: 'I would recommend this conversation host' }
  ]

  return (
    <Dialog dialog={dialog} name='nps3' type={'wide'} closeIcon={true}>
      {submitted ? (
        <Success />
      ) : (
        <form onSubmit={handleSave}>
          <div>
            <section className={classes.headerContainer}>
              <Typography variant='subtitle1'>{t('nps.dialogHeader')}</Typography>
            </section>
            <section className={classes.bodyContainer}>
              <Typography variant='body1'>
                Two final questions are about the topic and your host. How much do you agree with the following statements, on a scale of 1 - Strongly Disagree to 5 - Strongly
                Agree?
              </Typography>
            </section>
            <section className={classes.actionContainer}>
              {surveyOptions.map((opt, index) => (
                <Grid container key={opt.nps_question_id} spacing={1}>
                  <Grid item xs={1}>
                    {index + 1}.
                  </Grid>
                  <Grid item xs={5}>
                    {opt.label}
                  </Grid>
                  <Grid item xs={6}>
                    <NpsControlGroup
                      defaultValue={score}
                      name={`score-${index}`}
                      control={control}
                      required
                      error={errors && errors.score}
                      rules={{ validate: required }}
                      options={npsOptionsData}
                      onChange={(val) => saveSingleNps(opt.nps_question_id, val)}
                    />
                  </Grid>
                </Grid>
              ))}

              <Grid container className='reg-top'>
                <Grid item xs={12}>
                  Any feedback you’d like to share with your host/facilitator?
                </Grid>
                <Grid item xs={12}>
                  <TextFieldControl name='reason' control={control} multiline={true} rows={3} />
                </Grid>
              </Grid>
            </section>
            <DialogActions>
              <Button type='submit' variant='contained' color='primary'>
                {t('common.submit')}
              </Button>
            </DialogActions>
          </div>
        </form>
      )}
    </Dialog>
  )
}

const Styles = makeStyles((theme) =>
  createStyles({
    root: {},
    headerContainer: {
      padding: '.5rem',
      textAlign: 'center'
    },
    label: {
      marginTop: '1rem'
    },
    bodyContainer: {
      textAlign: 'center',
      padding: '2rem .5rem'
    },
    nps: {
      padding: '1rem 1rem 0',
      position: 'relative'
    },
    npsKey1: {
      position: 'absolute',
      left: '2.4rem',
      bottom: '-1rem'
    },
    npsKey2: {
      position: 'absolute',
      right: '2.4rem',
      bottom: '-1rem'
    },
    actionContainer: { backgroundColor: palette.grey100, padding: '3rem' },
    submit: { marginTop: '2rem' },
    actionContainer2: { padding: '3rem', textAlign: 'center' },
    bodyContainer2: { backgroundColor: palette.grey100, textAlign: 'center', padding: '2rem 6rem' },
    imgContainer: { width: '56px', textAlign: 'center', margin: '0 auto 1rem' }
  })
)

export default flow(
  observer,
  inject<InjectedProps, null>(
    ({
      mst: {
        nps: { saveNps },
        dialogs: { showDialog }
      }
    }: {
      mst: IStore
    }) => {
      return {
        saveNps,
        showDialog
      }
    }
  ),
  withTranslation
)(Nps3Dialog)
