import * as types from '../actions/actionTypes'
import initialState from './initialState'
import { assign } from 'lodash'

export const userCoords = (state = initialState.userCoords, action) => {
  switch (action.type) {
    case types.LOAD_USER_COORDS_FAILED:
      return assign({}, state, {
        error: action.data,
        loading: false
      })

    case types.USER_COORDS_LOADING:
      return assign({}, state, {
        error: null,
        loading: true
      })

    case types.LOAD_USER_COORDS_SUCCESS:
      return assign({}, state, {
        error: null,
        loading: false,
        item: action.userCoords
      })

    default:
      return state
  }
}
