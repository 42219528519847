import React, { useState, CSSProperties } from 'react'

export const ImagePreview = ({ img }) => {
  const [open, setOpen] = useState(false)
  if (!img) return null

  const imgStyle: CSSProperties = open
    ? {
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'fill',
        border: '1px solid #ccc'
      }
    : {
        width: '100px',
        height: '100px',
        objectFit: 'contain',
        border: '1px solid #ccc'
      }

  return (
    img.url && (
      <div style={{ margin: '.5em 0' }} onClick={() => setOpen(!open)}>
        <img src={img.url} alt='Private Label Email Header Image' style={imgStyle} />
      </div>
    )
  )
}
