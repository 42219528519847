import { Tab, Tabs } from '@material-ui/core'
import { flow } from 'lodash'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { IStore, ITopic } from '../../store'
import TopicSubscribersTable from '../admin/v2/Topics/TopicSubscribersTable'

type Props = {
  topic: ITopic
}

interface InjectedProps {
  users_total: number

  topicId?: number

  loadFilter(arg?: FIXME)
  setSortedPriority(val: boolean)
  setSortingId(val: string)
  setTopicId(val: number | undefined)
}

const TopicSubscribers = ({ topic, setSortingId, setSortedPriority, loadFilter, setTopicId, topicId }: Props & InjectedProps) => {
  useEffect(() => {
    if (topic) {
      setSortingId('id')
      setSortedPriority(true)
      setTopicId(topic.id)
      loadFilter({})
    }
    return () => {
      setTopicId(undefined)
      setSortingId('id')
      setSortedPriority(true)
      loadFilter({ reset: true, clear: true })
    }
  }, [topic, setTopicId, setSortedPriority, setSortingId, loadFilter])

  return <div>{topic && topicId && <TopicSubscribersTable topic={topic} height='calc(100vh - 32em)' noDataMsg={'No subscribers for this topic'} />}</div>
}
// export default TopicSubscribers
export default flow(
  observer,
  inject<InjectedProps, Props>(
    ({
      mst: {
        topic_subscribers: { loadFilter, setSortedPriority, setSortingId, setTopicId, topicId, list }
      }
    }: {
      mst: IStore
    }) => {
      return {
        loadFilter,
        users_total: list.length,
        setSortedPriority,
        setSortingId,
        setTopicId,
        topicId
      }
    }
  )
)(TopicSubscribers)
