import { Card, createStyles, makeStyles } from '@material-ui/core'
import React, { ReactElement, useContext } from 'react'
import { useHistory } from 'react-router'
import { DialogContext } from '../../Providers/DialogProvider'
import Signup1Form from './Signup1Form'

interface Props {}

export default function Signup1Page(props: Props): ReactElement {
  const history = useHistory()
  const classes = Styles()
  const { showDialog } = useContext(DialogContext)
  return (
    <Card className={classes.root}>
      <Signup1Form
        onLogin={() => {
          history.push('/login')
        }}
        onSignUp={() => {
          history.push('/login')
          !window.coco && showDialog('signup2')
        }}
      />
    </Card>
  )
}
const Styles = makeStyles((theme) =>
  createStyles({
    root: {
      maxWidth: '600px',
      margin: '10em auto'
    }
  })
)
