import { createSelector } from 'reselect'
import { getConversations, getHostedConversations } from './conversationGetters'

const getCurrentConversationId = (state, ownProps) => ownProps.conversationId || ownProps.match.params.conversationId

export const getCurrentConversation = createSelector(
  getConversations,
  getHostedConversations,
  getCurrentConversationId,
  (conversations, hostedConversations, conversationId) => conversations[conversationId] || hostedConversations[conversationId]
)
