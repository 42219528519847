import React, { Component } from 'react'
import { Grid } from '@material-ui/core'
import { ReactTitle } from 'react-meta-tags'
import withTranslation from '../hocs/withTranslation'

class PrivacyPolicy extends Component {
  render() {
    const { t } = this.props
    const linkClass = 'link-text'

    const TableOne = () => (
      <table
        className='basic-table'
        dangerouslySetInnerHTML={{
          __html: t('privacy.table1')
        }}
      />
    )

    const TableTwo = () => (
      <table
        className='basic-table'
        dangerouslySetInnerHTML={{
          __html: t('privacy.table2', { linkClass })
        }}
      />
    )

    return (
      <div className='decimal-list'>
        <ReactTitle title={t('privacy.h1')} />

        <section className='pane fat-pane'>
          <Grid container justify='center'>
            <Grid item md={8}>
              <div className='WordSection1'>
                <p className='MsoNormal' align='center' style={{ textAlign: 'center', lineHeight: 'normal' }}>
                  <b>
                    <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                      INCLUSIVV
                    </span>
                  </b>
                </p>
                <p className='MsoNormal' align='center' style={{ textAlign: 'center', lineHeight: 'normal' }}>
                  <b>
                    <u>
                      <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                        <span style={{ textDecoration: 'none' }}>&nbsp;</span>
                      </span>
                    </u>
                  </b>
                </p>
                <p className='MsoNormal' align='center' style={{ textAlign: 'center', lineHeight: 'normal' }}>
                  <b>
                    <u>
                      <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                        PRIVACY POLICY
                      </span>
                    </u>
                  </b>
                </p>
                <p className='MsoNormal' align='center' style={{ textAlign: 'center', lineHeight: 'normal' }}>
                  <b>
                    <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                      &nbsp;
                    </span>
                  </b>
                </p>
                <p className='MsoNormal' align='center' style={{ textAlign: 'center', lineHeight: 'normal' }}>
                  <b>
                    <i>
                      <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                        Effective as of Jan 26, 2022
                      </span>
                    </i>
                  </b>
                </p>
                <p className='MsoNormal' style={{ lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Civic Dinners, Inc. d/b/a Inclusivv a Delaware public benefit corporation (“
                    <b>
                      <i>Inclusivv</i>
                    </b>
                    ”, “
                    <b>
                      <i>we</i>
                    </b>
                    ”, “
                    <b>
                      <i>us</i>
                    </b>
                    ” or “
                    <b>
                      <i>our</i>
                    </b>
                    ”) is committed to protecting your (“
                    <b>
                      <i>you</i>
                    </b>
                    ” or “
                    <b>
                      <i>your</i>
                    </b>
                    ”) privacy and empowering you with this privacy policy (this “
                    <b>
                      <i>Privacy Policy</i>
                    </b>
                    ”). This Privacy Policy explains how we collect, use, disclose, and apply the information collected when you use or access our online website (
                  </span>
                  <span lang='EN'>
                    <a href='https://www.inclusivv.co/'>
                      <span style={{ fontFamily: '"Times New Roman",serif', color: '#0563C1' }}>https://www.inclusivv.co/</span>
                    </a>
                  </span>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    ), products, services, or applications that link to this Privacy Policy (collectively, the “
                    <b>
                      <i>Services</i>
                    </b>
                    ”). Our Terms of Service (the “
                    <b>
                      <i>Terms</i>
                    </b>
                    ”) are expressly incorporated herein by reference and are an integral part hereof. The Terms can be found here:
                  </span>
                  <span lang='EN'>
                    <a href='https://www.inclusivv.co/terms-of-service'>
                      <span style={{ fontFamily: '"Times New Roman",serif', color: '#1155CC' }}>https://www.inclusivv.co/terms-of-service</span>
                    </a>
                  </span>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    .
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; By using the Services or interacting with us, you are agreeing to this Privacy Policy. Please read the
                    following carefully to understand how we collect, use, disclose, and maintain information that can be used to identify you. In addition, this Privacy Policy
                    describes your choices for use, access, and correction of your personal data. If you do not agree to be bound by this Privacy Policy, please stop using the
                    Services.
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <b>
                    <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                      1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <u>Changes to this Privacy Policy</u>
                    </span>
                  </b>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    . We may change this Privacy Policy from time to time. Laws, regulations, and industry standards evolve, which may make those changes necessary, or we may make
                    changes to our Services. We will post the changes to this page and encourage you to review this Privacy Policy to stay informed. If we make changes that
                    materially alter your privacy rights, we may provide additional notice, such as via email or through the Services. If you disagree with the changes to this
                    Privacy Policy, you should deactivate your Services account.
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <b>
                    <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                      2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <u>Information We Collect and Receive</u>
                    </span>
                  </b>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    . We collect your information in a variety of ways when you interact with&nbsp;the Services. We collect information about you when you create an account and use
                    the Services and we do so in order to deliver the Services to you, to help improve your experience, and to support our business functions. More information
                    about the categories and sources of information is provided below.
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', textIndent: '.5in', lineHeight: 'normal' }}>
                  <b>
                    <i>
                      <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                        2.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Information You Provided to Us
                      </span>
                    </i>
                  </b>
                  <i>
                    <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                      .
                    </span>
                  </i>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    We collect information that you decide to share with us. At times, we may require you to provide certain information in order to use certain parts of our
                    Services, fulfill your requests, or provide you with certain Services.
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <i>
                    <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      2.1.1&nbsp;&nbsp;&nbsp;
                      <u>Account Information</u>
                    </span>
                  </i>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    . If you establish an account with us, we may require you to provide your name, email address, phone number, payment information, a password and information you
                    disclose in your user profile and your photograph. All payment data is stored by Stripe, Inc., our third-party payment processor, and you should review its
                    privacy policies.
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <i>
                      2.1.2&nbsp;&nbsp;&nbsp; <u>Content</u>
                    </i>
                    . We collect and store content that you post, send, receive and share through the Services.
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <i>
                      2.1.3&nbsp;&nbsp;&nbsp; <u>Contacting Us</u>
                    </i>
                    . You also may choose to send us information about yourself in an email containing inquiries about the Services and we use this information in order to help us
                    respond to your inquiry. You may also choose to submit information to us via other methods, including: (i) in response to marketing or other communications,
                    (ii) through social media or online forums, or (iii) in connection with an actual or potential business relationship with us.
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <i>
                    <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      2.1.4&nbsp;&nbsp;&nbsp;
                      <u>Surveys</u>
                    </span>
                  </i>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    . We may contact you to participate in surveys. If you decide to participate, you may be asked to provide certain information which may include personal data.
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <b>
                      <i>2.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Information We Obtain from Others</i>
                    </b>
                    .<i> </i>If you choose to log in to the Services through a social network (such as Facebook) or other third-party platform or otherwise connect your account on
                    a third-party platform to your account through the Services, we may collect personal data about you from that platform or network. For example, this information
                    may include your username, email address and other contact information, and your affiliations. Further, sometimes other users of the Services may provide us
                    information about you, including through customer service inquiries. We may also collect information about you from other publicly available sources.
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <b>
                    <u>
                      <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                        <span style={{ textDecoration: 'none' }}>&nbsp;</span>
                      </span>
                    </u>
                  </b>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <b>
                    <i>
                      <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 2.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Information We Automatically Collect
                      </span>
                    </i>
                  </b>
                  <i>
                    <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                      .
                    </span>
                  </i>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    We automatically collect certain information from you when you use the Services, including internet or other network activity information such as your IP
                    address, geolocation-related data (as described below), unique device identifiers, browsing and search history (including content you have viewed in the
                    Services), and Cookies (as defined below).
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <b>
                    <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                      &nbsp;
                    </span>
                  </b>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <i>
                    <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      2.3.1&nbsp;&nbsp;&nbsp;
                      <u>Log information</u>
                    </span>
                  </i>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    . We retain information about you when you access and use the Services. This information can include the following: Internet Protocol (“
                    <b>
                      <i>IP</i>
                    </b>
                    ”) address, timestamps, browser information, Internet Service Provider “
                    <b>
                      <i>ISP</i>
                    </b>
                    ”, webpages visited, and the URL of the webpage you visited before navigating to our Services.
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <i>
                    <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.3.2&nbsp;&nbsp;&nbsp;
                      <u>Usage Information</u>
                    </span>
                  </i>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    . We monitor user activity in connection with the Services and may collect information about the applications and features you use, websites you visit, as well
                    as types and amount of the Services you use.
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <i>
                      2.3.3&nbsp;&nbsp;&nbsp; <u>Location information</u>
                    </i>
                    . We receive information from you, your Customer and other third-parties that helps us approximate your location. We may, for example, use a business address
                    submitted by your employer, or an IP address received from your browser or device to determine approximate location. We may also collect location information
                    from devices in accordance with the consent process provided by your device.
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <i>
                    <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                      &nbsp;
                    </span>
                  </i>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <i>
                    <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      2.3.4&nbsp;&nbsp;&nbsp;
                      <u>Crash and Error Information</u>
                    </span>
                  </i>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    . If the Services crash or return an error, we may collect data to determine the cause of the error using first or third party services. The crash or error
                    information collected may include the following: Device IP address, device name, operating system version, application configurations(s), the time and date, and
                    other statistics.
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <i>
                    <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      2.3.5&nbsp;&nbsp;&nbsp;
                      <u>Cookies and Other Technologies</u>
                    </span>
                  </i>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    . Cookies and similar technologies (e.g. pixels and ad tags) (collectively, “
                    <b>
                      <i>Cookies</i>
                    </b>
                    ”) are small files which, when placed on your device, enable us to collect certain information, including personal data, from you in order to provide certain
                    features and functionality. We and our service providers and business partners use Cookies to collect data and recognize you and your device(s) on the Services
                    and elsewhere across your different devices. We do this to better understand the effectiveness of the advertising on the Services and to enhance your user
                    experience. To learn more about cookies please see our&nbsp;
                  </span>
                  <span lang='EN'>
                    <a href='https://www.civicdinners.com/cookie_policy'>
                      <span style={{ fontFamily: '"Times New Roman",serif', color: '#0563C1' }}>Cookies Policy</span>
                    </a>
                  </span>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    .&nbsp;&nbsp;
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <b>
                    <u>
                      <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                        <span style={{ textDecoration: 'none' }}>&nbsp;</span>
                      </span>
                    </u>
                  </b>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <b>
                    <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                      3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <u>How We Use Your Information</u>
                    </span>
                  </b>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    .
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', textIndent: '.5in', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; We may use the personal data that we collect for our legitimate interests and the limited purpose of providing the
                    Services and related functionality and services, as described in the Terms and in this Privacy Policy, and as permitted by applicable laws. These purposes
                    include circumstances where it is necessary to provide or fulfill the Services requested by or for you or where you have given us your express consent. We may
                    use your personal data to:
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p
                  className='MsoNormal'
                  style={{
                    marginLeft: '1.5in',
                    textAlign: 'justify',
                    textIndent: '-.5in',
                    lineHeight: 'normal'
                  }}
                >
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    (i)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Provide the information, products, and services you request;
                  </span>
                </p>
                <p
                  className='MsoNormal'
                  style={{
                    marginLeft: '1.5in',
                    textAlign: 'justify',
                    textIndent: '-.5in',
                    lineHeight: 'normal'
                  }}
                >
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p
                  className='MsoNormal'
                  style={{
                    marginLeft: '1.5in',
                    textAlign: 'justify',
                    textIndent: '-.5in',
                    lineHeight: 'normal'
                  }}
                >
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    (ii)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Enable us to provide hosts with information to build diverse tables if you consent.
                  </span>
                </p>
                <p
                  className='MsoNormal'
                  style={{
                    marginLeft: '1.5in',
                    textAlign: 'justify',
                    textIndent: '-.5in',
                    lineHeight: 'normal'
                  }}
                >
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p
                  className='MsoNormal'
                  style={{
                    marginLeft: '1.5in',
                    textAlign: 'justify',
                    textIndent: '-.5in',
                    lineHeight: 'normal'
                  }}
                >
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    (iii)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Display to other users on the site to provide for trust and familiarity.
                  </span>
                </p>
                <p
                  className='MsoNormal'
                  style={{
                    marginLeft: '1.5in',
                    textAlign: 'justify',
                    textIndent: '-.5in',
                    lineHeight: 'normal'
                  }}
                >
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p
                  className='MsoNormal'
                  style={{
                    marginLeft: '1.5in',
                    textAlign: 'justify',
                    textIndent: '-.5in',
                    lineHeight: 'normal'
                  }}
                >
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    (iv)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Allow our partners to determine if you’re qualified to participate in a conversation, and provide them with data useful
                    to determine the conversations’ benefit and reach.
                  </span>
                </p>
                <p
                  className='MsoNormal'
                  style={{
                    marginLeft: '1.5in',
                    textAlign: 'justify',
                    textIndent: '-.5in',
                    lineHeight: 'normal'
                  }}
                >
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p
                  className='MsoNormal'
                  style={{
                    marginLeft: '1.5in',
                    textAlign: 'justify',
                    textIndent: '-.5in',
                    lineHeight: 'normal'
                  }}
                >
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    (iv)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Service your account and provide you, if you are a registered user, with effective customer service;
                  </span>
                </p>
                <p
                  className='MsoNormal'
                  style={{
                    marginLeft: '1.5in',
                    textAlign: 'justify',
                    textIndent: '-.5in',
                    lineHeight: 'normal'
                  }}
                >
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p
                  className='MsoNormal'
                  style={{
                    marginLeft: '1.5in',
                    textAlign: 'justify',
                    textIndent: '-.5in',
                    lineHeight: 'normal'
                  }}
                >
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    (v)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Better understand your needs and interests, and provide you with a personalized experience when you use our
                    Services;
                  </span>
                </p>
                <p
                  className='MsoNormal'
                  style={{
                    marginLeft: '1.5in',
                    textAlign: 'justify',
                    textIndent: '-.5in',
                    lineHeight: 'normal'
                  }}
                >
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p
                  className='MsoNormal'
                  style={{
                    marginLeft: '1.5in',
                    textAlign: 'justify',
                    textIndent: '-.5in',
                    lineHeight: 'normal'
                  }}
                >
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    (vi)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Contact you with special offers and other information we believe will be of interest to you (in accordance with any
                    privacy preferences you have expressed to us);
                  </span>
                </p>
                <p
                  className='MsoNormal'
                  style={{
                    marginLeft: '1.5in',
                    textAlign: 'justify',
                    textIndent: '-.5in',
                    lineHeight: 'normal'
                  }}
                >
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p
                  className='MsoNormal'
                  style={{
                    marginLeft: '1.5in',
                    textAlign: 'justify',
                    textIndent: '-.5in',
                    lineHeight: 'normal'
                  }}
                >
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    (vii)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Contact you with information and notices related to your use of our Services;
                  </span>
                </p>
                <p
                  className='MsoNormal'
                  style={{
                    marginLeft: '1.5in',
                    textAlign: 'justify',
                    textIndent: '-.5in',
                    lineHeight: 'normal'
                  }}
                >
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p
                  className='MsoNormal'
                  style={{
                    marginLeft: '1.5in',
                    textAlign: 'justify',
                    textIndent: '-.5in',
                    lineHeight: 'normal'
                  }}
                >
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    (viii)&nbsp;&nbsp;&nbsp;&nbsp; Invite you to participate in surveys and provide feedback to us (in accordance with any privacy preferences you have expressed to
                    us);
                  </span>
                </p>
                <p
                  className='MsoNormal'
                  style={{
                    marginLeft: '1.5in',
                    textAlign: 'justify',
                    textIndent: '-.5in',
                    lineHeight: 'normal'
                  }}
                >
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p
                  className='MsoNormal'
                  style={{
                    marginLeft: '1.5in',
                    textAlign: 'justify',
                    textIndent: '-.5in',
                    lineHeight: 'normal'
                  }}
                >
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    (ix)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Improve our products and service offerings and develop new products and service offerings, improve our marketing and
                    promotional efforts, and improve the content, functionality, and usability of the Services;
                  </span>
                </p>
                <p
                  className='MsoNormal'
                  style={{
                    marginLeft: '1.5in',
                    textAlign: 'justify',
                    textIndent: '-.5in',
                    lineHeight: 'normal'
                  }}
                >
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p
                  className='MsoNormal'
                  style={{
                    marginLeft: '1.5in',
                    textAlign: 'justify',
                    textIndent: '-.5in',
                    lineHeight: 'normal'
                  }}
                >
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    (x)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Enforce our Terms;
                  </span>
                </p>
                <p
                  className='MsoNormal'
                  style={{
                    marginLeft: '1.5in',
                    textAlign: 'justify',
                    textIndent: '-.5in',
                    lineHeight: 'normal'
                  }}
                >
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p
                  className='MsoNormal'
                  style={{
                    marginLeft: '1.5in',
                    textAlign: 'justify',
                    textIndent: '-.5in',
                    lineHeight: 'normal'
                  }}
                >
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    (xi)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Promote security and protect against and prevent fraud, claims, and other liabilities;
                  </span>
                </p>
                <p
                  className='MsoNormal'
                  style={{
                    marginLeft: '1.5in',
                    textAlign: 'justify',
                    textIndent: '-.5in',
                    lineHeight: 'normal'
                  }}
                >
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p
                  className='MsoNormal'
                  style={{
                    marginLeft: '1.5in',
                    textAlign: 'justify',
                    textIndent: '-.5in',
                    lineHeight: 'normal'
                  }}
                >
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    (xii)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Verify the information you provide to us as well as the representations and warranties you make to us in the Terms or on the
                    Services;
                  </span>
                </p>
                <p
                  className='MsoNormal'
                  style={{
                    marginLeft: '1.5in',
                    textAlign: 'justify',
                    textIndent: '-.5in',
                    lineHeight: 'normal'
                  }}
                >
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p
                  className='MsoNormal'
                  style={{
                    marginLeft: '1.5in',
                    textAlign: 'justify',
                    textIndent: '-.5in',
                    lineHeight: 'normal'
                  }}
                >
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    (xiii)&nbsp;&nbsp;&nbsp;&nbsp; Meet our internal and external audit obligations;
                  </span>
                </p>
                <p
                  className='MsoNormal'
                  style={{
                    marginLeft: '1.5in',
                    textAlign: 'justify',
                    textIndent: '-.5in',
                    lineHeight: 'normal'
                  }}
                >
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p
                  className='MsoNormal'
                  style={{
                    marginLeft: '1.5in',
                    textAlign: 'justify',
                    textIndent: '-.5in',
                    lineHeight: 'normal'
                  }}
                >
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    (xiv)&nbsp;&nbsp;&nbsp;&nbsp; Evaluate your interest in employment and contact you regarding possible employment with us;
                  </span>
                </p>
                <p
                  className='MsoNormal'
                  style={{
                    marginLeft: '1.5in',
                    textAlign: 'justify',
                    textIndent: '-.5in',
                    lineHeight: 'normal'
                  }}
                >
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p
                  className='MsoNormal'
                  style={{
                    marginLeft: '1.5in',
                    textAlign: 'justify',
                    textIndent: '-.5in',
                    lineHeight: 'normal'
                  }}
                >
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    (xv)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Help you, if you are one of our employees, perform your job functions and to manage our workforce; and
                  </span>
                </p>
                <p
                  className='MsoNormal'
                  style={{
                    marginLeft: '1.5in',
                    textAlign: 'justify',
                    textIndent: '-.5in',
                    lineHeight: 'normal'
                  }}
                >
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p
                  className='MsoNormal'
                  style={{
                    marginLeft: '1.5in',
                    textAlign: 'justify',
                    textIndent: '-.5in',
                    lineHeight: 'normal'
                  }}
                >
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    (xvi)&nbsp;&nbsp;&nbsp;&nbsp; Use for any other purpose for which we provide notice at the time of collection.
                  </span>
                </p>
                <p
                  className='MsoNormal'
                  style={{
                    textAlign: 'justify',
                    textIndent: '-27.0pt',
                    lineHeight: 'normal'
                  }}
                >
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', textIndent: '.5in', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; We may aggregate and/or de-identify information collected through the Services and from other sources so that such
                    information can no longer be linked to you or your device (“
                    <b>
                      <i>Aggregate/De-Identified Information</i>
                    </b>
                    ”). We may use Aggregate/De-Identified Information for any purpose, including, without limitation, for research and marketing purposes
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <b>
                    <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                      4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <u>How We Share and Disclose Information</u>
                    </span>
                  </b>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    .
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <b>
                    <u>
                      <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                        <span style={{ textDecoration: 'none' }}>&nbsp;</span>
                      </span>
                    </u>
                  </b>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <b>
                    <i>
                      <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 4.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Service Providers
                      </span>
                    </i>
                  </b>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    .&nbsp;We provide access to or share your personal data with select third parties who perform services on our behalf. They have access to perform these services
                    but are prohibited from using your personal data for other purposes. They provide a variety of services to us, including, for example, billing, sales,
                    marketing, product content and features, advertising, analytics, research, customer service, data storage, security, fraud prevention, payment processing, and
                    legal services.
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <b>
                      <i>4.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Project Partners</i>
                    </b>
                    . These are organizations who we work with on for various Inclusivv projects and conversations - they fund projects and help us to deliver them in each project
                    area. We share general information about you including survey responses that you and other users provide in response to surveys that we carry out.
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <b>
                      <i>4.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Corporate Affiliates</i>
                    </b>
                    .<b>&nbsp;</b>We may share your personal data with our corporate affiliates, parents and/or subsidiaries.
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <b>
                      <i>4.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Your Consent</i>
                    </b>
                    . We may share your personal data for other purposes pursuant to your consent or with your further direction. If you access third-party services, such as
                    Facebook, to login to the Services, these third-party services may be able to collect your personal data, including information about your activity on the
                    Services, in accordance with their privacy policies.
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <b>
                    <i>
                      <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                        &nbsp;
                      </span>
                    </i>
                  </b>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <b>
                    <i>
                      <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 4.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Aggregate/De-Identified Data
                      </span>
                    </i>
                  </b>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    . From time to time, we may share Aggregate/De-Identified Information about use of the Services, such as by publishing a report on usage trends. The sharing of
                    such data is unrestricted.
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <b>
                    <i>
                      <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 4.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Legal Reasons
                      </span>
                    </i>
                  </b>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    . We may also disclose your personal data when we, in good faith, believe disclosure is appropriate to comply with the law, a court order, or a subpoena. We may
                    also disclose your personal data to prevent or investigate a possible crime, such as fraud or identity theft; to protect the security of our Services; to
                    enforce or apply our policies or other agreements; or to protect our own rights or property or the rights, property or safety of our users or others. We will
                    attempt to notify our users about legal demands for their personal data when appropriate in our judgment unless prohibited by law or court order or when the
                    request is an emergency. We may dispute such demands when we believe, in our discretion, that the requests are overbroad, vague, or lack proper authority.
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <b>
                    <i>
                      <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                        &nbsp;
                      </span>
                    </i>
                  </b>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <b>
                    <i>
                      <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 4.7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Sale, Merger, or Other Business Transfer
                      </span>
                    </i>
                  </b>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    . As we continue to develop our business, we may buy, merge, or partner with other companies. In such transactions (including in contemplation of such
                    transactions), personal data may be among the transferred assets. If a portion or all of our assets are sold or transferred to a third-party, your personal data
                    would likely be one of the transferred business assets. If such transfer is subject to additional mandatory restrictions under applicable laws, we will comply
                    with such restrictions.
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <b>
                    <u>
                      <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                        <span style={{ textDecoration: 'none' }}>&nbsp;</span>
                      </span>
                    </u>
                  </b>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <b>
                    <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                      5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <u>Your Choices</u>
                    </span>
                  </b>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    .
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', textIndent: '.5in', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; If you have registered for an account, you may access, review, and update certain personal data that you have
                    provided to us by logging into your account and using available features and functionalities or by contacting us in accordance with the “Contact Us” section
                    below.
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', textIndent: '.5in', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', textIndent: '.5in', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Most web browsers are set to accept Cookies by default. If you prefer, you can usually set your browser to remove
                    or reject Cookies. Please note that if you choose to remove or reject Cookies, this could affect the availability and functionality of our Services. For more
                    information, please see our&nbsp;
                  </span>
                  <span lang='EN'>
                    <a href='https://www.civicdinners.com/cookie_policy'>
                      <span style={{ fontFamily: '"Times New Roman",serif', color: '#0563C1' }}>Cookies Policy</span>
                    </a>
                  </span>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    .&nbsp;&nbsp;
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', textIndent: '.5in', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', textIndent: '.5in', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    (c)&nbsp;&nbsp;&nbsp;&nbsp; You have a choice at any time to stop us from sending you emails for marketing purposes by following the “unsubscribe” link included
                    in these messages. Please note that despite any indicated email marketing preferences, we may continue to send you administrative emails regarding Inclusivv,
                    including, for example, notices of updates to our Terms or this Privacy Policy, if we choose to provide such notices to you in this manner.
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', textIndent: '.5in', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; If you do not provide the personal data that we
                    need to provide the Services, we may not be able to provide with the Services or certain functionalities. We will tell you what personal data that you must
                    provide in order to receive the Services.
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (e)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; If you choose to connect to the Services
                    through your social media account or another third-party platform, you may be able to use your settings in your account with that platform to limit the
                    information we receive from it. If you revoke our ability to access information from a third-party platform, that choice will not apply to information that we
                    have already received from that third party.
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', textIndent: '.5in', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', textIndent: '.5in', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    (f)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Some browsers offer a “do not track” (“
                    <b>
                      <i>DNT</i>
                    </b>
                    ”) option. Since no common industry or legal standard for DNT has been adopted by industry groups, technology companies, or regulators, we do not respond to DNT
                    signals. We will make efforts to continue to monitor developments around DNT browser technology and the implementation of a standard.
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <b>
                    <u>
                      <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                        <span style={{ textDecoration: 'none' }}>&nbsp;</span>
                      </span>
                    </u>
                  </b>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <b>
                    <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                      6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <u>Security</u>
                    </span>
                  </b>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    . We take reasonable precautions intended to help protect your personal data that we collect. Unfortunately, no system or online transmission of data is
                    completely secure, and we cannot guarantee the security of data transmitted across the Internet. If you believe that information you provided to us is no longer
                    secure, please notify us immediately using the contact information provided below.
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <b>
                    <u>
                      <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                        <span style={{ textDecoration: 'none' }}>&nbsp;</span>
                      </span>
                    </u>
                  </b>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <b>
                    <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                      7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <u>European Residents</u>
                    </span>
                  </b>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    . If you are a resident of the European Economic Area, we rely on our legitimate interest as described in this Privacy Policy to process your personal data.
                    Additionally, subject to any exemptions as provided by law, you may have certain rights regarding the personal data we maintain about you. We offer you certain
                    choices about what personal data we collect from you, how we use that information, and how we communicate with you. If at any time you wish to exercise your
                    rights, please reach out to&nbsp;us in accordance with the “Contact Us” section below.
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <b>
                      <i>7.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Right of Access</i>
                    </b>
                    . If you ask us, we will confirm whether we are processing your personal data and, if so, provide you with a copy of all personal data you are lawfully entitled
                    to receive along with certain other details. If you require additional copies, we may need to charge a reasonable fee.
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <b>
                    <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                      &nbsp;
                    </span>
                  </b>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <b>
                    <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <i>7.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Right to Rectification</i>
                    </span>
                  </b>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    . If your personal data is inaccurate or incomplete, you are entitled to ask that we correct or complete it.
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <b>
                    <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                      &nbsp;
                    </span>
                  </b>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <b>
                    <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <i>7.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Right to Erasure</i>
                    </span>
                  </b>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    . You may request deletion of your personal data. We may ask you for additional information to verify your identity and will process your request subject to and
                    in accordance with the law.
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <b>
                      <i>7.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Right to Restrict Processing</i>
                    </b>
                    . You may request that we temporarily or permanently stop processing all or some of your personal data.
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <b>
                      <i>7.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Right to Object</i>
                    </b>
                    . You may object to us processing your personal data on grounds relating to your particular situation and object to your personal data being processed for
                    direct marketing purposes.
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <b>
                    <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                      &nbsp;
                    </span>
                  </b>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <b>
                    <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <i>7.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Right to Data Portability</i>
                    </span>
                  </b>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    . You have the right to obtain your personal data from us that you consented to give us or that was provided to us as necessary in connection with our contract
                    with you, and that is processed by us by automated means. We will give you your personal data in a structured, commonly used and machine-readable format.
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <b>
                      <i>7.7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Right to Withdraw Consent</i>
                    </b>
                    . If we rely on your consent to process your personal data, you have the right to withdraw that consent at any time. Withdrawal of consent will not affect any
                    processing of your data before we received notice that you wished to withdraw consent.
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <b>
                    <i>
                      <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 7.8&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Right to Lodge a Complaint
                      </span>
                    </i>
                  </b>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    . If you are in the EEA or Switzerland, you can lodge a complaint regarding how we collect and process your personal data to your local Data Protection
                    Authority, or if you are in the United Kingdom, the
                  </span>
                  <span lang='EN'>
                    <a href='https://ico.org.uk/'>
                      <span style={{ fontFamily: '"Times New Roman",serif', color: '#0563C1' }}>Information Commissioner’s Office</span>
                    </a>
                  </span>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    .
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <b>
                    <u>
                      <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                        <span style={{ textDecoration: 'none' }}>&nbsp;</span>
                      </span>
                    </u>
                  </b>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <b>
                    <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                      8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <u>California Residents</u>
                    </span>
                  </b>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    .
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <b>
                    <u>
                      <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                        <span style={{ textDecoration: 'none' }}>&nbsp;</span>
                      </span>
                    </u>
                  </b>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; If you are a resident of the State of
                    California, you have certain rights afforded to you under the California Consumer Privacy Act or the “
                    <b>
                      <i>CCPA</i>
                    </b>
                    ”. When we say “
                    <b>
                      <i>Personal Information</i>
                    </b>
                    ” in this section, we mean information that identifies, relates to, describes, is reasonably capable of being associated with, or could reasonably be linked,
                    directly or indirectly, with you. Personal Information does not include information that is aggregated or information that cannot be reasonably linked to you.
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <b>
                    <u>
                      <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                        <span style={{ textDecoration: 'none' }}>&nbsp;</span>
                      </span>
                    </u>
                  </b>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; We collect Personal Information and
                    Non-Personal Information for the business and commercial purposes described in the section above titled “How We Use Your Information”, and we share Personal
                    Information with the categories of third parties described in the section above titled “How We Share and Disclose Information”. We do not sell (as such term is
                    defined in the CCPA) the Personal Information we collect (and will not sell it without providing a right to opt out).
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Subject to certain limitations, the CCPA
                    provides California residents the following rights:
                    <br />
                    <br />
                  </span>
                </p>
                <p
                  className='MsoNormal'
                  style={{
                    marginLeft: '1.5in',
                    textAlign: 'justify',
                    textIndent: '-.5in',
                    lineHeight: 'normal'
                  }}
                >
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    (i)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; You have the right to request that we disclose to you the Personal Information we collect, use, or disclose,
                    and information about our data practices;
                  </span>
                </p>
                <p
                  className='MsoNormal'
                  style={{
                    marginLeft: '1.5in',
                    textAlign: 'justify',
                    textIndent: '-.5in',
                    lineHeight: 'normal'
                  }}
                >
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p
                  className='MsoNormal'
                  style={{
                    marginLeft: '1.5in',
                    textAlign: 'justify',
                    textIndent: '-.5in',
                    lineHeight: 'normal'
                  }}
                >
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    (ii)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; You have the right to request that we delete your Personal Information that we have collected from you;
                  </span>
                </p>
                <p
                  className='MsoNormal'
                  style={{
                    marginLeft: '1.5in',
                    textAlign: 'justify',
                    textIndent: '-.5in',
                    lineHeight: 'normal'
                  }}
                >
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p
                  className='MsoNormal'
                  style={{
                    marginLeft: '1.5in',
                    textAlign: 'justify',
                    textIndent: '-.5in',
                    lineHeight: 'normal'
                  }}
                >
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    (iii)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; You have the right to request that we no longer sell your Personal Information to third parties;
                  </span>
                </p>
                <p
                  className='MsoNormal'
                  style={{
                    marginLeft: '1.5in',
                    textAlign: 'justify',
                    textIndent: '-.5in',
                    lineHeight: 'normal'
                  }}
                >
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p
                  className='MsoNormal'
                  style={{
                    marginLeft: '1.5in',
                    textAlign: 'justify',
                    textIndent: '-.5in',
                    lineHeight: 'normal'
                  }}
                >
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    (iv)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; We will not discriminate against you for exercising any of these rights.
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <b>
                    <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                      &nbsp;
                    </span>
                  </b>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; California residents may make a request
                    pursuant to their “right to know”, “right to request deletion”, and/or “right to opt-out” under the CCPA by contacting us in accordance with the “Contact Us”
                    section below. In order to verify your request to know or delete information, we may require you to provide us with certain information to be used solely for
                    the purpose of verifying your identity.
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (e)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Under the CCPA, you may exercise these rights
                    yourself or you may designate an authorized agent to make these requests on your behalf. We may request that your authorized agent have written permission from
                    you to make requests on your behalf and may need to verify your authorized agent’s identity.
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <b>
                    <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                      9.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <u>Nevada Residents</u>
                    </span>
                  </b>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    . Under Nevada law, certain Nevada consumers may opt out of the sale of “personally identifiable information” for monetary consideration (as such terms are
                    defined under Nevada law) to a person for that person to license or sell such information to additional persons. We do not engage in such activity; however, if
                    you are a Nevada resident who has purchased services from us, you may submit a request to opt out of any potential future sales under Nevada law by contacting
                    us in accordance with the “Contact Us” section below. Please note we will take reasonable steps to verify your identity and the authenticity of the request.
                    Once verified, we will maintain your request in the event our practices change.
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <b>
                    <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                      &nbsp;
                    </span>
                  </b>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <b>
                    <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                      10.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <u>Information Retention</u>
                    </span>
                  </b>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    . We will retain information required to comply with privacy requests, manage active accounts, as required by law, in order to resolve disputes or enforce our
                    agreements. We will retain personal data we process on behalf of our clients as directed by them. We may also retain copies of your information for disaster
                    recovery purposes.
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <b>
                    <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                      11.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <u>Data Processing and Data Transfers</u>
                    </span>
                  </b>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    .
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To provide you with the Services, we may store,
                    process, and transmit data in the United States and locations around the world—including those outside your country.&nbsp;Your personal data, therefore, may be
                    subject to privacy laws that are different from those in your country.
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Personal data collected within the European
                    Economic Area may, for example, be transferred to and processed by third parties located in a country outside of the European Economic Area. In such instances,
                    we shall ensure that the transfer of your personal data is carried out in accordance with applicable privacy laws and, in particular, that appropriate
                    contractual, technical, and organizational measures are in place, such as the Standard Contractual Clauses approved by the EU Commission.
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <b>
                    <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                      12.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <u>Links to Third-Party Websites and Social Media Platforms</u>
                    </span>
                  </b>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    . The Services may provide the ability to connect to other websites. These websites may operate independently from us and may have their own privacy notices or
                    policies, which we strongly suggest you review. If any linked website is not owned or controlled by us, we are not responsible for its content, any use of the
                    website, or the privacy practices of the operator of the website.
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <b>
                    <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                      13.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <u>Children’s Privacy</u>
                    </span>
                  </b>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    . The Services are directed to individuals at the age of 18 and over. We do not knowingly collect personal data from individuals under the age of 18. If we
                    become aware of individuals who have provided us with personal data and are under the age of 18, we will take steps to deactivate the account and delete the
                    personal data. If you become aware of information from individuals under the age of 18 which has been provided to us, please contact us in accordance with the
                    “Contact Us” section below.
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p className='MsoNormal' style={{ textAlign: 'justify', lineHeight: 'normal' }}>
                  <b>
                    <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                      14.&nbsp;&nbsp;&nbsp; <u>Contact Us</u>
                    </span>
                  </b>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    . If you have any questions or concerns about our Privacy Policy, please contact us via email at 
                  </span>
                  <span lang='EN'>
                    <a href='mailto:info@inclusivv.co'>
                      <span style={{ fontFamily: '"Times New Roman",serif', color: '#0563C1' }}> info@inclusivv.co</span>
                    </a>
                  </span>
                  <span lang='EN' style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <p className='MsoNormal'>
                  <span lang='EN'>&nbsp;</span>
                </p>
              </div>
            </Grid>
          </Grid>
        </section>
      </div>
    )
  }
}

export default withTranslation(PrivacyPolicy)
