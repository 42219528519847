import { createSelector, createStructuredSelector } from 'reselect'
import { camelify } from '../decorators/textTools'

export const getPartnerStatsRaw = state => state.partnerStats

export const getLoadingPartnerStats = createSelector(
  getPartnerStatsRaw,
  p => p.loading
)

export const getPartnerStats = createSelector(
  getPartnerStatsRaw,
  p => camelify(p.list)
)

export default createStructuredSelector({
  partnerStats: getPartnerStats,
  loading: getLoadingPartnerStats
})
