import { types, Instance } from 'mobx-state-tree'
import PartnerConversationsApi from '../../../api/partners/ConversationsApi'
import PartnerBase from './PartnerBase'
import Conversation from '../Conversation'
import { LoadableStatus } from '../helpers/LoadableStatus'

export const PartnerConversations = types
  .compose(
    'PartnerConversations',
    PartnerBase,
    types.model({
      conversations: types.optional(types.array(Conversation), []),
      featuredConversationsList: types.optional(types.array(Conversation), []),
      hasMoreConversations: types.optional(types.boolean, true),
      hasMoreFeaturedConversations: types.optional(types.boolean, true)
    })
  )
  .actions((self) => ({
    setConversations: (conversations: any) => {
      self.conversations = conversations
    },
    setHasMoreConversations: (val: boolean) => {
      self.hasMoreConversations = val
    },
    setFeaturedConversations: (conversations: any) => {
      self.featuredConversationsList = conversations
    },
    setHasMoreFeaturedConversations: (val: boolean) => {
      self.hasMoreFeaturedConversations = val
    }
  }))
  .actions((self) => ({
    loadConversations: (args: any, { add = false }: { add?: boolean } = {}) => {
      if (self.isLoading('conversations')) return

      self.startLoading('conversations')
      return PartnerConversationsApi.getAll({ slug: self.slug, ...args })
        .then(({ response: { ok }, json }) => {
          self.stopLoading('conversations')
          if (json.length === 0) {
            self.setHasMoreConversations(false)
          } else {
            if (ok) {
              if (add) {
                self.setConversations([...self.conversations, ...json])
              } else {
                self.setConversations(json)
              }
            }
          }
        })
        .catch(() => {
          self.stopLoading('conversations')
        })
    },
    loadFeaturedConversations: (args: any, { add = false }: { add?: boolean } = {}) => {
      const listName = 'featuredConversationsList'
      if (self.isLoading(listName)) return

      self.startLoading(listName)
      return PartnerConversationsApi.getAll({ slug: self.slug, ...args })
        .then(({ response: { ok }, json }) => {
          self.stopLoading(listName)
          if (json.length === 0) {
            self.setHasMoreFeaturedConversations(false)
          } else {
            if (ok) {
              if (add) {
                self.setFeaturedConversations([...self.featuredConversationsList, ...json])
              } else {
                self.setFeaturedConversations(json)
              }
            }
          }
        })
        .catch(() => {
          self.stopLoading(listName)
        })
    }
  }))

export interface IPartnerConversations extends Instance<typeof PartnerConversations> {}
export default PartnerConversations
