import timeZoneApi from '../api/TimeZoneApi'

export function getTimeZone(coordinates, updateForm) {
  if (!coordinates) return Promise.reject('timeZoneApi requires coordinates')

  return timeZoneApi
    .get(coordinates)
    .then((response) => {
      if (response.response.ok) {
        updateForm(response.json.timeZoneId, 'timezone')
      }
    })
    .catch((error) => {
      throw error
    })
}
