import * as types from '../actions/actionTypes'
import initialState from './initialState'
import { assign } from 'lodash'

export const alertMessage = (state = initialState.alertMessage, action) => {
  switch (action.type) {
    case types.SHOW_ALERT_MESSAGE:
      const message = action.data.message
      return assign({}, state, { open: true, message })

    case types.CLOSE_ALERT_MESSAGE:
      return initialState.alertMessage

    default:
      return state
  }
}

export default alertMessage
