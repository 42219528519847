import React from 'react'
import { Controller } from 'react-hook-form'
import { FormHelperText } from '@material-ui/core'
import { AddressAutocomplete } from '../AutocompleteMui'
import { getTimeZone } from '../../../actions/timeZoneActions'
import { removeUndefinedValue } from '../../../store/models/utils'

export const AddressAutocompleteControl = ({
  color = 'secondary',
  required,
  helperText,
  onChange,
  onError,
  className,
  types,
  label,
  control,
  error,
  name,
  defaultValue,
  ...rest
}: any) => (
  <Controller
    name={name}
    control={control}
    error={!!error}
    helperText={error?.message}
    defaultValue={defaultValue || ''}
    {...rest}
    render={(props) => {
      return (
        <div className={className}>
          <AddressAutocomplete
            required={required}
            types={types}
            label={label}
            value={props.value?.name || props.value}
            color={color}
            onChange={(val) => {
              const request = {
                placeId: val.value
              }

              const callback = async (results, status) => {
                const location = await constructAddressData(results)
                props.onChange(location)
                onChange && onChange(location)
              }
              const service = new window.google.maps.places.PlacesService(document.createElement('div'))
              service.getDetails(request, callback)
            }}
            onBlur={props.onBlur}
            onError={onError}
          />
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
          {error && <FormHelperText error>{error?.message}</FormHelperText>}
        </div>
      )
    }}
  />
)

function extractAddressDetails(selectedPlace) {
  const componentForm = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'short_name',
    country: 'long_name',
    postal_code: 'short_name',
    neighborhood: 'long_name',
    sublocality_level_1: 'long_name',
    postal_town: 'long_name'
  }

  // Get each component of the address from the place details
  const selectedSuggest = {}

  for (const addressComponent of selectedPlace.address_components) {
    const addressType = addressComponent.types[0]
    if (componentForm[addressType]) {
      selectedSuggest[addressType] = addressComponent[componentForm[addressType]]
    }
  }

  return selectedSuggest
}

const constructAddressData = async (selectedPlace: any, venueName?: string, isVirtual?: boolean) => {
  const selectedSuggest = extractAddressDetails(selectedPlace)

  // Populate fields
  const {
    name,
    place_id,
    formatted_address,
    utc_offset_minutes,
    geometry: {
      location: { lng, lat }
    }
  } = selectedPlace
  const {
    street_number,
    route,
    neighborhood,
    sublocality_level_1,
    country,
    administrative_area_level_1: adminArea,
    locality: city,
    postal_code: postcode,
    postal_town
  } = selectedSuggest as any

  const resetFields = {
    name: null,
    place_id: null,
    venue_name: null,
    address: null,
    neighborhood: null,
    city: null,
    postal_town: null,
    state_level: null,
    postcode: null,
    country: null,
    full_place_response: null,
    lat: null,
    lng: null,
    coordinates: null,
    formatted_address: null,
    utc_offset_minutes: null
  }

  const fields = {
    venue_name: venueName ? venueName : isVirtual ? null : name.replace(/^\d[^\s]*/, ''),
    place_id,
    address: street_number ? `${street_number} ${route}` : route,
    neighborhood: neighborhood || sublocality_level_1 || postal_town || city,
    city: city ? city : postal_town,
    state_level: city === adminArea ? null : adminArea,
    postcode,
    country,
    formatted_address,
    utc_offset_minutes,
    full_place_response: JSON.stringify(selectedPlace),
    lat: lat(),
    lng: lng(),
    coordinates: [lat(), lng()]
  }

  await getTimeZone(`${lat()},${lng()}`, (val, field_name) => (fields[field_name] = val))

  return removeUndefinedValue(fields)
}
