import { Instance, types } from 'mobx-state-tree'
import { LoadableStatus } from './LoadableStatus'

export interface IFilterableArgs {
  search?: string
  page_size?: number
  page?: number
  topic_id?: number
  sorted_id?: string
  sorted_priority?: boolean
  time_scope?: string
}

export const Filterable = types
  .compose(
    'Filterable',
    LoadableStatus,
    types.model({
      search: types.optional(types.string, ''),
      page: types.optional(types.number, 0),
      pageSize: types.optional(types.number, 15),
      totalCount: types.maybe(types.number),
      pages: types.optional(types.number, 0),
      sortedId: types.maybe(types.string),
      sortedPriority: types.optional(types.boolean, false),
      topicId: types.maybe(types.number),
      partnerId: types.maybe(types.number),
      timeScope: types.maybe(types.string)
    })
  )
  .actions((self) => ({
    updateArgs: (args: IFilterableArgs) => {
      self.search = args.search || ''
      self.page = args.page || 0
      self.pageSize = args.page_size || 15
      self.sortedId = args.sorted_id
      self.sortedPriority = !!args.sorted_priority
      self.topicId = args.topic_id || undefined
    },
    setSearch: (val: string) => {
      self.search = val
    },
    setSortingId: (val: string) => {
      self.sortedId = val
    },
    setTopicId: (val: number) => {
      self.topicId = val
    },
    setPartnerId: (val: number | undefined) => {
      self.partnerId = val
    },
    setPage: (val: number) => {
      self.page = val
    },
    setPageSize: (val: number) => {
      self.pageSize = val
    },
    setSortedPriority: (val: boolean) => {
      self.sortedPriority = val
    },
    setTotalCount: (val?: number) => {
      self.totalCount = val
    },
    setTimeScope: (val?: string) => {
      self.timeScope = val
    },
    loadFilter: (opt?: { reset?: boolean; clear?: boolean }) => {
      // eslint-disable-next-line no-console
      console.log('add loadFilter')
    },
    setPages: (val?: number) => {
      self.pages = val || 0
    }
  }))

export interface IFilterable extends Instance<typeof Filterable> {}
