import React, { Component } from 'react'
import { Card, CardMedia, Typography, CardContent } from '@material-ui/core'
import withTranslation from '../hocs/withTranslation'

class WhatToExpectFB extends Component {
  render() {
    const { t } = this.props
    return (
      <section className='pane coco-lightest-grey xtra-fat-pane fat-pane-xs'>
        <div className='row center-xs'>
          <div className='col-xs-12'>
            <div className='box'>
              <h2 className='header-32 header-24-sm fat-bottom'>{t('conversation.whatToExpect')}</h2>
            </div>
          </div>
        </div>
        <div className='row center-xs'>
          <div className='col-xs-12 col-sm-4 fat-bottom-xs'>
            <div className='box'>
              <Card>
                <CardMedia>
                  <img className='img-responsive' src='https://civicdinners-manual-storage.s3.amazonaws.com/premium_events/what_to_expect_2_v2.jpeg' alt='what to expect' />
                </CardMedia>
                <CardContent>
                  <Typography gutterBottom variant='h5' component='h2'>
                    {t('conversation.buildNetwork')}
                  </Typography>
                  <Typography variant='body2' color='textSecondary' component='p'>
                    {t('conversation.buildNetworkDesc')}
                  </Typography>
                </CardContent>
              </Card>
            </div>
          </div>
          <div className='col-xs-12 col-sm-4 fat-bottom-xs'>
            <div className='box'>
              <Card>
                <CardMedia>
                  <img className='img-responsive' src='https://civicdinners-manual-storage.s3.amazonaws.com/premium_events/what_to_expect_1_v2.png' alt='what to expect' />
                </CardMedia>
                <CardContent>
                  <Typography gutterBottom variant='h5' component='h2'>
                    {t('conversation.boostKnowledge')}
                  </Typography>
                  <Typography variant='body2' color='textSecondary' component='p'>
                    {t('conversation.boostKnowledgeDesc')}
                  </Typography>
                </CardContent>
              </Card>
            </div>
          </div>
          <div className='col-xs-12 col-sm-4'>
            <div className='box'>
              <Card>
                <CardMedia>
                  <img className='img-responsive' src='https://civicdinners-manual-storage.s3.amazonaws.com/premium_events/what_to_expect_3_v2.jpeg' alt='what to expect' />
                </CardMedia>
                <CardContent>
                  <Typography gutterBottom variant='h5' component='h2'>
                    {t('conversation.growBusiness')}
                  </Typography>
                  <Typography variant='body2' color='textSecondary' component='p'>
                    {t('conversation.growBusinessDesc')}
                    <br />
                    <br />
                  </Typography>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default withTranslation(WhatToExpectFB)
