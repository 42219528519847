import React, { useContext } from 'react'
import { Card, CardContent, createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import ShareStory from '../ShareStory'
import { StoryContext } from '../contexts'
import { MediaFilePreview } from '../../utils/AvatarUploader'
import { StoryAuthor } from '../StoryAuthor'
import FavButton from '../../users/MyConversations/FavButton'

export const ListItem = () => {
  const story = useContext(StoryContext)
  const classes = useStyles()
  const assetUrl = story?.asset?.url || '/'
  if (!story) {
    return <></>
  }
  return (
    <Card className={classes.root} onClick={() => story?.show()} elevation={2}>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Grid container className={classes.cardContainer} spacing={1}>
            {story?.asset && (
              <Grid item xs={12} sm={4}>
                <MediaFilePreview file={story?.asset} className={classes.preview} rounded={true} />
              </Grid>
            )}
            <Grid item xs={12} sm={story?.asset ? 8 : 12}>
              <Grid container justify='space-between'>
                <Grid item>
                  <StoryAuthor />
                </Grid>
                <Grid item>
                  <FavButton likableId={story.id} likableType='Story' />
                </Grid>
              </Grid>
              <Typography variant='subtitle2' className={classes.headline}>
                {story?.headline}
              </Typography>
              <Typography variant='body2' className={classes.story}>
                {story?.content}
              </Typography>

              <ShareStory story={story} color='#898A8E' noLabel={true} spacing={1} iconClassNames={classes.shareIcon} />
            </Grid>
          </Grid>
        </CardContent>
      </div>
    </Card>
  )
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      textAlign: 'left'
    },
    avatar: {
      margin: '0 .5em .5em 0'
    },
    username: {
      marginBottom: '.25em'
    },
    location: {
      marginBottom: '.25em'
    },
    headline: {
      marginBottom: '.25em'
    },
    story: {
      marginBottom: '.25em',
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-line-clamp': 4,
      '-webkit-box-orient': 'vertical'
    },
    details: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column'
    },
    content: {
      // flex: '1 0 auto'
      // padding: '0 1em'
    },
    cover: {
      // minWidth: '15em',
      width: '100%',
      borderRadius: '1.3em'
    },
    shareIcon: {
      fontSize: '1em'
    },
    shareContainer: {
      marginTop: '1em'
    },
    onlyMobile: {
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },
    preview: {
      maxHeight: '184px'
    },
    cardContainer: {
      // flexDirection: 'column'
    }
  })
)
