import React from 'react'
import { DateTimeControl, SelectControl, DateControl, TimeControl } from '../../utils'
import moment from 'moment'
import { StepProps } from './NewSynthStepper'
import { isValidSynthDate, isValidTime, required } from '../../validations/fieldLevelValidations'
import withTranslation, { WithTranslation } from '../../hocs/withTranslation'

const DateStep = ({ topic, t, control, errors, setValue, setError, clearErrors, watch, trigger, synth }: StepProps & WithTranslation) => {
  const minDateSynth = topic.startsAt ? moment(topic.startsAt).format('yyyy-MM-DD') : undefined
  const maxDateSynth = topic.endsAt ? moment(topic.endsAt).format('yyyy-MM-DD') : undefined

  const min = minDateSynth
  const max = maxDateSynth
  return (
    <>
      <div>
        <div className='fat-top'>
          <DateControl
            name='startsAtDate'
            control={control}
            error={errors && errors.startsAtDate}
            defaultValue={moment()}
            rules={{
              validate: (tm) => {
                return !synth && !isValidSynthDate(topic.startsAt, topic.endsAt)(moment(tm)) ? 'Invalid date' : undefined
              }
            }}
            dateProps={{
              inputProps: {
                min: min ? moment(min)?.format('yyyy-MM-DD') : undefined,
                max: max ? moment(max)?.format('yyyy-MM-DD') : undefined
              }
            }}
          />
          <div className='label reg-top'>{t('common.startTime')}</div>
          <TimeControl
            name='startsAt'
            error={errors && errors.startsAt}
            control={control}
            rules={{ validate: (startsAtVal) => (!isValidTime(moment(startsAtVal), moment(watch('endsAt'))) ? t('convos.validTime') : undefined) }}
            defaultValue={moment()}
            onChange={(val: any) => {
              if (!val) return
              trigger('endsAt')
              setValue('startsAt', val, { shouldDirty: true })
            }}
          />
          <div className='label reg-top'>{t('common.endTime')}</div>
          <TimeControl
            name='endsAt'
            control={control}
            defaultValue={moment().add(1, 'hours')}
            rules={{ validate: (endsAtVal) => (!isValidTime(moment(watch('startsAt')), moment(endsAtVal)) ? t('convos.validTime') : undefined) }}
            error={errors && errors.endsAt}
            onChange={(val: any) => {
              if (!val) return
              trigger('startsAt')
              setValue('endsAt', val, { shouldDirty: true })
            }}
          />
          <SelectControl
            name='timezone'
            label={t('common.timeZone')}
            className='fat-top'
            control={control}
            defaultValue={(topic && topic.timezone) || 'US/Eastern'}
            error={errors && errors.timezone}
            options={moment.tz.names().map((value) => ({ label: value, value }))}
          />
        </div>
      </div>
    </>
  )
}

export default withTranslation(DateStep)
