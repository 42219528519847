import { Grid } from '@material-ui/core'
import React, { ReactElement, useContext } from 'react'

import { IStory } from '../../../store/models/Stories'
import { ParamTopicContext } from '../../Providers'
import { StoriesContext, StoryContext } from '../contexts'

import { ListItem } from './ListItem'

interface Props {}

export const StoryList = (props: Props): ReactElement => {
  const { topic } = useContext(ParamTopicContext)
  const { stories } = useContext(StoriesContext)
  return (
    <div>
      <Grid container spacing={2} direction='column' style={{ maxWidth: '50em', margin: '0 auto' }}>
        {stories?.map((story) => (
          <Grid item key={story.id}>
            <StoryContext.Provider value={story}>
              <ListItem />
            </StoryContext.Provider>
          </Grid>
        ))}
      </Grid>
    </div>
  )
}
