import * as types from '../actions/actionTypes'
import { assign } from 'lodash'
import initialState from './initialState'

export const topics = (state = initialState.topics, action) => {
  switch (action.type) {
    case types.LOAD_NEW_CONVERSATIONS_SUCCESS:
      return assign({}, state, {
        error: null,
        loadingTopics: false,
        list: assign({}, action.topics)
      })

    case types.LOAD_CONVERSATIONS_SUCCESS:
      return assign({}, state, {
        error: null,
        loadingTopics: false,
        list: assign({}, state.list, action.topics)
      })

    case types.LOAD_RANKED_CONVERSATIONS_SUCCESS:
      return assign({}, state, {
        errorRankedTopics: null,
        loadingRankedTopics: false,
        rankedList: assign({}, action.topics)
      })

    case types.LOAD_RANKED_CONVERSATIONS_FAILED:
      return assign({}, state, {
        errorRankedTopics: action.data,
        loadingRankedTopics: false
      })

    case types.RANKED_CONVERSATIONS_LOADING:
      return assign({}, state, {
        errorRankedTopics: null,
        loadingRankedTopics: true
      })

    case types.LOAD_CONVERSATIONS_FAILED:
      return assign({}, state, {
        error: action.data,
        loadingTopics: false
      })

    case types.CONVERSATIONS_LOADING:
      return assign({}, state, {
        error: null,
        loadingTopics: true
      })

    case types.CONVERSATION_LOADING:
      return assign({}, state, {
        error: null,
        loadingTopic: true
      })

    case types.LOAD_CONVERSATION_SUCCESS:
      return assign({}, state, {
        error: null,
        loadingTopic: false,
        list: assign({}, state.list, action.topics)
      })

    case types.DELETE_CONVERSATION_SUCCESS:
      const topicId = action.data
      return assign({}, state, delete state.list[topicId])

    case types.LOAD_PARTNER_CONVERSATIONS_SUCCESS:
      return assign({}, state, {
        errorPartnerTopics: null,
        loadingPartnerTopics: false,
        partnerTopicslist: assign({}, action.topics)
      })

    case types.LOAD_MORE_PARTNER_CONVERSATIONS_SUCCESS:
      return assign({}, state, {
        errorPartnerTopics: null,
        loadingPartnerTopics: false,
        partnerTopicslist: assign({}, state.partnerTopicslist, action.topics)
      })

    case types.PARTNER_CONVERSATIONS_LOADING:
      return assign({}, state, {
        errorPartnerTopics: null,
        loadingPartnerTopics: true
      })

    case types.LOAD_PARTNER_CONVERSATIONS_FAILED:
      return assign({}, state, {
        errorPartnerTopics: state.data,
        loadingPartnerTopics: false
      })

    default:
      return state
  }
}

export default topics
