import fetch from 'isomorphic-fetch'

class UserInvitationRequestsApi {
  static requestHeaders() {
    return {
      'Content-Type': 'application/json',
      AUTHORIZATION: `Bearer ${localStorage.jwt}`
    }
  }

  static getAll(userId) {
    const headers = this.requestHeaders()

    return fetch(`${process.env.REACT_APP_API_HOST}/users/${userId}/conversation_invitation_requests`, {
      method: 'GET',
      headers: headers
    })
      .then(response => response.json().then(json => ({ json, response })))
      .catch(error => {
        return error
      })
  }
}

export default UserInvitationRequestsApi
