import { types, Instance } from 'mobx-state-tree'

export const AnalyticsInlineStats = types.model('AnalyticsInlineStats', {
  key: types.maybe(types.string),
  label: types.maybe(types.string),
  count: types.maybe(types.string)
})

export interface IAnalyticsTotalStats extends Instance<typeof AnalyticsInlineStats> {}
export default AnalyticsInlineStats
