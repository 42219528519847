import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import { flow } from 'lodash'
import { IStore, IConversation } from '../../store'
import { inject } from 'mobx-react'
import { RouteComponentProps, withRouter } from 'react-router'

const ParamConversationProvider = ({ children, conversation, conversationLoading, isGuest, isHost }: Props & InjectedProps & RouteComponentProps) => {
  return <ParamConversationContext.Provider value={{ conversation, conversationLoading, isGuest, isHost }}>{children}</ParamConversationContext.Provider>
}

interface Props {
  children?: React.ReactNode | React.ReactNode[]
}

interface InjectedProps {
  conversation?: IConversation
  conversationLoading: boolean
  isGuest: boolean
  isHost: boolean
}

export const ParamConversationContext = React.createContext<InjectedProps>({
  conversationLoading: false,
  isGuest: false,
  isHost: false
})
export const useParamConversation = () => useContext(ParamConversationContext)

export default flow(
  observer,
  inject<InjectedProps, Props & RouteComponentProps<{ id?: string; conversationId?: string }>>(
    (
      {
        mst: {
          conversations,
          auth: { user }
        }
      }: {
        mst: IStore
      },
      { match: { params } }
    ) => {
      const conversationId = parseInt((params?.id || params?.conversationId) as any, 10)
      const conversation = conversations.list.find((d) => d.id === conversationId)
      !conversation && conversations.shouldLoad(conversationId, () => conversations.loadById(conversationId))
      if (user) {
        user.shouldLoad('userConversations', user.loadUserConversations)
      }

      return {
        conversation,
        conversationLoading: conversations.loadingIds.includes(conversationId),
        isGuest: !!user && user.userConversations.map((g) => g.conversationId).includes(conversationId),
        isHost: !!user && !!conversation && user.id === conversation?.host?.id
      }
    }
  ),
  withRouter
)(ParamConversationProvider)
