import { types, Instance } from 'mobx-state-tree'
import moment from 'moment-timezone'
import UserConversationsApi from '../../../api/users/UserConversationsApi'
import { LoadableStatus } from '../helpers'
import Editable from '../helpers/Editable'
import { Alertable } from '../helpers/Alertable'
import { UserBase } from './UserBase'

export const UserConversation = types
  .compose(
    'UserConversation',
    Editable,
    Alertable,
    types.model({
      id: types.identifierNumber,
      userId: types.number,
      conversationId: types.number,
      topicId: types.number,
      hostId: types.number,
      guestToken: types.maybeNull(types.string),
      attended: types.maybeNull(types.boolean),
      startsAt: types.maybeNull(types.string),
      endsAt: types.maybeNull(types.string)
    })
  )
  .actions((self) => ({
    pastConversation: () => {
      return moment(self.endsAt) < moment()
    },
    updatedAttendance: (attended) => {
      return UserConversationsApi.update(self.conversationId, self.id, { attended }).then(({ response: { ok }, json }) => {
        if (ok) {
          // self.setUserConversations(json)
          self.updateMe(json)
          self.showAlert('Updated')
        }
      })
    }
  }))

export const UserConversations = types
  .compose(
    'UserConversations',
    UserBase,
    LoadableStatus,
    types.model({
      userConversations: types.optional(types.array(UserConversation), [])
    })
  )
  .actions((self) => ({
    setUserConversations(data: any) {
      self.userConversations.replace(data)
    }
  }))
  .actions((self) => ({
    loadUserConversations: () => {
      if (self.isLoading('userConversations')) {
        return
      }
      self.startLoading('userConversations')
      return UserConversationsApi.getAll(self.id).then(({ response: { ok }, json }) => {
        if (ok) {
          self.setUserConversations(json)
        }
        self.stopLoading('userConversations')
      })
    }
  }))
  .actions((self) => ({
    attendedTopic: (topicId) => {
      return self.userConversations.find((uc) => uc.topicId === topicId)
    }
  }))

export interface IUserConversation extends Instance<typeof UserConversation> {}
export interface IUserConversations extends Instance<typeof UserConversations> {}
export default UserConversations
