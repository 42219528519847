import React from 'react'
import { get } from 'lodash'

import { connectDialogTheme } from '../connectTheme'
import { IDialog } from '../store/models/Dialog'
import { observer } from 'mobx-react'
import { IconButton, createStyles, makeStyles, Dialog, WithStyles, withStyles, DialogTitle as MuiDialogTitle, Typography, Theme } from '@material-ui/core'
import { CloseIcon } from '@material-ui/data-grid'

interface Props {
  name?: string
  title?: string
  dialog: IDialog
  type?: any
  children?: React.ReactNode
  classes?: any
  closeIcon: boolean
  closeClass?: string
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined
  open?: boolean
  afterClose()
}

const DialogWrapper = (props: Props) => {
  const { children, classes, afterClose, type = 'noPadding', closeIcon, dialog, open = true, closeClass = '', title, maxWidth } = props
  const styleClasses = useStyles()
  const handleClose = (e: any) => {
    e.stopPropagation()
    e.preventDefault()
    dialog && dialog?.closeDialog()
    if (afterClose) afterClose()
  }

  const types = {
    thin: { paper: `dialog thin-dialog slim-rows ${window.coco ? 'coco-light-blue-text' : ''}` },
    reg: { paper: 'dialog' },
    noPadding: { paper: 'dialog no-padding' },
    fatPadding: { paper: `dialog fat-padding ${styleClasses.typeBig}` },
    wide: { paper: 'dialog no-padding wide-dialog' }
  }

  const paperClass = maxWidth ? {} : get(types, type, types.reg)

  return (
    <Dialog open={open} onClose={handleClose} classes={{ ...paperClass, ...classes, paper: paperClass.paper }} maxWidth={maxWidth}>
      {title && <DialogTitle onClose={handleClose}>{title}</DialogTitle>}
      {children}
    </Dialog>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    closeIcon: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: '#FFFFFF'
    },
    paper: { padding: 0, backgroundColor: '#ff0000' },
    typeBig: {
      minHeight: '35rem'
    }
  })
)

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: '4px',
      color: theme.palette.grey[500]
    }
  })

export interface DialogTitleProps extends WithStyles<typeof styles> {
  children: React.ReactNode
  onClose?: (e: FIXME) => void
}

export const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='subtitle1' align='center'>
        {children}
      </Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

export default observer(connectDialogTheme(DialogWrapper))
