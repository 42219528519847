import { createSelector } from 'reselect'
import { flow, filter } from 'lodash'

export const getUserConversationInvitationRequestsRaw = state => state.conversationInvitationRequests

const getUserConversationInvitationRequestsList = createSelector(
  getUserConversationInvitationRequestsRaw,
  dir => dir.list
)

export const getPendingUserInvitationRequests = createSelector(
  getUserConversationInvitationRequestsList,
  invitationRequests => {
    const flowInvitationRequests = flow([filterPendingConversationInvitationRequests])
    return flowInvitationRequests(invitationRequests)
  }
)

function filterPendingConversationInvitationRequests(invitationRequests) {
  return filter(invitationRequests, ir => ir.pending)
}
