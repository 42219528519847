import { types } from 'mobx-state-tree'

export const ImageUrl = types.model('ImageUrl', {
  url: types.maybeNull(types.string)
})
export const MetaImage = types.model('MetaImage', {
  url: types.maybeNull(types.string),
  primary: types.maybeNull(ImageUrl),
  thumb: types.maybeNull(ImageUrl),
  tiny: types.maybeNull(ImageUrl),
  large: types.maybeNull(ImageUrl)
})
