import appAnalytics from '../analytics'
import { deleteApi, post } from './utils'

class UserConversationsApi {
  static create({ conversationId, userId }) {
    if (!conversationId) throw new Error('UserConversationsApi#create requires a conversationId')
    if (!userId) throw new Error('UserConversationsApi#create requires a userId')

    return post(`/user_conversations`, {
      body: JSON.stringify({
        conversation_id: conversationId,
        user_id: userId
      })
    }).then((d) => {
      appAnalytics.event({
        category: 'Conversation',
        action: 'Guest RSVP'
      })
      return d
    })
  }

  static destroy({ conversationId, userId, message }) {
    if (!conversationId) throw new Error('UserConversationsApi#destroy requires a conversationId')
    if (!userId) throw new Error('UserConversationsApi#destroy requires a userId')

    return deleteApi(`/user_conversations/${conversationId}`, {
      body: JSON.stringify({
        user_id: userId,
        message: message
      })
    }).then((d) => {
      appAnalytics.event({
        category: 'Conversation',
        action: 'Removed RSVP'
      })
      return d
    })
  }
}

export default UserConversationsApi
