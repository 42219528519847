import React from 'react'
import { Button as FlatButton, Chip, createStyles, Divider, Grid, makeStyles, Switch } from '@material-ui/core'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'

import { User } from '../../../getters/userGetters'
import Avatar from '../../users/Avatar.js'
import withTranslation, { WithTranslation } from '../../hocs/withTranslation'
import { flow } from 'lodash'
import { observer } from 'mobx-react'
import i18next from 'i18next'
import appAnalytics from '../../../analytics'
import { Link } from 'react-router-dom'
import { IConversation, IUser } from '../../../store'
import { useParamConversation } from '../../Providers'

interface Props {
  itemProps: IConversation
  item: IUser
}

const AttendanceAction = observer(({ userConversation }) => {
  const classes = Styles()
  return (
    <div>
      <Switch checked={userConversation?.attended} onChange={() => userConversation?.updatedAttendance(!userConversation?.attended)} />{' '}
      {userConversation?.attended ? 'Attended' : 'Did not attended'}{' '}
    </div>
  )
})

const GuestListItem = (props: Props & WithTranslation) => {
  const { itemProps, item: guest, t } = props
  const classes = Styles()
  const { conversation } = useParamConversation()
  // const { conversation } = useParamConversation()
  if (!conversation) {
    return <></>
  }
  const userConversation = conversation.userConversations.find((uc) => uc.userId === guest.id)
  return (
    <>
      <ListItem button>
        <Grid container spacing={1}>
          <Grid item>
            <ListItemAvatar>
              <Avatar profileImage={guest.profileImage} hasProfileImage={guest.hasProfileImage} size={'thumb'} />
            </ListItemAvatar>
          </Grid>

          <Grid item sm={3} className={classes.lineItems}>
            <ListItemText
              primary={
                <Link to={`/users/${guest.id}`} target='_blank' className={classes.userName}>
                  {User(guest).fullName}
                </Link>
              }
            />
          </Grid>

          <Grid item sm={4} className={classes.lineItems}>
            <ListItemText primary={guest.email} />
          </Grid>
          <Grid item className={classes.lineItems}>
            {/* {userConversation && <DisplayAttendance userConversation={userConversation} />} */}
            <AttendanceAction userConversation={userConversation} />
          </Grid>

          <Grid item className={classes.lineItems}>
            <ListItemSecondaryAction>
              <FlatButton
                onClick={() => {
                  appAnalytics.event({
                    category: 'Conversation',
                    action: 'Remove'
                  })
                  conversation.unattend({ userId: guest.id }, { showDialog: true, successAlert: i18next.t('conversation.rsvpCanceled') })
                }}
                color='secondary'
                variant='text'
                className={classes.removeButton}
              >
                {t('conversation.remove')}
              </FlatButton>
            </ListItemSecondaryAction>
          </Grid>
        </Grid>
      </ListItem>
      <Divider variant='inset' component='li' />
    </>
  )
}

export default flow(withTranslation, observer)(GuestListItem)
const Styles = makeStyles((theme) =>
  createStyles({
    userName: {
      textDecoration: 'underline'
    },
    removeButton: {
      color: '#EF403F'
    },
    notAttended: {
      color: '#EF403F'
    },
    attended: {
      color: '#00420d'
    },
    lineItems: {
      alignSelf: 'center'
    }
  })
)
