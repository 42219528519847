import React from 'react'
import StoriesSummary from './StoriesSummary'
import AdminMetaTags from '../../../shared/AdminMetaTags'

interface Props {}

const Stories = (props: Props) => {
  return (
    <>
      <AdminMetaTags title='Stories' />
      <StoriesSummary />
    </>
  )
}

export default Stories
