import React, { Component, useContext, useEffect, useMemo } from 'react'

import { observer } from 'mobx-react'
import { useAuth, useParamTopic } from '../../Providers'
import { ShowCourse } from '../../lms_courses/ShowCourse'
import { AppBar, Button, Container, Grid, Paper, Theme, Typography, createStyles, makeStyles } from '@material-ui/core'
import { useParams } from 'react-router'
import { IPartner, ITrack } from '../../../store'
import PayWall from './PayWall'

const TopicVideos = observer((props: FIXME) => {
  const { topic, partner } = useParamTopic()
  const { user } = useAuth()
  // console.log('TopicVideos', { topic, params, partner, isTrackMember })
  useEffect(() => {
    topic && topic.shouldLoad('topicLmsCourses', topic.loadTopicLmsCourses)
  }, [topic])
  useEffect(() => {
    user && user.shouldLoad('userLmsItems', user.loadUserLmsItems)
  }, [user])
  return (
    <PayWall>
      <Container maxWidth='sm' style={{ paddingTop: '2rem' }}>
        <Typography variant='h3' align='center'>
          {topic?.topicLmsCourse?.name}
        </Typography>
        <Typography align='center'>{topic?.topicLmsCourse?.description}</Typography>
        <ShowCourse />
      </Container>
    </PayWall>
  )
})

export default TopicVideos
