import React, { useContext } from 'react'

import { connectAdminTheme, connectTopicTheme } from '../../../connectTheme'
import { flow } from 'lodash'
import { AuthContext, useAuth } from '../../Providers/AuthProvider'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import DrawerMenu from './DrawerMenu'
import FavCard from './FavCard'

const MyFavs = (props) => {
  const history = useHistory()
  const { t } = useTranslation()
  const { user, loaded } = useContext(AuthContext)
  const classes = useStyles()

  if (loaded && !user) {
    history.push('/login')
  }
  return (
    <div className={classes.root}>
      <DrawerMenu />
      <main className={`${classes.content} quite-big-bottom-padding`}>
        <div className='col-xs-12 start-xs form-xl fat-top no-gutters'>
          <div style={{ margin: 'auto auto', padding: '0 1rem' }}>
            <div className='header-32 bold fat-bottom'>My Favorites</div>
            <div className='fat-bottom'>
              You can Favorite an item by clicking the Heart icon on a Conversation, Topic, Journey, or Customer page. To remove an item from your Favorite list, simply unclick the
              Heart.
            </div>
            <FavConversations />
            <FavTopics />
            <FavTracks />
            <FavPartners />
            <FavStories />
          </div>
        </div>
      </main>
    </div>
  )
}

export default flow(connectAdminTheme)(MyFavs)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    content: {
      flexGrow: 1,
      padding: '0 1em 1em 2em',
      margin: 'auto auto',
      backgroundColor: '#f5f5f5',
      minHeight: 'calc(100vh - 7em) !important',
      maxWidth: '100vw',
      [theme.breakpoints.down('sm')]: {
        padding: '0'
      }
    }
  })
)

const FavConversations = (props) => {
  const { user } = useAuth()
  const list = user?.likes.filter((i) => i.likableType === 'Conversation')
  return (
    <div className='fat-bottom'>
      <Typography variant='h6'>Conversations</Typography>
      <FavList list={list} favoritable='Conversations' />
    </div>
  )
}

const FavTopics = (props) => {
  const { user } = useAuth()
  const list = user?.likes.filter((i) => i.likableType === 'Topic')
  return (
    <div className='fat-bottom'>
      <Typography variant='h6'>Topics</Typography>
      <FavList list={list} favoritable='Topics' />
    </div>
  )
}

const FavPartners = (props) => {
  const { user } = useAuth()
  const list = user?.likes.filter((i) => i.likableType === 'Partner')
  return (
    <div className='fat-bottom'>
      <Typography variant='h6'>Partners</Typography>
      <FavList list={list} favoritable='Partners' />
    </div>
  )
}

const FavStories = (props) => {
  const { user } = useAuth()
  const list = user?.likes.filter((i) => i.likableType === 'Story')
  return (
    <div className='fat-bottom'>
      <Typography variant='h6'>Stories</Typography>
      <FavList list={list} favoritable='Stories' />
    </div>
  )
}

const FavTracks = (props) => {
  const { user } = useAuth()
  const list = user?.likes.filter((i) => i.likableType === 'Track')

  return (
    <div className='fat-bottom'>
      <Typography variant='h6'>Journeys</Typography>
      <FavList list={list} favoritable='Journeys' />
    </div>
  )
}

const FavList = flow(connectTopicTheme)(({ list, favoritable }) => {
  return (
    <section className={'pane reg-pane clear-pane'} style={{ maxHeight: 'calc(100vh / 3)', overflowY: 'auto' }}>
      {list && list.length === 0 && favoritable && <div className='light-grey-text'>No favorite {favoritable}</div>}
      {list?.map((item) => (
        <FavCard key={item.id} item={item} />
      ))}
    </section>
  )
})
