import React, { useEffect } from 'react'
import { flow, groupBy, uniq, sortBy } from 'lodash'
import { Button, Grid, Typography, IconButton, Paper, makeStyles, createStyles } from '@material-ui/core'
import { pick, pickBy, identity, camelCase } from 'lodash'
import { observer, inject } from 'mobx-react'
import { useForm, FormProvider } from 'react-hook-form'

import { camelify, snakify } from '../../../decorators/textTools'
// import withTranslation from '../hocs/withTranslation'
import { TextFieldControl, SelectControl } from '../../utils'
import { handleStartEndAt } from '../../validations/fieldLevelValidations'
import { IStore } from '../../../store/models'

import { Prompt } from 'react-router'
import { UNSAVED_FORM_WARNING } from '../../utils/constants'
import DateFnsUtils from '@date-io/date-fns'
import withTranslation from '../../hocs/withTranslation'
import { maxLength255 } from '../../validations/fieldLevelValidations'
import { withRouter } from 'react-router'
import DocumentItem from '../../documents/DocumentItem'
import LoadingAnimation from '../../shared/LoadingAnimation'
import DocumentGroup from '../../documents/DocumentGroup'
import { useParamTopic } from '../../Providers'

// eslint-disable-next-line complexity
const HostTools = ({ user, conversation, t, onSave, showDialog }: FIXME) => {
  const classes = Styles()
  const formMethods = useForm({ mode: 'onChange', shouldUnregister: false })
  const { handleSubmit, errors, formState, control, watch, setValue, setError, reset } = formMethods

  const { isDirty, isSubmitting, dirtyFields } = formState

  const displayError = (error) => {
    if (error) {
      for (const fieldName in error) {
        setError(camelCase(fieldName), {
          type: 'manual',
          message: error[fieldName]
        })
      }
    }
  }

  const handleSave = handleSubmit((data: any) => {
    const { privateEvent, location, suitableDiets, ...rest } = pick(data, Object.keys(pickBy(dirtyFields, identity)))
    const updateData = {
      ...camelify(location),
      ...(suitableDiets ? { suitableDiets: snakify(suitableDiets) } : {}),
      ...rest,
      ...handleStartEndAt(data),
      ...(privateEvent === undefined ? {} : { guestPolicy: privateEvent ? 'approval_only' : 'public' })
    }

    if ((conversation.isHost || user.isSuperAdmin()) && conversation.guestCount > 0) {
      showDialog('updateConversation', { values: { slug: conversation.slug, id: conversation.id, ...updateData } })
      onSave && onSave()
    } else {
      conversation.updateConversation(updateData).then(onSave).catch(displayError)
    }
    reset(data)
  })
  const { topic } = conversation

  useEffect(() => {
    topic && topic.shouldLoad('documents', topic.loadTopicDocuments)
  }, [topic])

  const { hostGuide, marketingToolKit, additionalFiles } = topic
  const additionalFilesGrouped = sortBy(Object.entries(groupBy(additionalFiles, (doc) => doc.groupId)), (k, _) => k)
  const groupIds = uniq<string>(additionalFiles.filter((d) => d.groupId).map((d) => d.groupId))

  const isFormValid = Object.keys(errors).length === 0
  const storyLink = `https://${window.envFriendlyDomain}/${topic?.slug}/conversations/${conversation.id}/story`
  const npsLink = `https://${window.envFriendlyDomain}/${topic?.slug}/conversations/${conversation.id}/nps`

  return (
    <div>
      <Paper elevation={3} style={{ padding: '1em' }}>
        <FormProvider {...formMethods}>
          <Grid container justify='space-between' direction={'column'}>
            <Grid item>
              <h4>Conversation Host Notes</h4>
            </Grid>
            <form onSubmit={handleSave}>
              <Grid item className={classes.myDownloadLinkGrid}>
                <div className='fat-top'>
                  <TextFieldControl
                    name='hostNotes'
                    control={control}
                    defaultValue={conversation && conversation.hostNotes?.toString()}
                    error={errors && errors.hostNotes}
                    label='Conversation Host Notes'
                    multiline={true}
                    rows={4}
                    rules={{ validate: { maxLength255 } }}
                  />
                </div>
              </Grid>
              <Grid container justify='flex-end' className='reg-bottom reg-top'>
                <Grid item>
                  <Button disabled={!isDirty || isSubmitting || !isFormValid} type='submit' color='secondary' variant='contained'>
                    Save Note
                  </Button>
                </Grid>
              </Grid>
              <Prompt when={isDirty || isSubmitting} message={UNSAVED_FORM_WARNING} />
            </form>
            <Grid item>
              <div className='fat-top'>
                <Typography>
                  <IconButton
                    style={{ marginLeft: '1rem' }}
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      navigator.clipboard.writeText(storyLink)
                      conversation.showAlert('Link copied')
                    }}
                  >
                    <i className='fal fa-copy opacity-6'></i>
                  </IconButton>
                  Copy link to Request Stories from Attendees
                </Typography>
              </div>
              <div className='fat-top'>
                <Typography>
                  <IconButton
                    style={{ marginLeft: '1rem' }}
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      navigator.clipboard.writeText(npsLink)
                      conversation.showAlert('NPS link copied')
                    }}
                  >
                    <i className='fal fa-copy opacity-6'></i>
                  </IconButton>
                  Copy link to Request Feedback from Attendees
                </Typography>
              </div>
            </Grid>
          </Grid>
        </FormProvider>
      </Paper>
      <HostDocuments />
    </div>
  )
}
const HostDocuments = observer((props: FIXME) => {
  const { topic } = useParamTopic()
  const classes = Styles()
  useEffect(() => {
    topic && topic.shouldLoad('documents', topic.loadTopicDocuments)
  }, [topic])
  if (!topic) return <>Loading</>
  const { marketingToolKit, hostGuide, additionalFiles } = topic
  const groupIds = uniq<string>(additionalFiles.filter((d) => d.groupId).map((d) => d.groupId || 'ungrouped'))
  const additionalFilesGrouped = sortBy(Object.entries(groupBy(additionalFiles, (doc) => doc.groupId)), (k, _) => k)
  return (
    <Paper elevation={3} style={{ padding: '1em 3em', marginTop: '2em' }}>
      <Grid item>
        <h4>Host Guide</h4>
        <Typography className={classes.myDownloadLink}>{hostGuide && <DocumentItem key={hostGuide.id} document={hostGuide} />}</Typography>
        <h4>Marketing Toolkit</h4>
        <Typography className={classes.myDownloadLink}>{marketingToolKit && <DocumentItem key={marketingToolKit.id} document={marketingToolKit} />}</Typography>
        <Typography className={classes.myDownloadLink}>
          {topic.loadingIds.includes('documents') ? (
            <LoadingAnimation />
          ) : (
            additionalFilesGrouped.map(([groupId, documents]) => (
              <DocumentGroup groupId={groupId} key={groupId} documents={documents} documentable={topic} groups={groupIds} editable={false} />
            ))
          )}
        </Typography>
      </Grid>
    </Paper>
  )
})

export default inject<FIXME, FIXME>(
  ({
    mst: {
      auth: { user },
      dialogs: { showDialog }
    }
  }: {
    mst: IStore
  }) => {
    return { user, showDialog }
  }
)(observer(withTranslation(HostTools)))

const Styles = makeStyles((theme) =>
  createStyles({
    myDownloadLink: {
      color: '#0976BC',
      fontSize: '1em'
    },
    myDownloadLinkGrid: {
      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    }
  })
)
