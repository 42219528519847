import React from 'react'
import { flow } from 'lodash'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'

import ConversationListCard from '../../conversations/ListCard'
import { connectTopicTheme } from '../../../connectTheme'
import LoadingAnimation from '../../shared/LoadingAnimation'
import withTranslation from '../../hocs/withTranslation'
import { inject, observer } from 'mobx-react'
import { IStore } from '../../../store'
import moment from 'moment-timezone'

const HostedConversationsList = (props: any) => {
  const { conversations, isHost, t, loadingList } = props

  if (loadingList) {
    return <LoadingAnimation size={40} />
  }

  const hostButton = () => {
    return (
      <>
        {!window.coco && (
          <div className='col-xs-12 col-xs-6 thin-top reg-top-xs'>
            <Link to={'/topics'}>
              <Button color='primary' fullWidth={true} className='btn-xl' variant='contained'>
                {t('conversation.hostAConversation')}
              </Button>
            </Link>
          </div>
        )}
      </>
    )
  }

  const Placeholder = () => {
    return (
      <div className='row no-gutters black-text huge-bottom'>
        <div className='col-xs-12 no-gutters'>
          <div className='dashed-border lightest-grey-border card-padding'>
            <h2 className='header-24 header-20-sm flat-top line-height-reg flat-bottom align-center'>
              {window.coco ? t('conversation.funAndRewardingConvos') : t('conversation.funAndRewarding')}
            </h2>
            {hostButton()}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      {conversations.length === 0 ? (
        <Placeholder />
      ) : (
        <>
          {conversations.length > 0 && (
            <div className='huge-bottom'>
              {[...conversations]
                .sort((b, a) => moment(a.startsAt).diff(moment(b.startsAt)))
                .map((conversation) => (
                  <ConversationListCard conversation={conversation} isHost={isHost} key={conversation.id} />
                ))}
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default flow(
  connectTopicTheme,
  withTranslation,
  observer,
  inject<any, any>(({ mst }: { mst: IStore }, ownProps) => {
    const {
      auth: { user },
      conversations,
      conversations: { allUpcomingHostedConversations }
    } = mst

    if (user) {
      user.shouldLoad('userConversations', user.loadUserConversations)
      conversations.shouldLoad('hostedList', conversations.loadHostedConversations)
    }

    const list = allUpcomingHostedConversations.filter((d) => d?.host?.id === user?.id)
    return {
      user,
      conversations: list,
      listCount: allUpcomingHostedConversations.length,
      loadingList: conversations.loadingIds.includes('hostedList')
    }
  })
)(HostedConversationsList)
