import React from 'react'
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper'
import { includes, isNull, flow, get, isEmpty } from 'lodash'
import i18n from 'i18next'
import AuthenticatingComponent from '../shared/PageLoadingAnimation'
import { showAlertMessage } from '../../actions/alertMessageActions'
import { getCurrentUser, getCurrentUserAndConversation, getCurrentUserAndTopic } from '../../getters/currentUserGetters'
import { observer, inject } from 'mobx-react'

const SUPER_ADMIN_ROLES = ['super_admin']
const PARTNER_ADMIN_ROLES = ['partner_admin', ...SUPER_ADMIN_ROLES]
const COMMUNITY_ADMIN_ROLES = ['community_admin', ...PARTNER_ADMIN_ROLES]
const USER_ROLES = ['user', ...COMMUNITY_ADMIN_ROLES]

export const isSuperAdmin = (user) => includes(SUPER_ADMIN_ROLES, user.role)
export const isPartnerAdmin = (user) => includes(PARTNER_ADMIN_ROLES, user.role)
export const isCommunityAdmin = (user) => includes(COMMUNITY_ADMIN_ROLES, user.role)
const isUser = (user) => includes(USER_ROLES, user.role)
const isHost = (user, conversation) => !isEmpty(conversation) && get(conversation.host, 'id') === user.id

export const authenticatedSelector = (state, props) => Boolean(getCurrentUser(state, props).id)
export const authenticatedPredicate = (predicate) => (state, props) => authenticatedSelector(state) && predicate(getCurrentUser(state, props))
const redirectAction = (newLoc) => (dispatch) => {
  dispatch(showAlertMessage({ message: i18n.t('auth.notAuthorized') }))
}

const authenticatedUserTopic = (predicate) => (state, ownProps) => {
  const { user, topic } = getCurrentUserAndTopic(state, ownProps)
  if (user && topic) {
    return predicate({ user, topic, ownProps })
  }
}

const authenticatedUserConversation = (predicate) => (state, ownProps) => {
  const { user, conversation } = getCurrentUserAndConversation(state, ownProps)
  if (user && conversation) {
    return predicate({ user, conversation, ownProps })
  }
}

const authenticatingSelector = (state) => isNull(state.currentUser) && state.session.active

// ++++++++++++ For wrapping routes - redirects if unauthorized +++++++++++++

/*
Migrating from Version 1.x
https://mjrussell.github.io/redux-auth-wrapper/docs/Migrating.html

* The main changes are the following:

- Done - Combined authSelector and predicate into a single authenticatedSelector
- Done - No longer passed authData as a prop to child components. This was the return value of authSelector. If you need your auth data, just connect it at a lower level.
- Done - renamed LoadingComponent to AuthenticatingComponent
- Done - renamed failureRedirectPath to redirectPath
- Done - redirectPath no longer defaults to /login
- Done - removed FailureComponent from the redirect helper, see Migrating failure and alternative components for details
- Done - Removed mapProps. If you need to prevent passing down any props from redux-auth-wrapper, use mapProps from recompose.

* Migrating failure and alternative components

- Done - Combined authSelector and predicate into a single authenticatedSelector
- Done - FailureComponent is optional now, not specifying it will render nothing (null) when the authenticatedSelector returns false
- All properties besides authenticatedSelector, authenticatingSelector, FailureComponent, and wrapperDisplayName have been removed

TODO: remove me after migration complete
*/

export const UserIsAuthenticated = connectedRouterRedirect({
  authenticatingSelector,
  authenticatedSelector,
  AuthenticatingComponent,
  wrapperDisplayName: 'UserIsAuthenticated',
  allowRedirectBack: false,
  redirectAction,
  redirectPath: '/'
})

export const UserIsViewingSelf = connectedRouterRedirect({
  authenticatingSelector,
  authenticatedSelector: authenticatedPredicate((user) => user.isViewingSelf),
  AuthenticatingComponent,
  wrapperDisplayName: 'UserIsViewingSelf',
  allowRedirectBack: false,
  redirectAction,
  redirectPath: '/'
})

export const UserIsSuperAdmin = connectedRouterRedirect({
  authenticatingSelector,
  authenticatedSelector: authenticatedPredicate((user) => isSuperAdmin(user)),
  AuthenticatingComponent,
  wrapperDisplayName: 'UserIsSuperAdmin',
  redirectPath: '/',
  allowRedirectBack: false,
  redirectAction
})

export const UserIsGeneralPartnerAdmin = connectedRouterRedirect({
  authenticatingSelector,
  authenticatedSelector: authenticatedPredicate((user) => isPartnerAdmin(user)),
  AuthenticatingComponent,
  wrapperDisplayName: 'UserIsGeneralPartnerAdmin',
  redirectPath: '/',
  allowRedirectBack: false,
  redirectAction
})

export const UserIsGeneralCommunityAdmin = connectedRouterRedirect({
  authenticatingSelector,
  authenticatedSelector: authenticatedPredicate((user) => isCommunityAdmin(user)),
  AuthenticatingComponent,
  wrapperDisplayName: 'UserIsGeneralCommunityAdmin',
  redirectPath: '/',
  allowRedirectBack: false,
  redirectAction
})

// for topics
// export const UserIsSpecificPartnerAdmin = connectedRouterRedirect({
//   authenticatingSelector,
//   authenticatedSelector: authenticatedUserTopic(
//     ({ user, topic }) => isSuperAdmin(user) || (isPartnerAdmin(user) && includes(user.communityAdminAuthorizedTopicIds, topic.id))
//   ),
//   AuthenticatingComponent,
//   wrapperDisplayName: 'UserIsSpecificPartnerAdmin',
//   redirectPath: '/',
//   allowRedirectBack: false,
//   redirectAction
// })
export const UserIsSpecificPartnerAdmin = (Comp) =>
  inject(({ mst: { topics } }, ownProps) => {
    const {
      match: {
        params: { slug }
      }
    } = ownProps
    const topic = topics.list.find((c) => c.slug === slug)
    if (!topic && !topics.topicLoading) {
      topics.loadBySlug(slug)
    }
    return { auth_topic: topic }
  })(
    observer(({ auth_topic, ...rest }) => {
      return auth_topic && auth_topic.canEdit && <Comp {...rest} />
    })
  )

// for topics
export const UserIsSpecificCommunityAdmin = connectedRouterRedirect({
  authenticatingSelector,
  authenticatedSelector: authenticatedUserTopic(
    ({ user, topic }) => isSuperAdmin(user) || (isCommunityAdmin(user) && includes(user.communityAdminAuthorizedTopicIds, topic.id))
  ),
  AuthenticatingComponent,
  wrapperDisplayName: 'UserIsSpecificCommunityAdmin',
  redirectPath: '/',
  allowRedirectBack: false,
  redirectAction
})

export const UserIsHostOrSpecificPartnerAdmin = connectedRouterRedirect({
  authenticatingSelector,
  authenticatedSelector: authenticatedUserConversation(
    //TODO: we need to wait on conversation data to populate for partner admins to be able to access a direct link here
    ({ user, conversation }) =>
      isHost(user, conversation) || isSuperAdmin(user) || (isPartnerAdmin(user) && includes(user.communityAdminAuthorizedTopicIds, get(conversation, 'topicId')))
  ),
  AuthenticatingComponent,
  wrapperDisplayName: 'UserIsHostOrSpecificPartnerAdmin',
  redirectPath: '/',
  allowRedirectBack: false,
  redirectAction
})

export const UserIsHostOrSpecificCommunityAdmin = connectedRouterRedirect({
  authenticatingSelector,
  authenticatedSelector: authenticatedUserConversation(
    ({ user, conversation }) => isHost(user, conversation) || isSuperAdmin(user) || (isCommunityAdmin(user) && includes(user.communityAdminAuthorizedTopicIds, conversation.topicId))
  ),
  AuthenticatingComponent,
  wrapperDisplayName: 'UserIsHostOrSpecificCommunityAdmin',
  redirectPath: '/',
  allowRedirectBack: false,
  redirectAction
})

// for partners
export const UserIsSpecificPartnerAdminForPartner = connectedRouterRedirect({
  authenticatingSelector,
  authenticatedSelector: (state, ownProps) => {
    const user = getCurrentUser(state)
    const slug = ownProps.match.params.slug
    if (user && slug) {
      const authorized = includes(user.partnerSlugs, slug)
      return isSuperAdmin(user) || (isPartnerAdmin(user) && authorized)
    }
  },

  AuthenticatingComponent,
  wrapperDisplayName: 'UserIsSpecificPartnerAdmin',
  redirectPath: '/',
  allowRedirectBack: false,
  redirectAction
})

export const UserIsHost = connectedRouterRedirect({
  authenticatingSelector,
  authenticatedSelector: (state, ownProps) => {
    const { user, conversation } = getCurrentUserAndConversation(state, ownProps)
    if (user && conversation) {
      return isSuperAdmin(user) || isHost(user, conversation)
    }
  },
  AuthenticatingComponent,
  wrapperDisplayName: 'UserIsHost',
  redirectPath: '/',
  allowRedirectBack: false,
  redirectAction
})

// ++++++++++++ For wrapping components or DOM elements - will not redirect +++++++++++++
export const VisibleOnlyLoggedIn = connectedAuthWrapper({
  authenticatedSelector,
  wrapperDisplayName: 'VisibleOnlyLoggedIn'
})

// export const VisibleOnlySuperAdmin = connectedAuthWrapper({
//   authenticatedSelector: authenticatedPredicate((user) => isSuperAdmin(user)),
//   wrapperDisplayName: 'VisibleOnlySuperAdmin'
// })

// export const VisibleOnlyUser = connectedAuthWrapper({
//   authenticatedSelector: authenticatedPredicate((user) => isUser(user)),
//   wrapperDisplayName: 'VisibleOnlyUser'
// })

// export const VisibleGeneralPartnerAdmin = connectedAuthWrapper({
//   authenticatedSelector: authenticatedPredicate((user) => isPartnerAdmin(user)),
//   wrapperDisplayName: 'VisibleGeneralPartnerAdmin'
// })

// export const VisibleGeneralCommunityAdmin = connectedAuthWrapper({
//   authenticatedSelector: authenticatedPredicate((user) => isCommunityAdmin(user)),
//   wrapperDisplayName: 'VisibleGeneralCommunityAdmin'
// })

export const VisibleGeneralCommunityAdmin = (Comp) =>
  inject(
    ({
      mst: {
        auth: { user }
      }
    }) => {
      return { auth_user: user }
    }
  )(
    observer(({ auth_user, ...rest }) => {
      return auth_user && auth_user.isCommunityAdmin() ? <Comp {...rest} /> : null
    })
  )

export const VisibleGeneralPartnerAdmin = (Comp) =>
  inject(
    ({
      mst: {
        auth: { user }
      }
    }) => {
      return { auth_user: user }
    }
  )(
    observer(({ auth_user, ...rest }) => {
      return auth_user && auth_user.isPartnerAdmin() ? <Comp {...rest} /> : null
    })
  )

export const VisibleOnlySuperAdmin = (Comp) =>
  inject(
    ({
      mst: {
        auth: { user }
      }
    }) => {
      return { auth_user: user }
    }
  )(
    observer(({ auth_user, ...rest }) => {
      return auth_user && auth_user.isSuperAdmin() ? <Comp {...rest} /> : null
    })
  )

export const VisibleOnlyUser = (Comp) =>
  inject(
    ({
      mst: {
        auth: { user }
      }
    }) => {
      return { auth_user: user }
    }
  )(
    observer(({ auth_user, ...rest }) => {
      return auth_user && auth_user.isUser() ? <Comp {...rest} /> : null
    })
  )

// need to pass in slug
export const ConvoVisibleSpecificPartnerAdmin = flow(
  VisibleOnlyUser,
  connectedAuthWrapper({
    authenticatedSelector: authenticatedUserTopic(
      ({ user, topic }) =>
        Object.keys(user).length > 0 &&
        Boolean(topic) &&
        (isSuperAdmin(user) || (isPartnerAdmin(user) && includes(user.communityAdminAuthorizedTopicIds, topic.id)))
    ),
    wrapperDisplayName: 'ConvoVisibleSpecificPartnerAdmin'
  })
)

// need to pass in slug
export const ConvoVisibleSpecificCommunityAdmin = flow(
  VisibleOnlyUser,
  connectedAuthWrapper({
    authenticatedSelector: authenticatedUserTopic(
      ({ user, topic }) =>
        Object.keys(user).length > 0 &&
        Boolean(topic) &&
        (isSuperAdmin(user) || (isCommunityAdmin(user) && includes(user.communityAdminAuthorizedTopicIds, topic.id)))
    ),
    wrapperDisplayName: 'VisibleSpecificCommunityAdmin'
  })
)

// need to pass in partner id
export const PartnerVisibleSpecificPartnerAdmin = flow(
  VisibleOnlyUser,
  connectedAuthWrapper({
    authenticatedSelector: (state, ownProps) => {
      const user = getCurrentUser(state)
      const slug = ownProps.slug
      if (user && slug) {
        const authorized = includes(user.partnerSlugs, slug)
        return Object.keys(user).length > 0 && Boolean(slug) && (isSuperAdmin(user) || (isPartnerAdmin(user) && authorized))
      }
    },
    wrapperDisplayName: 'VisibleSpecificPartnerAdmin'
  })
)

// need to pass in conversationId since HostEditLink is wrapped in VisibleOnlyHost which expects conversationId route params
export const VisibleOnlyHost = flow(
  VisibleOnlyUser,
  connectedAuthWrapper({
    authenticatedSelector: authenticatedUserConversation(({ user, conversation }) => isSuperAdmin(user) || isHost(user, conversation)),
    wrapperDisplayName: 'VisibleOnlyHost'
  })
)

// need to pass in conversationId and topicId
export const ConversationVisibleHostOrSpecificCommunityAdmin = flow(
  VisibleOnlyUser,
  connectedAuthWrapper({
    authenticatedSelector: authenticatedUserConversation(
      ({ user, conversation, ownProps: { topicId } }) =>
        Object.keys(user).length > 0 &&
        Boolean(topicId) &&
        (isHost(user, conversation) || isSuperAdmin(user) || (isCommunityAdmin(user) && includes(user.communityAdminAuthorizedTopicIds, topicId)))
    ),
    wrapperDisplayName: 'ConversationVisibleHostOrSpecificCommunityAdmin'
  })
)

// need to pass in conversationId and topicId
export const ConversationVisibleHostOrSpecificPartnerAdmin = flow(
  VisibleOnlyUser,
  connectedAuthWrapper({
    authenticatedSelector: authenticatedUserConversation(
      ({ user, conversation, ownProps: { topicId } }) =>
        Object.keys(user).length > 0 &&
        Boolean(topicId) &&
        (isHost(user, conversation) || isSuperAdmin(user) || (isPartnerAdmin(user) && includes(user.communityAdminAuthorizedTopicIds, topicId)))
    ),
    wrapperDisplayName: 'ConversationVisibleHostOrSpecificPartnerAdmin'
  })
)

// need to pass in conversationId and topicId
export const ConversationVisibleSpecificPartnerAdmin = flow(
  VisibleOnlyUser,
  connectedAuthWrapper({
    authenticatedSelector: authenticatedUserConversation(
      ({ user, ownProps: { topicId } }) =>
        Object.keys(user).length > 0 &&
        Boolean(topicId) &&
        (isSuperAdmin(user) || (isPartnerAdmin(user) && includes(user.communityAdminAuthorizedTopicIds, topicId)))
    ),
    wrapperDisplayName: 'ConversationVisibleHostOrSpecificPartnerAdmin'
  })
)
