import queryString from 'query-string'
import { get } from './utils'

class UsersTableApi {
  static getGuests(args) {
    const defaultParams = {
      page: 0,
      site_name: global.siteName
    }
    const params = queryString.stringify({ ...defaultParams, ...args })

    return get(`/admin/guests?${params}`)
  }

  static getHosts(args) {
    const defaultParams = {
      page: 0,
      site_name: global.siteName
    }
    const params = queryString.stringify({ ...defaultParams, ...args })

    return get(`/admin/hosts?${params}`)
  }

  static getUsers(args) {
    const defaultParams = {
      page: 0,
      site_name: global.siteName
    }
    const params = queryString.stringify({ ...defaultParams, ...args })

    return get(`/admin/users?${params}`)
  }
}

export default UsersTableApi
