import React, { useContext, useRef } from 'react'
import { assign } from 'lodash'

import { FacebookLogin } from 'react-facebook-login-component'
import i18next from '../../../i18n'
import { TextFieldControl } from '../../utils'
import { required, email } from '../../validations/fieldLevelValidations'
import { useForm } from 'react-hook-form'
import ReCAPTCHA from 'react-google-recaptcha'
import { Button, createStyles, Divider, Grid, makeStyles } from '@material-ui/core'
import { AuthContext } from '../../Providers/AuthProvider'
import { verifyUniqueEmail } from '../../../actions/sessionActions'
import { useTranslation } from 'react-i18next'
import GoogleLogin from '../Login/GoogleLogin'

interface Props {
  onLogin?()
  onSignUp?()
  hosting?: boolean
}

const Signup1Form = ({ onLogin, onSignUp, hosting }: Props) => {
  const { handleSubmit, errors, formState, control, watch, setValue, trigger } = useForm({ mode: 'onBlur', shouldUnregister: false })
  const recaptchaRef = useRef(null)
  const { isDirty, isSubmitting, isValid } = formState
  const { signupUser, loginWithFacebook } = useContext(AuthContext)
  const { t, i18n } = useTranslation()

  const moveToNextStep = () => {
    onSignUp && onSignUp()
  }
  const handleSave = handleSubmit((data: any, e) => {
    signupUser(data).then(moveToNextStep)
  })

  const builtValues = (data) => {
    return assign({}, data, {
      conversationRoleInterest: { hosting },
      locale: i18n.language
    })
  }

  const responseFacebook = (response) => {
    loginWithFacebook(builtValues(response)).then(moveToNextStep)
  }
  const linkClass = 'link-text'

  const validatePass = () => {
    watch('password_confirmation') && trigger('password_confirmation')
  }
  const classes = Styles()

  return (
    <div className={classes.root}>
      <h3 className={classes.header}>{t('auth.signupForAccount')}</h3>
      <div className='align-center'>
        <p>
          {t('auth.alreadyRegistered')}&nbsp;
          <a className={linkClass} onClick={onLogin}>
            {t('auth.loginHere')}
          </a>
        </p>
      </div>

      <form onSubmit={handleSave}>
        <TextFieldControl
          label={t('common.firstName')}
          rules={{ validate: { required } }}
          name='firstName'
          control={control}
          error={errors && errors.firstName}
          className='fat-top'
        />
        <TextFieldControl label={t('common.lastName')} rules={{ validate: { required } }} name='lastName' control={control} error={errors && errors.lastName} className='fat-top' />
        <TextFieldControl
          label={t('common.email')}
          rules={{
            validate: {
              required,
              email,
              uniqness: async (val) => {
                return verifyUniqueEmail({ email: val }).catch((err) => {
                  return err?.email[0]
                })
              }
            }
          }}
          name='email'
          control={control}
          error={errors && errors.email}
          className='fat-top'
        />
        <TextFieldControl
          label={t('auth.password')}
          rules={{ validate: { required } }}
          name='password'
          control={control}
          type='password'
          error={errors && errors.password}
          className='fat-top'
          onKeyUp={validatePass}
        />
        <TextFieldControl
          label={t('auth.confirmPass')}
          rules={{ validate: { required, passwordConfirmation: (val) => (val === watch('password') ? undefined : t('auth.passMustMatch')) } }}
          name='password_confirmation'
          control={control}
          type='password'
          error={errors && errors.password_confirmation}
          className='reg-bottom fat-top'
        />
        <TextFieldControl name='recaptchaResponse' rules={{ validate: { required } }} control={control} className='hide' />
        <div className={classes.actionContainer}>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={window.captchaSiteKey}
            onChange={(val) => {
              setValue('recaptchaResponse', val)
              trigger()
            }}
          />
          <Button size='large' disabled={!isDirty || isSubmitting || !isValid} type='submit' color='primary' variant='contained' className={classes.signUpButton}>
            {t('auth.signupWithEmail')}
          </Button>
        </div>
      </form>

      <div className='align-center'>
        <div className={classes.divider}>
          <Grid container justify='center' alignItems='center' spacing={1}>
            <Grid item xs={5}>
              <Divider />
            </Grid>
            <Grid item>{t('common.or')}</Grid>
            <Grid item xs={5}>
              <Divider />
            </Grid>
          </Grid>
        </div>
        <div className='facebook-login-btn'>
          <FacebookLogin
            socialId={process.env.REACT_APP_FACEBOOK_APP_ID}
            language={i18next.language}
            scope='public_profile, email'
            responseHandler={responseFacebook}
            xfbml={true}
            version='v2.8'
            buttonText={t('auth.fbSignup')}
            fields='id, email, first_name, last_name, picture'
          />
        </div>
      </div>
      <div className='align-center reg-top'>
        <div className={classes.divider}>
          <Grid container justify='center' alignItems='center' spacing={1}>
            <Grid item xs={5}>
              <Divider />
            </Grid>
            <Grid item>{t('common.or')}</Grid>
            <Grid item xs={5}>
              <Divider />
            </Grid>
          </Grid>
        </div>
        <GoogleLogin label='Sign Up with Google' style={{ marginTop: '.5em' }} />

        <div
          className='reg-top header-14 reg-bottom reg-gutters'
          dangerouslySetInnerHTML={{
            __html: t('auth.joinAgreement', { linkClass })
          }}
        />
      </div>
    </div>
  )
}

const Styles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: '2em',
      fontamily: 'Avenir'
    },
    header: {
      textAlign: 'center',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '32px'
    },
    subHeader: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '24px'
    },
    actionContainer: {
      maxWidth: '304px',
      margin: '1em auto',
      textAlign: 'center'
    },
    divider: {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '21px',
      color: '#6D6E71',
      paddingBottom: '1em'
    },
    signUpButton: {
      marginTop: '1em',
      width: '265px',
      height: '48px',
      fontStyle: 'normal',
      fontWeight: 800,
      fontSize: '18px',
      lineHeight: '26px'
    },
    terms: {
      fontSize: '12px',
      lineHeight: '19px',

      textAlign: 'center',

      color: '#212122'
    }
  })
)

export default Signup1Form
