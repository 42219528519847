import { getRoot, types } from 'mobx-state-tree'
// import { IPartners } from '../Partners'
import { IAuth } from '../Auth'
import { IConversations } from '../Conversations'
import { IDownloads } from '../Downloads'

export const RootAccessable = types.model({}).actions((self) => ({
  getAuth(): IAuth {
    const { auth } = getRoot(self)
    return auth
  },
  getPartners(): FIXME {
    const { partners } = getRoot(self)
    return partners
  },
  getConversations(): IConversations {
    const { conversations } = getRoot(self)
    return conversations
  },
  getDownloads(): IDownloads {
    const { downloads } = getRoot(self)
    return downloads
  }
}))
