import React, { Component } from 'react'

import { inject, observer } from 'mobx-react'
import { IStore } from '../../../../store/models'
import { withRouter } from 'react-router'
import { flow } from 'lodash'
import PartnerActions from '../partials/PartnerActions'
import DataTable, { IColData, ValueLabel } from '../../../shared/DataTableFilterable'
import { IPartner } from '../../../../store/models/Partner'
import { Link } from 'react-router-dom'
import { IOrganization } from '../../../../store/models/Organization/Organization'
import { Grid, Icon, IconButton, Tooltip } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
class OrganizationsTable extends Component<any, any> {
  render() {
    const { user, items, loading, filterable } = this.props

    const columnData: IColData[] = [
      { name: 'id', title: 'AEXID', width: 100, align: 'right', sortable: true },
      { name: 'name', title: 'Name', width: 300, sortable: true },
      { name: 'partnersCount', title: 'Associated Customer Pages', sortable: true },
      { name: 'churnzeroSync', title: 'CZ sync', width: 100, sortable: true },
      { name: 'actions', title: 'Actions', width: 220, align: 'center' }
    ]

    return (
      <DataTable
        height={'calc(100vh / 4)'}
        data={items}
        columns={columnData}
        loading={loading}
        filterable={filterable}
        rightColumns={['actions']}
        searchPlaceholder='Search'
        getCellValue={(row: IOrganization, columnName: string) => {
          switch (columnName) {
            case 'actions':
              return (
                <Grid container direction='row' justify='space-around' alignItems='center'>
                  <Tooltip title='Edit'>
                    <Link to={`/admin/organizations/${row.id}/edit`}>
                      <IconButton size='small'>
                        <i className='fas fa-pen opacity-8'></i>
                      </IconButton>
                    </Link>
                  </Tooltip>
                </Grid>
              )
            case 'churnzeroSync':
              return row[columnName] && <i className='fas fa-check opacity-5'></i>
            case 'id':
              return <ValueLabel value={row.aexid} />
            default:
              return <ValueLabel value={row[columnName]} />
          }
        }}
      />
    )
  }
}

export default flow(
  observer,
  inject<FIXME, FIXME>(
    ({
      mst: {
        auth: { user },
        organizations
      }
    }: {
      mst: IStore
    }) => {
      return {
        user,
        organizations,
        filterable: organizations,
        items: organizations.list,
        loading: organizations.loadingIds.includes('organizations_table')
      }
    }
  ),
  withRouter
)(OrganizationsTable)
