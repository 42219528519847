import { types, Instance } from 'mobx-state-tree'
import TrackBase from './TrackBase'
import { Alertable } from '../helpers/Alertable'
import Conversation from '../Conversation'
import Membership from './Membership'
import TracksApi from '../../../api/TracksApi'
import { removeNullStringValue } from '../utils'
import { Filterable, IFilterableArgs } from '../helpers'

export const Track = types
  .compose(
    'Track',
    TrackBase,
    Alertable,
    Filterable,
    types.model({
      partnerId: types.maybeNull(types.number),
      totalMembers: types.maybeNull(types.number),
      slug: types.maybeNull(types.string),
      dealName: types.maybe(types.string),
      owner: types.maybeNull(types.boolean),
      token: types.maybeNull(types.string),
      price: types.maybeNull(types.string),
      conversations: types.array(Conversation),
      memberships: types.array(Membership),
      list: types.optional(types.array(Membership), []),
      header: types.maybeNull(types.string),
      description: types.maybeNull(types.string),
      loginHeaderText: types.maybeNull(types.string),
      joinCtaText: types.maybeNull(types.string),
      joinCtaButton: types.maybeNull(types.string),
      joinCtaLink: types.maybeNull(types.string)
    })
  )
  .actions((self) => ({
    addToMemberships: (membership) => {
      self.memberships.push(membership)
    }
  }))
  .actions((self) => ({
    addConversation: (conversation_id: number) => {
      return TracksApi.addConversation(self.partnerId, self.id, conversation_id).then(({ response: { ok }, json }) => {
        if (ok && json) {
          self.updateMe(json)
          self.showAlert('Conversation Added')
        }
      })
    },
    createMembership: (data) => {
      return TracksApi.addMembership(self.id, data).then(({ response: { ok }, json }) => {
        if (ok && json) {
          self.addToMemberships(json)
          self.showAlert('Membership Created')
        }
      })
    },
    removeConversation: (conversation_id: number) => {
      return TracksApi.removeConversation(self.partnerId, self.id, conversation_id).then(({ response: { ok }, json }) => {
        if (ok && json) {
          self.updateMe(json)
          self.showAlert('Conversation Removed')
        }
      })
    }
  }))
  .actions((self) => ({
    setList(data: any[], totalCount?: number) {
      data.forEach((d) => removeNullStringValue(d))
      self.list.replace(data)
      self.setTotalCount(totalCount)
    }
  }))
  .actions((self) => ({
    loadMembershipsTable: (args: IFilterableArgs) => {
      if (self.isLoading('memberships_table')) {
        return
      }
      self.startLoading('memberships_table')
      self.updateArgs(args)

      return TracksApi.getAllMembershipsTable({ ...args, partner_id: self.partnerId }).then(({ response: { ok, statusText }, json: { memberships, total } }) => {
        if (ok) {
          self.setList(memberships, total)
        } else {
          self.showAlert(statusText)
        }
        self.stopLoading('memberships_table')
      })
    }
  }))
  .actions((self) => ({
    loadFilter: ({ reset = false, clear = true } = {}) => {
      if (reset) {
        self.page = 0
        self.pageSize = 15
        self.search = ''
      }

      if (clear) {
        self.list.replace([])
      }

      const { search, pageSize: page_size, page, sortedId: sorted_id, sortedPriority: sorted_priority } = self
      return self.loadMembershipsTable({ search, page_size, page, sorted_id, sorted_priority })
    }
  }))

export interface ITrack extends Instance<typeof Track> {}
export default Track
