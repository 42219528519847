import { flow, groupBy, uniq, sortBy } from 'lodash'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { Prompt, withRouter } from 'react-router'
import LoadingAnimation from '../shared/LoadingAnimation'
import { UNSAVED_FORM_WARNING } from '../utils/constants'
import DocumentGroup from './DocumentGroup'
import DocumentItem from './DocumentItem'
import { Form } from './Form'

interface Props {
  documentable: FIXME
  type: FIXME
}

const Edit = ({ documentable }: Props) => {
  const [dirty, setDirty] = useState(false)
  useEffect(() => {
    documentable && documentable.shouldLoad('documents', documentable.loadTopicDocuments)
  }, [documentable])

  const hostGuide = documentable.hostGuide
  const marketingToolKit = documentable.marketingToolKit
  const additionalFiles = documentable.additionalFiles

  const additionalFilesGrouped = sortBy(Object.entries(groupBy(additionalFiles, (doc) => doc.groupId)), (k, _) => k)
  const groupIds = uniq<string>(additionalFiles.filter((d) => d.groupId).map((d) => d.groupId))

  return (
    <div>
      <div>
        <h3>Host Guide</h3>
        {hostGuide ? (
          <DocumentItem
            key={hostGuide.id}
            document={hostGuide}
            onDelete={() => {
              documentable?.deleteDocument(hostGuide.id)
            }}
          />
        ) : (
          <>
            {documentable?.canUploadDocument() && (
              <Form
                onDirty={(d) => {
                  setDirty(d)
                }}
                onCancel={() => {
                  setDirty(false)
                }}
                onSubmit={(data) => documentable?.createDocument(data, 'hostGuide')}
              />
            )}
          </>
        )}
      </div>
      <div>
        <h3>Marketing Toolkit</h3>
        {marketingToolKit ? (
          <DocumentItem
            key={marketingToolKit.id}
            document={marketingToolKit}
            onDelete={() => {
              documentable?.deleteDocument(marketingToolKit.id)
            }}
          />
        ) : (
          <>
            {documentable?.canUploadDocument() && (
              <Form
                onDirty={(d) => {
                  setDirty(d)
                }}
                onCancel={() => {
                  setDirty(false)
                }}
                onSubmit={(data) => documentable?.createDocument(data, 'marketingToolKit')}
              />
            )}
          </>
        )}
      </div>
      <div>
        <h3>Additional Files {additionalFiles && additionalFiles.length > 1 && `(${additionalFiles.length} files)`}</h3>

        {documentable.loadingIds.includes('documents') ? (
          <LoadingAnimation />
        ) : (
          additionalFilesGrouped.map(([groupId, documents]) => (
            <DocumentGroup groupId={groupId} key={groupId} documents={documents} documentable={documentable} groups={groupIds} />
          ))
        )}
      </div>

      {documentable?.canUploadDocument() && (
        <div className='reg-top'>
          <Form
            onDirty={(d) => {
              setDirty(d)
            }}
            onCancel={() => {
              setDirty(false)
            }}
            onSubmit={(data) => documentable?.createDocument(data, 'additionalFiles')}
            grouped={true}
            groupIds={groupIds}
          />
        </div>
      )}
      <Prompt when={dirty} message={UNSAVED_FORM_WARNING} />
    </div>
  )
}

export default flow(observer, withRouter)(Edit)
