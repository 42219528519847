import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import { flow } from 'lodash'
import { IFilterable, IStore, IUser } from '../../store'
import { inject } from 'mobx-react'

const UsersProvider = ({ children, users, usersLoading, loadUsers, reloadUsers, filterable, setAttendeesType }: Props & InjectedProps) => {
  return <UserContext.Provider value={{ users, usersLoading, loadUsers, reloadUsers, filterable, setAttendeesType }}>{children}</UserContext.Provider>
}

interface Props {
  children?: React.ReactNode | React.ReactNode[]
}

interface InjectedProps {
  filterable: IFilterable
  users: IUser[]
  usersLoading: boolean
  setAttendeesType(n: string): void
  loadUsers()
  reloadUsers()
}

export const UserContext = React.createContext<InjectedProps>({
  filterable: {} as any,
  users: [],
  usersLoading: false,
  setAttendeesType: () => {
    // tbd
  },
  loadUsers: () => {
    // tbd
  },
  reloadUsers: () => {
    // tbd
  }
})

export const useUsers = () => useContext(UserContext)

export default flow(
  observer,
  inject<InjectedProps, Props>(
    ({
      mst: {
        users,
        auth: { user }
      }
    }: {
      mst: IStore
    }) => {
      user && users.shouldLoad('users_table', users.loadFilter)

      return {
        filterable: users,
        users: users.list,
        usersLoading: users.loadingIds.includes('users_table'),
        setAttendeesType: users.setAttendeesType,
        loadUsers: () => user && users.shouldLoad('users_table', users.loadFilter),
        reloadUsers: () => user && users.loadFilter()
      }
    }
  )
)(UsersProvider)
