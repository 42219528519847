import React, { useContext, useState } from 'react'
import { observer } from 'mobx-react'
import { flow } from 'lodash'
import { IStore, ITrack } from '../../store'
import { inject } from 'mobx-react'
import { useParamPartner } from './ParamPartnerProvider'

const PartnerTracksProvider = ({ children, tracks, loadTracks }: Props & InjectedProps) => {
  const { partner } = useParamPartner()
  const [trackLoading, settrackLoading] = useState(false)
  const [trackLoaded, settrackLoaded] = useState(false)
  return (
    <PartnerTrackContext.Provider
      value={{
        tracks,
        trackLoading,
        trackLoaded,
        loadTracks: (partnerId?: number) => {
          if (trackLoading) {
            return Promise.resolve()
          }
          settrackLoading(true)
          return loadTracks(partnerId || partner?.id).then(() => {
            settrackLoading(false)
            settrackLoaded(true)
          })
        },
        reloadTracks: (partnerId?: number) => {
          if (trackLoading) {
            return Promise.resolve()
          }
          settrackLoading(true)
          return loadTracks(partnerId || partner?.id).then(() => {
            settrackLoading(false)
            settrackLoaded(true)
          })
        }
      }}
    >
      {children}
    </PartnerTrackContext.Provider>
  )
}

interface Props {
  children?: React.ReactNode | React.ReactNode[]
}

interface InjectedProps {
  tracks: ITrack[]
  trackLoading: boolean
  trackLoaded: boolean
  loadTracks(partnerId?: number): Promise<any>
  reloadTracks(partnerId?: number): Promise<any>
}

export const PartnerTrackContext = React.createContext<InjectedProps>({
  tracks: [],
  trackLoading: false,
  trackLoaded: false,
  loadTracks: (partnerId?: number) => {
    return Promise.resolve()
  },
  reloadTracks: (partnerId?: number) => {
    return Promise.resolve()
  }
})

export const usePartnerTracks = () => useContext(PartnerTrackContext)

export default flow(
  observer,
  inject<InjectedProps, Props>(
    ({
      mst: {
        tracks,
        auth: { user }
      }
    }: {
      mst: IStore
    }) => {
      return {
        tracks: tracks.list,
        trackLoading: false,
        trackLoaded: false,
        loadTracks: (partnerId: number) => tracks.shouldLoad(partnerId, tracks.loadByPartnerId(partnerId)),
        reloadTracks: (partnerId: number) => tracks.loadByPartnerId(partnerId)
      }
    }
  )
)(PartnerTracksProvider)
