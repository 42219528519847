import React from 'react'
import { flow } from 'lodash'
import Card from '@material-ui/core/Card'

import { connectTopicTheme } from '../../connectTheme.js'
import { observer } from 'mobx-react'
import Button from '@material-ui/core/Button'
import { Props } from 'react-share/lib/ShareButton.js'

interface IProps {
  onDelete: () => void
  onEdit?: (id) => void
  topic: any
  theme: any
}

const ListCard = (props: IProps) => {
  const {
    onDelete,
    onEdit,
    topic: { id, slug, title, icon, cardBanner },
    theme
  } = props

  const Icon = () =>
    slug !== 'kai-and-korero' ? (
      <img className='img-responsive' src={icon.primary.url} alt='icon' />
    ) : (
      <img className='img-responsive' src='https://civicdinners-manual-storage.s3.amazonaws.com/topics/icons/civic-conversations-240.png' alt='icon' />
    )

  return (
    <Card className='fat-bottom'>
      <div className={'row no-gutters black-text'}>
        <div
          className='col-xs-3 no-gutters flex-center'
          style={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            minHeight: '100px'
          }}
        >
          <div className='center-margin'>
            <div className='convo-icon-xs center-margin'>
              <Icon />
            </div>
          </div>
        </div>

        <div className='row flat-left middle-xs no-gutters flat-right-padding'>
          <div className='col-xs-12'>
            <div className='box'>
              <p className='header-20 flat-top flat-bottom'>
                <a className='black-text' target='blank' href={`/${slug}`}>
                  {title}
                </a>
              </p>
              <p className='header-14 light-grey-text italic flat-top flat-bottom'>{cardBanner}</p>
            </div>
          </div>
        </div>
        {onDelete ||
          (onEdit && (
            <div className='col-xs-2 center-xs around-xs reg-top-padding'>
              {onDelete && (
                <Button className='reg-bottom thin-right-xs' onClick={onDelete}>
                  Delete
                </Button>
              )}

              {onEdit && (
                <Button className='reg-bottom thin-right-xs' onClick={() => onEdit(id)}>
                  Edit
                </Button>
              )}
            </div>
          ))}
      </div>
    </Card>
  )
}

export default flow(connectTopicTheme, observer)(ListCard)
