import React, { Component } from 'react'
import { connect } from 'react-redux'
import FlatButton from '@material-ui/core/Button'
import { isUndefined } from 'lodash'

import ProfileImageUploader from '../users/ProfileImageUploader'
import getConversationShowProps from '../../getters/conversationShowGetter'
import { getCurrentUser } from '../../getters/currentUserGetters'
import CalendarDropdown from '../shared/CalendarDropdown'
import withTranslation from '../hocs/withTranslation'
import Dialog from '../DialogWrapper'
import { withRouter } from 'react-router'

class JoinedConversationDialog extends Component {
  render() {
    const { conversation, currentUser, t, dialog } = this.props

    if (isUndefined(conversation)) return null

    const uploader = (
      <div>
        <h2>{t('conversation.addProfilePic')}</h2>
        <ProfileImageUploader retainModal={true} />
        <div className='reg-top reg-bottom'>
          <FlatButton label={t('conversation.cancelAddPhoto')} onClick={this.handleClose} variant='text'>
            {t('conversation.cancelAddPhoto')}
          </FlatButton>
        </div>
      </div>
    )

    const profileImageSection = currentUser.hasProfileImage ? null : uploader

    const CalendarButton = () => {
      return (
        <div className='row center-xs'>
          <CalendarDropdown conversation={conversation} />
        </div>
      )
    }

    return (
      <Dialog dialog={dialog} name='joinedConversation'>
        <div className='row'>
          <div className='col-xs-12 center-xs'>
            <h1>{t('conversation.yay', { name: currentUser.firstName })}</h1>

            <p className='xtra-fat-bottom'>{t('conversation.confirmAttendance', { event: conversation.eventTypeSingular })}</p>

            {profileImageSection}

            <CalendarButton />
          </div>
        </div>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const props = getConversationShowProps(state, ownProps)

  props.currentUser = getCurrentUser(state)

  return props
}

export default withRouter(connect(mapStateToProps, null)(withTranslation(JoinedConversationDialog)))
