import { CircularProgress, Grid, IconButton, Tooltip } from '@material-ui/core'
import { flow } from 'lodash'
import { inject } from 'mobx-react'
import { observer } from 'mobx-react'
import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import appAnalytics from '../../../../analytics'
import { IStore } from '../../../../store/models'
import { IDownload } from '../../../../store/models/Downloads'

interface Props {
  download: IDownload
}
interface InjectedProps {
  downloadConversationIds: number[]
}

const TopicActions = ({ download, history, downloadConversationIds }: Props & InjectedProps & RouteComponentProps) => {
  return (
    <Grid container direction='row' justify='space-around' alignItems='center'>
      {download.csvFile ? (
        <Tooltip title='Download'>
          <IconButton
            // disabled={!download.csvFile}
            size='small'
            onClick={() => {
              appAnalytics.event({
                category: 'Admin',
                action: 'Download Icon Clicked'
              })
              if (download.csvFile) {
                window.location.href = download.csvFile
              }
            }}
          >
            <i className='fas fa-cloud-download-alt opacity-8'></i>
          </IconButton>
        </Tooltip>
      ) : (
        <>
          <CircularProgress size={'1.5em'} />
        </>
      )}
    </Grid>
  )
}

export default flow(
  observer,
  inject<FIXME, FIXME>(({ mst: { downloads } }: { mst: IStore }) => {
    return {
      downloadConversationIds: downloads.list.filter((d) => d?.parameters?.conversationId).map((d) => d?.parameters?.conversationId)
    }
  }),
  withRouter
)(TopicActions)
