import React, { useContext, useEffect, useState } from 'react'
import { ParamTopicContext } from '../../Providers/ParamTopicProvider'
import { Controller } from 'react-hook-form'
import { FormHelperText, MenuItem, Button, Menu, Theme, makeStyles, createStyles, Divider } from '@material-ui/core'
import { Editor as DraftWysiwygEditor } from 'react-draft-wysiwyg'
import { EditorState, ContentState, convertToRaw, Modifier } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import 'react-datetime/css/react-datetime.css'
import palette from '../../../themes/palette'
import withTranslation from '../../hocs/withTranslation'
import { observer } from 'mobx-react'

export const WysiwygControl = observer(({ dynamicTags, className, lineHeight = 1.3, label, options, control, error, name, defaultValue, toolbarOnFocus = false, ...rest }: any) => {
  const [eState, setEditorState] = useState<any>(defaultEditorState(defaultValue))

  return (
    <Controller
      name={name}
      control={control}
      error={!!error}
      helperText={error?.message}
      defaultValue={eState}
      {...rest}
      render={(props) => {
        if (typeof props.value !== 'string') {
          props.onChange(draftToHtml(convertToRaw(eState.getCurrentContent())))
        }
        return (
          <>
            <div className={className}>
              {label && <div className='label xtra-fat-top'>{label}</div>}
              <DraftWysiwygEditor
                toolbarOnFocus={toolbarOnFocus}
                editorClassName='rdw-editor-textarea'
                editorState={eState}
                customDecorators={decorators}
                onEditorStateChange={(val) => {
                  setEditorState(val)
                  props.onChange(draftToHtml(convertToRaw(val.getCurrentContent())))
                }}
                editorStyle={{ lineHeight }}
                toolbar={{
                  options: ['fontFamily', 'blockType', 'fontSize', 'inline', 'textAlign', 'list', 'link', 'remove'],
                  textAlign: { inDropdown: true },
                  inline: { inDropdown: true },
                  link: { inDropdown: true },
                  list: { inDropdown: true }
                }}
                toolbarCustomButtons={dynamicTags ? [<DynamicTagsButton key='1' />] : undefined}
              />
            </div>
            {error && <FormHelperText error>{error?.message}</FormHelperText>}
          </>
        )
      }}
    />
  )
})
const Styles = makeStyles((theme: Theme) =>
  createStyles({
    tagGroup: {
      backgroundColor: palette.lightestgrey,
      fontWeight: 'bold'
    }
  })
)

const defaultEditorState = (html: FIXME) => {
  if (!html) return ''

  const blocksFromHTML = htmlToDraft(html)

  const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap)

  return EditorState.createWithContent(state)
}

interface DynamicTagsProps {
  commonly_used: string[]
  topic: string[]
  conversation_information: string[]
  host_information: string[]
  guest_information: string[]
  settings: string[]
  actions?: string[] | undefined
}
const DynamicTagsButton = observer(
  withTranslation((props: any) => {
    const [dynamicTags, setDynamicTags] = useState<DynamicTagsProps>({
      commonly_used: [
        'guest_first_name',
        'host_first_name',
        'client_name',
        'topic_title',
        'conversation_title_linked',
        'conversation_url',
        'conversation_date',
        'host_guide_linked',
        'host_guide_url',
        'survey_link_url',
        'survey_form_link'
      ],
      topic: ['topic_description', 'topic_title', 'topic_url', 'host_guide_linked', 'host_guide_url'],
      conversation_information: [
        'conversation_address',
        'conversation_date',
        'conversation_start_time',
        'conversation_end_time',
        'conversation_title_linked',
        'conversation_url',
        'guest_count',
        'guest_instructions',
        'venue_name',
        'virtual_link',
        'story_form_url',
        'conversation_nps_url'
      ],
      host_information: ['host_email', 'host_first_name', 'host_last_name', 'host_phone'],
      guest_information: ['guest_first_name', 'guest_last_name'],
      settings: [
        'brand_singular',
        'client_name',
        'email_sign_off',
        'event_type_plural',
        'event_type_singular',
        'site_title',
        'virtual_event_type_singular',
        'virtual_event_type_plural'
      ]
    })
    const { topic, topicLoading } = useContext(ParamTopicContext)

    useEffect(() => {
      //load topic actions but not for coco
      !window.coco && topic && topic.shouldLoad('topicActions', topic.loadTopicActions)
    }, [topic])

    if (!window.coco && topic && topic.topicActions.length > 0 && !dynamicTags.actions) {
      setDynamicTags({ ...dynamicTags, actions: ['actions_list'] })
    }

    const classes = Styles()
    const { editorState, onChange, t } = props

    // const [menuOptions] = useState(Object.entries(dynamicTags))
    const menuOptions = Object.entries(dynamicTags)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const addMustache = (keyword = ''): void => {
      const mustache = `{{{ ${keyword} }}}`
      const contentState = editorState.getCurrentContent()
      const selection = editorState.getSelection()
      const entity = contentState.createEntity('variable', 'IMMUTABLE', { keyword })
      const entityKey = entity.getLastCreatedEntityKey()
      const newContentState = Modifier.replaceText(contentState, selection, mustache, editorState.getCurrentInlineStyle(), entityKey)
      const newEditorState = EditorState.push(editorState, newContentState, 'insert-characters')
      const focusOffset = (selection.getFocusOffset() as number) + mustache.length

      const newStateWithSelection = EditorState.forceSelection(
        newEditorState,
        selection.merge({
          focusOffset,
          anchorOffset: focusOffset
        })
      )

      onChange(newStateWithSelection)
      setAnchorEl(null)
    }
    const handleClose = () => {
      setAnchorEl(null)
    }

    return (
      <>
        <Button
          aria-controls='dynamic_tags'
          aria-haspopup='true'
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            setAnchorEl(e.currentTarget)
          }}
        >
          Dynamic Tags
        </Button>
        <Menu disableRestoreFocus anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          {menuOptions.map(([group, tags]) => [
            <MenuItem key={group} disabled={true} className={classes.tagGroup}>
              {t(`convoNotifications.${group}`)}
            </MenuItem>,

            tags.map((tag) => [
              <MenuItem key={tag} onClick={(e) => addMustache(tag)} dense={true}>
                {t(`convoNotifications.dynamicTags.${tag}`)}
              </MenuItem>
            ])
          ])}
        </Menu>
      </>
    )
  })
)

const MustascheSpan = (props) => {
  return <span style={{ backgroundColor: palette.lightestYellow, color: palette.black }}>{props.children}</span>
}

const Mustasche3Span = (props) => {
  return <span style={{ backgroundColor: palette.lightestYellow }}>{props.children}</span>
}

const mustascheStrategy = (contentBlock, callback) => {
  const MUSTACHE_REGEX = /\{\{((?!\}\})(.|\n))*\}\}/g
  findWithRegex(MUSTACHE_REGEX, contentBlock, callback)
}

const mustasche3Strategy = (contentBlock, callback) => {
  const MUSTACHE_REGEX = /\{\{\{((?!\}\}\})(.|\n))*\}\}\}/g
  findWithRegex(MUSTACHE_REGEX, contentBlock, callback)
}

const findWithRegex = (regex, contentBlock, callback) => {
  const text = contentBlock.getText()
  let matchArr, start
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index
    const newArr: number = matchArr[0].length
    callback(start, (start as number) + (newArr as number))
  }
}
const decorators = [
  {
    strategy: mustasche3Strategy,
    component: Mustasche3Span
  },
  {
    strategy: mustascheStrategy,
    component: MustascheSpan
  }
]
