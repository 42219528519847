import { createMuiTheme } from '@material-ui/core/styles'
import { merge } from 'lodash'
import { defaultFontFamily, cocoFontFamily, defaulTypography, defaultButtonOverride, defaultLinkTheme } from './'
import palette from './palette'

const fontFamily = window.coco ? cocoFontFamily : defaultFontFamily

export const AdminTheme = () =>
  createMuiTheme(
    merge({}, defaultLinkTheme, {
      palette: {
        primary: {
          main: palette.adminBlue,
          contrastText: palette.white
        },
        secondary: {
          main: palette.magenta,
          contrastText: palette.white
        }
      },
      overrides: {
        MuiTypography: defaulTypography,
        MuiTabs: {
          root: {
            color: palette.white
          },
          indicator: {
            backgroundColor: palette.teal
          }
        },
        MuiFormControl: {
          root: {
            marginTop: '40px'
          }
        },
        MuiInput: {
          underline: {
            '&:after': {
              borderBottom: '1px solid rgba(0, 0, 0, 0.42)'
            },
            '&:hover:not($disabled):before': {
              borderBottom: '1px solid rgba(0, 0, 0, 0.42)'
            }
          }
        },
        MuiInputLabel: {
          root: {
            '&$focused': {
              color: palette.teal
            }
          }
        },
        MuiCard: {
          root: {
            overflow: 'visible'
          }
        },
        MuiButton: {
          ...defaultButtonOverride,
          root: {
            fontFamily
          },
          contained: {
            backgroundColor: palette.white,
            color: palette.darkestgrey,
            borderRadius: 2,
            boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
            '&:hover': {
              backgroundColor: 'transparent'
            },
            '&$disabled': {
              color: palette.grey,
              backgroundColor: palette.lightestgrey
            }
          },
          label: {
            textTransform: 'none',
            fontWeight: window.coco ? 400 : 500,
            letterSpacing: 0
          }
        },
        MuiCircularProgress: {
          colorPrimary: {
            color: window.coco ? palette.cocoLightBlue : palette.teal
          }
        }
      }
    })
  )
