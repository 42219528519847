import React, { Component } from 'react'
import { connect } from 'react-redux'
import FontIcon from '@material-ui/core/Icon'

import withTranslation from '../hocs/withTranslation'
import Dialog from '../DialogWrapper'
import { DialogTitle } from '@material-ui/core'

class EmailsSentDialog extends Component {
  render() {
    const { t, dialog } = this.props

    return (
      <Dialog dialog={dialog} name='emailsSent'>
        <DialogTitle disableTypography>{t('common.success')}</DialogTitle>

        <section className='pane reg-pane'>
          <div className='row reg-bottom'>
            <div className='col-xs-12 center-xs'>
              <div className='box'>
                <FontIcon className='fa fa-paper-plane-o header-36' />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-xs-12 center-xs'>
              <h1 className='flat header-24'>{t('conversation.sentEmailConfirm')}</h1>
            </div>
          </div>
        </section>
      </Dialog>
    )
  }
}

export default connect(null)(withTranslation(EmailsSentDialog))
