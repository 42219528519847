export const removeNullStringValue = (data) =>
  Object.keys(data).forEach((element) => {
    if (data[element] === 'null') {
      delete data[element]
    }
  })
export const removeUndefinedValue = (data) => {
  Object.keys(data).forEach((element) => {
    if (['null', 'undefined', undefined, null].includes(data[element])) {
      delete data[element]
    }
  })
  return data
}
