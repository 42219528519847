import React, { useEffect } from 'react'
import '../../../stylesheets/admin/index'
import { Drawer, Toolbar } from '@material-ui/core'
import { flow } from 'lodash'
import { inject, observer } from 'mobx-react'
import { Route, withRouter } from 'react-router'
import { connectAdminThemeV2 } from '../../../connectTheme'
import { IStore } from '../../../store/models'
import { IUser } from '../../../store/models/User'
import AdminDashboard from './AdminDashboard'
import Topics from './Topics'
import TopicEditPage from './Topics/Edit'
import NewTopic from './Topics/New'
import PartnerEditPage from './Partners/Edit'
import NewPartner from './Partners/New'
import ConversationEditPage from './Conversations/Edit'
import Attendees from './Attendees'
import AttendeesEdit from './Attendees/Edit'
import Conversations from './Conversations'
import Analytics from './Analytics'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import { IPartner } from '../../../store/models/Partner'
import palette from '../../../themes/palette'
import AdminMenu from './partials/AdminMenu'
import LoadingAnimation from '../../shared/LoadingAnimation'
import { IsMobile } from '../../hooks/useWindowSize'
import Settings from './Settings'
import NewOrganizationPage from './Organizations/New'
import EditOrganizationPage from './Organizations/Edit'
import { LoggedInPageWithProviders } from '../../../routes/LoggedInOnly'
import ParamTopicProvider from '../../Providers/ParamTopicProvider'
import ParamConversationProvider from '../../Providers/ParamConversationProvider'
import ParamPartnerProvider from '../../Providers/ParamPartnerProvider'
import Stories from './Stories'
import NpsAnswers from './NpsAnswers'
interface Props {
  user: IUser
  loaded: boolean
  history: any
  match: { params: { page?: string } }
  partner?: IPartner
  showLoginDialog()
  setRedirectOnSuccess(val: string)
  loginDialogLoaded: boolean
}

const drawerWidth = 240

const LoggedInEditPage = (PageComponent, PageProvider, validation) => LoggedInPageWithProviders(PageComponent, [PageProvider], validation)

const ConversationEdit = LoggedInEditPage(ConversationEditPage, ParamConversationProvider, ({ conversation }) => conversation?.canEdit)
const PartnerEdit = LoggedInEditPage(PartnerEditPage, ParamPartnerProvider, ({ partner }) => partner?.canEdit)
const TopicEdit = LoggedInEditPage(TopicEditPage, ParamTopicProvider, ({ topic }) => topic?.canEdit)

//  TODO: move to theming
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0
    },
    drawerPaper: {
      width: drawerWidth
    },
    drawerContainer: {
      overflow: 'auto',
      paddingTop: theme.spacing(2),
      height: '100%'
    },
    content: {
      flexGrow: 1,
      padding: '1em 1em 1em 1em',
      backgroundColor: palette.soSoGrey,
      minHeight: 'calc(100vh - 7em) !important',
      [theme.breakpoints.down('sm')]: {
        maxWidth: 'calc(100vw - 2em) !important'
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: 'calc(100vw - 17em) !important'
      },
      [theme.breakpoints.between('md', 1055)]: {
        maxWidth: 'calc(100vw - 2em) !important'
      }
    }
  })
)

const Admin = ({
  user,
  loaded,
  history,
  match: {
    params: { page }
  },
  history: {
    location: { pathname }
  },
  setRedirectOnSuccess,
  showLoginDialog,
  loginDialogLoaded
}: Props) => {
  useEffect(() => {
    if (loaded) {
      if (loaded && !user && !loginDialogLoaded) {
        setRedirectOnSuccess(pathname)
        history.push('/login')
        showLoginDialog()
      }
    }
  }, [loaded, user, loginDialogLoaded, history, showLoginDialog, setRedirectOnSuccess, pathname])

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <>
        <div className='toolbar-main-links no-margin'>
          <Drawer
            className={classes.drawer}
            variant='permanent'
            classes={{
              paper: classes.drawerPaper
            }}
          >
            {user && user.isCommunityAdmin() && (
              <>
                <Toolbar />
                <AdminMenu active={page} />
              </>
            )}
          </Drawer>
        </div>
        <main className={`${classes.content} ${IsMobile() && 'quite-big-bottom-padding'}`}>
          {loaded ? (
            user &&
            user.isCommunityAdmin() && (
              <>
                <Route exact path='/admin' component={() => <AdminDashboard />} />
                <Route exact path='/admin/organizations/new' component={NewOrganizationPage} />
                <Route exact path='/admin/organizations/:id/edit' component={EditOrganizationPage} />
                <Route exact path='/admin/partners/new' component={NewPartner} />
                <Route exact path='/admin/partners/:slug/edit' component={PartnerEdit} />
                <Route exact path='/admin/partners/:slug/edit/t/:tab?' component={PartnerEdit} />
                <Route exact path='/admin/topics' component={Topics} />
                <Route exact path='/admin/topics/new' component={NewTopic} />
                <Route exact path='/admin/topics/:slug/edit' component={TopicEdit} />
                <Route exact path='/admin/topics/:slug/edit/t/:tab?' component={TopicEdit} />
                <Route exact path='/admin/conversations' component={Conversations} />
                <Route exact path='/admin/conversations/:conversationId/edit' component={ConversationEdit} />
                <Route exact path='/admin/conversations/:conversationId/edit/t/:tab?' component={ConversationEdit} />
                <Route exact path='/admin/attendees' component={Attendees} />
                <Route exact path='/admin/attendees/:id/edit' component={AttendeesEdit} />
                <Route exact path='/admin/analytics/:tab?' component={Analytics} />
                <Route exact path='/admin/settings' component={Settings} />
                <Route exact path='/admin/stories' component={Stories} />
                <Route exact path='/admin/nps_answers' component={NpsAnswers} />
              </>
            )
          ) : (
            <LoadingAnimation />
          )}
        </main>
      </>
    </div>
  )
}
export default flow(
  connectAdminThemeV2,
  withRouter,
  observer,
  inject<FIXME, FIXME>(
    ({
      mst: {
        auth: { user, loaded, setRedirectOnSuccess },
        dialogs
      }
    }: {
      mst: IStore
    }) => {
      return {
        loaded,
        user,
        setRedirectOnSuccess,
        loginDialogLoaded: !!dialogs.list.find((d) => d.name === 'login'),
        showLoginDialog: () => dialogs.showDialog('login')
      }
    }
  )
)(Admin)
