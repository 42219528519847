import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { flow } from 'lodash'
import { IPartner } from '../../store'

const PartnerProvider = ({ children }: Props) => {
  const [partner, setPartner] = useState(undefined)
  return <PartnerContext.Provider value={{ partner, setPartner }}>{children}</PartnerContext.Provider>
}

interface Props {
  children?: React.ReactNode | React.ReactNode[]
}

export const PartnerContext = React.createContext({
  partner: undefined as IPartner | undefined,
  setPartner(conversation: any) {
    //  do nothing
  }
})

export default flow(observer)(PartnerProvider)
