import React from 'react'
import { Link } from 'react-router-dom'

import { observer } from 'mobx-react'
import DataTable, { IColData, ValueLabel } from '../../shared/DataTableFilterable'
import RemovedMembersActions from './RemovedMembersActions'
import { IMembershipUser, IMembershipUserBase } from '../../../store/models/User/MembershipUser'
import moment from 'moment-timezone'
import { ITrack } from '../../../store'
import { MenuItem, Select } from '@material-ui/core'
import { sortBy } from 'lodash'

interface IProps {
  membership: IMembershipUser
  track: ITrack
  search?: string
}

const searchList = (list: IMembershipUserBase[], word) => {
  return list.filter((item) => {
    return item.user?.firstName?.toLowerCase().match(word) || item.user?.lastName?.toLowerCase().match(word) || item.email?.toLowerCase()?.match(word)
  })
}
const sortList = (list: IMembershipUserBase[], sorted_id?: string, sorted_priority?: boolean) => {
  const getDataFromColumn = (obj?: IMembershipUserBase): any => {
    if (sorted_id === 'name') {
      return obj?.user?.fullName
    }
    if (sorted_id === 'subscriptionDate') {
      return obj?.startsAt
    }
    return obj && sorted_id ? obj[sorted_id] : undefined
  }

  if (sorted_id) {
    const sorted = sortBy(list, [(obj) => getDataFromColumn(obj)])
    return sorted_priority ? sorted.reverse() : sorted
  } else {
    return list
  }
}
const RemovedMembersTable = observer((props: IProps) => {
  const { membership, track, search } = props

  const columnData: IColData[] = [
    { name: 'name', title: 'Name', width: 150, sortable: true },
    { name: 'email', title: 'Email', width: 250, sortable: true },
    { name: 'role', title: 'Role', width: 100, sortable: true },
    { name: 'journey', title: 'Journey', width: 200, sortable: true },
    { name: 'subscriptionDate', title: 'Subscription Date', width: 250, sortable: true },
    { name: 'actions', title: ' ', width: 100, align: 'center' }
  ]
  const list = membership.fullList.filter((item) => item.status === 'removed')
  const sortedList = sortList(search ? searchList(list, search) : list, membership.sortedId, membership.sortedPriority)

  return (
    <DataTable
      data={sortedList}
      columns={columnData}
      loading={membership.loadingIds.includes('fullList')}
      filterable={membership}
      rightColumns={['actions']}
      height={'calc(100vh - 29rem)'}
      noDataMsg='None'
      hidePaging
      hideSearch
      getCellValue={(row: IMembershipUserBase, columnName: string) => {
        switch (columnName) {
          case 'name':
            return (
              <ValueLabel
                value={
                  <Link rel='noopener' title={row.user?.fullName} to={`/users/${row.id}`} target='blank' color='primary'>
                    {row.user?.fullName}
                  </Link>
                }
              />
            )
          case 'journey':
            return <ValueLabel value={track.name} />
          case 'subscriptionDate':
            return <ValueLabel value={`${moment(row.startsAt).format('L')} - ${moment(row.endsAt).format('L')}`} />
          case 'profile':
            return (
              <ValueLabel
                value={
                  <Link rel='noopener' title='User Profile' to={`/users/${row.id}`} target='blank' color='primary'>
                    {`/users/${row.id}`}
                  </Link>
                }
              />
            )
          case 'actions':
            return <RemovedMembersActions member={row} membership={membership} />
          default:
            return <ValueLabel value={row[columnName]} />
        }
      }}
    />
  )
})

export default RemovedMembersTable
