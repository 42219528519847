import { StyleRules, TypographyClassKey } from '@material-ui/core'
import { cocoFontFamily } from './cocoFontFamily'
import { defaultFontFamily } from './defaultFontFamily'
import palette from '../palette'

export const getFontFamily = () => (window.coco ? cocoFontFamily : defaultFontFamily)

export const defaulTypography: Partial<StyleRules<TypographyClassKey>> = {
  root: {
    fontFamily: getFontFamily(),
    fontSize: 16,
    ['& a']: {
      color: palette.blue500,
      '&:hover': {
        color: palette.blue900
      },
      '&:focus': {
        color: palette.blue700
      }
    }
  },
  h1: {
    fontFamily: getFontFamily(),
    fontWeight: 400,
    fontSize: '3.75rem',
    lineHeight: '4.5rem',
    letterSpacing: '-0.5px'
  },
  h2: {
    fontFamily: getFontFamily(),
    fontWeight: 400,
    fontSize: '3rem',
    lineHeight: '3.5rem'
  },
  h3: {
    fontFamily: getFontFamily(),
    fontWeight: 500,
    fontSize: '2.25rem',
    lineHeight: '2.75rem'
  },
  h4: {
    fontFamily: getFontFamily(),
    fontWeight: 400,
    fontSize: '2rem',
    lineHeight: '2.75rem',
    letterSpacing: '0.25px'
  },
  h5: {
    fontFamily: getFontFamily(),
    fontWeight: 400,
    fontSize: '1.5rem',
    lineHeight: '2.25rem'
  },
  h6: {
    fontFamily: getFontFamily(),
    fontWeight: 400,
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
    letterSpacing: '0.15px'
  },
  subtitle1: {
    fontFamily: getFontFamily(),
    fontWeight: 500,
    fontSize: '1.125rem',
    lineHeight: '1.75rem',
    letterSpacing: '0.15px'
  },
  subtitle2: {
    fontFamily: getFontFamily(),
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '1rem',
    letterSpacing: '0.1px'
  },
  body1: {
    fontFamily: getFontFamily(),
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    letterSpacing: '0.25px'
  },
  body2: {
    fontFamily: getFontFamily(),
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    letterSpacing: '0.25px'
  },
  button: {
    fontFamily: getFontFamily(),
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '1.25rem',
    letterSpacing: '0.5px'
  },
  caption: {
    fontFamily: getFontFamily(),
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: '1rem',
    letterSpacing: '0.5px'
  },
  overline: {
    fontFamily: getFontFamily(),
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: '1rem',
    letterSpacing: '1.25px',
    textTransform: 'uppercase'
  }
}
export default defaulTypography
