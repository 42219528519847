import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Card, CircularProgress, Container, createStyles, Grid, makeStyles, MenuItem, Paper, Select, Tab, Tabs, Theme, Tooltip, Typography } from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import { Link, Route, useHistory, useLocation, useParams } from 'react-router-dom'
import { IMembershipUser, IMembershipUserBase } from '../../../store/models/User/MembershipUser'
import { useAuth, useParamPartner, useUserTracks } from '../../Providers'
import { IConversation, IStore, ITrack } from '../../../store'
import ConversationCard from '../../conversations/Card'
// import ConversationCard from '../conversations/ListCard'
import { IMembership } from '../../../store/models/Track/Membership'
import LoadingAnimation from '../../shared/LoadingAnimation'
import { flow } from 'lodash'
import MyMembers from './MyMembers'
import MyMembershipPlan from './MyMembershipPlan'
import MyDetails from './MyDetails'
import DrawerMenu from './DrawerMenu'
import MyCommunity from './MyCommunity'
import { Analytics, Engagement, Actions, Leaderboard, Feedback } from './Analytics'
import { palette } from '../../../themes'

interface Props {}

const MembershipListLoader = () => {
  const { user, loaded, showSignup } = useAuth()
  const { loadHsMembership } = useUserTracks()
  const memberUsers = useMemo(() => [...(user?.membershipUsers || [])], [user])
  const { action } = useParams<{ action?: string }>()
  const history = useHistory()
  const { partner } = useParamPartner()

  const loadFullMembers = useCallback(() => {
    if (memberUsers) {
      memberUsers.filter((mu) => !mu.listLoaded).forEach((mu) => mu.loadFullList())
    }
  }, [memberUsers])
  useEffect(() => {
    loadFullMembers()
  }, [loadFullMembers])
  // useEffect(() => {
  //   if (partner && user && action === 'new') {
  //     loadHsMembership()
  //       .then(user.reloadProfile)
  //       .then(() => memberUsers.forEach((mu) => mu.loadFullList()))
  //       .then(() => history.replace(`/${partner?.slug}/journey`))
  //   } else if (loaded && !user && action === 'new') {
  //     showSignup()
  //   }
  // }, [partner, user, action, history, loadHsMembership, loadFullMembers, memberUsers, loaded, showSignup])

  return <></>
}

export default function Memberships(props: Props): ReactElement {
  const { user, loaded, showSignup } = useAuth()
  const history = useHistory()
  const { action, membership_id } = useParams<{ action: string; membership_id?: string }>()
  const [selectedMembership, setSelectedMembership] = useState<IMembershipUser | undefined>(undefined)
  const { tracks, tracksLoading } = useUserTracks()
  // const { tracks } = useUserTracks()
  const classes = useStyles()
  const memberships: IMembershipUser[] = user?.membershipUsers || []
  const handleMembershipSelect = ({ target: { value } }) => {
    // TBD
    setSelectedMembership(user?.membershipUsers.find((mu) => mu.membershipId === value))
    history.push(`/memberships/details/${value}`)
  }
  useEffect(() => {
    if (!user && loaded) {
      showSignup()
    }
  }, [user, loaded, showSignup])
  useEffect(() => {
    if (user && membership_id) {
      const selected = user?.membershipUsers.find((mu) => mu.membershipId.toString() === membership_id)
      setSelectedMembership(selected)
    }
  }, [membership_id, user])

  useEffect(() => {
    const defaultMem = user?.membershipUsers[user?.membershipUsers.length - 1] || undefined
    if (user && defaultMem && !selectedMembership && user?.membershipUsers.length > 0) {
      setSelectedMembership(defaultMem)
    }
  }, [selectedMembership, setSelectedMembership, user])
  if (!user && loaded) history.push('/login')
  if (!user || memberships.length === 0) {
    return (
      <>
        <LoadingAnimation />
      </>
    )
  }

  return (
    <Container className={classes.root}>
      <DrawerMenu membership={selectedMembership} />
      <MembershipListLoader />
      <div className={classes.rightContainer}>
        <div className='reg-top-padding xtra-fat-bottom-padding'>
          <Grid container justify='space-between'>
            <Grid item xs={12} sm={6}>
              {tracksLoading && <CircularProgress />}
              {!tracksLoading && selectedMembership && memberships.length > 1 && (
                <div style={{ textAlign: 'start', marginTop: '.25rem' }}>
                  <Select
                    value={selectedMembership?.membershipId}
                    // defaultValue={selectedMembership?.id}
                    onChange={handleMembershipSelect}
                  >
                    {memberships?.map((membership) => {
                      const track = tracks.find((t) => t.id === membership.trackId)
                      const companyName = membership?.companyName ? `${membership?.companyName} - ` : ''
                      return (
                        <MenuItem key={membership.membershipId} value={membership.membershipId} selected={selectedMembership?.membershipId === membership.membershipId}>
                          {companyName}
                          {track?.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </div>
              )}
              {!tracksLoading && selectedMembership && memberships.length === 1 && (
                <div style={{ textAlign: 'start', marginTop: '.25rem' }}>
                  {selectedMembership?.companyName ? `${selectedMembership?.companyName} - ` : ''}
                  {tracks.find((t) => t.id === selectedMembership.trackId)?.name}
                </div>
              )}
            </Grid>
          </Grid>
          <div>{selectedMembership && <Membership membership={selectedMembership} />}</div>
        </div>
      </div>
    </Container>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: palette.soSoGrey,
      minHeight: 'calc(100vh - 7em) !important',
      maxWidth: '100vw',
      paddingRight: 0
    },
    rightContainer: {
      marginRight: '1rem',
      // maxWidth: 'calc(100vw - 15rem)',
      [theme.breakpoints.up('md')]: {
        paddingLeft: '16rem'
        // marginRight: 'auto'
      }
    },
    rightContainerInner: {
      paddingLeft: '1rem',
      paddingTop: '0.1rem',
      marginTop: '1rem',
      minHeight: 'calc(100vh - 11em) !important',
      paddingBottom: '1rem',
      paddingRight: '1rem'
    }
  })
)

const Membership = observer(({ membership }: { membership: IMembershipUser }) => {
  const { tracks } = useUserTracks()
  const track = tracks.find((t) => t.id === membership.trackId)
  if (!track) return <></>
  return <MembershipItem membership={membership} track={track} />
})

export const MembershipItem = observer(({ membership, track }: { membership: IMembershipUser; track: ITrack }) => {
  const { action, membership_id } = useParams<{ action: string; membership_id?: string }>()
  const classes = useStyles()
  const history = useHistory()

  const rootName = '/memberships'
  const tabNames = ['details', 'members', 'plan', 'community', 'analytics', 'engagement', 'actions', 'leaderboard', 'feedback']

  useEffect(() => {
    if (!action && membership) {
      history.push(`/memberships/details/${membership.membershipId}`)
    }
  }, [action, history, membership])

  return (
    <div className='xtra-fat-bottom'>
      <Route
        path={`${rootName}/${tabNames[0]}/:membership_id?`}
        exact
        render={(p) => (
          <Paper className={classes.rightContainerInner}>
            <MyDetails {...{ p, membership, track }} />
          </Paper>
        )}
      />
      <Route
        path={`${rootName}/${tabNames[3]}/:membership_id`}
        exact
        render={(p) => (
          <Paper className={classes.rightContainerInner}>
            <MyCommunity {...{ p, membership, track }} />
          </Paper>
        )}
      />
      <Route
        path={`${rootName}/${tabNames[1]}/:membership_id?/:tab?`}
        render={(p) => (
          <Paper className={classes.rightContainerInner}>
            <MyMembers {...{ p, membership, track }} />
          </Paper>
        )}
      />
      <Route
        path={`${rootName}/${tabNames[2]}/:membership_id?`}
        render={(p) => (
          <Paper className={classes.rightContainerInner}>
            <PlanAndPayment {...{ p, membership, track }} />
          </Paper>
        )}
      />
      <Route
        exact
        path={`${rootName}/${tabNames[5]}/:membership_id`}
        render={(pp) => (
          <Paper className={classes.rightContainerInner}>
            <Engagement {...{ pp, membership, track }} />
          </Paper>
        )}
      />
      <Route
        exact
        path={`${rootName}/${tabNames[6]}/:membership_id`}
        render={(pp) => (
          <Paper className={classes.rightContainerInner}>
            <Actions {...{ pp, membership, track }} />
          </Paper>
        )}
      />
      <Route
        exact
        path={`${rootName}/${tabNames[7]}/:membership_id`}
        render={(pp) => (
          <Paper className={classes.rightContainerInner}>
            <Leaderboard {...{ pp, membership, track }} />
          </Paper>
        )}
      />
      <Route
        exact
        path={`${rootName}/${tabNames[8]}/:membership_id`}
        render={(pp) => (
          <Paper className={classes.rightContainerInner}>
            <Feedback {...{ pp, membership, track }} />
          </Paper>
        )}
      />
      {/* <Route exact path={`${rootName}/${tabNames[4]}/:membership_id/:tab`} render={(p) => <Engagement {...{ p, membership, track }} />} /> */}
      {/* </Paper> */}
      <Route
        exact
        path={`${rootName}/${tabNames[4]}/:membership_id?`}
        render={(p) => (
          <div className={classes.rightContainerInner}>
            <Analytics {...{ p, membership, track }} />
          </div>
        )}
      />
    </div>
  )
})

const PlanAndPayment = ({ membership, track }: { membership: IMembershipUser; track: ITrack }) => {
  return (
    <div style={{ margin: '1rem' }}>
      <Typography variant='h5'>Plan & Payment</Typography>
      <MyMembershipPlan {...{ membership, track }} />
    </div>
  )
}
