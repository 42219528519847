import { types, Instance, getRoot } from 'mobx-state-tree'
import moment from 'moment'

import { MetaImage } from '../base/BaseObjects'
import { includes } from 'lodash'
import ConversationGuests from './ConversationGuests'
import conversationsApi from '../../../api/ConversationsApi'
import ConversationBase from './ConversationBase'
import { Dialogable } from '../helpers/Dialogable'
import i18next from 'i18next'
import { snakify } from '../../../decorators/textTools'
import { capitalize } from '../../../decorators/textTools'
import Sectionables from '../helpers/Sectionables'
import ConversationRequests from './ConversationRequests'
import ConversationInvites from './ConversationInvites'
import ConversationNotifications from './ConversationNotifications'
import { RootAccessable } from '../helpers/RootAccessable'
import { IUser } from '../User'
import { startCase } from 'lodash'
import ConversationUserConversations from './UserConversations'
const DietType = types.maybe(types.union(types.string, types.boolean))
const SuitableDiets = types.model('SuitableDiets', {
  dairyFree: DietType,
  glutenFree: DietType,
  nutAllergy: DietType,
  omnivore: DietType,
  other: DietType,
  pescatarian: DietType,
  vegan: DietType,
  otherDiet: DietType,
  vegetarian: DietType
})

const Conversation = types
  .compose(
    'Conversation',
    ConversationBase,
    ConversationGuests,
    ConversationRequests,
    ConversationInvites,
    ConversationUserConversations,
    ConversationNotifications,
    Sectionables,
    types.model({
      //  String - Maybe null
      about: types.maybeNull(types.string),
      address: types.maybeNull(types.string),
      brandSingular: types.maybeNull(types.string),
      city: types.maybeNull(types.string),
      country: types.maybeNull(types.string),
      createdAt: types.maybeNull(types.string),
      description: types.maybeNull(types.string),
      endsAt: types.maybeNull(types.string),
      eventType: types.maybeNull(types.string),
      eventTypePlural: types.maybeNull(types.string),
      eventTypeSingular: types.maybeNull(types.string),
      formattedAddress: types.maybeNull(types.string),
      guestInstructions: types.maybeNull(types.string),
      guestPolicy: types.maybeNull(types.string),
      hostGuideUrl: types.maybeNull(types.string),
      metaDescription: types.maybeNull(types.string),
      metaTitle: types.maybeNull(types.string),
      placeId: types.maybeNull(types.string),
      postcode: types.maybeNull(types.string),
      startsAt: types.maybeNull(types.string),
      stateLevel: types.maybeNull(types.string),
      themeColor: types.maybeNull(types.string),
      venueName: types.maybeNull(types.string),
      venueType: types.maybeNull(types.string),
      virtualEventTypePlural: types.maybeNull(types.string),
      virtualEventTypeSingular: types.maybeNull(types.string),
      suitableDiets: types.maybeNull(types.union(types.string, SuitableDiets)),
      virtualLink: types.maybeNull(types.string),
      virtualType: types.maybeNull(types.string),
      featuredQuote: types.maybeNull(types.string),
      conversationType: types.maybeNull(types.string),
      neighborhood: types.maybeNull(types.string),
      schedule: types.maybeNull(types.string),
      partnerName: types.maybeNull(types.string),
      hostToken: types.maybeNull(types.string),
      pendingInvitationsCount: types.optional(types.integer, 0),
      hostNotes: types.maybeNull(types.string),
      title: types.maybeNull(types.string),

      //  Boolean - Maybe null
      topicActive: types.maybeNull(types.boolean),
      full: types.maybeNull(types.boolean),
      hasMetaImage: types.maybeNull(types.boolean),
      hidden: types.maybeNull(types.boolean),
      inviteFriends: types.maybeNull(types.boolean),
      registrationClosed: types.maybeNull(types.boolean),
      showFaqs: types.maybeNull(types.boolean),
      showGuestList: types.maybeNull(types.boolean),
      showMap: types.maybeNull(types.boolean),
      attendeeCount: types.maybeNull(types.number),
      attendedCount: types.maybeNull(types.number),
      topicId: types.maybeNull(types.number),
      guestCount: types.maybeNull(types.number),
      maxAttendees: types.maybeNull(types.number),
      maxGuests: types.maybeNull(types.number),
      actionCount: types.maybeNull(types.number),
      backgroundImage: MetaImage,
      icon: MetaImage,
      metaImage: MetaImage,
      partnerLogo: MetaImage,
      coordinates: types.optional(types.array(types.number), []),
      topicLoaded: types.optional(types.boolean, false),
      topicLoading: types.optional(types.boolean, false),
      topicFeatured: types.maybeNull(types.optional(types.boolean, false)),
      partnerFeatured: types.maybeNull(types.optional(types.boolean, false)),
      showTopicSponsors: types.maybeNull(types.optional(types.boolean, true)),
      hasActions: types.maybeNull(types.optional(types.boolean, false)),
      destroyMessage: types.optional(types.string, '')
    })
  )
  .views((self) => ({
    get eventTypeDisplay() {
      return self.eventType === 'premium_event' ? 'Premium' : 'Standard'
    },
    get venueTypeDisplay() {
      return self.venueType === 'virtual' ? 'Online' : startCase(self.venueType?.toString())
    },
    get visibility() {
      return self.hidden ? 'Hidden' : 'Displayed'
    },
    get guestPolicyDisplay() {
      return self.guestPolicy === 'approval_only' ? 'Invite Only' : 'Open Invite'
    },
    get linkExpired() {
      return !!moment(self.endsAt).add(30, 'minutes').isBefore(moment())
    },
    get dayBefore() {
      return !!moment(self.startsAt).subtract(1, 'days').isBefore(moment())
    },
    get expired() {
      return !!moment(self.endsAt).isBefore(moment())
    },
    get approvalOnly() {
      return self.guestPolicy === 'approval_only'
    },
    get startsAtLocally() {
      return moment(self.startsAt)
        .locale(self.locale)
        .tz(self.timezone || 'America/New_York')
        .format('LT')
    },
    get endsAtLocally() {
      return moment(self.endsAt)
        .locale(self.locale)
        .tz(self.timezone || 'America/New_York')
        .format('LT')
    },
    get timeZoneAbbr() {
      return moment()
        .tz(self.timezone || 'America/New_York')
        .format('zz')
    },
    get dateLocally() {
      return capitalize(
        moment(self.startsAt)
          .locale(self.locale)
          .tz(self.timezone || 'America/New_York')
          .format('LL')
      )
    },
    get isPremium() {
      return self.eventType === 'premium_event'
    },
    get isPublic() {
      return self.guestPolicy === 'public'
    },
    get isPrivate() {
      return self.guestPolicy === 'private'
    },

    get topic() {
      const { topics } = getRoot(self)
      return topics.list.find((c) => c.id === self.topicId)
    },
    get isHost() {
      const {
        auth: { user }
      } = getRoot(self)
      return user && self.host && self.host.id === user.id
    },
    get isVirtual() {
      return self.venueType === 'virtual'
    },
    get isRestaurantConversation() {
      return self.venueType === 'restaurant'
    },
    get isHomeConversation() {
      return self.venueType === 'home'
    },
    get isOtherConversation() {
      return self.venueType === 'other'
    },
    get isCustomVirtualType() {
      return self.virtualType === 'custom'
    },
    get dayLocally() {
      return moment(self.startsAt)
        .locale(self.locale)
        .tz(self.timezone || 'America/New_York')
        .format('ddd')
    },

    get yearLocally() {
      return moment(self.startsAt)
        .tz(self.timezone || 'America/New_York')
        .format('YYYY')
    },

    get dayLocallyFull() {
      return moment(self.startsAt)
        .locale(self.locale)
        .tz(self.timezone || 'America/New_York')
        .format('dddd')
    },

    get neighborhoodOrCity() {
      return self.neighborhood ? self.neighborhood : self.city
    }
  }))
  .views((self) => ({
    get showVirtualLink() {
      return !!(self.venueType === 'virtual' && !!self.virtualLink && !self.linkExpired)
    },
    get userVirtualLink() {
      const {
        auth: { user }
      } = getRoot(self)

      let type, token
      if (self.host?.id === user.id) {
        type = 'host'
        token = self.hostToken
      } else {
        type = 'guest'
        token = (user as IUser).userConversations.find((ud) => ud.conversationId === self.id)?.guestToken
      }
      return `${window.REACT_APP_ATTENDEE_EVENT_BASEURL}/api/event/${type}/${token}`
    },
    get conversationLink() {
      return `${window.location.host.includes('http') ? '' : 'https://'}${window.location.host}/${self.slug}/conversations/${self.id}`
    },
    get canEdit() {
      const {
        auth: { user }
      } = getRoot(self)
      return user && (user.isSuperAdmin() || self.isHost || (user.isCommunityAdmin() && includes(user.communityAdminAuthorizedTopicIds, self.topicId)))
    }
  }))
  .actions((self) => ({
    canDelete: () => {
      const {
        auth: { user }
      } = getRoot(self)
      return user && (self.isHost || user.isSuperAdmin() || user.isPartnerAdmin()) && (!self.expired || self.synthetic)
    },
    sectionableType: () => 'Conversation',
    showAlert(mgs: string) {
      const { alertMessages } = getRoot(self)
      alertMessages.showAlert(mgs)
    },
    deleteConversation: ({ confirm = true, destroyMessage = undefined } = {}) => {
      if (confirm) {
        self.showDialog('deleteConversation', { conversationId: self.id })
        return
      }
      return conversationsApi.destroy(self.id, destroyMessage || self.destroyMessage).then(({ response: { ok, statusText } }) => {
        if (ok) {
          self.closeDialog('deleteConversation')
          self.showAlert(i18next.t('conversation.deleted'))
        } else {
          self.showAlert(statusText)
        }
      })
    }
  }))
  .actions((self) => ({
    updateConversation(data: any, { success = 'Changes have been applied' } = {}) {
      return conversationsApi.update({ id: self.id, ...snakify(data) }).then(({ response: { ok, statusText }, json }) => {
        if (ok) {
          self.updateMe(json)
          self.showAlert(success)
        } else {
          self.showAlert(statusText)
          return Promise.reject(json)
        }
      })
    },
    reloadConversation() {
      return conversationsApi.get(self.id).then(({ response: { ok, statusText }, json }) => {
        if (ok) {
          self.updateMe(json)
          return Promise.resolve(json)
        } else {
          return Promise.reject(json)
        }
      })
    }
  }))
  .actions((self) => ({
    setPartnerConcent: (val: boolean) => {
      self.partnerConsented = val
      if (self.partnerConsented) {
        self.closeDialog('partnerConsent')
        if (self.requestingInvite) self.requestInvite()
        if (self.attendingProcess) self.attend()
      }
    },
    venueNameText: () =>
      !!self.isVirtual
        ? i18next.t('conversation.virtualTypeLabel', {
            eventType: capitalize(self.virtualEventTypeSingular)
          })
        : self.venueName,
    assignHost: (u: IUser) => {
      return self.updateConversation({ host_id: u.id }).then(() => self.showAlert('Host Updated'))
    }
  }))

export interface IConversation extends Instance<typeof Conversation> {}
export default Conversation
