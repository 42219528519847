import { Button } from '@material-ui/core'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuth, useParamConversation, useUsers } from '../../../Providers'
import HostAssignDialog from './HostAssignDialog'

type Props = {
  // test
}

export default function HostAssign(props: Props) {
  const [open, setOpen] = useState(false)
  const { conversation } = useParamConversation()
  const { user } = useAuth()
  return (
    <div className='fat-bottom'>
      <div className='label'>Host</div>
      {conversation?.host && (
        <Link to={`/users/${conversation?.host?.id}`} target='blank'>
          {conversation?.host?.firstName} {conversation?.host?.lastName}
        </Link>
      )}
      {user?.isSuperAdmin() && (
        <>
          <Button
            variant='outlined'
            className='reg-left'
            size='small'
            onClick={() => {
              setOpen(true)
            }}
          >
            Change
          </Button>
          <HostAssignDialog
            open={open}
            onClose={() => {
              setOpen(false)
            }}
          />
        </>
      )}
    </div>
  )
}
