import React, { useState } from 'react'
import { createStyles, Grid, makeStyles, Paper, Theme, Typography } from '@material-ui/core'
import { observer } from 'mobx-react'
import { IMembershipUser, IMembershipUserBase } from '../../../store/models/User/MembershipUser'
import { ITrack } from '../../../store'
import CommunityTable from './CommunityTable'

interface Props {
  membership: IMembershipUser
  track: ITrack
}

export const MyCommunity = observer(({ membership, track }: Props) => {
  const classes = useStyles()
  const [search, setSearch] = useState('')
  return (
    <div style={{ marginTop: '1rem' }}>
      <div>
        <Grid container direction='row' alignItems='center' justify='space-between'>
          <Grid item>
            <Typography variant='h6' className='reg-bottom reg-top'>
              Leaderboard
            </Typography>
          </Grid>
        </Grid>

        <Paper className={classes.section}>
          <CommunityTable membership={membership} track={track} search={search?.toLowerCase()} />
        </Paper>
      </div>
    </div>
  )
})
export default MyCommunity

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      marginTop: '2rem',
      paddingTop: '.25rem'
    }
  })
)
