import { includes } from 'lodash'
import { types, Instance, getRoot } from 'mobx-state-tree'
import i18n from 'i18next'
import usersApi from '../../../api/UsersApi'
import { snakify } from '../../../decorators/textTools'
import { removeNullStringValue } from '../utils'
import { Dialogable, Alertable } from '../helpers'
import { UserInvitationRequests } from './UserInvitationRequests'
import { UserBase } from './UserBase'
import { UserConversations } from './UserConversations'
import UserActions from './UserActions'
import { UserConnections } from './UserConnections'
import UserLmsItems from './UserLmsItems'

const SUPER_ADMIN_ROLES = ['super_admin']
const PARTNER_ADMIN_ROLES = ['partner_admin', ...SUPER_ADMIN_ROLES]
const COMMUNITY_ADMIN_ROLES = ['community_admin', ...PARTNER_ADMIN_ROLES]
const USER_ROLES = ['user', ...COMMUNITY_ADMIN_ROLES]

export const Coordinate = types.model('Coordinate', {
  latitude: types.maybe(types.union(types.string, types.number)),
  longitude: types.maybe(types.union(types.string, types.number))
})
export const ConversationRoleInterest = types.model('ConversationRoleInterest', {
  attending: types.maybe(types.union(types.string, types.boolean)),
  hosting: types.maybe(types.union(types.string, types.boolean))
})
export const UserPartner = types.model('UserPartner', { id: types.number, slug: types.string, churnzeroSync: types.boolean })
export const User = types
  .compose(
    'User',
    Alertable,
    UserBase,
    UserConversations,
    UserConnections,
    UserInvitationRequests,
    UserActions,
    UserLmsItems,
    types.model({
      role: types.maybe(types.string),
      education: types.maybeNull(types.string),
      occupation: types.maybeNull(types.string),
      ageRange: types.maybeNull(types.string),
      showDemographicDataToHosts: types.maybeNull(types.boolean),
      coordinates: types.union(types.maybe(Coordinate), types.maybe(types.array(types.string)), types.maybe(types.array(types.number))),
      partnerSlugs: types.maybeNull(types.array(types.string)),
      sso: types.maybeNull(types.string),
      associatedPartners: types.optional(types.array(UserPartner), []),
      communityAdminAuthorizedTopicIds: types.optional(types.array(types.number), []),
      interests: types.maybeNull(types.array(types.string)),
      conversationRoleInterest: types.maybeNull(types.optional(ConversationRoleInterest, { attending: false, hosting: false })),
      zoom: types.maybeNull(types.frozen(types.map(types.union(types.string, types.number))))
    })
  )
  .actions((self) => ({
    isUser: () => includes(USER_ROLES, self.role),
    isSuperAdmin: () => includes(SUPER_ADMIN_ROLES, self.role),
    isPartnerAdmin: () => includes(PARTNER_ADMIN_ROLES, self.role),
    isCommunityAdmin: () => includes(COMMUNITY_ADMIN_ROLES, self.role),
    updateSelf: (data: any) => {
      removeNullStringValue(data)
      Object.assign(self, data)
    }
  }))
  .actions((self) => ({
    reloadProfile: () => {
      return usersApi.get(self.id).then(({ response: { ok, statusText }, json }) => {
        if (ok) {
          self.updateSelf(json)
        } else {
          self.showAlert(statusText)
        }
      })
    },
    updateProfile: (data: any, { alert = true } = {}) => {
      return usersApi.update({ id: self.id, ...snakify(data) }).then(({ response: { ok, statusText }, json }) => {
        if (ok) {
          self.updateSelf(json.user)
          alert && self.showAlert(i18n.t('user.accountUpdated'))
        } else {
          alert && self.showAlert(statusText)
          return Promise.reject(json)
        }
      })
    },
    deleteUser: () => {
      const { auth } = getRoot(self)
      return usersApi.destroy(self.id).then(({ response: { ok } }) => {
        if (ok) {
          auth.logOutUser(i18n.t('user.accountDeleted'))
        }
      })
    },
    isGod: () => {
      /////
      return self.isSuperAdmin() && /@civicdinners.com$/.test(self.email || '')
      //  OR below
      // const GOD_USERS = ['nikka@civicdinners.com']
      // return includes(GOD_USERS, self.email)
      //
      /////
    },
    trackOnChurnzero: () => {
      const { user } = self.getAuth()
      const { list, selectedPartnerId } = self.getPartners()

      if (!user || user.role !== 'partner_admin') {
        return false
      }

      let partner

      if (selectedPartnerId) {
        partner = user.associatedPartners.find((p) => p.id === selectedPartnerId)
      } else {
        partner = user.associatedPartners[0]
      }

      return partner?.churnzeroSync
    }
  }))

export interface IUser extends Instance<typeof User> {}
export default User

export const Guest = types.compose('Guest', UserBase, Dialogable, types.model({}))
export const Host = types.compose('Host', UserBase, types.model({}))

export interface IGuest extends Instance<typeof Guest> {}
