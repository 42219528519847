import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { topics } from './topicReducer'
import { topicOptions } from './topicOptionsReducer'
import { topicNotifications } from './topicNotificationReducer'
import { conversationNotifications } from './conversationNotificationReducer'
import { surveyQuestions } from './surveyQuestionReducer'
import { partners } from './partnerReducer.js'
import { partnerStats } from './partnerStatsReducer.js'
import { topicsPartners } from './topicsPartnersReducer'
import { conversations } from './conversationReducer'
import { hostedConversations } from './hostedConversationReducer'
import { guests } from './guestReducer'
import { pendingRequestedGuests } from './pendingRequestedGuestsReducer'
import { pendingInvitedGuests } from './pendingInvitedGuestsReducer'
import { userConversations } from './userConversationReducer'
import { conversationsTable } from './conversationsTableReducer.js'
import { topicsTable } from './topicsTableReducer'
import { csvExports } from './CsvExportReducer.js'
import { usersTable } from './usersTableReducer.js'
import { sections } from './sectionReducer.js'
import { sponsors } from './sponsorReducer.js'
import { alertMessage } from './alertMessageReducer'
import { currentUser, isCurrentUserLoading } from './currentUserReducer'
import { conversationInvitationRequests } from './conversationInviationRequestReducer.js'
import { conversationInvitations } from './conversationInvitationReducer.js'
import { users } from './userReducer'
import { dialog } from './dialogReducer'
import { tabs } from './tabReducer'
import { actions } from './actionReducer'
import { userActions } from './userActionReducer'
import session from './sessionReducer'
import { userCoords } from './userCoordsReducer'
import { passwordResets } from './passwordResetsReducer'
import { reducer as formReducer } from 'redux-form'

const createRootReducer = (history) =>
  combineReducers({
    topics,
    topicOptions,
    topicNotifications,
    conversationNotifications,
    surveyQuestions,
    conversations,
    hostedConversations,
    users,
    guests,
    pendingRequestedGuests,
    pendingInvitedGuests,
    userConversations,
    partners,
    partnerStats,
    session,
    userCoords,
    currentUser,
    sections,
    sponsors,
    conversationsTable,
    topicsTable,
    csvExports,
    usersTable,
    conversationInvitationRequests,
    conversationInvitations,
    isCurrentUserLoading,
    alertMessage,
    dialog,
    tabs,
    passwordResets,
    topicsPartners,
    actions,
    userActions,
    form: formReducer,
    router: connectRouter(history)
  })

export default createRootReducer
