import React from 'react'
import { ReactTitle } from 'react-meta-tags'

const MSSA = () => {
  return (
    <div>
      <ReactTitle title='Master Software and Services Agreement' />
      <section className='pane fat-pane'>
        <h1>Master Software and Services Agreement</h1>
        <p>
          We make it easy for Inclusivv customers to sign and submit our Master Software and Services Agreement (MSSA). Click the link below to download the MSSA and email a signed
          copy to contracts@civicdinners.com.
        </p>
        <p>
          <a href='https://civicdinners-manual-storage.s3.amazonaws.com/online-policies/Civic+Conversations+-+MSSA+(20210329v5).pdf' rel='noreferrer' target='_blank'>
            Download the MSSA
          </a>
        </p>
      </section>
    </div>
  )
}
export default MSSA
