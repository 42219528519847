import { types, Instance, getRoot } from 'mobx-state-tree'
import topicsApi from '../../api/TopicsApi'
import Topic from './Topic'
import { Alertable } from './helpers/Alertable'
import { LoadableStatus } from './helpers/LoadableStatus'
import { removeNullStringValue } from './utils'
import TopicsTableApi from '../../api/TopicsTableApi'
import { Filterable, IFilterableArgs } from './helpers/Filterable'
import { snakify } from '../../decorators/textTools'

export const Topics = types
  .compose(
    'Topics',
    LoadableStatus,
    Alertable,
    Filterable,
    types.model({
      list: types.optional(types.array(Topic), [])
    })
  )
  .actions((self) => ({
    addToList(data: any) {
      removeNullStringValue(data)
      self.list.push(data)
    },
    addList(data: any[]) {
      data.forEach((d) => removeNullStringValue(d))
      self.list.replace(self.list.concat(data))
    },
    setList(data: any[]) {
      data.forEach((d) => removeNullStringValue(d))
      self.list.replace(data)
    }
  }))
  .actions((self) => ({
    loadTopicsTable: (args: IFilterableArgs) => {
      if (self.isLoading('topics_table')) {
        return
      }
      self.startLoading('topics_table')
      const {
        partners: { selectedPartnerId }
      } = getRoot(self)

      return TopicsTableApi.getAll({ ...args, ...(selectedPartnerId ? { partner_ids: [selectedPartnerId] } : {}) }).then(
        ({ response: { ok, statusText }, json: { pages, topics, total } }) => {
          if (ok) {
            self.setList(topics)
            self.setTotalCount(total)
          } else {
            self.showAlert(statusText)
          }
          self.stopLoading('topics_table')
        }
      )
    },
    loadBySlug: (slug: string) => {
      if (self.loadingIds.includes(slug.toLowerCase())) {
        return
      }
      self.startLoading(slug.toLowerCase())
      return topicsApi.get(slug).then(({ response: { ok }, json }) => {
        if (ok) {
          self.addToList(json)
        }
        self.stopLoading(slug.toLowerCase())
      })
    },
    loadById: (id: number) => {
      if (self.loadingIds.includes(id)) {
        return
      }
      self.startLoading(id)
      return topicsApi.getByIds([id]).then(({ response: { ok }, json }) => {
        if (ok && json.length > 0) {
          self.addList(json)
        }
        self.stopLoading(id)
      })
    },
    afterCreate: () => {
      self.setSortingId('createdAt')
      self.setTimeScope('active')
      self.setSortedPriority(true)
    }
  }))
  .actions((self) => ({
    loadFilter: ({ reset = false, clear = true } = {}) => {
      if (reset) {
        self.page = 0
        self.pageSize = 15
        self.search = ''
      }

      if (clear) {
        self.list.replace([])
      }

      const { search, pageSize: page_size, page, sortedId: sorted_id, sortedPriority: sorted_priority, timeScope: time_scope } = self
      return self.loadTopicsTable({ search, page_size, page, sorted_id, sorted_priority, time_scope })
    },
    createTopic: (data) => {
      return topicsApi.create(snakify(data)).then(({ response: { ok }, json }) => {
        if (ok) {
          self.addToList(json)
          return { data: json }
        } else {
          self.showAlert('Error')
          return { error: json }
        }
      })
    }
  }))
export interface ITopics extends Instance<typeof Topics> {}
export default Topics
