import { toFormData } from './FormData'
import { deleteApi, get, post, put } from './utils'
import appAnalytics from '../analytics'

class ConversationsApi {
  static getAll({ limit, offset, coordinates, venueType }) {
    return get(`/conversations?limit=${limit}&offset=${offset}&coordinates=${coordinates}&venue_type=${venueType}&site_name=${global.siteName}`)
  }

  static get(id) {
    return get(`/conversations/${id}`, {
      noToken: !!localStorage.jwt
    })
  }

  static create(formData) {
    return post(`/conversations`, {
      body: JSON.stringify({ conversation: formData })
    }).then((data) => {
      appAnalytics.event({
        category: 'Conversation',
        action: 'Created Conversation'
      })
      return data
    })
  }

  static createSynth(formData) {
    return post(`/conversations`, {
      body: JSON.stringify({ conversation: formData, synth: true })
    }).then((data) => {
      appAnalytics.event({
        category: 'Conversation',
        action: 'Created Conversation'
      })
      return data
    })
  }

  static update(data) {
    const formData = toFormData('conversation', data)

    return put(`/conversations/${data.id}`, {
      noContentTypeJson: true,
      body: formData
    }).then((d) => {
      return d
    })
  }

  static destroy(id, destroy_message) {
    return deleteApi(`/conversations/${id}`, {
      body: JSON.stringify({ destroy_message })
    }).then((data) => {
      appAnalytics.event({
        category: 'Conversation',
        action: 'Deleted Conversation'
      })
      return data
    })
  }
}

export default ConversationsApi
