import React, { Component, useContext } from 'react'
import Button from '@material-ui/core/Button'
import { flow, assign, get, isUndefined } from 'lodash'
import { Link, useParams, withRouter } from 'react-router-dom'
import DOMPurify from 'dompurify'
import PageLoadingAnimation from '../../shared/PageLoadingAnimation'
import { formatTextArea, ellipsis } from '../../../decorators/textTools'
import ButtonIfLoggedIn from '../../utils/ButtonIfLoggedIn'
import withTranslation from '../../hocs/withTranslation'
import InfiniteLocationList from '../../shared/InfiniteLocationListMst'
import { capitalize } from '../../../decorators/textTools'
import { redirectableConvo, convoRedirects } from '../../../regionalRecords'
import CustomMetaTags from '../../shared/CustomMetaTags'
import { inject, observer } from 'mobx-react'
import { IStore } from '../../../store/models'
import appAnalytics from '../../../analytics'
import { DefaultThemeProvider } from '../../../themes'
import { ParamTrackContext, useParamTrack } from '../../Providers/ParamTrackProvider'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import ListCard from '../ListCard'

const TrackPage = (props) => {
  const { track } = useParamTrack()
  const { t } = useTranslation()

  if (!track) return <></>
  return (
    <div className={'tracks-show'}>
      <section
        className='pane fat-pane hero-pane'
        style={
          {
            // backgroundColor: theme.palette.primary.main,
            // color: theme.palette.primary.contrastText
          }
        }
      >
        <div className='row'>
          <div className='col-xs-12'>
            <h1 className='header-64 hero-header flat-bottom'>{track.name}</h1>
          </div>
        </div>
      </section>

      <div>
        <span id='conversations' />
        <section className={'pane thin-pane'}>
          <div className='row center-xs'>
            <h2 className='header-32 header-24-sm flat-bottom'>{t('user.upcomingConversations')}</h2>
          </div>
        </section>
        <section className={'pane reg-pane clear-pane'}>
          <div className='row center-xs'>
            <Grid container>
              <Grid item xs={12} sm={6} md={3}>
                {track.conversations.map((conversation) => (
                  <ListCard key={conversation.id} conversation={conversation} />
                ))}
              </Grid>
            </Grid>
          </div>
        </section>
      </div>
    </div>
  )
}

export default flow(withRouter, withTranslation, observer)(TrackPage)
