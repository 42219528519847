import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import React from 'react'
import DateFnsUtils from '@date-io/date-fns'
import ConversationStepper from './SynthStepper'

const NewConversationPage = (props: FIXME) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ConversationStepper {...props} />
    </MuiPickersUtilsProvider>
  )
}

export default NewConversationPage
