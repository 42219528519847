import React, { Component } from 'react'
import { connect } from 'react-redux'
import { map } from 'lodash'

import { loadSectionSponsors } from '../../../actions/sponsorActions'
import sponsorsFormGetter from '../../../getters/sponsorsFormGetter'
import Sponsor from './Sponsor.js'

class Sections extends Component {
  componentDidMount() {
    const { loadSectionSponsors, section } = this.props
    loadSectionSponsors(section.id)
  }

  render() {
    const { section, sponsors } = this.props

    return (
      <div className='row center-xs'>
        {map(sponsors, (sponsor) => (
          <Sponsor key={sponsor.id} {...sponsor} size={section.size} />
        ))}
      </div>
    )
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  const id = ownProps.section.id

  return {
    loadSectionSponsors: () => dispatch(loadSectionSponsors(id))
  }
}

export default connect(sponsorsFormGetter, mapDispatchToProps)(Sections)
