import React, { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { useParamPartner, useParamTopic } from '../Providers'

export const AnalyticsTracker = () => {
  const { partner } = useParamPartner()
  const { topic } = useParamTopic()
  useEffect(() => {
    if (topic && topic.googleTagId) {
      const tagManagerArgs = {
        gtmId: topic.googleTagId
      }

      TagManager.initialize(tagManagerArgs)
    } else if (partner && partner.googleTagId) {
      const tagManagerArgs = {
        gtmId: partner.googleTagId
      }

      TagManager.initialize(tagManagerArgs)
    }
  }, [partner, topic])
  return <></>
}
