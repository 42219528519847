import * as types from '../actions/actionTypes'
import initialState from './initialState'

export default function sessionReducer(state = initialState.session, action) {
  const storageHasJwt = process.env.NODE_ENV === 'test' ? !!localStorage.getItem('jwt') : !!localStorage.jwt

  switch (action.type) {
    case types.LOG_IN_SUCCESS:
      return { active: storageHasJwt, error: null }

    case types.LOG_IN_FAILED:
      return { active: false, error: action.data }

    case types.LOG_OUT:
      return { active: storageHasJwt, error: null }

    default:
      return state
  }
}
