import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { flow } from 'lodash'
import { ITopic } from '../../store'

const TopicProvider = ({ children }: Props) => {
  const [topic, setTopic] = useState(undefined)
  return <TopicContext.Provider value={{ topic, setTopic }}>{children}</TopicContext.Provider>
}

interface Props {
  children?: React.ReactNode | React.ReactNode[]
}

export const TopicContext = React.createContext({
  topic: undefined as ITopic | undefined,
  setTopic(conversation: any) {
    //  do nothing
  }
})

export default flow(observer)(TopicProvider)
