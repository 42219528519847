import { CircularProgress, Grid, IconButton, Tooltip } from '@material-ui/core'
import { flow } from 'lodash'
import { inject } from 'mobx-react'
import { observer } from 'mobx-react'
import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import appAnalytics from '../../../../analytics'
import { IStore } from '../../../../store/models'
import { IConversation } from '../../../../store/models/Conversation'
import { Link } from 'react-router-dom'

interface Props {
  conversation: IConversation
}
interface InjectedProps {
  createCsvExport(val1: FIXME, val2: FIXME)
  downloadingConversationIds: any[]
  downloadedConversationIds: any[]
  downloadCsv(conversationId: number)
}

const ConversationActions = ({
  conversation: original,
  history,
  createCsvExport,
  downloadingConversationIds,
  downloadedConversationIds,
  downloadCsv
}: Props & InjectedProps & RouteComponentProps) => {
  return (
    <Grid container direction='row' justify='space-around' alignItems='center'>
      <Tooltip title='Edit'>
        <Link to={`/admin/conversations/${original.id}/edit`}>
          <IconButton
            size='small'
            onClick={() => {
              appAnalytics.event({
                category: 'Admin',
                action: 'Edit Conversation Icon Clicked'
              })
            }}
          >
            <i className='fas fa-pen opacity-8'></i>
          </IconButton>
        </Link>
      </Tooltip>
      <Tooltip title='Manage Guests'>
        <Link to={`/admin/conversations/${original.id}/edit/t/guests`}>
          <IconButton
            size='small'
            onClick={() => {
              appAnalytics.event({
                category: 'Admin',
                action: 'Conversation Guests Icon Clicked'
              })
            }}
          >
            <i className='fas fa-user-friends opacity-8'></i>
          </IconButton>
        </Link>
      </Tooltip>
      <Tooltip title='Invite Guest'>
        <IconButton
          size='small'
          onClick={() => {
            appAnalytics.event({
              category: 'Admin',
              action: 'Conversation Invite Icon Clicked'
            })
            original.invite()
          }}
        >
          <i className='fas fa-user-plus opacity-8'></i>
        </IconButton>
      </Tooltip>
      {downloadingConversationIds.includes(original.id) ? (
        <CircularProgress size={'1.5em'} />
      ) : (
        <Tooltip title='Download Guest List'>
          <IconButton
            size='small'
            color={downloadedConversationIds.includes(original.id) ? 'primary' : undefined}
            onClick={() => {
              appAnalytics.event({
                category: 'Admin',
                action: 'Conversation Download Guest List Icon Clicked'
              })
              // if (downloadedConversationIds.includes(original.id)) {
              //   downloadCsv(original.id)
              // } else {
              createCsvExport('guest', { conversationId: original.id })
              // }
            }}
          >
            <i className='fas fa-cloud-download-alt opacity-8'></i>
          </IconButton>
        </Tooltip>
      )}
    </Grid>
  )
}

export default flow(
  observer,
  inject<InjectedProps, Props>(
    ({
      mst: {
        downloads,
        downloads: { list, createCsvExport }
      }
    }: {
      mst: IStore
    }) => {
      const listName = 'my_downloads'
      downloads.shouldLoad(listName, downloads.loadFilter)

      return {
        createCsvExport: (csvType, params) => {
          return createCsvExport(csvType, params, { download: true }).then(() => {
            downloads.loadFilter()
          })
        },
        downloadingConversationIds: list.filter((d) => d?.parameters?.conversationId && !d?.csvFile).map((d) => d?.parameters?.conversationId),
        downloadedConversationIds: list.filter((d) => d?.parameters?.conversationId && d?.csvFile).map((d) => d?.parameters?.conversationId),
        downloadCsv: (conversationId: number) => {
          const download = list.find((d) => d?.parameters?.conversationId === conversationId)
          download && download.csvFile && window.open(download.csvFile.replace(/dl=0/gi, 'dl=1'))
        }
      }
    }
  ),
  withRouter
)(ConversationActions)
