import React from 'react'
import { flow } from 'lodash'
import Button from '@material-ui/core/Button'
import FontIcon from '@material-ui/core/Icon'

import * as validations from '../validations/fieldLevelValidations'
import withTranslation from '../hocs/withTranslation'
import Dialog from '../DialogWrapper'
import { observer } from 'mobx-react'
import { inject } from 'mobx-react'
import { IStore } from '../../store/index'
import { useForm } from 'react-hook-form'
import { TextFieldControl } from '../utils'
import { connectDialogTheme } from '../../connectTheme'
import { Typography } from '@material-ui/core'
import palette from '../../themes/palette'

const EmailHostDialog = ({ dialog, t, messageUsers, user }: FIXME) => {
  const { conversation } = dialog.data

  const { handleSubmit, errors, formState, control, watch, setValue, clearErrors, setError } = useForm({ mode: 'onBlur', shouldUnregister: false })

  const { isDirty, isSubmitting } = formState

  const handleSave = handleSubmit(({ message }: any, e) => {
    const data = {
      content: message,
      recipientId: conversation.host.id,
      senderId: user.id,
      conversationId: conversation.id,
      dialog: 'emailHost'
    }

    messageUsers(data)
  })

  return (
    <Dialog dialog={dialog} name='emailHost' type='noPadding' title={'Message Host'}>
      <section className='pane thin-reg-pane minimal-grey-pane'>
        <div className='row reg-bottom'>
          <div className='col-xs-12 center-xs'>
            <div className='box'>
              <FontIcon style={{ color: palette.blue500 }} className='far fa-paper-plane header-36' />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12 center-xs'>
            <Typography variant='h6'>Send Host a Message by Email</Typography>
          </div>
        </div>
      </section>
      <section className='pane big-top-padding big-bottom-padding'>
        <form onSubmit={handleSave}>
          <div className='row reg-bottom'>
            <div className='col-xs-12 align-left'>
              <TextFieldControl
                name='message'
                control={control}
                defaultValue={conversation && conversation.message?.toString()}
                error={errors && errors.message}
                label={t('conversation.hostMessage')}
                multiline={true}
                rules={{ validate: { required: validations.required } }}
                rows={3}
                variant='outlined'
              />
            </div>
          </div>

          <div className='row center-xs'>
            <div className='col-xs-12'>
              <Button disabled={!isDirty || isSubmitting} type='submit' className='reg-bottom flat-top btn-xl btn-block lowercase capitalize' variant='contained' color='primary'>
                {t('conversation.sendMessage')}
              </Button>
            </div>
          </div>
        </form>
      </section>
    </Dialog>
  )
}

export default flow(
  connectDialogTheme,
  withTranslation,
  observer,
  inject<FIXME, FIXME>(
    (
      {
        mst: {
          auth: { user },
          dialogs: { showDialog },
          users: { messageUsers },
          conversations
        }
      }: {
        mst: IStore
      },
      { dialog }
    ) => {
      return { user, showDialog, messageUsers }
    }
  )
)(EmailHostDialog)
