import React from 'react'
import { Link } from 'react-router-dom'
import { useAppLayout, useParamPartner } from '../Providers'
import { observer } from 'mobx-react'

const AppLogo = observer(({ redirectToPartner }) => {
  const { logoUrl, logoLink } = useAppLayout()

  return (
    <>
      {logoLink && (
        <a href={logoLink} target='blank'>
          {logoUrl && <img className='img-responsive' src={logoUrl} alt={window.siteTitlePlural} style={{ maxHeight: '66px' }} />}
        </a>
      )}
    </>
  )

  // )
})
export default AppLogo
