import React from 'react'
import FontIcon from '@material-ui/core/Icon'
import Button from '@material-ui/core/Button'
import { DialogTitle } from '@material-ui/core'
import * as validations from '../../validations/fieldLevelValidations'
import withTranslation from '../../hocs/withTranslation'
import { inject, observer } from 'mobx-react'
import { IStore } from '../../../store/models/index'
import { useForm } from 'react-hook-form'
import { TextFieldControl } from '../../utils/index'

const CreatedConversationDialogStepTwo = ({ user, conversation, t, showDialog, closeDialog, dialog }: any) => {
  const { handleSubmit, errors, formState, control } = useForm()
  const { isDirty, isSubmitting } = formState

  const { skipProfileImage, hideShare } = dialog.data

  if (!conversation) {
    return null
  }

  const handleSave = handleSubmit(({ emails, message }: any, e) => {
    const formData = emails.split(',').map((email: string) => ({ message, recipientEmail: email, conversationId: conversation.id, senderId: user.id }))
    Promise.all(formData.map((emailData: string) => conversation.createConversationInvitation(emailData))).then(() => {
      closeDialog('createdConversation')
      showDialog('createdConversation', { conversation, step: 3, skipProfileImage, hideShare })
    })
  })

  const header = conversation.isHost ? t('conversation.inviteGuests') : t('conversation.inviteFriends')

  const subheader = conversation.isHost ? t('conversation.inviteByEmail') : t('invitation.inviteByEmail')

  const draftText = () => {
    const {
      isHost,
      isVirtual,
      title,
      dateLocally,
      startsAtLocally,
      endsAtLocally,
      timeZoneAbbr,
      virtualEventTypeSingular,
      brandSingular,
      venueName,
      neighborhood,
      city
    } = conversation
    const hostingOrAttending = isHost ? t('conversation.hosting') : t('conversation.attending')
    if (isVirtual) {
      return t('conversation.draftVirtual', {
        hostingOrAttending,
        title,
        virtualEvent: virtualEventTypeSingular,
        dateLocally,
        startsAtLocally,
        endsAtLocally,
        timeZoneAbbr
      })
    } else {
      return t('conversation.draftText', {
        hostingOrAttending,
        title,
        brandSingular,
        dateLocally,
        startsAtLocally,
        endsAtLocally,
        timeZoneAbbr,
        venueName,
        neighborhood,
        city
      })
    }
  }

  return (
    <div>
      <DialogTitle disableTypography>{header}</DialogTitle>

      <section className='pane thin-reg-pane'>
        <div className='row reg-bottom'>
          <div className='col-xs-12 center-xs'>
            <div className='box'>
              <FontIcon className='fal fa-envelope-open-text header-36' />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12 center-xs'>
            <h1 className='flat header-24'>{subheader}</h1>
          </div>
        </div>
      </section>

      <section className='pane minimal-grey-pane big-top-padding big-bottom-padding'>
        <form onSubmit={handleSave}>
          <div className='row xtra-fat-bottom'>
            <div className='col-xs-12'>
              <TextFieldControl
                rules={{ validate: { required: validations.required, emails: validations.emails } }}
                label={t('conversation.emailHint')}
                name='emails'
                control={control}
                error={errors && errors.emails}
              />
            </div>
          </div>

          <div className='row xtra-fat-bottom'>
            <div className='col-xs-12 align-left'>
              <TextFieldControl
                rules={{ validate: validations.required }}
                name='message'
                multiline={true}
                rows={4}
                label={t('conversation.messageLabel')}
                control={control}
                error={errors && errors.message}
                defaultValue={draftText()}
              />
              <div className='label'>{t('conversation.insertMessage')}</div>
            </div>
          </div>

          <div className='row center-xs'>
            <div className='col-xs-12'>
              <Button
                type='submit'
                className='xtra-fat-bottom flat-top btn-xl btn-block lowercase capitalize'
                disabled={!isDirty || isSubmitting}
                variant='contained'
                color='primary'
              >
                {t('conversation.sendInvite')}
              </Button>
            </div>
          </div>
        </form>
      </section>
    </div>
  )
}

export default inject<FIXME, FIXME>(
  (
    {
      mst: {
        auth: { user },
        dialogs: { showDialog, closeDialog },
        conversations
      }
    }: {
      mst: IStore
    },
    ownProps
  ) => {
    const conversationId = ownProps.dialog.data.conversation.id
    const conversation = conversations.list.find((d) => d.id === conversationId)
    if (!conversation && !conversations.loadingIds.includes(conversationId)) {
      conversations.getConversation(conversationId)
    }
    return {
      user,
      conversation,
      showDialog,
      closeDialog
    }
  }
)(observer(withTranslation(CreatedConversationDialogStepTwo)))
