import React from 'react'
import { Card } from '@material-ui/core'

import Pending from './Pending'
import Invite from './Invite'
import List from './partials/List'
import { observer } from 'mobx-react'

const PartnerAdmins = ({ partner }: any) => {
  if (!partner) return null
  return (
    <>
      <Card className='no-padding xtra-huge-bottom no-shadow'>
        <List partner={partner} />
        <Pending partner={partner} />
        <Invite partner={partner} />
      </Card>
    </>
  )
}

export default observer(PartnerAdmins)
