import React from 'react'

import { observer } from 'mobx-react'
import { Typography } from '@material-ui/core'
import { useParamConversation } from '../../../Providers'

const NoteFromHost = observer(() => {
  const { conversation } = useParamConversation()
  if (!conversation || conversation.isVirtual || !conversation.guestInstructions) return <></>
  return (
    <>
      <Typography variant='subtitle1' className='thin-bottom reg-top'>
        A Note from the Host
      </Typography>
      <Typography variant='body2' className='fat-bottom'>
        {conversation.guestInstructions}
      </Typography>
    </>
  )
})

export default NoteFromHost
