import { createSelector } from 'reselect'
import { mapValues, assign, has } from 'lodash'

function getUsersRaw(state) {
  return state.users
}

export const getUsersList = createSelector(
  getUsersRaw,
  u => u.list
)

export const getUserIdFromParams = (state, props) => {
  return has(props, 'match.params.id') ? parseInt(props.match.params.id, 10) : null
}

export const getUsers = createSelector(
  getUsersList,
  users => {
    return mapValues(users, User)
  }
)

export const getUserLoading = createSelector(
  getUsersRaw,
  u => u.loadingUser
)

export const getUser = createSelector(
  getUsers,
  getUserIdFromParams,
  (users, profileId) => {
    var user = users[profileId]
    if (!user) return {}
    user = mapValues(user, val => (val === 'null' ? null : val))
    return user
  }
)

export function User(user) {
  return assign(user, {
    fullName: fullName(user)
  })
}

export function fullName({ firstName, lastName }) {
  return `${firstName} ${lastName}`
}
