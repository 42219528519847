import React, { useState } from 'react'
import { CheckboxControl } from '../../utils'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { observer } from 'mobx-react'
import { IConversation } from '../../../store'

const SuitableDiets = ({ conversation }: { conversation?: IConversation }) => {
  const { t } = useTranslation()
  const { watch, control } = useFormContext()
  const dietTypes = [
    { value: 'vegetarian', label: t('conversation.vegetarian') },
    { value: 'vegan', label: t('conversation.vegan') },
    { value: 'pescatarian', label: t('signup.pescatarian') },
    { value: 'nut_allergy', label: t('signup.nutAllergy') },
    { value: 'gluten_free', label: t('signup.gluten') },
    { value: 'dairy_free', label: t('conversation.dairyFree') }
  ]

  const suitableDiets = (conversation?.suitableDiets as any) || {}

  if (!conversation) return <></>
  if (watch('conversationType') === 'no_food_provided') {
    return <></>
  }
  return (
    <div>
      {dietTypes.map(({ label, value }) => (
        <div key={value}>
          <CheckboxControl name={`suitableDiets[${value}]`} label={label} control={control} defaultValue={suitableDiets[value] === true || suitableDiets[value] === 'true'} />
        </div>
      ))}
    </div>
  )
}

export default observer(SuitableDiets)
