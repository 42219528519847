import React, { useEffect } from 'react'
import ResponsiveBarCard from './ResponsiveBarCard'
import palette from '../../../../themes/palette'
import { Button } from '@material-ui/core'
import { flow } from 'lodash'
import { inject, observer } from 'mobx-react'
import { IStore } from '../../../../store/models'
import { getSnapshot } from 'mobx-state-tree'

const TotalConversations = ({ selectedPartnerId, graphData, loadAnalyticsData, loading }: Props) => {
  useEffect(() => {
    loadAnalyticsData()
  }, [loadAnalyticsData])

  useEffect(() => {
    loadAnalyticsData({}, { reload: true })
  }, [loadAnalyticsData, selectedPartnerId])

  return (
    <ResponsiveBarCard
      title='Total Conversations per Topic (Past Conversations)'
      yKeyName='Conversations'
      graphData={graphData}
      chartColor={palette.lightOrange}
      headerAction={<Button color='primary'></Button>}
      loading={loading}
      id={'ic-admin-bar-chart-conversations'}
    />
  )
}

interface Props {
  selectedPartnerId?: number
  graphData: any
  loading?: boolean
  loadAnalyticsData(val?: any, opt?: any)
}

export default flow(
  observer,
  inject<FIXME, FIXME>(
    ({
      mst: {
        analytics: { conversationsBar },
        partners: { selectedPartnerId }
      }
    }: {
      mst: IStore
    }) => {
      const { graphData, loadAnalyticsData, getDataName, loadingIds } = conversationsBar
      return {
        graphData: getSnapshot(graphData),
        loadAnalyticsData,
        selectedPartnerId,
        loading: loadingIds.includes(getDataName())
      }
    }
  )
)(TotalConversations)
