import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

const PageLoadingAnimation = ({ ...props }) => {
  const { size = 50, thickness = 3.5 } = props

  return (
    <div className='row center-xs page-spinner-placeholder'>
      <CircularProgress size={size} thickness={thickness} />
    </div>
  )
}

export default PageLoadingAnimation
