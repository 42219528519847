import { types, getRoot } from 'mobx-state-tree'
import { IStore } from '..'

export const Dialogable = types
  .model('Dialogable', {})
  .views((self) => ({
    get dialogs() {
      const { dialogs }: IStore = getRoot(self)
      return dialogs
    }
  }))
  .actions((self) => ({
    showDialog(name: string, data?: any) {
      const { dialogs }: IStore = getRoot(self)
      dialogs.showDialog(name, data)
    },
    closeDialog(name: string) {
      const { dialogs } = getRoot(self)
      dialogs.closeDialog(name)
    }
  }))
