import ViewQuiltIcon from '@material-ui/icons/ViewQuilt'
import ChatIcon from '@material-ui/icons/Chat'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import PeopleIcon from '@material-ui/icons/People'
import ShowChartIcon from '@material-ui/icons/ShowChart'
import SettingsIcon from '@material-ui/icons/Settings'
import { CustomersIcon } from '../../admin/v2/partials/AdminMenu'
export const INCLUSIVV_FOR_LINKS = [
  { label: 'nav.companies', url: 'https://inclusivv.co/for-companies' },
  { label: 'nav.universities', url: 'https://inclusivv.co/for-universities' },
  { label: 'nav.citiesRegions', url: 'https://inclusivv.co/for-communities' },
  { label: 'nav.Individuals', url: 'https://inclusivv.co/for-individuals' }
]
export const ABOUT_LINKS = [
  { label: 'nav.blog', url: 'https://blog.inclusivv.co' },
  { label: 'nav.impactStories', url: 'https://inclusivv.co/impact-stories' },
  { label: 'nav.ourStory', url: 'https://inclusivv.co/our-story' },
  { label: 'nav.ourTeam', url: 'https://inclusivv.co/our-team' },
  { label: 'nav.press', url: 'https://inclusivv.co/press?hsLang=en' }
]
export const EXPLORE_LINKS = [
  { label: 'nav.overview', url: 'https://inclusivv.co/overview' },
  { label: 'nav.conversationTopics', url: 'https://inclusivv.co/browse-topics' },
  { label: 'nav.conversationSeries', url: 'https://inclusivv.co/conversation-series' },
  { label: 'nav.howToHost', url: 'https://inclusivv.co/how-to-host' }
]
export const MY_ORGANIZATION_LINK = [
  { label: 'nav.dashboard', url: '/admin', icon: ViewQuiltIcon },
  { label: 'nav.topics', url: '/admin/topics', icon: ChatIcon },
  { label: 'nav.conversations', url: '/admin/conversations', icon: CalendarTodayIcon },
  { label: 'nav.attendees', url: '/admin/attendees', icon: PeopleIcon },
  // { label: 'nav.surveys', url: '/admin', icon: 'fal fa-chart-bar' },
  { label: 'nav.stories', url: '/admin/stories', icon: 'far fa-book-spells' },
  { label: 'nav.analytics', url: '/admin/analytics', icon: ShowChartIcon },
  { label: 'nav.customers', url: '/admin/settings', icon: CustomersIcon }
  // { label: 'nav.helpDocumentation', url: 'https://help.inclusivv.co/en/' }
]

export const PRICING_LINKS = 'https://inclusivv.co/pricing'
export const TALK_TO_EXPERTS_LINK = 'https://inclusivv.co/contact-us'
