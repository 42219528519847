import { types, Instance, getRoot } from 'mobx-state-tree'
import { Alertable } from '../helpers/Alertable'
import { ImageUrl } from '../base/BaseObjects'
import Editable from '../helpers/Editable'
import { RootAccessable } from '../helpers/RootAccessable'

const Document = types
  .compose(
    'Document',
    Alertable,
    Editable,
    RootAccessable,
    types.model({
      id: types.identifierNumber,
      documentableId: types.number,
      documentableType: types.string,
      documentType: types.maybeNull(types.string),
      file: types.maybe(ImageUrl),
      preview: types.maybeNull(ImageUrl),
      download: types.maybeNull(ImageUrl),
      groupId: types.maybeNull(types.string)
    })
  )
  .actions((self) => ({
    canEdit: () => {
      const { user } = self.getAuth()
      return user && (user.isSuperAdmin() || user.isPartnerAdmin())
    },
    fileName: () => {
      const fileUrl = self.file?.url || '/'
      return fileUrl.split('/').pop() || ''
    }
  }))

export interface IDocument extends Instance<typeof Document> {}
export default Document
