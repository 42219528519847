import { types, Instance } from 'mobx-state-tree'
import StoriesApi from '../../api/StoriesApi'
import { Alertable } from './helpers/Alertable'
import { Filterable, IFilterableArgs } from './helpers/Filterable'
import { LoadableStatus } from './helpers/LoadableStatus'
import { removeNullStringValue } from './utils'

import { snakify } from '../../decorators/textTools'
import { times, update } from 'lodash'
import Editable from './helpers/Editable'
import { ImageUrl } from './base/BaseObjects'
import { Dialogable } from './helpers'
import { RootAccessable } from './helpers/RootAccessable'

export const Story = types
  .compose(
    'Story',
    Editable,
    Alertable,
    Dialogable,
    types.model({
      id: types.identifierNumber,
      userId: types.maybeNull(types.number),
      conversationId: types.maybeNull(types.number),
      topicId: types.maybeNull(types.number),
      headline: types.string,
      firstName: types.maybeNull(types.string),
      lastName: types.maybeNull(types.string),
      createdAt: types.maybeNull(types.string),
      userLocation: types.maybeNull(types.string),
      asset: types.maybeNull(ImageUrl),
      prompt: types.maybeNull(types.string),
      visible: types.maybeNull(types.boolean),
      content: types.string,
      title: types.string,
      startsAt: types.string,
      slug: types.string,
      userAvatar: types.maybeNull(ImageUrl)
    })
  )
  .actions((self) => ({
    isHost: () => false,
    show: () => self.showDialog('showStory', { story: self }),
    updateVisibility: (visible: boolean) => {
      return StoriesApi.update(self.id, { visible }).then(({ response: { ok, statusText }, json }) => {
        if (ok) {
          self.updateMe(json)
          self.showAlert('Updated')
        }
      })
    }
  }))
  .views((self) => ({
    get authorName() {
      return `${self.firstName} ${self.lastName}`
    },
    get conversationLink() {
      return `${window.location.host.includes('http') ? '' : 'https://'}${window.location.host}/${self.slug}/conversations/${self.conversationId}`
    },
    get topicLink() {
      return `${window.location.host.includes('http') ? '' : 'https://'}${window.location.host}/${self.slug}`
    },
    get storyLink() {
      return `${window.location.host.includes('http') ? '' : 'https://'}${window.location.host}/${self.slug}/a/stories/${self.id}`
    }
  }))

const Word = types.model('Word', {
  text: types.string,
  value: types.number
})
export const Stories = types
  .compose(
    'Stories',
    LoadableStatus,
    Alertable,
    Filterable,
    RootAccessable,
    types.model({
      list: types.optional(types.array(Story), []),
      words: types.array(Word)
    })
  )
  .actions((self) => ({
    addToList(data: any) {
      removeNullStringValue(data)
      const found = self.list.find((o) => o.id === data.id)
      if (found) {
        Object.assign(found, data)
      } else {
        self.list.push(data)
      }
    },
    setList(data: any[], totalCount?: number) {
      data.forEach((d) => removeNullStringValue(d))
      self.list.replace(data)
      self.setTotalCount(totalCount)
    },
    setWords(data: any[]) {
      self.words.replace(data)
    }
  }))
  .actions((self) => ({
    afterCreate: () => {
      self.setSortingId('id')
      self.setSortedPriority(true)
    },
    loadStories: (topic_id: number) => {
      const lisName = 'stories'
      if (self.isLoading(lisName)) {
        return
      }
      self.startLoading(lisName)

      return StoriesApi.getStoriesForTopic({ topic_id }).then(({ response: { ok, statusText }, json: { stories, total } }) => {
        if (ok) {
          self.setList(stories, total)
        } else {
          self.showAlert(statusText)
        }
        self.stopLoading(lisName)
      })
    },
    loadStoriesTable: (args: IFilterableArgs) => {
      const lisName = 'stories_table'
      if (self.isLoading(lisName)) {
        return
      }
      self.startLoading(lisName)

      const { selectedPartnerId } = self.getPartners()

      return StoriesApi.getAll({ ...args, ...(selectedPartnerId ? { partner_id: selectedPartnerId } : {}) }).then(
        ({ response: { ok, statusText }, json: { stories, total, words } }) => {
          if (ok) {
            self.setList(stories, total)
            self.setWords(words)
          } else {
            self.showAlert(statusText)
          }
          self.stopLoading(lisName)
        }
      )
    }
  }))
  .actions((self) => ({
    loadFilter: ({ reset = false, clear = true } = {}) => {
      if (reset) {
        self.page = 0
        self.pageSize = 15
        self.search = ''
      }

      if (clear) {
        self.list.replace([])
      }

      const { search, pageSize: page_size, page, sortedId: sorted_id, sortedPriority: sorted_priority } = self
      return self.loadStoriesTable({ search, page_size, page, sorted_id, sorted_priority })
    }
  }))
export interface IStories extends Instance<typeof Stories> {}
export interface IStory extends Instance<typeof Story> {}
export default Stories
