import { assign } from 'lodash'
import initialState from './initialState'
import { LOADING_CSV_EXPORT, LOAD_CSV_EXPORT_FAILED, LOAD_CSV_EXPORT_SUCCESS } from '../actions/actionTypes'

export const csvExports = (state = initialState.csvExports, action) => {
  switch (action.type) {
    case LOADING_CSV_EXPORT:
      return assign({}, state, {
        loading: true,
        error: null
      })

    case LOAD_CSV_EXPORT_SUCCESS:
      return assign({}, state, {
        loading: false,
        error: null,
        list: assign({}, state.list, action.csvExports)
      })

    case LOAD_CSV_EXPORT_FAILED:
      return assign({}, state, {
        loading: false,
        error: action.data
      })

    default:
      return state
  }
}

export default csvExports
