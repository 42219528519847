import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import { flow, some } from 'lodash'
import appAnalytics from '../../analytics'

import { ellipsis } from '../../decorators/textTools.js'
import { connectTopicTheme } from '../../connectTheme.js'
import withTranslation from '../hocs/withTranslation'

class TopicCard extends Component {
  render() {
    const {
      theme,
      featured,
      topic: { slug, title, description, cardBanner, icon },
      t
    } = this.props

    const SlugIcon = () => {
      if (slug !== 'kai-and-korero') {
        return (
          <div className='row middle-xs'>
            <div className='convo-icon'>
              <img className='img-responsive' src={icon.primary.url} alt='icon' />
            </div>
          </div>
        )
      } else return null
    }

    const Description = () => {
      if (slug === 'kai-and-korero') {
        return (
          <div className='row no-gutters middle-xs thin-bottom'>
            <div className='col-xs header-18 white-text align-center'>{ellipsis({ text: description, length: 20, decorator: null })}</div>
          </div>
        )
      } else return null
    }

    const bannerSize = () => {
      if (title.length < 16) return 'header-22 header-16-xs'
      else return 'header-20 header-16-xs'
    }

    const CardBanner = () => {
      if (cardBanner) {
        return (
          <div className='row card-date-row middle-xs no-margin lightly-darkened-pane'>
            <div className='col-xs-12 no-gutters'>
              <div className={`${bannerSize()} capitalize`}>{cardBanner}</div>
            </div>
          </div>
        )
      } else return null
    }

    const titleSize = () => {
      let longWords = some(title.split(/\s+/), (word) => word.length > 15)
      if (longWords) return 'header-30 header-25-xs'
      if (title.length < 16) return 'header-40 header-36-xs'
      if (title.length < 24) return 'header-38 header-34-xs'
      if (title.length < 34) return 'header-36 header-32-xs'
      else return 'header-30 header-25-xs'
    }

    const trackEvent = () =>
      appAnalytics.event({
        category: 'Topic',
        action: 'Topic Card Cicked',
        nextPath: `/${slug}`,
        featured: !!featured
      })

    return (
      <div className='col-xs-12 col-sm-4'>
        <Link to={'/' + slug} onClick={trackEvent}>
          <div
            className={`${slug} card convo-card relative`}
            style={{
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText
            }}
          >
            <div className='row no-gutters middle-xs'>
              <CardBanner />

              <SlugIcon />

              <div className={`col-xs line-height-reg fat-bottom bold white-text align-center no-gutters min-max-width ${titleSize()}`}>{title}</div>
            </div>

            <Description />
            <div className='row align-center card-button-row bottom-xs absolute'>
              <div className='col-xs'>
                <div className='card-button'>
                  <Button variant='contained'>{t('common.learnMore')}</Button>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    )
  }
}

export default flow(connectTopicTheme)(withTranslation(TopicCard))
