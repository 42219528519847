import React, { useState } from 'react'
import { Button } from '@material-ui/core'
import SummaryContainer from '../partials/SummaryContainer'
import NpsAnswersTable from './NpsAnswersTable'
import { observer } from 'mobx-react'
import { inject } from 'mobx-react'
import { flow } from 'lodash'
import { IStore } from '../../../../store/models'
import { IUser } from '../../../../store/models/User'
import { RouteComponentProps, withRouter } from 'react-router'

interface Props {
  // user?: IUser
  // createCsvExport(params: FIXME): Promise<FIXME>
}
interface InjectedProps {
  user?: IUser
  createCsvExport(params?: FIXME): Promise<FIXME>
  selectedPartnerId?: number
}

const NpsAnswersSummary = ({ user, history, createCsvExport, selectedPartnerId }: InjectedProps & Props & RouteComponentProps) => {
  const [exported, setExported] = useState(false)
  const [exporting, setExporting] = useState(false)
  return (
    <div>
      <SummaryContainer
        title='Feedback'
        topRight={
          user && (user.isSuperAdmin() || user.isPartnerAdmin()) ? (
            <div>
              <Button
                variant='contained'
                disabled={exporting}
                startIcon={<i className='fal fa-file-export' />}
                onClick={() => {
                  setExporting(true)
                  createCsvExport({ parameters: { selectedPartnerId } }).then(() => {
                    setExported(true)
                    setExporting(false)
                  })
                }}
              >
                Export Data to CSV
              </Button>
              {exported && (
                <Button
                  className='reg-left'
                  startIcon={<i className='fas fa-cloud-download-alt' />}
                  onClick={() => {
                    history.push('/admin/analytics/downloads')
                  }}
                >
                  My Downloads
                </Button>
              )}
            </div>
          ) : undefined
        }
      >
        <NpsAnswersTable />
      </SummaryContainer>
    </div>
  )
}

export default flow(
  withRouter,
  observer,
  inject<InjectedProps, Props>(
    ({
      mst: {
        auth: { user },
        nps_answers: { createCsvExport },
        partners: { selectedPartnerId }
      }
    }: {
      mst: IStore
    }) => {
      return {
        user,
        createCsvExport,
        selectedPartnerId: selectedPartnerId || user?.associatedPartners[0]?.id
      }
    }
  )
)(NpsAnswersSummary)
