import React, { Component, useContext } from 'react'
import Button from '@material-ui/core/Button'
import { flow, assign, get, isUndefined } from 'lodash'
import { Link, useParams, withRouter } from 'react-router-dom'
import DOMPurify from 'dompurify'
import { connectTopicTheme } from '../../../connectTheme'
import PageLoadingAnimation from '../../shared/PageLoadingAnimation'
import { formatTextArea, ellipsis } from '../../../decorators/textTools'
import ProcessDescription from '../partials/ProccessDescription'
import ButtonIfLoggedIn from '../../utils/ButtonIfLoggedIn'
import BecomeAHostPane from '../partials/BecomeAHostPane'
import Sections from '../../sections/Sections'
import WhatToExpectFB from '../../conversations/WhatToExpectFB'
import withTranslation from '../../hocs/withTranslation'
import InfiniteLocationList from '../../shared/InfiniteLocationListMst'
import { capitalize } from '../../../decorators/textTools'
import { redirectableConvo, convoRedirects } from '../../../regionalRecords'
import CustomMetaTags from '../../shared/CustomMetaTags'
import { inject, observer } from 'mobx-react'
import { IStore, ITopic } from '../../../store/models'
import appAnalytics from '../../../analytics'
import { DefaultThemeProvider } from '../../../themes'
import { Stories } from './sections/Stories'
import { ParamTopicContext } from '../../Providers/ParamTopicProvider'
import { useAuth } from '../../Providers'
import FavButton from '../../users/MyConversations/FavButton'
import { SubscribeButton } from './sections/SubscribeButton'
import { Video } from './sections/Video'
import { AnalyticsTracker } from '../../partners/AnalyticsTracker'

const HostAConversation = ({ topic, children }) => {
  const { user } = useAuth()

  return (
    <>
      {!topic.isTemplate && (!topic.privateHosting || user?.isCommunityAdmin() || (user && topic.invitedHostIds.includes(user.id))) && (
        <div className='col-xs-12 col-sm-4 btn-column'>
          <div className='box'>{children}</div>
        </div>
      )}
    </>
  )
}

const TopicMetaTags = () => {
  const { topic } = useContext(ParamTopicContext)
  const { action, action_id } = useParams<{ action: string; action_id: string }>()
  if (!topic || (action && action.toLocaleLowerCase() === 'stories' && action_id)) {
    return <></>
  } else {
    return <CustomMetaTags record={topic} />
  }
}
export class TopicPage extends Component<{ topic: ITopic } & FIXME, FIXME> {
  state = {
    showFullDescription: false,
    showLearnMore: false,
    showProcessDescription: false,
    showFullAbout: false
  }
  storySection

  componentDidUpdate(prevProps) {
    const {
      loaded,
      user,
      topic,
      changeLanguage,
      redirectToTopic,
      history,
      location,
      match: {
        params: { action }
      }
    } = this.props
    if (topic && topic !== prevProps.topic) {
      if (topic.locale) {
        changeLanguage(topic.locale)
      }
    }

    if (window.coco) {
      convoRedirects(prevProps, this.props) && redirectToTopic({ siteId: 2 })
    }

    if (loaded && action && topic) {
      switch (action) {
        case 'invitation_request':
          topic.createTopicInvitationRequest()
          user && history.replace(location.pathname.replace('/a/invitation_request', ''))
          break
        case 'next_path':
          this.nextPath()
          break

        default:
          break
      }
    }
  }

  handleAttendConversation = () => {
    return () => {
      appAnalytics.event({
        category: 'Topic',
        action: 'Attend a Conversation'
      })
      const conversationSection: any = document.getElementById('conversations')
      conversationSection.scrollIntoView({ behavior: 'smooth' })
    }
  }

  handleReadMore = () => {
    return () => {
      this.setState({
        showProcessDescription: !this.state.showProcessDescription
      })
    }
  }

  nextPath = () => {
    const { slug, history, user, setRedirectOnSuccess, showDialog } = this.props
    if (user) {
      history.push(`/${slug}/new`)
    } else {
      setRedirectOnSuccess(`/${slug}/new`)
      showDialog('signup')
    }
  }

  render() {
    const { topicLoading, showDialog, userCoordsLoading, error, topic, theme, showTopicSubscribeButton, t, slug, loaded, history, conversations, stories, subscribed } = this.props

    if (error) {
      return <div className='alert alert-danger'>Error: {error.statusText} </div>
    }

    const redirecting = redirectableConvo(slug) && userCoordsLoading

    const pageNotReady = () => {
      return redirecting || topicLoading || isUndefined(topic) || !loaded
    }
    if (pageNotReady()) return <PageLoadingAnimation />

    const { title, subtitle, description, partnerLogo, partnerName, partnerLogoLink, active, eventTypeSingular, locale, videoLink } = topic
    const { showFullDescription, showFullAbout } = this.state

    const EditStyle = { marginLeft: 'calc(100% - 88px)' }

    const newConversationPath = `/${slug}/new`

    const renderSubtitle = subtitle ? <h2 className='header-32 hero-sub-header thin-top'>{subtitle}</h2> : null

    const PartnerLogo = () => {
      if (partnerLogoLink) {
        return (
          <div className='partner-logo-container center-margin'>
            <Link to={partnerLogoLink}>
              <img src={partnerLogo.primary.url} className='img-responsive' alt={partnerName} />
            </Link>
          </div>
        )
      } else {
        return (
          <div className='partner-logo-container center-margin'>
            <img src={partnerLogo.primary.url} className='img-responsive' alt={partnerName} />
          </div>
        )
      }
    }

    const hostConversationButton = (options = {}) => {
      const className = get(options, 'className')

      const nestOptions = {
        label: t('convos.hostOwn'),
        hosting: true,
        className,
        onClick: () => {
          appAnalytics.event({
            category: 'Topic',
            action: 'Host Your Own'
          })
          this.nextPath()
        }
      }
      return <ButtonIfLoggedIn {...nestOptions} />
    }

    const WrappedAboutSection = () => {
      // hack for fb
      if (slug === 'femalefounders') {
        return (
          <div
            className='description-section'
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(topic.about)
            }}
          />
        )
      } else {
        return (
          <DescriptionSection
            text={topic.about}
            showFullDescription={showFullAbout}
            toggleView={() => this.setState({ showFullAbout: !showFullAbout })}
            length={47}
            readmore={true}
            t={t}
          />
        )
      }
    }

    const activeActions = () => {
      return (
        <div className='row center-xs'>
          <div className='col-xs-12 col-sm-4 btn-column'>
            <div className='box'>
              <DefaultThemeProvider>
                <Button color={window.coco ? undefined : 'primary'} onClick={this.handleAttendConversation()} className='btn-xl' fullWidth={true} variant='contained'>
                  {t('convos.attendConversation', {
                    eventTypeSingular: capitalize(eventTypeSingular)
                  })}
                </Button>
              </DefaultThemeProvider>
            </div>
          </div>
          <HostAConversation topic={topic}>{hostConversationButton()}</HostAConversation>

          <SubscribeButton />
        </div>
      )
    }

    const inactivePane = () => {
      return (
        <div className='row center-xs'>
          <div className='col-xs-12 btn-column'>
            <div className='box'>
              <h2 className='header-28 flat-bottom'>{t('convos.inactive')}</h2>
            </div>
          </div>
        </div>
      )
    }

    const actionPane = () => {
      if (active) {
        return activeActions()
      } else {
        return inactivePane()
      }
    }

    return (
      <div className={`${slug} topics-show`}>
        <AnalyticsTracker />
        <TopicMetaTags />
        <div style={{ float: 'right' }}>
          <FavButton likableId={topic.id} likableType='Topic' />
        </div>
        <section
          className='pane fat-pane hero-pane'
          style={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText
          }}
        >
          <div className='row'>
            <div className='col-xs-12'>
              <h1 className='header-64 hero-header flat-bottom'>{title}</h1>
            </div>
          </div>

          <div className='row'>
            <div className='col-xs-12 col-sm-8'>
              {renderSubtitle}

              <DescriptionSection
                text={description}
                showFullDescription={showFullDescription}
                toggleView={() => this.setState({ showFullDescription: !showFullDescription })}
                length={170}
                readmore={false}
              />
            </div>

            <div className='col-xs-12 col-sm-4 center-xs'>
              <PartnerLogo />
            </div>
          </div>
        </section>
        <section className={`pane thin-pane ${window.coco ? 'coco-light-grey' : 'darkestgrey-pane'}`}>{actionPane()}</section>
        <Video />
        <section className={`pane fat-pane center-xs ${window.coco ? 'clear-pane' : 'lightestgrey-pane'}`}>
          <div className='row content-cage'>
            <div className='col-xs-12'>
              <h2 className='header-32 black-text fat-bottom'>{t('convos.aboutHeader')}</h2>
              <div className='header-20 black-text fat-bottom align-left'>
                <WrappedAboutSection />
              </div>
            </div>
          </div>
        </section>
        {topic.showHowItWorks && (
          <section className='pane clear-pane xtra-fat-pane fat-pane-xs'>
            <div className='row center-xs'>
              <div className='col-xs-12'>
                <div className='box'>
                  <h2 className='header-32 header-24-sm fat-bottom'>{t('convos.howHeader')}</h2>
                </div>
              </div>
              <div className='col-xs-6 col-sm-2'>
                <img className='img-responsive' src='https://s3.amazonaws.com/civicdinners-manual-storage/how-it-works-1-bw.png' alt={'how it works icon 1'} />
                <p className='header-24 header-18-sm center-xs'>{t('convos.hostHeader')}</p>
              </div>
              <div className='col-xs-6 col-sm-2'>
                <img className='img-responsive' src='https://s3.amazonaws.com/civicdinners-manual-storage/how-it-works-2-bw.png' alt={'how it works icon 2'} />
                <p className='header-22 header-16-xs center-xs'>{t('convos.guestHeader')}</p>
              </div>
              <div className='col-xs-6 col-sm-2'>
                <img className='img-responsive' src='https://s3.amazonaws.com/civicdinners-manual-storage/how-it-works-3-bw.png' alt={'how it works icon 3'} />
                <p className='header-24 header-18-sm center-xs'>{t('convos.questionHeader')}</p>
              </div>
              <div className='col-xs-6 col-sm-2'>
                <img className='img-responsive' src='https://s3.amazonaws.com/civicdinners-manual-storage/how-it-works-4-bw.png' alt={'how it works icon 4'} />
                <p className='header-24 header-18-sm center-xs'>{t('convos.timeHeader')}</p>
              </div>
              <div className='col-xs-6 col-sm-2'>
                <img className='img-responsive' src='https://s3.amazonaws.com/civicdinners-manual-storage/how-it-works-5-bw.png' alt={'how it works icon 5'} />
                <p className='header-24 header-18-sm center-xs'>{t('convos.voiceHeader')}</p>
              </div>
            </div>

            <div className='row center-xs reg-bottom'>
              <div className='col-xs-12'>
                <Button onClick={this.handleReadMore()} variant='text'>
                  {t('common.learnMore')}
                </Button>
              </div>
            </div>

            {this.state.showProcessDescription && <ProcessDescription />}
          </section>
        )}
        {
          <div>
            <span id='conversations' />
            {topic.featuredConversations && (
              <InfiniteLocationList
                limit={9}
                initQuery={(args) => {
                  if (topic.hasMoreFeaturedConversations) {
                    topic.loadFeaturedConversations({ topic_featured: true, ...args })
                  }
                }}
                query={(args) => {
                  if (topic.hasMoreFeaturedConversations) {
                    topic.loadFeaturedConversations({ topic_featured: true, ...args }, { add: true })
                  }
                }}
                slug={slug}
                formName={'ConversationForm'}
                itemType='conversation'
                hideOnEmpty={true}
                header={topic.featuredConversationsTitle}
                itemLabel={window.coco ? t('common.topic') : t('common.conversation')}
                items={topic.featuredConversationsList}
                hasMoreItems={topic.hasMoreFeaturedConversations}
                itemsLoading={topic.loadingIds.includes('featuredConversationsList')}
                featured
              />
            )}
            <InfiniteLocationList
              limit={9}
              initQuery={(args) => {
                if (topic.hasMoreConversations) {
                  topic.loadConversations({ ...(topic.featuredConversations ? { topic_featured: false } : {}), ...args })
                }
              }}
              query={(args) => {
                if (topic.hasMoreConversations) {
                  topic.loadConversations({ ...(topic.featuredConversations ? { topic_featured: false } : {}), ...args }, { add: true })
                }
              }}
              slug={slug}
              formName={'ConversationForm'}
              itemType='conversation'
              header={window.coco ? t('user.upcomingTopics') : t('user.upcomingConversations')}
              itemLabel={window.coco ? t('common.topic') : t('common.conversation')}
              items={topic.conversations}
              hasMoreItems={topic.hasMoreConversations}
              itemsLoading={topic.loadingIds.includes('conversations')}
            />
          </div>
        }
        <div ref={(r) => (this.storySection = r)}>
          <Stories />
        </div>
        {!topic.privateHosting && (
          <>
            <section className={`pane thin-pane ${window.coco ? 'coco-medium-grey white-text' : 'darkestgrey-pane'}`}>
              <div className='row center-xs'>
                <h2 className='header-32 flat-bottom uppercase'>{t('convos.becomeHost')}</h2>
              </div>
            </section>
            <BecomeAHostPane
              newConversationPath={newConversationPath}
              theme={theme}
              active={active}
              eventTypeSingular={eventTypeSingular}
              hostConversationButton={hostConversationButton}
              locale={locale}
            />
          </>
        )}
        <Sections sectionable={topic} />
      </div>
    )
  }
}

const DescriptionSection = withTranslation(function DescriptionSection({ text, showFullDescription, toggleView, length, readmore, t }: FIXME) {
  if (text === null) return null

  const content = showFullDescription ? formatTextArea(text) : ellipsis({ text, length })

  const readMoreToggle =
    text.length > length ? (
      // eslint-disable-next-line
      <a style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={toggleView}>
        {showFullDescription ? '' : t('common.readMore')}
      </a>
    ) : null

  return (
    <div className='hero-description'>
      {content && (
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(content[0].props.children)
          }}
        />
      )}
      {readmore ? readMoreToggle : null}
    </div>
  )
})

export default flow(
  connectTopicTheme,
  withRouter,
  withTranslation,
  observer,
  inject<FIXME, FIXME>(
    (
      {
        mst: {
          auth: { user, loaded, setRedirectOnSuccess },
          topics,
          dialogs: { showDialog },
          stories
        }
      }: {
        mst: IStore
      },
      {
        match: {
          params: { slug: rawSlug }
        }
      }
    ) => {
      const slug = rawSlug.toLowerCase()
      const topic = topics.list.find((d) => d.slug.toLowerCase() === slug)
      const topicLoading = topics.loadingIds.includes(slug)
      const topicLoaded = topics.loadedIds.includes(slug)
      if (!topic && !topicLoading && !topicLoaded) {
        topics.loadBySlug(slug)
      }
      const userInvitationRequestsIds = topic && user ? user.userInvitationRequests.map((req) => req.topicId) : []
      const subscribed = topic && user && user.likes?.find((f) => f.likableId === topic.id && f.likableType === 'Topic' && f.kind === 'subscribe')
      const showTopicSubscribeButton = !subscribed && topic && topic.subscriptionEnabled

      return {
        user,
        subscribed,
        setRedirectOnSuccess,
        loaded,
        showDialog,
        slug,
        topic,
        topicLoading,
        showTopicSubscribeButton,
        conversations: topic && topic.conversations,
        featuredConversationsList: topic && topic.featuredConversationsList,
        conversationCount: topic && topic.conversations.length,
        featuredConversationCount: topic && topic.featuredConversationsList.length
      }
    }
  )
)(TopicPage)
