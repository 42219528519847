import React, { Component } from 'react'
import withTranslation from '../../hocs/withTranslation'

class BecomeAHostPane extends Component {
  render() {
    const { theme, hostConversationButton, active, eventTypeSingular, t, locale } = this.props

    const hostButton = () => {
      if (active) {
        return (
          <div className='row center-xs flat-bottom'>
            <div className='col-xs-12 col-sm-4'>{hostConversationButton()}</div>
          </div>
        )
      }
    }

    const CreateEvent = () => {
      const fontSize = locale === 'sv_SE' ? 'header-22' : 'header-24'

      return (
        <div className='col-xs-6 col-sm-2 reg-bottom-xs'>
          <div className='max-60 center-margin'>
            <img className='img-responsive' src='https://s3.amazonaws.com/civicdinners-manual-storage/become_host_upload.png' alt={t('convos.cloudIcon')} />
          </div>
          <p className={`thin-bottom ${fontSize} header-18-sm center-xs uppercase bold`}>{t('convos.postEvent', { eventTypeSingular })}</p>
          <p className='header-18 header-16-xs center-xs'>{t('convos.createEvent', { eventTypeSingular })}</p>
        </div>
      )
    }

    return (
      <section
        className='pane fat-pane fat-pane-xs'
        style={{
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText
        }}
      >
        <div className='row center-xs fat-bottom'>
          <div className='col-xs-6 col-sm-2 reg-bottom-xs'>
            <div className='max-60 center-margin'>
              <img className='img-responsive' src='https://s3.amazonaws.com/civicdinners-manual-storage/become_host_location.png' alt={t('convos.locationIcon')} />
            </div>
            <p className='thin-bottom header-24 header-18-sm center-xs uppercase bold'>{t('convos.selectLocation')}</p>
            <p className='header-18 header-16-xs center-xs'>{t('convos.hostConvo')}</p>
          </div>
          <div className='col-xs-6 col-sm-2 reg-bottom-xs'>
            <div className='max-60 center-margin'>
              <img className='img-responsive' src='https://s3.amazonaws.com/civicdinners-manual-storage/become_host_calendar.png' alt={t('convos.calendarIcon')} />
            </div>
            <p className='thin-bottom header-24 header-18-sm center-xs uppercase bold'>{t('convos.chooseDate')}</p>
            <p className='header-18 header-16-xs center-xs'>{t('convos.pickDateTime')}</p>
          </div>

          <CreateEvent />

          <div className='col-xs-6 col-sm-2 reg-bottom-xs'>
            <div className='max-60 center-margin'>
              <img className='img-responsive' src='https://s3.amazonaws.com/civicdinners-manual-storage/social-media-icons.png' alt={t('convos.socialIcon')} />
            </div>
            <p className='thin-bottom header-24 header-18-sm center-xs uppercase bold'>{t('convos.inviteNetwork')}</p>
            <p className='header-18 header-16-xs center-xs'>{t('convos.setGuestLimit')}</p>
          </div>
          <div className='col-xs-6 col-sm-2 reg-bottom-xs'>
            <div className='max-60 center-margin'>
              <img className='img-responsive' src='https://s3.amazonaws.com/civicdinners-manual-storage/chat-bubble.png' alt={t('convos.chatIcon')} />
            </div>
            <p className='thin-bottom header-24 header-18-sm center-xs uppercase bold'>{t('convos.hostInvite', { eventTypeSingular })}</p>
            <p className='header-18 header-16-xs center-xs'>{t('convos.followHost')}</p>
          </div>
        </div>

        {hostButton()}
      </section>
    )
  }
}

export default withTranslation(BecomeAHostPane)
