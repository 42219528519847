import queryString from 'query-string'
import appAnalytics from '../analytics'
import { toFormData } from './FormData'
import { get, put, post, deleteApi } from './utils'

class OrganizationsApi {
  static getAll(args) {
    const defaultParams = { page: 0, site_name: global.siteName }
    const params = queryString.stringify({ ...defaultParams, ...args })

    return get(`/admin/organizations?${params}`)
  }

  static get(id) {
    return get(`/organizations/${id}`)
  }

  static create(data) {
    return post(`/organizations`, {
      noContentTypeJson: true,
      body: toFormData('organization', data)
    }).then((d) => {
      appAnalytics.event({
        category: 'Organization',
        action: 'Organization Created'
      })
      return d
    })
  }

  static update({ id, ...data }) {
    return put(`/organizations/${id}`, {
      noContentTypeJson: true,
      body: toFormData('organization', data)
    }).then((d) => {
      return d
    })
  }
}

export default OrganizationsApi
