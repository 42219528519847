import { types, Instance } from 'mobx-state-tree'
import { MetaImage } from '../base'
import { Alertable, LoadableStatus } from '../helpers'
import { RootAccessable } from '../helpers/RootAccessable'

export const UserBase = types
  .compose(
    'UserBase',
    RootAccessable,
    LoadableStatus,
    Alertable,
    types.model({
      id: types.identifierNumber,
      email: types.maybe(types.string),
      placeId: types.maybeNull(types.string),
      bio: types.maybeNull(types.string),
      city: types.maybeNull(types.string),
      phoneNumber: types.maybeNull(types.string),
      companyName: types.maybeNull(types.string),
      domain: types.maybeNull(types.string),
      facebookLink: types.maybeNull(types.string),
      favoriteFood: types.maybeNull(types.string),
      favoriteRestaurant: types.maybeNull(types.string),
      firstName: types.maybeNull(types.string),
      hasProfileImage: types.maybeNull(types.boolean),
      instagramLink: types.maybeNull(types.string),
      invitedUserCount: types.optional(types.number, 0),
      lastName: types.maybeNull(types.string),
      linkedinLink: types.maybeNull(types.string),
      magicWand: types.maybeNull(types.string),
      profileImage: types.maybe(MetaImage),
      stateLevel: types.maybeNull(types.string),
      title: types.maybeNull(types.string),
      twitterLink: types.maybeNull(types.string),
      websiteLink: types.maybeNull(types.string),
      country: types.maybeNull(types.string),
      role: types.maybeNull(types.string),
      pronoun: types.maybeNull(types.string),
      public: types.maybeNull(types.boolean),
      createdConversationsCount: types.maybeNull(types.number),
      registeredConversationsCount: types.maybeNull(types.number)
    })
  )

  .views((self) => ({
    get nameInitial() {
      return `${self?.firstName && self.firstName[0]}${self?.lastName && self.lastName[0]}`
    },
    get fullName() {
      return `${self.firstName} ${self.lastName}`
    },
    get location() {
      if (self.city && self.stateLevel) {
        return `${self.city}, ${self.stateLevel}`
      }
      if (!self.city && self.stateLevel) {
        return self.stateLevel
      }
      if (self.city && !self.stateLevel) {
        return self.city
      }
      return ''
    }
  }))
  .actions((self) => ({
    initials: () => {
      return `${self.firstName?.charAt(0) || ''} ${self.lastName?.charAt(0) || ''}`
    }
  }))

export interface IUserBase extends Instance<typeof UserBase> {}
