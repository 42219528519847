import { types, Instance } from 'mobx-state-tree'

export const GraphData = types.model('GraphData', {
  x: types.maybe(types.union(types.string, types.number)),
  y: types.maybe(types.union(types.string, types.number))
})

export const LineGraphData = types.model('LineGraphData', {
  id: types.maybe(types.string),
  data: types.maybe(types.array(GraphData))
})

export interface IGraphData extends Instance<typeof GraphData> {}
export default GraphData
