import * as types from '../actions/actionTypes'

export const currentUser = (state = null, action) => {
  switch (action.type) {
    case types.LOAD_CURRENT_USER_SUCCESS:
      if (!action.user.email.includes('@civicdinners.com')) {
        window.setupFullstory && window.setupFullstory()
      }
      return action.user.id

    case types.REMOVE_CURRENT_USER_SUCCESS:
      return null

    default:
      return state
  }
}

export const isCurrentUserLoading = (state = false, action) => {
  switch (action.type) {
    case types.LOAD_CURRENT_USER_START:
      return true

    case types.LOAD_CURRENT_USER_SUCCESS:
      return false

    default:
      return state
  }
}

export default currentUser
