import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import { observer } from 'mobx-react'
import { useForm } from 'react-hook-form'
import { getSnapshot, isStateTreeNode } from 'mobx-state-tree'
import { times } from 'lodash'

import { ITopic, ITopicAction } from '../../store'
import * as validations from '../validations/fieldLevelValidations'
import { SelectControl, TextFieldControl } from '../utils'
import { createStyles, Grid, makeStyles, Paper, Theme, Typography } from '@material-ui/core'

interface Props {
  topic: ITopic
  action?: ITopicAction
  index?: number
  closeForm?()
  onDirty?(val: boolean)
}

const ActionForm = (props: Props) => {
  const { handleSubmit, errors, formState, control, reset } = useForm({ mode: 'onBlur' })
  const { isDirty, isSubmitting } = formState
  const { action, closeForm: closeFormParent, topic, onDirty } = props
  const classes = useStyles()
  const [submitting, setsubmitting] = useState(false)

  useEffect(() => {
    action ? action.setDirty(isDirty) : onDirty && onDirty(isDirty)
  }, [action, isDirty, onDirty])

  const closeForm = () => {
    action ? action.setDirty(false) : onDirty && onDirty(false)
    closeFormParent && closeFormParent()
  }
  const handleSave = handleSubmit((data: any, e) => {
    setsubmitting(true)
    const updateData = { ...(isStateTreeNode(action) ? getSnapshot(action) : action || {}), ...data }

    const afterCreate = () => {
      reset(undefined, {
        errors: false, // errors will not be reset
        dirtyFields: false, // dirtyFields will not be reset
        isDirty: false, // dirty will not be reset
        isSubmitted: true,
        touched: false,
        isValid: true,
        submitCount: true
      })
      closeForm && closeForm()
    }
    ;(action ? action.updateAction : topic.createAction)(updateData)
      .then(afterCreate)
      .then(() => setsubmitting(false))
  })

  const actionCount: number = topic?.topicActions.length

  return (
    <Grid container alignItems='center' spacing={1} className={classes.root}>
      <Grid item xs={11}>
        <Paper className={classes.lightGrayBorder} elevation={0}>
          <Typography variant='subtitle1'>Title and Description</Typography>
          <Typography variant='body2' className='fat-bottom'>
            Be clear and specific about what you want people to do and why.
          </Typography>
          <form onSubmit={handleSave}>
            <div className='row bottom-xs no-gutters'>
              <div className='col-xs-12 no-gutters'>
                <TextFieldControl
                  name='title'
                  control={control}
                  defaultValue={action?.title?.toString() || ''}
                  error={errors && errors.title}
                  label={'Title'}
                  placeholder='Ex. End Food Insecurity'
                  required
                  rules={{ validate: validations.required }}
                  fullWidth={true}
                  className='reg-bottom'
                  helperText='Clear, concise and specific action title'
                />
              </div>
            </div>
            <div className='row bottom-xs no-gutters fat-bottom'>
              <div className='col-xs-12 no-gutters'>
                <TextFieldControl
                  name='description'
                  control={control}
                  defaultValue={action?.description?.toString() || ''}
                  error={errors && errors.description}
                  label={'Description'}
                  helperText='Short description of the action you’d like people to take.'
                  fullWidth={true}
                  multiline={true}
                />
              </div>
            </div>

            <Typography variant='subtitle1' className='reg-top'>
              Button
            </Typography>
            <Typography variant='body2' className='fat-bottom'>
              Include an optional button that links to a web page. Specify the text of the button label and enter a link. We recommend the button text should be four words or less
              with a compelling call to action, e.g. “Register as a Mentor” or “Take the Survey”
            </Typography>

            <div className='row bottom-xs no-gutters'>
              <div className='col-xs-12 col-sm-4 no-right-gutter' style={{ paddingLeft: 0 }}>
                <TextFieldControl
                  name='resourceText'
                  control={control}
                  defaultValue={action?.resourceText?.toString() || ''}
                  error={errors && errors.resourceText}
                  label={'Text'}
                  // required
                  // rules={{ validate: validations.required }}
                  placeholder='Ex. Find A Food Bank'
                  className='reg-bottom'
                />
              </div>
            </div>
            <div className='row bottom-xs no-gutters'>
              <div className='col-xs-12 col-sm-12 no-left-gutter'>
                <TextFieldControl
                  name='resourceLink'
                  control={control}
                  defaultValue={action?.resourceLink?.toString() || ''}
                  error={errors && errors.resourceLink}
                  label={'Link'}
                  // required
                  rules={{ validate: { http: validations.http } }}
                  // rules={{ validate: { http: validations.http, req: validations.required } }}
                  placeholder='URL link'
                />
              </div>
            </div>
            <Typography variant='subtitle1' className='reg-bottom fat-top'>
              List Position
            </Typography>

            <div className='row bottom-xs no-gutters'>
              <div className='col-xs-4 no-gutters'>
                <SelectControl
                  name='rank'
                  label='Order in List'
                  control={control}
                  defaultValue={action?.rank || props.index}
                  error={errors && errors.rank}
                  required
                  rules={{ validate: validations.required }}
                  options={times(action ? actionCount : actionCount + 1, (n) => ({ value: n + 1, label: n + 1 }))}
                />
              </div>
            </div>

            <div className='row bottom-xs no-gutters xtra-fat-top reg-bottom'>
              <div className='col-xs-9 no-gutters'>
                <Button onClick={closeForm} className='reg-right' variant='contained'>
                  Close
                </Button>
                <Button disabled={!isDirty || isSubmitting || submitting} color='primary' type='submit' className='reg-right' variant='contained'>
                  {action ? 'Update' : 'Create'}
                </Button>
              </div>
              <div className='col-xs-3 no-gutters' style={{ textAlign: 'right' }}>
                {action && (
                  <Button
                    classes={{ root: classes.delete }}
                    color='primary'
                    onClick={(e) => {
                      action.deleteAction()
                    }}
                  >
                    Delete
                  </Button>
                )}
              </div>
            </div>
          </form>
        </Paper>
      </Grid>
      <Grid item xs={1}></Grid>
    </Grid>
  )
}

export default observer(ActionForm)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { marginTop: '1em' },
    delete: {
      color: '#E51814'
    },
    lightGrayBorder: {
      background: '#F6F7F9',
      border: '1px solid #CDCFD2',
      boxSizing: 'border-box',
      borderRadius: '4px',
      padding: '1.5em'
    }
  })
)
