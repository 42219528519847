import React from 'react'
import { flow } from 'lodash'

import { connectTopicTheme } from '../../connectTheme'
import withTranslation from '../hocs/withTranslation'
import Dialog from '../DialogWrapper'
import { inject, observer } from 'mobx-react'
import { IStore } from '../../store/models'
import { useForm } from 'react-hook-form'
import { TextFieldControl } from '../utils'
import PageLoadingAnimation from '../shared/PageLoadingAnimation'
import { withRouter } from 'react-router'

const DeleteConversationDialog = ({ dialog, conversation, t, history }) => {
  const { handleSubmit, control } = useForm()
  if (!conversation) {
    return <PageLoadingAnimation />
  }

  const handleSave = handleSubmit(({ destroyMessage }) => {
    conversation.deleteConversation({ destroyMessage, confirm: false }).then(() => history.push(`/${conversation.slug}`))
  })

  const { title, venueName, hasGuests, dateLocally, startsAtLocally, endsAtLocally, eventTypeSingular } = conversation

  return (
    <Dialog dialog={dialog} name='deleteConversation'>
      <div className='align-center'>
        <h2 className='fat-bottom'>{t('conversation.deletePrompt', { eventType: eventTypeSingular })}</h2>

        <div className='header-20'>
          <p>{title}</p>
          <p>{dateLocally}</p>
          <p>
            {startsAtLocally} - {endsAtLocally}
          </p>
          <p>{venueName}</p>
        </div>

        <form onSubmit={handleSave}>
          {hasGuests ? (
            <div className='row reg-bottom'>
              <div className='col-xs-12 align-left'>
                <TextFieldControl name='destroyMessage' label={t('conversation.deleteHint')} multiline={true} control={control} />
              </div>
            </div>
          ) : null}

          <div className='row center-xs xtra-fat-top reg-bottom'>
            <div className='col-xs-12'>
              <a className='underline fat-bottom' onClick={handleSave}>
                {t('conversation.cancelLabel', { eventType: eventTypeSingular })}
              </a>
            </div>
          </div>

          <div className='row center-xs reg-bottom'>
            <div className='col-xs-12'>
              <a className='underline fat-bottom' onClick={() => dialog.closeDialog()}>
                {t('conversation.cancelDelete')}
              </a>
            </div>
          </div>
        </form>
      </div>
    </Dialog>
  )
}

export default flow(
  observer,
  inject<FIXME, FIXME>(
    (
      {
        mst: {
          conversations,
          dialogs: { showDialog }
        }
      }: { mst: IStore },
      {
        dialog: {
          data: { conversationId }
        }
      }
    ) => {
      const conversation = conversations.list.find((d) => d.id === conversationId)

      return {
        conversation,
        showDialog
      }
    }
  ),

  connectTopicTheme,
  withRouter,
  withTranslation
)(DeleteConversationDialog)
