import React, { Component, useEffect, useState } from 'react'
// import { flow, has, omit, get, isEmpty, isUndefined } from 'lodash'
// import queryString from 'query-string'
// import { connectTopicTheme } from '../../../connectTheme'
// import PageLoadingAnimation from '../../shared/PageLoadingAnimation'
// import withTranslation from '../../hocs/withTranslation'
// import CustomMetaTags from '../../shared/CustomMetaTags'
// import { withRouter } from 'react-router'
// import Sections from '../../sections/Sections'
import { inject, observer } from 'mobx-react'
// import { IConversation, IStore } from '../../../store/models'
// import HeroPane from './HeroPane'
// import InfoPane from './InfoPane'
// import CallToAction from './CallToAction'
// import AboutPane from './AboutPane'
// import ActionPaneSwitcher from './ActionPaneSwitcher'
// import GuestList from './GuestList'
// import WhatToExpectPane from './WhatToExpectPane'
// import FaqPane from './FaqPane'
// import MapPane from './MapPane'
// import { PartnerLogoPane, SchedulePane, DescriptionPane } from './BasicPanes'
// import SharePane from './SharePane'
// import { ConversationContext } from '../../Providers/ConversationProvider'
// import { NpsHandler } from '../../shared'
// import { Stories } from '../../topics/Show/sections/Stories'
// import moment from 'moment-timezone'
import { AppBar, Button, Container, createStyles, Grid, makeStyles, Theme, Toolbar, Typography, useScrollTrigger } from '@material-ui/core'
import { useAuth, useParamConversation } from '../../../Providers'
import { useTranslation } from 'react-i18next'
import { capitalize } from '../../../../decorators/textTools'
import appAnalytics from '../../../../analytics'
import ShareDropdown from '../../partials/ShareDropdown'
// import { capitalize } from '../../../../decorators/textTools'

const ConversationActionsBar = (props) => {
  const classes = useStyles()
  const { conversation, isGuest, isHost } = useParamConversation()
  const { user } = useAuth()

  if (!conversation || conversation.expired) return <></>

  user?.shouldLoad('userInvitationRequests', user.loadUserInvitationRequests)

  return (
    <>
      <AppBar position='fixed' className={classes.appBar} style={{ backgroundColor: '#F6F7F9' }}>
        <Toolbar className={classes.toolBar}>
          <div className={classes.container}>
            {!isGuest && !isHost && <NotAttending />}
            {isGuest && <GuestActions />}
            {isHost && <HostActions />}
          </div>
        </Toolbar>
      </AppBar>
    </>
  )
}

export default observer(ConversationActionsBar)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      top: 'auto',
      bottom: 0,
      backgroundColor: '#C4E6D8',
      color: '#212121',
      zIndex: 1300
    },
    toolBar: {
      // alignSelf: 'center'
    },
    actionLink: {
      color: '#EE413E'
      // marginLeft: '0.4rem'
    },
    icon: {
      color: '#388465'
      // marginRight: '0.4rem'
    },
    container: {
      margin: '.5rem auto .5rem auto',
      // minWidth: '50rem',
      width: '100%',
      maxWidth: '50rem',
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        justify: 'center'
      }
    },
    text: {
      // textAlign: 'center',
      // whiteSpace: 'nowrap'
      // maxWidth: '20rem'
    },
    link: {
      color: '#EE413E',
      marginLeft: '.2rem',
      [theme.breakpoints.down('xs')]: {
        marginLeft: '0',
        textAlign: 'center'
      }
    },
    primaryButton: {
      backgroundColor: '#F7BF14',
      ['&:hover']: {
        backgroundColor: '#F7BF14'
      }
    },
    desktop: {
      [theme.breakpoints.down('xs')]: { display: 'none' }
    },
    mobile: {
      [theme.breakpoints.up('sm')]: { display: 'none' }
    }
  })
)

const NotAttending = observer(() => {
  const { user } = useAuth()
  const { t } = useTranslation()
  const classes = useStyles()
  const { conversation } = useParamConversation()
  const invitationRequest = conversation && user && user.userInvitationRequests.find((i) => i.conversationId === conversation.id)

  return (
    <>
      {conversation && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            {conversation.full || conversation.registrationClosed ? (
              t('conversation.eventIsFull', { event: conversation.isVirtual ? conversation.virtualEventTypeSingular : conversation.eventTypeSingular })
            ) : conversation?.approvalOnly ? (
              <Button
                disabled={!!invitationRequest}
                variant='contained'
                classes={{ contained: classes.primaryButton }}
                fullWidth
                size='large'
                onClick={() => {
                  conversation.requestInvite()
                }}
              >
                {invitationRequest ? t('invitation.pending') : t('common.reqInvite')}
              </Button>
            ) : (
              <Button
                variant='contained'
                classes={{ root: classes.primaryButton }}
                fullWidth
                size='large'
                onClick={() => {
                  conversation.attend()
                }}
              >
                <span className={classes.desktop}>
                  {t('conversation.attendEventLabel', {
                    event: conversation.isVirtual ? capitalize(conversation.virtualEventTypeSingular) : capitalize(conversation.eventTypeSingular)
                  })}
                </span>
                <span className={classes.mobile}>Attend</span>
              </Button>
            )}
          </Grid>
          {conversation.inviteFriends && (
            <Grid item xs={12} sm={6}>
              <ShareDropdown
                hideMoreActionButtons={true}
                conversation={conversation}
                trigger={
                  <Button variant='outlined' fullWidth size='large'>
                    Share
                  </Button>
                }
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
              />
            </Grid>
          )}
        </Grid>
      )}
    </>
  )
})

const GuestActions = observer(() => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { conversation } = useParamConversation()

  return (
    <>
      {conversation && conversation.inviteFriends ? (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Button variant='contained' classes={{ contained: classes.primaryButton }} fullWidth size='large' onClick={() => conversation.invite()}>
              {t('common.inviteFriends')}
            </Button>
          </Grid>

          <Grid item xs={12} sm={6}>
            <ShareDropdown
              hideMoreActionButtons={true}
              conversation={conversation}
              trigger={
                <Button variant='outlined' fullWidth size='large'>
                  Share
                </Button>
              }
              anchorOrigin={anchorOrigin}
              transformOrigin={transformOrigin}
            />
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </>
  )
})

const HostActions = observer(() => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { conversation } = useParamConversation()
  return (
    <>
      {conversation && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            {conversation.full || conversation.registrationClosed ? (
              <Typography>
                {t('conversation.eventIsFull', {
                  event: conversation.isVirtual ? conversation.virtualEventTypeSingular : conversation.eventTypeSingular
                })}
              </Typography>
            ) : (
              <Button
                variant='contained'
                classes={{ contained: classes.primaryButton }}
                fullWidth
                size='large'
                onClick={() => {
                  conversation.invite()
                }}
              >
                <span className={classes.desktop}>{t('common.inviteFriends')}</span>
                <span className={classes.mobile}>Invite</span>
              </Button>
            )}
          </Grid>
          <Grid item xs={12} sm={4}>
            <ShareDropdown
              hideMoreActionButtons={true}
              conversation={conversation}
              trigger={
                <Button variant='outlined' fullWidth size='large'>
                  Share
                </Button>
              }
              anchorOrigin={anchorOrigin}
              transformOrigin={transformOrigin}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              variant='outlined'
              fullWidth
              size='large'
              onClick={() => {
                appAnalytics.event({
                  category: 'Conversation',
                  action: 'Download Host Guide'
                })
                conversation.hostGuideUrl && window.open(conversation.hostGuideUrl.replace(/dl=0/gi, 'dl=1'))
              }}
            >
              <span className={classes.desktop}>Download Host Guide</span>
              <span className={classes.mobile}>Guide</span>
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  )
})
const anchorOrigin = {
  vertical: 'top',
  horizontal: 'center'
}
const transformOrigin = {
  vertical: 'bottom',
  horizontal: 'center'
}
