import React, { useContext, useEffect, useState } from 'react'
import queryString from 'query-string'
import { withRouter, useLocation, useHistory } from 'react-router'
import { DialogContext } from '../Providers/DialogProvider'
import { flow } from 'lodash'
import { observer } from 'mobx-react'
import { inject } from 'mobx-react'
import { IStore } from '../../store'
import { INps } from '../../store/models/Nps'
import { ParamPartnerContext } from '../Providers/ParamPartnerProvider'
import { useAuth, useParamConversation, useParamTopic } from '../Providers'
import moment from 'moment-timezone'
import { INpsAnswer } from '../../store/models/NpsAnswers'

interface InjectedProps {
  npsLoaded?: boolean
  npsAnswers?: INpsAnswer[]
}
export const NpsHandler = flow(
  observer,
  inject<InjectedProps, null>(
    ({
      mst: {
        auth: { user }
      }
    }: {
      mst: IStore
    }) => {
      user?.shouldLoad('npsAnswers', user?.loadNpsAnswers)
      return {
        npsLoaded: user?.loadedIds.includes('npsAnswers'),
        npsAnswers: user?.nps
      }
    }
  )
)(({ npsLoaded, npsAnswers }: InjectedProps) => {
  const [inProcess, setInProcess] = useState(false)
  const { showDialog } = useContext(DialogContext)
  const history = useHistory()
  const location = useLocation()
  const { topic } = useParamTopic()
  const { conversation } = useParamConversation()
  const { partner } = useContext(ParamPartnerContext)
  const { user, attendedConversationIds, userConversationIds } = useAuth()
  useEffect(() => {
    if (!inProcess && conversation && user) {
      const { nps_id, token, score } = queryString.parse(location.search)

      const showSurvey = (dialogName: string, nps_question_id: number) => {
        setInProcess(true)
        showDialog(dialogName, {
          nps_question_id,
          token,
          score: score ? score.toString() : undefined,
          conversation_id: conversation?.id,
          topic_id: topic?.id,
          attendeeSurveyLink: topic?.attendeeSurveyLink,
          partner_id: partner?.id,
          user_id: user?.id
        })
      }
      const shouldShowSurvey = (npsQuestionId: number) =>
        npsLoaded &&
        npsAnswers &&
        !npsAnswers?.find((n) => n.conversationId === conversation.id && n.npsQuestionId === npsQuestionId) &&
        (attendedConversationIds.includes(conversation.id) || (userConversationIds.includes(conversation.id) && moment(conversation.endsAt).isBefore(moment())))
      if ((nps_id && token) || shouldShowSurvey(1)) {
        showSurvey('nps', (nps_id as any) || 1)
        history.replace(location.pathname)
      } else if (shouldShowSurvey(2) || shouldShowSurvey(2) || shouldShowSurvey(3) || shouldShowSurvey(4) || shouldShowSurvey(5)) {
        showSurvey('nps2', 2)
      } else if (shouldShowSurvey(7) || shouldShowSurvey(8)) {
        showSurvey('nps3', 7)
      }
    }
  }, [npsLoaded, history, location, conversation, topic, partner, showDialog, user, setInProcess, inProcess, attendedConversationIds, npsAnswers, userConversationIds])

  return <></>
})
