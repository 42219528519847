import { Grid, IconButton, Tooltip } from '@material-ui/core'
import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import appAnalytics from '../../../../analytics'
import { IStore } from '../../../../store/models'
import { inject } from 'mobx-react'
import { observer } from 'mobx-react'
import { flow } from 'lodash'
import { IUser } from '../../../../store/models/User'
import { useAuth, useParamTopic } from '../../../Providers'
import { ITopicSubscriber } from '../../../../store/models/TopicSubscribers'

interface Props {
  subscriber: ITopicSubscriber
}
interface InjectedProps {
  user?: IUser
  loadFilter()
}

const TopicSubscribersActions = ({ subscriber, history, loadFilter }: Props & InjectedProps & RouteComponentProps) => {
  const { user: authUser } = useAuth()
  const { topic } = useParamTopic()
  return (
    <Grid container direction='row' justify='space-around' alignItems='center'>
      <Tooltip title='Unsubscribe'>
        <IconButton
          size='small'
          onClick={() => {
            topic?.topicUnsubscribe(subscriber.id).then(loadFilter)
          }}
        >
          <i className='fas fa-unlink opacity-8'></i>
        </IconButton>
      </Tooltip>
    </Grid>
  )
}

export default flow(
  observer,
  inject<InjectedProps, Props>(
    ({
      mst: {
        auth: { masquerade },
        topic_subscribers: { loadFilter }
      }
    }: {
      mst: IStore
    }) => {
      return {
        loadFilter
      }
    }
  ),
  withRouter
)(TopicSubscribersActions)
