import React, { useEffect } from 'react'
import { useForm, DeepMap, FieldError, FieldNamesMarkedBoolean, SetValueConfig, Control, ErrorOption } from 'react-hook-form'
import { flow } from 'lodash'
import { observer, inject } from 'mobx-react'
import { withRouter, RouteComponentProps, useHistory } from 'react-router'
import { StepButton, Stepper, Step, StepContent, Button, Typography } from '@material-ui/core'
import moment from 'moment'

import { ITopic } from '../../../store/models/Topic'
import { camelify, capitalize } from '../../../decorators/textTools'
import withTranslation, { WithTranslation } from '../../hocs/withTranslation'
import { IStore } from '../../../store/models'
import { HOW_TO_HOST_LINK, TextFieldControl } from '../../utils'

import RoleOfHostStep from './RoleOfHostStep'
import ConversationTypeStep from './ConversationTypeStep'
import ConversationLocationStep from './ConversationLocationStep'
import VirtualDetailsStep from './VirtualDetailsStep'
import DateStep from './DateStep'
import OtherConversationType from './OtherConversationType'
import GuestInstruction from './GuestInstruction'
import SuitableDiets from './SuitableDiets'
import { handleStartEndAt, http } from '../../validations/fieldLevelValidations'
import HomeConversationType from './HomeConversationType'
import { IUser } from '../../../store/models/User'
import TimeZoneApi from '../../../api/TimeZoneApi'
import LoadingAnimation from '../../shared/LoadingAnimation'
import { Link } from 'react-router-dom'
import { useParamConversation, useParamTopic } from '../../Providers'
import { useTranslation } from 'react-i18next'

interface Props {
  user: IUser
  topic: ITopic
  t: any
}

const NewStepper = ({ topic, t, history, changeLanguage, user }: Props & RouteComponentProps & WithTranslation) => {
  const { handleSubmit, errors, formState, control, watch, setValue, clearErrors, setError, trigger } = useForm({ mode: 'onBlur', shouldUnregister: false })

  const { dirtyFields } = formState

  const [activeStep, setActiveStep] = React.useState(0)
  const [showLoader, setshowLoader] = React.useState(false)
  const [guestInstructionsHeader, setGuestInstructionsHeader] = React.useState(t('conversation.guestInstructionsHeader'))
  const [guestInstructionsHint, setGuestInstructionsHint] = React.useState('')

  const venue_type = watch('venueType')
  const conversationType = watch('conversationType')
  const venueType = watch('venueType')
  const location = watch('location')
  const placeId = watch('placeId')
  const isVirtualConversation = watch('venueType') === undefined || watch('venueType') === 'virtual'
  const isRestaurantConversation = watch('venueType') === 'restaurant'
  const isHomeConversation = watch('venueType') === 'home'
  // const isPotluck = isHomeConversation && watch('conversationType') === 'potluck'

  const showSuitableDiets = !isRestaurantConversation && !isVirtualConversation

  const userFirstName = user?.firstName

  useEffect(() => {
    if (venue_type === 'home') {
      setGuestInstructionsHeader(t('conversation.guestInstructionsHeader'))
    }
    if (venue_type === 'other') {
      setGuestInstructionsHeader(t('conversation.additionalNotes'))
      setGuestInstructionsHint(t('conversation.guestInstructionsHint'))
    } else {
      setGuestInstructionsHint('')
    }
  }, [venue_type, setGuestInstructionsHeader, t])

  useEffect(() => {
    if (topic && topic.locale) {
      changeLanguage(topic.locale)
    }
  }, [topic, changeLanguage])

  useEffect(() => {
    if (conversationType === 'potluck') {
      setValue('guestInstructions', t('conversation.guestInstructions'), { shouldDirty: true })
    }
    if (['catered', 'main_course_provided'].includes(conversationType)) {
      setValue('guestInstructions', t('conversation.mainCourseInstructions'), { shouldDirty: true })
    }
    if (['fully_catered', 'full_conversation_provided'].includes(conversationType)) {
      setValue('guestInstructions', t('conversation.fullConversationInstructions'), { shouldDirty: true })
    }
    if (['no_food_provided'].includes(conversationType)) {
      setValue('guestInstructions', t('conversation.noFoodInstructions'), { shouldDirty: true })
    }
    if (['other'].includes(conversationType)) {
      setValue('guestInstructions', t('conversation.otherFoodInstructions'), { shouldDirty: true })
    }
  }, [conversationType, setValue, t])

  useEffect(() => {
    if (venueType === 'virtual') {
      setValue('venueName', '', { shouldDirty: true })
      setValue('virtualType', 'dailyco', { shouldDirty: true })
      setValue('guestInstructions', t('conversation.venueTypeGuestInstructions'), { shouldDirty: true })
    }
    if (venueType === 'restaurant') {
      setValue('venueName', '', { shouldDirty: true })
      setValue('virtualType', '', { shouldDirty: true })
      setValue('guestInstructions', t('conversation.restaurantVenueDesc'), { shouldDirty: true })
    }
    if (venueType === 'home') {
      setValue('venueName', t('conversation.homeVenueName', { firstName: userFirstName }), { shouldDirty: true })
      setValue('virtualType', '', { shouldDirty: true })
      setValue('guestInstructions', t('conversation.privateVenueDesc'), { shouldDirty: true })
    }
    if (venueType === 'other') {
      setValue('venueName', '', { shouldDirty: true })
      setValue('virtualType', '', { shouldDirty: true })
      setValue('guestInstructions', '', { shouldDirty: true })
    }
  }, [venueType, setValue, t, userFirstName])

  useEffect(() => {
    if (placeId) {
      TimeZoneApi.getByPlaceId(placeId).then(({ timeZoneId }) => {
        setValue('timezone', timeZoneId, { shouldDirty: true })
      })
    }
  }, [placeId, setValue])

  if (!topic) {
    return null
  }

  const locationStepLabel = () =>
    isVirtualConversation
      ? t('conversation.virtualStepLabel', {
          event: capitalize(topic.virtualEventTypeSingular)
        })
      : t('conversation.locationStepLabel')

  const getSteps = () => {
    return [
      t('conversation.roleOfHost'),
      t('conversation.whatType', { brand: topic.brandSingular }),
      t('conversation.eventLocation', {
        eventType: capitalize(topic.eventTypeSingular)
      }),
      locationStepLabel(),
      t('common.date'),
      ...(!isVirtualConversation ? ['Food'] : [])
      // ...(!isVirtualConversation && !isRestaurantConversation ? [guestInstructionsHeader] : []),
      // ...(showSuitableDiets ? [t('conversation.suitableType')] : [])
    ]
  }
  const steps = getSteps()

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleSave = handleSubmit(({ location: locationData, ...val }: any) => {
    const updateData = { ...camelify(locationData), ...val, ...{ topicFeatured: false, partnerFeatured: false }, ...handleStartEndAt(val) }
    setshowLoader(true)
    topic
      .createConversation(updateData)
      .then(({ data = undefined }: any = {}) => {
        const { id, slug } = data
        setshowLoader(false)
        history.push(`/${slug}/conversations/${id}`)
      })
      .catch(() => setshowLoader(false))
  })

  const validStep = (stepIndex: number) => {
    if (stepIndex === 3 && watch('virtualType') === 'custom' && watch('virtualLink') && http(watch('virtualLink'))) {
      return false
    }
    const found = [
      { index: 0, fieldNames: ['hostAgreement'] },
      { index: 1, fieldNames: [] },
      { index: 2, fieldNames: [] },
      {
        index: 3,
        fieldNames: [
          ...(isVirtualConversation ? ['location', 'maxAttendees'] : isHomeConversation ? ['address', 'venueName'] : ['location', 'venueName']),
          ...(watch('virtualType') === 'custom' ? ['virtualLink'] : []),
          ...(isRestaurantConversation ? ['venueAgreement'] : [])
        ]
      },
      { index: 4, fieldNames: ['startsAtDate', 'startsAt', 'endsAt'] },
      { index: 5, fieldNames: ['conversationType', 'guestInstructions'] }
      // { index: 6, fieldNames: ['guestInstructions'] },
      // { index: 7, fieldNames: [] }
    ].find(({ index, fieldNames }) => index === stepIndex && (!!fieldNames.find((fieldName) => !!watch(fieldName)) || fieldNames.length === 0))
    return found ? !found.fieldNames.find((fieldName) => !watch(fieldName) || !!errors[fieldName]?.message) : !!found
  }

  const getStepContent = (step: number) => {
    const stepProps = { topic, control, watch, errors, setValue, dirtyFields, clearErrors, setError, trigger }
    if (showLoader) {
      return <></>
    }

    switch (step) {
      case 0:
        return <RoleOfHostStep {...stepProps} />
      case 1:
        return <ConversationTypeStep {...stepProps} />
      case 2:
        return <ConversationLocationStep {...stepProps} />
      case 3:
        return <VirtualDetailsStep {...stepProps} />
      case 4:
        return <DateStep {...stepProps} />
      case 5:
        return (
          <>
            {isHomeConversation ? <HomeConversationType {...stepProps} /> : <OtherConversationType {...stepProps} />}
            <GuestInstruction {...{ ...stepProps, guestInstructionsHint }} control={control} />
            <SuitableDiets {...stepProps} />
          </>
        )
      // case 6:
      //   return <GuestInstruction {...{ ...stepProps, guestInstructionsHint }} control={control} />
      // case 7:
      //   return <SuitableDiets {...stepProps} />
      default:
        return 'Unknown step'
    }
  }

  const getStepAction = () => (
    <div>
      <div className='fat-top'>
        {showLoader ? (
          <LoadingAnimation />
        ) : (
          <>
            {activeStep === steps.length - 1 && (
              <Button type='submit' variant='contained' color={window.coco ? 'secondary' : 'primary'} disabled={!!steps.find((_, i) => !validStep(i))}>
                {t('common.finish')}
              </Button>
            )}
            {activeStep !== steps.length - 1 && (
              <Button variant='contained' color={window.coco ? 'secondary' : 'primary'} onClick={handleNext} disabled={!validStep(activeStep)}>
                {t('common.next')}
              </Button>
            )}
            {activeStep !== 0 && (
              <Button className={'reg-left'} disabled={activeStep === 0} onClick={handleBack}>
                {t('common.back')}
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  )

  return (
    <div className={`${topic.slug} conversations-new`}>
      <div className='row'>
        <div className='col-xs-12 form-centered form-lg'>
          <ConversationHeader />
          <form onSubmit={handleSave}>
            <div
              style={{
                border: '1px solid #fff',
                maxWidth: 880,
                margin: 'auto'
              }}
            >
              <div>
                <TextFieldControl name='city' control={control} type='hidden' className='hide' />
                <TextFieldControl name='placeId' control={control} type='hidden' className='hide' />
                <Stepper activeStep={activeStep} orientation='vertical' nonLinear={true}>
                  {steps.map((label, index) => {
                    return (
                      <Step key={label}>
                        <StepButton onClick={() => activeStep >= index && validStep(index) && setActiveStep(index)} completed={activeStep >= index && validStep(index)}>
                          {label}
                        </StepButton>
                        <StepContent>
                          {getStepContent(index)}
                          {getStepAction()}
                        </StepContent>
                      </Step>
                    )
                  })}
                </Stepper>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default flow(
  withRouter,
  withTranslation,
  observer,
  inject<FIXME, FIXME>(
    (
      {
        mst: {
          auth: { user },
          topics
        }
      }: {
        mst: IStore
      },
      {
        match: {
          params: { slug: slugRaw }
        }
      }
    ) => {
      const slug = slugRaw.toLowerCase()
      const topic = topics.list.find((d) => d.slug === slug)
      !topic && topics.shouldLoad(slug, () => topics.loadBySlug(slug))

      return {
        user,
        topic
      }
    }
  )
)(NewStepper)

type TFieldValues = FIXME

export interface StepProps {
  guestInstructionsHint?: string
  topic: ITopic
  control: Control<Record<string, any>>
  errors: DeepMap<Record<string, any>, FieldError>
  watch(names: string): any
  setValue(name: string, value: any, config?: SetValueConfig): void
  clearErrors(name?: string | string[]): void
  setError(name: string, error: ErrorOption): void
  dirtyFields: FieldNamesMarkedBoolean<TFieldValues>
  trigger: (name?: string | string[] | undefined) => Promise<boolean>
}

const ConversationHeader = () => {
  const { topic } = useParamTopic()
  const { t } = useTranslation()

  if (!topic) {
    return <></>
  }
  return (
    <div className='reg-top'>
      <Link to={`/${topic?.slug}`}>Go Back</Link>
      <Typography variant='h6' className='thin-top thin-bottom'>
        {t('conversation.hostEvent', {
          eventType: capitalize(topic.eventTypeSingular)
        })}{' '}
        on
      </Typography>
      <Typography variant='h3'>{topic?.title}</Typography>

      <p
        dangerouslySetInnerHTML={{
          __html: t('conversation.learnAboutHosting', {
            url: HOW_TO_HOST_LINK
          })
        }}
      />
    </div>
  )
}
