import { Button } from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router'
import Form from '../../../users/Edit'

type Props = {
  //
}

export default function Edit(props: Props) {
  const history = useHistory()
  return (
    <div>
      <Button onClick={() => (history.length > 2 ? history.goBack() : history.push('/admin/attendees'))}>Back</Button>
      <Form {...props} afterSave={history.goBack} />
    </div>
  )
}
