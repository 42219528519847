import { assign } from 'lodash'

import { LOADING_CONVERSATIONS_TABLE, LOAD_CONVERSATIONS_TABLE_SUCCESS, LOAD_CONVERSATIONS_TABLE_FAILED } from '../actions/actionTypes.js'
import initialState from './initialState'

export const topicsTable = (state = initialState.topicsTable, action) => {
  switch (action.type) {
    case LOADING_CONVERSATIONS_TABLE:
      return assign({}, state, {
        loadingTopicsTable: true,
        errorTopicsTable: null
      })

    case LOAD_CONVERSATIONS_TABLE_SUCCESS:
      return assign({}, state, {
        loadingTopicsTable: false,
        errorTopicsTable: null,
        tableData: assign({}, state.tableData, action.data)
      })

    case LOAD_CONVERSATIONS_TABLE_FAILED:
      return assign({}, state, {
        loadingTopicsTable: false,
        errorTopicsTable: action.data
      })

    default:
      return state
  }
}

export default topicsTable
