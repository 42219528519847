import { SubmissionError } from 'redux-form'
import { mapKeys } from 'lodash'
import i18n from 'i18next'

import * as types from './actionTypes'
import UsersApi from '../api/UsersApi'
import AuthApi from '../api/AuthApi'
import FacebookUsersApi from '../api/FacebookUsersApi'
import { currentUserSuccess, removeCurrentUserSuccess } from './currentUserActions'
import { removeUserInvitationRequests } from './ConversationInvitationRequestActions.js'
import { loadUserInvitationRequests } from './ConversationInvitationRequestActions.js'
import { showAlertMessage } from './alertMessageActions'
// import { closeDialog } from './dialogActions'
import { User } from '../getters/userGetters'
import { get } from 'lodash'
import { redeemInvitation } from './InvitationActions'
import { history } from '../components/browserHistory'
import mst from '../store/models'

export function loginSuccess(json) {
  return (dispatch) => {
    document.querySelector('body').classList.remove('fixed-body-for-ios') // handle iOS11 cursor jumping on input bug
    mst.dialogs.closeDialog('login')
    mst.dialogs.closeDialog('signup')
    // dispatch(closeDialog('login'))
    // dispatch(closeDialog('signup')) // NB: sometimes fb signup can generate a login
    dispatch({
      type: types.LOG_IN_SUCCESS,
      data: json
    })
  }
}

export function loginFailed(json) {
  return {
    type: types.LOG_IN_FAILED,
    data: json
  }
}

export function logoutSuccess() {
  return { type: types.LOG_OUT }
}

const handleLoginSuccess = (response) => {
  return (dispatch, getState) => {
    localStorage.setItem('jwt', response.auth_token)

    dispatch(loginSuccess(response))

    const user = camelize(response.user) // fb is snake_case

    handleInviteCode(user, dispatch)

    assignFullStoryId(user)

    dispatch(currentUserSuccess(user))

    mst.auth.reduxLogin(user)

    dispatch(loadUserInvitationRequests(user.id))

    // don't run success handler if sign-up two should open
    if (!user.phoneNumber || !user.placeId) return
    // TODO move this into a separate action that is also called by userActions.updateUser
    const onAuthSuccess = get(getState(), 'router.locationBeforeTransitions.state.authSuccess')
    if (onAuthSuccess) dispatch(onAuthSuccess(user.id))
  }
}

function camelize(object) {
  return mapKeys(object, camelizeKey)
}
function camelizeKey(value, key) {
  return key.replace(/_(\w)/g, (a, b) => b.toUpperCase())
}

const handleSignUpFailure = (response, dispatch, resetRecaptcha) => {
  dispatch(loginFailed(response))
  resetRecaptcha()
  throw new SubmissionError(response)
}

const handleLogInFailure = (response, dispatch) => {
  dispatch(loginFailed(response))
  dispatch(showAlertMessage({ message: response.error }))
}

export function logInUser(credentials, data) {
  return function (dispatch) {
    return AuthApi.login(credentials, data)
      .then((response) => {
        if (response.auth_token) {
          destroyInviteCode()

          dispatch(handleLoginSuccess(response))

          loggedInBootIntercom(response.user)

          dispatch(showAlertMessage(response))

          if (data.modal === false) history.push(`/`)
        } else {
          handleLogInFailure(response, dispatch)
        }
      })
      .catch((error) => {
        throw error
      })
  }
}

export function verifyUniqueEmail(formData) {
  return UsersApi.verifyUniqueEmail(formData)
    .then((res) => {
      if (res.response.ok) {
        return
      } else {
        // eslint-disable-next-line
        throw { email: [i18n.t('auth.emailTaken')] }
      }
    })
    .catch((error) => {
      throw error
    })
}
export function verifySsoEmail(formData) {
  return UsersApi.verifySsoEmail(formData)
    .then((res) => {
      if (res.response.ok) {
        return res.json
      }
      //  else {
      //   // eslint-disable-next-line
      //   throw { email: [i18n.t('auth.ssoAccount')] }
      // }
    })
    .catch((error) => {
      throw error
    })
}

export function loginFacebookUser(responseData, data) {
  return function (dispatch) {
    return FacebookUsersApi.create(responseData)
      .then((response) => {
        if (response.auth_token) {
          dispatch(handleLoginSuccess(response))

          if (response.action === 'login') {
            loggedInBootIntercom(camelize(response.user))
          } else if (response.action === 'signup') {
            signedUpBootIntercom(camelize(response.user))
          }

          dispatch(showAlertMessage(response))

          if (data && data.modal === false) history.push(`/`)
        } else {
          handleLogInFailure(response, dispatch)
        }
      })
      .catch((error) => {
        throw error
      })
  }
}

const handleSignupSuccess = (response, data) => {
  return (dispatch) => {
    exposeSignUpPixel()

    if (response.user.signedUpConversationId === 1322) exposeFBSignUpPixel() // For Facebook's Connected small business conversation

    dispatch(handleLoginSuccess(response))

    // redirect(data, dispatch)
    if (data.modal === false) history.push(`/`)
  }
}

export function SignUpUser(formData, data) {
  return function (dispatch) {
    return UsersApi.create(formData, data)
      .then((res) => {
        if (res.response.ok) {
          dispatch(handleSignupSuccess(res.json, data))

          signedUpBootIntercom(res.json.user)
        } else {
          handleSignUpFailure(res.json, dispatch, data.resetRecaptcha)
        }
      })
      .catch((error) => {
        throw error
      })
  }
}

export function signUpFacebookUser(responseData, data) {
  return function (dispatch) {
    return FacebookUsersApi.create(responseData)
      .then((response) => {
        if (response.auth_token) {
          console.log('RESPONSE: ', response)

          dispatch(handleSignupSuccess(response, data))

          if (response.action === 'login') {
            loggedInBootIntercom(camelize(response.user))
          } else if (response.action === 'signup') {
            signedUpBootIntercom(camelize(response.user))
          }

          dispatch(showAlertMessage(response))
        } else {
          handleLogInFailure(response, dispatch)
        }
      })
      .catch((error) => {
        throw error
      })
  }
}

export function logOutUser(message = null) {
  return function (dispatch) {
    if (localStorage.main_jwt) {
      localStorage.setItem('jwt', localStorage.main_jwt)
      localStorage.removeItem('main_jwt')
      localStorage.setItem('reload_message', message)
      window.location = '/'
    } else {
      localStorage.removeItem('jwt')
      mst.auth.reduxLogOutUser()
      dispatch(logoutSuccess())
      dispatch(removeCurrentUserSuccess())
      dispatch(removeUserInvitationRequests())
      message && dispatch(showAlertMessage({ message: message }))
    }
  }
}

export function assignFullStoryId(user) {
  if (process.env.NODE_ENV !== 'production') return

  user = User(user)
  const { role, host, guest, domain, stateLevel } = user
  window.fsIdentify(user.id, {
    email: user.email,
    displayName: user.fullName,
    role,
    host,
    guest,
    domain,
    stateLevel
  })
}

const intercomAppId = () => (global.coco ? process.env.REACT_APP_COCO_INTERCOM_APP_ID : process.env.REACT_APP_INTERCOM_APP_ID)

const intercomSite = () => (global.coco ? 'coco' : 'inclusivv')

export function loggedInBootIntercom(user) {
  if (process.env.NODE_ENV !== 'production') return

  user = User(user)

  if (user.domain === global.domain) {
    console.log('Boot Intercom sync user for', intercomSite())
    window.Intercom('boot', {
      app_id: intercomAppId(),
      user_id: user.id,
      email: user.email,
      api_base: 'https://api-iam.intercom.io'
    })
  } else {
    console.log('Boot Intercom only visitor for', intercomSite())
    window.Intercom('boot', {
      app_id: intercomAppId(),
      api_base: 'https://api-iam.intercom.io'
    })
  }
}

export function signedUpBootIntercom(user) {
  if (process.env.NODE_ENV !== 'production') return

  user = User(user)

  var createdAtInSeconds = Math.round(new Date(user.createdAt).getTime() / 1000)

  window.Intercom('boot', {
    app_id: intercomAppId(),
    user_id: user.id,
    created_at: createdAtInSeconds,
    name: user.fullName,
    email: user.email,
    phone: user.phoneNumber,
    site: global.siteName
  })
}

function exposeSignUpPixel() {
  if (process.env.NODE_ENV !== 'production') return
  window.fbq('trackSingle', '1904101133142530', 'CompleteRegistration')
}

function exposeFBSignUpPixel() {
  if (process.env.NODE_ENV !== 'production') return
  window.fbq('trackSingle', '1635900643345604', 'CompleteRegistration')
}

const destroyInviteCode = () => {
  if (!!localStorage.getItem('invite_code')) localStorage.removeItem('invite_code')
}

const handleInviteCode = (user, dispatch) => {
  var inviteCode = localStorage.getItem('invite_code')
  if (!!inviteCode) {
    dispatch(redeemInvitation({ userId: user.id, inviteCode }))
  }
  destroyInviteCode()
}
