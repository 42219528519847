import { Button, IconButton } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'

interface VideoPlayerProps {
  videoLink: string
  startAt?: number
  hideClose?: boolean
  autoPlay?: boolean
  onEnded?(progress: any): void
  onStop?(progress: any): void
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ autoPlay = true, hideClose = false, videoLink, onEnded, onStop, startAt }) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [isPlaying, setIsPlaying] = useState(false)

  // const current = videoRef?.current
  // const duration = current?.duration
  useEffect(() => {
    if (startAt && videoRef.current) {
      videoRef.current.currentTime = startAt || 0
    }
  }, [startAt])
  // useEffect(() => {
  //   const setTime = videoLink && current && startAt !== undefined && duration !== undefined && current.currentTime === 0
  //   console.log({ current, startAt, setTime })
  //   if (setTime && current) {
  //     const startAtSeconds = ((startAt || 0) / 100) * (duration || 0)
  //     console.log({ startAt, startAtSeconds, currentTime: current?.currentTime })

  //     // current.currentTime = startAtSeconds
  //     // current?.play()

  //     // setIsPlaying(true)
  //   }
  // }, [current, duration])
  // useEffect(() => {
  //   console.log({ current, startAt, ddd: videoLink && videoRef?.current && startAt !== undefined && duration !== undefined && videoRef.current.currentTime === 0 })
  //   if (videoLink && videoRef?.current && startAt !== undefined && duration !== undefined && videoRef.current.currentTime === 0) {
  //     const startAtSeconds = (startAt / 100) * duration
  //     console.log({ startAt, startAtSeconds, t: videoRef.current.currentTime })
  //     // videoRef.current.currentTime = startAtSeconds
  //     // videoRef.current.play()
  //     // setIsPlaying(true)
  //   }
  // }, [current, videoLink, videoRef, duration, setIsPlaying])

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause()
      } else {
        videoRef.current.play()
      }
      setIsPlaying(!isPlaying)
    }
  }

  const handleBack10Seconds = () => {
    if (videoRef.current) {
      if (videoRef.current.currentTime < 10) {
        videoRef.current.currentTime = 0
      } else {
        videoRef.current.currentTime -= 10
      }
      videoRef.current.play()
    }
  }

  const handleRestart = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0
      videoRef.current.play()
    }
  }

  const handlePlay = () => {
    // console.log('Video started playing')
    setIsPlaying(true)
  }
  const getProgress = () => (((videoRef?.current?.currentTime || 0) / (videoRef.current?.duration || 1)) * 100).toFixed(2)
  const handlePause = () => {
    // console.log('Video paused/stopped')
    setIsPlaying(false)
    onEnded && onEnded(getProgress())
  }

  const handleEnded = () => {
    // console.log('Video ended')
    setIsPlaying(false)
    onEnded && onEnded(getProgress())
  }

  const handleStop = () => {
    // console.log('Video stop')
    setIsPlaying(false)
    // onEnded && onEnded(getProgress())
    onStop && onStop(getProgress())
  }

  return (
    <div>
      <video autoPlay={autoPlay} ref={videoRef} disablePictureInPicture onPlay={handlePlay} onPause={handlePause} onEnded={handleEnded} style={{ width: '100%' }}>
        <source src={videoLink} type='video/mp4' />
        Your browser does not support the video tag.
      </video>
      <div>
        {/* <Button onClick={handleBack10Seconds}>Back 10 Seconds</Button> */}
        {/* <Button onClick={handlePlayPause}>{isPlaying ? 'Pause' : 'Play'}</Button> */}
        <IconButton onClick={handleRestart}>
          <i className='fas fa-step-backward'></i>
        </IconButton>
        <IconButton onClick={handleBack10Seconds}>
          <i className='far fa-undo-alt'></i>
        </IconButton>
        {!isPlaying && (
          <IconButton onClick={handlePlayPause}>
            <i className='fas fa-play'></i>
          </IconButton>
        )}
        {isPlaying && (
          <IconButton onClick={handlePlayPause}>
            <i className='fas fa-pause'></i>
          </IconButton>
        )}

        {hideClose ? null : (
          <>
            <IconButton onClick={handleStop}>
              <i className='fas fa-stop'></i>
            </IconButton>

            <Button onClick={handleStop}>Close</Button>
          </>
        )}
      </div>
    </div>
  )
}

export default VideoPlayer

// const Player = ({ link }: { link: string }) => {
//   return (
//     <div>
//       <video controls width='640' height='360'>
//         <source
//           src={link}
//           type='video/mp4'
//           onPlay={(e) => {
//             console.log({ e })
//           }}
//           onDurationChange={(e) => {
//             console.log({ e })
//           }}
//           onProgress={(e) => {
//             console.log({ e })
//           }}
//         />
//         Your browser does not support the video tag.
//       </video>
//     </div>
//   )
// }
