import React, { Component } from 'react'

import { inject, observer } from 'mobx-react'
import { IStore, IStory, Story } from '../../../../store/models'
import { withRouter } from 'react-router'
import { flow } from 'lodash'
import DataTable, { IColData, ValueLabel } from '../../../shared/DataTableFilterable'
import { Grid, IconButton, Tooltip, Link as MuiLink } from '@material-ui/core'
import { truncate } from 'lodash'

import moment from 'moment'
import NpsActions from '../partials/NpsActions'
import { INpsAnswer } from '../../../../store/models/NpsAnswers'
import { useAuth } from '../../../Providers'

const StoryActions = observer(({ row }: { row: IStory }) => {
  return (
    <>
      <Tooltip title='View Story'>
        <IconButton size='small' onClick={() => row.show()}>
          <i className='fas fa-search opacity-8'></i>
        </IconButton>
      </Tooltip>
      <Tooltip title={row.visible ? 'Hide' : 'Show'}>
        <IconButton size='small' onClick={() => row.updateVisibility(!row.visible)}>
          {row.visible ? <i className='fas fa-eye-slash opacity-8' /> : <i className='fas fa-eye opacity-8' />}
        </IconButton>
      </Tooltip>
    </>
  )
})
const ShowVisivility = observer(({ row }: { row: IStory }) => <ValueLabel value={row.visible ? 'Visible' : 'Hidden'} />)
const NpsAnswersTable = (props) => {
  // render() {
  const { items, loading, filterable } = props
  const { user } = useAuth()

  if (!user) {
    return <></>
  }

  const columnData: IColData[] = [
    { name: 'id', title: 'ID', width: 80, sortable: true },
    { name: 'createdAt', title: 'Created', width: 100, sortable: true },
    { name: 'npsQuestionId', title: 'Question', width: 300, sortable: true },
    { name: 'score', title: 'Score', width: 80, sortable: true },
    { name: 'reason', title: 'Reason', width: 300, sortable: true },
    ...(user?.isSuperAdmin() ? [{ name: 'userName', title: 'User Name', width: 150 }] : []),
    { name: 'partnerName', title: 'Partner Name', width: 200 },
    { name: 'conversationTitle', title: 'Conversation Title', width: 200 },
    ...(user?.isSuperAdmin() ? [{ name: 'userId', title: 'User ID', width: 100, sortable: true }] : []),
    { name: 'topicId', title: 'Topic ID', width: 100, sortable: true },
    { name: 'conversationId', title: 'Conversation ID', width: 120, sortable: true },
    { name: 'organizationId', title: 'Organization ID', width: 120, sortable: true },
    { name: 'partnerId', title: 'Partner ID', width: 100, sortable: true },
    { name: 'actions', title: 'Actions', width: 220, align: 'center' }
  ]

  const questions = [
    'How likely are you to recommend this experience to a friend or colleague?',
    'I felt my voice was heard and valued',
    'I feel a greater sense of empathy with others',
    'I now have a deeper understanding of this topic',
    'I feel more committed to take action on this topic',
    "Any comments you'd like to share?",
    'This topic was very interesting',
    'I would recommend this conversation facilitator',
    "Any comments you'd like to share with your facilitator?"
  ]

  return (
    <DataTable
      height={'calc(100vh - 16em)'}
      data={items}
      columns={columnData}
      loading={loading}
      filterable={filterable}
      rightColumns={['actions']}
      hideSearch={true}
      getCellValue={(row: INpsAnswer, columnName: string) => {
        switch (columnName) {
          case 'actions':
            return <NpsActions item={row} reload={() => filterable.loadFilter()} />
          case 'createdAt':
            return moment(row.createdAt).tz(moment.tz.guess()).format('L')
          case 'npsQuestionId':
            return <ValueLabel value={row.npsQuestionId ? questions[row.npsQuestionId - 1] : row.npsQuestionId} />
          default:
            return <ValueLabel value={row[columnName]} />
        }
      }}
    />
  )
}

export default flow(
  observer,
  inject<FIXME, FIXME>(
    ({
      mst: {
        auth: { user },
        nps_answers
      }
    }: {
      mst: IStore
    }) => {
      return {
        user,
        nps_answers,
        filterable: nps_answers,
        items: nps_answers.list,
        loading: nps_answers.loadingIds.includes('nps_answers_table')
      }
    }
  ),
  withRouter
)(NpsAnswersTable)
