import React, { Component } from 'react'
import { connect } from 'react-redux'

import getInviteCreateProps from '../../getters/inviteCreateGetter'
import StepOne from './InvitationDialog'
import StepTwo from './InvitationDialogSuccess'
import Dialog from '../DialogWrapper'

export class InvitationDialogContainer extends Component {
  render() {
    const props = this.props
    const { step } = this.props

    return (
      <Dialog name='invitation' type='thin'>
        {step === 1 ? <StepOne {...props} /> : null}
        {step === 2 ? <StepTwo {...props} /> : null}
      </Dialog>
    )
  }
}

export default InvitationDialogContainer = connect(getInviteCreateProps, null)(InvitationDialogContainer)
