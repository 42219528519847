import { types, Instance } from 'mobx-state-tree'
import PartnersApi from '../../api/PartnersApi'
import PartnersTableApi from '../../api/PartnersTableApi'
import { Alertable } from './helpers/Alertable'
import { Filterable, IFilterableArgs } from './helpers/Filterable'
import { LoadableStatus } from './helpers/LoadableStatus'
import Partner from './Partner'
import { removeNullStringValue } from './utils'
import PartnersStats from './PartnersStats'
import { snakify } from '../../decorators/textTools'

export const Partners = types
  .compose(
    'Partners',
    LoadableStatus,
    Alertable,
    PartnersStats,
    Filterable,
    types.model({
      list: types.optional(types.array(Partner), []),
      selectedPartnerId: types.maybe(types.number)
    })
  )
  .actions((self) => ({
    addToList(data: any) {
      removeNullStringValue(data)
      const found = self.list.find((p) => p.id === data.id)
      if (found) {
        Object.assign(found, data)
      } else {
        self.list.push(data)
      }
    },
    setList(data: any[], totalCount?: number) {
      data.forEach((d) => removeNullStringValue(d))
      self.list.replace(data)
      self.setTotalCount(totalCount)
    }
  }))
  .actions((self) => ({
    loadBySlug: (rawSlug: string) => {
      const slug = rawSlug.toLowerCase()
      if (self.isLoading(slug)) {
        return
      }
      self.startLoading(slug)
      return PartnersApi.get(slug).then(({ response: { ok }, json }) => {
        if (ok) {
          self.addToList(json)
        }
        self.stopLoading(slug)
      })
    }
  }))
  .actions((self) => ({
    selectPartner: (partnerId) => {
      self.selectedPartnerId = partnerId
    },
    loadPartnersTable: (args: IFilterableArgs) => {
      if (self.isLoading('partners_table')) {
        return
      }
      self.startLoading('partners_table')
      self.updateArgs(args)

      return PartnersTableApi.getAll(args).then(({ response: { ok, statusText }, json: { partners, total } }) => {
        if (ok) {
          self.setList(partners, total)
        } else {
          self.showAlert(statusText)
        }
        self.stopLoading('partners_table')
      })
    },
    loadBySlugs: (rawSlug: string[], list_name: string) => {
      if (self.isLoading(list_name)) {
        return
      }
      self.startLoading(list_name)
      return Promise.all(rawSlug.map((slug) => self.loadBySlug(slug))).then(() => {
        self.stopLoading(list_name)
      })
    }
  }))
  .actions((self) => ({
    loadFilter: ({ reset = false, clear = true } = {}) => {
      if (reset) {
        self.page = 0
        self.pageSize = 15
        self.search = ''
      }

      if (clear) {
        self.list.replace([])
      }

      const { search, pageSize: page_size, page, sortedId: sorted_id, sortedPriority: sorted_priority } = self
      return self.loadPartnersTable({ search, page_size, page, sorted_id, sorted_priority })
    },
    afterCreate: () => {
      self.setSortingId('id')
      self.setSortedPriority(true)
    },
    createPartner: (data: any) => {
      return PartnersApi.create(snakify(data)).then(({ response: { ok }, json }) => {
        if (ok) {
          self.addToList(json)
          return { data: json }
        }
      })
    }
  }))

export interface IPartners extends Instance<typeof Partners> {}
export default Partners
