import React, { useContext, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Button, createStyles, Divider, Drawer, IconButton, Link, ListItemAvatar, makeStyles, Theme } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '../../users/Avatar'
import { VisibleOnlyUser } from '../../auth/authWrappers'
import withTranslation from '../../hocs/withTranslation'
import { inject, observer } from 'mobx-react'
import { flow } from 'lodash'
import { IStore } from '../../../store/models'
import NavList from './NavList'
import { ABOUT_LINKS, EXPLORE_LINKS, INCLUSIVV_FOR_LINKS, PRICING_LINKS, TALK_TO_EXPERTS_LINK } from './Links'
import { DialogContext } from '../../Providers/DialogProvider'
import { useTranslation } from 'react-i18next'
import MyOrganizationMobileNav from './MyOrganizationMobileNav'

const MobileNav = (props: FIXME) => {
  const {
    logged_in,
    currentUser,
    match: {
      params: { page }
    }
  } = props
  const [open, setopen] = useState(false)
  const classes = useStyles()
  const { showDialog } = useContext(DialogContext)
  const { t } = useTranslation()

  const handleToggle = () => setopen((op) => !op)

  const handleClose = () => setopen(false)

  const handleDialog = (type) => {
    return () => {
      showDialog(type)
      handleClose()
    }
  }

  const handleLogOut = (event) => {
    event.preventDefault()
    const logOutMessage = t('auth.signedout')
    props.logOutUser(logOutMessage)
    handleClose()
  }
  const ListItemExternal = (p) => <ListItem button component='a' onClick={handleClose} {...p} />
  const ListItemLink = (p) => <ListItem button component='a' onClick={handleClose} {...p} />

  const signedOutLinks = (
    <Button onClick={handleDialog('login')} variant='outlined' className='toolbar-nav-link coco-session-btn no-wrap' classes={{ root: classes.button }}>
      {t('auth.login')}
    </Button>
  )

  const sessionLinks = () => {
    if (logged_in) {
      return (
        <Button onClick={handleLogOut} variant='outlined' className='toolbar-nav-link coco-session-btn no-wrap' classes={{ root: classes.button }}>
          <ListItemText>{t('auth.signout')}</ListItemText>
        </Button>
      )
    } else {
      return signedOutLinks
    }
  }

  const MyConversations = VisibleOnlyUser(() => (
    <ListItemLink href={'/myconversations'}>
      <ListItemText primary={t('nav.myConversations')} classes={{ primary: classes.label }} />
    </ListItemLink>
  ))

  const MyMembership = VisibleOnlyUser(() => (
    <ListItemLink href={'/memberships'}>
      <ListItemText primary={'My Membership'} classes={{ primary: classes.label }} />
    </ListItemLink>
  ))

  const MyProfile = VisibleOnlyUser(() => (
    <ListItemLink href={`/users/${currentUser.id}`}>
      <ListItemText primary={t('nav.myProfile')} classes={{ primary: classes.label }} />
    </ListItemLink>
  ))

  const logo = () => {
    if (window.coco) {
      return (
        <ListItemLink onClick={() => props.redirectToPartner()}>
          <ListItemText>{t('nav.home')}</ListItemText>
        </ListItemLink>
      )
    } else {
      return (
        <ListItemLink href='/'>
          <ListItemText classes={{ primary: classes.label }}>{t('nav.home')}</ListItemText>
        </ListItemLink>
      )
    }
  }

  return (
    <div>
      <IconButton className='fa fa-navicon' onClick={handleToggle} />

      <Drawer open={open} onClose={handleToggle} className='mobile-nav' classes={{ paper: classes.drawerPaper }}>
        <List>
          <ListItemText primary={<IconButton className='fal fa-times' onClick={handleToggle} />} className={classes.close} />

          {/* <Divider classes={{ root: classes.divider }} />
          <NavList label='nav.inclusivvFor' menus={INCLUSIVV_FOR_LINKS} />
          <Divider classes={{ root: classes.divider }} />
          <NavList label='nav.explore' menus={EXPLORE_LINKS} />
          <Divider classes={{ root: classes.divider }} />
          {window.inclusivv && (
            <>
              <Link href={PRICING_LINKS} color='inherit'>
                <ListItem button>
                  <ListItemText primary={t('nav.pricing')} classes={{ primary: classes.label }} />
                </ListItem>
              </Link>
              <Divider classes={{ root: classes.divider }} />
            </>
          )}
          <NavList label='nav.about' menus={ABOUT_LINKS} />
           */}
          <Divider classes={{ root: classes.divider }} />

          {currentUser && (
            <>
              <ListItem>
                <ListItemAvatar>
                  <Avatar profileImage={currentUser.profileImage} hasProfileImage={currentUser.hasProfileImage} size={'thumb'} />
                </ListItemAvatar>
                <ListItemText primary={currentUser.fullName} classes={{ primary: classes.label }} />
              </ListItem>
              <Divider classes={{ root: classes.divider }} />
            </>
          )}
          {logo()}
          <Divider classes={{ root: classes.divider }} />

          <MyConversations />
          <Divider classes={{ root: classes.divider }} />
          {currentUser && currentUser.membershipUsers.length > 0 && <MyMembership />}
          <Divider classes={{ root: classes.divider }} />
          <MyOrganizationMobileNav onClick={handleClose} />
          <Link href={'https://help.inclusivv.co/en/'} color='inherit' target='blank'>
            <ListItem button>
              <ListItemText primary={'Help Center'} classes={{ primary: classes.label }} />
            </ListItem>
          </Link>
          <Divider classes={{ root: classes.divider }} />
          <MyProfile />
        </List>
        <div style={{ flexGrow: 1 }} />
        {/* {window.inclusivv && (!currentUser || !currentUser?.isCommunityAdmin()) && (
          <Button href={TALK_TO_EXPERTS_LINK} variant='contained' color='primary' className='toolbar-nav-link coco-session-btn no-wrap' classes={{ root: classes.button }}>
            {"Let's Chat"}
          </Button>
        )} */}
        {sessionLinks()}
      </Drawer>
    </div>
  )
}

export default flow(
  withTranslation,
  observer,
  inject<FIXME, FIXME>(
    (
      {
        mst: {
          auth: { user, loaded, logOutUser, loadDefaultPartner },
          dialogs: { showDialog }
        }
      }: {
        mst: IStore
      },
      { history }
    ) => {
      return {
        loaded,
        user,
        logged_in: !!user,
        currentUser: user,
        logOutUser,
        loadDefaultPartner,
        showDialog,
        redirectToPartner: () => {
          loadDefaultPartner().then((slug) => history.push(`/${slug}`))
        }
      }
    }
  ),
  withRouter
)(MobileNav)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      backgroundColor: '#C2C9D177'
    },
    button: {
      margin: '.5em'
    },
    drawerPaper: {
      width: '100%',
      marginTop: '4.8em',
      height: 'calc(100% - 4.8em)'
    },
    close: {
      textAlign: 'right',
      marginRight: '.5em'
    },
    label: {
      fontWeight: 500
    }
  })
)
