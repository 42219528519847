import { toFormData } from './FormData'
import { deleteApi, post, put } from './utils'

class SponsorsApi {
  static create(data) {
    const formData = toFormData('sponsor', data)

    return post(`/sponsors`, {
      body: formData,
      noContentTypeJson: true
    })
  }

  static update(data) {
    const formData = toFormData('sponsor', data)

    return put(`/sponsors/${data.id}`, {
      body: formData,
      noContentTypeJson: true
    })
  }

  static destroy(id) {
    return deleteApi(`/sponsors/${id}`)
  }
}

export default SponsorsApi
