import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { flow } from 'lodash'
import { IStore, IPartner } from '../../store'
import { inject } from 'mobx-react'
import { RouteComponentProps } from 'react-router'
import { useAppLayout } from './AppLayoutProvider'

const ParamPartnerProvider = ({ children, partner, partnerLoading }: Props & InjectedProps & RouteComponentProps) => {
  const { changeLogo, logoUrl } = useAppLayout()
  useEffect(() => {
    if (partner && partner.privateLabel && partner?.logoImage?.url && partner.logoImage.url !== logoUrl) {
      changeLogo(partner.logoImage.url, `/${partner.slug}`)
    }
  }, [changeLogo, partner, logoUrl])
  return <ParamPartnerContext.Provider value={{ partner, partnerLoading }}>{children}</ParamPartnerContext.Provider>
}

interface Props {
  children?: React.ReactNode | React.ReactNode[]
}

interface InjectedProps {
  partner?: IPartner
  partnerLoading: boolean
}

export const ParamPartnerContext = React.createContext<InjectedProps>({
  partnerLoading: false
})

export const useParamPartner = () => useContext(ParamPartnerContext)

export default flow(
  observer,
  inject<InjectedProps, Props & RouteComponentProps<{ slug: string }>>(
    (
      {
        mst: { partners }
      }: {
        mst: IStore
      },
      {
        match: {
          params: { slug: slugRaw }
        }
      }
    ) => {
      const slug = slugRaw.toLowerCase()
      const partner = partners.list.find((d) => d.slug === slug)
      !partner && partners.shouldLoad(slug, () => partners.loadBySlug(slug))

      return {
        partner,
        partnerLoading: partners.loadingIds.includes(slug)
      }
    }
  )
)(ParamPartnerProvider)
