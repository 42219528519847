import { createSelector } from 'reselect'
import { assign, map, flow, filter, find, has, isUndefined } from 'lodash'

import coordinateDecorator from '../decorators/coordinateDecorator'

export const getTopicsRaw = (state) => state.topics

export const getTopicId = (_, props) => props.topic.id

export const getSlug = (state, props) => {
  if (has(props, 'slug') && !isUndefined(props.slug)) {
    return props.slug.toLowerCase()
  } else if (has(props, 'match.params.slug')) {
    return props.match.params.slug.toLowerCase()
  } else {
    return null
  }
}

export const getTopicsList = createSelector(getTopicsRaw, (c) => c.list)

export const getRankedTopicsList = createSelector(getTopicsRaw, (c) => c.rankedList)

const getPartnerTopicsList = createSelector(getTopicsRaw, (c) => c.partnerTopicslist)

export const getVisibleTopics = createSelector(getTopicsList, (topics) => {
  const flowTopics = flow([filterHiddenTopics])
  return flowTopics(topics)
})

export const getVisiblePartnerTopics = createSelector(getPartnerTopicsList, (topics) => {
  const flowTopics = flow([filterHiddenTopics])
  return flowTopics(topics)
})

export const getPartnerTopicSlugs = createSelector(getVisiblePartnerTopics, (partnerTopics) => map(partnerTopics, (pc) => pc.slug))

export const getAllTopics = createSelector(getTopicsList, (topics) => {
  return map(topics, (c) => {
    return assign({}, c, {
      regionCoordinates: coordinateDecorator(c.regionCoordinates)
    })
  })
})

export const getTopics = createSelector(getVisibleTopics, (topics) => {
  return map(topics, (c) => {
    return assign({}, c, {
      regionCoordinates: coordinateDecorator(c.regionCoordinates)
    })
  })
})

export const getTopic = createSelector(getAllTopics, getSlug, (topics, slug) => {
  return find(topics, (c) => c.slug === slug)
})

function filterHiddenTopics(topics) {
  return filter(topics, (c) => !c.hidden)
}

export const getErrorTopics = createSelector(getTopicsRaw, (c) => c.error)

export const getLoadingTopics = createSelector(getTopicsRaw, (c) => c.loadingTopics)

export const getLoadingRankedTopics = createSelector(getTopicsRaw, (c) => c.loadingRankedTopics)

export const getLoadingTopic = createSelector(getTopicsRaw, (c) => c.loadingTopic)

export const getLoadingPartnerTopics = createSelector(getTopicsRaw, (c) => c.loadingPartnerTopics)
