import React, { useMemo } from 'react'
import { Grid } from '@material-ui/core'

import moment from 'moment'
import { IMembershipUser } from '../../../store/models/User/MembershipUser'
import { IConversation } from '../../../store'
import ConversationCard from '../../conversations/Card'
import ConversationListCard from '../../conversations/ListCard'

interface Props {
  conversations: IConversation[]
  membershipUser: IMembershipUser
  past?: boolean
}

export const TrackConversations = ({ conversations, membershipUser, past = false }: Props) => {
  const list = useMemo(() => {
    if (past) {
      return [...conversations].filter((i) => moment(i.startsAt).isBefore(moment())).sort((a, b) => moment(b.startsAt).diff(moment(a.startsAt)))
    }
    return [...conversations].filter((i) => moment(moment()).isBefore(i.startsAt)).sort((a, b) => moment(a.startsAt).diff(moment(b.startsAt)))
  }, [conversations, past])
  return (
    <section>
      <Grid container>
        {list.map((conversation) => (
          <Grid key={conversation.id} xs={12} sm={12} className='reg-bottom'>
            <ConversationListCard showAttended={true} past={past} conversation={conversation} isHost={conversation.isHost} key={conversation.id} />
          </Grid>
        ))}
      </Grid>
    </section>
  )
}
export default TrackConversations
