import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import { flow } from 'lodash'
import { IStore, ITrack } from '../../store'
import { inject } from 'mobx-react'
import { RouteComponentProps, withRouter } from 'react-router'

const ParamTrackProvider = ({ children, track, trackLoading }: Props & InjectedProps & RouteComponentProps) => {
  return <ParamTrackContext.Provider value={{ track, trackLoading }}>{children}</ParamTrackContext.Provider>
}

interface Props {
  children?: React.ReactNode | React.ReactNode[]
}

interface InjectedProps {
  track?: ITrack
  trackLoading: boolean
}

export const ParamTrackContext = React.createContext<InjectedProps>({
  trackLoading: false
})

export const useParamTrack = () => useContext(ParamTrackContext)

export default flow(
  observer,
  inject<InjectedProps, Props & RouteComponentProps<{ slug: string }>>(
    (
      {
        mst: { tracks }
      }: {
        mst: IStore
      },
      { match }
    ) => {
      // const slug = match?.params?.slug
      // const track = slug ? tracks.list.find((d) => d.slug === slug) : undefined
      // !track && tracks.shouldLoad(slug, () => tracks.loadBySlug(slug))

      return {
        track: undefined,
        trackLoading: false //tracks.loadingIds.includes(slug)
      }
    }
  ),
  withRouter
)(ParamTrackProvider)
