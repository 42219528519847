import React, { Component } from 'react'
import { Field } from 'redux-form'
import { renderFilterSelectField } from '../validations/fieldRenderers'
import withTranslation from '../hocs/withTranslation'
import MenuItem from '@material-ui/core/MenuItem'

class VenueTypeFilter extends Component {
  render() {
    if (this.props == null) return null

    const { updateForm, t } = this.props

    return (
      <Field name='venueType' component={renderFilterSelectField} onChange={() => updateForm()} className='white-background'>
        <MenuItem value=''>{t('conversation.allConversations')}</MenuItem>
        <MenuItem value='virtual'>{t('conversation.virtual')}</MenuItem>
        <MenuItem value='in_person'>{t('conversation.inPerson')}</MenuItem>
      </Field>
    )
  }
}

export default withTranslation(VenueTypeFilter)
