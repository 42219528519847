import { types, Instance } from 'mobx-state-tree'
import MembershipApi from '../../../api/MembershipApi'
import { snakify } from '../../../decorators/textTools'
import { Filterable, IFilterableArgs } from '../helpers'
import { Alertable } from '../helpers/Alertable'
import { Dialogable } from '../helpers/Dialogable'
import Editable from '../helpers/Editable'
import { LoadableStatus } from '../helpers/LoadableStatus'
import { MembershipUser } from '../User/MembershipUser'
import { removeNullStringValue } from '../utils'

export const Membership = types
  .compose(
    'Membership',
    LoadableStatus,
    Dialogable,
    Alertable,
    Editable,
    Filterable,
    types.model({
      id: types.identifierNumber,
      active: types.boolean,
      dealName: types.string,
      amount: types.maybeNull(types.string),
      companyName: types.maybeNull(types.string),
      dealClosedAt: types.maybeNull(types.string),
      endsAt: types.string,
      hubspotDealId: types.maybeNull(types.string),
      startsAt: types.string,
      totalMembers: types.maybeNull(types.number),
      seatLimit: types.maybeNull(types.number),
      trackId: types.maybeNull(types.number),
      owners: types.array(MembershipUser),
      list: types.optional(types.array(MembershipUser), [])
    })
  )
  .actions((self) => ({
    updateMembership: (data) => {
      return MembershipApi.updateMembership(self.id, { ...snakify(data) }).then(({ response: { ok, statusText }, json }) => {
        if (ok) {
          self.updateMe(json)
          self.showAlert('Updated Membership')
        } else {
          self.showAlert('Error Updated Membership')
          return Promise.reject(json)
        }
      })
    }
  }))

export interface IMembership extends Instance<typeof Membership> {}
export default Membership
