import React, { Component } from 'react'
import { isEmpty } from 'lodash'
import InfiniteScroll from 'react-infinite-scroll-component'

import LoadingAnimation from './LoadingAnimation'
import { observer } from 'mobx-react'

interface Props {
  limit?: number
  height?: string
  itemsLoading?: boolean
  items: any[]
  hasMoreItems: boolean
  wrapperClass?: string
  wrapperStyle?: any
  item?: any
  itemProps?: any
  emptyMessage?: any
  initQuery(args: { limit: number; offset: number })
  query(args: { limit: number; offset: number })
}
interface State {
  page?: number
}

class InfiniteList extends Component<Props, State> {
  state = {
    page: -1
  }
  componentDidMount() {
    this.handleScroll(0)
  }

  handleScroll = (newPage?: number) => {
    const { limit = 10, itemsLoading } = this.props
    const { page } = this.state
    if (itemsLoading) {
      return
    }
    this.setState({ page: newPage ? newPage : page + 1 }, () => {
      const offset = limit * this.state.page
      if (this.state.page === 0) {
        this.props.initQuery({ limit, offset })
      } else {
        this.props.query({ limit, offset })
      }
    })
  }

  render() {
    const { items, hasMoreItems, itemsLoading, wrapperClass, itemProps, height } = this.props
    const list = items

    if (!items) return null

    const Item = this.props.item
    const EmptyMessage = this.props.emptyMessage
    const listEmpty = isEmpty(list) && !itemsLoading

    const InviniteScrollList = observer(() => (
      <InfiniteScroll
        loader={<LoadingAnimation />}
        dataLength={list.length}
        next={this.handleScroll}
        hasMore={hasMoreItems && !itemsLoading}
        {...(height ? { scrollableTarget: 'scrollableDiv' } : {})}
      >
        {listEmpty ? (
          <EmptyMessage />
        ) : (
          <div className={wrapperClass}>
            {list.map((item, index) => (
              <Item key={item.id || index} item={item} itemProps={itemProps} />
            ))}
          </div>
        )}

        {itemsLoading && <LoadingAnimation />}
      </InfiniteScroll>
    ))
    return height ? (
      <div
        id='scrollableDiv'
        style={{
          height,
          ...(listEmpty
            ? {}
            : {
                overflow: 'auto',
                display: 'flex'
              })
        }}
      >
        <InviniteScrollList />
      </div>
    ) : (
      <InviniteScrollList />
    )
  }
}

export default observer(InfiniteList)
