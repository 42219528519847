import React, { Component } from 'react'

import Avatar from '../../../users/Avatar'
import { Divider, ListItem, ListItemSecondaryAction, ListItemText, ListItemAvatar, Button } from '@material-ui/core'
import { observer } from 'mobx-react'

class InvitationListItem extends Component<FIXME, FIXME> {
  render() {
    const { item: pendingInvitation, removeAdmin } = this.props,
      { user, recipientEmail } = pendingInvitation || {}

    return (
      <>
        <ListItem>
          <ListItemAvatar>
            <Avatar profileImage={user && user.profileImage} hasProfileImage={user && user.hasProfileImage} size={'thumb'} />
          </ListItemAvatar>
          <ListItemText primary={user ? `${user.first_name} ${user.last_name}` : recipientEmail} />
          <ListItemSecondaryAction>
            Invitation Pending
            {pendingInvitation.canDelete() && (
              <Button onClick={removeAdmin} type='submit' color='primary' variant='contained' className='thin-left'>
                Remove
              </Button>
            )}
          </ListItemSecondaryAction>
        </ListItem>
        <Divider variant='inset' component='li' />
      </>
    )
  }
}

export default observer(InvitationListItem)
