import { flow } from 'lodash'
import { observer } from 'mobx-react'
import { inject } from 'mobx-react'
import React from 'react'
import { withRouter } from 'react-router'
import { IStore } from '../../store/models'
import PartnerForm from './Form'

const NewPartnerPage = ({ createPartner, history }: FIXME) => {
  return (
    <div>
      <div className='row'>
        <div className='col-xs-12 form-lg'>
          <PartnerForm page={'new'} onSave={(data) => data && history.push(`/admin/partners/${data.slug}/edit`)} createPartner={(val) => createPartner && createPartner(val)} />
        </div>
      </div>
    </div>
  )
}

export default flow(
  observer,
  inject<FIXME, FIXME>(({ mst: { partners } }: { mst: IStore }) => {
    return {
      createPartner: partners.createPartner
    }
  }),
  withRouter
)(NewPartnerPage)
