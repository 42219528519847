import React, { Component, useEffect } from 'react'
import { useLocation, withRouter } from 'react-router'
import { useDispatch } from 'react-redux'
import queryString from 'query-string'

import withTranslation, { WithTranslation } from './hocs/withTranslation'
import { observer, inject } from 'mobx-react'
import { IStore } from '../store/models'
import { IUser } from '../store/models/User'
import AppLayout from './layout/AppLayout'
import { loadUserCoordsSuccess } from '../actions/userCoordsActions'
import { flow } from 'lodash'
import { useTranslation } from 'react-i18next'

const CoordsBridge = ({ long, lat }: any) => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (long && lat) {
      const userCoords = JSON.stringify([lat, long])
      dispatch(loadUserCoordsSuccess({ userCoords }))
    }
  }, [long, lat, dispatch])
  return <></>
}

const LocaleCheck = () => {
  const location = useLocation()
  const { i18n } = useTranslation()
  useEffect(() => {
    const query = queryString.parse(location?.search)
    if (i18n && query && query.locale) {
      try {
        const locale = typeof query.locale === 'string' ? query.locale : query.locale[0]
        locale && i18n.changeLanguage(locale)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('unknown language')
      }
    }
  }, [i18n, location])

  return <></>
}

class AppContainer extends Component<Props & InjectedProps> {
  componentDidMount() {
    const {
        match,
        history,
        location,
        i18n: { changeLanguage },
        getLocationFromIP
      } = this.props,
      { search } = location,
      query = queryString.parse(search)

    getLocationFromIP()
  }

  componentDidUpdate(prevProps: Props & InjectedProps) {
    const { logged_in, redirectOnSuccess, history, setRedirectOnSuccess } = this.props

    if (logged_in && logged_in !== prevProps.logged_in && redirectOnSuccess) {
      setRedirectOnSuccess(undefined)
      history.push(redirectOnSuccess)
    }
  }

  render() {
    const { children, long, lat } = this.props
    return (
      <AppLayout>
        <LocaleCheck />
        {children}
        <CoordsBridge {...{ long, lat }} />
      </AppLayout>
    )
  }
}

interface Props extends WithTranslation {
  children: any
  history: any
  location: any
  match: any
}
interface InjectedProps {
  user?: IUser
  redirectOnSuccess?: string
  logged_in: boolean
  long: any
  lat: any
  getLocationFromIP(): Promise<any>
  setRedirectOnSuccess(val?: string)
}

export default flow(
  withRouter,
  withTranslation,
  observer,
  inject<InjectedProps, Props>(({ mst }: { mst: IStore }, ownProps) => {
    const { auth } = mst
    return {
      user: auth.user,
      long: auth.longitude,
      lat: auth.latitude,
      redirectOnSuccess: auth.redirectOnSuccess,
      logged_in: !!auth.user,
      getLocationFromIP: auth.getLocationFromIP,
      setRedirectOnSuccess: auth.setRedirectOnSuccess
    }
  })
)(AppContainer)
