import React from 'react'
import FontIcon from '@material-ui/core/Icon'
import Button from '@material-ui/core/Button'
import { flow } from 'lodash'
import i18n from 'i18next'

// import { deleteUserConversationNoReRender } from '../../actions/userConversationActions'
import { connectTopicTheme } from '../../connectTheme.js'
import * as validations from '../validations/fieldLevelValidations'
import { capitalize } from '../../decorators/textTools'
import withTranslation from '../hocs/withTranslation'
import Dialog from '../DialogWrapper'
import { useForm } from 'react-hook-form'
import { TextFieldControl } from '../utils/index'
import { observer } from 'mobx-react'
import { inject } from 'mobx-react'
import { IStore } from '../../store/models/index.js'
import { DialogTitle } from '@material-ui/core'

const RemoveGuestDialog = ({ dialog, t, conversation }: any) => {
  const { handleSubmit, errors, control } = useForm({ mode: 'onBlur' })

  const unattend = (message = '') => {
    conversation.unattend({ userId: dialog?.data?.userId, message }, { successAlert: dialog?.data?.successAlert }).then(dialog.closeDialog)
  }

  const handleSave = handleSubmit((data: any) => {
    unattend(data.message)
  })

  return (
    <Dialog dialog={dialog} name='removeGuest' type='thin'>
      <div>
        <DialogTitle disableTypography>{t('conversation.sendMsgQuestion')}</DialogTitle>

        <section className='pane thin-reg-pane'>
          <div className='row reg-bottom'>
            <div className='col-xs-12 center-xs'>
              <div className='box'>
                <FontIcon className='fa fa-envelope-o header-36' />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-xs-12 center-xs'>
              <h1 className='flat header-24'>{t('conversation.sendMsgToGuest')}</h1>
            </div>
          </div>
        </section>

        <section className='pane minimal-grey-pane big-top-padding big-bottom-padding'>
          <form onSubmit={handleSave}>
            <div className='row reg-bottom'>
              <div className='col-xs-12 align-left'>
                <TextFieldControl
                  name='message'
                  control={control}
                  defaultValue={i18n.t('conversation.removeNotice', {
                    url: `https://${window.domain}/${conversation.slug}`,
                    eventTypePlural: conversation.eventTypePlural,
                    interpolation: { escapeValue: false }
                  })}
                  error={errors && errors.message}
                  multiline={true}
                  rows={3}
                  label={t('common.message')}
                  required
                  rules={{ validate: validations.required }}
                  className='reg-bottom'
                />
              </div>
            </div>
            <div className='row center-xs'>
              <div className='col-xs-12'>
                <Button type='submit' className='reg-bottom flat-top btn-xl btn-block lowercase capitalize' color='primary' variant='contained'>
                  {t('conversation.sendMsgRemove')}
                </Button>
              </div>
            </div>

            <div className='row center-xs'>
              <div className='col-xs-12'>
                <Button onClick={() => unattend()} className='reg-bottom flat-top btn-xl btn-block lowercase capitalize' color='primary' variant='contained'>
                  {t('conversation.removeNoNotice')}
                </Button>
              </div>
            </div>
          </form>
        </section>
      </div>
    </Dialog>
  )
}

export default flow(
  connectTopicTheme,
  withTranslation,
  observer,
  inject<FIXME, FIXME>(
    (
      { mst: { conversations } }: { mst: IStore },
      {
        dialog: {
          data: {
            conversation: { id }
          }
        }
      }
    ) => {
      const conversation = conversations.list.find((d) => d.id === id)

      return {
        conversation
      }
    }
  )
)(RemoveGuestDialog)
