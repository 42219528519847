import { createStructuredSelector, createSelector } from 'reselect'
import { getTopicId } from './topicGetters'

export const getSurveyQuestionsRaw = state => state.surveyQuestions

export const getLoadingSurveyQuestions = createSelector(
  getSurveyQuestionsRaw,
  q => q.loading
)

const getSurveyQuestionsList = createSelector(
  getSurveyQuestionsRaw,
  q => q.list
)

export const getSurveyQuestions = createSelector(
  getSurveyQuestionsList,
  questions => {
    return questions
  }
)

export default createStructuredSelector({
  questions: getSurveyQuestions,
  loading: getLoadingSurveyQuestions,
  topicId: getTopicId
})
