import React, { useEffect, useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { flow, includes } from 'lodash'
import Card from '@material-ui/core/Card'
import FontIcon from '@material-ui/core/Icon'
import Button from '@material-ui/core/Button'

import { connectTopicTheme } from '../../connectTheme'
import withTranslation from '../hocs/withTranslation'
import { useHistory, withRouter } from 'react-router'
import { IConversation, IStore } from '../../store/index'
import { inject } from 'mobx-react'
import { observer } from 'mobx-react'
import { createStyles, IconButton, makeStyles, Theme, Typography } from '@material-ui/core'
import { AuthContext, useAuth } from '../Providers'
import { useTranslation } from 'react-i18next'
import { palette } from '../../themes'

const TakeActionButton = observer(({ conversation, isHost, past }: { conversation: IConversation; isHost: boolean; past: boolean }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { user } = useAuth()
  const classes = useStyles()
  const user_actions = user?.userActions.filter((ua) => ua.finishedAt && ua.topicId === conversation.topicId)
  if (!isHost && past && conversation.hasActions && conversation.isAttending()) {
    const conversationPath = `/${conversation.slug}/conversations/${conversation.id}`
    const actionCount = conversation.actionCount && conversation.actionCount < (user_actions?.length || 0) ? conversation.actionCount : user_actions?.length || 0
    return (
      <div className='reg-bottom'>
        <Button
          onClick={(e) => {
            e.preventDefault()
            history.push(conversationPath)
          }}
          className='grey-btn'
          fullWidth
          classes={{ label: classes.root }}
        >
          {t('conversation.takeAction')}
        </Button>
        <Typography variant='caption'>
          ({actionCount}/{conversation.actionCount} actions completed)
        </Typography>
      </div>
    )
  } else return <></>
})

const RegisterConversation = observer(({ conversation, isHost, past }: { conversation: IConversation; isHost: boolean; past: boolean }) => {
  const history = useHistory()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [attending, setAttending] = useState(conversation.isAttending())

  if (!attending && !conversation.expired) {
    const conversationPath = `/${conversation.slug}/conversations/${conversation.id}`
    return (
      <div className='reg-bottom'>
        <Button
          onClick={(e) => {
            e.preventDefault()
            setLoading(true)
            conversation
              .attend()
              .then(() => {
                setLoading(false)
                setAttending(true)
              })
              .catch(() => setLoading(false))
          }}
          className='yellow-btn'
          fullWidth
          classes={{ label: classes.root }}
          disabled={loading}
        >
          {loading ? 'Registering...' : 'Register'}
        </Button>
      </div>
    )
  } else if (attending && !conversation.expired) {
    return (
      <div className='reg-bottom'>
        <Button
          onClick={(e) => {
            e.preventDefault()
            setLoading(true)
            conversation
              .unattend({}, {})
              ?.then(() => {
                setAttending(false)
                setLoading(false)
              })
              .catch(() => setLoading(false))
          }}
          className='grey-btn'
          fullWidth
          classes={{ label: classes.root }}
        >
          Cancel RSVP
        </Button>
      </div>
    )
  }
  return <></>
})

const VirtualLocationBlock = ({ conversation, isHost, past }: { conversation: IConversation; isHost: boolean; past: boolean }) => {
  const handleVirtualLink = (e) => {
    e.preventDefault()
    e.stopPropagation()
    window.open(conversation.userVirtualLink)
  }
  if (conversation.isAttending() && conversation.showVirtualLink) {
    return (
      <div className='row no-gutters reg-bottom'>
        <div className='col-xs-2 no-gutters center-xs'>
          <div onClick={handleVirtualLink}>
            <FontIcon className='fa fa-play-circle opacity-6 dark-grey-text' />
          </div>
        </div>
        <div className='col-xs-10 no-gutters'>
          <div className='header-18 no-margin'>
            <span onClick={handleVirtualLink} className='dark-grey-text hover-underline'>
              Meeting Link
            </span>
            <IconButton
              size='small'
              style={{ marginLeft: '1rem' }}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                navigator.clipboard.writeText(conversation.userVirtualLink)
              }}
            >
              <i className='fal fa-copy opacity-6'></i>
            </IconButton>
          </div>
        </div>
      </div>
    )
  }
  // else if (conversation.isVirtual) {
  //   return (
  //     <div className='row no-gutters reg-bottom'>
  //       <div className='col-xs-2 no-gutters center-xs'>
  //         <FontIcon className='fa fa-play-circle opacity-6' />
  //       </div>
  //       <div className='col-xs-10 no-gutters'>
  //         <div className='header-18 no-margin dark-grey-text'>
  //           <p className='flat-top flat-bottom'>{conversation.venueName}</p>
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }
  return <></>
}
const StoriesButton = ({ conversation, isHost, past }: { conversation: IConversation; isHost: boolean; past: boolean }) => {
  const history = useHistory()
  if (!isHost && past && conversation.isAttending()) {
    return (
      <Button
        onClick={(e) => {
          e.preventDefault()
          history.push(`/${conversation.slug}/conversations/${conversation.id}/story`)
        }}
        className='reg-bottom grey-btn'
        fullWidth
      >
        Share Reflection
      </Button>
    )
  }
  return <></>
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      whiteSpace: 'unset'
    }
  })
)
const ListCard = (props: FIXME) => {
  const {
    conversation: {
      inviteFriends,
      slug,
      title,
      id,
      city,
      stateLevel,
      dateLocally,
      locale,
      startsAtLocally,
      endsAtLocally,
      dayLocallyFull,
      timeZoneAbbr,
      formattedAddress,
      guestCount,
      maxGuests,
      icon,
      // venueName,
      host,
      hostGuideUrl,
      userVirtualLink,
      showVirtualLink,
      isVirtual,
      venueNameText
    },
    conversation: rawConvo,
    theme,
    isHost,
    past,
    showAttended = true,
    currentUserId,
    t,
    history,
    showDialog,
    changeLanguage
  } = props

  const conversation = rawConvo as IConversation
  useEffect(() => {
    changeLanguage(locale)
  }, [changeLanguage, locale])
  const venueName = venueNameText()

  const { user } = useContext(AuthContext)
  useEffect(() => {
    if (user) {
      user.shouldLoad('userActions', user.loadUserActions)
    }
  }, [user])
  const conversationPath = `/${slug}/conversations/${id}`
  const editConversationPath = `/${slug}/conversations/${id}/edit`

  const longLangs = ['sv_SE', 'es_ES', 'es_419', 'fr_FR', 'nl_NL', 'pt_BR']
  const labelStyle = includes(longLangs, locale) ? 'btn-long-label' : ''

  const Icon = () =>
    slug !== 'kai-and-korero' ? (
      <img className='img-responsive' src={icon.primary.url} alt='icon' />
    ) : (
      <img className='img-responsive' src='https://civicdinners-manual-storage.s3.amazonaws.com/topics/icons/civic-conversations-240.png' alt='icon' />
    )

  const renderGuestCount = isHost ? <p>{t('conversation.totalGuests', { guestCount, maxGuests })}</p> : <p />

  const opacity = past && 'opacity-7'

  // const userActions = user.userActions.filter((ua) => ua.actionId && topicActions.includes(ua.actionId))
  // const completedCount = userActions.filter((ua) => !!ua.finishedAt).length
  // const totalActionCount = topic.topicActions.length

  const handleHostGuide = (e) => {
    e.preventDefault()
    e.stopPropagation()
    window.open(hostGuideUrl.replace(/dl=0/gi, 'dl=1'))
  }

  const handleVirtualLink = (e) => {
    e.preventDefault()
    e.stopPropagation()
    window.open(userVirtualLink)
  }

  const handleEdit = (tab = '') => (e) => {
    e.preventDefault()
    e.stopPropagation()
    history.push(editConversationPath + tab)
  }

  const ManageGuestsButton = () => {
    if (isHost) {
      return (
        <Button onClick={handleEdit('/guests')} className={`${labelStyle} reg-bottom thin-right-xs grey-btn`} fullWidth>
          {t('conversation.manageGuests')}
        </Button>
      )
    } else return null
  }

  const ConversationDetailsButton = () => {
    if (isHost) {
      return (
        <Button onClick={handleEdit()} className={`${labelStyle} reg-bottom grey-btn`} fullWidth>
          {t('conversation.changeDetails')}
        </Button>
      )
    } else return null
  }

  const hostGuide = () => {
    if (isHost && hostGuideUrl) {
      return (
        <div className='row no-gutters'>
          <div className='col-xs-2 no-gutters center-xs'>
            <div onClick={handleVirtualLink}>
              <FontIcon className='far fa-book-open opacity-6 dark-grey-text cut-off-icon' />
            </div>
          </div>
          <div className='col-xs-10 no-gutters'>
            <div className='header-18 no-margin hover-underline'>
              <div onClick={handleHostGuide} className='dark-grey-text'>
                {t('conversation.hostGuide')}
              </div>
            </div>
          </div>
        </div>
      )
    } else return null
  }
  const MessageHostButton = () => {
    if (!isHost && !past) {
      return (
        <Button
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            showDialog('emailHost', { conversation })
          }}
          className='reg-bottom thin-right-xs grey-btn'
          startIcon={<FontIcon className='far fa-comment-dots opacity-8' />}
          fullWidth
        >
          {t('conversation.messageHost')}
        </Button>
      )
    } else return null
  }

  // const TakeActionButton = () => {
  //   if (!isHost && past && conversation.hasActions) {
  //     return (
  //       <Button
  //         onClick={(e) => {
  //           e.preventDefault()
  //           history.push(conversationPath)
  //         }}
  //         className='reg-bottom grey-btn'
  //         fullWidth
  //       >
  //         {t('conversation.takeAction')}

  //         <div>(1/2)</div>
  //       </Button>
  //     )
  //   } else return null
  // }
  // const StoriesButton = () => {
  //   if (!isHost && past) {
  //     return (
  //       <Button
  //         onClick={(e) => {
  //           e.preventDefault()
  //           history.push(`/${conversation.slug}/conversations/${conversation.id}/story`)
  //         }}
  //         className='reg-bottom grey-btn'
  //         fullWidth
  //       >
  //         Share Reflection
  //       </Button>
  //     )
  //   } else return null
  // }

  const NPSButton = () => {
    if (!isHost && past) {
      return (
        <Button
          onClick={(e) => {
            e.preventDefault()
            history.push(`/${conversation.slug}/conversations/${conversation.id}/nps`)
          }}
          className='reg-bottom grey-btn'
          fullWidth
        >
          Provide Feedback
        </Button>
      )
    } else return null
  }

  const InviteFriendsButton = () => {
    if (!isHost && !past) {
      return (
        <Button
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            showDialog('createdConversation', { conversation, step: 2 })
          }}
          className='reg-bottom grey-btn'
          fullWidth
        >
          {t('common.inviteFriends')}
        </Button>
      )
    } else return null
  }

  const Address = () => {
    if (past) {
      return <p className='flat-top flat-bottom'>{`${city} ${stateLevel ? `, ${stateLevel}` : null}`}</p>
    } else {
      return <p className='flat-top flat-bottom'>{formattedAddress ? formattedAddress : `${city} ${stateLevel ? `, ${stateLevel}` : null}`}</p>
    }
  }

  const locationBlock = () => {
    if (!isVirtual) {
      return (
        <div className='row no-gutters reg-bottom'>
          <div className='col-xs-2 no-gutters center-xs'>
            <FontIcon className='fal fa-map-marker-alt opacity-6' />
          </div>
          <div className='col-xs-10 no-gutters'>
            <div className='header-18 no-margin dark-grey-text'>
              <p className='flat-top flat-bottom'>{venueName}</p>
              <Address />
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <Link to={conversationPath}>
      <Card className='fat-bottom'>
        <div className={`row no-gutters black-text ${opacity} hover-opaque`}>
          <div
            className='col-xs-12 col-sm-3 no-gutters flex-center'
            style={{
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText
            }}
          >
            <div className='center-margin'>
              <div className='convo-icon-sm center-margin'>
                <Icon />
              </div>
              <p className='header-20 thin-left-padding thin-right-padding flat-top thin-bottom align-center'>{title}</p>
            </div>
          </div>

          <div className='col-xs-12 col-sm-6 middle-xs reg-top-padding reg-bottom-padding header-17 flat-right-padding'>
            <div className='row no-gutters'>
              <div className='col-xs-12 no-gutters'>
                <div className='row no-gutters reg-bottom'>
                  <div className='col-xs-2 no-gutters center-xs'>
                    <FontIcon className='far fa-calendar-day opacity-6' />
                  </div>
                  <div className='col-xs-10 no-gutters'>
                    <p className='header-18 no-margin dark-grey-text'>
                      {dayLocallyFull} {dateLocally}
                    </p>
                  </div>
                </div>
                <div className='row no-gutters reg-bottom '>
                  <div className='col-xs-2 no-gutters center-xs'>
                    <FontIcon className='fa fa-clock-o opacity-6' />
                  </div>
                  <div className='col-xs-10 no-gutters'>
                    <p className='header-18 no-margin dark-grey-text'>
                      {startsAtLocally} - {endsAtLocally} {timeZoneAbbr}
                    </p>
                  </div>
                </div>
                {locationBlock()}
                <VirtualLocationBlock conversation={conversation} isHost={isHost} past={past} />
                {hostGuide()}
                {showAttended && conversation.attended() && (
                  <div className='row no-gutters reg-bottom '>
                    <div className='col-xs-2 no-gutters center-xs'>
                      <FontIcon className='fas fa-check-circle' style={{ color: palette.green500 }} />
                    </div>
                    <div className='col-xs-10 no-gutters'>
                      <p className='header-18 no-margin dark-grey-text'>
                        <strong>Attended</strong>
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='col-xs-12 col-sm-3 center-xs around-xs'>
            {renderGuestCount}

            <ManageGuestsButton />

            <ConversationDetailsButton />

            <MessageHostButton />

            {!window.coco && inviteFriends && <InviteFriendsButton />}

            <TakeActionButton conversation={conversation} isHost={isHost} past={past} />
            <RegisterConversation conversation={conversation} isHost={isHost} past={past} />
            <StoriesButton conversation={conversation} isHost={isHost} past={past} />
            <NPSButton />
          </div>
        </div>
      </Card>
    </Link>
  )
}

export default flow(
  connectTopicTheme,
  withRouter,
  withTranslation,
  observer,
  inject<FIXME, FIXME>(
    ({
      mst: {
        auth: { user },
        dialogs: { showDialog }
      }
    }: {
      mst: IStore
    }) => {
      return {
        user,
        currentUserId: user?.id,
        showDialog
      }
    }
  )
)(ListCard)
