import React from 'react'
import VirtualLocationStep from './VirtualLocationStep'
import SynthLocationStep from './SynthLocationStep'
import HomeLocationStep from './HomeLocationStep'
import PublicLocationStep from './PublicLocationStep'
import { StepProps } from './NewSynthStepper'
import withTranslation, { WithTranslation } from '../../hocs/withTranslation'

const VirtualDetailsStep = (props: StepProps & WithTranslation) => {
  const { watch, synth } = props
  return (
    <>
      <div>
        {synth ? (
          <SynthLocationStep {...props} />
        ) : (
          <>
            {watch('venueType') === 'virtual' && <VirtualLocationStep {...props} />}
            {watch('venueType') === 'home' && <HomeLocationStep {...props} />}
            {['restaurant', 'other'].includes(watch('venueType')) && <PublicLocationStep {...props} />}
          </>
        )}
      </div>
    </>
  )
}

export default withTranslation(VirtualDetailsStep)
