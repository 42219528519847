import React from 'react'
import { observer } from 'mobx-react'
import { useParamPartner } from '../../../Providers'
import LoadingAnimation from '../../../shared/LoadingAnimation'
import PrivateLabelForm from './PrivateLabelForm'

interface Props {}

export const PrivateLabel = observer((props: Props) => {
  const { partner } = useParamPartner()

  if (!partner) {
    return <LoadingAnimation />
  }

  return (
    <div>
      <PrivateLabelForm />
    </div>
  )
})

export default PrivateLabel
