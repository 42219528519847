import * as types from '../actions/actionTypes'
import { assign } from 'lodash'
import initialState from './initialState'

export const conversationInvitationRequests = (state = initialState.conversationInvitationRequests, action) => {
  switch (action.type) {
    case types.LOAD_USER_INVITATION_REQUESTS_SUCCESS:
      return assign({}, state, {
        loading: false,
        error: null,
        list: assign({}, state.list, action.conversationInvitationRequest)
      })

    case types.LOAD_USER_INVITATION_REQUESTS_FAILED:
      return assign({}, state, {
        loading: false,
        error: action.data
      })

    case types.USER_INVITATION_REQUESTS_LOADING:
      return assign({}, state, {
        loading: true,
        error: null
      })

    case types.REMOVE_USER_INVITATION_REQUESTS:
      return assign({}, state, {
        loading: false,
        error: null,
        list: {}
      })

    default:
      return state
  }
}

export default conversationInvitationRequests
