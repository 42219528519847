import { types, Instance, getRoot, getSnapshot } from 'mobx-state-tree'
import AuthApi from '../../../api/AuthApi'
import User, { IUser } from '../User'
import { isNull, isUndefined, isEmpty, pick, mapKeys } from 'lodash'
import publicIP from 'public-ip'
import UsersApi from '../../../api/UsersApi'
import { Alertable } from '../helpers/Alertable'
import { assignFullStoryId, loggedInBootIntercom } from '../../../actions/sessionActions'
import { Dialogable } from '../helpers/Dialogable'
import { LoadableStatus } from '../helpers/LoadableStatus'
import { AfterLoginActionProcessor } from '../helpers/AfterLoginActionable'
import { removeNullStringValue } from '../utils'
import RegionalPartnersApi from '../../../api/RegionalPartnersApi'
import { getRegionalRecordByArea } from '../../../regionalRecords'
import appAnalytics from '../../../analytics'
import { capitalize } from '../../../decorators/textTools'
import FacebookUsersApi from '../../../api/FacebookUsersApi'
import GoogleUsersApi from '../../../api/GoogleUsersApi'
import i18next from 'i18next'
import PasswordResetsApi from '../../../api/PasswordResetsApi'
import { RootAccessable } from '../helpers/RootAccessable'
import { MembershipUser } from '../User/MembershipUser'
import surveyAnswersApi from '../../../api/SurveyAnswersApi'
import { NpsAnswer } from '../NpsAnswers'
import Likable from './Likable'
import FavoriteApi from '../../../api/FavoriteApi'

const intercomAppId = () => (window.coco ? process.env.REACT_APP_COCO_INTERCOM_APP_ID : process.env.REACT_APP_INTERCOM_APP_ID)

export const AuthUser = types
  .compose(
    'AuthUser',
    User,
    RootAccessable,
    types.model({
      membershipUsers: types.array(MembershipUser),
      likes: types.array(Likable),
      connections: types.array(types.number),
      connectionsRequested: types.array(types.number),
      nps: types.array(NpsAnswer)
    })
  )
  .actions((self) => ({
    setNps: (val) => {
      self.nps.replace(val)
    },
    addToUserConnectionRequested: (val) => {
      self.connectionsRequested.push(val)
    }
  }))
  .actions((self) => ({
    loadMyConversations: () => {
      const conversations = self.getConversations()
      conversations.shouldLoad('myConversationsList', conversations.loadMyConversations)
    },
    loadNpsAnswers: () => {
      const listName = 'npsAnswers'
      if (self.isLoading(listName)) {
        return
      }
      return surveyAnswersApi.getAll().then(({ response: { ok }, json }) => {
        self.stopLoading(listName)
        if (ok) {
          self.setNps(json)
        }
      })
    }
  }))
export const Auth = types
  .compose(
    'Auth',
    Alertable,
    Dialogable,
    LoadableStatus,
    AfterLoginActionProcessor,
    types.model({
      user: types.maybe(AuthUser),
      ip: types.maybe(types.string),
      latitude: types.maybe(types.string),
      longitude: types.maybe(types.string),
      redirectOnSuccess: types.maybe(types.string),
      loaded: types.optional(types.boolean, false),
      defaultSlug: types.maybe(types.string)
    })
  )

  .actions((self) => ({
    softReset: () => {
      self.user = undefined
      self.redirectOnSuccess = undefined
      self.defaultSlug = undefined
    },
    setDefaultSlug: (val: string) => {
      self.defaultSlug = val
    }
  }))

  .actions((self) => ({
    setIp(ip: string) {
      self.ip = ip
    },
    setCoords(latitude: string, longitude: string) {
      self.latitude = latitude
      self.longitude = longitude
    },
    setRedirectOnSuccess: (val?: string) => {
      self.redirectOnSuccess = val
    },
    loadDefaultPartner: (): Promise<string> => {
      if (self.defaultSlug) {
        return Promise.resolve(self.defaultSlug)
      }

      return RegionalPartnersApi.getAll(window.coco ? 2 : 1)
        .then(({ response: { ok }, json }) => {
          if (ok) {
            const myPartner = getRegionalRecordByArea(json, JSON.stringify([self.latitude, self.longitude]))
            self.setDefaultSlug(myPartner?.slug)
            return myPartner?.slug
          }
          return 'uk'
        })
        .catch(() => 'uk')
    }
  }))

  .actions((self) => ({
    setUser(user: any) {
      user && removeNullStringValue(user)

      self.user = {
        ...user,
        coordinates: {
          latitude: user.coordinates[0],
          longitude: user.coordinates[1]
        }
      }
      self.loaded = true
      if (self.user) {
        self.user.loadUserConversations()

        self.user.loadUserInvitationRequests()

        appAnalytics.set(userAnalyticsSnapshot(self.user))
        if (self.user && self.user.trackOnChurnzero()) {
          window.churnZero.set({
            accountExternalId: self.user.associatedPartners[0]?.id,
            contactExternalId: self.user.id
          })

          window.churnZero.track('Total User Sessions Count')
        }

        self.afterLoginActionProcess()
        if (localStorage.signup === 'true') {
          localStorage.removeItem('signup')
          self.showDialog('signup2')
        }
      }
    },
    logOutUser(message?: string) {
      localStorage.removeItem('jwt')
      self.user = undefined

      message && self.showAlert(message)
      const root: any = getRoot(self)
      root?.reset()
    },
    reduxLogOutUser() {
      if (!localStorage.main_jwt) {
        const root: any = getRoot(self)
        appAnalytics.event({
          category: 'User',
          action: 'Logged Out'
        })
        self.user = undefined
        root?.reset()
      }
    },
    getLocationFromIP() {
      self.startLoading('ip')
      return publicIP.v4().then((ip) => {
        self.stopLoading('ip')
        if (isNull(ip) || isUndefined(ip) || isEmpty(ip)) return
        self.startLoading('coords')
        return UsersApi.getLocationFromIP(ip).then(({ response, json }) => {
          self.stopLoading('coords')

          if (response && response?.ok) {
            const { latitude, longitude } = json
            if (latitude && latitude) {
              self.setCoords(latitude.toString(), longitude.toString())
            }
          }
        })
      })
    }
  }))

  .actions((self) => ({
    userInitProcess: () => {
      if (self.user) {
        assignFullStoryId(self.user)
        loggedInBootIntercom(self.user)
        self.user.loadUserInvitationRequests()

        if (!self.user.phoneNumber || !self.user.placeId) self.showDialog('signupStepTwo')
      }
    },
    reduxSetUser: (user) => {
      self.setUser(user)
      if (self.user) {
        self.user.loadUserConversations()
        self.user.loadUserInvitationRequests()
      }
    }
  }))

  .actions((self) => ({
    reduxLogin: (user) => {
      self.reduxSetUser(user)
      if (!window.coco && self.user && (!self.user.phoneNumber || !self.user.placeId)) self.showDialog('signupStepTwo')
    },

    logInUser(credentials: any, data: any) {
      return AuthApi.login(credentials, data).then(({ error, user, message, auth_token }) => {
        if (auth_token) {
          localStorage.setItem('jwt', auth_token)
          self.setUser(user)
          self.userInitProcess()
          message && self.showAlert(message)
        } else {
          error && self.showAlert('Log In Unsuccessful')
          throw error
        }
      })
    },
    afterCreate: () => {
      if (!localStorage.jwt) {
        self.loaded = true
        window.Intercom &&
          window.Intercom('boot', {
            app_id: intercomAppId()
          })
      }
    },
    isMasquerading: () => {
      return !!localStorage.main_jwt
    }
  }))

  .actions((self) => ({
    handleLoginSuccess: ({ auth_token, user }) => {
      function camelize(object) {
        return mapKeys(object, camelizeKey)
      }
      function camelizeKey(value, key) {
        return key.replace(/_(\w)/g, (a, b) => b.toUpperCase())
      }

      const camelisedUser = camelize(user) // fb is snake_case

      localStorage.setItem('jwt', auth_token)
      self.setUser(camelisedUser)
      self.userInitProcess()
    },
    addToLikes: (like) => {
      self.user?.likes.push(like)
    },
    removeFromLikes: (id) => {
      self.user?.likes.replace(self.user?.likes.filter((l) => l.id !== id))
    }
  }))

  .actions((self) => ({
    loginWithFacebook: (responseData) => {
      return FacebookUsersApi.create(responseData)
        .then((response) => {
          if (response.auth_token) {
            self.handleLoginSuccess(response)
            response?.message && self.showAlert(response.message)
            if (response.action === 'login') {
              // loggedInBootIntercom(camelize(response.user))
            } else if (response.action === 'signup') {
              // signedUpBootIntercom(camelize(response.user))
            }

            // dispatch(showAlertMessage(response))
          }
        })
        .catch((error) => {
          throw error
        })
    },
    loginWithGoogle: (responseData) => {
      return GoogleUsersApi.create(responseData)
        .then((response) => {
          if (response.auth_token) {
            self.handleLoginSuccess(response)
            response?.message && self.showAlert(response.message)
            if (response.action === 'login') {
              // loggedInBootIntercom(camelize(response.user))
            } else if (response.action === 'signup') {
              // signedUpBootIntercom(camelize(response.user))
            }

            // dispatch(showAlertMessage(response))
          }
        })
        .catch((error) => {
          throw error
        })
    },
    masquerade: (id) => {
      return UsersApi.masquerade(id).then(({ response: { ok, status, statusText }, json }) => {
        if (ok) {
          const { message, user, auth_token } = json
          const main_jwt = localStorage.jwt

          localStorage.setItem('main_jwt', main_jwt)
          localStorage.setItem('jwt', auth_token)
          const root: FIXME = getRoot(self)
          root.softReset()
          self.reduxLogin(user)
          self.showAlert(message)
          return user
        }
      })
    },
    signupUser: (formData) => {
      return UsersApi.create(formData, { locale: i18next.language }).then(({ response: { ok, status, statusText }, json }) => {
        if (ok) {
          self.handleLoginSuccess(json)
        }
      })
    },
    passwordReset: (data) => {
      return PasswordResetsApi.createPasswordReset(data).then((resp) => {
        const { json } = resp
        if (json.error) {
          self.showAlert(Object.values(json.error).join(', '))
        } else if (json.errors) {
          self.showAlert('Error')
        } else {
          return json
        }
      })
    },
    favorite: (likable_id, likable_type) => {
      return FavoriteApi.create({ likable_id, likable_type }).then(({ response: { ok, status, statusText }, json }) => {
        if (ok) {
          self.showAlert('Favorited')
          self.addToLikes(json)
        }
      })
    },
    unfavorite: (id) => {
      return FavoriteApi.destroy(id).then(({ response: { ok, status, statusText }, json }) => {
        if (ok) {
          self.showAlert('Unfavorited')
          self.removeFromLikes(id)
        }
      })
    }
  }))

export interface IAuthUser extends Instance<typeof AuthUser> {}
export interface IAuth extends Instance<typeof Auth> {}
export default Auth

const userAnalyticsSnapshot = (user: IUser) => ({
  userId: user.id,
  dimension1: user.role,
  dimension2: capitalize(window.siteTitle)
})
