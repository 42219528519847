import en_US from './en_US'
import de_DE from './de_DE'
import es_ES from './es_ES'
import es_419 from './es_419'
import fr_FR from './fr_FR'
import it_IT from './it_IT'
import nl_NL from './nl_NL'
import pl_PL from './pl_PL'
import pt_BR from './pt_BR'
import sv_SE from './sv_SE'
import tr_TR from './tr_TR'

export default {
  en_US: {
    translation: en_US
  },
  de_DE: {
    translation: de_DE
  },
  es_ES: {
    translation: es_ES
  },
  es_419: {
    translation: es_419
  },
  fr_FR: {
    translation: fr_FR
  },
  it_IT: {
    translation: it_IT
  },
  nl_NL: {
    translation: nl_NL
  },
  pl_PL: {
    translation: pl_PL
  },
  pt_BR: {
    translation: pt_BR
  },
  sv_SE: {
    translation: sv_SE
  },
  tr_TR: {
    translation: tr_TR
  }
}
