import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core'

import CalendarDropdown from '../../shared/CalendarDropdown'
import { observer } from 'mobx-react'
import ShareConversation from '../partials/ShareConversation'
import { useTranslation } from 'react-i18next'

const Success = (props: any) => {
  const { conversation } = props
  const { t } = useTranslation()

  if (!conversation) return <></>

  const classes = Styles()

  return (
    <div>
      <section className='pane thin-reg-pane'>
        <div className='row'>
          <div className='col-xs-12 center-xs'>
            <p className='flat-top' dangerouslySetInnerHTML={{ __html: t('conversation.yourAttending') }} />
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12 center-xs'>
            <h1 className='header-28 blue-text flat-bottom'>{conversation.title}</h1>
          </div>
        </div>

        <div className='row reg-top'>
          <div className='row center-xs'>
            <CalendarDropdown conversation={conversation} />
          </div>
        </div>
      </section>

      <section className='pane minimal-grey-pane fat-top-padding big-bottom-padding'>
        <div className='row'>
          <div className='col-xs-12 fat-bottom center-xs'>
            <h2 className={classes.header}>{t('socialMedia.shareEvent')}</h2>
          </div>
          <div className='row no-gutters reg-bottom'>
            <div className='no-gutters'>
              <ShareConversation
                conversation={conversation}
                style={{ color: '#ff0000' }}
                gridSizes={{ xs: 12 }}
                color='#737374'
                labelColor='#212122'
                align='left'
                longLabel={true}
                buttonContainerStyle={{ marginLeft: '7.5em' }}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
const Styles = makeStyles((theme) =>
  createStyles({
    header: {
      fontFamily: 'Avenir',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '22px',
      lineHeight: '30px',
      marginBottom: 0
    }
  })
)
export default observer(Success)
