import React, { useContext } from 'react'

import ConversationsList from './ConversationsList'
import HostedConversationsList from './HostedConversationsList'
import PastConversationsList from './PastConversationsList'
import { connectAdminTheme } from '../../../connectTheme'
import { flow } from 'lodash'
import { AuthContext } from '../../Providers/AuthProvider'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { createStyles, Drawer, Icon, ListItemIcon, makeStyles, MenuItem, MenuList, Theme, Toolbar, Typography } from '@material-ui/core'
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt'
import ChatIcon from '@material-ui/icons/Chat'
import { Link } from 'react-router-dom'
import DrawerMenu from './DrawerMenu'

const MyConversations = (props) => {
  const history = useHistory()
  const { t } = useTranslation()
  const { user, loaded } = useContext(AuthContext)
  const classes = useStyles()

  if (loaded && !user) {
    history.push('/login')
  }
  return (
    <div className={classes.root}>
      <DrawerMenu />
      <main className={`${classes.content} quite-big-bottom-padding`}>
        <div className='col-xs-12 start-xs form-xl fat-top no-gutters'>
          <div style={{ margin: 'auto auto', padding: '0 1rem' }}>
            <div className='header-32 bold fat-bottom'>{t('nav.myConversations')}</div>

            <h2 className='dividing-header header-14 xtra-fat-bottom font-normal'>
              <span>{t('user.upcomingConversations')}</span>
            </h2>

            <HostedConversationsList isHost={true} />

            <ConversationsList isHost={false} />

            <PastConversationsList isHost={false} />
          </div>
        </div>
      </main>
    </div>
  )
}

export default flow(connectAdminTheme)(MyConversations)
const drawerWidth = 250
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    content: {
      flexGrow: 1,
      padding: '0 1em 1em 2em',
      margin: 'auto auto',
      backgroundColor: '#f5f5f5',
      minHeight: 'calc(100vh - 7em) !important',
      maxWidth: '100vw',
      [theme.breakpoints.down('sm')]: {
        padding: '0'
      }
    }
  })
)
