import { getAreaOfPolygon, isPointInPolygon } from 'geolib/es/index'
import { assign, first, has, includes, isEmpty, map, orderBy, pickBy, size, filter, sortBy } from 'lodash'
import coordinateDecorator from './decorators/coordinateDecorator'
import { history } from './components/browserHistory'

const redirectConvoSlugs = [
  'female-founders',
  'resilient-smbs',
  'socially-responsible-smbs',
  'connected-smbs',
  'funding-female-founders',
  'connected-enterprises',
  'female-entrepreneurs'
]

export const redirectableConvo = (slug) => includes(redirectConvoSlugs, slug)

export const convoRedirects = (prevProps, props) => redirectableConvo(props.slug) && redirects(prevProps, props)

export const redirects = (prevProps, props) => {
  if (!process.env.REACT_APP_IP_STACK_KEY) return true

  const {
    history: { location },
    userCoords
  } = props

  if (location.state && has(location.state, 'forward')) {
    history.replace({ ...location, search: '' })
    return true
  }

  return userCoords !== prevProps.userCoords
}

export const getRegionalRecordByArea = (results, userCoords) => {
  if (isEmpty(results)) return null // no regional results

  results = userIsInside(results, userCoords)

  if (isEmpty(results)) return null

  if (size(results) === 1) return first(results) // exactly one result

  results = map(results, (r) => assign({}, r, { area: getAreaOfPolygon(r.regionCoordinates) })) // get areas of polygons
  return first(orderBy(results, 'area')) // return record with smallest area
}

export const getRegionalRecordBySlug = (results, userCoords, slug) => {
  if (isEmpty(results)) return null // no regional results

  results = userIsInside(results, userCoords)

  if (isEmpty(results)) return null

  results = filter(results, (r) => includes(r.slug, slug)) // filter results of slug included in result slugs
  if (size(results) === 1) return first(results) // exactly one result

  return first(sortBy(results, (r) => r.slug.length)) // if more than one, return result with shortest slug
}

const userIsInside = (results, userCoords) => {
  userCoords = coordinateDecorator(JSON.parse(userCoords))
  results = map(results, (p) =>
    assign({}, p, {
      regionCoordinates: coordinateDecorator(p.regionCoordinates)
    })
  ) // decorate results coords
  return map(pickBy(results, (p) => isPointInPolygon(userCoords, p.regionCoordinates))) // results where userCoords are inside polygon
}
