import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'

import withTranslation from '../../hocs/withTranslation'
import Dialog from '../../DialogWrapper'
import { IStore } from '../../../store/models'
import EmailInvite from './EmailInvite'

const EmailReinviteDialog = (props) => {
  const { dialog, showDialog, changeLanguage, currentUser, conversation } = props
  const { skipProfileImage } = dialog.data

  useEffect(() => {
    conversation && changeLanguage(conversation.locale)
  }, [conversation, changeLanguage])

  const afterClose = () => {
    if (!skipProfileImage && conversation && !currentUser.hasProfileImage) {
      showDialog('profileImage')
    }
  }

  if (!conversation) return null

  return (
    <Dialog dialog={dialog} name='emailReinvite' afterClose={afterClose} type='noPadding'>
      <EmailInvite {...props} dialog={dialog} conversation={conversation} reinvite={true} />
    </Dialog>
  )
}

export default inject<FIXME, FIXME>(
  (
    {
      mst: {
        auth: { user },
        dialogs: { showDialog, closeDialog },
        conversations
      }
    }: {
      mst: IStore
    },
    ownProps
  ) => {
    const conversationId = ownProps.dialog.data.conversationId
    const conversation = conversations.list.find((d) => d.id === conversationId)
    if (!conversation && !conversations.loadingIds.includes(conversationId)) {
      conversations.getConversation(conversationId)
    }
    return {
      currentUser: user,
      conversation,
      showDialog,
      closeDialog
    }
  }
)(observer(withTranslation(EmailReinviteDialog)))
