import React, { useContext, useState } from 'react'
import Dialog from '../DialogWrapper'
import { flow, map, range, pick, pickBy, identity } from 'lodash'
import withTranslation, { WithTranslation } from '../hocs/withTranslation'
import { withRouter } from 'react-router'
import { IDialog } from '../../store/models/Dialog'
import { TextFieldControl, NpsControlGroup } from '../utils'
import { required } from '../validations/fieldLevelValidations'
import { useForm } from 'react-hook-form'
import { Button, createStyles, DialogActions, Grid, makeStyles, Typography } from '@material-ui/core'
import palette from '../../themes/palette'
import { inject, observer } from 'mobx-react'
import { IStore } from '../../store'
interface InjectedProps {
  saveNps(val): Promise<any>
  attendeeSurveyLink?: string
  showDialog(val: any, data: any)
}
interface Props {
  dialog: IDialog
  history: any
}

const Nps2Dialog = ({ saveNps, dialog, t, i18n, history, showDialog }: Props & InjectedProps & WithTranslation) => {
  const { handleSubmit, errors, formState, control } = useForm({ mode: 'onBlur', shouldUnregister: false })
  const [saving, setSaving] = useState(false)
  const { score, attendeeSurveyLink } = dialog.data as any

  const saveSingleNps = (nps_question_id, npsScore) => {
    return saveNps({ ...dialog.data, nps_question_id, score: npsScore })
  }

  const nextStep = () => {
    showDialog('nps3', dialog.data)
    setSaving(false)
    dialog.closeDialog()
  }

  const handleSave = handleSubmit(({ reason }: any, e) => {
    if (reason) {
      setSaving(true)
      saveNps({ ...dialog.data, nps_question_id: 6, score: 0, reason })
        .then(nextStep)
        .catch(() => {
          setSaving(false)
        })
    } else {
      nextStep()
    }
  })

  const npsOptions = () =>
    map(range(5), (i) => {
      return { label: i + 1, value: (i + 1).toString() }
    })

  const npsOptionsData = npsOptions()

  const classes = Styles()

  const surveyOptions = [
    { nps_question_id: 2, label: 'I felt my voice was heard and valued' },
    { nps_question_id: 3, label: 'I feel a greater sense of empathy with others' },
    { nps_question_id: 4, label: 'I now have a deeper understanding of this topic' },
    { nps_question_id: 5, label: 'I feel more committed to take action on this topic' }
  ]

  return (
    <Dialog dialog={dialog} name='nps2' type={'wide'} closeIcon={true}>
      <form onSubmit={handleSave}>
        <section className={classes.headerContainer}>
          <Typography variant='subtitle1'>{t('nps.dialogHeader')}</Typography>
        </section>

        <section className={classes.bodyContainer}>
          <Typography variant='body1'>
            After attending this conversation, how much do you agree with the following statements on a scale of 1 - Strongly Disagree to 5 - Strongly Agree?
          </Typography>
        </section>
        <section className={classes.actionContainer}>
          {surveyOptions.map((opt, index) => (
            <Grid container key={opt.nps_question_id} spacing={1}>
              <Grid item xs={1}>
                {index + 1}.
              </Grid>
              <Grid item xs={5}>
                {opt.label}
              </Grid>
              <Grid item xs={6}>
                <NpsControlGroup
                  defaultValue={score}
                  name={`score-${index}`}
                  control={control}
                  required
                  error={errors && errors.score}
                  rules={{ validate: required }}
                  options={npsOptionsData}
                  onChange={(val) => saveSingleNps(opt.nps_question_id, val)}
                />
              </Grid>
            </Grid>
          ))}

          <Grid container className='reg-top'>
            <Grid item xs={12}>
              What’s one idea, key takeaway or action you plan to take as a result of this conversation?
            </Grid>
            <Grid item xs={12}>
              <TextFieldControl name='reason' control={control} multiline={true} rows={3} />
            </Grid>
          </Grid>
        </section>

        <DialogActions>
          <Button type='submit' disabled={saving} variant='contained' color='primary'>
            {t('common.next')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

const Styles = makeStyles((theme) =>
  createStyles({
    root: {},
    headerContainer: {
      padding: '.5rem',
      textAlign: 'center'
    },
    label: {
      marginTop: '1rem'
    },
    bodyContainer: {
      textAlign: 'center',
      padding: '1rem .5rem'
    },
    nps: {
      padding: '1rem 1rem 0',
      position: 'relative'
    },
    npsKey1: {
      position: 'absolute',
      left: '2.4rem',
      bottom: '-1rem'
    },
    npsKey2: {
      position: 'absolute',
      right: '2.4rem',
      bottom: '-1rem'
    },
    actionContainer: { backgroundColor: palette.grey100, padding: '2rem' },
    actionContainer2: { padding: '3rem', textAlign: 'center' },
    bodyContainer2: { backgroundColor: palette.grey100, textAlign: 'center', padding: '2rem 6rem' },
    imgContainer: { width: '56px', textAlign: 'center', margin: '0 auto 1rem' }
  })
)

export default flow(
  observer,
  inject<InjectedProps, null>(
    ({
      mst: {
        nps: { saveNps },
        dialogs: { showDialog }
      }
    }: {
      mst: IStore
    }) => {
      return {
        saveNps,
        showDialog
      }
    }
  ),
  withTranslation
)(Nps2Dialog)
