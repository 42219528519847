import React, { Component, useCallback, useEffect, useMemo, useState } from 'react'
import { isUndefined, debounce, flow } from 'lodash'
import DOMPurify from 'dompurify'

import { connectTopicTheme } from '../../../connectTheme'
import PageLoadingAnimation from '../../shared/PageLoadingAnimation'
import InfiniteLocationList from '../../shared/InfiniteLocationListMst'
import StatsModule from '../StatsModule'
import withTranslation from '../../hocs/withTranslation'
import CustomMetaTags from '../../shared/CustomMetaTags'
import queryString from 'query-string'
import { inject, observer } from 'mobx-react'
import { IStore, ITrack } from '../../../store/models/index'
import Sections from '../../sections/Sections'
import MembershipConversations from './MembershipConversations'
import { MembershipManager } from '../partials/MembershipManager'
import { useAuth, useParamPartner, useUserTracks } from '../../Providers'
import { AppBar, Button, createStyles, makeStyles, Paper, Theme, Toolbar, Typography, Link as MuiLink, Backdrop, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import PartnerTracksProvider, { usePartnerTracks } from '../../Providers/PartnerTracksProvider'
import { useHistory, useParams } from 'react-router'
import MembershipHsRedirect from './MembershipHsRedirect'
import FavButton from '../../users/MyConversations/FavButton'

// import { MembershipHsRedirect } from '../users/Memberships'
class PartnerPage extends Component<FIXME, FIXME> {
  state = { inviteToken: null }
  _bg_ref

  componentDidUpdate(prevProps) {
    this.setBackgroundImage()

    if (this.props.partner && this.props.partner !== prevProps.partner && this.props.partner.locale) {
      this.props.changeLanguage(this.props.partner.locale)
    }
  }

  setBackgroundImage = () => {
    const { partner } = this.props
    if (!partner) return null
    const { backgroundImage } = partner
    const { primary } = backgroundImage || {}
    if (!backgroundImage || !primary || !primary.url) return null

    if (this._bg_ref) {
      this._bg_ref.style.backgroundImage = `url(${primary.url || ''})`
    }
  }

  showSignup = debounce(() => {
    const { currentUserId, showDialog } = this.props
    if (currentUserId === null) {
      showDialog('signup', { inviteLink: true })
    }
  }, 500)

  render() {
    const { partnerLoading, topicsLoading, conversationsLoading, error, partner, t, topics, conversations, featuredConversationsLoading } = this.props

    if (error) {
      return <div className='alert alert-danger'>{t('partners.error', { message: error.statusText })} </div>
    }

    if (partnerLoading || isUndefined(partner)) return <PageLoadingAnimation />

    const { name, about, slug, website, facebookLink, twitterLink, instagramLink, profileImage } = partner

    const EditStyle = { marginLeft: 'calc(100% - 88px)' }

    const isTlc = slug === 'thelovablecity'

    const ManualProfileImage = () => {
      switch (slug) {
        case 'ARC':
          return <img className='img-responsive' alt='partner logo' src='https://civicdinners-manual-storage.s3.amazonaws.com/arc_logo_480.jpg' />
        case 'NetImpact':
          return <img className='img-responsive' alt='partner logo' src='https://civicdinners-manual-storage.s3.amazonaws.com/netimpact-profile-image.jpg' />
        case 'handsonatlanta':
          return <img className='img-responsive' alt='partner logo' src='https://civicdinners-manual-storage.s3.amazonaws.com/partners/handsonatlanta/profile_image.png' />
        case 'DecaturGA':
          return <img className='img-responsive' alt='partner logo' src='https://civicdinners-manual-storage.s3.amazonaws.com/partners/DecaturGA/profile_image.png' />
        case 'GA-DFCS':
          return <img className='img-responsive' alt='partner logo' src='https://civicdinners-manual-storage.s3.amazonaws.com/partners/GA-DFCS/profile_image.png' />
        case 'BCT':
          return <img className='img-responsive' alt='partner logo' src='https://civicdinners-manual-storage.s3.amazonaws.com/partners/BCT/profile_image.png' />
        case 'SDDCELL':
          return <img className='img-responsive' alt='partner logo' src='https://civicdinners-manual-storage.s3.amazonaws.com/partners/SDDCELL/profile_image.png' />
        case 'ConsciousCap':
          return <img className='img-responsive' alt='partner logo' src='https://civicdinners-manual-storage.s3.amazonaws.com/partners/ConsciousCap/profile-image_2.jpg' />
        case 'Miami-DadeTPO':
          return <img className='img-responsive' alt='partner logo' src='https://civicdinners-manual-storage.s3.amazonaws.com/partners/Miami-DadeTPO/profile-400.jpg' />
        case 'up-to-us':
          return <img className='img-responsive' alt='partner logo' src='https://civicdinners-manual-storage.s3.amazonaws.com/partners/up-to-us/profile_image.jpg' />
        case 'sage':
          return <img className='img-responsive' alt='partner logo' src='https://civicdinners-manual-storage.s3.amazonaws.com/partners/sage/profile-image.png' />
        case 'NAMI-GA':
          return <img className='img-responsive' alt='partner logo' src='https://civicdinners-manual-storage.s3.amazonaws.com/partners/NAMI-GA/profile_image.jpg' />

        default:
          return null
      }
    }

    const ProfileImage = () => {
      if (profileImage.primary.url === null) return <ManualProfileImage />
      return <img src={profileImage.primary.url} className='img-responsive' alt='partner logo' />
    }

    return (
      <div className={`${slug} partners-show`}>
        <PartnerTracksProvider>
          <CustomMetaTags record={partner} />
          <PartnerFav />
          <section
            className='pane hero-pane yellow-pane no-gutters'
            id='background'
            ref={(ref) => {
              this._bg_ref = ref
              this.setBackgroundImage()
            }}
          >
            <div className='row pane thin-pane-sm skinny-pane-xs pane-darkened infinity-sides name-pane'>
              <div className='col-xs-12 col-sm-4 xs-hide sm-show relative'>
                <div className='partner-profile-image absolute'>
                  <ProfileImage />
                </div>
              </div>
              <div className='col-xs-12 col-sm-8'>
                <h1 className='header-28 hero-header flat-bottom'>{name}</h1>
              </div>
            </div>
          </section>

          <MembershipManager />

          <PartnerContent />
        </PartnerTracksProvider>
      </div>
    )
  }
}

const PartnerFav = observer(() => {
  const { partner } = useParamPartner()
  const { tracks, reloadTracks, trackLoading, trackLoaded } = usePartnerTracks()
  const { user } = useAuth()
  // const classes = useStyles()
  const [track, setTrack] = useState<ITrack | undefined>(undefined)
  const trackFromList = tracks?.find((tr) => tr.partnerId === partner?.id)
  useEffect(() => {
    partner && !trackLoading && !trackLoaded && reloadTracks(partner.id)
  }, [trackLoading, trackLoaded, partner, reloadTracks])
  useEffect(() => {
    partner && trackFromList !== track && setTrack(trackFromList)
  }, [track, trackFromList, setTrack, partner])
  const rawmembershipUsers = user?.membershipUsers
  const memberUsers: FIXME[] = useMemo(() => rawmembershipUsers || [], [rawmembershipUsers])
  const userTrackIds = useMemo(() => (memberUsers && memberUsers?.map((mu) => mu.trackId)) || [], [memberUsers])
  const isTrackMember = useMemo(() => userTrackIds.includes(track?.id), [track, userTrackIds])
  if (!partner || !track) {
    return <></>
  }

  return (
    <div style={{ float: 'right' }}>
      <FavButton likableId={track.id} likableType='Track' />
    </div>
  )
})

const PartnerContent = observer(() => {
  const { partner } = useParamPartner()
  // const EditStyle = { marginLeft: 'calc(100% - 88px)' }
  const { t } = useTranslation()
  const { tracks, reloadTracks, trackLoading, trackLoaded } = usePartnerTracks()
  const { user } = useAuth()
  // const classes = useStyles()
  const [track, setTrack] = useState<ITrack | undefined>(undefined)
  const trackFromList = tracks?.find((tr) => tr.partnerId === partner?.id)
  useEffect(() => {
    partner && !trackLoading && !trackLoaded && reloadTracks(partner.id)
  }, [trackLoading, trackLoaded, partner, reloadTracks])
  useEffect(() => {
    partner && trackFromList !== track && setTrack(trackFromList)
  }, [track, trackFromList, setTrack, partner])
  const rawmembershipUsers = user?.membershipUsers
  const memberUsers: FIXME[] = useMemo(() => rawmembershipUsers || [], [rawmembershipUsers])
  const userTrackIds = useMemo(() => (memberUsers && memberUsers?.map((mu) => mu.trackId)) || [], [memberUsers])
  const isTrackMember = useMemo(() => userTrackIds.includes(track?.id), [track, userTrackIds])
  if (!partner || !track) {
    return <></>
  }

  // const topicsLoading = partner?.loadingIds.includes('topics')
  // const topics = partner && Array.from(partner.topics.filter((c) => !c.hidden))
  // const featuredConversationsLoading = partner && partner.loadingIds.includes('featuredConversationsList')
  // const conversations = partner && Array.from(partner.conversations)
  // const conversationsLoading = partner && partner.loadingIds.includes('conversations')
  // const isTlc = partner.slug === 'thelovablecity'
  const hideContent = !isTrackMember && !user?.isSuperAdmin()
  const { about, website, facebookLink, twitterLink, instagramLink } = partner as FIXME

  const FacebookLink = () => {
    if (!facebookLink || facebookLink === 'null') return null
    return (
      <div className='col-xs-2 col-xs-offset-2 col-sm-4 col-sm-offset-0 align-right'>
        <a href={facebookLink} target='_blank' rel='noopener noreferrer'>
          <i className='fab fa-facebook gold-text header-42 ' />
        </a>
      </div>
    )
  }

  const TwitterLink = () => {
    if (!twitterLink || twitterLink === 'null') return null
    return (
      <div className='col-xs-2 col-sm-4'>
        <a href={twitterLink} target='_blank' rel='noopener noreferrer'>
          <i className='fa fa-twitter gold-text header-42 ' />
        </a>
      </div>
    )
  }

  const InstagramLink = () => {
    if (!instagramLink || instagramLink === 'null') return null
    return (
      <div className='col-xs-2 col-sm-4'>
        <a href={instagramLink} target='_blank' rel='noopener noreferrer'>
          <i className='fa fa-instagram gold-text header-42 ' />
        </a>
      </div>
    )
  }
  // console.log({ hideContent })
  return (
    <>
      {!hideContent && (
        <>
          <section className='pane clear-pane relative no-gutters'>
            <div className='row infinity-sides'>
              <div className='col-xs-12 col-sm-4 reg-column-sm minimal-grey-pane no-right-gutter-sm'>
                <StatsModule partner={partner} />

                <div className='xtra-fat-top'>
                  <div className='center-margin width-240-sm'>
                    <div className='row no-gutters xtra-fat-bottom middle-xs'>
                      <div className='col-xs-4 align-right'>
                        <i className='fal fa-browser header-24' />
                      </div>
                      <div className='col-xs-8 no-right-gutter no-left-gutter header-14'>
                        {website && (
                          <a href={website} target='blank'>
                            {website}
                          </a>
                        )}
                      </div>
                    </div>

                    <div className='row no-gutters middle-xs xtra-fat-bottom xtra-fat-bottom-padding-sm'>
                      <FacebookLink />

                      <TwitterLink />

                      <InstagramLink />
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-xs-12 col-sm-8 reg-column reg-left-padding-sm header-18 reg-pane'>
                <div className='flat-top-xs' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(about || '') }} />
              </div>
            </div>
          </section>
          <MembershipConversations variant='topicFeatured' />
          <MembershipConversations variant='upcoming' />

          <Sections sectionable={partner} />
        </>
      )}
      <MembershipHsRedirect />
      {hideContent && (
        <>
          {/* <Backdrop className={classes.backdrop} open={true}> */}
          <MembershipPane />
          {/* </Backdrop> */}
        </>
      )}
    </>
  )
})

export default flow(
  connectTopicTheme,
  observer,
  inject<FIXME, FIXME>(
    (
      {
        mst: {
          auth: { user, loaded },
          partners,
          partners: { timeScope, setTimeScope },
          dialogs: { showDialog }
        }
      }: { mst: IStore },
      {
        match: {
          params: { slug: rawSlug }
        }
      }
    ) => {
      const slug = rawSlug.toLowerCase()
      const partnerLoading = partners.loadingIds.includes(slug)
      const partner = partners.list.find((d) => d.slug.toLowerCase() === slug)
      if (!partner && !partnerLoading) {
        partners.loadBySlug(slug)
      }

      return {
        currentUserId: user?.id,
        user,
        loaded,
        partner,
        showDialog,
        partnerLoading,
        topics: partner && Array.from(partner.topics.filter((c) => !c.hidden)),
        topicsLoading: partner?.loadingIds.includes('topics'),
        conversations: partner && Array.from(partner.conversations),
        conversationsLoading: partner && partner.loadingIds.includes('conversations'),
        featuredConversationsLoading: partner && partner.loadingIds.includes('featuredConversationsList')
      }
    }
  ),
  withTranslation
)(PartnerPage)
const MembershipPane = () => {
  const classes = useStyles()
  const { partner } = useParamPartner()
  const tracks = partner?.tracks
  const track = tracks && tracks[0]
  const { user } = useAuth()
  return (
    <AppBar color='inherit' position='relative'>
      <div className={classes.appBar}>
        <Paper className={classes.paper}>
          <Typography variant='h5' align='center' className='reg-bottom'>
            {track?.header || 'Begin Your Journey'}
          </Typography>
          <Typography variant='body2' align='center' component='p' className='reg-bottom'>
            {track?.description ||
              "The Inclusivv Membership community is full of people like you — those who believe in the power of intimate conversations to bring about real and lasting change. To protect the integrity of this community, this page is for members only. But don't worry! It's easy to join, and we'd be happy to have you."}
          </Typography>
          <Grid container justify='center' spacing={3} className='reg-top'>
            {!user && (
              <Grid item>
                <Typography variant='subtitle2' component='p' className='reg-bottom'>
                  {track?.loginHeaderText || 'Already a Member?'}
                </Typography>
                <Button variant='contained' color='primary' className='reg-bottom' onClick={() => partner?.showDialog('login')}>
                  Log In Now
                </Button>
              </Grid>
            )}
            <Grid item>
              <Typography variant='subtitle2' component='p' className='reg-bottom'>
                {track?.joinCtaText || 'Not a Member Yet?'}
              </Typography>
              <Button variant='outlined' className='reg-bottom' href='https://inclusivv.co/membership'>
                {track?.joinCtaButton || 'Become a Member'}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </AppBar>
  )
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      // top: 'auto',
      // bottom: 0,
      // height: '48vh',
      padding: '4rem',
      background: 'linear-gradient(76.7deg, #EE413E 0%, #B32D73 55.76%, #5D3A92 100%)',
      boxShadow: '0px -2px 16px rgba(0, 0, 0, 0.1), 0px -2px 13px rgba(0, 0, 0, 0.25)'
    },
    paper: {
      maxWidth: '685px',
      textAlign: 'center',
      padding: '2rem',
      margin: 'auto auto',
      borderRadius: '24px'
    },
    backdrop: {
      zIndex: 1207,
      color: '#fff'
    }
  })
)
