import React from 'react'
import { flow } from 'lodash'
import DOMPurify from 'dompurify'
import { connectTopicTheme } from '../../../connectTheme'
import withTranslation, { WithTranslation } from '../../hocs/withTranslation'
import { IConversation } from '../../../store/models/Conversation'
import LoadingAnimation from '../../shared/LoadingAnimation'
import { observer } from 'mobx-react'
import { Typography } from '@material-ui/core'

export const SchedulePane = flow(
  observer,
  withTranslation
)(({ conversation: { schedule }, t }: { conversation: IConversation } & WithTranslation) => {
  return (
    <>
      {schedule && (
        <section className='pane clear-pane xtra-fat-pane fat-pane-xs'>
          <Typography variant='h2' align='center' className='header-32 header-24-sm reg-bottom light-light-grey-text'>
            {t('common.schedule')}
          </Typography>
          <div className='col-xs-12 schedule-wysiwyg'>
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(schedule)
              }}
            />
          </div>
        </section>
      )}
    </>
  )
})

export const PartnerLogoPane = flow(observer)(({ conversation: { partnerLogo } }: { conversation: IConversation }) => {
  return (
    <>
      {partnerLogo?.large?.url && (
        <section className='pane clear-pane trim-pane'>
          <div className='row no-gutters no-margin'>
            <div className='col-xs-12 no-gutters'>
              <div className='max-240 max-160-xs'>
                <img className='img-responsive' src={partnerLogo.large.url} alt='partner logo' />
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  )
})

export const PartnershipPane = flow(
  connectTopicTheme,
  observer
)(({ conversation: { isPremium }, theme }: { conversation: IConversation; theme: FIXME }) => {
  if (isPremium) {
    return (
      <section
        className='pane infinity-sides'
        style={{
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText
        }}
      >
        <div className='row no-gutters'>
          <div className='col-xs-6 no-gutters partnership-pane'>
            <img className='img-responsive' src='https://civicdinners-manual-storage.s3.amazonaws.com/partners/facebookEMEA/partnership_image.jpg' />
          </div>
          <div className='col-xs-6 no-gutters'>oh</div>
        </div>
      </section>
    )
  }
})

export const DescriptionPane = flow(
  connectTopicTheme,
  observer
)(({ conversation, theme }: { theme: FIXME; conversation?: IConversation }) => {
  if (!conversation) {
    return <LoadingAnimation />
  }
  if (conversation.isPremium) {
    return (
      <section
        className='pane center-xs'
        style={{
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText
        }}
      >
        <div className='lightly-darkened-pane thin-pane skinny-pane-xs hero-description'>
          <div className='row content-cage'>
            <div className='col-xs-12'>
              {conversation.description && (
                <div
                  className='header-20 header-20-sm no-margin white-text'
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(conversation.description)
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    )
  } else {
    return (
      <section
        className='pane center-xs'
        style={{
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText
        }}
      >
        <div className='lightly-darkened-pane thin-pane skinny-pane-xs hero-description'>
          <div className='row content-cage'>
            <div className='col-xs-12'>
              {conversation.description && (
                <div
                  className='header-24 header-20-sm no-margin'
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(conversation.description)
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    )
  }
})
