import { deleteApi, get, post, put } from './utils'

class surveyQuestionsApi {
  static create(data) {
    return post(`/survey_questions`, {
      body: JSON.stringify({ survey_question: data })
    })
  }

  static update(data) {
    return put(`/survey_questions/${data.id}`, {
      body: JSON.stringify({ survey_question: data })
    })
  }

  static getAll(slug) {
    return get(`/survey_questions?slug=${slug}`)
  }

  static destroy(id) {
    return deleteApi(`/survey_questions/${id}`)
  }
}

export default surveyQuestionsApi
