import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Card, createStyles, Divider, Grid, makeStyles, MenuItem, Paper, Select, Tab, Tabs, Theme, Tooltip, Typography } from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { IMembershipUser } from '../../../store/models/User/MembershipUser'
import { useAuth } from '../../Providers'
import { IConversation, IStore, ITrack } from '../../../store'
import ConversationCard from '../../conversations/Card'
import { flow } from 'lodash'
import MyUpcomingConversations from './MyUpcomingConversations'
import TrackConversations from './TrackConversations'

interface Props {
  membership: IMembershipUser
  track: ITrack
}

export const MyDetails = ({ membership, track }: Props) => {
  return (
    <div style={{ marginTop: '1rem' }}>
      <Grid container justify='space-between'>
        <Grid item>
          <Typography variant='h5'>{track?.name}</Typography>
        </Grid>
        <Grid item>
          <Button rel='noopener' href={`/${track?.slug}/journey`} target='_blank' variant='outlined'>
            View Journey Page
          </Button>
        </Grid>
      </Grid>
      <Divider style={{ marginTop: '1rem', backgroundColor: '#cccccc' }} />
      {/* <MyJourney {...{ membership, track }} /> */}
      <MyConversations {...{ membership, track }} />
      {/* <MyMembers {...{ membership, track }} /> */}
    </div>
  )
}

export default MyDetails

const MyConversations = ({ membership, track }: { membership: IMembershipUser; track: ITrack }) => {
  const classes = useStyles()
  return (
    <div style={{ marginTop: '1rem' }}>
      {/* <Typography variant='subtitle1' className='reg-bottom reg-top'>
        Upcoming Membership Conversations
      </Typography>
      <Paper elevation={0} className={`${classes.conversations} reg-bottom`}>
        {track?.conversations && <MyUpcomingConversations membershipUser={membership} conversations={track?.conversations} track={track} />}
      </Paper> */}
      <Typography variant='subtitle1' className='reg-bottom reg-top'>
        Upcoming Membership Conversations
      </Typography>
      <Paper elevation={0} className={`${classes.conversations} reg-bottom`}>
        {track?.conversations && <TrackConversations membershipUser={membership} conversations={track?.conversations} />}
      </Paper>
      <Typography variant='subtitle1' className='reg-bottom reg-top'>
        Past Membership Conversations
      </Typography>
      <Paper elevation={0} className={`${classes.conversations} reg-bottom`}>
        {track?.conversations && <TrackConversations past={true} membershipUser={membership} conversations={track?.conversations} />}
      </Paper>
    </div>
  )
}

const MyJourney = ({ membership, track }: { membership: IMembershipUser; track: ITrack }) => {
  return (
    <Card style={{ marginTop: '1rem', padding: '1rem' }}>
      <Typography variant='subtitle2' style={{ marginTop: '1rem' }}>
        My Journey
      </Typography>
      <Typography variant='body2' style={{ marginTop: '1rem' }}>
        <Link to={`/${track.slug}/journey`}>{track.name}</Link>
      </Typography>
      <Typography variant='body2' style={{ marginTop: '.5rem' }}>
        12 month access to attend a monthly conversation championing belonging and well-being
      </Typography>
      <Typography variant='body2'>{membership.dealName}</Typography>
      <Typography variant='body2'>{`${moment(membership.startsAt).format('MM/DD/yyyy')} - ${moment(membership.endsAt).format('MM/DD/yyyy')}`}</Typography>
    </Card>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { marginTop: '1em' },
    delete: {
      color: '#E51814'
    },
    conversations: {
      // maxHeight: 'calc(100vh / 3)',
      // overflow: 'auto'
    },
    members: {
      maxHeight: 'calc(100vh / 3)',
      overflow: 'auto'
    }
  })
)
