import React from 'react'
import { connect } from 'react-redux'
import { loadConversation } from '../../actions/conversationActions'
import { withRouter } from 'react-router'

// TODO generalize this into a higher-order component!

class FetchConversationWrapper extends React.Component {
  componentDidMount() {
    this.loadConversation()
  }
  loadConversation = () => {
    const {
      match: {
        params: { conversationId }
      }
    } = this.props
    if (conversationId && conversationId !== 'new') {
      this.props.loadConversation()
    }
  }

  componentDidUpdate(prevProps) {
    // this provides a data refresh after update
    const {
      match: {
        path,
        params: { conversationId }
      }
    } = this.props
    if (conversationId && conversationId !== prevProps.match.params.conversationId && prevProps.match.path !== path && path.include(':conversationId')) {
      this.loadConversation()
    }
  }

  render() {
    return this.props.children
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  const conversationId = parseInt(ownProps.match.params.conversationId, 10)

  return {
    loadConversation: () => dispatch(loadConversation(conversationId))
  }
}

export default withRouter(connect(null, mapDispatchToProps)(FetchConversationWrapper))
