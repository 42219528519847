import { includes } from 'lodash'
import { types, Instance, getRoot } from 'mobx-state-tree'
import { MetaImage } from '../base/BaseObjects'
import TopicActions from './TopicActions'
import TopicSurveyQuestions from './TopicSurveyQuestions'
import ConversationInvitationRequestsApi from '../../../api/ConversationInvitationRequestsApi'
import { AfterLoginActionable } from '../helpers/AfterLoginActionable'
import TopicBase from './TopicBase'
import topicsApi from '../../../api/TopicsApi'
import { snakify } from '../../../decorators/textTools'
import TopicNotifications from './TopicNotifications'
import TopicDocuments from './TopicDocuments'
import Sectionables from '../helpers/Sectionables'
import TopicConversations from './TopicConversations'
import Partner from '../Partner'
import TopicStories from './TopicStories'
import { RootAccessable } from '../helpers/RootAccessable'
import FavoriteApi from '../../../api/FavoriteApi'
import TopicSubscribeApi from '../../../api/TopicSubscribeApi'
import surveyAnswersApi from '../../../api/SurveyAnswersApi'
import TopicLmsCourses from './TopicLmsCourses'
export const TopicAttr = types.compose(
  'TopicAttr',
  TopicBase,
  TopicSurveyQuestions,
  TopicActions,
  TopicLmsCourses,
  TopicNotifications,
  TopicDocuments,
  TopicConversations,
  types.model({})
)

export const Topic = types
  .compose(
    'Topic',
    RootAccessable,
    TopicAttr,
    AfterLoginActionable,
    TopicStories,
    Sectionables,
    types.model({
      about: types.maybeNull(types.string),
      brandSingular: types.maybeNull(types.string),
      cardBanner: types.maybeNull(types.string),
      description: types.maybeNull(types.string),
      emailSignOff: types.maybeNull(types.string),
      eventTypePlural: types.maybeNull(types.string),
      eventTypeSingular: types.maybeNull(types.string),
      hostGuideUrl: types.maybeNull(types.string),
      locale: types.maybeNull(types.string),
      metaDescription: types.maybeNull(types.string),
      metaTitle: types.maybeNull(types.string),
      partnerConsentBody: types.maybeNull(types.string),
      partnerConsentTitle: types.maybeNull(types.string),
      partnerLogoLink: types.maybeNull(types.string),
      videoLink: types.maybeNull(types.string),
      attendeeSurveyLink: types.maybeNull(types.string),
      surveyLink: types.maybeNull(types.string),
      subtitle: types.maybeNull(types.string),
      themeColor: types.maybeNull(types.string),
      virtualEventTypePlural: types.maybeNull(types.string),
      virtualEventTypeSingular: types.maybeNull(types.string),
      hasMetaImage: types.maybeNull(types.boolean),
      hasPartnerLogo: types.maybeNull(types.boolean),
      hidden: types.maybeNull(types.boolean),
      homeConversations: types.maybeNull(types.boolean),
      otherConversations: types.maybeNull(types.boolean),
      partnerConsent: types.maybeNull(types.boolean),
      restaurantConversations: types.maybeNull(types.boolean),
      virtualConversations: types.maybeNull(types.boolean),
      visibleOnlyPartners: types.maybeNull(types.boolean),
      privateHosting: types.maybeNull(types.boolean),
      storiesEnabled: types.maybeNull(types.optional(types.boolean, false)),
      metaImage: types.maybe(MetaImage),
      partnerLogo: types.maybe(MetaImage),
      icon: types.maybe(MetaImage),
      logoImage: types.maybeNull(MetaImage),
      privateLabelEmailHeaderImage: types.maybeNull(MetaImage),
      privateLabel: types.maybeNull(types.boolean),
      regionCoordinates: types.optional(types.array(types.number), []),
      timezones: types.maybeNull(types.optional(types.array(types.string), [])),
      conversationsCount: types.optional(types.number, 0),
      showHowItWorks: types.maybeNull(types.boolean),
      unarchivable: types.maybeNull(types.boolean),
      journeyTopic: types.maybeNull(types.boolean),
      subscriptionEnabled: types.maybeNull(types.optional(types.boolean, true)),
      subscriptionSurveyUrl: types.maybeNull(types.string),
      subscriptionDescription: types.maybeNull(types.string),
      subscriptionCtaLabel: types.maybeNull(types.string),
      unsubscribeCtaLabel: types.maybeNull(types.string),
      partnerSlug: types.maybeNull(types.string),
      googleTagId: types.maybeNull(types.string),
      allowDocumentUpload: types.maybeNull(types.boolean),
      enableSurveyScoring: types.maybeNull(types.boolean)
    })
  )
  .views((self) => ({
    get canEdit() {
      const { user } = self.getAuth()
      return user && (user.isSuperAdmin() || (user && user.isCommunityAdmin() && includes(user.communityAdminAuthorizedTopicIds, self.id)))
    }
  }))
  .actions((self) => ({
    createTopicInvitationRequest: () => {
      const { user } = self.getAuth()
      if (user) {
        return ConversationInvitationRequestsApi.create({ userId: user.id, topic_id: self.id }).then(({ response: { ok }, json }) => {
          if (ok) {
            self.showDialog('topicInvitationRequestSent', { topic: self })
            user.loadUserInvitationRequests({ reload: true })
          } else {
            self.showAlert(json?.error || json?.errors?.message)
          }
        })
      } else {
        self.setAfterLoginAction('createTopicInvitationRequest')
        self.showDialog('signup')
      }
    },
    updateTopic: (data) => {
      return topicsApi.update({ ...snakify(data), id: self.id }).then(({ response: { ok }, json }) => {
        if (ok) {
          self.updateMe(json)
          self.showAlert('Topic updated successfully')
          return { data: json }
        } else {
          self.showAlert('Error')
          return { error: json }
        }
      })
    },
    inviteHost: (data) => {
      return topicsApi.inviteHost({ ...snakify(data), id: self.id }).then(({ response: { ok }, json }) => {
        if (ok) {
          self.updateMe(json)
          self.showAlert('Host Invitation Sent')
          return { data: json }
        } else {
          self.showAlert('Error')
          return { error: json }
        }
      })
    },
    inviteHostTest: () => {
      return topicsApi.inviteHostTest({ id: self.id }).then(({ response: { ok }, json }) => {
        if (ok) {
          self.updateMe(json)
          self.showAlert('Test Invitation Sent Successfully')
          return { data: json }
        } else {
          self.showAlert('Error')
          return { error: json }
        }
      })
    },
    attendedTopic: () => {
      const { user } = self.getAuth()
      return user?.userConversations.find((uc) => uc.topicId === self.id)
    },
    getAllPastConversations: () => {
      const conversations = self.getConversations()
      return conversations.allPastConversations
    },
    setIsTemplate: (isTemplate: boolean) => {
      self.isTemplate = isTemplate
    },
    createTopicSubscribeCsvExport: (topic_id: number) => {
      const downloads = self.getDownloads()
      return downloads.createCsvExport('topic_subscribe', { topic_id })
    },
    saveSurveyAnswers: (ans) => {
      return surveyAnswersApi.bulkCreate(ans)
    },
    topicSubscribe: () => {
      return TopicSubscribeApi.topicSubscribe(self.id).then(({ response: { ok, status, statusText }, json }) => {
        if (ok) {
          // self.showDialog('topicInvitationRequestSent', { topic: self })
          const auth = self.getAuth()
          auth?.addToLikes(json)
        }
      })
    },
    topicUnsubscribe: (id) => {
      return TopicSubscribeApi.topicUnsubscribe(id).then(({ response: { ok, status, statusText }, json }) => {
        if (ok) {
          const auth = self.getAuth()
          auth?.removeFromLikes(id)
        }
      })
    }
  }))

export interface ITopic extends Instance<typeof Topic> {}
export default Topic
