import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Container, createStyles, Grid, makeStyles, Paper, Theme, Typography } from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import { IMembershipUser, IMembershipUserBase } from '../../../../store/models/User/MembershipUser'
import { IStore, ITrack } from '../../../../store'
import { get } from '../../../../api/utils'
import { useHistory, useParams } from 'react-router'
import DataTable, { IColData, ValueLabel } from '../../../shared/DataTable'
import LoadingAnimation from '../../../shared/LoadingAnimation'
import { INpsAnswer } from '../../../../store/models/NpsAnswers'
import { useAuth } from '../../../Providers'
import moment from 'moment'
import { flow } from 'lodash'

interface Props {
  membership: IMembershipUser
  track: ITrack
}

interface EngagementProps {
  feedbacks: INpsAnswer[]
}

export const Feedback = observer(({ membership, track }: Props) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<EngagementProps>({ feedbacks: [] })
  const { action, membership_id } = useParams<{ action: string; membership_id?: string }>()

  useEffect(() => {
    setLoading(true)
    get(`/memberships/${membership_id}/feedback`).then(({ json, response: { ok } }) => {
      if (ok) {
        setData(json)
      }
      setLoading(false)
    })
  }, [membership_id])

  if (loading) {
    return <LoadingAnimation />
  }

  return (
    <div style={{ marginTop: '1rem' }}>
      <Typography variant='h6' className='reg-bottom reg-top'>
        Feedback
      </Typography>
      <DownloadFeedback membership={membership} />
      <Grid container direction='column' alignItems='flex-start' spacing={3}>
        <Grid item className={classes.grid}>
          <EngagementDetail membership={membership} track={track} data={data} />
        </Grid>
      </Grid>
    </div>
  )
})
export default Feedback

const EngagementDetail = ({ membership, track, data }: Props & { data: EngagementProps }) => {
  const { user } = useAuth()
  const classes = useStyles()
  const columnData: IColData[] = [
    { name: 'id', title: 'ID', width: 80, sortable: true },
    { name: 'createdAt', title: 'Created', width: 100, sortable: true },
    { name: 'npsQuestionId', title: 'Question', width: 300, sortable: true },
    { name: 'score', title: 'Score', width: 80, sortable: true },
    { name: 'reason', title: 'Reason', width: 300, sortable: true },
    { name: 'partnerName', title: 'Partner Name', width: 200 },
    { name: 'conversationTitle', title: 'Conversation Title', width: 200 },
    { name: 'topicId', title: 'Topic ID', width: 100, sortable: true },
    { name: 'conversationId', title: 'Conversation ID', width: 120, sortable: true },
    { name: 'partnerId', title: 'Partner ID', width: 100, sortable: true }
    // { name: 'actions', title: 'Actions', width: 220, align: 'center' }
  ]

  const questions = [
    'How likely are you to recommend this experience to a friend or colleague?',
    'I felt my voice was heard and valued',
    'I feel a greater sense of empathy with others',
    'I now have a deeper understanding of this topic',
    'I feel more committed to take action on this topic',
    "Any comments you'd like to share?",
    'This topic was very interesting',
    'I would recommend this conversation facilitator',
    "Any comments you'd like to share with your facilitator?"
  ]
  return (
    <div className={classes.section}>
      <div className='reg-left reg-bottom-padding'>
        <DataTable
          height={'calc(100vh - 16em)'}
          data={data.feedbacks}
          columns={columnData}
          loading={false}
          rightColumns={['actions']}
          hideFilter={true}
          getCellValue={(row: INpsAnswer, columnName: string) => {
            switch (columnName) {
              // case 'actions':
              //   return <NpsActions item={row} reload={() => filterable.loadFilter()} />
              case 'createdAt':
                return moment(row.createdAt).tz(moment.tz.guess()).format('L')
              case 'npsQuestionId':
                return <ValueLabel value={row.npsQuestionId ? questions[row.npsQuestionId - 1] : row.npsQuestionId} whiteSpace='normal' />
              default:
                return <ValueLabel value={row[columnName]} whiteSpace='normal' />
            }
          }}
        />
      </div>
    </div>
  )
}

const DownloadFeedback = flow(
  observer,
  inject<any, any>(
    ({
      mst: {
        nps_answers: { createCsvExport }
      }
    }: {
      mst: IStore
    }) => {
      return {
        createCsvExport
      }
    }
  )
)(({ membership, createCsvExport }: { membership: IMembershipUser; createCsvExport?(arg: any) }) => {
  const [exported, setExported] = useState(false)
  const [exporting, setExporting] = useState(false)
  const history = useHistory()
  return (
    <div>
      <Button
        variant='contained'
        disabled={exporting}
        startIcon={<i className='fal fa-file-export' />}
        onClick={() => {
          setExporting(true)
          createCsvExport &&
            createCsvExport({ parameters: { selectedMembershipId: membership.membershipId } }).then(() => {
              setExported(true)
              setExporting(false)
            })
        }}
      >
        Export Data to CSV
      </Button>
      {exported && (
        <Button
          className='reg-left'
          startIcon={<i className='fas fa-cloud-download-alt' />}
          onClick={() => {
            history.push('/downloads')
          }}
        >
          My Downloads
        </Button>
      )}
    </div>
  )
})

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      // backgroundColor: '#00ff00',
      width: '100%'
    },
    dataPaper: {
      padding: theme.spacing(2),
      borderRadius: '16px',
      maxWidth: '10rem'
      // minHeight: '6rem'
    },
    section: {
      // marginTop: '2rem',
      paddingTop: '.25rem'
      // minHeight: '10rem'
      // backgroundColor: '#ff0000',
      // width: '100%'
    }
  })
)
