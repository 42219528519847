import React, { useEffect } from 'react'
import { Button, createStyles, Grid, Icon as FontIcon, makeStyles, Typography } from '@material-ui/core'
import { Link, withRouter, RouteComponentProps } from 'react-router-dom'

import PendingGuests from './PendingGuests'
import withTranslation from '../../hocs/withTranslation'
import { flow } from 'lodash'
import { IConversation } from '../../../store/models/Conversation'
import { observer } from 'mobx-react'
import ShareDropdown from '../partials/ShareDropdown'
import GuestRequests from './GuestRequests'
import GuestList from './GuestList'

interface Props {
  conversation: IConversation
  t: any
}
const ManageGuests = ({ conversation, t }: Props & RouteComponentProps) => {
  const classes = Styles()
  useEffect(() => {
    if (conversation) {
      conversation.shouldLoad('conversationUserConversations', conversation.loadUserConversations)
    }
  }, [conversation])

  return (
    <div className='huge-bottom'>
      <Grid container justify={'space-between'}>
        <Grid item className={classes.myDownloadLinkGrid}>
          <Link className='purple-text' to='/downloads' target='_blank'>
            <Typography variant='h6' component='span' className={classes.myDownloadLink}>
              {t('common.myDownloads')} <i className='fas fa-external-link-alt'></i>
            </Typography>
          </Link>
        </Grid>
        <Grid item>
          <Grid container justify={'space-between'} spacing={2}>
            <Grid item>
              <Button startIcon={<FontIcon className='fas fa-envelope opacity-8 thin-right-padding' />} onClick={() => conversation.invite()} variant='contained'>
                {t('conversation.inviteGuests')}
              </Button>
            </Grid>
            <Grid item>
              <ShareDropdown conversation={conversation} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <div className='row no-gutters'>
        <div className='col-xs-12 no-gutters'>
          {conversation.guestPolicy === 'approval_only' && <GuestRequests conversation={conversation} />}
          <PendingGuests conversation={conversation} />
          <GuestList conversation={conversation} />
        </div>
      </div>
    </div>
  )
}

export default flow(observer, withTranslation, withRouter)(ManageGuests)
const Styles = makeStyles((theme) =>
  createStyles({
    myDownloadLink: {
      color: '#0976BC',
      fontSize: '1em'
    },
    myDownloadLinkGrid: {
      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    }
  })
)
