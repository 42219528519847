import { post, put, deleteApi } from './utils'
import appAnalytics from '../analytics'

class UserConnectionsApi {
  static create(data) {
    return post(`/user_connections`, {
      body: JSON.stringify(data)
    }).then((res) => {
      appAnalytics.event({
        category: 'User',
        action: 'Send Connection Request',
        conversationId: data.conversationId
      })
      return res
    })
  }

  static update(data) {
    return put(`/user_connections/${data.id}`, {
      body: JSON.stringify(data)
    }).then((res) => {
      appAnalytics.event({
        category: 'User',
        action: 'Accept Connection Request',
        conversationId: data.conversationId
      })
      return res
    })
  }

  static delete(data) {
    return deleteApi(`/user_connections/${data?.id}`).then((res) => {
      appAnalytics.event({
        category: 'User',
        action: 'Remove Connection Request',
        conversationId: data?.conversationId
      })
      return res
    })
  }
}

export default UserConnectionsApi
