import { createSelector } from 'reselect'
import { sortBy } from 'lodash'

export const getActionsRaw = state => state.actions

const getActionsList = createSelector(
  getActionsRaw,
  s => s.list
)

export const getActions = createSelector(
  getActionsList,
  actions => {
    return actions
  }
)

export const getRankedActions = createSelector(
  getActions,
  actions => {
    return sortBy(actions, c => c.rank)
  }
)

export const getLoadingActions = createSelector(
  getActionsRaw,
  s => s.loading
)
