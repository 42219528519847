import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import { debounce } from 'lodash'

interface OptionType {
  name: string
}

interface Props {
  value: string
  threshold?: number
  label?: string
  color?: 'primary' | 'secondary' | undefined
  required?: boolean
  onSelect(val: any): void
  onBlur?(e: any): void
  onLoadOptions(text: string): Promise<OptionType[]>
}

const AutocompleteMui = ({ color = 'secondary', threshold = 1000, onLoadOptions, onSelect, value, label, required, onBlur }: Props) => {
  const [query, setQuery] = useState<any>(value)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState<OptionType[]>([])

  useEffect(() => {
    if (!open) {
      setOptions([])
    }
  }, [open])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    debounce(() => {
      if (query && query !== value) {
        setLoading(true)
        onLoadOptions(query).then((list) => {
          setOptions(list)
          setLoading(false)
        })
      }
    }, threshold),
    [query, threshold]
  )

  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      getOptionSelected={(opt, val) => opt.name === val.name}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={loading}
      freeSolo={true}
      onChange={(e, val: any) => {
        if (val) {
          onSelect(val)
          setQuery(val.name)
        } else {
          onSelect('')
          setQuery('')
        }
      }}
      value={{ name: query?.formatted_address || query || '' }}
      renderInput={(params) => {
        return (
          <TextField
            defaultValue={query}
            {...params}
            fullWidth={true}
            label={label}
            color={color}
            required={required}
            InputProps={{
              onBlur,
              onChange: ({ target: { value: val } }) => setQuery(val),
              value: query,
              ...params.InputProps,
              endAdornment: <React.Fragment>{loading ? <CircularProgress color='inherit' size={20} /> : null}</React.Fragment>
            }}
          />
        )
      }}
    />
  )
}

export default AutocompleteMui

export const AddressAutocomplete = ({
  onChange,
  value,
  color,
  required,
  label,
  types = ['(regions)'],
  onError,
  onBlur
}: {
  color?: 'primary' | 'secondary' | undefined
  types?: string[]
  label?: string
  required?: boolean
  value: string
  onChange(val: any): void
  onBlur?(e: any): void
  onError?(): void
}) => (
  <AutocompleteMui
    onSelect={(val: any) => onChange(val)}
    value={value}
    color={color}
    required={required}
    label={label}
    onBlur={onBlur}
    onLoadOptions={(query: string) => {
      return new Promise((resolve, reject) => {
        if (query) {
          const googleService = new window.google.maps.places.AutocompleteService()
          googleService.getPlacePredictions({ input: query, types }, (suggested: any, status: any) => {
            if (status === 'OK') {
              resolve(
                suggested.map((data: any) => ({
                  name: data.description,
                  value: data.place_id,
                  data
                }))
              )
            } else {
              onError && onError()
              reject()
            }
          })
        }
      })
    }}
  />
)
