import { types, Instance, getRoot } from 'mobx-state-tree'
import OrganizationsApi from '../../../api/OrganizationsApi'
import { snakify } from '../../../decorators/textTools'
import { Filterable, IFilterableArgs } from '../helpers'
import { Alertable } from '../helpers/Alertable'
import Editable from '../helpers/Editable'
import Organizations from '../Organizations'
import { LoadableStatus } from '../helpers/LoadableStatus'
import { Partner, IPartner } from '../../models/Partner'
import PartnersTableApi from '../../../api/PartnersTableApi'
import { removeNullStringValue } from '../utils'

export const Organization = types
  .compose(
    'Organization',
    Alertable,
    Editable,
    Filterable,
    LoadableStatus,
    types.model({
      id: types.identifierNumber,
      aexid: types.string,
      name: types.string,
      partnersCount: types.maybeNull(types.number),
      partners: types.array(Partner),
      churnzeroSync: types.maybeNull(types.boolean)
    })
  )
  .actions((self) => ({
    setList(data: any[], totalCount?: number) {
      data.forEach((d) => removeNullStringValue(d))
      self.partners.replace(data)
      self.setTotalCount(totalCount)
    }
  }))
  .actions((self) => ({
    updateOrganization: (data) => {
      return OrganizationsApi.update({ ...snakify(data), id: self.id }).then(({ response: { ok }, json }) => {
        if (ok) {
          self.updateMe(json)
          self.showAlert('Partner successfully updated')
          return { data: json }
        } else {
          self.showAlert('Error')
          return { error: json }
        }
      })
    },
    loadFilter: (args: IFilterableArgs) => {
      if (self.isLoading('partners_table')) {
        return
      }
      self.startLoading('partners_table')
      // self.updateArgs(args)

      return PartnersTableApi.getAll({ ...args, organization_id: self.id }).then(({ response: { ok, statusText }, json: { partners, total } }) => {
        if (ok) {
          self.setList(
            partners.filter((p) => p.organizationId === self.id),
            total
          )
        } else {
          self.showAlert(statusText)
        }
        self.stopLoading('partners_table')
      })
    }
  }))

export interface IOrganization extends Instance<typeof Organization> {}
export default Organization
