import * as types from '../actions/actionTypes'
import { assign, keys, omit } from 'lodash'
import initialState from './initialState'

export const sections = (state = initialState.sections, action) => {
  switch (action.type) {
    case types.LOAD_SECTIONS_SUCCESS:
      return assign({}, state, {
        error: null,
        loading: false,
        list: assign({}, state.list, action.sections)
      })

    case types.LOAD_NEW_SECTIONS_SUCCESS:
      return assign({}, state, {
        error: null,
        loading: false,
        list: assign({}, action.sections)
      })

    case types.LOAD_SECTIONS_FAILED:
      return assign({}, state, {
        error: action.data,
        loading: false
      })

    case types.SECTIONS_LOADING:
      return assign({}, state, {
        error: null,
        loading: true
      })

    case types.DELETE_SECTIONS_SUCCESS:
      return assign({}, state, {
        loading: false,
        error: null,
        list: omit(state.list, keys(action.sections))
      })
    default:
      return state
  }
}

export default sections
