import React from 'react'
import { Button, Divider, Menu, MenuItem, TextField } from '@material-ui/core'
import moment from 'moment'
import { observer } from 'mobx-react'

export const FILTER_OPTIONS = [
  { label: 'Week to Date', value: 'week_to_date' },
  { label: 'Last Week', value: 'last_week' },
  { label: 'Month to Date', value: 'month_to_date' },
  { label: 'Last Month', value: 'last_month' },
  { label: 'Year to Date', value: 'year_to_date' },
  { label: 'Last 12 Months', value: '12_month' },
  { label: 'All Past Conversations', value: 'all_past' },
  { label: 'All Time', value: 'all' }
]

const DateFilterAction = ({ scopedBy, handleClose: handleCloseParent, setStartsAt, setEndsAt, handleCustomDate: handleCustomDateParent }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [disabled, disableSubmit] = React.useState(true)
  const open = Boolean(anchorEl)

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (menu_name?: string) => (event) => {
    setAnchorEl(null)
    disableSubmit(true)
    menu_name && handleCloseParent(menu_name)(event)
  }

  const handleCustomDate = () => {
    setAnchorEl(null)
    disableSubmit(true)
    handleCustomDateParent()
  }

  return (
    <>
      <Button aria-controls='fade-menu' aria-haspopup='true' onClick={handleOpenMenu} color='primary'>
        {FILTER_OPTIONS.find((fo) => fo.value === scopedBy)?.label}
        {scopedBy === 'date_range' && 'Custom Dates'}
        <i className='thin-left-padding fas fa-chevron-down header-14'></i>
      </Button>

      <Menu id='fade-menu' anchorEl={anchorEl} keepMounted open={open} onClose={handleClose(undefined)} color='primary'>
        {FILTER_OPTIONS.map(({ label, value }) => (
          <MenuItem selected={scopedBy === value} key={value} value={value} color='primary' onClick={handleClose(value)}>
            {label}
          </MenuItem>
        ))}
        <Divider />
        <MenuItem disabled selected={scopedBy === 'date_range'}>
          Custom Dates
        </MenuItem>
        <MenuItem className='opacity-5 hover-opaque'>
          <TextField
            size='medium'
            label='Start'
            type='date'
            defaultValue={moment().subtract(12, 'months').format('YYYY-MM-DD')}
            InputLabelProps={{
              shrink: true
            }}
            onChange={({ currentTarget: { value } }) => {
              disableSubmit(false)
              setStartsAt(value)
            }}
          />
        </MenuItem>
        <MenuItem className='opacity-5 hover-opaque'>
          <TextField
            size='small'
            label='End'
            type='date'
            defaultValue={moment().format('YYYY-MM-DD')}
            InputLabelProps={{
              shrink: true
            }}
            onChange={({ currentTarget: { value } }) => {
              disableSubmit(false)
              setEndsAt(value)
            }}
          />
        </MenuItem>
        <div className='align-right reg-margin'>
          <Button className='reg-right' color='secondary' disableElevation onClick={() => setAnchorEl(null)}>
            Cancel
          </Button>
          <Button variant='contained' color='primary' disableElevation disabled={disabled} onClick={handleCustomDate}>
            Apply
          </Button>
        </div>
      </Menu>
    </>
  )
}
export default observer(DateFilterAction)
