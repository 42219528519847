import { createSelector, createStructuredSelector } from 'reselect'
import { assign } from 'lodash'

import { getUsers, getUserIdFromParams } from './userGetters'
import { getTopic } from './topicGetters'
import { getCurrentConversation } from './currentConversationGetters'
import coordinateDecorator from '../decorators/coordinateDecorator'
import { isSuperAdmin, isPartnerAdmin, isCommunityAdmin } from '../components/auth/authWrappers'

export const getCurrentUserId = state => state.currentUser

export const getCurrentUser = createSelector(
  getUsers,
  getCurrentUserId,
  getUserIdFromParams,
  (users, id, profileId) => {
    const user = users[id]
    if (!user) return {}

    return assign({}, user, {
      coordinates: coordinateDecorator(user.coordinates),
      isViewingSelf: user.id === profileId,
      isSuperAdmin: isSuperAdmin(user),
      isPartnerAdmin: isPartnerAdmin(user),
      isCommunityAdmin: isCommunityAdmin(user)
    })
  }
)

export const getCurrentUserAndConversation = createStructuredSelector({
  user: getCurrentUser,
  conversation: getCurrentConversation
})

export const getCurrentUserAndTopic = createStructuredSelector({
  user: getCurrentUser,
  topic: getTopic
})

export const getCurrentUserAndTopicAndConversation = createStructuredSelector({
  user: getCurrentUser,
  topic: getTopic,
  conversation: getCurrentConversation
})
