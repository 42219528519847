import i18n from 'i18next'
// import moment from 'moment'
import moment from 'moment-timezone'
import { isUndefined } from 'lodash'
import DOMAIN_WHITELIST from './domain_whitelist'

export const required = (value) => (value ? undefined : i18n.t('validations.required'))

export const maxLength = (max) => (value) => (value && strip(value).length > max ? i18n.t('validations.maxCharacters', { max }) : undefined)
export const maxLength255 = maxLength(255)
export const maxLength2000 = maxLength(2000)
export const maxDailyCoAttendance = (value) => (parseInt(value, 10) > 24 ? i18n.t('validations.maxDailyCoAttendance') : undefined)
export const minAttendance = (value) => (parseInt(value, 10) < 2 ? i18n.t('validations.minAttendance') : undefined)
export const minNumber = (min) => (value) => (parseInt(value, 10) < min ? `Should be more than ${min}` : undefined)
export const maxNumber = (max) => (value) => (parseInt(value, 10) > max ? `Should be less than ${max}` : undefined)

export const minLength = (min) => (value) => {
  return value && strip(value).length < min ? i18n.t('validations.minCharacters', { min }) : undefined
}
export const number = (value) => {
  return value && isNaN(Number(strip(value))) ? i18n.t('validations.number') : undefined
}

export const colorHex = (value) => (value && !/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i.test(value) ? i18n.t('validations.invalidColor') : undefined)

export const domain = (value) => (value && !/^[A-Z0-9.-]+\.[A-Z]{2,9}$/i.test(value) ? i18n.t('validations.invalidDomain') : undefined)
export const domainWhitelist = (value) => (DOMAIN_WHITELIST.includes(value) ? 'generic domians are not valid' : undefined)
export const email = (value) => (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,9}$/i.test(value) ? i18n.t('validations.invalidEmail') : undefined)

export const http = (value) => {
  if (!value) return undefined
  const insecure = 'http://',
    secure = 'https://',
    stripped = strip(value)
  return stripped.substr(0, insecure.length) === insecure || stripped.substr(0, secure.length) === secure ? undefined : i18n.t('validations.invalidLink')
}

export const emails = (trim) => {
  var emailArray = trim.split(',')
  var valid = true
  for (var n = 0; n < emailArray.length; n++) {
    var member_info = trim ? emailArray[n].trim() : emailArray[n]

    if (email(member_info)) {
      valid = false
      break
    }
  }
  return valid === true ? undefined : i18n.t('validations.invalidEmails')
}

function strip(string) {
  return string.replace(/\s/g, '').replace(/-/g, '')
}

export const passwordConfirmation = (val, form) => (val === form.password ? undefined : i18n.t('auth.passMustMatch'))

export const notSlugFriendly = (value) => (value && (!/^([a-z0-9._+-]{1,255})$/i.test(value) || value.toLowerCase() != value) ? i18n.t('validations.notSlugFriendly') : undefined)

export const isValidTime = (startsAt, endsAt) => {
  if (!isUndefined(endsAt)) {
    return endsAt.isAfter(startsAt)
  }
  return false
}

export const isValidDate = (start, end) => (current) => {
  const yesterday = moment().subtract(1, 'day')
  if (current.isBefore(yesterday)) {
    return false
  } else if (!start && !end) {
    return true
  } else if (start && !end) {
    return current.isSameOrAfter(start)
  } else if (!start && end) {
    return current.isSameOrBefore(end)
  } else if (start && end) {
    return current.isSameOrAfter(start) && current.isSameOrBefore(end)
  }
}

export const isValidSynthDate = (start, end) => (current) => {
  const tomorrow = moment().subtract(1, 'day')
  if (current.isAfter(tomorrow)) {
    return false
  }
}

export const handleStartEndAt = ({ startsAtDate = undefined, startsAt = undefined, endsAt = undefined, timezone } = {}) => {
  if (startsAtDate) {
    const startMoment = moment(startsAt)
    const endMoment = moment(endsAt)

    const startData = { year: startsAtDate.year(), month: startsAtDate.month(), date: startsAtDate.date(), hour: startMoment.hour(), minute: startMoment.minute() }
    const endData = { year: startsAtDate.year(), month: startsAtDate.month(), date: startsAtDate.date(), hour: endMoment.hour(), minute: endMoment.minute() }

    const newStartsAt = moment.tz(startData, timezone)
    const newEndsAt = moment.tz(endData, timezone)

    return { startsAtDate: newStartsAt.utc().toString(), startsAt: newStartsAt.utc().toString(), endsAt: newEndsAt.utc().toString() }
  }

  return {}
}
