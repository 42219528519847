import queryString from 'query-string'
import { get } from './utils'

class TopicsTableApi {
  static getAll(args) {
    const defaultParams = { page: 0, site_name: global.siteName }

    const params = queryString.stringify(
      {
        ...defaultParams,
        ...args
      },
      { arrayFormat: 'bracket' }
    )

    return get(`/admin/topics?${params}`)
  }
}

export default TopicsTableApi
