import React from 'react'
import { Grid, IconButton, Tooltip } from '@material-ui/core'
import { observer } from 'mobx-react'
import { INpsAnswer } from '../../../../store/models/NpsAnswers'
interface Props {
  item: INpsAnswer
  reload()
}

const NpsActions = observer(({ item, reload }: Props) => {
  return (
    <Grid container direction='row' justify='space-around' alignItems='center'>
      <Tooltip title='Delete'>
        <IconButton
          size='small'
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            if ((window as FIXME).confirm('Are you sure you want to delete this Feedback record?', '')) {
              item.deleteNps().then(reload)
            }
          }}
        >
          <i className='fal fa-trash-alt opacity-8'></i>
        </IconButton>
      </Tooltip>
    </Grid>
  )
})

export default NpsActions
