import React from 'react'
import Button from '@material-ui/core/Button'
import { flow, assign, last } from 'lodash'
import { IDialog } from '../../store/models/Dialog'
import { IUser } from '../../store/models/User'
import { connectTopicTheme } from '../../connectTheme.js'
import { required, maxLength2000 } from '../validations/fieldLevelValidations'
import withTranslation from '../hocs/withTranslation'
import Dialog from '../DialogWrapper'
import { useForm } from 'react-hook-form'
import { TextFieldControl } from '../utils/index'
import { observer } from 'mobx-react'
import { inject } from 'mobx-react'
import { IStore } from '../../store/models/index.js'
import { DialogTitle } from '@material-ui/core'
import { useAuth } from '../Providers'
import { useTranslation } from 'react-i18next'

interface Props {
  data: any
  dialog: IDialog
}

const UserConnectionRequestDialog = observer(({ dialog, data }: Props) => {
  const { handleSubmit, errors, control } = useForm({ mode: 'onBlur' })
  const { user } = useAuth()
  const { t } = useTranslation()

  const handleSave = handleSubmit((val: any) => {
    user?.createUserConnection(assign({}, val, data))
  })

  return (
    <Dialog dialog={dialog} name='userConnectionRequestDialog' type='noPadding'>
      <div>
        <DialogTitle disableTypography>{t('userConnection.personalizeConnection')}</DialogTitle>

        <section className='pane reg-top-padding flat-bottom'>
          <div className='row'>
            <div className='col-xs-12'>
              <p className='flat'>{t('userConnection.personalizeMessage')}</p>
            </div>
          </div>
        </section>
        <form onSubmit={handleSave}>
          <section className='pane big-top-padding big-bottom-padding'>
            <div className='row reg-bottom'>
              <div className='col-xs-12 align-left'>
                <TextFieldControl
                  name='message'
                  control={control}
                  error={errors && errors.message}
                  multiline={true}
                  rows={3}
                  label={t('common.message')}
                  rules={{ validate: maxLength2000, required }}
                  className='reg-bottom'
                />
              </div>
            </div>
          </section>
          <section className='pane minimal-grey-pane big-top-padding big-bottom-padding'>
            <div className='row center-xs'>
              <div className='col-xs-12'>
                <Button type='submit' className='reg-bottom flat-top btn-xl btn-block lowercase' variant='contained' color='secondary'>
                  {t('userConnection.sendMessage')}
                </Button>
              </div>
            </div>
          </section>
        </form>
      </div>
    </Dialog>
  )
})

export default UserConnectionRequestDialog
