import React from 'react'

import Dialog from '../DialogWrapper'
import { flow } from 'lodash'
import { observer } from 'mobx-react'
import { IDialog } from '../../store/models/Dialog'
import { createStyles, DialogTitle, Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import ShareStory from './ShareStory'
import { MediaFilePreview } from '../utils/AvatarUploader'
import StoryShow from './StoryShow'

interface Props {
  dialog: IDialog
}

const SuccessDialog = ({ dialog }: Props) => {
  const { t } = useTranslation()
  const classes = Styles()
  const { conversation, headline, story, file } = dialog.data as any

  return (
    <Dialog dialog={dialog} name='storyCollectionSuccess' type='noPadding' closeIcon={true} closeClass={classes.closeIcon}>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography variant='subtitle1'>{t('story.thankYou')}</Typography>
      </DialogTitle>
      <Grid container direction='column'>
        <Grid item>
          <div className={classes.subHeaderContainer}>
            <img src='https://civicdinners-manual-storage.s3.amazonaws.com/story/stories-megaphone.png' className={`img-responsive ${classes.icon}`} />
            <Typography variant='h6' className={classes.successHeader} align='center'>
              {t('story.successHeader')}
            </Typography>
            <ShareStory story={story} color='#898A8E' noLabel={true} spacing={2} className={classes.shareStory} justify={'center'} hideShareLabel={true} />
          </div>
        </Grid>
        <StoryShow story={story} />
      </Grid>
    </Dialog>
  )
}

const Styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'center',
      margin: '0 auto',
      width: '100%',
      backgroundColor: '#E5E5E5'
    },
    dialogTitle: { backgroundColor: '#ffffff', color: '#000000' },
    subHeaderContainer: {
      textAlign: 'center',
      margin: '0 auto',
      backgroundColor: '#F6F7F9',
      minHeight: '10em',
      padding: '1em 0'
    },
    successHeader: {
      textAlign: 'center',
      margin: '.4em auto',
      maxWidth: '18em'
    },
    content: {
      margin: '1em auto',
      padding: '1em',
      maxWidth: '25em'
    },

    shareStory: {
      maxWidth: '15em',
      margin: '0 auto'
    },
    shareContainerClass: {
      backgroundColor: '#ff0000'
    },

    image: {
      borderRadius: '1.3em'
    },
    closeIcon: {
      color: '#818285'
    },
    icon: {
      margin: '0 auto 1em auto',
      maxWidth: '3em !important'
    },
    spacer: {
      marginTop: '1em'
    },
    shareContainer: {}
  })
)

export default flow(observer)(SuccessDialog)
