import React from 'react'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { assign } from 'lodash'

import { TopicTheme, AdminTheme as AdminThemeV2, DefaultTheme, DialogTheme, CocoTheme } from './themes'
import { AdminTheme } from './themes/AdminTheme'

export const connectTopicTheme = (component) => {
  const connectedTheme = (props) => {
    const { topic, conversation } = props

    const themeable = topic || conversation
    const theme = themeable ? TopicTheme(themeable) : DefaultTheme

    const nextProps = assign({}, props, { theme })
    const element = React.createElement(component, nextProps)

    return <MuiThemeProvider theme={theme}>{element}</MuiThemeProvider>
  }
  connectedTheme.displayName = 'connectedTheme'
  return connectedTheme
}

export const connectAdminTheme = (component) => {
  const connectedTheme = (props) => {
    const theme = AdminTheme()

    const nextProps = assign({}, props, { theme })
    const element = React.createElement(component, nextProps)

    return <MuiThemeProvider theme={theme}>{element}</MuiThemeProvider>
  }
  connectedTheme.displayName = 'connectedTheme'
  return connectedTheme
}

export const connectCocoTheme = (ChilComp) => {
  return (props) => {
    const theme = CocoTheme()
    return (
      <MuiThemeProvider theme={theme}>
        <ChilComp {...props} />
      </MuiThemeProvider>
    )
  }
}

export const connectAdminThemeV2 = (ChilComp) => {
  return (props) => {
    const theme = AdminThemeV2()
    return (
      <MuiThemeProvider theme={theme}>
        <ChilComp {...props} />
      </MuiThemeProvider>
    )
  }
}

export const connectDialogTheme = (component) => {
  const connectedTheme = (props) => {
    const theme = DialogTheme()

    const nextProps = assign({}, props, { theme })
    const element = React.createElement(component, nextProps)

    return <MuiThemeProvider theme={theme}>{element}</MuiThemeProvider>
  }
  connectedTheme.displayName = 'connectedTheme'
  return connectedTheme
}
