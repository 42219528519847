import React, { useContext } from 'react'
import { createStyles, Divider, makeStyles, Theme } from '@material-ui/core'
import { useLocation } from 'react-router'
import { AuthContext } from '../../Providers'
import { MY_ORGANIZATION_LINK } from './Links'
import NavList from './NavList'

const MyOrganizationMobileNav = ({ onClick }) => {
  const { user } = useContext(AuthContext)
  const classes = useStyles()
  const location = useLocation()
  const getPage = () => {
    if (location.pathname.match(/^\/admin\/topics/)) return 'nav.topics'
    if (location.pathname.match(/^\/admin\/conversations/)) return 'nav.conversations'
    if (location.pathname.match(/^\/admin\/attendees/)) return 'nav.attendees'
    if (location.pathname.match(/^\/admin\/stories/)) return 'nav.stories'
    if (location.pathname.match(/^\/admin\/nps_answers/)) return 'nav.nps_answers'
    if (location.pathname.match(/^\/admin\/analytics/)) return 'nav.analytics'
    if (location.pathname.match(/^\/admin\/settings/)) return 'nav.customers'
    if (location.pathname.match(/^\/admin+$/)) return 'nav.dashboard'
  }
  if (!window.inclusivv) return <></>
  const page = getPage()
  return (
    <>
      {user && user?.isCommunityAdmin() && (
        <>
          <NavList
            defaultOpen={!!page}
            onClick={onClick}
            label='nav.myOrganization'
            menus={MY_ORGANIZATION_LINK.filter((p) => {
              if (p.label === 'nav.stories' && !user?.isPartnerAdmin()) {
                return false
              }
              if (['nav.customers', 'nav.nps_answers'].includes(p.label) && !user?.isSuperAdmin()) {
                return false
              }
              return true
            }).map((p) => {
              return { ...p, selected: p.label === page }
            })}
          />
          <Divider classes={{ root: classes.divider }} />
        </>
      )}
    </>
  )
}

export default MyOrganizationMobileNav
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      backgroundColor: '#C2C9D177'
    }
  })
)
