import { createMuiTheme } from '@material-ui/core/styles'
import { fade } from '@material-ui/core/styles/colorManipulator'
import { merge } from 'lodash'
import { getFontFamily, defaulTypography, defaultButtonOverride, defaultLinkTheme } from './'
import palette from './palette'

export const AdminTheme = () =>
  createMuiTheme(
    merge({}, defaultLinkTheme, {
      palette: {
        primary: {
          main: palette.darkBlue,
          contrastText: palette.white
        },
        secondary: {
          main: palette.teal,
          contrastText: palette.white
        }
      },
      typography: {
        fontFamily: getFontFamily(),
        fontWeightRegular: 500
      },
      overrides: {
        MuiTypography: defaulTypography,
        MuiCardHeader: {
          root: {
            borderBottom: `1px solid ${palette.lightestgrey}`,
            padding: '12px 16px'
          },
          title: {
            fontSize: '16px',
            lineHeight: '24px'
          },
          action: {
            marginTop: '0'
          }
        },
        MuiCardContent: {
          root: {
            padding: '16px !important'
          }
        },
        MuiSwitch: {
          switchBase: {
            // Controls default (unchecked) color for the thumb
            color: palette.white,
            '&:hover': {
              color: palette.white
            }
          },
          colorSecondary: {
            '&$checked': {
              // Controls checked color for the thumb
              color: palette.teal,
              '&:hover': {
                backgroundColor: 'transparent'
              }
            }
          },
          track: {
            // Controls default (unchecked) color for the track
            opacity: 0.2,
            backgroundColor: '#000',
            '$checked$checked + &': {
              // Controls checked color for the track
              opacity: 0.7,
              backgroundColor: palette.teal
            }
          }
        },
        MuiChip: {
          root: {
            borderRadius: 4
          },
          colorPrimary: {
            backgroundColor: fade(palette.teal, 0.1),
            color: palette.teal
          },
          iconColorPrimary: {
            color: palette.teal
          },
          colorSecondary: {
            backgroundColor: fade(palette.red, 0.2),
            color: palette.red
          },
          iconColorSecondary: {
            color: palette.red
          },
          iconSmall: {
            marginLeft: 8,
            marginRight: -8
          }
        },
        MuiListItem: {
          root: {
            color: palette.darkestgrey,
            fontSize: 14,
            '&$selected': {
              color: palette.darkBlue,
              backgroundColor: palette.lightDarkBlue
            },
            '&$selected:hover': {
              color: palette.darkBlue,
              backgroundColor: palette.lightDarkBlue
            },
            '&$disabled': {
              opacity: 1
            },
            '&$focusVisible': {
              opacity: 1
            }
          }
        },
        MuiMenuItem: {
          root: {
            paddingTop: 10
          }
        },
        MuiTab: {
          root: {
            borderBottom: '1px solid #DBDCDF',
            color: palette.black,
            '&$selected': {
              color: palette.darkBlue
            },
            textTransform: 'unset',
            textColor: palette.darkBlue
          },
          wrapper: {
            flexDirection: 'row'
          }
        },
        MuiTabs: {
          indicator: {
            backgroundColor: palette.darkBlue
          }
        },
        MuiTableCell: {
          root: {
            backgroundColor: palette.white,
            color: palette.grey,
            padding: 8
          },
          stickyHeader: {
            backgroundColor: palette.white,
            color: palette.grey
          },
          head: {
            backgroundColor: palette.white,
            color: palette.grey
          }
        },
        MuiTableSortLabel: {
          root: {
            backgroundColor: palette.white,
            color: palette.grey
          }
        },
        MuiIconButton: {
          root: {
            color: palette.lightergrey,
            '&:hover': {
              color: palette.darkBlue
            }
          }
        },
        MuiButton: {
          ...defaultButtonOverride,
          contained: {
            backgroundColor: palette.white,
            color: palette.darkestgrey,
            boxShadow: '0px 1px 4px 1px rgba(0, 0, 0, 0.12)'
          },
          containedPrimary: {
            '&$disabled': {
              opacity: 0.5,
              color: '#ffffff',
              backgroundColor: '#0976BC'
            }
          },
          root: {
            textTransform: 'none'
          }
        },
        MuiTablePagination: {
          root: { color: palette.grey, backgroundColor: palette.white },
          spacer: {
            flex: 'none'
          }
        },
        MuiInputLabel: {
          asterisk: { color: 'red' }
        }
      }
    })
  )
