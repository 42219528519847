import { get, put, post } from './utils'

class ConversationNotificationsApi {
  static update(formData) {
    return put(`/conversation_notifications/${formData.id}`, {
      body: JSON.stringify({ conversation_notification: formData })
    })
  }

  static create(formData, conversationId) {
    return post(`/conversation_notifications?conversationId=${conversationId}`, {
      body: JSON.stringify({ conversation_notification: formData })
    })
  }

  static getAll(conversationId) {
    return get(`/conversation_notifications?conversationId=${conversationId}`)
  }
}

export default ConversationNotificationsApi
