import React, { useContext, useEffect } from 'react'
import { flow } from 'lodash'
import ActionListItem from '../../actions/ActionListItem'
import { observer } from 'mobx-react'
import { Chip, createStyles, Divider, Grid, makeStyles, Paper, Theme, Typography } from '@material-ui/core'
import { AuthContext, ParamConversationContext, ParamTopicContext } from '../../Providers'

const ActionPaneSwitcher = ({ id }: { id?: string }) => {
  const { user } = useContext(AuthContext)
  const { conversation } = useContext(ParamConversationContext)
  const { topic } = useContext(ParamTopicContext)
  const classes = useStyles()

  useEffect(() => {
    topic && topic.shouldLoad('topicActions', topic.loadTopicActions)
  }, [topic])

  useEffect(() => {
    if (user) {
      user.shouldLoad('userActions', user.loadUserActions)
      user.shouldLoad('userConversations', user.userConversations)
    }
  }, [user])
  if (!user || !conversation || !topic) {
    return <></>
  }
  const topicActions = topic.topicActions.map((ta) => ta.id)
  const userActions = user.userActions.filter((ua) => ua.actionId && topicActions.includes(ua.actionId))
  const completedCount = userActions.filter((ua) => !!ua.finishedAt).length
  const totalActionCount = topic.topicActions.length
  return (
    <>
      {(conversation.isGuest() || conversation.isHost) && topic.topicActions.length > 0 && (
        <div className={classes.root} id={id}>
          <div>
            <Typography variant='h5' className='reg-bottom' align='center'>
              Take Action For {topic.title}
            </Typography>
            <Typography variant='body2' align='center'>
              As someone who is interested in talking about {topic.title} and who wants to make positive change in the world, you’ll want to use our actions checklist. Check off
              actions as you complete them and we’ll keep a tally in your profile.
            </Typography>
            <Divider className={classes.divider} />
          </div>
          <div>
            <Grid container justify='space-between' className='reg-bottom'>
              <Grid item>Checklist</Grid>
              <Grid item>
                <Chip label={`${completedCount} of ${totalActionCount} Completed`} classes={{ root: classes.chip }} size='small' />
              </Grid>
            </Grid>

            <Paper variant='outlined' classes={{ root: classes.actionsContainer }}>
              {topic.topicActions.map((action) => (
                <ActionListItem key={action.id} action={action} conversation={conversation} />
              ))}
            </Paper>
          </div>
        </div>
      )}
    </>
  )
}

export default flow(observer)(ActionPaneSwitcher)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '2em 0',
      width: '100%'
    },
    divider: {
      backgroundColor: '#F7BF14',
      margin: '1.5em 0',
      height: '2px'
    },
    chip: {
      borderRadius: '4px'
    },
    actionsContainer: {
      width: '100%',
      borderRadius: '6px',
      border: '1px solid #CDCFD2'
    }
  })
)
