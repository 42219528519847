import React from 'react'
import { Card, CardHeader, CardContent, Grid } from '@material-ui/core'
import { ResponsiveLine, Serie } from '@nivo/line'
import TopLineStats from './TopLineStats'
import classes from '../../../../stylesheets/admin/ResponsiveLineCard.module.scss'
import LineGraphToolTip from './LineGraphToolTip'
import moment from 'moment-timezone'
import PageLoadingAnimation from '../../../shared/PageLoadingAnimation'

interface Props {
  title: string
  totalStats?: any
  inLineStats?: any
  graphData: Serie[]
  chartColor: any
  headerAction?: React.ReactNode
  yLabel?: string
  loading?: boolean
  id?: string
}

const decorateTimeFormat = (data) => {
  let axis = data?.length > 15 ? 'Q/YY' : 'MMM YY'
  let tooltip = 'MMMM YYYY'
  if (data) {
    const startAt = data[0]?.x
    const endAt = data[data.length - 1]?.x
    const diff = moment(endAt).diff(moment(startAt), 'days')

    switch (true) {
      case diff < 20:
        axis = data.length > 15 ? 'M/D' : 'ddd D'
        tooltip = 'MMMM DD, YYYY'
        break
      case diff < 50:
        axis = 'MMM D'
        break
      case diff < 366:
        axis = 'MMM'
        break
      default:
        tooltip = '[Q]Q YYYY'
        break
    }
  }
  return { axis, tooltip }
}

const ResponsiveLineCard = ({ title, totalStats = {}, inLineStats, chartColor, graphData = [], headerAction, yLabel, loading, id }: Props) => {
  const timeFormatting = decorateTimeFormat(graphData[0]?.data)
  return (
    <Grid item xs={12} sm={6}>
      <Card className='max-height' id={id}>
        <CardHeader title={title} action={headerAction} />
        <CardContent>
          <div>
            <TopLineStats totalStats={totalStats} inLineStats={inLineStats} />

            <div className={inLineStats.length > 4 ? classes.responsiveLineContainerShort : classes.responsiveLineContainerLong}>
              {loading ? (
                <div style={{ height: '100%', width: '100%', paddingTop: '.7em' }}>
                  <PageLoadingAnimation />
                </div>
              ) : (
                <ResponsiveLine
                  data={graphData}
                  colors={chartColor}
                  margin={{ top: 40, right: 20, bottom: 50, left: 35 }}
                  enableGridY={false}
                  yScale={{ type: 'linear', min: 'auto', max: 'auto' }}
                  xFormat={(v) => `${moment(v).format(timeFormatting.tooltip)}`}
                  axisBottom={{
                    orient: 'bottom',
                    tickSize: 0,
                    format: (v: any) => moment(v).format(timeFormatting.axis)
                  }}
                  axisLeft={{
                    orient: 'left',
                    tickSize: 0,
                    tickValues: 4
                  }}
                  pointSize={8}
                  useMesh={true}
                  animate={true}
                  theme={{
                    fontSize: 12,
                    textColor: '#a2a3a8',
                    legends: {
                      text: {
                        fontWeight: 600,
                        fill: '#6d6e71',
                        fontFamily: 'avenir, sans-serif'
                      }
                    }
                  }}
                  tooltip={(input) => <LineGraphToolTip input={input.point} yLabel={yLabel} />}
                  legends={[
                    {
                      anchor: 'bottom',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: 50,
                      itemsSpacing: 6,
                      itemDirection: 'left-to-right',
                      itemWidth: 100,
                      itemHeight: 10,
                      itemOpacity: 1,
                      symbolSize: 12,
                      symbolShape: 'square',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)'
                    }
                  ]}
                />
              )}
            </div>
          </div>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default ResponsiveLineCard
