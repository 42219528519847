import React, { useCallback, useEffect, useMemo } from 'react'

import { observer } from 'mobx-react'
import { useAuth, useParamPartner, useUserTracks } from '../../Providers'
import { useHistory, useParams } from 'react-router'

export const MembershipHsRedirect = observer(() => {
  const { user, loaded, showSignup } = useAuth()
  const { loadHsMembership } = useUserTracks()
  const memberUsers = useMemo(() => [...(user?.membershipUsers || [])], [user])
  const { action } = useParams<{ action?: string }>()
  const history = useHistory()
  const { partner } = useParamPartner()

  const loadFullMembers = useCallback(() => {
    if (memberUsers) {
      memberUsers.filter((mu) => !mu.listLoaded).forEach((mu) => mu.loadFullList())
    }
  }, [memberUsers])
  useEffect(() => {
    loadFullMembers()
  }, [loadFullMembers])
  useEffect(() => {
    if (partner && user && action === 'new') {
      loadHsMembership()
        .then(user.reloadProfile)
        .then(() => memberUsers.forEach((mu) => mu.loadFullList()))
        .then(() => history.push(`/${partner?.slug}/journey`))
    } else if (loaded && !user && action === 'new') {
      showSignup()
    }
  }, [partner, user, action, history, loadHsMembership, loadFullMembers, memberUsers, loaded, showSignup])

  return <></>
})

export default MembershipHsRedirect
