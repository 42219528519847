import { forEach, isPlainObject } from 'lodash'

export function toFormData(prefix, obj, formData = new FormData()) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key]
      const computedKey = prefix ? `${prefix}[${key}]` : key

      if (value instanceof File) {
        formData.append(computedKey, value)
      } else if (typeof value === 'object' && !Array.isArray(value)) {
        toFormData(computedKey, value, formData)
      } else if (Array.isArray(value)) {
        forEach(value, (nestedValue) => {
          if (typeof nestedValue === 'object' && !Array.isArray(nestedValue)) {
            toFormData(`${prefix}[${key}]`, nestedValue, formData)
          } else {
            formData.append(`${prefix}[${key}][]`, nestedValue)
          }
        })
      } else {
        formData.append(computedKey, value)
      }
    }
  }

  return formData
}
export function toFormDataOld(prefix, data) {
  var formData = new FormData()
  forEach(data, (value, name) => {
    if (isPlainObject(value)) {
      forEach(value, (nestedValue, nestedName) => {
        formData.append(`${prefix}[${name}][${nestedName}]`, nestedValue)
      })
    } else if (Array.isArray(value)) {
      forEach(value, (nestedValue) => {
        formData.append(`${prefix}[${name}][]`, nestedValue)
      })
    } else {
      formData.append(`${prefix}[${name}]`, value)
    }
  })
  return formData
}
