import React from 'react'
import { RadioGroupControl } from '../../utils'
import { StepProps } from './NewStepper'
import withTranslation, { WithTranslation } from '../../hocs/withTranslation'

const ConversationTypeStep = ({ t, control }: StepProps & WithTranslation) => {
  return (
    <>
      <RadioGroupControl
        name='guestPolicy'
        control={control}
        defaultValue={'public'}
        options={[
          {
            label: t('conversation.publicGuestPolicy'),
            value: 'public'
          },
          {
            label: t('conversation.approvalGuestPolicy'),
            value: 'approval_only'
          }
        ]}
      />
    </>
  )
}

export default withTranslation(ConversationTypeStep)
