import React from 'react'
import i18n from 'i18next'
import MetaTags from 'react-meta-tags'
import { capitalize } from '../../decorators/textTools'

export default function defaultMetaTags(Component) {
  return class DefaultMetaTags extends React.PureComponent {
    render() {
      return (
        <div>
          <MetaTags>
            <title>Inclusivv Community Conversations to create a culture of Inclusion and Belonging.</title>
            <meta name='description' content={i18n.t('meta.defaultDescription')} />
            <meta name='image' property='og:image' content='https://civicdinners-manual-storage.s3.amazonaws.com/civicdinners_newsocialimage.png' />
            <meta
              name='description'
              property='og:description'
              content='Formerly known as Civic Dinners, Inclusivv helps brands, communities and individuals create meaningful connection through structured conversation.'
            />
          </MetaTags>

          <Component {...this.props} />
        </div>
      )
    }
  }
}
