import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'

import Success from './Success'
import withTranslation from '../../hocs/withTranslation'
import Dialog from '../../DialogWrapper'
import { IStore } from '../../../store/models'
import { useTranslation } from 'react-i18next'

const CreatedConversationDialog = (props) => {
  const { dialog, showDialog, changeLanguage, currentUser, conversation } = props
  const { skipProfileImage } = dialog.data
  const { t } = useTranslation()

  useEffect(() => {
    conversation && changeLanguage(conversation.locale)
  }, [conversation, changeLanguage])

  const afterClose = () => {
    if (!skipProfileImage && conversation && !currentUser.hasProfileImage) {
      showDialog('profileImage')
    }
  }

  if (!conversation) return <></>

  return (
    <Dialog dialog={dialog} name='attendingConversation' afterClose={afterClose} type='noPadding' closeIcon={true} title={t('invitation.success')}>
      <Success {...props} dialog={dialog} conversation={conversation} showDialog={showDialog} />
    </Dialog>
  )
}

export default inject<FIXME, FIXME>(
  (
    {
      mst: {
        auth: { user },
        dialogs: { showDialog, closeDialog },
        conversations
      }
    }: {
      mst: IStore
    },
    ownProps
  ) => {
    const conversationId = ownProps.dialog.data.conversation.id
    const conversation = conversations.list.find((d) => d.id === conversationId)
    if (!conversation && !conversations.loadingIds.includes(conversationId)) {
      conversations.getConversation(conversationId)
    }
    return {
      currentUser: user,
      conversation,
      showDialog,
      closeDialog
    }
  }
)(observer(withTranslation(CreatedConversationDialog)))
