import React, { useEffect, useState } from 'react'
import { ITopic, ITopicAction } from '../../../store'
import LoadingAnimation from '../../shared/LoadingAnimation'
import { observer } from 'mobx-react'
import withTranslation from '../../hocs/withTranslation'
import CircularProgress from '@material-ui/core/CircularProgress'
import RsvpQuestion from './RsvpQuestion'
type Props = {
  topic: ITopic
  onDone?: any
  onCancel?: any
}
type Answer = {
  surveyQuestionId: number
  answer: string
  topicId: number
  value?: number
}
const RsvpQuestionaire = ({ topic, onDone, onCancel }: Props) => {
  const [stepIndex, setStepIndex] = useState(0)
  const [completed, setCompleted] = useState(false)
  const { surveyQuestions: questions } = topic
  const questionCount = questions.length
  const currentQuestion = questions[stepIndex]
  const lastQuestion = stepIndex + 1 === questionCount

  const [answers, setAnswers] = useState<Answer[]>([])

  const handleNext = (values) => {
    if (values.answer) {
      const answer = {
        surveyQuestionId: currentQuestion.id,
        answer: values.answer,
        value: values.value,
        topicId: topic.id
      }
      setAnswers([...answers, answer])
    }

    if (stepIndex < questionCount - 1) {
      setStepIndex((prevStep) => prevStep + 1)
    } else {
      setStepIndex(0)
      setCompleted(true)
    }
  }

  useEffect(() => {
    if (completed) {
      setCompleted(false)
      topic.saveSurveyAnswers(answers).then(() => onDone && onDone())
    }
  }, [completed, onDone, topic, answers])

  const handleBack = () => {
    if (stepIndex > 0) {
      setStepIndex((prevStep) => prevStep - 1)
    }
  }

  useEffect(() => {
    if (topic?.surveyQuestions.length === 0) {
      topic?.loadSurveyQuestions()
    }
  }, [topic])

  return questions.length === 0 ? (
    <div className='row center'>
      <CircularProgress size={20} thickness={1.5} color={'secondary'} />
    </div>
  ) : (
    <div>
      <RsvpQuestion question={currentQuestion} handleNext={handleNext} handleBack={handleBack} onCancel={onCancel} />
    </div>
  )
}

export default withTranslation(observer(RsvpQuestionaire))
