import fetch from 'isomorphic-fetch'
import appAnalytics from '../analytics/index'
import { toFormData } from './FormData'
import { get, deleteApi, post, put } from './utils'
import queryString from 'query-string'

class TopicSubscribersApi {
  getAll(topic_id, args) {
    const defaultParams = { page: 0, site_name: window.siteName }
    const params = queryString.stringify({ ...defaultParams, ...args })
    return get(`/admin/topic_subscribers/${topic_id}?${params}`)
  }
}

export default new TopicSubscribersApi()
