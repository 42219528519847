import { types, Instance } from 'mobx-state-tree'
import npsAnswersApi from '../../api/admin/NpsAnswersApi'
import { Alertable, Filterable, IFilterableArgs, LoadableStatus } from './helpers'
import { removeNullStringValue } from './utils'
import CsvExportsApi from '../../api/CsvExportsApi'
import { RootAccessable } from './helpers/RootAccessable'

export const NpsAnswer = types
  .model('NpsAnswer', {
    id: types.maybe(types.number),
    conversationId: types.maybeNull(types.number),
    npsQuestionId: types.maybeNull(types.number),
    organizationId: types.maybeNull(types.number),
    partnerId: types.maybeNull(types.number),
    topicId: types.maybeNull(types.number),
    userId: types.maybeNull(types.number),
    score: types.maybeNull(types.number),
    reason: types.maybeNull(types.string),
    userName: types.maybeNull(types.string),
    partnerName: types.maybeNull(types.string),
    conversationTitle: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.string)
  })
  .actions((self) => ({
    deleteNps: () => {
      return self.id ? npsAnswersApi.destroy(self.id) : Promise.resolve()
    }
  }))

export const NpsAnswers = types
  .compose(
    'NpsAnswers',
    LoadableStatus,
    Alertable,
    Filterable,
    RootAccessable,
    types.model({
      list: types.optional(types.array(NpsAnswer), [])
    })
  )
  .actions((self) => ({
    setList: (data: any[], totalCount?: number) => {
      data.forEach((d) => removeNullStringValue(d))
      self.list.replace(data)
      self.setTotalCount(totalCount)
    }
  }))
  .actions((self) => ({
    loadStoriesTable: (args: IFilterableArgs) => {
      const lisName = 'nps_answers_table'
      if (self.isLoading(lisName)) {
        return
      }
      self.startLoading(lisName)

      return npsAnswersApi.getAll(args).then(({ response: { ok, statusText }, json: { nps_answers, total } }) => {
        if (ok) {
          self.setList(nps_answers, total)
        } else {
          self.showAlert(statusText)
        }
        self.stopLoading(lisName)
      })
    }
  }))
  .actions((self) => ({
    loadFilter: ({ reset = false, clear = true } = {}) => {
      if (reset) {
        self.page = 0
        self.pageSize = 15
        self.search = ''
      }

      if (clear) {
        self.list.replace([])
      }

      const { search, pageSize: page_size, page, sortedId: sorted_id, sortedPriority: sorted_priority } = self
      return self.loadStoriesTable({ search, page_size, page, sorted_id, sorted_priority })
    },
    createCsvExport: ({ parameters }: { parameters?: any } = {}) => {
      const { user } = self.getAuth()
      user?.trackOnChurnzero() && window.churnZero.track('Count CSV Export', 'nps_answers')
      return CsvExportsApi.create({ csvType: 'nps_answer', parameters })
        .then((res) => {
          const { ok, status, statusText } = res.response

          if (ok) {
            self.showAlert('Exported')
          }
        })
        .catch((error) => {
          throw error
        })
    }
  }))
export interface INpsAnswers extends Instance<typeof NpsAnswers> {}
export interface INpsAnswer extends Instance<typeof NpsAnswer> {}
export default NpsAnswers
