import { Button, Card, Paper, Typography } from '@material-ui/core'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { IDomain } from '../../../../store'
import { useParamPartner } from '../../../Providers'
import { TextFieldControl } from '../../../utils'
import * as validations from '../../../validations/fieldLevelValidations'

type Props = {
  //  todo
}

export default observer(function Domains(props: Props) {
  const [newForm, setNewForm] = useState(false)
  const [edit, setEdit] = useState<IDomain | undefined>(undefined)
  const { handleSubmit, errors, formState, control, reset } = useForm({ mode: 'onChange' })
  const { isDirty, isSubmitting, isValid } = formState

  const { partner } = useParamPartner()
  const handleCreate = handleSubmit((data: any, e) => {
    partner?.updatePartner({ domains_attributes: [data] }).then((resp) => {
      reset({}, { isDirty: false })
      setNewForm(false)
    })
  })
  const handleUpdate = handleSubmit((data: any, e) => {
    partner?.updatePartner({ domains_attributes: [{ id: edit?.id, ...data }] }).then((resp) => {
      reset({}, { isDirty: false })
      setEdit(undefined)
    })
  })
  const domainValidations = { validate: { required: validations.required, domain: validations.domain, domainWhitelist: validations.domainWhitelist } }
  return (
    <div>
      <Typography className='reg-bottom'>
        If your organization has one or more Internet domains used for email services, you can enter those domains on this screen to link them to your account. Adding one or more
        domains enables us to link users whose email addresses include those domains to your account.
      </Typography>
      <Typography className='reg-bottom'>
        To add a domain, click Add Domain and enter the portion of the domain after the @ in the email address. For example, if your organization’s email addresses are
        firstname.lastname@acme.com, you would enter acme.com in the Domain field. If your organization uses multiple email domains, you can enter more than one domain below. For
        example, an organization might have acme.com, mail.acme.com, hq.acme.com, and office.acme.com along with a subsidiary organization that uses acme2.com. All those domains
        could be entered.
      </Typography>
      <Typography className='fat-bottom'>
        Domains for generic email service providers used by multiple organizations like gmail.com, yahoo.com, or hotmail.com should not be entered.
      </Typography>
      {!edit && !newForm && (
        <Button variant='outlined' onClick={() => setNewForm((prevSt) => !prevSt)}>
          {!newForm ? 'Add Domain' : 'Cancel'}
        </Button>
      )}
      {!edit && newForm && (
        <form onSubmit={handleCreate}>
          <TextFieldControl name='domain' control={control} error={errors && errors.domain} label={'Domain'} required rules={domainValidations} className='reg-bottom' />

          <Button className='giant-top reg-right' color='secondary' variant='contained' onClick={() => setNewForm(false)}>
            Cancel
          </Button>
          <Button disabled={!isValid || !isDirty || isSubmitting} type='submit' className='giant-top' color='secondary' variant='contained'>
            Submit
          </Button>
        </form>
      )}
      {edit && (
        <form onSubmit={handleUpdate}>
          <TextFieldControl
            name='domain'
            control={control}
            error={errors && errors.domain}
            defaultValue={edit.domain}
            label={'Domain'}
            required
            rules={domainValidations}
            className='reg-bottom'
          />

          <Button className='giant-top reg-right' color='secondary' variant='contained' onClick={() => setEdit(undefined)}>
            Cancel
          </Button>

          <Button disabled={!isValid || !isDirty || isSubmitting} type='submit' className='giant-top' color='secondary' variant='contained'>
            Submit
          </Button>
        </form>
      )}
      <div>
        {partner?.domains.map((domain) => (
          <Paper key={domain.id} variant='outlined' className='reg-top reg-left-padding reg-top-padding reg-bottom-padding reg-right-padding'>
            <div className='reg-bottom'>Domain: {domain.domain}</div>
            <Button
              variant='outlined'
              className='reg-right'
              onClick={() => {
                setEdit(domain)
              }}
              disabled={edit === domain}
            >
              Edit
            </Button>
            <Button
              variant='outlined'
              onClick={() => {
                if ((window as FIXME).confirm('Are you sure you want to delete this Domain')) {
                  partner?.updatePartner({ domains_attributes: [{ id: domain.id, _destroy: '1' }] })
                }
              }}
              disabled={edit === domain}
            >
              Delete
            </Button>
          </Paper>
        ))}
      </div>
    </div>
  )
})
