import { Breadcrumbs, Typography } from '@material-ui/core'
import React from 'react'
import OrganizationForm from './Form'
import SummaryContainer from '../partials/SummaryContainer'
import { IStore } from '../../../../store/models'
import { inject } from 'mobx-react'
import { observer } from 'mobx-react'
import { flow } from 'lodash'
import AdminMetaTags from '../../../shared/AdminMetaTags'
import { Link } from 'react-router-dom'

import { withRouter, RouteComponentProps } from 'react-router'

interface Props {
  createOrganization: any
}

const New = ({ createOrganization, history }: Props & RouteComponentProps) => {
  return (
    <div>
      <AdminMetaTags title='Organizations' pageType='New' />

      <Breadcrumbs aria-label='breadcrumb'>
        <Link color='primary' to='/admin/settings'>
          Settings
        </Link>
        <Typography color='textPrimary'>New Organization</Typography>
      </Breadcrumbs>
      <SummaryContainer title={'New Organization'}>
        <div className='row'>
          <div className='col-xs-12 form-lg'>
            <OrganizationForm
              page={'new'}
              onSave={(data) => data && history.push(`/admin/organizations/${data.id}/edit`)}
              createOrganization={(val) => createOrganization && createOrganization(val)}
            />
          </div>
        </div>
      </SummaryContainer>
    </div>
  )
}

export default flow(
  observer,
  inject<FIXME, FIXME>(
    ({
      mst: {
        auth: { user },
        organizations
      }
    }: {
      mst: IStore
    }) => {
      return {
        createOrganization: organizations.createOrganization
      }
    }
  ),
  withRouter
)(New)
