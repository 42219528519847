import React, { useEffect, useState } from 'react'
import { flow, sortBy } from 'lodash'
import PageLoadingAnimation from '../shared/PageLoadingAnimation'
import ListCard from '../topics/ListCard'
import { observer } from 'mobx-react'
import { inject } from 'mobx-react'
import { IPartner, IStore, IUser } from '../../store/models'
import { useLocation, withRouter } from 'react-router'
import { Button, Card } from '@material-ui/core'
interface Props {
  user: IUser
  partner: IPartner
  loading: boolean
  history: any
}

const PartnersTopicsEdit = ({ loading, partner, history }: Props) => {
  useEffect(() => {
    partner?.shouldLoad('topics', partner.loadTopics)
    partner?.shouldLoad('topicOptions', partner.loadTopicOptions)
  }, [partner])

  if (loading) {
    return <PageLoadingAnimation />
  }

  return (
    <div className='xtra-fat-bottom'>
      {sortBy(partner.topics, ['title']).map((topic) => (
        <ListCard
          key={topic.id}
          topic={topic}
          partnerId={partner.id}
          onEdit={() => {
            history.push(`/admin/topics/${topic.slug}/edit`)
          }}
        />
      ))}
    </div>
  )
}

export default flow(
  withRouter,
  observer,
  inject<FIXME, FIXME>(
    ({
      mst: {
        auth: { user }
      }
    }: {
      mst: IStore
    }) => {
      return {
        user
      }
    }
  )
)(PartnersTopicsEdit)
