import ReactGA from 'react-ga'
import { assign } from 'lodash'
import { capitalize } from '../decorators/textTools'

class AppAnalytics {
  constructor({ googleTrackingId }) {
    ReactGA.initialize(googleTrackingId, {
      testMode: process.env.NODE_ENV !== 'production'
    })
    // eslint-disable-next-line no-console
    window.debugMode && console.log('GoogleAnalytics', 'init')
  }

  pageview(location: { pathname: string; search: string }) {
    const path = location.pathname + location.search
    ReactGA.pageview(path)
    // eslint-disable-next-line no-console
    window.debugMode && console.log('GoogleAnalytics', 'pageview', { path })
  }

  event(data: EventData) {
    data = assign({}, data, {
      dimension4: window.location.pathname,
      ...(data.nextPath && { dimension5: data.nextPath }),
      ...(data.featured && { dimension6: data.featured }),
      ...(data.conversationId && { dimension7: data.conversationId }),
      label: capitalize(window.siteTitle)
    })
    ReactGA.event(data)
    // eslint-disable-next-line no-console
    window.debugMode && console.log('GoogleAnalytics', 'event', { data })
  }

  set(data: any) {
    ReactGA.set(data)
    // eslint-disable-next-line no-console
    window.debugMode && console.log('GoogleAnalytics', 'set', { data })
  }
}
interface EventData {
  category: string
  action: string
  value?: number
  nextPath?: string
  featured?: string | boolean
  conversationId?: number
}
const appAnalytics = new AppAnalytics({ googleTrackingId: 'UA-82147322-2' })
export default appAnalytics
