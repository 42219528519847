import { get, deleteApi, post, put } from './utils'
import queryString from 'query-string'

class MembershipsApi {
  getFullList(id) {
    return get(`/memberships/${id}/membership_users`)
  }
  getPendingList(id) {
    return get(`/memberships/${id}/pending`)
  }
  removeMembership(membershipId, id) {
    return deleteApi(`/memberships/${membershipId}/membership_users/${id}`)
  }
  unremoveMembership(membershipId, id) {
    return put(`/memberships/${membershipId}/membership_users/${id}/add`)
  }
  updateRole(membershipId, id, role) {
    return put(`/memberships/${membershipId}/membership_users/${id}`, {
      body: JSON.stringify({ role })
    })
  }
  updateMembershipUserType(membershipId, id, membershipType) {
    return put(`/memberships/${membershipId}/membership_users/${id}`, {
      body: JSON.stringify({ membershipType })
    })
  }
  updateMembership(membershipId, membership) {
    return put(`/memberships/${membershipId}`, {
      body: JSON.stringify({ membership })
    })
  }
  inviteMembers(membershipId, id, emails, message) {
    return put(`/memberships/${membershipId}/invite/`, {
      body: JSON.stringify({ emails, message })
    })
  }
}

export default new MembershipsApi()
