import queryString from 'query-string'
import appAnalytics from '../analytics'
import { toFormData } from './FormData'
import { get, put, post, deleteApi } from './utils'

class PartnersTableApi {
  static getAll(args) {
    const defaultParams = { page: 0, site_name: global.siteName }
    const params = queryString.stringify({ ...defaultParams, ...args })

    return get(`/admin/partners?${params}`)
  }
}

export default PartnersTableApi
