import React, { useCallback, useEffect, useState } from 'react'
import { Button, Divider, Tab, Tabs } from '@material-ui/core'
import SummaryContainer from '../partials/SummaryContainer'
import ConversationTable from './ConversationsTable'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import SummaryStats from '../partials/SummaryStats'
import { withRouter } from 'react-router'
import { flow } from 'lodash'
import { inject, observer } from 'mobx-react'
import { IStore } from '../../../../store/models'
import appAnalytics from '../../../../analytics'

interface Props {
  history: any
  conversations_total: number
  hosts_total: number
  guests_total: number
  uniq_guests_total: number
  upcoming_conversations: number
  past_conversations: number
  topicId: number | undefined
  loadStats()
  setTimeScope(val: string)
  loadFilter(arg)
  setSortedPriority(val: boolean)
  setSortingId(val: string)
}

const ConversationSummary = ({
  history,
  conversations_total,
  hosts_total,
  guests_total,
  uniq_guests_total,
  loadStats,
  setTimeScope,
  loadFilter,
  setSortedPriority,
  setSortingId,
  upcoming_conversations,
  past_conversations,
  topicId
}: Props) => {
  const [index, setIndex] = useState(0)
  const resetList = useCallback(() => {
    setTimeScope('upcoming')
    setSortingId('startsAt')
    setSortedPriority(false)
    loadStats()
  }, [setTimeScope, setSortingId, setSortedPriority, loadStats])
  // const resetList = () => {
  //   setTimeScope('upcoming')
  //   setSortingId('startsAt')
  //   setSortedPriority(false)
  //   loadStats()
  // }
  useEffect(() => {
    resetList()
  }, [resetList])
  useEffect(() => {
    resetList()
    loadFilter({ reset: true, clear: true })
  }, [resetList, loadFilter, topicId])
  return (
    <div>
      <SummaryContainer
        title={'Conversation Summary'}
        id='ic-admin-dashboard'
        topRight={
          <Button
            color='primary'
            onClick={() => {
              history.push('/admin/conversations')
            }}
          >
            All Conversations <ArrowForwardIcon color='primary' />
          </Button>
        }
      >
        <SummaryStats
          data={[
            { title: 'Total Conversations', value: conversations_total },
            { title: 'Total Unique Hosts', value: hosts_total },
            { title: 'Total Unique Guests', value: uniq_guests_total },
            { title: 'Total Guests', value: guests_total }
          ]}
        />
        <Tabs
          value={index}
          onChange={(e, val) => {
            setIndex(val)
            setSortingId('startsAt')
            if (val === 1) {
              setTimeScope('past')
              setSortedPriority(true)
            } else {
              setTimeScope('upcoming')
              setSortedPriority(false)
            }
            loadFilter({ reset: true, clear: true })
          }}
          indicatorColor='primary'
          textColor='primary'
          variant='fullWidth'
          centered
        >
          <Tab label={`Upcoming (${upcoming_conversations})`} />
          <Tab label={`Past (${past_conversations})`} />
        </Tabs>
        <ConversationTable index={index} />
      </SummaryContainer>
    </div>
  )
}

export default flow(
  observer,
  inject<FIXME, FIXME>(
    ({
      mst: {
        partners: { conversations_total, hosts_total, guests_total, uniq_guests_total, loadStats, upcoming_conversations, past_conversations },
        conversations: { setTimeScope, loadFilter, setSortedPriority, setSortingId, topicId }
      }
    }: {
      mst: IStore
    }) => {
      return {
        loadFilter,
        setTimeScope,
        conversations_total,
        hosts_total,
        guests_total,
        uniq_guests_total,
        loadStats,
        setSortedPriority,
        setSortingId,
        upcoming_conversations,
        past_conversations,
        topicId
      }
    }
  ),
  withRouter
)(ConversationSummary)
