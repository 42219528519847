import { toFormData } from './FormData'
import { deleteApi, post, put, get } from './utils'
import queryString from 'query-string'

class TopicSubscribeApi {
  topicSubscribe(topicId: number) {
    return post('/subscribe', {
      body: JSON.stringify({ likable: { likable_id: topicId, likable_type: 'Topic', kind: 'subscribe' } })
    })
  }

  topicUnsubscribe(id: number) {
    return deleteApi(`/subscribe/${id}`)
  }
}

export default new TopicSubscribeApi()
