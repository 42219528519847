import { Button, createStyles, Dialog, DialogActions, DialogContent, Grid, IconButton, Link, Theme, Typography, withStyles, WithStyles } from '@material-ui/core'
import React from 'react'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import { CloseIcon } from '@material-ui/data-grid'
import { Link as RouterLink } from 'react-router-dom'
import { useParamConversation } from '../../../Providers'
import appAnalytics from '../../../../analytics'
import { useTranslation } from 'react-i18next'
import ShareConversation from '../../partials/ShareConversation'
import ShareDropdown from '../../partials/ShareDropdown'

interface Props {
  children: FIXME
}

const WhatsNext = (props: Props) => {
  const [open, setOpen] = React.useState(false)
  const { conversation } = useParamConversation()
  const { t } = useTranslation()
  const host = conversation?.host

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  if (!conversation) return <></>
  return (
    <div>
      <Link color='primary' onClick={handleClickOpen}>
        {props.children}
      </Link>
      <Dialog onClose={handleClose} open={open} maxWidth='sm'>
        <DialogTitle onClose={handleClose}>What’s Next?</DialogTitle>
        {/* <DialogContent dividers> */}
        <div style={{ backgroundColor: '#F6F7F9', padding: '1rem' }}>
          <div style={{ fontSize: '2rem', margin: '0 auto .5rem auto', textAlign: 'center', color: '#388465' }}>
            <i className='far fa-star'></i>
          </div>
          <Typography gutterBottom variant='h6' align='center'>
            Get Ready to Host
          </Typography>
        </div>
        <div style={{ padding: '1rem 3rem' }}>
          <Typography variant='subtitle2' className='reg-bottom'>
            Add a Bio
          </Typography>
          <Typography variant='body1' className='fat-bottom'>
            Add a photo and brief bio to your <RouterLink to={`/users/${host?.id}/edit`}>profile</RouterLink> so your guests can get to know you in the Meet the Host section.
          </Typography>
          <Typography variant='subtitle2' className='reg-bottom'>
            Download the Host Guide
          </Typography>
          <Typography variant='body1' className='fat-bottom'>
            Download the{' '}
            <Link
              onClick={() => {
                appAnalytics.event({
                  category: 'Conversation',
                  action: 'Download Host Guide (Whats next?)'
                })
                conversation.hostGuideUrl && window.open(conversation.hostGuideUrl.replace(/dl=0/gi, 'dl=1'))
              }}
            >
              host guide
            </Link>{' '}
            to review the conversation questions and faciltaton best practices.
          </Typography>
          <Typography variant='subtitle2' className='reg-bottom'>
            Spread the Word
          </Typography>
          <Typography variant='body1' className='fat-bottom'>
            Let people know about the conversation you’re hosting! Invite others by email or share on social media.
          </Typography>
        </div>
        {/* </DialogContent> */}
        <DialogActions style={{ padding: '1rem 3rem' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {conversation.full || conversation.registrationClosed ? (
                <Typography>
                  {t('conversation.eventIsFull', {
                    event: conversation.isVirtual ? conversation.virtualEventTypeSingular : conversation.eventTypeSingular
                  })}
                </Typography>
              ) : (
                <Button
                  variant='contained'
                  color='primary'
                  fullWidth
                  size='large'
                  onClick={() => {
                    conversation.invite()
                  }}
                >
                  {t('common.inviteFriends')}
                </Button>
              )}
            </Grid>
            <Grid item xs={12}>
              <ShareDropdown
                conversation={conversation}
                trigger={
                  <Button fullWidth size='large' variant='outlined'>
                    Share
                  </Button>
                }
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
              />
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default WhatsNext
const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      color: '#212121',
      backgroundColor: '#ffffff'
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  })

export interface DialogTitleProps extends WithStyles<typeof styles> {
  children: React.ReactNode
  onClose: () => void
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6' align='center'>
        {children}
      </Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})
