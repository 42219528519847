import React from 'react'
import { Divider, Grid } from '@material-ui/core'
import { ValueBox } from '../partials/ValueBox'

interface Props {
  data: { title: string; value: string | number | undefined }[]
  bottomDivider?: boolean
}

const SummaryStats = ({ data, bottomDivider = true }: Props) => (
  <>
    <Grid container direction='row' justify='space-evenly' alignItems='center' className='reg-padding'>
      {data.map((d, i) => (
        <React.Fragment key={`${d.title}-fragment`}>
          <Grid item md={bottomDivider ? undefined : 2}>
            <ValueBox {...d} />
          </Grid>
          {i < data.length - 1 && <Divider orientation='vertical' variant='middle' flexItem style={{ maxWidth: '1px' }} />}
        </React.Fragment>
      ))}
    </Grid>
    {bottomDivider && <Divider />}
  </>
)

export default SummaryStats
