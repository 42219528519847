import React from 'react'
import { DialogTitle } from '@material-ui/core'
import { flow } from 'lodash'
import { Button } from '@material-ui/core'

import withTranslation, { WithTranslation } from '../../hocs/withTranslation'
import Dialog from '../../DialogWrapper'
import { TextFieldControl, RadioGroupControl, CheckboxControl } from '../../utils'
import { observer } from 'mobx-react'
import { useForm } from 'react-hook-form'
import { required } from '../../validations/fieldLevelValidations'
import { IDialog } from '../../../store/models/Dialog.js'
import { inject } from 'mobx-react'
import { IStore, IUser } from '../../../store'

interface Props {
  dialog: IDialog
}
interface InjectedProps {
  user?: IUser
}

const ConversationInvitationRequestDialog = ({ user, dialog, t }: Props & InjectedProps & WithTranslation) => {
  const { handleSubmit, errors, formState, control } = useForm({ mode: 'onBlur', shouldUnregister: false })

  if (!user) {
    return <></>
  }

  const { isSubmitting } = formState

  const { conversation } = dialog.data as any

  const handleSave = handleSubmit((data: any, e) => {
    conversation.createConversationInvitationRequest(data).then(dialog.closeDialog)
  })
  const Age = () => {
    return (
      <div className='row reg-bottom'>
        <div className='col-xs-12'>
          <p>{t('convos.ageForm.title')}</p>
          <RadioGroupControl
            name='ageRange'
            control={control}
            required
            defaultValue={user.ageRange}
            error={errors && errors.ageRange}
            rules={{ validate: required }}
            className='radio-group'
            options={[
              {
                label: t('convos.ageForm.under21Label'),
                value: 'under_21'
              },
              {
                label: '21 - 30',
                value: 'main_course_provided'
              },
              {
                label: '31 - 40',
                value: '31-40'
              },
              {
                label: '41 - 50',
                value: '41-50'
              },
              {
                label: '51 - 60',
                value: '51-60'
              },
              {
                label: '61+',
                value: 'over_61'
              }
            ]}
          />
        </div>
      </div>
    )
  }

  const Job = () => {
    return (
      <div className='row reg-bottom'>
        <div className='col-xs-12'>
          <p>{t('convos.jobForm.title')}</p>
          <RadioGroupControl
            name='occupation'
            defaultValue={user.occupation}
            control={control}
            required
            error={errors && errors.occupation}
            rules={{ validate: required }}
            className='radio-group'
            options={[
              {
                label: t('convos.jobForm.studentLabel'),
                value: 'student'
              },
              {
                label: t('convos.jobForm.partTimeLabel'),
                value: 'part_time'
              },
              {
                label: t('convos.jobForm.fullTimeLabel'),
                value: 'full_time'
              },
              {
                label: t('convos.jobForm.businessOwnerLabel'),
                value: 'business_owner'
              },
              {
                label: t('convos.jobForm.retireLabel'),
                value: 'retiree'
              }
            ]}
          />
        </div>
      </div>
    )
  }

  const Education = () => {
    return (
      <div className='row reg-bottom'>
        <div className='col-xs-12'>
          <p>{t('convos.eduForm.title')}</p>
          <RadioGroupControl
            name='education'
            defaultValue={user.education}
            control={control}
            required
            error={errors && errors.education}
            rules={{ validate: required }}
            className='radio-group'
            options={[
              {
                label: t('convos.eduForm.someHigh'),
                value: 'some_high_school'
              },
              {
                label: t('convos.eduForm.highGrad'),
                value: 'high_school_graduate'
              },
              {
                label: t('convos.eduForm.someCollege'),
                value: 'some_college'
              },
              {
                label: t('convos.eduForm.trade'),
                value: 'trade_school'
              },
              {
                label: t('convos.eduForm.collegeGrad'),
                value: 'college_graduate'
              },
              {
                label: t('convos.eduForm.somePost'),
                value: 'some_postgraduate'
              },
              {
                label: t('convos.eduForm.postGrad'),
                value: 'postgraduate_degree'
              }
            ]}
          />
        </div>
      </div>
    )
  }

  return (
    <Dialog dialog={dialog} name='conversationInvitationRequest' type='noPadding'>
      <div>
        <DialogTitle disableTypography>{t('convos.reqInvite')}</DialogTitle>

        {!window.coco && (
          <section className='pane fat-top'>
            <div className='row fat-row'>
              <div className='col-xs-12 center-xs'>
                <p className='header-18 line-height-26'>{t('convos.reqInfo')}</p>
              </div>
            </div>
          </section>
        )}

        <section className='pane thin-reg-pane big-top-padding big-bottom-padding'>
          <form onSubmit={handleSave}>
            <div className='xtra-fat-bottom'>
              {!window.coco && (
                <div>
                  <Age />
                  <Job />
                  <Education />
                </div>
              )}

              <div className='row reg-bottom'>
                <div className='col-xs-12'>
                  <TextFieldControl
                    name='message'
                    control={control}
                    defaultValue={conversation && conversation.message?.toString()}
                    error={errors && errors.message}
                    multiline={true}
                    rows={2}
                    rowsMax={4}
                    helperText={t('convos.writeMessage', {
                      name: conversation.host.firstName
                    })}
                  />
                </div>
              </div>

              {!window.coco && (
                <div className='row reg-bottom'>
                  <div className='col-xs-12'>
                    <CheckboxControl
                      name='showDemographicDataToHosts'
                      label={t('convos.allowDemoData')}
                      control={control}
                      error={errors && errors.showDemographicDataToHosts}
                      defaultValue={user.showDemographicDataToHosts}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className='row center-xs'>
              <div className='col-xs-12'>
                <Button disabled={isSubmitting} type='submit' className='reg-bottom xtra-fat-top' color='secondary' variant='contained'>
                  {t('common.submit')}
                </Button>
              </div>
            </div>
          </form>
        </section>
      </div>
    </Dialog>
  )
}

export default flow(
  observer,
  inject<InjectedProps, Props>(
    ({
      mst: {
        auth: { user }
      }
    }: {
      mst: IStore
    }) => {
      return {
        user
      }
    }
  ),
  withTranslation
)(ConversationInvitationRequestDialog)
