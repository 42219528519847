import { toFormData } from './FormData.js'
import queryString from 'query-string'
import { deleteApi, get, put, post } from './utils'
import appAnalytics from '../analytics'

class TopicsApi {
  static getAll(args) {
    const defaultParams = { limit: 15, offset: 0, site_name: global.siteName }
    const params = queryString.stringify({ ...defaultParams, ...args })

    return get(`/topics?${params}`)
  }
  static getByIds(ids) {
    const params = queryString.stringify({ site_name: global.siteName })
    return get(`/topics?${params}&ids=${ids}`)
  }

  static getAllOptions(args) {
    const defaultParams = { small: true, limit: 15, offset: 0, site_name: global.siteName }
    const params = queryString.stringify({ ...defaultParams, ...args })

    return get(`/topics?${params}`)
  }

  static get(slug) {
    return get(`/topics/${slug}`)
  }

  static create(data) {
    const formData = toFormData('topic', data)

    return post(`/topics?site_name=${global.siteName}`, {
      noContentTypeJson: true,
      body: formData
    }).then((d) => {
      appAnalytics.event({
        category: 'Topic',
        action: 'Created Topic'
      })
      return d
    })
  }

  static update(data) {
    const formData = toFormData('topic', data)

    return put(`/topics/${data.id}?site_name=${global.siteName}`, {
      noContentTypeJson: true,
      body: formData
    }).then((d) => {
      return d
    })
  }
  static inviteHost(data) {
    const formData = toFormData('topic', data)

    return put(`/topics/${data.id}/invite_host?site_name=${global.siteName}`, {
      noContentTypeJson: true,
      body: formData
    }).then((d) => {
      return d
    })
  }
  static inviteHostTest(data) {
    const formData = toFormData('topic', data)

    return put(`/topics/${data.id}/invite_host_test?site_name=${global.siteName}`, {
      noContentTypeJson: true,
      body: formData
    }).then((d) => {
      return d
    })
  }

  static destroy(id) {
    return deleteApi(`/topics/${id}`).then((data) => {
      appAnalytics.event({
        category: 'Topic',
        action: 'Deleted Topic'
      })
      return data
    })
  }
}

export default TopicsApi
