import { types, Instance } from 'mobx-state-tree'
import PendingRequestedGuestsApi from '../../../api/conversations/pendingRequestedGuestsApi'
import ConversationInvitationRequestsApi from '../../../api/ConversationInvitationRequestsApi.js'
import ConversationBase from './ConversationBase'

import { Dialogable } from '../helpers/Dialogable'
import { MetaImage } from '../base/BaseObjects'
import { snakify } from '../../../decorators/textTools'

const PendingRequested = types
  .model('PendingRequested', {
    id: types.identifierNumber,
    profileImage: types.maybe(MetaImage),
    hasProfileImage: types.maybeNull(types.boolean),
    firstName: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string)
  })
  .actions((self) => ({
    fullName: () => `${self.firstName} ${self.lastName}`
  }))

const ConversationRequests = types
  .compose(
    'ConversationRequests',
    ConversationBase,
    Dialogable,
    types.model({
      pendingRequested: types.optional(types.array(PendingRequested), [])
    })
  )
  .actions((self) => ({
    setPendingRequested(val: any) {
      self.pendingRequested.replace(val)
    },
    addToPendingRequested(val: any) {
      self.pendingRequested.push(val)
    },
    removePendingRequested(id: number) {
      self.pendingRequested.replace(self.pendingRequested.filter((g) => g.id !== id))
    }
  }))
  .actions((self) => ({
    loadPendingRequested: () => {
      const listName = 'pendingRequested'
      if (self.isLoading(listName)) return

      self.startLoading(listName)
      return PendingRequestedGuestsApi.getAll(self.id).then(({ response: { ok }, json }) => {
        if (ok) {
          self.setPendingRequested(json)
        }
        self.stopLoading(listName)
      })
    },
    handleAcceptRequest: (userId) => {
      return PendingRequestedGuestsApi.update({ id: userId, userId, conversationId: self.id, invite_accepted: 'true' }).then(({ response: { ok }, json }) => {
        if (ok) {
          self.removePendingRequested(userId)
          return json
        }
      })
    },
    acceptAllPendingRequest: () => {
      return PendingRequestedGuestsApi.update_all({ conversationId: self.id, invite_accepted: 'true' }).then(({ response: { ok }, json }) => {
        if (ok) {
          self.showAlert('Accepted all requests')
          return json
        }
      })
    },

    createConversationInvitationRequest: (data: any) => {
      const formData = snakify(data)
      const { user } = self.getAuth()

      if (user) {
        return user.updateProfile(formData, { alert: false }).then(() =>
          ConversationInvitationRequestsApi.create({ ...formData, user_id: user.id, conversation_id: self.id }).then(({ response: { ok }, json }) => {
            if (ok) {
              user.loadUserInvitationRequests({ reload: true })
              self.showDialog('conversationInvitationRequestSent', { conversation: self })
            } else {
              self.showAlert(json?.error || json?.errors?.message)
            }
          })
        )
      }
    }
  }))

export interface IConversationRequests extends Instance<typeof ConversationRequests> {}
export default ConversationRequests
