import React, { useCallback, useEffect, useState } from 'react'
import { WithTranslation as WithTranslationNext, useTranslation } from 'react-i18next'

export interface WithTranslation extends WithTranslationNext {
  changeLanguage(locale: string | string[])
  isLoaded: boolean
}

const withTranslation = <P extends unknown>(Component: React.ComponentType<P & WithTranslation>) => (props: P) => {
  const localeProps = useTranslation()
  const [isLoaded, setisLoaded] = useState(localeProps.i18n.isInitialized)
  const [currentLocale, setCurrentLocale] = useState('en_US')
  const { i18n } = localeProps,
    { language } = i18n

  useEffect(() => {
    const onLoaded = () => setisLoaded(true)
    if (!isLoaded) {
      i18n.on('loaded', onLoaded)
      i18n.on('languageChanged', setCurrentLocale)
    }
    return () => i18n.off('loaded', onLoaded)
  }, [isLoaded, i18n, setCurrentLocale])

  const changeLanguage = useCallback(
    (val) => {
      if (val) {
        const newLocale = typeof val === 'string' ? val : val[0]
        if (currentLocale !== newLocale) {
          setCurrentLocale(newLocale)
          localeProps.i18n.changeLanguage(newLocale)
        }
      }
    },
    [localeProps, currentLocale]
  )

  return <Component {...(props as P)} {...localeProps} language={language} tReady={localeProps.ready} changeLanguage={changeLanguage} isLoaded={isLoaded} />
}

export default withTranslation
