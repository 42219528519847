import { normalize } from 'normalizr'
import { SubmissionError } from 'redux-form'

import * as types from './actionTypes'
import topicsApi from '../api/TopicsApi'
import partnerTopicsApi from '../api/partners/TopicsApi'
import { showAlertMessage } from './alertMessageActions'
import { history } from '../components/browserHistory'
import { topicSchema, topicsSchema } from '../normalizers/topicNormalizers'
import RegionalTopicsApi from '../api/sites/RegionalTopicsApi'
import { isEmpty } from 'lodash'
import { getRegionalRecordBySlug } from '../regionalRecords'
import { redirectToPartner } from '../actions/partnerActions'

export const LoadingRankedTopics = () => ({
  type: types.RANKED_CONVERSATIONS_LOADING
})

export const LoadingTopics = () => ({
  type: types.CONVERSATIONS_LOADING
})

export const LoadingTopic = () => ({
  type: types.CONVERSATION_LOADING
})

export const loadNewTopicsSuccess = (json) => ({
  type: types.LOAD_NEW_CONVERSATIONS_SUCCESS,
  ...json
})

export const loadTopicsSuccess = (json) => ({
  type: types.LOAD_CONVERSATIONS_SUCCESS,
  ...json
})

export const loadRankedTopicsSuccess = (json) => ({
  type: types.LOAD_RANKED_CONVERSATIONS_SUCCESS,
  ...json
})

export const deleteTopicSuccess = (id) => ({
  type: types.DELETE_CONVERSATION_SUCCESS,
  data: id
})

export const loadTopicsFailed = (json) => ({
  type: types.LOAD_CONVERSATIONS_FAILED,
  data: json
})

export const loadRankedTopicsFailed = (json) => ({
  type: types.LOAD_RANKED_CONVERSATIONS_FAILED,
  data: json
})

export const loadTopicSuccess = (json) => ({
  type: types.LOAD_CONVERSATION_SUCCESS,
  ...json
})

export const partnerTopicsLoading = () => ({
  type: types.PARTNER_CONVERSATIONS_LOADING
})

export const loadPartnerTopicsFailed = (json) => ({
  type: types.LOAD_PARTNER_CONVERSATIONS_FAILED,
  data: json
})

export const loadPartnerTopicsSuccess = (json) => ({
  type: types.LOAD_PARTNER_CONVERSATIONS_SUCCESS,
  ...json
})

export const loadMorePartnerTopicsSuccess = (json) => ({
  type: types.LOAD_MORE_PARTNER_CONVERSATIONS_SUCCESS,
  ...json
})

const showAlert = (dispatch, message) => dispatch(showAlertMessage({ message }))

export function loadNewTopics({ limit, offset, scope }) {
  return function (dispatch) {
    dispatch(LoadingTopics())

    return topicsApi
      .getAll({ limit, offset, scope })
      .then((res) => {
        const { ok, status, statusText } = res.response

        if (ok) {
          const flatData = normalize(res.json, topicsSchema).entities
          dispatch(loadNewTopicsSuccess(flatData))
        } else {
          dispatch(loadTopicsFailed({ status, statusText }))
        }
      })
      .catch((error) => {
        throw error
      })
  }
}

export function loadTopics({ limit, offset, scope }) {
  return function (dispatch) {
    dispatch(LoadingTopics())

    return topicsApi
      .getAll({ limit, offset, scope })
      .then((res) => {
        const { ok, status, statusText } = res.response

        if (ok) {
          const flatData = normalize(res.json, topicsSchema).entities
          dispatch(loadTopicsSuccess(flatData))
        } else {
          dispatch(loadTopicsFailed({ status, statusText }))
        }
      })
      .catch((error) => {
        throw error
      })
  }
}

export function loadTopicsById(ids) {
  return function (dispatch) {
    dispatch(LoadingTopics())

    return topicsApi.getByIds(ids).then(({ response: { ok, status, statusText }, json }) => {
      if (ok) {
        dispatch(loadTopicsSuccess(normalize(json, topicsSchema).entities))
      } else {
        dispatch(loadTopicsFailed({ status, statusText }))
      }
    })
  }
}

export function loadRankedTopics({ limit, offset, scope }) {
  return function (dispatch) {
    dispatch(LoadingRankedTopics())

    return topicsApi
      .getAll({ limit, offset, scope })
      .then((res) => {
        const { ok, status, statusText } = res.response

        if (ok) {
          const flatData = normalize(res.json, topicsSchema).entities
          dispatch(loadRankedTopicsSuccess(flatData))
        } else {
          dispatch(loadRankedTopicsFailed({ status, statusText }))
        }
      })
      .catch((error) => {
        throw error
      })
  }
}

export function loadTopic(slug) {
  return function (dispatch) {
    dispatch(LoadingTopic())

    return topicsApi
      .get(slug)
      .then((res) => {
        const { ok, status, statusText } = res.response
        if (ok) {
          const flatData = normalize(res.json, topicSchema).entities
          dispatch(loadTopicSuccess(flatData))
        } else {
          dispatch(loadTopicsFailed({ status, statusText }))
        }
      })
      .catch((error) => {
        throw error
      })
  }
}

export function createTopic(formData) {
  return function (dispatch) {
    return topicsApi
      .create(formData)
      .then((response) => {
        if (response.response.ok) {
          history.push(`/${response.json.slug}`)
          showAlert(dispatch, 'Topic successfully created')
        } else {
          throw new SubmissionError(response.json)
        }
      })
      .catch((error) => {
        throw error
      })
  }
}

export function updateTopic(formData) {
  return function (dispatch) {
    return topicsApi
      .update(formData)
      .then((response) => {
        if (response.response.ok) {
          history.push(`/${response.json.slug}`)
          showAlert(dispatch, 'Topic successfully updated')
        } else {
          throw new SubmissionError(response.json)
        }
      })
      .catch((error) => {
        throw error
      })
  }
}

export function deleteTopic(id) {
  return function (dispatch) {
    return topicsApi
      .destroy(id)
      .then((response) => {
        if (response.ok) {
          dispatch(deleteTopicSuccess(id))
          history.push('/topics')
          showAlert(dispatch, 'Topic deleted')
        } else {
          showAlert(dispatch, `Topic delete failed: ${response.statusText}`)
        }
      })
      .catch((error) => {
        throw error
      })
  }
}

export function loadPartnerTopics(slug, args) {
  return function (dispatch) {
    dispatch(partnerTopicsLoading())
    return partnerTopicsApi.getAll(slug, args).then(({ response: { ok, status, statusText }, json }) => {
      if (ok) {
        const flatData = normalize(json, topicsSchema).entities
        dispatch(loadPartnerTopicsSuccess(flatData))
      } else {
        dispatch(loadPartnerTopicsFailed({ status, statusText }))
      }
    })
  }
}

export function loadMorePartnerTopics(slug, args) {
  return function (dispatch) {
    dispatch(partnerTopicsLoading())

    return partnerTopicsApi.getAll(slug, args).then(({ response: { ok, status, statusText }, json }) => {
      if (ok) {
        const flatData = normalize(json, topicsSchema).entities
        dispatch(loadMorePartnerTopicsSuccess(flatData))
      } else {
        dispatch(loadPartnerTopicsFailed({ status, statusText }))
      }
    })
  }
}

export function redirectToTopic(data, slug) {
  return (dispatch, getState) => {
    const { item: userCoords } = getState().userCoords

    if (isEmpty(userCoords)) return dispatch(redirectToPartner({ siteId: data.siteId })) // no user coords, redirect to partner page
    dispatch(LoadingTopic())

    return RegionalTopicsApi.getAll(data.siteId)
      .then((res) => {
        const { ok } = res.response

        if (ok) {
          let convo = getRegionalRecordBySlug(res.json, userCoords, slug)

          if (convo) {
            const flatData = normalize(convo, topicSchema).entities
            dispatch(loadTopicSuccess(flatData))

            history.push({
              pathname: `/${convo.slug}`,
              state: { noLoad: true }
            })
          } else {
            dispatch(redirectToPartner({ siteId: data.siteId }))
          }
        }
      })
      .catch((error) => {
        throw error
      })
  }
}
