import React from 'react'
import { useAuth } from '../../Providers/AuthProvider'
import { useParamConversation } from '../../Providers/ParamConversationProvider'
import appAnalytics from '../../../analytics'
import { camelCase, flow } from 'lodash'
import { observer } from 'mobx-react'
import { connectTopicTheme } from '../../../connectTheme'

import { TwitterShareButton, FacebookShareButton } from 'react-share'
import { Typography, createStyles, makeStyles, Grid } from '@material-ui/core'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { IConversation } from '../../../store'

interface Props {
  conversation?: IConversation
  theme?: any
  style: React.CSSProperties | undefined
  buttonContainerStyle: React.CSSProperties | undefined
  gridSizes?: any
  color?: string
  labelColor?: string
  align?: string
  longLabel?: boolean
}

const SocialMediaButton = ({ children, gridSizes = { xs: 12, md: 12, lg: 1 }, style }: any) => {
  const classes = Styles()
  return (
    <Grid item {...gridSizes} className={classes.linkContainer} style={style}>
      {children}
    </Grid>
  )
}
const CustomButton = observer(({ onClick, children, iconClassNames, color = '#ffffff', align = 'center', labelColor = '#ffffff' }: any) => {
  const classes = Styles()
  return (
    <button onClick={onClick} className={classnames('react-share__ShareButton', classes.button)} style={{ textAlign: align }}>
      <i className={classnames(iconClassNames, classes.icon)} style={{ color }} />
      <Typography component='span' classes={{ root: classes.label }} style={{ color: labelColor }}>
        {children}
      </Typography>
    </button>
  )
})
const ShareConversation = ({
  conversation: propConversation,
  // t,
  // userLoaded,
  style,
  gridSizes = { xs: 12, md: 12, lg: 1 },
  color = '#ffffff',
  labelColor = '#ffffff',
  align,
  longLabel = false,
  buttonContainerStyle = undefined
}: Props) => {
  const classes = Styles()
  const { conversation = propConversation } = useParamConversation()
  const { loaded: userLoaded } = useAuth()
  const { t } = useTranslation()
  const isHost = conversation?.isHost
  if (!conversation || !userLoaded || (userLoaded && !conversation.isAttending())) {
    return <></>
  }
  return (
    <Grid justify='center' container spacing={2}>
      <SocialMediaButton gridSizes={gridSizes} style={buttonContainerStyle}>
        <CustomButton
          align={align}
          color={color}
          labelColor={labelColor}
          onClick={() => {
            conversation.invite({ skipProfileImage: true, hideShare: true })
          }}
          iconClassNames={'fas fa-envelope'}
        >
          {t(longLabel ? 'socialMedia.shareByEmailLong' : 'socialMedia.shareByEmail')}
        </CustomButton>
      </SocialMediaButton>

      <SocialMediaButton gridSizes={gridSizes} style={buttonContainerStyle}>
        <FacebookShareButton
          onClick={() => {
            appAnalytics.event({
              category: 'Conversation',
              action: 'Share on Facebook'
            })
          }}
          className={classes.button}
          url={conversation.conversationLink}
          hashtag={camelCase(conversation.slug)}
          quote={t(isHost ? 'socialMedia.facebookConversationBodyHost' : 'socialMedia.facebookConversationBodyGuest', { conversationName: conversation.title })}
          style={{ textAlign: align as any }}
        >
          <i className={classnames('fab fa-facebook', classes.icon)} style={{ color }} />
          <Typography component='span' classes={{ root: classes.label }} style={{ color: labelColor }}>
            {t(longLabel ? 'socialMedia.shareOnFacebookLong' : 'socialMedia.shareOnFacebook')}
          </Typography>
        </FacebookShareButton>
      </SocialMediaButton>

      <SocialMediaButton gridSizes={gridSizes} style={buttonContainerStyle}>
        <CustomButton
          align={align}
          color={color}
          labelColor={labelColor}
          onClick={() => {
            appAnalytics.event({
              category: 'Conversation',
              action: 'Share on LinkedIn'
            })
            window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${conversation.conversationLink}`, '_blank', 'width=600,height=600')
          }}
          iconClassNames={'fab fa-linkedin-in'}
        >
          {t(longLabel ? 'socialMedia.shareOnLinkedInLong' : 'socialMedia.shareOnLinkedIn')}
        </CustomButton>
      </SocialMediaButton>
      <SocialMediaButton gridSizes={gridSizes} style={buttonContainerStyle}>
        <TwitterShareButton
          onClick={() => {
            appAnalytics.event({
              category: 'Conversation',
              action: 'Share on Twitter'
            })
          }}
          className={classes.button}
          url={conversation.conversationLink}
          style={{ textAlign: align as any }}
          hashtags={[camelCase(conversation.slug)]}
          title={t(isHost ? 'socialMedia.twitterConversationBodyHost' : 'socialMedia.twitterConversationBodyGuest', { conversationName: conversation.title })}
        >
          <i className={classnames('fab fa-twitter', classes.icon)} style={{ color }} />
          <Typography component='span' classes={{ root: classes.label }} style={{ color: labelColor }}>
            {t(longLabel ? 'socialMedia.shareOnTwitterLong' : 'socialMedia.shareOnTwitter')}
          </Typography>
        </TwitterShareButton>
      </SocialMediaButton>
      <SocialMediaButton gridSizes={gridSizes} style={buttonContainerStyle}>
        <CustomButton
          align={align}
          color={color}
          labelColor={labelColor}
          onClick={() => {
            navigator.clipboard.writeText(conversation.conversationLink)
            conversation.showAlert(t('socialMedia.copiedLink'))
          }}
          iconClassNames={'fas fa-link'}
        >
          {t(longLabel ? 'socialMedia.copyLinkLong' : 'socialMedia.copyLink')}
        </CustomButton>
      </SocialMediaButton>
    </Grid>
  )
}

const Styles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: '2em'
    },
    header: {
      fontWeight: 'normal',
      fontSize: '32px'
    },
    linkContainer: {
      margin: '0 1em'
    },
    label: {
      fontFamily: 'avenir, sans-serif !important',
      color: '#ffffff',
      fontSize: '18px !important',
      whiteSpace: 'nowrap',
      fontWeight: 400,
      alignItems: 'flex-end'
    },
    icon: {
      marginRight: '.4em',
      fontSize: '1.3em',
      alignItems: 'flex-end',
      color: '#ffffff',
      alignSelf: 'center'
    },
    button: {
      backgroundColor: 'transparent',
      border: 'none',
      padding: '0',
      font: 'inherit',
      color: 'inherit',
      cursor: 'pointer',
      outline: 'none',
      minWidth: '8em',
      '&:hover': {
        textDecoration: 'underline !important'
      }
    }
  })
)

export default flow(
  connectTopicTheme,
  observer
  // inject<InjectedProps, Props>(
  //   ({
  //     mst: {
  //       auth: { user, loaded: userLoaded }
  //     }
  //   }: {
  //     mst: IStore
  //   }) => {
  //     user && user.shouldLoad('userConversations', user.userConversations)

  //     return {
  //       user,
  //       userLoaded,
  //       count: user?.userConversations.length
  //     }
  //   }
  // ),
  // withTranslation
)(ShareConversation)
