import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { Provider as MSTProvider } from 'mobx-react'
import { ConnectedRouter as Router } from 'connected-react-router'
import { DefaultThemeProvider } from '../themes'
import App from './App'
import mst from '../store/models'
import ConversationProvider from './Providers/ConversationProvider'
import TopicProvider from './Providers/TopicProvider'
import UserTracksProvider from './Providers/UserTracksProvider'
import UsersProvider from './Providers/UsersProvider'

const Root = ({ store, history }) => (
  <React.Suspense fallback='loading'>
    <Provider store={store}>
      <MSTProvider mst={mst}>
        <DefaultThemeProvider>
          <UsersProvider>
            <TopicProvider>
              <UserTracksProvider>
                <ConversationProvider>
                  <Router history={history} onUpdate={hashLinkScroll}>
                    <App />
                  </Router>
                </ConversationProvider>
              </UserTracksProvider>
            </TopicProvider>
          </UsersProvider>
        </DefaultThemeProvider>
      </MSTProvider>
    </Provider>
  </React.Suspense>
)

Root.propTypes = {
  store: PropTypes.object.isRequired
}

function hashLinkScroll() {
  const { hash } = window.location
  if (hash !== '') {
    // Push onto callback queue so it runs after the DOM is updated,
    // this is required when navigating from a different page so that
    // the element is rendered on the page before trying to getElementById.
    setTimeout(() => {
      const id = hash.replace('#', '')
      const element = document.getElementById(id)
      if (element) element.scrollIntoView()
    }, 1200)
  }
}

export default Root
