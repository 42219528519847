import React, { useState } from 'react'
import { flow } from 'lodash'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import withTranslation from '../../hocs/withTranslation'
import { observer } from 'mobx-react'
import { IConversation } from '../../../store/models/Conversation'
import { createStyles, Divider, Grid, makeStyles, Icon as FontIcon, Paper } from '@material-ui/core'
import InfiniteList from '../../shared/InfiniteListMst'
import GuestListItem from './GuestListItem'
import appAnalytics from '../../../analytics'
import { Link } from 'react-router-dom'

interface Props {
  conversation: IConversation
  t: FIXME
}

const GuestList = ({ conversation, t }: Props) => {
  const classes = Styles()
  const [exported, setexported] = useState(false)
  const EmptyMessage = () => {
    return (
      <div>
        <p className='italic'>{t('conversation.noConfirmedGuests')}</p>
      </div>
    )
  }
  const CreateExport = () => (
    <>
      {!exported && (
        <Button
          startIcon={<FontIcon className='fas fa-cloud-download-alt opacity-8 thin-right-padding' />}
          onClick={() => conversation.createCsvExport()?.then(() => setexported(true))}
          variant='contained'
        >
          {t('conversation.exportGuestCSV')}
        </Button>
      )}
    </>
  )
  const ExportedCsv = () => {
    if (exported) {
      return (
        <Link to='/downloads'>
          <div className='row no-gutters'>
            <div className='col-xs-12 center-xs no-gutters'>
              <div className='alert-bar alert-bar-notice thin-top header-16 bold'>
                <p
                  className='flat-top flat-bottom'
                  dangerouslySetInnerHTML={{
                    __html: t('conversation.availableCSV')
                  }}
                />
                <p className='flat-top flat-bottom'>{t('conversation.emailWhenDone')}</p>
              </div>
            </div>
          </div>
        </Link>
      )
    } else return <></>
  }
  return (
    <div className='fat-top'>
      <ExportedCsv />
      <Grid container justify='space-between'>
        <Grid item>
          <h2>{t('user.guestList')}</h2>
        </Grid>
        <Grid item>
          <CreateExport />
        </Grid>
      </Grid>
      <Paper elevation={3} className={classes.paper}>
        <Grid container justify='space-between'>
          <Grid item>
            <h4>
              {t('conversation.confirmedGuests')} {`(${conversation.guestCount})`}
            </h4>
          </Grid>
          <Grid item>
            <Button
              variant='outlined'
              color='primary'
              onClick={() => {
                appAnalytics.event({
                  category: 'Conversation',
                  action: 'Send Message to All'
                })
                conversation.messageGuests()
              }}
            >
              {t('conversation.sendMessageToAll')}
            </Button>
          </Grid>
        </Grid>
        <List className={classes.scrollContainer}>
          <Divider variant='inset' component='li' />
          <InfiniteList
            initQuery={(args: any) => {
              conversation.loadGuests(args)
            }}
            query={(args: any) => {
              conversation.loadGuests(args, true)
            }}
            {...{ items: conversation.guests, itemsLoading: conversation.loadingIds.includes('guests') }}
            item={GuestListItem}
            itemProps={conversation}
            emptyMessage={EmptyMessage}
            hasMoreItems={conversation.hasMoreGuests}
            height={'30em'}
            limit={10}
          />
        </List>
      </Paper>
    </div>
  )
}
const Styles = makeStyles((theme) =>
  createStyles({
    paper: {
      padding: '1em 1em'
    },
    scrollContainer: {
      '& .infinite-scroll-component__outerdiv': {
        flex: 1
      }
    }
  })
)
export default flow(observer, withTranslation)(GuestList)
