import { types, Instance, getRoot } from 'mobx-state-tree'
import UserConversationsApi from '../../api/users/UserConversationsApi'
import ConversationUsersApi from '../../api/users/ConversationsApi'
import ConversationsApi from '../../api/ConversationsApi'
import { removeNullStringValue } from './utils'
import ConversationsTableApi from '../../api/ConversationsTableApi'
import CsvExportsApi from '../../api/CsvExportsApi'
import Conversation from './Conversation'
import { UserConversation } from './User'
import { Filterable, IFilterableArgs, LoadableStatus, Alertable } from './helpers'
import { RootAccessable } from './helpers/RootAccessable'
import HostedConversationsApi from '../../api/users/HostedConversationsApi'
import moment from 'moment'
import { sortBy } from 'lodash'

export const Conversations = types
  .compose(
    'Conversations',
    LoadableStatus,
    Alertable,
    Filterable,
    RootAccessable,
    types.model({
      list: types.optional(types.array(Conversation), []),
      allPastConversations: types.optional(types.array(Conversation), []),
      allUpcomingHostedConversations: types.optional(types.array(Conversation), []),
      allUpcomingConversations: types.optional(types.array(Conversation), []),
      userConversations: types.optional(types.array(UserConversation), [])
    })
  )
  .actions((self) => ({
    addToPastConversations: (data: any[]) => {
      const currentIds = self.allPastConversations.map((d) => d.id)
      const newList = [...self.allPastConversations, ...data.filter((d) => !currentIds.includes(d.id))]
      self.allPastConversations.replace(sortBy(newList, 'startsAt'))
    }
  }))
  .actions((self) => ({
    setHostedList(data: any) {
      const { user } = self.getAuth()
      if (user) {
        const allUpcomingConversations = data.filter((d) => moment(d.endsAt).add(4, 'hour').isAfter(moment()))
        const allPastConversations = data.filter((d) => moment(d.endsAt).add(4, 'hour').isBefore(moment()))
        self.allUpcomingHostedConversations.replace(allUpcomingConversations)
        self.addToPastConversations(allPastConversations)
      }
    },
    setMyConversationsList(data: any) {
      const { user } = self.getAuth()
      if (user) {
        const allUpcomingConversations = data.filter((d) => moment(d.endsAt).add(4, 'hour').isAfter(moment()))
        const allPastConversations = data.filter((d) => moment(d.endsAt).add(4, 'hour').isBefore(moment()))
        self.allUpcomingConversations.replace(allUpcomingConversations)
        self.addToPastConversations(allPastConversations)
      }
    },
    addList(data: any) {
      removeNullStringValue(data)
      const found = self.list.find((d) => d.id === data.id)
      if (found) {
        self.list.replace(self.list.map((d) => (d.id === data.id ? data : d)))
      } else {
        self.list.push(data)
      }
    },
    setList(data: any[], totalCount?: number) {
      data.forEach((d) => removeNullStringValue(d))
      self.list.replace(data)
      self.setTotalCount(totalCount)
    },
    setUserConversations(data: any[]) {
      data.forEach((d) => removeNullStringValue(d))
      self.userConversations.replace(data)
    },
    isLoaded(id: number) {
      return self.list.map((d) => d.id).includes(id)
    }
  }))
  .actions((self) => ({
    loadConversationsTable: (args: IFilterableArgs) => {
      if (self.isLoading('conversations_table')) {
        return
      }
      self.startLoading('conversations_table')
      const {
        partners: { selectedPartnerId }
      } = getRoot(self)

      return ConversationsTableApi.getAll({
        ...args,
        ...(selectedPartnerId ? { partner_ids: [selectedPartnerId] } : {}),
        ...(self.partnerId ? { partner_id: self.partnerId } : {})
      }).then(({ response: { ok, statusText }, json: { pages, conversations, total } }) => {
        if (ok) {
          self.setList(conversations, pages)
          self.setTotalCount(total)
        } else {
          self.showAlert(statusText)
        }
        self.stopLoading('conversations_table')
      })
    },
    loadById(conversationId: number) {
      if (self.isLoading(conversationId)) {
        return
      }
      self.startLoading(conversationId)
      return ConversationsApi.get(conversationId).then(({ response: { ok }, json }) => {
        if (ok) {
          self.addList(json)
        }
        self.stopLoading(conversationId)
      })
    },
    loadUserConversations: (userId: number) => {
      if (self.isLoading('user_conversations')) {
        return
      }
      self.startLoading('user_conversations')
      return UserConversationsApi.getAll(userId).then(({ response: { ok }, json }) => {
        if (ok) {
          self.setUserConversations(json)
        }
        self.stopLoading('user_conversations')
      })
    }
  }))
  .actions((self) => ({
    getConversation(id: number, { load = true }: { load?: boolean } = {}) {
      const found = self.list.find((d) => d.id === id)
      if (!found && load) {
        self.loadById(id)
      }
      return found
    },
    afterCreate: () => {
      self.setSortingId('startsAt')
      self.setTimeScope('upcoming')
      self.setSortedPriority(false)
    },
    loadFilter: ({ reset = false, clear = true } = {}) => {
      if (reset) {
        self.page = 0
        self.pageSize = 15
        self.search = ''
        // self.topicId = undefined
      }
      if (clear) {
        self.list.replace([])
      }
      const { search, pageSize: page_size, page, sortedId: sorted_id, sortedPriority: sorted_priority, timeScope: time_scope, topicId: topic_id } = self
      return self.loadConversationsTable({ search, page_size, page, sorted_id, sorted_priority, time_scope, topic_id })
    },
    createCsvExport: ({ parameters }: { parameters?: any } = {}) => {
      const { user } = self.getAuth()
      user?.trackOnChurnzero() && window.churnZero.track('Count CSV Export', 'conversations')
      return CsvExportsApi.create({ csvType: 'conversation', parameters })
        .then((res) => {
          const { ok, status, statusText } = res.response

          if (ok) {
            self.showAlert('Exported')
          }
        })
        .catch((error) => {
          throw error
        })
    },
    loadHostedConversations: () => {
      const listName = 'hostedList'
      if (self.isLoading(listName) || self.isLoaded(listName)) {
        return
      }
      const { user } = self.getAuth()
      if (user) {
        self.startLoading(listName)
        return HostedConversationsApi.getAll(user.id).then(({ response: { ok }, json }) => {
          self.stopLoading(listName)
          if (ok) {
            self.setHostedList(json)
          }
        })
      } else {
        return Promise.reject()
      }
    },
    loadMyConversations: ({ reload = false }: { reload?: boolean } = {}) => {
      const listName = 'myConversationsList'
      if (self.isLoading(listName) || (!reload && self.isLoaded(listName))) {
        return
      }
      const { user } = self.getAuth()
      if (user) {
        self.startLoading(listName)
        return ConversationUsersApi.getAll(user.id).then(({ response: { ok }, json }) => {
          self.stopLoading(listName)
          if (ok) {
            self.setMyConversationsList(json)
          }
        })
      } else {
        return Promise.reject()
      }
    }
  }))

export interface IConversations extends Instance<typeof Conversations> {}
export default Conversations
