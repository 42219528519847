import React, { Component, ReactNode } from 'react'
import { Skeleton } from '@material-ui/lab'
import { Button, InputBase, Divider, IconButton, InputAdornment, Typography, TablePagination } from '@material-ui/core'
import { observer } from 'mobx-react'
import { VirtualTable, Grid, TableHeaderRow, TableFixedColumns } from '@devexpress/dx-react-grid-material-ui'

interface SearchArgs {
  search: string
  page_size: number | string
  page: number
  sorted_id?: string
  sorted_priority?: boolean
}
interface State extends SearchArgs {}
interface Props {
  loading?: boolean
  size?: 'small' | 'medium' | undefined
  height?: string | number | undefined
  hideFilter?: boolean
  hidePaging?: boolean
  searchOnly?: boolean
  columns: IColData[]
  data: any[]
  emptyCellDefault?: string | ReactNode
  pageSize?: number
  totalCount?: number
  pageSizeOptions?: (number | { label: any; value: any })[]
  leftColumns?: string[]
  rightColumns?: string[]
  onChange?(args: SearchArgs): any
  getCellValue?(row: any, columnName: string): any
}

export const ValueLabel = ({
  value,
  empty,
  onClick,
  whiteSpace
}: {
  onClick?()
  value?: string | React.ReactNode | undefined
  empty?: string | React.ReactNode | undefined
  whiteSpace?: undefined | 'normal'
}) => (
  <div
    title={typeof value === 'string' ? value : undefined}
    onClick={onClick}
    className={onClick ? 'link-text' : ''}
    style={{ ...(onClick ? { cursor: 'pointer' } : {}), whiteSpace }}
  >
    {value ? value : empty !== undefined ? empty : '-'}
  </div>
)

export interface IColData {
  name: string
  title?: string
  width?: number
  minWidth?: number
  sortable?: boolean
  align?: 'left' | 'center' | 'right' | undefined
}
interface ColumnExtension {
  /** The name of the column to extend. */
  columnName: string
  /** The table column width. */
  width?: number | string
  /** The table column alignment. */
  align?: 'left' | 'right' | 'center'
  /** Specifies whether word wrap is enabled in a column's cells. */
  wordWrapEnabled?: boolean
}

class DataTable extends Component<Props, State> {
  state = {
    search: '',
    page_size: 15,
    page: 0,
    sorted_id: 'id',
    sorted_priority: true
  }
  componentDidMount() {
    const { onChange } = this.props
    onChange && onChange(this.state)
  }

  onFormSubmit = (e: any) => {
    e.preventDefault()
    const { onChange } = this.props
    onChange && onChange(this.state)
  }

  handleSearch = (e: any) => {
    e.preventDefault()
    this.setState({ search: e.target.value })
  }
  handleSorting = (name: string) => (e: any) => {
    const { onChange } = this.props
    e.preventDefault()
    const newState = { sorted_id: name, sorted_priority: name === this.state.sorted_id ? !this.state.sorted_priority : true }
    this.setState(newState as any, () => onChange && onChange(this.state))
  }

  handlePaging = (name: string) => (e: any) => {
    const {
      target: { value }
    } = e
    e.preventDefault()
    this.updatePaging(name, value)
  }
  updatePaging = (name: string, value: any) => {
    const { onChange } = this.props
    const newState = { [name]: value }
    this.setState(newState as any, () => onChange && onChange(this.state))
  }

  render() {
    const {
      loading,
      columns = [],
      data = [],
      pageSizeOptions = [10, 15, 50, 100],
      hideFilter,
      hidePaging,
      size,
      height,
      searchOnly = false,
      emptyCellDefault = '',
      totalCount,
      leftColumns,
      rightColumns,
      getCellValue
    } = this.props

    const { search = '', page_size, sorted_id, sorted_priority, page } = this.state

    const colExt: ColumnExtension[] = columns.map((c) => ({ ...c, columnName: c.name }))

    return (
      <div>
        <form onSubmit={this.onFormSubmit}>
          {!hideFilter && (
            <>
              <div className='reg-padding'>
                <InputBase
                  value={search}
                  onChange={this.handleSearch}
                  placeholder='Search'
                  fullWidth
                  startAdornment={
                    !searchOnly && (
                      <InputAdornment position='start'>
                        <i className='far fa-search'></i>
                      </InputAdornment>
                    )
                  }
                />
              </div>
              <Divider />

              <Button type='submit' style={{ display: 'none' }} />
            </>
          )}

          <Grid getCellValue={getCellValue} rows={data} columns={columns}>
            <VirtualTable
              noDataCellComponent={() => {
                return (
                  <td colSpan={columns.length}>
                    {loading ? (
                      <Skeleton variant='rect' animation='wave'>
                        <div style={{ width: '100vw', height: 470 }}></div>
                      </Skeleton>
                    ) : (
                      <Typography gutterBottom variant='h5' component='h2' align='center' className='reg-padding'>
                        No Data
                      </Typography>
                    )}
                  </td>
                )
              }}
              columnExtensions={colExt}
              height={height}
            />
            <TableHeaderRow
              contentComponent={({ align, column, children, classes, ...restProps }: any) => {
                const isActive = sorted_id && sorted_id === column.name
                return (
                  <TableHeaderRow.Content onClick={column.sortable ? this.handleSorting(column.name) : undefined} align={align || column.align} column={column} {...restProps}>
                    {children}{' '}
                    {column.sortable && isActive ? (
                      sorted_priority ? (
                        <i className='far fa-arrow-up thin-left-padding'></i>
                      ) : (
                        <i className='far fa-arrow-down thin-left-padding'></i>
                      )
                    ) : null}
                  </TableHeaderRow.Content>
                )
              }}
            />
            <TableFixedColumns leftColumns={leftColumns} rightColumns={rightColumns} />
          </Grid>

          {!hideFilter && !hidePaging && (
            <TablePagination
              rowsPerPageOptions={pageSizeOptions}
              component='div'
              count={totalCount || 0}
              rowsPerPage={page_size}
              page={page}
              onChangePage={(e, val) => this.updatePaging('page', val)}
              onChangeRowsPerPage={this.handlePaging('page_size')}
            />
          )}
        </form>
      </div>
    )
  }
}

export default observer(DataTable)
