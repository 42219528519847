import { types, Instance, getRoot } from 'mobx-state-tree'
import InviteAdminApi from '../../../api/partners/Admins/inviteAdminApi'
import PartnerBase from './PartnerBase'
import { Alertable } from '../helpers/Alertable'
import { LoadableStatus } from '../helpers/LoadableStatus'
import { UserBase } from '../User'
import { camelify } from '../../../decorators/textTools'
import { includes } from 'lodash'
import Invite from '../helpers/Invite'

const PartnerAdminInvite = types
  .compose(
    'PartnerAdminInvite',
    Invite,

    types.model({
      id: types.identifierNumber
    })
  )
  .actions((self) => ({
    canDelete: () => {
      const {
        auth: { user }
      } = getRoot(self)
      return user && (user.isSuperAdmin() || (user.isPartnerAdmin() && self.senderId === user.id))
    }
  }))
export const PartnerAdminInvitations = types
  .compose(
    'PartnerAdminInvitations',
    PartnerBase,
    LoadableStatus,
    Alertable,
    types.model({
      adminInvitations: types.array(PartnerAdminInvite)
    })
  )
  .actions((self) => ({
    setAdminInvitations: (val: any) => {
      self.adminInvitations.replace(val)
    },
    removeAdminInvitationFromList: (inviteId: number) => {
      self.adminInvitations.replace(self.adminInvitations.filter((admin) => admin.id !== inviteId))
    },
    addAdminInvitation: (val: any) => {
      self.adminInvitations.push(val)
    }
  }))
  .actions((self) => ({
    loadAdminInvitations: () => {
      const listName = 'adminInvitations'
      if (self.isLoading(listName)) {
        return
      }

      self.startLoading(listName)
      return InviteAdminApi.getAll({ partnerId: self.id, limit: 50, offset: 0 })
        .then(({ response: { ok }, json }) => {
          self.stopLoading(listName)
          if (ok) {
            self.setAdminInvitations(json.map(camelify))
          }
        })
        .catch(() => {
          self.stopLoading(listName)
        })
    },
    deleteAdminInvitation: (id) => {
      return InviteAdminApi.delete({ partnerId: self.id, id }).then(({ response: { ok }, json }) => {
        if (ok) {
          self.removeAdminInvitationFromList(id)
        }
      })
    },
    inviteAdmin: (email: string) => {
      return InviteAdminApi.invite(self.id, email).then(({ response: { ok }, json }) => {
        if (ok) {
          self.addAdminInvitation(camelify(json))
        } else {
          self.showAlert('Invitation failed')
        }
      })
    }
  }))

export interface IPartnerAdminInvitations extends Instance<typeof PartnerAdminInvitations> {}
export default PartnerAdminInvitations
