import { StyleRules, TypographyClassKey } from '@material-ui/core'
import { createMuiTheme, ThemeOptions } from '@material-ui/core/styles'
import { merge } from 'lodash'
import { getFontFamily, defaulTypography, defaultButtonOverride } from './'

import palette from './palette'

export const defaultLinkTheme: ThemeOptions = {
  overrides: {
    MuiButton: {
      contained: {
        '&.link': {
          color: palette.white,
          backgroundColor: palette.blue500,
          '&:hover': {
            backgroundColor: palette.blue700
          },
          '&:focus': {
            backgroundColor: palette.blue900
          }
        }
      },
      outlined: {
        '&.link': {
          color: palette.blue500,
          border: `1px solid ${palette.blue500}`
        }
      },
      text: {
        '&:hover': {
          boxShadow: 'rgba(0, 0, 0, 0.14) 0px 1px 4px'
        },
        '&.link': {
          color: palette.blue500
        }
      }
    }
  }
}
export const defaultThemeData: ThemeOptions = merge({}, defaultLinkTheme, {
  palette: {
    primary: {
      light: palette.yellow100,
      main: palette.yellow500,
      dark: palette.yellow900
    },
    secondary: {
      light: palette.magenta100,
      main: palette.magenta500,
      dark: palette.magenta900
    },
    error: {
      light: palette.red100,
      main: palette.red500,
      dark: palette.red900
    },
    warning: {
      light: palette.orange100,
      main: palette.orange500,
      dark: palette.orange900
    },
    info: {
      light: palette.lightBlue100,
      main: palette.blue500,
      dark: palette.blue900
    },
    success: {
      light: palette.green100,
      main: palette.green500,
      dark: palette.green900
    }
  },
  overrides: {
    MuiTypography: defaulTypography,
    MuiFormControl: {
      root: {
        marginTop: '14px'
      }
    },
    MuiFormLabel: {
      root: {
        fontFamily: getFontFamily()
      }
    },
    MuiMenuItem: {
      root: {
        fontFamily: getFontFamily(),
        fontWeight: 500
      }
    },
    MuiInputBase: {
      input: {
        fontFamily: getFontFamily()
      }
    },
    MuiIcon: {
      root: {
        color: palette.blue500
      }
    },
    MuiButton: {
      ...defaultButtonOverride,
      root: {
        fontFamily: getFontFamily(),
        color: palette.grey800,
        boxShadow: 'rgba(0, 0, 0, 0.14) 0px 1px 4px',
        '&:disabled': {
          color: palette.grey400
        },
        lineHeight: '24px'
      },

      contained: {
        fontFamily: getFontFamily(),
        boxShadow: 'rgba(0, 0, 0, 0.14) 0px 1px 4px',
        color: palette.grey800,
        backgroundColor: palette.grey50,
        '&:disabled': {
          backgroundColor: palette.grey200
        },
        '&:hover': {
          backgroundColor: palette.grey100
        }
      },
      outlined: {
        boxShadow: 'rgba(0, 0, 0, 0) 0px 1px 4px',
        color: palette.grey800
      },
      text: {
        boxShadow: 'rgba(0, 0, 0, 0) 0px 1px 4px',
        color: palette.grey800,
        '&:hover': {
          boxShadow: 'rgba(0, 0, 0, 0.14) 0px 1px 4px'
        }
      },
      label: {
        textTransform: 'none',
        fontWeight: 500,
        letterSpacing: 0,
        fontFamily: getFontFamily()
      }
    },
    MuiInput: {
      underline: {
        '&:after': {
          borderBottom: '1px solid rgba(0, 0, 0, 0.42)'
        },
        '&:hover:not($disabled):before': {
          borderBottom: '1px solid rgba(0, 0, 0, 0.42)'
        }
      }
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: palette.teal
      }
    },
    MuiSnackbarContent: {
      message: {
        width: '100%',
        textAlign: 'center'
      }
    },
    MuiListItemAvatar: {
      root: {
        minWidth: '50px'
      }
    },
    MuiStepIcon: {
      active: {
        fill: palette.magenta500
      },
      completed: {
        fill: palette.magenta500
      },
      text: {
        fill: palette.white
      }
    },
    MuiDivider: {
      root: {
        backgroundColor: palette.grey700,
        flex: 1,
        '&.secondary': {
          backgroundColor: palette.yellow500,
          height: '2px',
          margin: '1.5em 0 2em 0'
        }
      }
    }
  }
})
export const DefaultTheme = createMuiTheme(defaultThemeData)

export default DefaultTheme
