import { types, Instance } from 'mobx-state-tree'
import TopicSubscribersApi from '../../api/TopicSubscribersApi'
import User from './User'
import { LoadableStatus } from './helpers/LoadableStatus'
import { Alertable } from './helpers/Alertable'
import { removeNullStringValue } from './utils'
import CsvExportsApi from '../../api/CsvExportsApi'
import MessageApi from '../../api/MessagesApi'

import { Filterable } from './helpers/Filterable'
import UsersTableApi from '../../api/UsersTableApi'
import { Dialogable } from './helpers'
import { RootAccessable } from './helpers/RootAccessable'
import Editable from './helpers/Editable'

export const SurveyAnswer = types.compose(
  'SurveyAnswer',
  Editable,
  types.model({
    id: types.identifierNumber,
    answer: types.maybe(types.string),
    surveyQuestionId: types.maybe(types.number),
    question: types.maybeNull(types.string),
    value: types.maybeNull(types.number)
  })
)

export const TopicSubscriber = types.compose(
  'TopicSubscriber',
  Editable,
  types.model({
    id: types.identifierNumber,
    userId: types.maybe(types.number),
    firstName: types.maybe(types.string),
    lastName: types.maybe(types.string),
    email: types.maybe(types.string),
    city: types.maybeNull(types.string),
    stateLevel: types.maybeNull(types.string),
    country: types.maybeNull(types.string),
    surveyAnswers: types.array(SurveyAnswer)
  })
)

export const TopicSubscribers = types
  .compose(
    'TopicSubscribers',
    LoadableStatus,
    Alertable,
    Dialogable,
    Filterable,
    RootAccessable,
    types.model({
      list: types.optional(types.array(TopicSubscriber), []),
      topicId: types.maybe(types.number)
    })
  )
  .actions((self) => ({
    addList(user: any) {
      removeNullStringValue(user)
      const found = self.list.find((u) => u.id === user.id)
      if (found) {
        found.updateMe(user)
      } else {
        self.list.push(user)
      }
    },
    setTopicId(val: number) {
      self.topicId = val
    },
    setList(data: any[], totalCount?: number) {
      data.forEach((user) => removeNullStringValue(user))
      self.list.replace(data)
      self.setTotalCount(totalCount)
    }
  }))
  .actions((self) => ({
    loadUsers: (args: { search?: string; page_size?: number; page?: number; sorted_id?: string; sorted_priority?: string }) => {
      if (self.isLoading('users_table') || !self.topicId) {
        return
      }
      self.startLoading('users_table')

      return TopicSubscribersApi.getAll(self.topicId, args).then(({ response: { ok, statusText }, json: { pages, users, total: totalCount } }) => {
        if (ok) {
          self.setList(users, totalCount)
          self.setPages(pages)
        } else {
          // self.showAlert(statusText)
        }
        self.stopLoading('users_table')
      })
    }
  }))
  .actions((self) => ({
    loadFilter: ({ reset = false, clear = true } = {}) => {
      if (reset) {
        self.page = 0
        self.pageSize = 15
        self.search = ''
      }
      if (clear) {
        self.list.replace([])
      }
      const { search, pageSize: page_size, page, sortedId: sorted_id, sortedPriority: sorted_priority } = self
      return self.loadUsers({ search, page_size, page, sorted_id, sorted_priority: (!!sorted_priority).toString() })
    },
    afterCreate: () => {
      self.setSortedPriority(true)
      self.setSortingId('id')
    }
  }))

export interface ITopicSubscribers extends Instance<typeof TopicSubscribers> {}
export interface ITopicSubscriber extends Instance<typeof TopicSubscriber> {}
export interface ISurveyAnswer extends Instance<typeof SurveyAnswer> {}
export default TopicSubscribers
