import * as types from './actionTypes'
import mst from '../store/models'

export const showAlertMessage = (json) => {
  mst.alertMessages.showAlert(json.message)
  return {
    type: types.SHOW_ALERT_MESSAGE,
    data: json
  }
}

export const closeAlertMessage = (json) => {
  return {
    type: types.CLOSE_ALERT_MESSAGE,
    data: json
  }
}

export const showAlert = (dispatch, message) => dispatch(showAlertMessage({ message }))
