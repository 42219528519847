import React, { Component } from 'react'
import { connect } from 'react-redux'

import getPartnerStatsProps from '../../getters/partnerStatsGetter'
import { loadPartnerStats } from '../../actions/partnerActions'
import LoadingAnimation from '../shared/LoadingAnimation.js'
import withTranslation from '../hocs/withTranslation'

class StatsModule extends Component {
  componentDidMount() {
    this.props.loadPartnerStats()
  }

  render() {
    const {
      partnerStats: { parentConversations, parentAttendees, pastConversations, activeConversations, attendees },
      loading,
      partner,
      t
    } = this.props
    const { location } = partner

    if (partner == null) return null
    if (loading) {
      return <LoadingAnimation size={40} thickness={2} />
    }

    const cookedPastConversations = () => {
      switch (partner.id) {
        case 1: // arc
          return pastConversations + 20
        case 22: // tlc
          return pastConversations + 8
        default:
          return pastConversations
      }
    }

    const cookedAttendeeCount = () => {
      switch (partner.id) {
        case 1: // arc
          return attendees + 220
        case 22: // tlc
          return attendees + 100
        default:
          return attendees
      }
    }

    const globalStats = () => {
      if (global.coco) {
        return (
          <div>
            <div className='row no-gutters thin-bottom middle-xs fat-top'>
              <div className='col-xs-4 align-right'>
                <i className='fal fa-globe header-24 opacity-8' />
              </div>
              <div className='col-xs-8 no-right-gutter no-left-gutter weak-underline-sm tiny-bottom-padding'>{t('common.global')}</div>
            </div>

            <div className='row no-gutters thin-bottom reg-top'>
              <div className='col-xs-4 align-right'>{parentConversations}</div>
              <div className='col-xs-8 no-right-gutter no-left-gutter'>{t('partners.topics')}</div>
            </div>
            <div className='row no-gutters thin-bottom'>
              <div className='col-xs-4 align-right'>{parentAttendees}</div>
              <div className='col-xs-8 no-right-gutter no-left-gutter'>{t('partners.attendees')}</div>
            </div>
          </div>
        )
      }
    }

    const Stats = () => {
      return (
        <div className='counter-table header-22 width-240-sm center-margin fat-top-padding'>
          <div className='row no-gutters thin-bottom middle-xs'>
            <div className='col-xs-4 align-right'>
              <i className='fal fa-map-marker-alt header-24 opacity-8' />
            </div>
            <div className='col-xs-8 no-right-gutter no-left-gutter weak-underline-sm tiny-bottom-padding'>{location}</div>
          </div>

          <div className='row no-gutters thin-bottom reg-top'>
            <div className='col-xs-4 align-right'>{cookedPastConversations()}</div>
            <div className='col-xs-8 no-right-gutter no-left-gutter'>{global.coco ? t('partners.pastTopics') : t('partners.pastConversations')}</div>
          </div>

          <div className='row no-gutters thin-bottom'>
            <div className='col-xs-4 align-right'>{activeConversations}</div>
            <div className='col-xs-8 no-right-gutter no-left-gutter'>{global.coco ? t('partners.activeTopics') : t('partners.activeConversations')}</div>
          </div>
          <div className='row no-gutters thin-bottom'>
            <div className='col-xs-4 align-right'>{cookedAttendeeCount()}</div>
            <div className='col-xs-8 no-right-gutter no-left-gutter'>{t('partners.totalAttend')}</div>
          </div>

          {globalStats()}
        </div>
      )
    }

    return (
      <div>
        <Stats />
      </div>
    )
  }
}

export default (StatsModule = connect(
  getPartnerStatsProps,
  mapDispatchToProps
)(withTranslation(StatsModule)))

function mapDispatchToProps(dispatch, ownProps) {
  const slug = ownProps.partner.slug

  return {
    loadPartnerStats: () => dispatch(loadPartnerStats(slug))
  }
}
