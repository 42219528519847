import { normalize } from 'normalizr'
import { SubmissionError } from 'redux-form'

import SectionSponsorsApi from '../api/sections/SponsorsApi'
import SponsorsApi from '../api/SponsorsApi'
import * as types from './actionTypes'
import { sponsorsSchema, sponsorSchema } from '../normalizers/sponsorNormalizers'
import { showAlertMessage } from './alertMessageActions'

export const Loading = () => ({
  type: types.SPONSORS_LOADING
})

export const loadSponsorsSuccess = (json) => ({
  type: types.LOAD_SPONSORS_SUCCESS,
  ...json
})

export const loadSponsorsFailed = (json) => ({
  type: types.LOAD_SPONSORS_FAILED,
  data: json
})

export const deleteSponsorSuccess = (json) => ({
  type: types.DELETE_SPONSORS_SUCCESS,
  ...json
})

const showAlert = (dispatch, message) => dispatch(showAlertMessage({ message }))

export function createSponsor(data) {
  return function (dispatch) {
    dispatch(Loading())

    return SponsorsApi.create(data)
      .then((res) => {
        const { ok, status, statusText } = res.response

        if (ok) {
          const flatData = normalize(res.json, sponsorSchema).entities
          dispatch(loadSponsorsSuccess(flatData))
          showAlert(dispatch, 'Sponsor successfully created')
        } else {
          dispatch(loadSponsorsFailed({ status, statusText }))
          throw new SubmissionError(res.json)
        }
      })
      .catch((error) => {
        throw error
      })
  }
}

export function updateSponsor(data) {
  return function (dispatch) {
    dispatch(Loading())

    return SponsorsApi.update(data)
      .then((res) => {
        const { ok, status, statusText } = res.response

        if (ok) {
          const flatData = normalize(res.json, sponsorSchema).entities
          dispatch(loadSponsorsSuccess(flatData))
          showAlert(dispatch, 'Sponsor successfully updated')
        } else {
          dispatch(loadSponsorsFailed({ status, statusText }))
          throw new SubmissionError(res.json)
        }
      })
      .catch((error) => {
        throw error
      })
  }
}

export function deleteSponsor(data) {
  return function (dispatch) {
    dispatch(Loading())

    return SponsorsApi.destroy(data.id)
      .then((res) => {
        const { ok, status, statusText } = res.response

        if (ok) {
          const flatData = normalize(res.json, sponsorSchema).entities
          dispatch(deleteSponsorSuccess(flatData))
          showAlert(dispatch, 'Sponsor deleted')
        } else {
          dispatch(loadSponsorsFailed({ status, statusText }))
          throw new SubmissionError(res.json)
        }
      })
      .catch((error) => {
        throw error
      })
  }
}

export function loadSectionSponsors(sectionId) {
  return function (dispatch) {
    dispatch(Loading())

    return SectionSponsorsApi.getAll(sectionId)
      .then((res) => {
        const { ok, status, statusText } = res.response

        if (ok) {
          const flatData = normalize(res.json, sponsorsSchema).entities
          dispatch(loadSponsorsSuccess(flatData))
        } else {
          dispatch(loadSponsorsFailed({ status, statusText }))
        }
      })
      .catch((error) => {
        throw error
      })
  }
}
