import React from 'react'
import { observer } from 'mobx-react'
import { AppBar, createStyles, makeStyles, Theme, Toolbar, Typography } from '@material-ui/core'
import { useParamConversation, useParamTopic } from '../../../Providers'
import { useTranslation } from 'react-i18next'
import WhatsNext from './WhatsNext'

/* eslint-disable-next-line */
const ConversationBanner = (props) => {
  const classes = useStyles()
  const { conversation, isGuest, isHost } = useParamConversation()
  const { topic } = useParamTopic()
  const { t } = useTranslation()

  if (!conversation || !topic) return <></>
  const onTakeActions = () => {
    const actionsSection = document.getElementById('conversationActions')
    actionsSection &&
      window.scrollTo({
        top: actionsSection?.offsetTop - 120,
        behavior: 'smooth'
      })
  }
  const active = !conversation.expired
  const withAction = topic && topic?.topicActions.length > 0
  const activeGuest = isGuest && active
  const activeHost = isHost && active

  if (/*(!active && !withAction && isHost) ||*/ active && !withAction && !isGuest) return <></>

  const showBanner = /*!(!active && !withAction && isHost) &&*/ !(active && !withAction && !isGuest) && !(active && withAction && !activeGuest && !activeHost)
  if (!showBanner) return <></>

  return (
    <AppBar position='fixed' className={classes.appBar} style={{ backgroundColor: conversation.expired ? '#F6F7F9' : isGuest || isHost ? '#C4E6D8' : '#F6F7F9' }}>
      <Toolbar className={classes.toolBar}>
        <div className={classes.container}>
          {activeGuest && (
            <>
              <Typography variant='subtitle1' component='div' className={classes.text}>
                <i className={`far fa-check-circle thin-right ${classes.icon}`} /> You’re attending this conversation!
              </Typography>
              <Typography
                variant='subtitle1'
                component='a'
                className={classes.link}
                onClick={() => {
                  conversation.unattend({}, { showDialog: true, dialog: 'deleteRSVP', successAlert: t('conversation.rsvpCanceled') })
                }}
              >
                Cancel RSVP
              </Typography>
            </>
          )}
          {activeHost && (
            <>
              <Typography variant='subtitle1' className={classes.text}>
                <i className={`far fa-star thin-right ${classes.icon}`} />
                You’re hosting this conversation!
              </Typography>
              <WhatsNext>
                <Typography variant='subtitle1' className={classes.link}>
                  What&apos;s next?
                </Typography>
              </WhatsNext>
            </>
          )}
          {!active && !isGuest && !isHost && (
            <>
              <Typography variant='subtitle1' className={classes.text}>
                This conversation has occurred
              </Typography>
            </>
          )}
          {!active && isGuest && (
            <>
              <Typography variant='subtitle1' className={classes.text}>
                <i className={`far fa-check-circle ${classes.icon}`} />
                You attended this conversation.
              </Typography>
              {withAction && (
                <Typography variant='subtitle1' component='a' className={classes.link} onClick={onTakeActions}>
                  Take Action
                </Typography>
              )}
            </>
          )}
          {!active && withAction && isHost && (
            <>
              <Typography variant='subtitle1' className={classes.text}>
                <i className={`far fa-star thin-right ${classes.icon}`} />
                You hosted this Conversation
              </Typography>
              <Typography variant='subtitle1' component='a' className={classes.link} onClick={onTakeActions}>
                Take Action
              </Typography>
            </>
          )}
          {!active && isHost && (
            <>
              <Typography variant='subtitle1' className={classes.text}>
                <i className={`far fa-star thin-right ${classes.icon}`} />
                You hosted this Conversation
              </Typography>
            </>
          )}
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default observer(ConversationBanner)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      top: '4.7rem',
      bottom: 'auto',
      backgroundColor: '#C4E6D8',
      color: '#212121'
    },
    toolBar: {
      // alignSelf: 'center'
    },
    actionLink: {
      color: '#EE413E'
      // marginLeft: '0.4rem'
    },
    icon: {
      color: '#388465',
      marginRight: '0.4rem'
    },
    container: {
      margin: '.5rem auto .5rem auto',
      // minWidth: '50rem'
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        justify: 'center'
      }
    },
    text: {
      // textAlign: 'center',
      // whiteSpace: 'nowrap'
      // maxWidth: '20rem'
    },
    link: {
      color: '#EE413E',
      marginLeft: '.2rem',
      [theme.breakpoints.down('xs')]: {
        marginLeft: '0',
        textAlign: 'center'
      }
    }
  })
)
