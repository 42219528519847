import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

const LoadingAnimation = ({ ...props }) => {
  const { size = 100, thickness = 1.5, color = 'secondary' } = props

  return (
    <div className='row center-xs spinner-page'>
      <CircularProgress size={size} thickness={thickness} color={color} />
    </div>
  )
}

export default LoadingAnimation
