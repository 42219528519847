import React, { Component } from 'react'
import { has, omit } from 'lodash'
import { connect } from 'react-redux'
import queryString from 'query-string'

import { unsubscribeUser } from '../../actions/userActions'
import { history } from '../browserHistory'
import withTranslation from '../hocs/withTranslation'
import { getCurrentUserId } from '../../getters/currentUserGetters'
import { showDialog } from '../../actions/dialogActions'

export class UnsubscribePage extends Component {
  componentDidMount(prevProps) {
    this.handleQueryParams()
  }

  componentDidUpdate(prevProps) {
    this.handleQueryParams()
  }

  handleQueryParams() {
    const { location, unsubscribeUser, currentUserId, isCurrentUserLoading, showDialog } = this.props

    if (location.search) {
      var params = queryString.parse(location.search)

      if (has(params, 'md_email') && has(params, 'md_id')) {
        if (currentUserId == null) {
          if (isCurrentUserLoading === false) {
            showDialog('login')
          }
          return
        }

        unsubscribeUser()

        const nextParams = omit(params, ['md_email', 'md_id'])
        const nextSearch = queryString.stringify(nextParams)
        history.replace({
          pathname: location.pathname,
          search: nextSearch
        })
      }
    }
  }

  render() {
    const { t, currentUserId } = this.props

    if (currentUserId === null) return null

    return (
      <div className='row min-content-height center-xs giant-top'>
        <div className='col-xs-12'>
          <h2>{t('auth.unsubscribed1')}</h2>

          <h2>{t('auth.unsubscribed2')}</h2>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const currentUserId = getCurrentUserId(state)
  const isCurrentUserLoading = state.isCurrentUserLoading
  return { currentUserId, isCurrentUserLoading }
}

function mapDispatchToProps(dispatch) {
  return {
    unsubscribeUser: (data) => dispatch(unsubscribeUser(data)),
    showDialog: (type, data) => dispatch(showDialog(type, data))
  }
}

export default withTranslation(connect(mapStateToProps, mapDispatchToProps)(UnsubscribePage))
