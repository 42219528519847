import React, { Component, useContext, useEffect, useMemo, useRef } from 'react'

import { observer } from 'mobx-react'
import { useAuth, useParamTopic } from '../../Providers'
import { ShowCourse } from '../../lms_courses/ShowCourse'
import { Button, Container, Typography } from '@material-ui/core'
import { useHistory, useParams } from 'react-router'
import VideoPlayer from '../../lms_courses/VideoPlayer'
import PayWall from './PayWall'

const TopicVideo = observer((props: FIXME) => {
  const { topic } = useParamTopic()
  const { user } = useAuth()
  const { video_id, slug } = useParams<{ video_id: string; slug: string }>()
  const lmsItem = useMemo(() => topic?.topicLmsCourse?.lmsItems.find((lmsI) => lmsI.id.toString() === video_id), [topic?.topicLmsCourse?.lmsItems, video_id])
  const userLmsItem = useMemo(() => lmsItem && user?.userLmsItems.find((lmsI) => lmsI?.lmsItemId === lmsItem.id), [user?.userLmsItems, lmsItem])
  const history = useHistory()
  // const useLmsItems = user?.userLmsItems.filter((uLmsItem) => uLmsItem.lmsItemId && lmsItemIds.includes(uLmsItem.lmsItemId))
  // console.log('TopicVideos', { topic, video_id, lmsItem, userLmsItem })
  useEffect(() => {
    topic && topic.shouldLoad('topicLmsCourses', topic.loadTopicLmsCourses)
  }, [topic])
  useEffect(() => {
    user && user.shouldLoad('userLmsItems', user.loadUserLmsItems)
  }, [user])

  const onEnded = (progress) => {
    // console.log({ progress })
    return lmsItem?.createUserLmsItem({ progress }).then(() => {
      user?.loadUserLmsItems({ reload: true })
    })
  }
  // console.log({ userLmsItem })
  return (
    <PayWall>
      <Container maxWidth='md' style={{ paddingTop: '2rem' }}>
        <Button onClick={() => history.push(`/${slug}/videos`)}>Back</Button>
        <Typography variant='h3' align='center'>
          {topic?.topicLmsCourse?.name}
        </Typography>
        <Typography align='center'>{topic?.topicLmsCourse?.description}</Typography>
        <div className='row center-xs'>
          <div className='col-xs-12'>
            {lmsItem?.file?.url && (
              <VideoPlayer
                videoLink={lmsItem.file.url}
                onEnded={onEnded}
                startAt={userLmsItem?.progress || undefined}
                onStop={(progress) => {
                  lmsItem?.createUserLmsItem({ progress }).then(() => {
                    user?.loadUserLmsItems({ reload: true })
                  })
                }}
                hideClose={true}
                autoPlay={true}
              />
              // <video autoPlay disablePictureInPicture={false} ref={videoRef} style={{ width: '100%' }}>
              //   <source src={lmsItem.file.url} type='video/mp4' />
              //   Your browser does not support the video tag.
              // </video>
            )}
          </div>
        </div>
      </Container>
    </PayWall>
  )
})

export default TopicVideo
