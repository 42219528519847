import React from 'react'
import ConversationsSummary from './ConversationsSummary'
import AdminMetaTags from '../../../shared/AdminMetaTags'

interface Props {}

const Conversations = (props: Props) => {
  return (
    <>
      <AdminMetaTags title='Conversations' />
      <ConversationsSummary />
    </>
  )
}

export default Conversations
