import React from 'react'
import { flow } from 'lodash'
import withTranslation, { WithTranslation } from '../../hocs/withTranslation'
import { observer } from 'mobx-react'
import { IConversation } from '../../../store/models/Conversation'
import { ITopic } from '../../../store/models/Topic'
import ActionPaneSwitcher from './ActionPaneSwitcher'

const FaqPane = ({ conversation, topic, t }: { conversation: IConversation; topic: ITopic } & WithTranslation) => {
  return (
    <>
      {conversation.isPremium && conversation.showFaqs && (
        <div>
          <section className='pane clear-pane xtra-fat-pane'>
            <div className='row center-xs huge-bottom'>
              <div className='col-xs-12'>
                <div className='box'>
                  <h2 className='header-32 header-24-sm light-light-grey-text'>{t('conversation.faq')}</h2>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-xs-12 col-sm-6'>
                <div className='box reg-bottom'>
                  <h3 className='header-24 thin-bottom'>{t('conversation.faqh1')}</h3>
                  <p className='flat-top xtra-fat-bottom'>{t('conversation.faqp1')}</p>
                  <h3 className='header-24 thin-bottom'>{t('conversation.faqh2')}</h3>
                  <p className='flat-top xtra-fat-bottom'>{t('conversation.faqp2')}</p>
                </div>
              </div>
              <div className='col-xs-12 col-sm-6'>
                <div className='box reg-bottom'>
                  <h3 className='header-24 thin-bottom'>{t('conversation.faqh3')}</h3>
                  <p className='flat-top xtra-fat-bottom'>{t('conversation.faqp3')}</p>
                  <h3 className='header-24 thin-bottom'>{t('conversation.faqh4')}</h3>
                  <p className='flat-top xtra-fat-bottom'>{t('conversation.faqp4')}</p>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  )
}

export default flow(observer, withTranslation)(FaqPane)
