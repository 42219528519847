import React, { useState } from 'react'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import moment from 'moment'
import { Container, Chip, IconButton } from '@material-ui/core'
import { flow, startCase } from 'lodash'
import ConversationActions from '../partials/ConversationActions'
import { inject, observer } from 'mobx-react'
import { IStore, IConversation, IUser } from '../../../../store/models'
import DataTable, { IColData, ValueLabel } from '../../../shared/DataTableFilterable'
import { IFilterable } from '../../../../store/models/helpers/Filterable'
import Dialog from '../../../DialogWrapper'

const ConversationsTable = ({
  loading,
  items,
  filterable,
  index,
  user,
  topicId,
  height = 'calc(100vh - 29em)',
  noDataMsg = 'No results found.'
}: Props & InjectedProps & RouteComponentProps) => {
  const columnData: IColData[] = [
    { name: 'id', title: 'ID', width: 90, sortable: true },
    { name: 'title', title: 'Title', width: 250, sortable: true },
    { name: 'startsAt', title: 'Date of Conversation', width: 150, sortable: true },
    { name: 'time', title: 'Time', width: 80 },
    { name: 'attendeeCount', title: 'Registered Guests', align: 'center', width: 120, icon: 'info-circle', sortable: true },
    // { name: 'attendeeCount', title: 'Registered Guests', align: 'center', width: 120, sortable: true },
    { name: 'maxAttendees', title: 'Maximum Attendees', align: 'center', width: 120, icon: 'info-circle', hint: 'Guests and Hosts', sortable: true },
    { name: 'guestsFull', title: ' ', align: 'left', width: 50 },

    { name: 'host', title: 'Host Name', width: 150 },
    { name: 'venueType', title: 'Venue', sortable: true },
    { name: 'guestPolicy', title: 'Guest Policy', sortable: true },
    { name: 'pendingInvitationsCount', title: 'Pending Requests', sortable: true },

    { name: 'phoneNumber', title: 'Host Phone' },
    { name: 'email', title: 'Host Email', width: 230 },

    { name: 'link', title: 'Link', width: 250 },

    { name: 'partnerName', title: 'Customer Name' },

    { name: 'eventType', title: 'Type', sortable: true },
    { name: 'hidden', title: 'Visibility', sortable: true },

    { name: 'neighborhood', title: 'Neighborhood', align: 'left', width: 150 },
    { name: 'city', title: 'City', align: 'left', width: 150 },
    { name: 'stateLevel', title: 'State', align: 'left', width: 100 },
    { name: 'country', title: 'Country', align: 'left', width: 150 },
    { name: 'topic', title: 'Topic', width: 240 },
    { name: 'hostNotes', title: 'Notes' },
    { name: 'actions', title: 'Actions', width: 220 }
  ]

  if (index === 1) {
    columnData.splice(6, 0, {
      name: 'attendedCount',
      align: 'right',
      title: 'Attendees',
      width: 120,
      icon: 'info-circle',
      hint: 'Guests and Hosts who clicked join link'
    })
  }

  const [hostNotes, setHostNotes] = useState(undefined)

  return (
    <>
      <DataTable
        topicId={topicId}
        data={items}
        columns={columnData}
        loading={loading}
        filterable={filterable}
        rightColumns={['actions']}
        searchPlaceholder='Search (Conversation Topic, Customer, Slug, Email, Phone, City, State, Country)'
        noDataMsg={noDataMsg}
        height={height}
        // eslint-disable-next-line complexity
        getCellValue={(row: any, columnName: string) => {
          switch (columnName) {
            case 'title':
              return (
                <ValueLabel
                  value={
                    <Link rel='noopener' title={row.title} to={`/${row.slug}/conversations/${row.id}`} target='blank' color='primary'>
                      {row.title}
                    </Link>
                  }
                />
              )
            case 'topic':
              return (
                <ValueLabel
                  value={
                    <Link rel='noopener' title={row.title} to={`/admin/topics/${row.slug}/edit`} target='blank' color='primary'>
                      {row.title}
                    </Link>
                  }
                />
              )
            case 'startsAt':
              return <ValueLabel value={row.startsAt && moment(row.startsAt).format('L')} />
            case 'time':
              return <ValueLabel value={row.startsAt && moment(row.startsAt).format('LT')} />
            case 'attendeeCount':
              return <ValueLabel value={row.guestCount} />
            case 'guestsFull':
              return <ValueLabel value={row.attendeeCount === row.maxAttendees ? <Chip size='small' label='Full' /> : ' '} />
            case 'host':
              return row.host && <ValueLabel value={`${row.host.firstName} ${row.host.lastName}`} />
            case 'phoneNumber':
              return row.host && <ValueLabel value={row.host.phoneNumber} />
            case 'email':
              return row.host && <ValueLabel value={row.host.email} />
            case 'venueType':
              return <ValueLabel value={row.venueType === 'virtual' ? 'Online' : row.venueType === 'other' ? 'Office / Other' : startCase(row.venueType)} />
            case 'eventType':
              return <ValueLabel value={row.eventTypeDisplay} />
            case 'hidden':
              return <ValueLabel value={row.visibility} />
            case 'guestPolicy':
              return <ValueLabel value={row.guestPolicyDisplay} />
            case 'hostNotes':
              return (
                row.hostNotes && (
                  <IconButton size='small' onClick={() => setHostNotes(row.hostNotes)}>
                    <i className='fas fa-sticky-note opacity-8'></i>
                  </IconButton>
                )
              )

            // <ValueLabel value={row.hostNotes} />
            case 'link':
              return (
                <ValueLabel
                  value={
                    <Link rel='noopener' to={`/${row.slug}/conversations/${row.id}`} target='blank' color='primary'>
                      {`/${row.slug}/conversations/${row.id}`}
                    </Link>
                  }
                />
              )
            case 'actions':
              return <ConversationActions conversation={row} />
            default:
              return <ValueLabel value={row[columnName]} />
          }
        }}
      />

      <Dialog
        open={!!hostNotes}
        dialog={{
          closeDialog: () => {
            setHostNotes(undefined)
          }
        }}
        title={'Conversation Host Notes'}
      >
        <Container>
          <div className='fat-top fat-bottom'>{hostNotes}</div>
        </Container>
      </Dialog>
    </>
  )
}
interface Props {
  index: number
  topicId?: number
  height?: string
  noDataMsg?: string
}
interface InjectedProps {
  items: IConversation[]
  loading: boolean
  filterable: IFilterable
  user?: IUser
}

export default flow(
  observer,
  inject<InjectedProps, Props>(({ mst: { conversations, auth } }: { mst: IStore }) => {
    return {
      user: auth.user,
      filterable: conversations,
      items: conversations.list,
      loading: conversations.loadingIds.includes('conversations_table')
    }
  }),
  withRouter
)(ConversationsTable)
