import React from 'react'
import Button from '@material-ui/core/Button'
import { isUndefined } from 'lodash'
import { DialogTitle, createStyles, makeStyles } from '@material-ui/core'

import CalendarDropdown from '../../shared/CalendarDropdown'
import withTranslation from '../../hocs/withTranslation'
import { observer } from 'mobx-react'
import ShareConversation from '../partials/ShareConversation'
import appAnalytics from '../../../analytics'

const CreatedConversationContentStepOne = (props: any) => {
  if (isUndefined(props.dialog?.data?.conversation)) return null

  const {
    conversation: { title, locale, hostGuideUrl },
    conversation,
    t
  } = props

  const labelStyle = locale === 'es_ES' ? 'btn-xl-long-label' : 'btn-xl'
  const classes = Styles()

  return (
    <div>
      <DialogTitle disableTypography>{t('invitation.success')}</DialogTitle>

      <section className='pane thin-reg-pane'>
        <div className='row'>
          <div className='col-xs-12 center-xs'>
            <p className='flat-top' dangerouslySetInnerHTML={{ __html: t('conversation.yourHost') }} />
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12 center-xs'>
            <h1 className='header-28 blue-text flat-bottom'>{title}</h1>
          </div>
        </div>

        <div className='row reg-top'>
          <div className='row center-xs'>
            <CalendarDropdown conversation={conversation} />
          </div>
        </div>
      </section>

      <section className='pane minimal-grey-pane fat-top-padding big-bottom-padding'>
        <div className='row'>
          <div className='col-xs-12 fat-bottom center-xs'>
            <h2 className={classes.header}>{t('socialMedia.shareEvent')}</h2>
          </div>
          <div className='row no-gutters reg-bottom'>
            <div className='no-gutters'>
              <ShareConversation
                conversation={conversation}
                style={{ color: '#ff0000' }}
                gridSizes={{ xs: 12 }}
                color='#737374'
                labelColor='#212122'
                align='left'
                longLabel={true}
                buttonContainerStyle={{ marginLeft: '7.5em' }}
              />
            </div>
          </div>
        </div>
      </section>

      <section className='pane thin-reg-pane'>
        <div className='center-xs'>
          <div className='col-xs-12 no-gutters'>
            <Button
              startIcon={<i className='far fa-book-open'></i>}
              className={`reg-bottom flat-top ${labelStyle} btn-block dialog-btn`}
              onClick={() => {
                appAnalytics.event({
                  category: 'Conversation',
                  action: 'Download Host Guide'
                })
                window.open(hostGuideUrl.replace(/dl=0/gi, 'dl=1'))
              }}
              variant='contained'
            >
              {t('conversation.downloadHostGuide')}
            </Button>
          </div>
        </div>
      </section>
    </div>
  )
}
const Styles = makeStyles((theme) =>
  createStyles({
    header: {
      fontFamily: 'Avenir',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '22px',
      lineHeight: '30px',
      marginBottom: 0
    }
  })
)
export default observer(withTranslation(CreatedConversationContentStepOne))
