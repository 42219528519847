import React from 'react'
import SummaryContainer from '../partials/SummaryContainer'
import DownloadsTable from './DownloadsTable'
import { Divider, Paper, Typography } from '@material-ui/core'

interface Props {
  height?: string
}

const DownloadsSummary = (props: Props) => {
  return (
    <SummaryContainer>
      <Paper>
        <Typography variant='h6' component='p' className='reg-padding'>
          My Downloads
        </Typography>

        <Divider />

        <Typography variant='subtitle2' component='p' className='reg-padding'>
          Your downloads are available for 3 days.
        </Typography>

        <Divider />

        <DownloadsTable height={props.height} />
      </Paper>
    </SummaryContainer>
  )
}

export default DownloadsSummary
