import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { flow } from 'lodash'
import { IConversation } from '../../store'

const ConversationProvider = ({ children }: Props) => {
  const [conversation, setConversation] = useState(undefined)
  return <ConversationContext.Provider value={{ conversation, setConversation }}>{children}</ConversationContext.Provider>
}

interface Props {
  children?: React.ReactNode | React.ReactNode[]
}

export const ConversationContext = React.createContext({
  conversation: undefined as IConversation | undefined,
  setConversation(conversation: any) {
    //  do nothing
  }
})

export default flow(observer)(ConversationProvider)
