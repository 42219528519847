import React, { useContext, useState } from 'react'
import Dialog from '../DialogWrapper'
import { flow, map, range, pick, pickBy, identity } from 'lodash'
import withTranslation, { WithTranslation } from '../hocs/withTranslation'
import { withRouter } from 'react-router'
import { IDialog } from '../../store/models/Dialog'
import { TextFieldControl, NpsControlGroup } from '../utils'
import { required } from '../validations/fieldLevelValidations'
import { useForm } from 'react-hook-form'
import { Button, createStyles, DialogActions, makeStyles, Typography } from '@material-ui/core'
import palette from '../../themes/palette'
import { inject, observer } from 'mobx-react'
import { IStore } from '../../store'
interface InjectedProps {
  saveNps(val): Promise<any>
  showDialog(val: any, data: any)
  attendeeSurveyLink?: string
}
interface Props {
  dialog: IDialog
  history: any
}

const NpsDialog = ({ saveNps, dialog, t, i18n, history, showDialog }: Props & InjectedProps & WithTranslation) => {
  const { handleSubmit, errors, formState, control } = useForm({ mode: 'onBlur', shouldUnregister: false })
  const { dirtyFields } = formState
  const { score, attendeeSurveyLink } = dialog.data as any
  const [saving, setSaving] = useState(false)

  const handleSave = handleSubmit((data: any, e) => {
    data = pick(data, Object.keys(pickBy(dirtyFields, identity)))
    setSaving(true)
    saveNps({ ...dialog.data, ...data })
      .then(() => {
        showDialog('nps2', dialog.data)
        dialog.closeDialog()
        setSaving(false)
      })
      .catch(() => {
        setSaving(false)
      })
  })

  const npsOptions = () =>
    map(range(11), (i) => {
      return { label: i, value: i.toString() }
    })

  const npsOptionsData = npsOptions()

  const classes = Styles()

  return (
    <Dialog dialog={dialog} name='nps' type={'wide'} closeIcon={true}>
      <form onSubmit={handleSave}>
        <div>
          <section className={classes.headerContainer}>
            <Typography variant='subtitle1'>{t('nps.dialogHeader')}</Typography>
          </section>
          <section className={classes.bodyContainer}>
            <Typography variant='caption'>{t('nps.dialogCaption1')}</Typography>
            <div>
              <Typography variant='caption'>{t('nps.dialogCaption2')}</Typography>
            </div>
            <Typography variant='body2' className={classes.label}>
              {t('nps.label')}
            </Typography>
            <div className={classes.nps}>
              <NpsControlGroup
                defaultValue={score}
                name='score'
                control={control}
                required
                error={errors && errors.score}
                rules={{ validate: required }}
                options={npsOptionsData}
              />

              <Typography variant='caption' className={classes.npsKey1}>
                {t('nps.key0')}
              </Typography>
              <Typography variant='caption' className={classes.npsKey2}>
                {t('nps.key10')}
              </Typography>
            </div>
          </section>
          <section className={classes.actionContainer}>
            <TextFieldControl name='reason' control={control} multiline={true} rows={3} label={t('nps.reasonLabel')} />
          </section>
        </div>
        <DialogActions>
          <Button type='submit' disabled={saving} variant='contained' color='primary' className={classes.submit}>
            {t('common.next')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

const Styles = makeStyles((theme) =>
  createStyles({
    root: {},
    headerContainer: {
      padding: '.5rem',
      textAlign: 'center'
    },
    label: {
      marginTop: '1rem'
    },
    bodyContainer: {
      backgroundColor: palette.grey100,
      textAlign: 'center',
      padding: '2rem .5rem'
    },
    nps: {
      padding: '1rem 1rem 0',
      position: 'relative'
    },
    npsKey1: {
      position: 'absolute',
      left: '2.4rem',
      bottom: '-1rem'
    },
    npsKey2: {
      position: 'absolute',
      right: '2.4rem',
      bottom: '-1rem'
    },
    actionContainer: { padding: '1rem 3rem', backgroundColor: palette.grey100 },
    submit: { marginTop: '2rem' },
    actionContainer2: { padding: '3rem', textAlign: 'center' },
    bodyContainer2: { backgroundColor: palette.grey100, textAlign: 'center', padding: '2rem 6rem' },
    imgContainer: { width: '56px', textAlign: 'center', margin: '0 auto 1rem' }
  })
)

export default flow(
  observer,
  inject<InjectedProps, null>(
    ({
      mst: {
        nps: { saveNps },
        dialogs: { showDialog }
      }
    }: {
      mst: IStore
    }) => {
      return {
        saveNps,
        showDialog
      }
    }
  ),
  withTranslation
)(NpsDialog)
