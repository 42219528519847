import React, { Component } from 'react'
import FontIcon from '@material-ui/core/Icon'

import withTranslation from '../../hocs/withTranslation'
import Dialog from '../../DialogWrapper'
import { Button, Typography } from '@material-ui/core'
// import { useParamConversation } from '../../Providers'

export class ConversationInvitationRequestSentDialog extends Component {
  render() {
    const { t, dialog } = this.props
    // const { conversation } = useParamConversation()
    const { conversation } = dialog.data || {}
    console.log({ conversation })
    return (
      <Dialog dialog={dialog} name='conversationInvitationRequestSent' title='Thanks for Your Request!'>
        <section className='pane thin-reg-pane minimal-grey-pane' style={{ textAlign: 'center' }}>
          <FontIcon style={{ color: '#5D3A92', fontSize: '5rem', marginBottom: '1rem' }} className='far fa-envelope' />
          <Typography variant='h6' align='center'>
            Your invitation is pending host approval.
          </Typography>
        </section>
        <section className='pane thin-reg-pane'>
          <Typography variant='body2' align='center'>
            Your request has been sent to the host of this conversation. You will be notified by email when your request is approved.
          </Typography>
        </section>
        {/* <div className='col-xs-12 center-xs reg-padding'>
          <Button
            fullWidth
            style={{ color: '#E51814' }}
            size='large'
            color='secondary'
            onClick={() => {
              dialog.closeDialog()
            }}
          >
            Cancel Request
          </Button>
        </div> */}
      </Dialog>
    )
  }
}

export default withTranslation(ConversationInvitationRequestSentDialog)
