import { types, Instance } from 'mobx-state-tree'
import UserLmsItemsApi from '../../../api/users/UserLmsItemsApi'
import LmsItemsApi from '../../../api/UserLmsItemsApi'

import { UserBase } from './UserBase'
import Editable from '../helpers/Editable'

export const UserLmsItem = types
  .compose(
    'UserLmsItem',
    Editable,
    types.model('UserLmsItem', {
      id: types.identifierNumber,
      userId: types.maybeNull(types.number),
      lmsItemId: types.maybeNull(types.number),
      progress: types.maybeNull(types.number),
      status: types.maybeNull(types.string)
    })
  )
  .actions((self) => ({
    updateAction: (data) => {
      return LmsItemsApi.update({ userLmsItemId: self.id, data }).then(({ response: { ok, status, statusText }, json }) => {
        if (ok) {
          self.updateMe(json)
        }
      })
    },
    deleteAction: () => {
      return LmsItemsApi.delete(self.id)
    }
  }))

export const UserLmsItems = types
  .compose(
    'UserLmsItems',
    UserBase,
    types.model({
      userLmsItems: types.optional(types.array(UserLmsItem), [])
    })
  )
  .actions((self) => ({
    setUserLmsItems(data: any) {
      try {
        self.userLmsItems.replace(data)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('oops')
      }
    }
  }))
  .actions((self) => ({
    loadUserLmsItems: ({ reload = false } = {}) => {
      const listName = 'userLmsItems'
      if (self.isLoading(listName) || (!reload && self.isLoaded(listName))) {
        return
      }
      self.startLoading(listName)
      return UserLmsItemsApi.getAll(self.id).then(({ response: { ok }, json }) => {
        if (ok) {
          self.setUserLmsItems(json)
        }
        self.stopLoading(listName)
      })
    }
  }))

export interface IUserLmsItem extends Instance<typeof UserLmsItem> {}
export interface IUserLmsItems extends Instance<typeof UserLmsItems> {}
export default UserLmsItems
