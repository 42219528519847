import { Divider, Grid, Tab, Tabs } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import AnalyticsSummary from './AnalyticsSummary'
import DownloadsSummary from './DownloadsSummary'
import AdminMetaTags from '../../../shared/AdminMetaTags'
import { flow } from 'lodash'
import { observer, inject } from 'mobx-react'
import { IStore, IUser } from '../../../../store/models'
import PartnerSelector from '../AdminDashboard/PartnerSelector'

interface Props {}
interface InjectedProps {
  user?: IUser
}

const TAB_URL = ['', 'downloads']

const Analytics = ({ match: { params }, history, user }: Props & InjectedProps & RouteComponentProps<{ tab: string }>) => {
  const newTabIndex = TAB_URL.indexOf(params.tab) === -1 ? 0 : TAB_URL.indexOf(params.tab)
  const [index, setIndex] = useState(newTabIndex)

  useEffect(() => {
    if (index !== newTabIndex) {
      history.replace(`/admin/analytics/${TAB_URL[index]}`)
    }
  }, [index, newTabIndex, history])

  useEffect(() => {
    user?.trackOnChurnzero() && window?.churnZero?.track('Analytics Reviewed')
  }, [user])

  return (
    <div>
      <AdminMetaTags title='Analytics' />
      <PartnerSelector />
      <Grid container direction='row' justify='space-between' alignItems='center'>
        <h2>Analytics</h2>
      </Grid>
      <Tabs
        value={index}
        onChange={(e, val) => {
          setIndex(val)
        }}
        indicatorColor='primary'
        textColor='primary'
      >
        <Tab label={'Analytics'} />
        <Tab label={'My Downloads'} />
      </Tabs>
      <Divider className='fat-bottom' />
      {index === 0 && <AnalyticsSummary />}
      {index === 1 && <DownloadsSummary />}
    </div>
  )
}

export default flow(
  observer,
  withRouter,
  inject<InjectedProps, Props>(
    ({
      mst: {
        auth: { user }
      }
    }: {
      mst: IStore
    }) => {
      return {
        user
      }
    }
  )
)(Analytics)
