import React, { useContext } from 'react'
import { useHistory } from 'react-router'
import { AlertContext } from '../components/Providers/AlertProvider'

import { AuthContext } from '../components/Providers/AuthProvider'
import { ParamConversationContext } from '../components/Providers/ParamConversationProvider'
import { ParamPartnerContext } from '../components/Providers/ParamPartnerProvider'
import { ParamTopicContext } from '../components/Providers/ParamTopicProvider'
import LoadingAnimation from '../components/shared/LoadingAnimation'
import { IConversation, IPartner, ITopic, IUser } from '../store'

interface Props {
  children?: FIXME //React.ReactElement | React.ReactElement[]
  roleLevel?: 'user' | 'community_admin' | 'partner_admin' | 'super_admin'
  reroute?: string
  validation?(data: { topic?: ITopic; conversation?: IConversation; partner?: IPartner; user?: IUser }): boolean
}

export const LoggedInOnly = ({ children, roleLevel = 'user', reroute = '/', validation }: Props) => {
  const { loaded, user } = useContext(AuthContext)
  const { topic, topicLoading } = useContext(ParamTopicContext)
  const { conversation, conversationLoading } = useContext(ParamConversationContext)
  const { partner, partnerLoading } = useContext(ParamPartnerContext)

  const rerouteUser = () => {
    history.push(reroute)
  }
  const rerouteIfNotValidated = () => {
    if (validation) {
      const isValid = validation({ topic, conversation, partner, user })
      if (isValid !== undefined && !isValid) {
        rerouteUser()
      }
    } else {
      rerouteUser()
    }
  }
  const history = useHistory()

  if (!loaded || topicLoading || conversationLoading || partnerLoading) return <LoadingAnimation />
  if (user) {
    if (
      (roleLevel === 'community_admin' && !user.isCommunityAdmin()) ||
      (roleLevel === 'partner_admin' && !user.isPartnerAdmin()) ||
      (roleLevel === 'super_admin' && !user.isSuperAdmin())
    ) {
      rerouteIfNotValidated()
    }

    return children
  }
  rerouteIfNotValidated()
  return <></>
}

export const LoggedInPageWithProviders = (PageComponent, pageProviders: any[] = [], validation: any = undefined) => (props) => {
  return pageProviders.length > 0 ? (
    pageProviders.reduce(
      (sum, PageProvider: any) => <PageProvider {...props}>{sum}</PageProvider>,
      <LoggedInOnly validation={validation}>
        <PageComponent {...props} />
      </LoggedInOnly>
    )
  ) : (
    <LoggedInOnly validation={validation}>
      <PageComponent {...props} />
    </LoggedInOnly>
  )
}

export const PageWithProviders = (PageComponent, pageProviders: any[] = [], validation: any = undefined) => (props) => {
  return pageProviders.length > 0 ? (
    pageProviders.reduce((sum, PageProvider: any) => <PageProvider {...props}>{sum}</PageProvider>, <PageComponent {...props} />)
  ) : (
    <PageComponent {...props} />
  )
}
export default LoggedInOnly
