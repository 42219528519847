import * as types from '../actions/actionTypes'
import { assign, keys, omit } from 'lodash'
import initialState from './initialState'

export const sponsors = (state = initialState.sponsors, action) => {
  switch (action.type) {
    case types.LOAD_SPONSORS_SUCCESS:
      return assign({}, state, {
        error: null,
        loading: false,
        list: assign({}, state.list, action.sponsors)
      })

    case types.LOAD_SPONSORS_FAILED:
      return assign({}, state, {
        error: action.data,
        loading: false
      })

    case types.SPONSORS_LOADING:
      return assign({}, state, {
        error: null,
        loading: true
      })

    case types.DELETE_SPONSORS_SUCCESS:
      return assign({}, state, {
        loading: false,
        error: null,
        list: omit(state.list, keys(action.sponsors))
      })

    default:
      return state
  }
}

export default sponsors
