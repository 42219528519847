class FacebookUsersApi {
  static create(responseData) {
    const user = responseData
    const { id: uid, first_name, last_name, picture, signedUpTopicId, signedUpConversationId, locale, accessToken } = user

    const request = new Request(`${process.env.REACT_APP_API_HOST}/facebook_users`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({
        user: {
          uid,
          first_name,
          last_name,
          profile_image: picture.data.url,
          signedUpTopicId,
          signedUpConversationId
        },
        locale,
        accessToken,
        site_name: global.siteName
      })
    })

    return fetch(request)
      .then((response) => {
        return response.json()
      })
      .catch((error) => {
        return error
      })
  }
}

export default FacebookUsersApi
