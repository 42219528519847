import React, { useContext, useState } from 'react'
import { flow } from 'lodash'

import { inject, observer } from 'mobx-react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory, withRouter } from 'react-router'

import * as validations from '../validations/fieldLevelValidations'
import PageLoadingAnimation from '../shared/PageLoadingAnimation'

import { TextFieldControl, RadioGroupControl, AvatarControl } from '../utils'
import { createStyles, Divider, FormControlLabel, Grid, makeStyles, RadioGroup, Theme, Typography, Button } from '@material-ui/core'
import { ParamTopicContext, AuthContext, ParamConversationContext, DialogContext } from '../Providers'
import { IStore, IStories } from '../../store'

const Form = ({ onSubmit, stories }: Props & InjectedProps) => {
  const { handleSubmit, errors, formState, control, watch, setError, reset, clearErrors, setValue } = useForm({ mode: 'onChange' })

  const { isDirty, isSubmitting, dirtyFields, isValid } = formState

  const [submitting, setsubmitting] = useState(false)
  const { topic } = useContext(ParamTopicContext)
  const { conversation } = useContext(ParamConversationContext)
  const { user } = useContext(AuthContext)
  const { showDialog } = useContext(DialogContext)
  const history = useHistory()
  const classes = Styles()

  const { t } = useTranslation()

  if (!topic) return <></>
  const handleSave = handleSubmit((data: any, e) => {
    setsubmitting(true)
    const storyData = { ...data, user_id: user?.id, topic_id: topic?.id, conversation_id: conversation?.id, prompt: t(`story.${data.prompt}`) }
    topic.create(storyData).then(({ data: story }) => {
      if (story) {
        onSubmit && onSubmit()
        history.goBack()
        showDialog('storyCollectionSuccess', { story, conversation, storyId: story.id })
      }
    })
  })

  if (!topic || !conversation) {
    return <PageLoadingAnimation />
  }
  const PROMPT_EXAMPLES = [
    {
      label: <Typography variant='body2'>{`${t('story.prompt_1')}...`}</Typography>,
      value: 'prompt_1'
    },
    {
      label: <Typography variant='body2'>{`${t('story.prompt_2')}...`}</Typography>,
      value: 'prompt_2'
    },
    {
      label: <Typography variant='body2'>{`${t('story.prompt_3')}...`}</Typography>,
      value: 'prompt_3'
    },
    {
      label: <Typography variant='body2'>{`${t('story.prompt_4')}...`}</Typography>,
      value: 'prompt_4'
    }
  ]
  const promptChange = (val) => {
    if (val === 'prompt_4') {
      setValue('content', '', { shouldValidate: true })
    } else {
      setValue('content', `${t(`story.${val}`)} `, { shouldValidate: true })
    }
  }
  return (
    <div className={classes.root}>
      <div>
        <Typography variant='h5'>{t('story.shareYourStory')}</Typography>
        <Typography variant='caption' component='p' style={{ marginBottom: '1.5em' }}>
          {t('story.estimate', { min: 3, max: 5 })}
        </Typography>
        <Typography variant='body1' component='p' style={{ marginBottom: '1.5em' }}>
          {t('story.welcomeHeader', { first_name: user?.firstName, convo_topic: topic?.title, sponsor: topic?.partnerName })}
        </Typography>
        <Typography variant='body2' component='p'>
          {t('story.didYouKnow')}
        </Typography>
      </div>
      <Divider className={classes.divider} />
      <form onSubmit={handleSave}>
        <div>
          <Typography variant='body1' style={{ marginBottom: '1em' }}>
            {t('story.choosePrompt')}
          </Typography>
          <Typography variant='caption' component='p' style={{ marginBottom: '1em' }}>
            {t('story.selectOne')}
          </Typography>
          <Grid container className={classes.radioContainer}>
            <RadioGroupControl
              name='prompt'
              control={control}
              onChange={promptChange}
              RadioGroupWrapper={({ children, ...props }) => {
                return (
                  <RadioGroup {...props}>
                    <Grid container spacing={2} justify='center'>
                      {children}
                    </Grid>
                  </RadioGroup>
                )
              }}
              RadioWrapper={(props) => {
                return (
                  <Grid item xs={12} sm={6} md={6}>
                    <FormControlLabel {...props} className={classes.radio} />
                  </Grid>
                )
              }}
              options={PROMPT_EXAMPLES}
            />
          </Grid>
        </div>
        <Divider className={classes.divider} />
        <Typography variant='body1' component='p' style={{ marginBottom: '1em' }}>
          {t('story.addMedia')}
        </Typography>
        <Typography variant='body2' component='p' style={{ marginBottom: '0.5em' }}>
          {t('story.addMediaHelper')}
        </Typography>
        <Typography variant='caption' component='p' style={{ marginBottom: '1.5em' }}>
          {t('story.uploadFileHelper')}
        </Typography>
        <div className={classes.avatarImage}>
          <AvatarControl
            name='asset'
            accept='image/*,video/*'
            control={control}
            avatarSize={'profile-primary'}
            className={classes.preview}
            rounded={false}
            placeHolder={<MediaPlaceholder />}
          />
        </div>
        <Divider className={classes.divider} />
        <Typography variant='body1' style={{ marginBottom: '1em' }}>
          {t('story.addHeadline')}
        </Typography>
        <Typography variant='caption'>{t('story.addHeadlineHelper')}</Typography>
        <TextFieldControl
          name='headline'
          control={control}
          defaultValue={''}
          error={errors && errors.headline}
          required
          variant='outlined'
          className={`${classes.field} ${classes.sectionSpacer2}`}
          rules={{ validate: validations.required }}
        />
        <Typography variant='body1'>
          {t('story.storyFieldHeader')} <Typography variant='caption'>{t('story.storyFieldHeaderCaption')}</Typography>
        </Typography>
        <TextFieldControl
          name='content'
          control={control}
          defaultValue={''}
          error={errors && errors.content}
          required
          className={`${classes.field} ${classes.sectionSpacer2}`}
          variant='outlined'
          rules={{ validate: { required: validations.required, maxLength: validations.maxLength(2500) } }}
          multiline={true}
          rows={3}
        />
        <Typography variant='caption' component='p' className={classes.sectionSpacer2}>
          {t('story.note', { sponsor: topic?.partnerName })}
        </Typography>
        <Button disabled={submitting || isSubmitting || !isValid} type='submit' color='primary' variant='contained' className={classes.sectionSpacer2}>
          {t('story.publishStory')}
        </Button>
      </form>
    </div>
  )
}
const Styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: '3em'
    },
    radioContainer: {
      margin: '1em 0 2em 0'
    },
    field: {
      backgroundColor: '#ffffff'
    },
    divider: {
      backgroundColor: '#F7BF14',
      margin: '1.5em 0 2em 0',
      height: '2px'
    },
    avatarImage: {
      maxHeight: '184px'
    },
    radio: {
      backgroundColor: 'white',
      width: '100%',
      borderRadius: '10px',
      border: '1px solid #DFE0E2',
      marginLeft: 0,
      height: '100%',
      padding: '.2em 0'
    },
    sectionSpacer2: {
      marginBottom: '2em'
    },
    sectionSpacer1: {
      marginBottom: '1em'
    },
    preview: {
      backgroundColor: '#ff0000',
      maxHeight: '184px'
    }
  })
)
// export default flow(observer)(Form)

//  TODO: Move somewhere
const MediaPlaceholder = () => {
  return (
    <div style={{ backgroundColor: '#ffffff', padding: '2em', textAlign: 'center' }}>
      <i className='far fa-photo-video img-responsive' style={{ fontSize: '5em', margin: 'auto auto', color: '#898A8E' }}></i>
    </div>
  )
}

interface Props {
  onSubmit?: any
}
interface InjectedProps {
  stories: IStories
}
export default flow(
  observer,
  inject<InjectedProps, Props>(({ mst: { stories } }: { mst: IStore }, ownProps) => {
    return {
      stories,
      onSumbit: ownProps
    }
  }),
  withRouter
)(Form)
