import { assign } from 'lodash'
import { post } from './utils'

class MessagesApi {
  static create(data) {
    data = assign({}, data, { site_name: global.siteName })

    return post(`/messages`, {
      body: JSON.stringify(data)
    })
  }
}

export default MessagesApi
