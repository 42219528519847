import appAnalytics from '../analytics'
import { put, post } from './utils'

class ConversationInvitationRequestsApi {
  static create(values) {
    return post(`/conversation_invitation_requests`, {
      body: JSON.stringify(values)
    }).then((d) => {
      appAnalytics.event({
        category: 'Conversation',
        action: 'Invitation Requested'
      })
      return d
    })
  }

  static update(values) {
    return put(`/conversation_invitation_requests/${values.id}`, {
      body: JSON.stringify(values)
    }).then((d) => {
      appAnalytics.event({
        category: 'Conversation',
        action: 'Invitation Request Updated'
      })
      return d
    })
  }
}

export default ConversationInvitationRequestsApi
