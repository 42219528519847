import { CardContent, Card, CardHeader, Grid } from '@material-ui/core'
import React, { useEffect } from 'react'
import ReactWordcloud, { Options } from 'react-wordcloud'
import { flow } from 'lodash'

import { observer } from 'mobx-react'
import { inject } from 'mobx-react'

import { IStore } from '../../../../store/models'
import { IUser } from '../../../../store/models/User'
import { RouteComponentProps, withRouter } from 'react-router'

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {
  stories: any
  items: any
  selectedPartnerId?: number
  reloadList?(): void
}
const words = [
  {
    text: 'told',
    value: 64
  },
  {
    text: 'mistake',
    value: 11
  },
  {
    text: 'thought',
    value: 16
  },
  {
    text: 'bad',
    value: 17
  }
]
const Stories = ({ items, selectedPartnerId, reloadList }: Props) => {
  const options: Options = {
    // colors: ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b'],
    colors: ['#1f77b4'],
    enableTooltip: true,
    deterministic: false,
    fontFamily: 'impact',
    fontSizes: [20, 80],
    fontStyle: 'normal',
    fontWeight: 'normal',
    padding: 1,
    rotations: 1,
    rotationAngles: [0, 90],
    scale: 'sqrt',
    spiral: 'archimedean',
    transitionDuration: 500,
    enableOptimizations: true,
    svgAttributes: {},
    textAttributes: {},
    tooltipOptions: {}
  }
  const [loadedId, setLoadedId] = React.useState<number | undefined>(selectedPartnerId)

  useEffect(() => {
    if (selectedPartnerId !== loadedId) {
      reloadList && reloadList()
      setLoadedId(selectedPartnerId)
    }
  }, [selectedPartnerId, loadedId, reloadList])

  return items && items.length > 0 ? (
    <Grid item sm={6}>
      <Card>
        <CardHeader title='Word Cloud based on all Shared Stories' />
        <CardContent>
          <div>
            <ReactWordcloud options={options} words={items.map((i) => i.toJSON())} />
          </div>
        </CardContent>
      </Card>
    </Grid>
  ) : null
}

export default flow(
  observer,
  inject<FIXME, FIXME>(
    ({
      mst: {
        auth: { user },
        partners: { selectedPartnerId },
        stories
      }
    }: {
      mst: IStore
    }) => {
      stories.shouldLoad('stories_table', stories.loadFilter)
      return {
        user,
        stories,
        filterable: stories,
        items: stories.words,
        selectedPartnerId,
        reloadList: () => stories.loadFilter(),
        loading: stories.loadingIds.includes('stories_table')
      }
    }
  ),
  withRouter
)(Stories)
