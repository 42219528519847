import React from 'react'
import TopicsSummary from './TopicsSummary'
import AdminMetaTags from '../../../shared/AdminMetaTags'

interface Props {}

const Topics = (props: Props) => {
  return (
    <>
      <AdminMetaTags title='Topics' />
      <TopicsSummary />
    </>
  )
}

export default Topics
