import React from 'react'
import { RadioGroupControl } from '../../utils'
import { StepProps } from './NewStepper'
import withTranslation, { WithTranslation } from '../../hocs/withTranslation'
import { Typography } from '@material-ui/core'

const OtherConversationType = ({ t, control }: StepProps & WithTranslation) => {
  return (
    <>
      <Typography variant='body2'>While including a meal is optional, we know that great conversations happen over food and drink.</Typography>
      <Typography variant='subtitle2' className='fat-top thin-bottom'>
        Meal Type
      </Typography>
      <RadioGroupControl
        name='conversationType'
        control={control}
        options={[
          {
            label: t('conversation.potLuckLabel'),
            value: 'potluck'
          },
          {
            label: t('conversation.cateredLabel'),
            value: 'catered'
          },
          {
            label: t('conversation.fullyCateredLabel'),
            value: 'fully_catered'
          },
          {
            label: t('conversation.noFood'),
            value: 'no_food_provided'
          },
          {
            label: t('common.other'),
            value: 'other'
          }
        ]}
      />
    </>
  )
}

export default withTranslation(OtherConversationType)
