import { types, getRoot, getType } from 'mobx-state-tree'
import { IStore } from '..'
import { IRoot } from '../Root'

export const AfterLoginActionable = types.model('AfterLoginActionable', {}).actions((self) => ({
  setAfterLoginAction: (action: string) => {
    const { auth }: IStore = getRoot(self)
    auth.setAfterLoginAction(self, action)
  }
}))
export const AfterLoginActionProcessor = types
  .model('AfterLoginActionProcessor', {
    afterLoginAction: types.maybe(types.frozen(types.map(types.union(types.string, types.number))))
  })
  .actions((self) => ({
    setAfterLoginAction: (afterLoginAction, action: string) => {
      const type = getType(afterLoginAction).name
      self.afterLoginAction = { type, id: afterLoginAction.id, action }
    },
    afterLoginActionProcess: () => {
      if (self.afterLoginAction) {
        const { topics, conversations, partners, users }: IRoot = getRoot(self)
        const { type, id, action } = self.afterLoginAction
        let item

        if (type === 'Topic') {
          item = topics.list.find((c) => c.id === id)
        }
        if (type === 'Conversation') {
          item = conversations.list.find((c) => c.id === id)
        }
        if (type === 'Partner') {
          item = partners.list.find((c) => c.id === id)
        }
        if (type === 'User') {
          item = users.list.find((c) => c.id === id)
        }

        item && item[action]()

        self.afterLoginAction = undefined
      }
    }
  }))
