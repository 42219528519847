import { get } from './utils'
import queryString from 'query-string'

class AnalyticsApi {
  static getAttendeesBarData(partner_ids, args) {
    const params = queryString.stringify({ ...args, ...(partner_ids && partner_ids.length ? { partner_ids } : {}) })
    return get(`/admin/analytics/attendees_bar_data?${params}`)
  }

  static getConversationsBarData(partner_ids, args) {
    const params = queryString.stringify({ ...args, ...(partner_ids && partner_ids.length ? { partner_ids } : {}) })
    return get(`/admin/analytics/conversations_bar_data?${params}`)
  }

  static getConversationsLineData(args) {
    const params = queryString.stringify({ ...args })
    return get(`/admin/analytics/conversations_line_data?${params}`)
  }

  static getAttendeesLineData(args) {
    const { partner_ids } = args
    console.log({ partner_ids })
    const params = queryString.stringify({ ...args })
    return get(`/admin/analytics/attendees_line_data?${params}`)
  }
}

export default AnalyticsApi
