import { types, Instance } from 'mobx-state-tree'
import { LoadableStatus } from './helpers/LoadableStatus'
import { AttendeesBar, ConversationsBar, ConversationsLine, AttendeesLine } from './AnalyticsData'

export const Analytics = types.compose(
  LoadableStatus,
  types.model({
    attendeesBar: AttendeesBar,
    conversationsBar: ConversationsBar,
    conversationsLine: ConversationsLine,
    attendeesLine: AttendeesLine
  })
)

export interface IAnalytics extends Instance<typeof Analytics> {}
export default Analytics
