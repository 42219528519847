import { assign } from 'lodash'

import { LOAD_HOSTED_DINNERS_FAILED, LOAD_HOSTED_DINNERS_SUCCESS, LOADING_HOSTED_DINNERS } from '../actions/actionTypes'
import initialState from './initialState'

export const hostedConversations = (state = initialState.hostedConversations, action) => {
  switch (action.type) {
    case LOADING_HOSTED_DINNERS:
      return assign({}, state, {
        loading: true
      })

    case LOAD_HOSTED_DINNERS_SUCCESS:
      return assign({}, state, {
        loading: false,
        error: null,
        list: assign({}, action.hostedConversations)
      })

    case LOAD_HOSTED_DINNERS_FAILED:
      return assign({}, state, {
        loading: false,
        error: action.data
      })

    default:
      return state
  }
}

export default hostedConversations
