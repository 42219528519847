import React, { Component } from 'react'
import { connect } from 'react-redux'

import Sponsors from '../topics/partials/Sponsors'

class Section extends Component {
  render() {
    const { header } = this.props

    return (
      <section className='pane clear-pane fat-pane fat-pane-xs'>
        <div className='row center-xs'>
          <div className='col-xs-12'>
            <div className='box'>
              <h2 className='header-32 header-24-sm fat-bottom'>{header}</h2>
            </div>
          </div>
        </div>

        <Sponsors section={this.props} />
      </section>
    )
  }
}

export default Section = connect(null, null)(Section)
