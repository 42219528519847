import { types, Instance } from 'mobx-state-tree'
import { removeNullStringValue } from '../utils'
import TopicBase from './TopicBase'
import testEmailsApi from '../../../api/TestEmailsApi'
import i18n from 'i18next'
import { Story } from '../Stories'
import StoriesApi from '../../../api/StoriesApi'
import { snakify } from '../../../decorators/textTools'
import { RootAccessable } from '../helpers/RootAccessable'

export const TopicStories = types
  .compose(
    'TopicStories',
    RootAccessable,
    TopicBase,
    types.model({
      topicStories: types.optional(types.array(Story), [])
    })
  )
  .actions((self) => ({
    setTopicStories(val: any[]) {
      val.forEach((d) => removeNullStringValue(d))
      self.topicStories.replace(val.sort((a, b) => a.id - b.id))
    },
    updateListInList: (data) => {
      self.topicStories.replace(self.topicStories.filter((n) => (n.id === data.id ? data : n)))
    },
    addToStories: (data: any) => {
      removeNullStringValue(data)
      const found = self.topicStories.find((o) => o.id === data.id)
      if (found) {
        Object.assign(found, data)
      } else {
        self.topicStories.push(data)
      }
    },
    loadMyConversations: () => {
      const { user } = self.getAuth()
      if (user) {
        const conversations = self.getConversations()
        conversations.shouldLoad('myConversationsList', conversations.loadMyConversations)
        conversations.shouldLoad('hostedList', conversations.loadHostedConversations)
        user.loadedIds.includes('userConversations')
      }
    }
  }))
  .actions((self) => ({
    loadTopicStories: () => {
      const lisName = 'topicStories'
      if (self.isLoading(lisName)) {
        return
      }
      self.startLoading(lisName)

      return StoriesApi.getStoriesForTopic({ topic_id: self.id }).then(({ response: { ok, statusText }, json: { stories, total } }) => {
        if (ok) {
          self.setTopicStories(stories)
        } else {
          self.showAlert(statusText)
        }
        self.stopLoading(lisName)
      })
    },
    sendTestEmail: (data) => {
      return testEmailsApi.create({ ...data, topic_id: self.id }).then((response) => {
        if (response.response.ok) {
          self.showAlert(i18n.t('conversationNotifications.testEmailSuccess'))
        } else {
          self.showAlert(i18n.t('conversationNotifications.testEmailFail'))
        }
      })
    },
    create: (data: any) => {
      data.asset === '' && delete data.asset

      return StoriesApi.create(snakify(data)).then(({ response: { ok }, json }) => {
        if (ok) {
          self.addToStories(json)
          return { data: self.topicStories.find((s) => s.id === json.id) }
        } else {
          return { error: json }
        }
      })
    }
  }))

export interface ITopicStories extends Instance<typeof TopicStories> {}
export default TopicStories
