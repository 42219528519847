import { types, Instance } from 'mobx-state-tree'
import { LoadableStatus } from './helpers/LoadableStatus'
import { Alertable } from './helpers/Alertable'
import { removeNullStringValue } from './utils'
import CsvExportsApi from '../../api/CsvExportsApi'
import { Filterable } from './helpers/Filterable'

import { RootAccessable } from './helpers/RootAccessable'

interface IDownloadArgs {
  csvType?: string
  id?: number
}

const DownloadParams = types.model('DownloadParams', {
  conversationId: types.maybe(types.number),
  surveyQuestionId: types.maybe(types.number),
  membershipId: types.maybe(types.number),
  selectedMembershipId: types.maybe(types.number),
  topicId: types.maybe(types.union(types.string, types.number))
})

const Download = types.model('Download', {
  id: types.identifierNumber,
  createdAt: types.string,
  csvFile: types.maybeNull(types.string),
  csvType: types.maybeNull(types.string),
  conversationSlug: types.maybeNull(types.string),
  parameters: types.maybeNull(DownloadParams)
  // surveyQuestion: null
})

export const Downloads = types
  .compose(
    'Downloads',
    LoadableStatus,
    Filterable,
    Alertable,
    RootAccessable,
    types.model({
      list: types.optional(types.array(Download), []),
      polling: types.optional(types.boolean, false),
      pollingCount: types.optional(types.number, 0),
      downloadAfterCreate: types.optional(types.array(types.number), [])
    })
  )

  .actions((self) => ({
    setPolling: (val: boolean) => {
      self.polling = val
    },

    removeFromDownloadAfterCreate: (id) => {
      if (self.downloadAfterCreate.includes(id)) {
        self.downloadAfterCreate.remove(id)
      }
    }
  }))
  .actions((self) => ({
    startPolling: () => {
      if (self.polling && self.pollingCount > 60) {
        //  Max polling count reached
        self.pollingCount = 0
        self.polling = false
        return
      }

      if (self.polling) {
        self.pollingCount += 1
      } else {
        self.pollingCount = 0
        self.polling = true
        setTimeout(() => {
          self.setPolling(false)
          self.loadFilter()
        }, 5000)
      }
    },
    stopPolling: () => {
      self.polling = false
      self.pollingCount = 0
    }
  }))
  .actions((self) => ({
    addToDownloadAfterCreate: (id) => {
      if (!self.downloadAfterCreate.includes(id)) {
        self.downloadAfterCreate.push(id)
        self.startPolling()
      }
    },
    checkPolling: () => {
      self.list.find((d) => !d.csvFile) ? self.startPolling() : self.stopPolling()
    },
    checkForDownload: () => {
      const downloadList = self.list.filter((d) => d.csvFile && self.downloadAfterCreate.includes(d.id))
      if (downloadList.length) {
        downloadList.map((d) => {
          if (d.csvFile) {
            window.location.href = d.csvFile.replace(/dl=0/gi, 'dl=1')
            self.showAlert('Exported')
          }
          self.removeFromDownloadAfterCreate(d.id)
        })
      }
    }
  }))
  .actions((self) => ({
    addList(data: any) {
      removeNullStringValue(data)
      self.list.push(data)
    },
    setList(data: any[]) {
      data.forEach((user) => removeNullStringValue(user))
      try {
        self.list.replace(data)
        self.checkForDownload()
        self.checkPolling()
      } catch (error) {
        console.error({ error })
      }
    }
  }))
  .actions((self) => ({
    loadList: () => {
      const listName = 'my_downloads'
      if (self.isLoading(listName)) {
        return
      }
      self.startLoading(listName)
      return CsvExportsApi.getAll().then(({ response: { ok, statusText }, json }) => {
        if (ok) {
          self.setList(json)
        } else {
          self.showAlert(statusText)
        }
        self.stopLoading(listName)
      })
    },
    createCsvExport(
      csvType: 'user' | 'conversation' | 'guest' | 'survey_question' | 'nps_answer' | 'membership_user' | 'topic_subscribe',
      parameters: { conversationId?: number; surveyQuestionId?: number; membershipId?: number; topic_id?: number } = {},
      { download = false } = {}
    ) {
      const { user } = self.getAuth()
      user?.trackOnChurnzero() && window.churnZero.track('Count CSV Export', csvType)

      return CsvExportsApi.create({ csvType, parameters })
        .then(({ response: { ok }, json: { success, id } }) => {
          if (ok) {
            if (success) {
              self.showAlert('Exporting...')
              if (download) {
                self.addToDownloadAfterCreate(id)
              }
            }
          }
        })
        .catch((error) => {
          throw error
        })
    }
  }))
  .actions((self) => ({
    loadFilter: () => {
      return self.loadList()
    }
  }))

export interface IDownload extends Instance<typeof Download> {}
export interface IDownloads extends Instance<typeof Downloads> {}
export default Downloads
