import React from 'react'
import Button from '@material-ui/core/Button'
import FontIcon from '@material-ui/core/Icon'

import * as validations from '../validations/fieldLevelValidations.js'
import withTranslation, { WithTranslation } from '../hocs/withTranslation'
import Dialog from '../DialogWrapper'
import { flow } from 'lodash'
import { observer } from 'mobx-react'
import { IDialog } from '../../store/models/Dialog'
import { useForm } from 'react-hook-form'
import { TextFieldControl } from '../utils'
import { Grid } from '@material-ui/core'
import palette from '../../themes/palette'
// import { DialogTitle } from '@material-ui/core'

interface Props {
  dialog: IDialog
}

const EmailGuestsDialog = ({ dialog, t }: Props & WithTranslation) => {
  const { conversation } = dialog.data as any
  const { handleSubmit, errors, formState, control, watch } = useForm({ mode: 'onChange', shouldUnregister: false })

  const { isDirty, isSubmitting } = formState

  const handleSave = handleSubmit(({ message }: any, e) => {
    conversation.messageGuests(message).then(() => dialog.closeDialog())
  })

  return (
    <Dialog dialog={dialog} name='emailGuests' type='noPadding' title={t('conversation.emailGuests')}>
      {/* <DialogTitle disableTypography>{t('conversation.emailGuests')}</DialogTitle> */}

      <section className='pane thin-reg-pane minimal-grey-pane'>
        <div className='row reg-bottom'>
          <div className='col-xs-12 center-xs'>
            <div className='box'>
              <FontIcon style={{ color: palette.blue500 }} className='far fa-paper-plane header-36' />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12 center-xs'>
            <h1 className='flat header-24'>Send Guests a Message by Email</h1>
          </div>
        </div>
      </section>

      <section className='pane big-top-padding big-bottom-padding'>
        <form onSubmit={handleSave}>
          <Grid container style={{ padding: '0 2rem' }} spacing={2}>
            <Grid item xs={12}>
              {/* <div className='col-xs-12 align-left'> */}
              <TextFieldControl
                name='message'
                color='primary'
                variant='outlined'
                control={control}
                defaultValue={conversation && conversation.message?.toString()}
                error={errors && errors.message}
                label={t('conversation.emailGuests')}
                multiline={true}
                rules={{ validate: { required: validations.required } }}
                rows={3}
              />
              {/* </div> */}
            </Grid>

            <Grid item xs={12}>
              {/* <div className='col-xs-12'> */}
              <Button
                disabled={isSubmitting || !watch('message')}
                fullWidth
                size='large'
                type='submit'
                // className='reg-bottom flat-top btn-xl btn-block lowercase capitalize'
                color='primary'
                variant='contained'
              >
                Send Message
              </Button>
              {/* </div> */}
            </Grid>
          </Grid>
        </form>
      </section>
    </Dialog>
  )
}

export default flow(withTranslation, observer)(EmailGuestsDialog)
