import React from 'react'
import { Grid, Box } from '@material-ui/core'
import classes from '../../../../stylesheets/admin/StatsColumn.module.scss'
import { humanize } from '../../../../decorators/textTools'

interface Props {
  inLineStats: any
}
const STAT_MAPPING = {
  approval_only: 'Invite Only',
  open_invitation: 'Open Invite'
}
const StatsColumn = ({ inLineStats }: Props) => {
  return (
    <Grid item xs={12} sm={4} className='header-12 grey-text flat-bottom-padding'>
      {inLineStats.map((stat) => {
        const { key, count } = stat
        return (
          <Grid key={key} container spacing={2} className={classes.statsColumnItem}>
            <Grid item xs={7} className='lightest-grey-bottom-border'>
              <Box fontWeight={500}>{STAT_MAPPING[key] || humanize(key)}</Box>
            </Grid>
            <Grid item xs={5} sm={4} className='align-right lightest-grey-bottom-border'>
              <Box fontWeight={600}>{count}</Box>
            </Grid>
          </Grid>
        )
      })}
    </Grid>
  )
}

export default StatsColumn
