import React, { useContext, useEffect } from 'react'
import { observer } from 'mobx-react'
import { flow } from 'lodash'
import { IPartner, IStore, ITopic } from '../../store'
import { inject } from 'mobx-react'
import { RouteComponentProps, withRouter } from 'react-router'
import { useAppLayout } from './AppLayoutProvider'

const ParamTopicProvider = ({ children, topic, topicLoading }: Props & InjectedProps & RouteComponentProps) => {
  const { changeLogo, logoUrl } = useAppLayout()
  useEffect(() => {
    if (topic && topic.privateLabel && topic?.logoImage?.url && topic?.logoImage?.url !== logoUrl) {
      changeLogo(topic.logoImage.url, `/${topic?.partnerSlug}`)
    }
  }, [changeLogo, topic, logoUrl])
  return <ParamTopicContext.Provider value={{ topic, topicLoading }}>{children}</ParamTopicContext.Provider>
}

interface Props {
  children?: React.ReactNode | React.ReactNode[]
}

interface InjectedProps {
  topic?: ITopic
  partner?: IPartner
  topicLoading: boolean
}

export const ParamTopicContext = React.createContext<InjectedProps>({
  topicLoading: false
})

export const useParamTopic = () => useContext(ParamTopicContext)

export default flow(
  observer,
  inject<InjectedProps, Props & RouteComponentProps<{ slug: string }>>(
    (
      {
        mst: { topics, partners, tracks }
      }: {
        mst: IStore
      },
      { match }
    ) => {
      const slug = match?.params?.slug.toLowerCase()
      const topic = slug ? topics.list.find((d) => d.slug === slug) : undefined
      !topic && topics.shouldLoad(slug, () => topics.loadBySlug(slug))
      topic && topic?.partnerSlug && partners.shouldLoad(topic.partnerSlug, () => partners.loadBySlug(topic.partnerSlug!))
      const partner = topic ? partners.list.find((d) => d.slug === topic.partnerSlug) : undefined
      const track = topic ? tracks.list.find((d) => d.slug === topic.partnerSlug) : undefined
      topic && topic?.partnerId && !track && tracks.shouldLoad(topic.partnerId, () => tracks.loadByPartnerId(topic.partnerId!))
      // console.log({ track })

      return {
        topic,
        topicLoading: topics.loadingIds.includes(slug),
        partner
      }
    }
  ),
  withRouter
)(ParamTopicProvider)
