import React from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import { CocoTheme, DefaultTheme } from './'
import { AdminTheme } from './AdminThemeV2'

export const DefaultThemeProvider = ({ children = <></> }: { children: any }) => (
  <ThemeProvider theme={DefaultTheme}>{window.coco ? <ThemeProvider theme={CocoTheme}>{children}</ThemeProvider> : children}</ThemeProvider>
)

export const AdminThemeProvider = ({ children = <></> }: { children: any }) => (
  <ThemeProvider theme={AdminTheme}>{window.coco ? <ThemeProvider theme={CocoTheme}>{children}</ThemeProvider> : children}</ThemeProvider>
)
