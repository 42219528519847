import { types, Instance } from 'mobx-state-tree'
import { Alertable } from '../helpers/Alertable'
import { Dialogable } from '../helpers/Dialogable'
import Editable from '../helpers/Editable'
import { LoadableStatus } from '../helpers/LoadableStatus'

export const TrackBase = types.compose(
  'TrackBase',
  LoadableStatus,
  Dialogable,
  Alertable,
  Editable,
  types.model({
    id: types.identifierNumber,
    name: types.string
    // slug: types.string
  })
)

export interface ITrackBase extends Instance<typeof TrackBase> {}
export default TrackBase
