import React, { Component } from 'react'
import { DialogTitle } from '@material-ui/core'
import { connect } from 'react-redux'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import Button from '@material-ui/core/Button'
import DOMPurify from 'dompurify'

import * as validations from '../validations/fieldLevelValidations'
import { renderCheckbox } from '../validations/fieldRenderers'
import { getCurrentUserId } from '../../getters/currentUserGetters'
import { confirmPartnerConsent } from '../../actions/partnerConsentActions'
import withTranslation from '../hocs/withTranslation'
import Dialog from '../DialogWrapper'
import { inject, observer } from 'mobx-react'

class PartnerConsentDialog extends Component {
  handleSubmit = (values) => {
    // const { currentUserId, nextAction, confirmPartnerConsent, mst_conversation, dialog, closeDialog, name } = this.props
    // return confirmPartnerConsent(currentUserId, nextAction)
    this.props.mst_conversation.setPartnerConcent(true)
  }

  render() {
    const { partnerConsentTitle, partnerConsentBody, handleSubmit, pristine, submitting, consented, t, dialog } = this.props

    return (
      <Dialog dialog={dialog} name='partnerConsent' type='thin'>
        <div>
          <DialogTitle disableTypography>{partnerConsentTitle}</DialogTitle>

          <section className='pane minimal-grey-pane big-top-padding big-bottom-padding'>
            <form onSubmit={handleSubmit(this.handleSubmit)}>
              <div className='row reg-bottom'>
                <div className='col-xs-12'>
                  <Field
                    name='consent'
                    color='secondary'
                    label={
                      <div
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(partnerConsentBody)
                        }}
                      />
                    }
                    component={renderCheckbox}
                    validate={[validations.required]}
                  />
                </div>
              </div>

              <div className='row center-xs fat-top'>
                <div className='col-xs-12'>
                  <Button
                    label={t('common.next')}
                    type='submit'
                    className='reg-bottom flat-top btn-xl btn-block lowercase capitalize'
                    color='primary'
                    disabled={pristine || submitting || !consented}
                    variant='contained'
                  >
                    {t('common.next')}
                  </Button>
                </div>
              </div>
            </form>
          </section>
        </div>
      </Dialog>
    )
  }
}

const formName = 'PartnerConsentDialog'
const selector = formValueSelector(formName)
const FormPartnerConsentDialog = reduxForm({ form: formName })(observer(PartnerConsentDialog))

function mapStateToProps(state, ownProps) {
  const { partnerConsentTitle, partnerConsentBody, nextAction } = ownProps.dialog?.data || {}
  const currentUserId = getCurrentUserId(state)
  const consented = !!selector(state, 'consent')

  return {
    partnerConsentTitle,
    partnerConsentBody,
    currentUserId,
    nextAction,
    consented
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    confirmPartnerConsent: (userId, nextAction) => dispatch(confirmPartnerConsent(userId, nextAction))
  }
}

const ReduxedPage = withTranslation(connect(mapStateToProps, mapDispatchToProps)(FormPartnerConsentDialog))

export default inject(
  (
    {
      mst: {
        conversations,
        dialogs: { showDialog, closeDialog }
      }
    },
    {
      name,
      dialog: {
        data: { conversationId: conversationIdString }
      }
    }
  ) => {
    const conversationId = parseInt(conversationIdString)
    const mst_conversation = conversations.list.find((d) => d.id === conversationId)
    if (!mst_conversation && !conversations.loadingIds.includes(conversationId)) {
      conversations.getConversation(conversationId)
    }
    return {
      mst_conversation,
      closeDialog: () => closeDialog(name)
    }
  }
)(observer((props) => <ReduxedPage {...props} />))
