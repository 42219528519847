import * as types from '../actions/actionTypes'
import { assign } from 'lodash'

const initialState = {
  createMessage: null,
  useMessage: null
}

export const passwordResets = (state = initialState, action) => {
  switch (action.type) {
    case types.PASSWORD_RESETS_CREATE_SUCCESS:
      return assign({}, state, {
        createMessage: action.message
      })

    case types.PASSWORD_RESETS_USE_SUCCESS:
      return assign({}, state, {
        useMessage: action.message
      })

    default:
      return state
  }
}

export default passwordResets
