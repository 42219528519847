import React from 'react'
import { Card, CardContent, MenuList, Typography } from '@material-ui/core'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import { createStyles, Theme, makeStyles, withStyles } from '@material-ui/core/styles'
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt'
import ChatIcon from '@material-ui/icons/Chat'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import PeopleIcon from '@material-ui/icons/People'
import ShowChartIcon from '@material-ui/icons/ShowChart'
import MuiMenuItem from '@material-ui/core/MenuItem'

import palette from '../../../../themes/palette'
import { withRouter } from 'react-router'
import { flow } from 'lodash'
import { connectAdminThemeV2 } from '../../../../connectTheme'
import { inject, observer } from 'mobx-react'
import { IStore } from '../../../../store/models'
import { IUser } from '../../../../store/models/User'
import Icon from '@material-ui/core/Icon'
interface Props {
  user?: IUser
  history: any
  active?: string
  showActive?: boolean
  match?: { params?: { page?: string } }
  onClose?()
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerContainer: {
      overflow: 'auto',
      paddingTop: theme.spacing(2)
    },
    menuListItem: {
      paddingTop: theme.spacing(1.7),
      paddingBottom: theme.spacing(1.7)
    },
    helpBox: {
      margin: theme.spacing(2),
      marginTop: 'auto',
      backgroundColor: palette.nothinGrey
    }
  })
)

const MenuItem = withStyles({
  root: {
    '&$selected': {
      color: palette.black,
      backgroundColor: palette.lightYellow
    },
    '&$selected:hover': {
      color: palette.black,
      backgroundColor: palette.lightYellow
    }
  },
  selected: {}
})(MuiMenuItem)

const AdminMenu = ({ user, history, active, onClose, match, showActive }: Props) => {
  const classes = useStyles()

  const HelpBox = () => (
    <>
      <Card className={classes.helpBox} elevation={0}>
        <CardContent>
          <Typography variant='subtitle2' component='p' color='textPrimary'>
            Need help?
          </Typography>
          <Typography
            variant='caption'
            component='a'
            onClick={() => {
              window.open('https://help.inclusivv.co/en/collections/2743414-civic-conversations-customer-resources')
            }}
            color='textSecondary'
          >
            Check our documentation <i className='far fa-external-link'></i>
          </Typography>
        </CardContent>
      </Card>
    </>
  )

  return (
    <>
      <div className={classes.drawerContainer}>
        <MenuList>
          {[
            { label: 'Dashboard', name: undefined, url: '/admin', MenuIcon: ViewQuiltIcon },
            { label: 'Topics', name: 'topics', MenuIcon: ChatIcon, id: 'ic-admin-nav-topics' },
            { label: 'Conversations', name: 'conversations', MenuIcon: CalendarTodayIcon, id: 'ic-admin-nav-conversations' },
            { label: 'Guests/Hosts', name: 'attendees', MenuIcon: PeopleIcon, id: 'ic-admin-nav-attendees' },
            ...(user?.isPartnerAdmin() ? [{ label: 'Stories', name: 'stories', MenuIcon: StoriesIcon }] : []),
            { label: 'Analytics', name: 'analytics', MenuIcon: ShowChartIcon, id: 'ic-admin-nav-analytics' },
            ...(user?.isPartnerAdmin() ? [{ label: 'Feedback', name: 'nps_answers', MenuIcon: NpsAnswersIcon }] : []),
            ...(user?.isSuperAdmin() ? [{ label: 'Customers', name: 'settings', MenuIcon: CustomersIcon }] : [])
          ].map(({ label, name, url, MenuIcon, id }) => (
            <MenuItem
              key={label}
              {...(showActive !== false ? { selected: active === name || name === match?.params?.page } : {})}
              className={classes.menuListItem}
              id={id}
              onClick={() => {
                history.push(url !== undefined ? url : `/admin/${name}`)
                onClose && onClose()
              }}
            >
              <ListItemIcon>
                <MenuIcon />
              </ListItemIcon>
              <Typography variant='inherit' noWrap>
                {label}
              </Typography>
            </MenuItem>
          ))}
        </MenuList>
      </div>

      <HelpBox />
    </>
  )
}

const StoriesIcon = (props) => {
  return <Icon className='far fa-book-spells' />
}

const NpsAnswersIcon = (props) => {
  return <Icon className='fal fa-poll-people' />
}

export const CustomersIcon = (props) => {
  return <Icon className='fal fa-folder' />
}
export default flow(
  observer,
  inject<FIXME, FIXME>(
    ({
      mst: {
        auth: { user }
      }
    }: {
      mst: IStore
    }) => {
      return {
        user
      }
    }
  ),
  connectAdminThemeV2,
  withRouter
)(AdminMenu)
