import React from 'react'
import Button from '@material-ui/core/Button'
import { useForm } from 'react-hook-form'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import * as validations from '../../validations/fieldLevelValidations'
import withTranslation from '../../hocs/withTranslation'
import { DialogActions } from '@material-ui/core'
import { TextFieldControl, RadioGroupControl } from '../../utils'
import { AnswerOption } from '../../../store'
type Props = {
  question: any
  t?: any
  handleNext?: any
  handleBack?: any
  onCancel?: any
}
const RsvpQuestion = ({ question, t, handleNext, handleBack, onCancel }: Props) => {
  const { control, handleSubmit, setValue } = useForm()
  const submitForm = (values) => {
    handleNext(question.questionType === 'select' ? question.options[values.answer] : values)
    setValue('answer', '')
    return true
  }

  const options = question?.options?.map((o: AnswerOption, index) => ({ label: o.answer, value: index }))
  if (question === null) return null

  return (
    <div>
      <div className='row'>
        <div className='col-xs-12 reg-top'>
          <h3 className='flat header-24'>{question.question}</h3>
          <p>{question.description}</p>
        </div>
      </div>

      <form onSubmit={handleSubmit(submitForm)}>
        <section className='pane big-top-padding'>
          <div className='row reg-bottom'>
            <div className='col-xs-12'>
              {question.questionType === 'text' && (
                <TextFieldControl control={control} name='answer' label={t('conversation.yourAnswer')} value={''} validate={[validations.required]} />
              )}
              {question.questionType === 'select' && (
                <div>
                  <RadioGroupControl name='answer' control={control} options={options} />
                </div>
              )}
            </div>
          </div>
          <DialogActions>
            <Button onClick={() => onCancel && onCancel()}>Cancel</Button>
            <Button endIcon={<NavigateNextIcon />} type='submit' className=' lowercase capitalize' variant='contained' color='primary'>
              Next
            </Button>
          </DialogActions>
        </section>
      </form>
    </div>
  )
}

export default withTranslation(RsvpQuestion)
