import React from 'react'
import { Button } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { TextFieldControl } from '../../utils'
import * as validations from '../../validations/fieldLevelValidations'

const ADMIN_LIMIT = 50

const Invite = ({ partner }: FIXME) => {
  const { handleSubmit, errors, formState, control, watch, reset } = useForm({ mode: 'onBlur' })
  const { isDirty, isSubmitting } = formState
  const MaxReached = () => <div>Admin limit reached</div>

  const handleSave = handleSubmit((data: any, e) => {
    partner.inviteAdmin(data.email).then(() => reset())
  })

  const currentEmails = [...partner.admins.map((a) => a.email), ...partner.adminInvitations.map((a) => a.recipientEmail)]
  const adminCount: number = currentEmails.length
  return adminCount !== undefined ? (
    adminCount >= ADMIN_LIMIT ? (
      <MaxReached />
    ) : (
      <form onSubmit={handleSave}>
        <TextFieldControl
          name='email'
          control={control}
          defaultValue={''}
          error={errors && errors.email}
          label={'Invite Admin'}
          rules={{ validate: validations.email }}
          className='reg-bottom'
        />
        <Button disabled={!isDirty || isSubmitting || currentEmails.includes(watch('email'))} type='submit' className='fat-top' color='primary' variant='contained'>
          Invite
        </Button>
      </form>
    )
  ) : (
    <div>Loading...</div>
  )
}

export default Invite
