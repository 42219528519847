import InvitationsApi from '../api/InvitationsApi.js'
import { showDialog } from './dialogActions.js'
import * as types from './actionTypes'

export function createInvitation(values) {
  return function(dispatch) {
    return InvitationsApi.create(values)
      .then(response => {
        if (response.response.ok) {
          dispatch({
            type: types.INVITE_CREATE_SUCCESS
          })

          dispatch(showDialog('invitation', { step: 2 }))
        }
      })
      .catch(error => {
        throw error
      })
  }
}

export function redeemInvitation(data) {
  return function(dispatch) {
    return InvitationsApi.redeem(data)
      .then(response => {
        if (response.response.ok) {
          dispatch({
            type: types.INVITE_REDEEM_SUCCESS
          })
        }
      })
      .catch(error => {
        throw error
      })
  }
}
