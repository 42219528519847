// import { SHOW_DIALOG, CLOSE_DIALOG } from '../actions/actionTypes'
// import { assign } from 'lodash'

export const dialog = (state = {}) => {
  return state
  // // const { type, dialog, data } = action

  // switch (type) {
  //   case SHOW_DIALOG:
  //     // return assign({}, state, {
  //     //   [dialog]: { open: true, data }
  //     // })
  //     return state

  //   case CLOSE_DIALOG:
  //     return state
  //   // if (!dialog) return {}

  //   // return assign({}, state, {
  //   //   [dialog]: { open: false }
  //   // })

  //   default:
  //     return state
  // }
}

export default dialog
