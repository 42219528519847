class Churnzero {
  apiKey: string
  accountExternalId?: number
  contactExternalId?: number

  constructor({ apiKey }) {
    this.apiKey = apiKey
    // eslint-disable-next-line no-console
    window.debugMode && console.log('Churnzero', 'init')
  }

  set({ accountExternalId, contactExternalId }: { accountExternalId: number; contactExternalId: number }) {
    this.accountExternalId = accountExternalId
    this.contactExternalId = contactExternalId

    // eslint-disable-next-line no-console
    window.debugMode && console.log('Churnzero', 'set', { accountExternalId, contactExternalId })
  }

  setAttribute({ entity = 'account', name, value }: { entity: string; name: string; value: any }) {
    window.ChurnZero.push(['setAppKey', this.apiKey])
    window.ChurnZero.push(['setAttribute', entity, name, value])

    // eslint-disable-next-line no-console
    window.debugMode && console.log('Churnzero', 'setAttribute', { entity, name, value })
  }

  track(eventName: string, description?: number | string) {
    const { accountExternalId, contactExternalId, apiKey } = this
    const now = Date.now()

    window.ChurnZero?.push(['setAppKey', apiKey])
    window.ChurnZero?.push(['setContact', accountExternalId, contactExternalId])
    window.ChurnZero?.push(['trackEvent', eventName, description ? description : now])

    // eslint-disable-next-line no-console
    window.debugMode && console.log('Churnzero', 'trackEvent', { eventName, description: description || now, accountExternalId, contactExternalId, apiKey })
  }
}
export default Churnzero
