import React from 'react'
import { CheckboxControl } from '../../utils'
import { StepProps } from './NewStepper'
import withTranslation, { WithTranslation } from '../../hocs/withTranslation'

const RoleOfHostStep = ({ topic, t, control }: StepProps & WithTranslation) => {
  const hostRoleList = window.coco ? t('conversation.cocoOl1', { eventType: topic.eventTypeSingular }) : t('conversation.ol1', { eventType: topic.eventTypeSingular })
  return (
    <>
      <ol dangerouslySetInnerHTML={{ __html: hostRoleList }} />
      <CheckboxControl
        name='hostAgreement'
        control={control}
        label={
          <span
            dangerouslySetInnerHTML={{
              __html: t('conversation.privacyConsentLabel', { linkClass: 'link-text' })
            }}
          />
        }
      />
    </>
  )
}

export default withTranslation(RoleOfHostStep)
