import appAnalytics from '../analytics'
import { post, get } from './utils'

class CsvExportsApi {
  static get(args) {
    let { id, csvType } = args
    id = id ? id : csvType

    return get(`/${csvType}_csv_exports/${id}`)
  }

  static getAll() {
    return get(`/csv_exports`)
  }

  static create({ csvType, parameters }) {
    return post(`/${csvType}_csv_exports`, {
      body: JSON.stringify({
        csv_export: { csvType, parameters, site_name: global.siteName }
      })
    }).then((d) => {
      appAnalytics.event({
        category: 'Export',
        action: `Created ${csvType} csv`
      })
      return d
    })
  }
}

export default CsvExportsApi
