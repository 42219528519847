import fetch from 'isomorphic-fetch'
import { get } from '../utils'
import queryString from 'query-string'

class PartnerStatsApi {
  static requestHeaders() {
    return {
      'Content-Type': 'application/json'
    }
  }

  static getAll(slug) {
    const headers = this.requestHeaders()

    return fetch(`${process.env.REACT_APP_API_HOST}/partners/${slug}/stats`, {
      method: 'GET',
      headers: headers
    })
      .then((response) => response.json().then((json) => ({ json, response })))
      .catch((error) => {
        return error
      })
  }

  static getStatsForPartnerIds(partner_ids) {
    const params = partner_ids && partner_ids.length ? queryString.stringify({ partner_ids }, { arrayFormat: 'bracket' }) : ''
    return get(`/admin/partners_stats?${params}`)
  }
}

export default PartnerStatsApi
