import React, { useEffect, useState } from 'react'
import { Button, Divider, Tab, Tabs } from '@material-ui/core'
import SummaryContainer from '../partials/SummaryContainer'
import ConversationsTable from './ConversationsTable'
import SummaryStats from '../partials/SummaryStats'
import { flow } from 'lodash'
import { inject, observer } from 'mobx-react'
import { IStore } from '../../../../store/models'
import { IUser } from '../../../../store/models/User'
import { withRouter, RouteComponentProps } from 'react-router'
import appAnalytics from '../../../../analytics'

interface Props {
  user?: IUser
  conversations_total: number
  hosts_total: number
  guests_total: number
  uniq_guests_total: number
  upcoming_conversations: number
  past_conversations: number
  loadStats()
  setTimeScope(val: string)
  loadFilter(arg)
  createCsvExport()
  setSortedPriority(val: boolean)
  setSortingId(val: string)
}

const ConversationsSummary = (props: Props & RouteComponentProps) => {
  const { user, loadStats, setTimeScope, loadFilter, createCsvExport, setSortedPriority, setSortingId, upcoming_conversations, past_conversations, history } = props
  const [index, setIndex] = useState(0)
  const [isInit, setIsInit] = useState(false)
  const [exported, setExported] = useState(false)
  const [exporting, setExporting] = useState(false)

  useEffect(() => {
    setTimeScope('upcoming')
    setSortingId('startsAt')
    setSortedPriority(false)
    loadStats()
    setIsInit(true)
    return () => {
      setIsInit(false)
    }
  }, [loadStats, setTimeScope, setSortedPriority, setSortingId])

  return (
    <div>
      <SummaryContainer
        title={'Conversations'}
        id='ic-admin-conversations'
        topRight={
          user && (user.isSuperAdmin() || user.isPartnerAdmin()) ? (
            <div>
              <Button
                variant='contained'
                disabled={exporting}
                startIcon={<i className='fal fa-file-export' />}
                onClick={() => {
                  setExporting(true)
                  createCsvExport().then(() => {
                    setExported(true)
                    setExporting(false)
                  })
                }}
              >
                Export All Conversations CSV
              </Button>
              {exported && (
                <Button
                  className='reg-left'
                  startIcon={<i className='fas fa-cloud-download-alt' />}
                  onClick={() => {
                    history.push('/admin/analytics/downloads')
                  }}
                >
                  My Downloads
                </Button>
              )}
            </div>
          ) : undefined
        }
      >
        <SummaryStats
          data={[
            { title: 'Total Conversations', value: props.conversations_total },
            { title: 'Total Unique Hosts', value: props.hosts_total },
            { title: 'Total Unique Guests', value: props.uniq_guests_total },
            { title: 'Total Guests', value: props.guests_total }
          ]}
        />

        <Tabs
          value={index}
          onChange={(e, val) => {
            setIndex(val)
            setSortingId('startsAt')
            if (val === 1) {
              setTimeScope('past')
              setSortedPriority(true)
            } else {
              setTimeScope('upcoming')
              setSortedPriority(false)
            }
            loadFilter({ reset: true, clear: true })
          }}
          indicatorColor='primary'
          textColor='primary'
          variant='fullWidth'
          centered
        >
          <Tab label={`Upcoming (${upcoming_conversations})`} />
          <Tab label={`Past (${past_conversations})`} />
        </Tabs>

        {isInit && <ConversationsTable index={index} />}
      </SummaryContainer>
    </div>
  )
}

export default flow(
  withRouter,
  observer,
  inject<FIXME, FIXME>(
    ({
      mst: {
        auth: { user },
        partners: { conversations_total, hosts_total, guests_total, uniq_guests_total, loadStats, upcoming_conversations, past_conversations },
        conversations: { setTimeScope, loadFilter, createCsvExport, setSortedPriority, setSortingId }
      }
    }: {
      mst: IStore
    }) => {
      return {
        user,
        loadFilter,
        setTimeScope,
        conversations_total,
        hosts_total,
        guests_total,
        uniq_guests_total,
        loadStats,
        createCsvExport,
        setSortedPriority,
        setSortingId,
        upcoming_conversations,
        past_conversations
      }
    }
  )
)(ConversationsSummary)
