import React from 'react'
import { mapKeys } from 'lodash'

function formatTextArea(description) {
  if (description) {
    const paragraphs = description.split('||')
    return paragraphs.map((para, index) => <p key={index}>{para}</p>)
  }
}

function ellipsis({ text, length = 47, decorator = formatTextArea }) {
  const aWord = '[^\\s]+\\s+'
  const regex = new RegExp(`^\\s*(${aWord}){${length}}`)
  const match = text.match(regex)

  const newText = match ? match[0] + '... ' : text

  if (decorator) return decorator(newText)
  return newText
}

function snakify(object) {
  return mapKeys(object, snakifyKey)
}

function snakifyKey(value, key) {
  return key.replace(/([A-Z])/g, function ($1) {
    return '_' + $1.toLowerCase()
  })
}

function titleize(string) {
  var result = string.replace(/([A-Z])/g, ' $1')
  var finalResult = result.charAt(0).toUpperCase() + result.slice(1)
  return finalResult
}

function titleify(object) {
  return mapKeys(object, (value, key) => titleize(key))
}

const camelize = (str) => str.replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''))

const deSnakify = (str) => str.replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', ' ').replace('_', ' '))

const humanize = (str) => str && titleize(deSnakify(str))

function camelify(object) {
  return mapKeys(object, (value, key) => camelize(key))
}

function capitalize(str) {
  if (!str) return ''
  return str.replace(/(?:^|\s)\S/g, function (a) {
    return a.toUpperCase()
  })
}

function lowercase(str) {
  if (!str) return ''
  return str.replace(/(?:^|\s)\S/g, function (a) {
    return a.toLowerCase()
  })
}

export { formatTextArea, ellipsis, snakify, titleify, titleize, camelify, humanize, capitalize, lowercase }
