import React, { Component } from 'react'
import withTranslation from '../../hocs/withTranslation'

class ProccessDescription extends Component {
  render() {
    const { t } = this.props
    return (
      <div className='row center-xs content-cage'>
        <div className='col-xs-12'>
          <h2 className='xtra-fat-top'>{t('home.host')}</h2>

          <p className='xtra-fat-bottom'>{t('convos.hostRole')}</p>

          <h2>{t('home.numOfGuest')}</h2>

          <p className='xtra-fat-bottom'>{t('convos.guestInvite')}</p>

          <h2>{t('home.questions')}</h2>

          <p className='xtra-fat-bottom'>{t('convos.hostQuestions')}</p>

          <h2>{t('home.equalTime')}</h2>

          <p className='xtra-fat-bottom'>{t('convos.timeDesc')}</p>

          <h2>{t('home.voice')}</h2>

          <p>{t('convos.voiceDesc')}</p>
        </div>
      </div>
    )
  }
}

export default withTranslation(ProccessDescription)
