import { types, Instance } from 'mobx-state-tree'
import PartnerTopicsApi from '../../../api/partners/TopicsApi'
import topicsApi from '../../../api/TopicsApi'
import PartnerBase from './PartnerBase'
import Topic from '../Topic'
import { LoadableStatus } from '../helpers/LoadableStatus'
import { sortBy } from 'lodash'

const TopicOption = types.model('TopicOption', {
  id: types.identifierNumber,
  cardBanner: types.maybeNull(types.string),
  title: types.maybeNull(types.string)
})

export const PartnerTopics = types
  .compose(
    'PartnerTopics',
    PartnerBase,
    LoadableStatus,
    types.model({
      topics: types.optional(types.array(Topic), []),
      topicOptions: types.optional(types.array(TopicOption), []),
      hasMoreTopics: types.optional(types.boolean, true),
      loadTopicQueueArgs: types.array(types.frozen(types.map))
    })
  )
  .actions((self) => ({
    setTopics: (topics: any) => {
      self.topics = topics
    },
    addToTopics: (topics: any) => {
      self.topics.push(topics)
    },
    setTopicOptions: (topics: any) => {
      self.topicOptions = topics
    },
    setHasMoreTopics: (val: boolean) => {
      self.hasMoreTopics = val
    }
  }))
  .actions((self) => ({
    loadTopics: (args: any = { page: 0 }, { add = false }: { add?: boolean } = {}) => {
      if (self.isLoading('topics')) {
        return
      }

      self.startLoading('topics')
      return PartnerTopicsApi.getAll(self.slug, args)
        .then(({ response: { ok }, json }) => {
          self.stopLoading('topics')
          if (ok) {
            if (json.length === 0) {
              if (add) {
                self.setHasMoreTopics(false)
              } else {
                self.setTopics(json)
              }
            } else {
              if (add) {
                self.setTopics([...self.topics, ...json])
              } else {
                self.setTopics(json)
              }
            }
          }
        })
        .catch(() => {
          self.stopLoading('topics')
        })
    },
    loadTopicOptions: (args: any, { add = false }: { add?: boolean } = {}) => {
      const listName = 'topicOptions'
      if (self.isLoading(listName)) {
        return
      }

      self.startLoading(listName)
      return topicsApi
        .getAll({ scope: 'unfinished', small: true })
        .then(({ response: { ok }, json }) => {
          self.stopLoading(listName)
          if (ok) {
            self.setTopicOptions(sortBy(json, ['title']))
          }
        })
        .catch(() => {
          self.stopLoading(listName)
        })
    }
  }))
export interface IPartnerTopics extends Instance<typeof PartnerTopics> {}
export default PartnerTopics
