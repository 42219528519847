import React from 'react'

import { flow } from 'lodash'
import InfiniteLocationList from '../../shared/InfiniteLocationListMst'
import { observer } from 'mobx-react'
import { IConversation } from '../../../store/models/index'
import { useTranslation } from 'react-i18next'
import { usePartnerTracks } from '../../Providers/PartnerTracksProvider'
import moment from 'moment-timezone'
import { useParamPartner } from '../../Providers'

const MembershipConversations = ({ variant = 'upcoming' }) => {
  const { tracks } = usePartnerTracks()
  const { partner } = useParamPartner()
  const { t } = useTranslation()
  if (tracks.length === 0) return <></>
  let conversations: IConversation[] = []
  tracks.forEach((n) => {
    conversations = [...conversations, ...n.conversations].filter((conv) => {
      if (moment(conv.startsAt).isBefore(moment())) {
        return false
      }
      if (variant === 'topicFeatured' && conv.topicFeatured) {
        return true
      }
      if (variant === 'upcoming' && !conv.topicFeatured) {
        return true
      }
      return false
    })
  })

  const upcomingConversationHeader = partner?.privateLabel ? partner?.upcomingConversationHeader : t('user.upcomingConversations')
  const featuredConversationHeader = partner?.privateLabel ? partner?.featuredConversationHeader : partner?.featuredConversationsTitle || 'Featured Conversations'
  const header = variant === 'upcoming' ? upcomingConversationHeader : featuredConversationHeader

  return (
    <InfiniteLocationList
      limit={50}
      initQuery={(args) => {
        // loadTracks()
      }}
      query={(args) => {
        // if (partner.hasMoreConversations) {
        //   partner.loadConversations({ ...(partner.featuredConversations ? { partner_featured: false } : {}), ...args }, { add: true })
        // }
      }}
      slug={'tracks'}
      formName={'ConversationForm'}
      itemType='conversation'
      header={header}
      itemLabel={t('common.conversation')}
      items={conversations}
      hasMoreItems={false}
      itemsLoading={false}
    />
  )
}

export default flow(observer)(MembershipConversations)
