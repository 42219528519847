import { get, put } from '../utils'

class pendingRequestedGuestsApi {
  static getAll(id) {
    return get(`/conversations/${id}/pending_requested_guests`)
  }

  static update(data) {
    return put(`/conversations/${data.conversationId}/pending_requested_guests/${data.id}`, {
      body: JSON.stringify(data)
    })
  }

  static update_all(data) {
    return put(`/conversations/${data.conversationId}/pending_requested_guests/update_all`, {
      body: JSON.stringify(data)
    })
  }
}

export default pendingRequestedGuestsApi
