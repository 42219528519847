import React, { useContext, useState } from 'react'
import FontIcon from '@material-ui/core/Icon'
import Button from '@material-ui/core/Button'
// import { DialogTitle } from '@material-ui/core'
import * as validations from '../../validations/fieldLevelValidations'
import withTranslation, { WithTranslation } from '../../hocs/withTranslation'
import { inject, observer } from 'mobx-react'
import { IConversation, IStore, ITopic, IUser } from '../../../store/models/index'
import { useForm } from 'react-hook-form'
import { CheckboxControl, TextFieldControl } from '../../utils/index'
import { IDialog } from '../../../store/models/Dialog'
import InviteSent from './InviteSent'
import { DialogContext } from '../../Providers/DialogProvider'
import { DialogTitle } from '../../DialogWrapper'
import { Typography } from '@material-ui/core'
import { palette } from '../../../themes'
import { useParamTopic } from '../../Providers'

interface Props {
  user: IUser
  conversation: IConversation
  dialog: IDialog
  reinvite?: boolean
}

interface InjectedProps {
  topic?: ITopic
}

const EmailInvite = ({ user, conversation, t, dialog, reinvite = false, topic }: Props & InjectedProps & WithTranslation) => {
  const { handleSubmit, errors, formState, control, setValue, watch } = useForm()
  const { isDirty, isSubmitting } = formState
  const { showDialog } = useContext(DialogContext)
  // const [inviteMembers, setInviteMembers] = useState(false)

  if (!conversation) {
    return null
  }

  const reinviteHandler = (message): Promise<any> => {
    const emails = (dialog.data as any).emails.split(',')
    if (emails.length === 1) {
      return conversation.pendingInvite.find((i) => i.recipientEmail === emails[0])?.resendInvite({ showDialog: false, message }) || Promise.resolve()
    } else {
      return conversation.resendAllPendingInvite({ showDialog: false, message })
    }
  }

  const handleSave = handleSubmit(({ emails, message, inviteAllMembers }: any, e) => {
    const formData = emails
      .split(',')
      .map((email: string) => ({ message, recipientEmail: email.replace(/\s/g, ''), conversationId: conversation.id, senderId: user.id, inviteAllMembers }))
    ;(reinvite
      ? reinviteHandler(message)
      : Promise.all(
          formData.map((emailData) =>
            conversation.createConversationInvitation(emailData).then((resp?: any) => {
              const { errors: errs } = resp || {}
              if (errs && errs?.recipient_email) {
                conversation.showAlert(errs?.recipient_email.join(', '))
              }
            })
          )
        )
    )
      .then(conversation.loadPendingInvite)
      .then(() => {
        dialog.closeDialog()
        showDialog('emailInviteSent', { conversationId: conversation.id })
      })
  })

  let header = conversation.isHost ? t('conversation.inviteGuests') : t('conversation.inviteFriends')
  let subheader = conversation.isHost ? t('conversation.inviteByEmail') : t('invitation.inviteByEmail')
  const emails = reinvite && (dialog.data as any).emails
  if (conversation.isHost && reinvite) {
    header = t('conversation.reinviteGuests')
    subheader = t('conversation.reinviteByEmail')
  }

  const draftText = () => {
    const {
      isHost,
      isVirtual,
      title,
      dateLocally,
      startsAtLocally,
      endsAtLocally,
      timeZoneAbbr,
      virtualEventTypeSingular,
      brandSingular,
      venueName,
      neighborhood,
      city,
      stateLevel
    } = conversation
    const hostingOrAttending = isHost ? t('conversation.hosting') : t('conversation.attending')
    if (isVirtual) {
      return t('conversation.draftVirtual', {
        hostingOrAttending,
        title,
        virtualEvent: virtualEventTypeSingular,
        dateLocally,
        startsAtLocally,
        endsAtLocally,
        timeZoneAbbr
      })
    } else {
      return t('conversation.draftText', {
        hostingOrAttending,
        title,
        brandSingular,
        dateLocally,
        startsAtLocally,
        endsAtLocally,
        timeZoneAbbr,
        venueName,
        neighborhood,
        city,
        stateLevel
      })
    }
  }

  return (
    <div>
      <DialogTitle onClose={() => dialog.closeDialog()}>{header}</DialogTitle>

      <section className='pane thin-reg-pane'>
        <div className='row reg-bottom'>
          <div className='col-xs-12 center-xs'>
            <div className='box'>
              <FontIcon style={{ fontSize: '3rem' }} className='fal fa-envelope-open-text' />
            </div>
          </div>
        </div>
        <Typography variant='h6' align='center'>
          {subheader}
        </Typography>
      </section>

      <section className='pane minimal-grey-pane big-top-padding big-bottom-padding'>
        <form onSubmit={handleSave}>
          <div className='row xtra-fat-bottom'>
            <div className='col-xs-12' title={emails ? emails : undefined}>
              <TextFieldControl
                rules={watch('inviteAllMembers') ? {} : { validate: { required: validations.required, emails: validations.emails } }}
                label={t('conversation.to')}
                helperText={t('conversation.emailHint')}
                name='emails'
                variant='outlined'
                disabled={!!emails || watch('inviteAllMembers')}
                defaultValue={emails || undefined}
                control={control}
                error={errors && errors.emails}
                multiline
              />
              {topic?.journeyTopic && user.isPartnerAdmin() && (
                <div>
                  <CheckboxControl name='inviteAllMembers' label={'Invite All Active Journey Members'} control={control} error={errors && errors.inviteAllMembers} />
                </div>
              )}
            </div>
          </div>

          <div className='row xtra-fat-bottom'>
            <div className='col-xs-12 align-left'>
              <TextFieldControl
                rules={{ validate: validations.required }}
                name='message'
                variant='outlined'
                multiline={true}
                rows={4}
                label={t('conversation.messageLabel')}
                control={control}
                error={errors && errors.message}
                defaultValue={draftText()}
                helperText={"We'll send this message to your guests."}
              />
              {/* <div className='label'>{t('conversation.insertMessage')}</div> */}
            </div>
          </div>

          <div className='row center-xs'>
            <div className='col-xs-12'>
              <Button
                type='submit'
                className='xtra-fat-bottom flat-top btn-xl btn-block lowercase capitalize'
                disabled={(!isDirty && !reinvite) || isSubmitting}
                variant='contained'
                color='primary'
              >
                Send Invitation
              </Button>
            </div>
          </div>
        </form>
      </section>
    </div>
  )
}

export default inject<FIXME, FIXME>(
  (
    {
      mst: {
        auth: { user },
        conversations,
        topics
      }
    }: {
      mst: IStore
    },
    ownProps
  ) => {
    const conversationId = ownProps.dialog.data.conversationId
    const conversation = conversations.list.find((d) => d.id === conversationId)
    if (!conversation && !conversations.loadingIds.includes(conversationId)) {
      conversations.getConversation(conversationId)
    }
    return {
      user,
      conversation,
      topic: topics.list.find((t) => t.id === conversation?.topicId)
    }
  }
)(observer(withTranslation(EmailInvite)))
