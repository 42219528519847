import React from 'react'
import { reduxForm } from 'redux-form'
import { flow } from 'lodash'

import LocationFilter from './LocationFilter'
import VenueTypeFilter from './VenueTypeFilter'
import withTranslation from '../hocs/withTranslation'

export const formName = 'ConversationFilters'

const ConversationFilters = (props) => {
  const blockSubmit = (ev) => ev.preventDefault()

  return (
    <form onSubmit={blockSubmit} className='row center-xs' style={{ marginTop: '1rem' }}>
      <div className='col-xs-12 col-sm-6 align-center-xs align-right-sm reg-bottom-xs'>
        <div className='col-xs-12 col-sm-8 no-gutters-xs no-right-gutter col-sm-offset-4'>
          <div className='col-xs-12 col-sm-11 col-sm-offset-1 no-right-gutter no-gutters-xs'>
            <LocationFilter updateForm={props.updateForm} />
          </div>
        </div>
      </div>
      <div className='col-xs-12 col-sm-6'>
        <div className='row no-gutters'>
          <div className='col-sm-1 align-right no-gutters xs-hide sm-show thin-top'>
            <p>{props.t('conversation.filterLabel')}</p>
          </div>

          <div className='col-xs-12 col-sm-8 no-gutters-xs'>
            <div className='col-xs-12 col-sm-11 no-left-gutter no-gutters-xs'>
              <VenueTypeFilter updateForm={props.updateForm} />
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export default flow(reduxForm({ form: formName }))(withTranslation(ConversationFilters))
