export default {
  conversations: {
    loadingConversation: false,
    loadingConversations: false,
    loadingTopicConversations: false,
    loadingPartnerConversations: false,
    errorConversation: null,
    errorConversations: null,
    errorTopicConversations: null,
    errorPartnerConversations: null,
    list: {}
  },
  hostedConversations: {
    loading: false,
    error: null,
    list: {}
  },
  users: {
    loadingUser: false,
    loadingUsers: false,
    errorUser: null,
    errorUsers: null,
    list: {}
  },
  guests: {
    loading: false,
    error: null,
    list: {}
  },
  pendingRequestedGuests: {
    loading: false,
    error: null,
    list: {}
  },
  pendingInvitedGuests: {
    loading: false,
    error: null,
    list: {}
  },
  userConversations: {
    loading: false,
    error: null,
    list: {}
  },
  topics: {
    loadingTopics: false,
    loadingTopic: false,
    error: null,
    list: {},
    rankedList: {},
    loadingRankedTopics: false,
    loadingPartnerTopics: false,
    errorPartnerTopics: null
  },
  topicOptions: {
    loaded: false,
    loading: false,
    error: null,
    list: {}
  },
  topicNotifications: {
    loading: false,
    error: null,
    list: {}
  },
  conversationNotifications: {
    loading: false,
    error: null,
    list: {}
  },
  partners: {
    loading: false,
    error: null,
    list: {}
  },
  partnerStats: {
    loading: false,
    error: null,
    list: {}
  },
  sections: {
    loading: false,
    error: null,
    list: {}
  },
  sponsors: {
    loading: false,
    error: null,
    list: {}
  },
  topicsPartners: {
    loading: false,
    error: null,
    list: {}
  },
  currentUser: null,
  dialog: {},
  form: {},
  tabs: {
    position: 0
  },
  router: {
    locationBeforeTransitions: null
  },
  session: {
    active: !!localStorage.jwt,
    error: null
  },
  userCoords: {
    loading: false,
    error: null,
    item: ''
  },
  alertMessage: {
    open: false,
    message: ''
  },
  csvExports: {
    loading: false,
    error: null,
    list: {}
  },
  conversationInvitationRequests: {
    loading: false,
    error: false,
    list: {}
  },
  conversationInvitations: {
    list: {}
  },
  conversationsTable: {
    loadingConversationsTable: false,
    errorConversationsTable: null,
    tableData: {}
  },
  topicsTable: {
    loadingTopicsTable: false,
    errorTopicsTable: null,
    tableData: {}
  },
  usersTable: {
    loadingUsersTable: false,
    errorUsersTable: null,
    tableData: {}
  },
  surveyQuestions: {
    loading: false,
    error: null,
    list: {}
  },
  actions: {
    loading: false,
    error: null,
    list: {}
  },
  userActions: {
    loading: false,
    error: null,
    list: {}
  }
}
