import React, { useState } from 'react'
import { Controller } from 'react-hook-form'
import {
  Link,
  Switch,
  TextField,
  Radio,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Checkbox,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Chip,
  makeStyles
} from '@material-ui/core'
import Datetime from 'react-datetime'
import AvatarUploader from '../AvatarUploader'
import { RegionMap } from '../../RegionMap'
import { chunk, identity, pick, pickBy, truncate } from 'lodash'
import FacebookShareUploader from '../../FacebookShareUploader'
import 'react-datetime/css/react-datetime.css'
import classnames from 'classnames'
import { defaultFontFamily } from '../../../themes'
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker, DatePicker, TimePicker } from '@material-ui/pickers'
import moment from 'moment-timezone'

export const getDirtyFieldData = (rest, dirtyFields) => ({ ...pick(rest, Object.keys(pickBy(dirtyFields, identity))) })

export const TextFieldControl = ({ color = 'secondary', control, error, name, defaultValue, fullWidth = true, ...rest }: any) => (
  <Controller
    name={name}
    as={TextField}
    control={control}
    fullWidth={fullWidth}
    color={color}
    error={!!error}
    helperText={error?.message}
    defaultValue={defaultValue || ''}
    {...rest}
  />
)
export const CheckboxControl = ({ onChange, color = 'secondary', disabled, className, label, control, error, name, defaultValue, ...rest }: any) => (
  <Controller
    name={name}
    control={control}
    error={!!error}
    helperText={error?.message}
    defaultValue={defaultValue || false}
    {...rest}
    render={(props) => {
      return (
        <FormControlLabel
          checked={!!props.value}
          className={className}
          disabled={disabled}
          control={
            <Checkbox
              color={color}
              onChange={(e, v) => {
                props.onChange(v)
                onChange && onChange(v)
              }}
            />
          }
          label={label}
        />
      )
    }}
  />
)

export const SwitchControl = ({ onChange, color = 'secondary', helperText, disabled, className, label, control, error, name, defaultValue, ...rest }: any) => (
  <Controller
    name={name}
    control={control}
    error={!!error}
    defaultValue={defaultValue || false}
    {...rest}
    render={(props) => {
      const toggle = (e) => {
        e.stopPropagation()
        e.preventDefault()
        const newVal = !props.value
        props.onChange(newVal)
        onChange && onChange(newVal)
      }
      return (
        <div className={className}>
          <FormControlLabel
            color={color}
            disabled={disabled}
            control={
              <>
                <Switch checked={!!props.value} className='reg-left' onChange={toggle} />
              </>
            }
            label={
              <div className='reg-left'>
                <InputLabel onClick={toggle}>{label}</InputLabel>
                {helperText && (
                  <FormHelperText className='huge-left' onClick={toggle}>
                    {helperText}
                  </FormHelperText>
                )}
                {error && <FormHelperText error>{error?.message}</FormHelperText>}
              </div>
            }
          />
        </div>
      )
    }}
  />
)

interface RadioGroupOption {
  label: string
  value: any
  className?: string
  helperText?: string
}

export const RadioGroupControl = ({
  helperText,
  required,
  color = 'secondary',
  onChange,
  options,
  control,
  error,
  name,
  defaultValue,
  RadioGroupWrapper = RadioGroup,
  RadioWrapper = FormControlLabel,
  ...rest
}: { options: RadioGroupOption[] } & any) => (
  <Controller
    name={name}
    control={control}
    error={!!error}
    defaultValue={defaultValue === undefined || defaultValue === null ? '' : defaultValue}
    {...rest}
    render={(props: any) => {
      return (
        <RadioGroupWrapper>
          {options.map((opt: RadioGroupOption, i: number) => (
            <RadioWrapper
              key={i}
              label={
                <>
                  {opt.label}
                  {opt.helperText && <FormHelperText>{opt.helperText}</FormHelperText>}
                </>
              }
              className={opt.className}
              control={
                <Radio
                  required={required}
                  color={color}
                  checked={props.value === opt.value}
                  onClick={() => {
                    props.onChange(opt.value)
                    onChange && onChange(opt.value)
                  }}
                />
              }
            />
          ))}
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
          {error && <FormHelperText error>{error?.message}</FormHelperText>}
        </RadioGroupWrapper>
      )
    }}
  />
)
const npsStyles = makeStyles({
  root: {
    display: 'inline-block'
  },
  radio: {
    display: 'inline-block',
    padding: '0 2px 8px'
  },
  icon: {
    width: '43px',
    height: '28px',
    textAlign: 'center',
    verticalAlign: 'middle',
    borderRadius: '4px',
    color: '#212122 !important',
    textDecoration: 'none',
    fontSize: '18px',
    backgroundColor: '#ffffff',
    paddingTop: '8px',
    fontFamily: defaultFontFamily,
    fontWeight: 'bold'
  },
  checkedIcon: {
    backgroundColor: '#922A8E',
    color: '#ffffff !important'
  }
})

export const NpsControlGroup = ({
  helperText,
  required,
  color = 'secondary',
  onChange,
  options,
  control,
  error,
  name,
  defaultValue,
  ...rest
}: { options: RadioGroupOption[] } & any) => {
  const classes = npsStyles()
  return (
    <Controller
      name={name}
      control={control}
      error={!!error}
      defaultValue={defaultValue || ''}
      {...rest}
      render={(props: any) => {
        return (
          <RadioGroup className={classes.root}>
            {options.map((opt: RadioGroupOption, i: number) => (
              <Radio
                key={i}
                required={required}
                color={color}
                checked={props.value === opt.value}
                onClick={() => {
                  props.onChange(opt.value)
                  onChange && onChange(opt.value)
                }}
                checkedIcon={<a className={classnames(classes.icon, classes.checkedIcon)}>{opt.label}</a>}
                icon={<a className={classes.icon}>{opt.label}</a>}
                className={classes.radio}
                disableRipple
              />
            ))}
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
            {error && <FormHelperText error>{error?.message}</FormHelperText>}
          </RadioGroup>
        )
      }}
    />
  )
}

export const DateTimeControl = ({ helperText, label, onChange, options, control, error, name, defaultValue, dateProps, inputProps = {}, ...rest }: any) => (
  <Controller
    name={name}
    control={control}
    error={!!error}
    helperText={error?.message}
    defaultValue={defaultValue || ''}
    {...rest}
    render={(props: any) => {
      return (
        <>
          {label && <div className='label xtra-fat-top'>{label}</div>}
          <Datetime
            {...dateProps}
            value={props.value ? new Date(props.value) : ''}
            inputProps={{
              className: 'material-input cursor-pointer',
              readOnly: true,
              ...inputProps
            }}
            onChange={(value) => {
              props.onChange(value)
              onChange && onChange(value)
            }}
            onBlur={(value) => {
              props.onChange(value)
              onChange && onChange(value)
            }}
          />
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
          {error && <FormHelperText error>{error?.message}</FormHelperText>}
        </>
      )
    }}
  />
)
export const DateControl = ({ helperText, label, onChange, options, control, error, name, defaultValue, dateProps, inputProps = {}, ...rest }: any) => (
  <>
    <Controller
      name={name}
      control={control}
      error={!!error}
      helperText={error?.message}
      defaultValue={defaultValue || ''}
      {...rest}
      render={(props: any) => {
        return (
          <>
            {label && <div className='label xtra-fat-top'>{label}</div>}
            <TextField
              type='date'
              InputLabelProps={{
                shrink: true
              }}
              {...dateProps}
              value={moment(props.value).format('yyyy-MM-DD')}
              onChange={(value) => {
                const valStr = value.currentTarget.value
                const newVal = moment(valStr)
                props.onChange(newVal)
                onChange && onChange(newVal)
              }}
            />
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
            {error && <FormHelperText error>{error?.message}</FormHelperText>}
          </>
        )
      }}
    />
  </>
)
export const TimeControl = ({ helperText, label, onChange, options, control, error, name, defaultValue, dateProps, inputProps = {}, ...rest }: any) => (
  <>
    <Controller
      name={name}
      control={control}
      error={!!error}
      helperText={error?.message}
      defaultValue={defaultValue || ''}
      {...rest}
      render={(props: any) => {
        return (
          <>
            {label && <div className='label xtra-fat-top'>{label}</div>}
            <TextField
              type='time'
              InputLabelProps={{ shrink: true }}
              {...dateProps}
              value={props.value ? props.value.format('HH:mm') : ''}
              inputProps={{
                pattern: '[0-9]{2}:[0-9]{2}'
              }}
              pattern='[0-9]{2}:[0-9]{1}'
              onChange={(value) => {
                const valStr = value.currentTarget.value
                const newVal = moment(valStr, 'HH:mm')
                props.onChange(newVal)
                onChange && onChange(newVal)
              }}
            />
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
            {error && <FormHelperText error>{error?.message}</FormHelperText>}
          </>
        )
      }}
    />
  </>
)

export const AvatarControl = ({
  hasProfileImage,
  profileImage,
  rounded,
  avatarSize,
  options,
  control,
  error,
  name,
  defaultValue,
  accept,
  placeHolder,
  className,
  ...rest
}: any) => (
  <Controller
    name={name}
    control={control}
    error={!!error}
    helperText={error?.message}
    defaultValue={defaultValue || ''}
    {...rest}
    render={(props: any) => {
      return (
        <>
          <AvatarUploader
            avatarSize={avatarSize}
            profileImage={profileImage}
            hasProfileImage={hasProfileImage}
            value={props.value}
            accept={accept}
            rounded={rounded}
            className={className}
            placeHolder={placeHolder}
            onChange={(value: any) => {
              props.onChange(value)
            }}
          />
          {error && <FormHelperText error>{error?.message}</FormHelperText>}
        </>
      )
    }}
  />
)

export const FacebookShareUploaderControl = ({ image, hasImage, control, error, name, defaultValue, ...rest }: any) => (
  <Controller
    name={name}
    control={control}
    error={!!error}
    helperText={error?.message}
    defaultValue={defaultValue || ''}
    {...rest}
    render={(props: any) => {
      return (
        <>
          <FacebookShareUploader
            image={image}
            hasImage={hasImage}
            input={{
              value: props.value,
              onChange: (value: any) => {
                props.onChange(value)
              }
            }}
          />
          {error && <FormHelperText error>{error?.message}</FormHelperText>}
        </>
      )
    }}
  />
)

export const FileButtonControl = ({
  color = 'secondary',
  onChange,
  onDelete,
  deletable = false,
  deleteConfirm = "Once you delete, it's gone for good",
  required,
  className,
  accept = undefined,
  helperText,
  control,
  error,
  name,
  sizeLimit = undefined,
  contentType = undefined,
  defaultValue,
  ...rest
}: any) => {
  const [showDelete, setShowDelete] = useState(deletable && !!defaultValue?.url)
  const [fileSizeError, setFileSizeError] = useState('')
  return (
    <>
      {showDelete && defaultValue?.url && (
        <Controller
          name={`remove_${name}`}
          control={control}
          defaultValue={false}
          render={(props) => {
            return <input type='hidden' value={props.value} />
          }}
        />
      )}
      <Controller
        name={name}
        control={control}
        error={!!error}
        helperText={error?.message}
        defaultValue={defaultValue?.url || defaultValue || ''}
        {...rest}
        render={(props: any) => {
          const fileUrl = props.value?.url || props.value
          return (
            <div className={className}>
              {fileUrl && fileUrl.split && (
                <FormHelperText>
                  <Link
                    target='blank'
                    onClick={() => {
                      window.open(fileUrl)
                    }}
                  >
                    {truncate(fileUrl.split('/').pop(), { length: 100 })}
                  </Link>
                </FormHelperText>
              )}
              {props.value?.name && <FormHelperText>{props.value?.name}</FormHelperText>}
              {props.value?.size && <FormHelperText>({(props.value?.size / 1048576).toFixed(2)}MB)</FormHelperText>}
              <Button variant='contained' component='label' color={color}>
                Upload File {required && <sup style={{ color: 'red' }}>*</sup>}
                <input
                  type='file'
                  accept={accept}
                  hidden
                  onChange={(e: any) => {
                    if (e?.currentTarget?.files?.length) {
                      const file = e.currentTarget.files[0]
                      if (contentType && file.type !== contentType) {
                        props.onChange(undefined)
                        onChange && onChange(undefined)
                        setFileSizeError('File must be an MP4 format')
                      } else if (sizeLimit && file.size >= sizeLimit) {
                        props.onChange(undefined)
                        onChange && onChange(undefined)
                        setFileSizeError(`File must be less than ${sizeLimit / 1048576}MB. (File is ${(file.size / 1048576).toFixed(2)}MB)`)
                      } else {
                        setFileSizeError('')
                        props.onChange(file)
                        onChange && onChange(file)
                        setShowDelete(true)
                      }
                    }
                  }}
                />
              </Button>
              {showDelete && props.value && (
                <Button
                  className='reg-left'
                  component='label'
                  color={color}
                  onClick={(e) => {
                    if (!deleteConfirm || (deleteConfirm && (window as FIXME).confirm(deleteConfirm))) {
                      props.onChange(null)
                      onChange && onChange(null)
                      onDelete && onDelete(null)
                    }
                  }}
                >
                  Delete
                </Button>
              )}

              {helperText && <FormHelperText>{helperText}</FormHelperText>}
              {error && <FormHelperText error>{error?.message}</FormHelperText>}
              {fileSizeError && <FormHelperText error>{fileSizeError}</FormHelperText>}
            </div>
          )
        }}
      />
    </>
  )
}
export const FileControl = FileButtonControl

export const MapRegionControl = ({ options, control, error, name, defaultValue, ...rest }: any) => (
  <Controller
    name={name}
    control={control}
    error={!!error}
    helperText={error?.message}
    defaultValue={defaultValue || []}
    {...rest}
    render={(props) => {
      return (
        <>
          <RegionMap
            updateForm={(value: any, action: any) => {
              if (action === 'region_coordinates') {
                props.onChange(value)
              }
            }}
            regionCoordinates={chunk(props.value, 2).map(([latitude, longitude]) => ({ latitude, longitude }))}
          />
          {error && <FormHelperText error>{error?.message}</FormHelperText>}
        </>
      )
    }}
  />
)

interface SelectOption {
  label: string
  value: any
  disabled?: boolean
}
export const SelectControl = ({
  color = 'secondary',
  required,
  label,
  onChange,
  className,
  options,
  control,
  error,
  name,
  defaultValue,
  multiple,
  helperText,
  ...rest
}: { options: SelectOption[] } & any) => (
  <Controller
    name={name}
    control={control}
    error={!!error}
    helperText={error?.message}
    defaultValue={defaultValue || ''}
    {...rest}
    render={(props: any) => {
      return (
        <>
          <div className={className}>
            <InputLabel required={required} shrink>
              {label}
            </InputLabel>
            <Select
              color={color}
              required={required}
              error={!!error}
              fullWidth={true}
              multiple={multiple}
              value={multiple ? Array.from(props.value) : props.value}
              onChange={({ target: { value } }) => {
                const newVal = multiple ? (value as any).filter((v) => v !== '') : value
                props.onChange(newVal)
                onChange && onChange(newVal)
              }}
            >
              {options.map((opt: SelectOption, i: number) => (
                <MenuItem key={i} value={opt.value} disabled={opt.disabled}>
                  {opt.label}
                </MenuItem>
              ))}
            </Select>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
            {error && <FormHelperText error>{error?.message}</FormHelperText>}
          </div>
        </>
      )
    }}
  />
)

export const renderFormHelper = ({ touched, error }: FIXME) => {
  if (!(touched && error)) {
    return
  } else {
    return <FormHelperText>{touched && error}</FormHelperText>
  }
}

export const localOptions = [
  { label: 'English', value: 'en_US' },
  { label: 'German', value: 'de_DE' },
  { label: 'Spanish (Spain)', value: 'es_ES' },
  { label: 'Spanish (Latin America)', value: 'es_419' },
  { label: 'French', value: 'fr_FR' },
  { label: 'Italian', value: 'it_IT' },
  { label: 'Dutch', value: 'nl_NL' },
  { label: 'Polish', value: 'pl_PL' },
  { label: 'Portuguese (Brazil)', value: 'pt_BR' },
  { label: 'Swedish', value: 'sv_SE' },
  { label: 'Turkish', value: 'tr_TR' }
]
