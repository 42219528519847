import React, { useEffect } from 'react'
import { Table, TableBody, TableHead, TableCell, TableRow } from '@material-ui/core'

import UserCsvExports from './UserCsvExports'
import ConversationCsvExports from './ConversationCsvExports'
import GuestCsvExports from './GuestCsvExports'
import SurveyQuestionCsvExports from './SurveyQuestionCsvExports'
import withTranslation from '../../hocs/withTranslation'
import { IStore } from '../../../store/models'
import { inject, observer } from 'mobx-react'
import { flow } from 'lodash'
import { withRouter } from 'react-router'
import DownloadsSummary from '../../admin/v2/Analytics/DownloadsSummary'

const DownloadsPage = ({ t, loaded, user, history }: FIXME) => {
  // useEffect(() => {
  //   if (loaded && user && user.isCommunityAdmin()) {
  //     history.push('/admin/analytics/downloads')
  //   }
  // }, [loaded, user, history])
  // return <DownloadsSummary />
  return (
    <div className='hugest-bottom'>
      <section className='pane reg-pane'>
        <div className='row'>
          <div className='col-xs-12'>
            <div className='box'>
              <DownloadsSummary height={'calc(100vh - 15rem)'} />
              {/* <div className='header-32 bold thin-bottom'>{t('common.myDownloads')}</div>
              <div className='header-16 italic dark-grey- text'>{t('user.yourDownloads')}</div>
              <div className='header-16 italic fat-bottom dark-grey-text'>{t('user.refreshDownloads')}</div>
              <Table>
                <TableHead>
                  <TableRow className='row-size-16'>
                    <TableCell className='no-left-gutter'>{t('user.csvType')}</TableCell>
                    <TableCell>{t('user.details')}</TableCell>
                    <TableCell>{t('user.requestedAt')}</TableCell>
                    <TableCell>&nbsp;</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <UserCsvExports />

                  <ConversationCsvExports />

                  <GuestCsvExports />

                  <SurveyQuestionCsvExports />
                </TableBody>
              </Table> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default flow(
  withTranslation,
  withRouter,
  observer,
  inject<FIXME, FIXME>(
    ({
      mst: {
        auth: { user, loaded }
      }
    }: {
      mst: IStore
    }) => {
      return {
        user,
        loaded
      }
    }
  )
)(DownloadsPage)
