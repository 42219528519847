import React, { useEffect, useState } from 'react'
import { Button, Tab, Tabs } from '@material-ui/core'
import SummaryContainer from '../partials/SummaryContainer'
import TopicsTable from './TopicsTable'
import SummaryStats from '../partials/SummaryStats'
import { inject, observer } from 'mobx-react'
import { IStore } from '../../../../store/models'
import { flow } from 'lodash'
import { withRouter, RouteComponentProps } from 'react-router'
import { IUser } from '../../../../store/models/User'

interface Props {
  user: IUser
  topics_total: number
  conversations_total: number
  hosts_total: number
  guests_total: number
  timeScope: string
  loadStats()
  setTimeScope(val: string)
  loadFilter(arg)
}

const TopicsSummary = ({
  user,
  history,
  topics_total,
  conversations_total,
  hosts_total,
  guests_total,
  loadStats,
  setTimeScope,
  loadFilter,
  timeScope
}: Props & RouteComponentProps) => {
  const [index, setIndex] = useState('archived' === timeScope ? 1 : 0)
  useEffect(() => {
    loadStats()
  }, [loadStats])
  return (
    <div>
      <SummaryContainer
        title={'Topics'}
        id='ic-admin-topics'
        topRight={
          user.isSuperAdmin() && (
            <Button onClick={() => history.push('/admin/topics/new')} variant='contained' startIcon={<i className='far fa-plus-circle'></i>}>
              New Topic
            </Button>
          )
        }
      >
        <SummaryStats
          data={[
            { title: 'Total Topics', value: topics_total },
            { title: 'Total Conversations', value: conversations_total },
            { title: 'Total Unique Hosts', value: hosts_total },
            { title: 'Total Guests', value: guests_total }
          ]}
        />
        <Tabs
          value={index}
          onChange={(e, val) => {
            setIndex(val)
            if (val === 1) {
              setTimeScope('archived')
            } else {
              setTimeScope('active')
            }
            loadFilter({ reset: true, clear: true })
          }}
          indicatorColor='primary'
          textColor='primary'
          variant='fullWidth'
          centered
        >
          <Tab label={'Active'} />
          <Tab label={'Archived'} />
        </Tabs>

        <TopicsTable />
      </SummaryContainer>
    </div>
  )
}

export default flow(
  withRouter,
  observer,
  inject<FIXME, FIXME>(
    ({
      mst: {
        auth: { user },
        partners: { topics_total, conversations_total, hosts_total, guests_total, loadStats },
        topics: { setTimeScope, loadFilter, timeScope }
      }
    }: {
      mst: IStore
    }) => {
      return {
        user,
        topics_total,
        conversations_total,
        hosts_total,
        guests_total,
        loadStats,
        setTimeScope,
        loadFilter,
        timeScope
      }
    }
  )
)(TopicsSummary)
