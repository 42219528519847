import appAnalytics from '../../../analytics'
import { deleteApi, get, post, put } from '../../utils'

class inviteAdminApi {
  static getAll({ partnerId, limit, offset }) {
    return get(`/partners/${partnerId}/admin_invitations?limit=${limit}&offset=${offset}`).catch((error) => {
      return error
    })
  }

  static invite(partnerId, email) {
    return post(`/partners/${partnerId}/admin_invitations?site_name=${global.siteName}`, { body: JSON.stringify({ email }) })
      .catch((error) => {
        return error
      })
      .then((d) => {
        return d
      })
  }

  static acceptInvitation({ partnerId, token }) {
    return put(`/partners/${partnerId}/admin_invitations/accept?token=${token}`)
      .catch((error) => {
        return error
      })
      .then((d) => {
        appAnalytics.event({
          category: 'Admin',
          action: 'Partner admin accepted invitation'
        })
        return d
      })
  }

  static delete({ partnerId, id }) {
    return deleteApi(`/partners/${partnerId}/admin_invitations/${id}`).then((d) => {
      appAnalytics.event({
        category: 'Admin',
        action: 'Partner admin invitation removed'
      })
      return d
    })
  }
}

export default inviteAdminApi
