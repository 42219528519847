import React from 'react'
import VirtualLocationStep from './VirtualLocationStep'
import HomeLocationStep from './HomeLocationStep'
import PublicLocationStep from './PublicLocationStep'
import { StepProps } from './NewStepper'
import withTranslation, { WithTranslation } from '../../hocs/withTranslation'

const VirtualDetailsStep = (props: StepProps & WithTranslation) => {
  const { watch } = props
  return (
    <>
      <div>
        {watch('venueType') === 'virtual' && <VirtualLocationStep {...props} />}
        {watch('venueType') === 'home' && <HomeLocationStep {...props} />}
        {['restaurant', 'other'].includes(watch('venueType')) && <PublicLocationStep {...props} />}
      </div>
    </>
  )
}

export default withTranslation(VirtualDetailsStep)
