import { Button, Grid } from '@material-ui/core'
import { isEmpty, identity, pick, pickBy, camelCase } from 'lodash'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { IDocument } from '../../store/models/Topic/TopicDocuments'
import { FileButtonControl, SelectControl, TextFieldControl } from '../utils'
import * as validations from '../validations/fieldLevelValidations'

interface Props {
  document?: IDocument
  onSubmit(data: any): Promise<any>
  onCancel?()
  onDirty?(val: boolean)
  grouped?: boolean
  groupIds?: (string | undefined)[]
}

export const Form = ({ document, onSubmit, onCancel, onDirty, grouped = false, groupIds = [] }: Props) => {
  const { handleSubmit, errors, formState, control, watch, setError, reset, clearErrors } = useForm({ mode: 'onBlur', shouldUnregister: false })
  const { isDirty, isSubmitting, dirtyFields } = formState

  const displayError = (error) => {
    if (error) {
      for (const fieldName in error) {
        setError(camelCase(fieldName), {
          type: 'manual',
          message: error[fieldName]
        })
      }
    }
  }

  const handleSave = handleSubmit((data: any, e) => {
    data.groupId = isEmpty(data.newGroupId) ? data.groupId : data.newGroupId
    delete data.newGroupId
    const updateData = { ...(document ? pick(data, Object.keys(pickBy(dirtyFields, identity))) : data) }

    onSubmit(updateData).then(afterSave)
  })

  const afterSave = async ({ error = undefined }: { error?: any; data?: any } = {}) => {
    if (error) {
      displayError(error)
    } else {
      reset({ file: null })
    }
    onDirty && onDirty(false)
  }

  useEffect(() => {
    onDirty && onDirty(isDirty)
  }, [document, isDirty, onDirty])

  return (
    <div className='fat-bottom'>
      <form onSubmit={handleSave}>
        <Grid container>
          <Grid item xs={5}>
            <FileButtonControl
              rules={{ validate: validations.required }}
              className='reg-bottom'
              name='file'
              control={control}
              defaultValue={document?.file}
              error={errors && errors.file}
            />
          </Grid>

          <Grid item xs={3}>
            {grouped && dirtyFields.file && (
              <SelectControl name='groupId' control={control} label={'Group'} options={groupIds.map((id) => ({ label: id, value: id }))} className='reg-bottom' />
            )}
          </Grid>

          <Grid item xs={3} className='reg-left'>
            {grouped && dirtyFields.file && (
              <TextFieldControl name='newGroupId' control={control} error={errors && errors.newGroupId} label={'New Group'} rules={{ validate: validations.notSlugFriendly }} />
            )}
          </Grid>
        </Grid>

        <div className={!isDirty ? 'hide' : undefined}>
          <Button type='submit' className='reg-top' color='secondary' variant='contained'>
            Submit
          </Button>
          <Button
            className='reg-top thin-left'
            color='secondary'
            onClick={() => {
              reset({ file: null })
              onCancel && onCancel()
            }}
          >
            Cancel
          </Button>
        </div>
      </form>
      <br />
    </div>
  )
}
