import React, { Component } from 'react'
import { flow, isEmpty } from 'lodash'

import PartnerForm from './Form'
import PartnersTopicsEdit from './PartnersTopicsEdit'
import { connectAdminThemeV2 } from '../../connectTheme.js'
import AppTabs from '../shared/AppTabs'
import PartnerAdmins from './Admins'
import SocialMediaEdit from '../social-media/Form'
import SectionsEdit from '../sections/EditMst'
import Tracks from './Edit/Tracks/Tracks'
import PrivateLabel from './Edit/PrivateLabel/PrivateLabel'
import { withRouter } from 'react-router'
import { inject, observer } from 'mobx-react'
import { IStore } from '../../store/models'
import PageLoadingAnimation from '../shared/PageLoadingAnimation'
import PartnerTracksProvider from '../Providers/PartnerTracksProvider'
import Sso from './Edit/Sso'
import Domains from './Edit/Domains'

class EditPartnerPage extends Component<FIXME, FIXME> {
  tab_names = ['', 'admins', 'private_label', 'seo', 'topics', 'sponsors', 'sso', 'domains', 'journey']

  getTabNames = () => {
    const { partner } = this.props
    const tabNames = [...this.tab_names]
    if (!partner?.privateLabel) {
      tabNames.splice(2, 1)
    }
    if (!window.MEMBER_ENABLED_PARTNERS.includes(partner?.id) && !partner?.isJourney) {
      tabNames.splice(8, 1)
    }
    return tabNames
  }
  handleTabChange = (tabIndex) => {
    const {
      match: {
        params: { slug }
      },
      history
    } = this.props

    history.push(`/admin/partners/${slug}/edit/t/${this.getTabNames()[tabIndex]}`)
  }

  tabIndex = () => {
    const {
      match: {
        params: { tab }
      }
    } = this.props
    if (!tab) {
      return 0
    }
    const activeTab = this.getTabNames().indexOf(tab)
    return activeTab === -1 ? 0 : activeTab
  }
  render() {
    const { partner, history, partnerType, organizations, user } = this.props

    if (!partner) return <PageLoadingAnimation />

    return (
      <PartnerTracksProvider>
        {!isEmpty(partner) && (
          <AppTabs
            index={this.tabIndex()}
            onChange={(tabIndex) => {
              history.push(`/admin/partners/${partner.slug}/edit/t/${this.getTabNames()[tabIndex]}`)
            }}
            tabs={[
              {
                label: 'General',
                content: (
                  <>
                    <div className='header-24 bold fat-bottom'>Edit {partnerType}</div>
                    <PartnerForm organizations={organizations} partner={partner} page={'edit'} onSave={(data) => data && history.push(`/admin/partners/${data.slug}/edit`)} />
                  </>
                )
              },
              {
                label: 'Admins',
                content: (
                  <>
                    <div className='header-24 bold fat-bottom'>Admins</div>
                    <PartnerAdmins partner={partner} />
                  </>
                )
              },
              ...(partner?.privateLabel
                ? [
                    {
                      label: 'Private Label',
                      content: (
                        <>
                          <div className='header-24 bold fat-bottom'>Private Label</div>
                          <PrivateLabel />
                        </>
                      )
                    }
                  ]
                : []),

              {
                label: 'Social Media & SEO',
                content: (
                  <>
                    <div className='header-24 bold fat-bottom'>Social Media & SEO</div>
                    <SocialMediaEdit item={partner} onSave={partner.updatePartner} />
                  </>
                )
              },
              {
                label: 'Linked Topics',
                content: (
                  <>
                    <div className='header-24 bold fat-bottom'>Linked Topics</div>
                    <PartnersTopicsEdit partner={partner} />
                  </>
                )
              },
              {
                label: 'Sponsors',
                content: (
                  <>
                    <div className='header-24 bold fat-bottom'>Sponsors</div>
                    {!isEmpty(partner) && <SectionsEdit sectionable={partner} type='Partner' />}
                  </>
                )
              },
              ...(user.isPartnerAdmin()
                ? [
                    {
                      label: 'SSO',
                      content: (
                        <>
                          <div className='header-24 bold fat-bottom'>SSO</div>
                          {partner && <Sso />}
                        </>
                      )
                    }
                  ]
                : []),
              ...(user.isPartnerAdmin()
                ? [
                    {
                      label: 'Domains',
                      content: (
                        <>
                          <div className='header-24 bold fat-bottom'>Domains</div>
                          {partner && <Domains />}
                        </>
                      )
                    }
                  ]
                : []),
              ...(window.MEMBER_ENABLED_PARTNERS.includes(partner.id) || partner.isJourney
                ? [
                    {
                      label: 'Journey',
                      wide: true,
                      noBottom: true,
                      content: (
                        <div style={{ margin: '0 1rem 0 1rem' }}>
                          <div className='header-24 bold fat-bottom'>Journey</div>
                          {!isEmpty(partner) && <Tracks />}
                        </div>
                      )
                    }
                  ]
                : [])
            ]}
            contentStyle={{ height: 'calc(100vh - 17em)', overflowY: 'auto' }}
          />
        )}
      </PartnerTracksProvider>
    )
  }
}

export default flow(
  observer,
  inject<FIXME, FIXME>(
    (
      {
        mst: {
          auth: { user, loaded },
          partners,
          organizations
        }
      }: {
        mst: IStore
      },
      {
        match: {
          params: { slug: rawSlug }
        }
      }
    ) => {
      const slug = rawSlug?.toLowerCase()
      const partner = partners.list.find((d) => d.slug.toLowerCase() === slug)
      const partnerLoading = partners.loadingIds.includes(slug)

      !partner && partners.shouldLoad(slug, () => partners.loadBySlug(slug))
      organizations.shouldLoad('organizations_table', () => {
        organizations.setPageSize(100)
        organizations.loadFilter()
      })
      if (organizations.pageSize !== 100 && !organizations.loadingIds.includes('organizations_table')) {
        organizations.setPageSize(100)
        organizations.loadFilter()
      }

      return {
        user,
        partnerType: 'Customer',
        partner,
        partnerLoading,
        organizations,
        count: organizations.list
      }
    }
  ),
  withRouter,
  connectAdminThemeV2
)(EditPartnerPage)
