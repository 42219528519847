import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import { flow } from 'lodash'
import { IStore, ITrack } from '../../store'
import { inject } from 'mobx-react'

const UserTracksProvider = ({ children, tracks, tracksLoading, loadTracks, joinMembership, reloadTracks, loadHsMembership }: Props & InjectedProps) => {
  return <UserTrackContext.Provider value={{ tracks, tracksLoading, loadTracks, joinMembership, reloadTracks, loadHsMembership }}>{children}</UserTrackContext.Provider>
}

interface Props {
  children?: React.ReactNode | React.ReactNode[]
}

interface InjectedProps {
  tracks: ITrack[]
  tracksLoading: boolean
  loadTracks()
  reloadTracks()
  joinMembership(token: string, action?: string): Promise<any>
  loadHsMembership(): Promise<any>
}

export const UserTrackContext = React.createContext<InjectedProps>({
  tracks: [],
  tracksLoading: false,
  loadTracks: () => {
    // tbd
  },
  reloadTracks: () => {
    // tbd
  },
  joinMembership: () => {
    return Promise.resolve()
  },
  loadHsMembership: () => {
    return Promise.resolve()
  }
})

export const useUserTracks = () => useContext(UserTrackContext)

export default flow(
  observer,
  inject<InjectedProps, Props>(
    ({
      mst: {
        tracks,
        auth: { user }
      }
    }: {
      mst: IStore
    }) => {
      user && tracks.shouldLoad('all', tracks.loadTracks)

      return {
        tracks: tracks.userList,
        joinMembership: tracks.joinMembership,
        tracksLoading: tracks.loadingIds.includes('all'),
        loadTracks: () => user && tracks.shouldLoad('all', tracks.loadTracks),
        reloadTracks: () => user && tracks.loadTracks(),
        loadHsMembership: tracks.loadHsMembership
      }
    }
  )
)(UserTracksProvider)
