import * as types from '../actions/actionTypes'
import { assign } from 'lodash'
import initialState from './initialState'

export const topicOptions = (state = initialState.topicOptions, action: any) => {
  switch (action.type) {
    case types.CONVERSATION_OPTIONS_LOADING:
      return assign({}, state, {
        error: null,
        loading: true
      })

    case types.LOAD_CONVERSATION_OPTIONS_SUCCESS:
      return assign({}, state, {
        error: null,
        loading: false,
        loaded: true,
        list: assign({}, state.list, action.topics)
      })

    case types.LOAD_CONVERSATION_OPTIONS_FAILED:
      return assign({}, state, {
        error: action.data,
        loading: false
      })

    default:
      return state
  }
}

export default topicOptions
