import React, { useEffect } from 'react'
import { flow } from 'lodash'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import LoadingAnimation from '../../shared/LoadingAnimation'
import Avatar from '../../users/Avatar.js'
import withTranslation from '../../hocs/withTranslation'
import { observer } from 'mobx-react'
import { IConversation } from '../../../store/models/Conversation'
import { createStyles, Grid, makeStyles, Paper } from '@material-ui/core'
import appAnalytics from '../../../analytics'

interface Props {
  conversation: IConversation
  t: FIXME
}

const GuestRequests = ({ conversation, t }: Props) => {
  useEffect(() => {
    if (!conversation.loadingIds.includes('pendingRequested') && !conversation.loadedIds.includes('pendingRequested')) {
      conversation.loadPendingRequested()
    }
  }, [conversation])

  const classes = Styles()
  const reloadConversation = () => {
    conversation.reloadConversation()
    conversation.loadPendingRequested()
    conversation.setHasMoreGuests(true)
    conversation.loadGuests()
  }
  return (
    <div>
      <h3>{t('conversation.requests')}</h3>
      <Paper elevation={3} className={classes.paper}>
        <Grid container justify='space-between'>
          <Grid item>
            <h4>
              {t('conversation.pendingRequests')} {`(${conversation.pendingRequested.length})`}
            </h4>
          </Grid>

          <Grid item>
            <Button
              variant='contained'
              color='primary'
              disabled={conversation.pendingRequested.length === 0}
              onClick={() => {
                appAnalytics.event({
                  category: 'Conversation',
                  action: 'Accept All Requests'
                })
                conversation.acceptAllPendingRequest().then(reloadConversation)
              }}
            >
              {t('conversation.acceptAllRequests')}
            </Button>
          </Grid>
        </Grid>
        {conversation.loadingIds.includes('pendingRequested') ? (
          <LoadingAnimation />
        ) : conversation.pendingRequested.length ? (
          <List>
            <Divider variant='inset' component='li' />
            {conversation.pendingRequested.map((guest) => (
              <div key={guest.id}>
                <ListItem button>
                  <ListItemAvatar>
                    <Avatar profileImage={guest.profileImage} hasProfileImage={guest.hasProfileImage} size={'thumb'} />
                  </ListItemAvatar>
                  <ListItemText primary={guest.fullName()} />
                  <ListItemSecondaryAction>
                    <Button
                      onClick={() =>
                        conversation.handleAcceptRequest(guest.id).then((user) => {
                          appAnalytics.event({
                            category: 'Conversation',
                            action: 'Accept Request'
                          })
                          reloadConversation()
                        })
                      }
                      color='primary'
                      // variant='contained'
                    >
                      {t('conversation.acceptRequest')}
                    </Button>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider variant='inset' component='li' />
              </div>
            ))}
          </List>
        ) : (
          <p className='italic'>{t('conversation.noPendingRequests')}</p>
        )}
      </Paper>
    </div>
  )
}

const Styles = makeStyles((theme) =>
  createStyles({
    paper: {
      padding: '1em 1em'
    }
  })
)

export default flow(observer, withTranslation)(GuestRequests)
