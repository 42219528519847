import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { flow } from 'lodash'
import { inject, observer } from 'mobx-react'
import { IStore, IUser } from '../../../../store/models'
import DataTable, { IColData, ValueLabel } from '../../../shared/DataTableFilterable'
import { ITopic } from '../../../../store/models/Topic'
import { IFilterable } from '../../../../store/models/helpers/Filterable'
import TopicCellValue from '../partials/TopicCellValue'

const TopicsTable = ({ items, loading, filterable, user }: Props & InjectedProps & RouteComponentProps) => {
  const columnData: IColData[] = [
    { name: 'createdAt', title: 'Created Date', width: 140, sortable: true, align: 'right' },
    { name: 'title', title: 'Conversation Topic', minWidth: 250, sortable: true },
    { name: 'active', title: 'Status', width: 100 },
    { name: 'startsAt', title: 'Start Date', width: 100, sortable: true, align: 'right' },
    { name: 'endsAt', title: 'End Date', width: 100, sortable: true, align: 'right' },
    ...(user?.isSuperAdmin() ? ([{ name: 'isTemplate', title: 'Template', width: 80, align: 'center', sortable: true }] as IColData[]) : []),
    { name: 'partnerName', title: 'Customer', minWidth: 200, sortable: true },
    { name: 'conversationsCount', title: 'Conversations', width: 120, sortable: true, align: 'right' },
    { name: 'blank', title: ' ', width: 80 },
    { name: 'actions', title: 'Actions', width: 220, align: 'center' }
  ]

  return (
    <DataTable
      height={'calc(100vh / 3)'}
      data={items}
      columns={columnData}
      loading={loading}
      filterable={filterable}
      rightColumns={['actions']}
      hideFilter
      getCellValue={TopicCellValue}
    />
  )
}
interface Props {}
interface InjectedProps {
  items: ITopic[]
  loading: boolean
  filterable: IFilterable
  user?: IUser
}
export default flow(
  observer,
  inject<InjectedProps, Props>(
    ({
      mst: {
        topics,
        auth: { user }
      }
    }: {
      mst: IStore
    }) => {
      return {
        user,
        filterable: topics,
        items: topics.list,
        loading: topics.loadingIds.includes('topics_table')
      }
    }
  ),
  withRouter
)(TopicsTable)
