import React, { Component } from 'react'
import Button from '@material-ui/core/Button'

import Dialog from '../DialogWrapper'
import { connectTopicTheme } from '../../connectTheme'
import withTranslation from '../hocs/withTranslation'
import { flow } from 'lodash'
import { inject, observer } from 'mobx-react'
import { IStore } from '../../store/models'
import { DialogActions, Grid, Icon, Typography } from '@material-ui/core'

class DeleteRSVPDialog extends Component<FIXME, FIXME> {
  handleSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const { conversation, dialog, userId } = this.props

    conversation && conversation.unattend({ userId }, { successAlert: dialog?.data?.successAlert })
    dialog.closeDialog()
  }

  render() {
    const { t, dialog } = this.props

    return (
      <Dialog dialog={dialog} name='deleteRSVP' title={'Cancel RSVP'}>
        <form onSubmit={this.handleSubmit}>
          <div className='align-center'>
            <div style={{ backgroundColor: '#F6F7F9', padding: '1rem' }}>
              <Icon className='far fa-calendar-times thin-bottom' style={{ color: '#EE413E', fontSize: '3rem', marginTop: '1rem' }}></Icon>
              <Typography variant='h6'>Are You Sure You Want to Cancel Your RSVP?</Typography>
            </div>
            <DialogActions style={{ margin: '.5rem' }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Button variant='contained' color='primary' fullWidth size='large' onClick={() => dialog.closeDialog()}>
                    No - I Can Still Attend
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button type='submit' variant='contained' fullWidth size='large' style={{ color: '#ff0000' }}>
                    Yes - Please Cancel My RSVP
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </div>
        </form>
      </Dialog>
    )
  }
}

export default flow(
  connectTopicTheme,
  observer,
  inject<FIXME, FIXME>(
    (
      {
        mst: {
          conversations,
          dialogs: { showDialog }
        }
      }: { mst: IStore },
      { data: { conversationId } }
    ) => {
      const conversation = conversations.list.find((d) => d.id === conversationId)
      if (!conversation && !conversations.loadingIds.includes(conversationId)) {
        conversations.loadById(conversationId)
      }

      return {
        conversation,
        showDialog
      }
    }
  ),

  withTranslation
)(DeleteRSVPDialog)
