import React from 'react'
import { isNull } from 'lodash'

class FacebookShareUploader extends React.Component {
  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
  }

  onChange(e) {
    const {
      input: { onChange }
    } = this.props
    onChange(e.target.files[0])
  }

  renderPreview = (value) => {
    const { image, hasImage } = this.props

    if (value && !isNull(value.url)) {
      let preview
      try {
        var url = window.URL || window.webkitURL || window.mozURL
        preview = url.createObjectURL(value)
      } catch (error) {
        //  https://developers.google.com/web/updates/2018/10/chrome-71-deps-rems
        preview = value.url
      }

      return preview
    } else if (hasImage) {
      return image.primary.url
    } else {
      return 'https://civicdinners-manual-storage.s3.amazonaws.com/civicdinners_newsocialimage.png'
    }
  }

  render() {
    const {
      input: { value }
    } = this.props

    return (
      <div>
        <div className={`facebook-share-preview`}>
          <img src={this.renderPreview(value)} alt='preview' />
        </div>
        <div className='label header-20 thin-top'>Image size should be no smaller than 1200px x 630px (1/1.91 ratio)</div>
        <input type='file' onChange={this.onChange} />
      </div>
    )
  }
}

export default FacebookShareUploader
