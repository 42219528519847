import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { ITopic } from '../../store'
import { ItemForm } from './ItemForm'
import { LmsCourse } from './LmsCourse'
import { CourseForm } from './CourseForm'
import { Button, Typography } from '@material-ui/core'
import LoadingAnimation from '../shared/LoadingAnimation'

interface Props {
  topic: ITopic
}

const Edit = observer(({ topic }: Props) => {
  // const [lmsCourse, setLmsCourse] = useState<ITopicLmsCourse | undefined>(undefined)
  const [editCourse, setEditCourse] = useState(false)
  const [createItem, setCreateItem] = useState(false)
  const lmsCourse = topic.topicLmsCourse
  const isLoading = !topic || topic?.loadingIds?.includes('topicLmsCourses')
  useEffect(() => {
    topic && topic.shouldLoad('topicLmsCourses', topic.loadTopicLmsCourses)
  }, [topic])
  // useEffect(() => {
  //   if (topicLmsCourse) {
  //     setLmsCourse(topicLmsCourse)
  //   }
  // }, [topicLmsCourse, setLmsCourse])

  // console.log({ lmsCourse })

  if (isLoading) {
    return <LoadingAnimation />
  }
  return (
    <div>
      {lmsCourse && (
        <>
          <Typography variant='h5'>{lmsCourse?.name}</Typography>
          <Typography variant='h6' className='reg-bottom'>
            {lmsCourse?.description}
          </Typography>
        </>
      )}
      <Button variant='outlined' className='reg-bottom' onClick={() => setEditCourse(!editCourse)}>
        {editCourse ? 'Cancel' : lmsCourse ? 'Edit' : 'Create'}
      </Button>
      <div>
        {editCourse && (
          <CourseForm
            lmsCourse={lmsCourse || undefined}
            onSubmit={(data) => {
              // console.log({ data })
              return lmsCourse
                ? lmsCourse.updateLmsCourse(data).then(() => {
                    topic.loadTopicLmsCourses()
                    setEditCourse(false)
                  })
                : topic.createLmsCourse(data).then(() => {
                    topic.loadTopicLmsCourses()
                    setEditCourse(false)
                  })
              // return Promise.resolve()
            }}
          />
        )}
        {lmsCourse && !createItem && (
          <Button variant='outlined' className='reg-bottom' onClick={() => setCreateItem(true)}>
            Create Video
          </Button>
        )}
        {createItem && (
          <ItemForm
            onSubmit={(data) => {
              // console.log({ data })
              return lmsCourse
                ? lmsCourse.createLmsItem(data).then(() => {
                    setCreateItem(false)
                    topic.loadTopicLmsCourses()
                  })
                : Promise.resolve()
            }}
          />
        )}
      </div>
      <div>{lmsCourse && <LmsCourse lmsCourse={lmsCourse} />}</div>
    </div>
  )
})

export default Edit
