import React from 'react'
import { Controller } from 'react-hook-form'
import { FormHelperText, InputLabel, Chip, createStyles, makeStyles } from '@material-ui/core'

interface ChipGroupOption {
  label: string
  value: any
  className?: string
}

export const ChipGroupControl = ({
  label,
  helperText,
  required,
  color = 'secondary',
  onChange,
  options,
  control,
  error,
  name,
  defaultValue,
  ...rest
}: { options: ChipGroupOption[] } & any) => {
  const classes = Styles()
  return (
    <Controller
      name={name}
      control={control}
      error={!!error}
      defaultValue={defaultValue || ''}
      {...rest}
      render={(props: any) => {
        return (
          <div>
            <InputLabel required={required} shrink className={classes.label}>
              {label}
            </InputLabel>
            {error && <FormHelperText error>{error?.message}</FormHelperText>}
            {helperText && <FormHelperText className={classes.helper}>{helperText}</FormHelperText>}
            <div className={classes.chips}>
              {options.map((opt: ChipGroupOption, i: number) => (
                <Chip
                  key={i}
                  label={opt.label}
                  color={color}
                  variant={props.value.includes(opt.value) ? 'default' : 'outlined'}
                  className={classes.chip}
                  onClick={() => {
                    const newVal = props.value.includes(opt.value) ? props.value.filter((v) => v !== opt.value) : [...(props.value || []), opt.value]
                    props.onChange(newVal)
                    onChange && onChange(newVal)
                  }}
                />
              ))}
            </div>
          </div>
        )
      }}
    />
  )
}
const Styles = makeStyles((theme) =>
  createStyles({
    label: {
      fontFamily: 'Avenir',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '1.5em',
      lineHeight: '24px'
    },
    helper: {},
    chips: {
      marginTop: '1em'
    },
    chip: {
      margin: '.3em'
    }
  })
)

export default ChipGroupControl
