import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginLeft: '.5em'
    }
  }
}))
export default function GroupedButtons({ children }) {
  const classes = useStyles()

  return <div className={classes.root}>{children}</div>
}
