import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { flow } from 'lodash'
import { SnackbarProvider, useSnackbar } from 'notistack'
import { IStore } from '../../store'
import { IAlertMessage } from '../../store/models/AlertMessage'
import { makeStyles } from '@material-ui/core'
const useStyles = makeStyles(() => ({
  root: {
    zIndex: 10000,
    ['& #notistack-snackbar']: {
      margin: '0 auto'
    }
  }
}))
export const AlertSnackProvider = ({ children }: Props) => {
  const classes = useStyles()
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      classes={{
        containerRoot: classes.root
      }}
    >
      {children}
    </SnackbarProvider>
  )
}

const AlertContextProvider = flow(
  observer,
  inject<InjectedProps, Props>(({ mst }: { mst: IStore }, ownProps) => {
    const { alertMessages } = mst
    return {
      clearAlert: alertMessages.clearAlert,
      alertMessages: alertMessages.list
    }
  })
)(({ children, alertMessages, clearAlert }: Props & InjectedProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const alertCount = alertMessages.length
  useEffect(() => {
    if (alertCount > 0) {
      !window.coco && alertMessages.map((alertMessage) => enqueueSnackbar(alertMessage.message))
      clearAlert()
    }
  }, [alertCount, alertMessages, enqueueSnackbar, clearAlert])
  const showAlert = (msg) => {
    !window.coco && enqueueSnackbar(msg)
  }
  return <AlertContext.Provider value={{ showAlert }}>{children}</AlertContext.Provider>
})

const AlertProvider = ({ children }: Props) => {
  return (
    <AlertSnackProvider>
      <AlertContextProvider>{children}</AlertContextProvider>
    </AlertSnackProvider>
  )
}

export const AlertContext = React.createContext({
  showAlert: (msg) => {
    // console.log({ msg })
  }
})

interface Props {
  children?: React.ReactNode | React.ReactNode[]
}
interface InjectedProps {
  alertMessages: IAlertMessage[]
  clearAlert()
}

export default AlertProvider
