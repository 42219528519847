import { types, Instance } from 'mobx-state-tree'
import moment from 'moment-timezone'
import { UserConversation } from '../User/UserConversations'
import UserConversationsApi from '../../../api/users/UserConversationsApi'
import { LoadableStatus } from '../helpers'
import ConversationBase from './ConversationBase'

export const ConversationUserConversations = types
  .compose(
    'ConversationUserConversations',
    ConversationBase,
    LoadableStatus,
    types.model({
      userConversations: types.optional(types.array(UserConversation), [])
    })
  )
  .actions((self) => ({
    setUserConversations(data: any) {
      self.userConversations.replace(data)
    }
  }))
  .actions((self) => ({
    loadUserConversations: () => {
      if (self.isLoading('conversationUserConversations')) {
        return
      }
      self.startLoading('conversationUserConversations')
      return UserConversationsApi.getAllByConversationId(self.id).then(({ response: { ok }, json }) => {
        if (ok) {
          self.setUserConversations(json)
        }
        self.stopLoading('conversationUserConversations')
      })
    }
  }))
  .actions((self) => ({
    attendedTopic: (topicId) => {
      return self.userConversations.find((uc) => uc.topicId === topicId)
    }
  }))

export interface IConversationUserConversations extends Instance<typeof ConversationUserConversations> {}
export default ConversationUserConversations
