import { assign, omit, keys } from 'lodash'

import * as types from '../actions/actionTypes'
import initialState from './initialState'

export const surveyQuestions = (state = initialState.surveyQuestions, action) => {
  switch (action.type) {
    case types.LOAD_SURVEY_QUESTIONS_FAILED:
      return assign({}, state, {
        error: action.data,
        loading: false
      })

    case types.SURVEY_QUESTIONS_LOADING:
      return assign({}, state, {
        error: null,
        loading: true
      })

    case types.LOAD_SURVEY_QUESTIONS_SUCCESS:
      return assign({}, state, {
        error: null,
        loading: false,
        list: assign({}, state.list, action.surveyQuestions)
      })

    case types.LOAD_NEW_SURVEY_QUESTIONS_SUCCESS:
      return assign({}, state, {
        error: null,
        loading: false,
        list: assign({}, action.surveyQuestions)
      })

    case types.DELETE_SURVEY_QUESTION_SUCCESS:
      return assign({}, state, {
        error: null,
        loading: false,
        list: omit(state.list, keys(action.surveyQuestions))
      })

    default:
      return state
  }
}

export default surveyQuestions
