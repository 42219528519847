import { createStructuredSelector, createSelector } from 'reselect'
import { isEmpty, filter, find, assign, keys, includes, isUndefined } from 'lodash'

import { getCurrentUserId, getCurrentUser } from './currentUserGetters'
import { getConversations, getErrorConversation, getLoadingConversation } from './conversationGetters'
import { getTopics, getPartnerTopicSlugs } from './topicGetters'
import { getPendingUserInvitationRequests } from './conversationInvitationRequestGetters'
import { getApprovedConversationInvitations } from './conversationInvitationGetters.js'
import { getUserConversations } from './userConversationGetters'
import { getSurveyQuestions } from './surveyQuestionGetter'
import { getActions } from './actionGetters'

const getConversationId = (state, props) => {
  return props.match?.params?.conversationId || props?.data?.conversationId
}

export const getConversation = createSelector(getConversations, getConversationId, (conversations, id) => {
  let conversation = find(conversations, (d) => d.id === parseInt(id, 10))
  if (isUndefined(conversation)) return

  return assign({}, conversation, {
    premiumEvent: conversation.eventType === 'premium_event'
  })
})

const hasPendingUserConversationInvitationRequest = createSelector(getConversation, getPendingUserInvitationRequests, (conversation, userPendingInvitationRequests) => {
  return !isEmpty(filter(userPendingInvitationRequests, (upir) => (conversation ? upir.conversationId === conversation.id : false)))
})

const hasApprovedConversationInvitation = createSelector(getConversation, getApprovedConversationInvitations, (conversation, approvedConversationInvitations) => {
  return !isEmpty(filter(approvedConversationInvitations, (invitation) => (conversation ? invitation.conversationId === conversation.id : false)))
})

export const getIsGuest = createSelector(getUserConversations, getConversationId, getCurrentUserId, (userConversations, conversationId, userId) => {
  if (!userId) return false
  return includes(keys(userConversations), conversationId)
})

export default createStructuredSelector({
  currentUserId: getCurrentUserId,
  currentUser: getCurrentUser,
  isCurrentUserLoading: (state) => state.isCurrentUserLoading, // TODO move this to a getter file
  conversations: getConversations,
  conversation: getConversation,
  error: getErrorConversation,
  loading: getLoadingConversation,
  topics: getTopics,
  partnerTopicSlugs: getPartnerTopicSlugs,
  pendingInvitationRequest: hasPendingUserConversationInvitationRequest,
  approvedInvitation: hasApprovedConversationInvitation,
  isGuest: getIsGuest,
  questions: getSurveyQuestions,
  actions: getActions
})
