import React, { useState } from 'react'
import { truncate } from 'lodash'

import { observer } from 'mobx-react'
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import { useParamConversation } from '../../../Providers'
import { suitableDietsDecorator } from '../../../../decorators/conversationDecorator'

const FoodAndDrinks = observer(() => {
  const classes = useStyles()
  const { conversation, conversationLoading } = useParamConversation()
  const showBanner = conversation && (conversation.isGuest() || conversation.isHost)
  const host = conversation?.host
  const [expandHowItWorks, setexpandHowItWorks] = useState(false)
  if (!conversation || conversation.isVirtual || !conversation.suitableDiets) return <></>
  return (
    <>
      <Typography variant='subtitle1' className='thin-bottom'>
        Food and Drink
      </Typography>
      <Typography variant='body2' className='fat-bottom'>
        <div>{suitableDietsDecorator(conversation)}</div>
      </Typography>
    </>
  )
})

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '4em auto 0 auto',
      minHeight: 'calc(100vh - 22em)',
      color: '#000000',
      maxWidth: '50rem'
    }
  })
)

export default FoodAndDrinks
