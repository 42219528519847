import React from 'react'
import GoogleOneTapLogin from 'react-google-one-tap-login'
import { Button } from '@material-ui/core'
import { useAuth } from '../../Providers'

export default function GoogleLogin({ label, style }: { label?: string; style?: React.CSSProperties }) {
  const { loginWithGoogle } = useAuth()

  const handleLogin = () => {
    fetch('/removeCookie').then((resp) => {
      window.google.accounts.id.prompt()
    })
  }

  return (
    <div>
      <Button
        onClick={handleLogin}
        color='primary'
        size='large'
        variant='contained'
        style={{
          ...{
            marginTop: '1em',
            width: '265px',
            height: '48px',
            fontStyle: 'normal',
            fontWeight: 800,
            fontSize: '18px',
            lineHeight: '26px',
            backgroundColor: '#4285F4',
            color: '#ffffff'
          },
          ...style
        }}
      >
        {label || 'Sign in with Google'}
      </Button>
      <GoogleOneTapLogin
        onError={(error) => console.error({ error })}
        onSuccess={(response) => loginWithGoogle(response)}
        googleAccountConfigs={{ client_id: '690853729375-rs8b0j2tgautgsl67v311604okkv5bfl.apps.googleusercontent.com' }}
      />
    </div>
  )
}
