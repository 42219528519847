import React, { useContext, useState } from 'react'
import { assign } from 'lodash'
import { FacebookLogin } from 'react-facebook-login-component'
import i18next from '../../../i18n'
import { TextFieldControl } from '../../utils'
import { required, email } from '../../validations/fieldLevelValidations'
import { useForm } from 'react-hook-form'
import { Button, Card, CircularProgress, createStyles, Divider, Grid, makeStyles, Typography, Link, Menu, MenuItem } from '@material-ui/core'
import { AuthContext } from '../../Providers/AuthProvider'
import { ConversationContext } from '../../Providers/ConversationProvider'
import { TopicContext } from '../../Providers/TopicProvider'
import ResetPassword from '../../password-resets/CreateContainer'
import { useTranslation } from 'react-i18next'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { useAuth } from '../../Providers'
import LoadingAnimation from '../../shared/LoadingAnimation'
import { verifySsoEmail } from '../../../actions/sessionActions'
import GoogleLogin from './GoogleLogin'

interface Props {
  title?: string
  onLogin?()
  onSignUp?()
  hosting?: boolean
  sso?: boolean
}
const SsoLoginOption = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <>
      <Button aria-controls='simple-menu' aria-haspopup='true' onClick={handleClick}>
        Sign in with SSO
      </Button>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        getContentAnchorEl={null}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <MenuItem style={{ minWidth: '7.5rem' }} alignItems='center'>
          <a href={window.OKTA_LOGIN_URL} style={{ textAlign: 'center' }}>
            Okta
          </a>
        </MenuItem>
      </Menu>
    </>
  )
}

const LoginForm = ({ onLogin, title, onSignUp, hosting, sso = false }: Props) => {
  const { handleSubmit, errors, formState, control, watch } = useForm({ mode: 'onBlur', shouldUnregister: false })
  const { isDirty, isSubmitting, isValid } = formState
  const { loaded } = useAuth()

  const { logInUser, loginWithFacebook } = useContext(AuthContext)
  const { conversation } = useContext(ConversationContext)
  const { topic } = useContext(TopicContext)
  const [loading, setloading] = useState(false)
  const [showError, setshowError] = useState(false)
  const [ssoLogin, setSsoLogin] = useState(false)
  const [nonSsoLogin, setNonSsoLogin] = useState(false)
  const [ssoEmailChecking, setSsoEmailChecking] = useState(false)
  const [emailChecked, setEmailChecked] = useState(false)
  const [ssoName, setSsoName] = useState()
  const { t, i18n } = useTranslation()

  const loginComplete = () => {
    setloading(false)
    onLogin && onLogin()
  }

  const handleSave = handleSubmit((data: any, e) => {
    setloading(true)
    if (ssoLogin && ssoName === 'google') {
      window.google.accounts.id.prompt()
      return
    }
    if (ssoLogin && ssoName) {
      location.href = window.OKTA_LOGIN_URL
      return
    }

    if (!nonSsoLogin && !ssoLogin) {
      return handleEmailCheck().then(() => {
        setloading(false)
      })
    }
    setshowError(false)
    logInUser(data, { locale: i18n.language })
      .then(loginComplete)
      .catch(() => {
        setloading(false)
        setshowError(true)
      })
  })

  const builtValues = (data) => {
    return assign({}, data, {
      conversationRoleInterest: { hosting },
      signedUpTopicId: topic?.id,
      signedUpConversationId: conversation?.id,
      locale: i18next.language
    })
  }

  const linkClass = 'link-text'

  const classes = Styles()

  const handleEmailCheck = () => {
    if (!ssoEmailChecking) {
      setSsoEmailChecking(true)
      return verifySsoEmail({ email: watch('email') })
        .then((s) => {
          setSsoEmailChecking(false)
          setEmailChecked(true)
          if (s.sso) {
            window.location.href = s.sso
            // setNonSsoLogin(false)
            // setSsoLogin(true)
          } else {
            setSsoName(undefined)
            setNonSsoLogin(true)
            setSsoLogin(false)
          }
        })
        .catch(() => {
          setSsoEmailChecking(false)
          setEmailChecked(true)

          setSsoName(undefined)
          setNonSsoLogin(true)
          setSsoLogin(false)
        })
    }
    return Promise.resolve()
  }

  const handleChangeEmail = () => {
    setNonSsoLogin(false)
    setSsoLogin(false)
    setSsoName(undefined)
    setEmailChecked(false)
  }

  // const title = conversationInviteLink ? t('auth.inviteLogin') : t('auth.login')
  if (!loaded) {
    return <LoadingAnimation />
  }
  // const validEmail = loading || !errors.email || !watch('email')
  // const nonSsoLogin = watch('email') && !ssoLogin && validEmail
  return (
    <div className={classes.root}>
      <h3 className={classes.header}>{title || t('auth.login')}</h3>
      <div className='align-center'>
        <p>
          {t('auth.noAccount')}&nbsp;
          <a className={linkClass} onClick={onSignUp}>
            {t('auth.signupHere')}
          </a>
        </p>
      </div>
      {showError && (
        <div style={{ backgroundColor: '#FAEBEB', padding: '.5rem', borderRadius: '.5rem' }}>
          <Typography variant='body1' color='error' align='center' component='div' style={{ display: 'flex', justifyContent: 'center' }}>
            <HighlightOffIcon style={{ fontSize: '1rem', marginRight: '.25rem', marginTop: '.25rem' }} />
            Sorry, the email or password you entered is incorrect.
          </Typography>
        </div>
      )}

      <form onSubmit={handleSave}>
        <TextFieldControl
          label={t('common.email')}
          // rules={{ validate: { required, email } }}
          name='email'
          control={control}
          error={errors && errors.email}
          className='fat-top'
          disabled={loading || emailChecked}
          rules={{
            validate: {
              required,
              email
              // uniqness: async (val) => {
              //   return verifySsoEmail({ email: val }).then((s) => {
              //     if (s) {
              //       setSsoLogin(true)
              //       return `Sign in with SSO using ${s.sso}`
              //     } else {
              //       setSsoLogin(false)
              //     }
              //   })
              // }
            }
          }}
        />
        {nonSsoLogin && (
          <>
            <TextFieldControl
              label={t('auth.password')}
              rules={{ validate: { required } }}
              name='password'
              control={control}
              type='password'
              error={errors && errors.password}
              className='fat-top'
              disabled={loading}
            />
          </>
        )}
        <div className={classes.actionContainer}>
          {/* <Button size='large' disabled={!isDirty || isSubmitting || loading} type='submit' color='primary' variant='contained' className={classes.loginButton}>
            {!loading ? (
              <>
                {nonSsoLogin && t('auth.login')}
                {!nonSsoLogin && !ssoName && 'Next'}
                {ssoLogin && ssoName && `Login with ${ssoName}`}
              </>
            ) : (
              <CircularProgress color='secondary' />
            )}
          </Button>
          {!loading && ((ssoLogin && ssoName) || emailChecked) && (
            <Button size='large' variant='contained' className={classes.loginButton} onClick={handleChangeEmail}>
              Use Different Email
            </Button>
          )} */}
          <LoginAction {...{ isDirty, isSubmitting, loading, nonSsoLogin, ssoName, ssoLogin, emailChecked, handleChangeEmail }} />
        </div>
      </form>
      <div className={classes.forgotPassword}>
        <Grid container justify='center'>
          <Grid item xs={12} sm={6}>
            <ResetPassword />
          </Grid>
          {/* {sso && (
            <Grid item xs={12} sm={6}>
              <SsoLoginOption />
            </Grid>
          )} */}
        </Grid>
      </div>

      <div className='align-center'>
        <div className={classes.divider}>
          <Grid container justify='center' alignItems='center' spacing={1}>
            <Grid item xs={5}>
              <Divider />
            </Grid>
            <Grid item>{t('common.or')}</Grid>
            <Grid item xs={5}>
              <Divider />
            </Grid>
          </Grid>
        </div>
        <div className='facebook-login-btn'>
          <Facebook {...{ loginComplete, hosting }} />
        </div>
      </div>
      <div className='align-center reg-top'>
        <div className={classes.divider}>
          <Grid container justify='center' alignItems='center' spacing={1}>
            <Grid item xs={5}>
              <Divider />
            </Grid>
            <Grid item>{t('common.or')}</Grid>
            <Grid item xs={5}>
              <Divider />
            </Grid>
          </Grid>
        </div>
        <GoogleLogin />
      </div>
    </div>
  )
}

const LoginAction = ({ isDirty, isSubmitting, loading, nonSsoLogin, ssoName, ssoLogin, emailChecked, handleChangeEmail }) => {
  const classes = Styles()
  const { t } = useTranslation()
  return (
    <>
      <Button size='large' disabled={!isDirty || isSubmitting || loading} type='submit' color='primary' variant='contained' className={classes.loginButton}>
        {!loading ? (
          <>
            {nonSsoLogin && t('auth.login')}
            {!nonSsoLogin && !ssoName && 'Next'}
            {ssoLogin && ssoName && `Login with ${ssoName}`}
          </>
        ) : (
          <CircularProgress color='secondary' />
        )}
      </Button>
      {!loading && ((ssoLogin && ssoName) || emailChecked) && (
        <Button size='large' variant='contained' className={classes.loginButton} onClick={handleChangeEmail}>
          Use Different Email
        </Button>
      )}
    </>
  )
}
const Facebook = ({ loginComplete, hosting }) => {
  const { loaded } = useAuth()

  const { loginWithFacebook } = useContext(AuthContext)
  const { conversation } = useContext(ConversationContext)
  const { topic } = useContext(TopicContext)

  const { t, i18n } = useTranslation()

  const builtValues = (data) => {
    return assign({}, data, {
      conversationRoleInterest: { hosting },
      signedUpTopicId: topic?.id,
      signedUpConversationId: conversation?.id,
      locale: i18n.language
    })
  }

  const responseFacebook = (response) => {
    loginWithFacebook(builtValues(response)).then(loginComplete)
  }

  if (!loaded) {
    return <LoadingAnimation />
  }

  return (
    <FacebookLogin
      socialId={process.env.REACT_APP_FACEBOOK_APP_ID}
      language={i18n.language}
      scope='public_profile, email'
      responseHandler={responseFacebook}
      xfbml={true}
      version='v2.8'
      buttonText={t('auth.fbLogin')}
      fields='id, email, first_name, last_name, picture'
    />
  )
}

const Styles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: '2em'
    },
    header: {
      textAlign: 'center',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '32px'
    },
    actionContainer: {
      maxWidth: '304px',
      margin: '1em auto',
      textAlign: 'center'
    },
    divider: {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '21px',
      color: '#6D6E71',
      paddingBottom: '1em'
    },
    loginButton: {
      marginTop: '1em',
      width: '265px',
      height: '48px',
      fontStyle: 'normal',
      fontWeight: 800,
      fontSize: '18px',
      lineHeight: '26px'
    },
    forgotPassword: {
      textAlign: 'center',
      margin: '1em 0'
    }
  })
)

export default LoginForm
