import { types, Instance } from 'mobx-state-tree'
import { MetaImage } from '../base'

export const Likable = types.model({
  id: types.identifierNumber,
  likableId: types.integer,
  likableType: types.string,
  kind: types.string,
  name: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
  icon: types.maybeNull(MetaImage),
  cardBanner: types.maybeNull(types.string),
  themeColor: types.maybeNull(types.string),
  partnerName: types.maybeNull(types.string),
  content: types.maybeNull(types.string)
})

export default Likable
export interface ILikable extends Instance<typeof Likable> {}
