import React, { useCallback, useEffect, useState } from 'react'
import { IconButton, Tooltip } from '@material-ui/core'
import { ILikable } from '../../../store/models/Auth/Likable'
import { palette } from '../../../themes'
import { useAuth } from '../../Providers'

type Props = {
  likableType: 'Topic' | 'Conversation' | 'Partner' | 'Track' | 'Story'
  likableId: number
}

export default function FavButton({ likableType, likableId }: Props) {
  const { user, favorite, unfavorite, showLogin } = useAuth()
  const likes = user?.likes
  const [liked, setLiked] = useState<undefined | ILikable>(undefined)

  const checkLike = useCallback(() => {
    const likable = likes?.find((f) => f.likableId === likableId && f.likableType === likableType && f.kind === 'bookmark')
    if (likable) {
      setLiked(likable)
    } else {
      setLiked(undefined)
    }
  }, [likes, likableId, likableType])
  useEffect(() => {
    checkLike()
  }, [checkLike])

  const handleFavorite = (e) => {
    e.stopPropagation()
    if (!user) {
      showLogin()
    } else {
      favorite(likableId, likableType).then(checkLike)
    }
  }
  const handleUnfavorite = (e) => {
    e.stopPropagation()
    liked?.id && unfavorite(liked.id).then(checkLike)
  }

  return (
    <div>
      {liked ? (
        <Tooltip title='Unfavorite'>
          <IconButton aria-label='Unfavorite' onClick={handleUnfavorite}>
            <i className='fas fa-heart' style={{ color: palette.yellow500 }}></i>
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title='Favorite'>
          <IconButton aria-label='favorite' onClick={handleFavorite}>
            <i className='far fa-heart'></i>
          </IconButton>
        </Tooltip>
      )}
    </div>
  )
}
