import React, { Component } from 'react'
import { Link, withRouter, RouteComponentProps } from 'react-router-dom'

import { flow } from 'lodash'
import { inject, observer } from 'mobx-react'
import { IStore } from '../../../../store/models'
import DataTable, { IColData, ValueLabel } from '../../../shared/DataTableFilterable'
import { IUser } from '../../../../store/models/User'
import { IFilterable } from '../../../../store/models/helpers/Filterable'
import AttendeesActions from '../partials/AttendeesActions'

interface IProps {}
class GuestsTable extends Component<InjectedProps & IProps & RouteComponentProps, null> {
  render() {
    const { user, filterable, items, loading } = this.props

    const columnData: IColData[] = [
      { name: 'id', title: 'ID', width: 90, sortable: true },
      { name: 'firstName', title: 'First Name', width: 150, sortable: true },
      { name: 'lastName', title: 'Last Name', width: 150, sortable: true },
      ...(user?.isPartnerAdmin() ? [{ name: 'profile', title: 'Profile', width: 150 }] : []),
      ...(user?.isSuperAdmin() ? [{ name: 'role', title: 'Role', width: 150, sortable: true }] : []),
      { name: 'email', title: 'Email', width: 250, sortable: true },
      { name: 'city', title: 'City', width: 150, sortable: true },
      { name: 'stateLevel', title: 'State', width: 150, sortable: true },
      { name: 'country', title: 'Country', width: 220, sortable: true },
      ...(user?.isSuperAdmin() ? ([{ name: 'actions', title: 'Actions', width: 220, align: 'center' }] as IColData[]) : [])
    ]

    return (
      <DataTable
        data={items}
        columns={columnData}
        loading={loading}
        filterable={filterable}
        rightColumns={['actions']}
        height={'calc(100vh - 23em)'}
        searchPlaceholder='Search (First Name, Last Name, Email, City, State)'
        getCellValue={(row: IUser, columnName: string) => {
          switch (columnName) {
            case 'profile':
              return (
                <ValueLabel
                  value={
                    <Link rel='noopener' title='User Profile' to={`/users/${row.id}`} target='blank' color='primary'>
                      {`/users/${row.id}`}
                    </Link>
                  }
                />
              )
            case 'actions':
              return <AttendeesActions attendee={row} />
            default:
              return <ValueLabel value={row[columnName]} />
          }
        }}
      />
    )
  }
}

interface InjectedProps {
  filterable: IFilterable
  items: IUser[]
  loading: boolean
  user?: IUser
}

export default flow(
  withRouter,
  observer,
  inject<InjectedProps, IProps>(
    ({
      mst: {
        auth: { user },
        users
      }
    }: {
      mst: IStore
    }) => {
      return {
        user,
        filterable: users,
        items: users.list,
        loading: users.loadingIds.includes('users_table')
      }
    }
  )
)(GuestsTable)
