import React, { useEffect, useState } from 'react'
import { flow, startCase } from 'lodash'

import Dialog from '../../../DialogWrapper'
import { inject, observer } from 'mobx-react'
import { Button, Chip, Grid, IconButton, Paper, Tooltip, Link as MuiLink, makeStyles, createStyles, Theme } from '@material-ui/core'
import DataTable, { IColData, ValueLabel } from '../../../shared/DataTableFilterable'
import { Link, withRouter, RouteComponentProps } from 'react-router-dom'
import moment from 'moment'
import { IConversation, IFilterable, IStore, ITrack, IUser } from '../../../../store'
import { Label } from '@material-ui/icons'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      backgroundColor: '#C4E6D8',
      color: '#212121'
    }
  })
)

const AddConversationDialog = ({ track, loading, items, filterable }: Props & InjectedProps & RouteComponentProps) => {
  const classes = useStyles()
  const columnData: IColData[] = [
    { name: 'id', title: 'ID', width: 90, sortable: true },
    { name: 'title', title: 'Title', width: 250, sortable: true },
    { name: 'startsAt', title: 'Date of Conversation', width: 150, sortable: true },
    { name: 'time', title: 'Time', width: 80 },
    { name: 'guests', title: 'Registered Attendees', align: 'right', width: 120, icon: 'info-circle', hint: 'Guests and Hosts' },
    { name: 'guestsFull', title: ' ', align: 'left', width: 50 },

    { name: 'host', title: 'Host Name', width: 150 },
    { name: 'venueType', title: 'Venue', sortable: true },
    { name: 'guestPolicy', title: 'Guest Policy', sortable: true },
    { name: 'pendingInvitationsCount', title: 'Pending Requests', sortable: true },

    { name: 'phoneNumber', title: 'Host Phone' },
    { name: 'email', title: 'Host Email', width: 230 },

    { name: 'link', title: 'Link', width: 250 },

    { name: 'partnerName', title: 'Customer Name' },

    { name: 'eventType', title: 'Type', sortable: true },
    { name: 'hidden', title: 'Visibility', sortable: true },

    { name: 'neighborhood', title: 'Neighborhood', align: 'left', width: 150 },
    { name: 'city', title: 'City', align: 'left', width: 150 },
    { name: 'stateLevel', title: 'State', align: 'left', width: 100 },
    { name: 'country', title: 'Country', align: 'left', width: 150 },
    { name: 'actions', title: 'Actions', width: 100 }
  ]
  const [open, setopen] = useState(false)

  useEffect(() => {
    if (track && track.partnerId) {
      filterable.setPartnerId(track.partnerId)
    }
    return () => {
      filterable.setPartnerId(undefined)
    }
  }, [filterable, track])

  return (
    <>
      <Button variant='contained' color='primary' size='small' startIcon={<i className='fas fa-tasks'></i>} onClick={() => setopen(true)}>
        Manage Conversations
      </Button>
      <Dialog dialog={{ closeDialog: () => setopen(false) }} open={open} name='emailHost' maxWidth='md' title={'Manage Conversations'}>
        <section className='pane big-top-padding big-bottom-padding'>
          <div className='row reg-bottom'>
            <div className='col-xs'>
              <Paper elevation={0}>
                <DataTable
                  data={items}
                  columns={columnData}
                  loading={loading}
                  filterable={filterable}
                  rightColumns={['actions']}
                  searchPlaceholder='Search (Conversation Topic, Customer, Slug, Email, Phone, City, State, Country)'
                  noDataMsg='No results found.'
                  height={'calc(100vh - 29em)'}
                  // eslint-disable-next-line complexity
                  getCellValue={(row: any, columnName: string) => {
                    switch (columnName) {
                      case 'title':
                        return (
                          <>
                            <ValueLabel
                              value={
                                <Link rel='noopener' title={row.title} to={`/${row.slug}/conversations/${row.id}`} target='blank' color='primary'>
                                  {row.title}
                                </Link>
                              }
                            />

                            <AddedLabel conversation={row} track={track} />
                          </>
                        )
                      case 'startsAt':
                        return <ValueLabel value={row.startsAt && moment(row.startsAt).format('L')} />
                      case 'time':
                        return <ValueLabel value={row.startsAt && moment(row.startsAt).format('LT')} />
                      case 'guests':
                        return <ValueLabel value={`${row.attendeeCount}/${row.maxAttendees}`} />
                      case 'guestsFull':
                        return <ValueLabel value={row.attendeeCount === row.maxAttendees ? <Chip size='small' label='Full' /> : ' '} />
                      case 'host':
                        return row.host && <ValueLabel value={`${row.host.firstName} ${row.host.lastName}`} />
                      case 'phoneNumber':
                        return row.host && <ValueLabel value={row.host.phoneNumber} />
                      case 'email':
                        return row.host && <ValueLabel value={row.host.email} />
                      case 'venueType':
                        return <ValueLabel value={row.venueType === 'virtual' ? 'Online' : row.venueType === 'other' ? 'Office / Other' : startCase(row.venueType)} />
                      case 'eventType':
                        return <ValueLabel value={row.eventTypeDisplay} />
                      case 'hidden':
                        return <ValueLabel value={row.visibility} />
                      case 'guestPolicy':
                        return <ValueLabel value={row.guestPolicyDisplay} />
                      case 'link':
                        return (
                          <ValueLabel
                            value={
                              <Link rel='noopener' to={`/${row.slug}/conversations/${row.id}`} target='blank' color='primary'>
                                {`/${row.slug}/conversations/${row.id}`}
                              </Link>
                            }
                          />
                        )
                      case 'actions':
                        return (
                          <ConversationActions
                            track={track}
                            conversation={row}
                            onClick={(e, convo, action) => {
                              action === 'add' && track.addConversation(convo.id)
                              action === 'remove' && track.removeConversation(convo.id)
                            }}
                          />
                        )
                      default:
                        return <ValueLabel value={row[columnName]} />
                    }
                  }}
                />
              </Paper>
            </div>
          </div>
        </section>
      </Dialog>
    </>
  )
}

const AddedLabel = observer(({ track, conversation }) => {
  const classes = useStyles()
  const isAdded = !!track.conversations.find((c) => c.id === conversation.id)
  return isAdded ? <Chip label='Added' size='small' className={classes.chip} /> : <></>
})

const ConversationActions = observer(({ track, conversation, onClick }: { track: ITrack; conversation: IConversation; onClick?(e, m: IConversation, action: string) }) => {
  const isAdded = !!track.conversations.find((c) => c.id === conversation.id)

  return (
    <Grid container direction='row' justify='space-around' alignItems='center'>
      <Tooltip title='View Conversation'>
        <MuiLink href={conversation.conversationLink} target='_blank'>
          <IconButton size='small'>
            <i className='fas fa-external-link opacity-8'></i>
          </IconButton>
        </MuiLink>
      </Tooltip>

      {!isAdded && (
        <Tooltip title='Add Conversation'>
          <IconButton size='small' onClick={(e) => onClick && onClick(e, conversation, 'add')}>
            <i className='fas fa-plus opacity-8'></i>
          </IconButton>
        </Tooltip>
      )}
      {isAdded && (
        <Tooltip title='Remove Conversation'>
          <IconButton size='small' onClick={(e) => onClick && onClick(e, conversation, 'remove')}>
            <i className='fas fa-minus opacity-8'></i>
          </IconButton>
        </Tooltip>
      )}
    </Grid>
  )
})

interface Props {
  track: ITrack
}
interface InjectedProps {
  items: IConversation[]
  loading: boolean
  filterable: IFilterable
  user?: IUser
}

export default flow(
  observer,
  inject<InjectedProps, Props>(({ mst: { conversations, auth } }: { mst: IStore }) => {
    return {
      user: auth.user,
      filterable: conversations,
      items: conversations.list,
      loading: conversations.loadingIds.includes('conversations_table')
    }
  }),
  withRouter
)(AddConversationDialog)
