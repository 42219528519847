import React, { useCallback, useState } from 'react'
import Button from '@material-ui/core/Button'
import FontIcon from '@material-ui/core/Icon'
import { flow } from 'lodash'
import { Link } from 'react-router-dom'

import { observer } from 'mobx-react'
import { useForm, useFieldArray, Control, FieldErrors } from 'react-hook-form'
import { getSnapshot, isStateTreeNode } from 'mobx-state-tree'
import { TextFieldControl, RadioGroupControl } from '../utils'
import { IStore } from '../../store/models'
import { inject } from 'mobx-react'
import { useEffect } from 'react'
import { ButtonGroup, Fade, makeStyles, Card, Typography, Grid, IconButton, Tooltip } from '@material-ui/core'

import { Delete } from '@material-ui/icons'
import DraggableItem from '../utils/DraggableItem'
import { DragIndicator } from '@material-ui/icons'
const SurveyQuestionForm = (props: FIXME) => {
  const { question, topic, action, downloadingSurveyQuestionIds, downloadedSurveyQuestionIds, downloadCsv, onDirty, closeForm, enableScoring } = props

  const defaultValues = {
    question: question?.question?.toString() || '',
    description: question?.description?.toString() || '',
    questionType: question?.questionType || 'text',
    options: question?.options || [
      {
        answer: '',
        value: 100
      }
    ]
  }

  const { handleSubmit, errors, formState, control, reset, watch, setValue } = useForm({ mode: 'onBlur', defaultValues })
  const { isDirty, isSubmitting } = formState
  const [questionType, setQuestionType] = useState(question?.questionType || 'text')
  const [isDragged, setIsDragged] = useState(false)
  useEffect(() => {
    question ? question.setDirty(isDirty) : onDirty && onDirty(isDirty)

    if (isDragged) {
      //DINO HELP!.  SET FORM DIRTY HERE!!!!
      // console.log({ isDragged, isDirty })
      setIsDragged(false)
    }
  }, [question, isDirty, onDirty, isDragged])

  const handleSave = handleSubmit((data: any, e) => {
    const options = data.options.map((option, index) => ({ ...option, value: parseInt(option.value, 10) }))
    const updateData = { ...(action === 'update' ? (isStateTreeNode(question) ? getSnapshot(question) : question || {}) : {}), ...data, options }
    const afterSave = ({ savedData = undefined } = {}) => {
      reset(action === 'create' ? undefined : savedData, {
        errors: false, // errors will not be reset
        dirtyFields: false, // dirtyFields will not be reset
        isDirty: false, // dirty will not be reset
        isSubmitted: true,
        touched: false,
        isValid: true,
        submitCount: true
      })
      closeForm && closeForm()
    }
    action === 'create' && topic.createSurveyQuestion(updateData).then(afterSave)
    action === 'update' && question?.updateSurveyQuestion(updateData).then(afterSave)
  })

  const [exported, setexported] = useState(false)

  const ExportButton = () => {
    if (action === 'update') {
      return (
        <Button
          // className='reg-right'
          startIcon={<FontIcon className='far fa-file-export header-18 opacity-8' />}
          disabled={downloadingSurveyQuestionIds.includes(question.id) || exported}
          onClick={() => {
            if (question && downloadedSurveyQuestionIds.includes(question.id)) {
              downloadCsv(question.id)
            } else {
              question && topic.createSurveyQuestionCsvExport(question.id)
            }
            setexported(true)
          }}
          // variant='contained'
        >
          Export CSV Results
        </Button>
      )
    } else return null
  }

  const ExportedCsv = () => {
    if (exported) {
      return (
        <div className='row no-gutters'>
          <div className='col-xs-12 center-xs no-gutters'>
            <div className='alert-bar alert-bar-notice thin-top header-16'>
              <p className='flat-top flat-bottom'>
                {"Won't be long! Your Csv will be available on your 'My Downloads' Page "}
                <Link className='dark-grey-text underline' to='/downloads'>
                  here
                </Link>
                .
              </p>
              <p className='flat-top flat-bottom'>{"We'll also send you an email when it's finished."}</p>
            </div>
          </div>
        </div>
      )
    } else return null
  }

  return (
    <form onSubmit={handleSave}>
      <div className='reg-top'>
        <TextFieldControl name='question' control={control} defaultValue={question?.question?.toString() || ''} error={errors && errors.question} label={'Question'} />
      </div>
      <div className='reg-top'>
        <TextFieldControl name='description' control={control} defaultValue={question?.description?.toString() || ''} error={errors && errors.description} label={'Description'} />
      </div>

      {/* question type selector */}
      <Grid container spacing={4} className='reg-top'>
        {/* left */}
        <Grid item xs={4}>
          <div style={{ minHeight: '40px' }}>
            <Typography variant='caption'>Choose the type of answer you want to collect for this question</Typography>
          </div>
          <RadioGroupControl
            name='questionType'
            control={control}
            defaultValue={questionType}
            error={errors && errors.questionType}
            label='Answer Type'
            options={[
              { value: 'text', label: 'text' },
              { value: 'select', label: 'multiple-choice' }
            ]}
            className='reg-bottom'
            onChange={setQuestionType}
          />
        </Grid>

        {/* multiple-choice */}
        <Grid item xs={8}>
          <Fade in={questionType === 'select'}>
            <div>
              {/* <DndProvider backend={HTML5Backend}> */}
              <QuestionOptions control={control} errors={errors} enableScoring={topic?.enableSurveyScoring} setIsDragged={setIsDragged} />
              {/* </DndProvider> */}
            </div>
          </Fade>
        </Grid>
      </Grid>

      {/* buttons */}
      <div className='row no-gutters'>
        <div className='col-xs-12 no-gutters align-right xtra-fat-top'>
          <ExportButton />
          <ButtonGroup className='reg-left'>
            {action === 'update' && (
              <Button variant='contained' onClick={() => topic.deleteQuestion(question.id)} className='red-text'>
                Remove
              </Button>
            )}

            <Button variant='contained' onClick={closeForm}>
              Cancel
            </Button>

            <Button disabled={!isDirty || isSubmitting} type='submit' color='secondary' variant='contained'>
              {action === 'create' ? 'Add question' : 'Update'}
            </Button>
          </ButtonGroup>
          <ExportedCsv />
        </div>
      </div>
    </form>
  )
}

type QuestionOptionsProps = {
  control: Control
  errors: FieldErrors
  enableScoring: boolean
  setIsDragged: (isDragged: boolean) => void
}
interface QuestionOptionProps {
  control: Control
  enableScoring: boolean
  index: number
  errors: any
  remove: (index: number) => void
  option: Partial<Record<'id', number> & { value: string; weight: number }>
  moveItem: (dragIndex: number, hoverIndex: number) => void
}

const QuestionOptions = observer(({ control, errors, enableScoring, setIsDragged }: QuestionOptionsProps) => {
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'options'
  })

  const moveItem = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const draggedField = fields[dragIndex]
      const newFields = [...fields]
      newFields.splice(dragIndex, 1)
      newFields.splice(hoverIndex, 0, draggedField)

      setIsDragged(true)
      control.setValue('options', newFields)
    },
    [fields, control, setIsDragged]
  )

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant='body1'>Add options for the user to select from.</Typography>
      </Grid>
      <Grid item container xs={12} spacing={2}>
        {fields.map((option, index) => (
          <Grid item xs={12} key={`option_${index}`}>
            <DraggableItem index={index} id={index} moveItem={moveItem}>
              <QuestionOption control={control} enableScoring={enableScoring} index={index} errors={errors} remove={remove} option={option} />
            </DraggableItem>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12}>
        <Button startIcon={<i className='fa fa-plus opacity-6' />} onClick={() => append({ value: '', weight: 0 })} variant='contained' color='primary'>
          Add Option
        </Button>
      </Grid>
    </Grid>
  )
})

const QuestionOption = ({
  index,
  option,
  remove,
  errors,
  control,
  enableScoring
}: {
  answer?: string | null
  value?: number | null
  index: number
  remove: any
  errors: any
  control: any
  option: any
  enableScoring: boolean
}) => {
  const classes = useStyles()
  return (
    <Grid container className={classes.dragHandle}>
      <Grid item xs={11}>
        <Card elevation={0} style={{ padding: '0 .5rem' }}>
          <Grid container spacing={1} alignItems='center'>
            <Grid item xs={1}>
              <Tooltip title='Drag to reorder'>
                <DragIndicator style={{ color: '#ccc' }} />
              </Tooltip>
            </Grid>
            <Grid item xs={9}>
              <TextFieldControl
                name={`options[${index}].answer`}
                control={control}
                defaultValue={option.answer}
                error={errors && errors[`options[${index}]`]}
                label={`Option #${index + 1}`}
              />
            </Grid>
            {enableScoring && (
              <Grid item xs={2}>
                <TextFieldControl type='number' name={`options[${index}].value`} control={control} defaultValue={option.value} label='Value' />
              </Grid>
            )}
          </Grid>
        </Card>
      </Grid>

      <Grid item xs={1}>
        <IconButton aria-label='Remove option' onClick={() => remove(index)}>
          <Delete />
        </IconButton>
      </Grid>
    </Grid>
  )
}
export default flow(
  observer,
  inject<FIXME, FIXME>(
    ({
      mst: {
        downloads,
        downloads: { list }
      }
    }: {
      mst: IStore
    }) => {
      downloads.shouldLoad('my_downloads', downloads.loadFilter)
      return {
        downloadingSurveyQuestionIds: list.filter((d) => d?.parameters?.surveyQuestionId && !d?.csvFile).map((d) => d?.parameters?.surveyQuestionId),
        downloadedSurveyQuestionIds: list.filter((d) => d?.parameters?.surveyQuestionId && d?.csvFile).map((d) => d?.parameters?.surveyQuestionId),
        downloadCsv: (surveyQuestionId: number) => {
          const download = list.find((d) => d?.parameters?.surveyQuestionId === surveyQuestionId)
          download && download.csvFile && window.open(download.csvFile.replace(/dl=0/gi, 'dl=1'))
        }
      }
    }
  )
)(SurveyQuestionForm)

const useStyles = makeStyles((theme) => ({
  dragHandle: {
    cursor: 'grab'
  },
  isDragging: {
    cursor: 'grabbing',
    color: 'cyan'
  }
}))
