import { Button, createStyles, Grid, makeStyles } from '@material-ui/core'
import { flow } from 'lodash'
import { inject, observer } from 'mobx-react'
import React from 'react'
import { withRouter } from 'react-router'
import { connectAdminThemeV2 } from '../../../connectTheme'
import { IStore } from '../../../store'
import withTranslation from '../../hocs/withTranslation'
import LoadingAnimation from '../../shared/LoadingAnimation'
import Edit from './Edit'

const HostEdit = (props: any) => {
  const { conversation } = props
  const classes = Styles()
  if (!conversation) {
    return <LoadingAnimation />
  }
  return (
    <div className={classes.root}>
      <Grid container direction='row' justify='space-between' alignItems='center' className={classes.header}>
        <Grid item>{conversation.title && <h1>{conversation.title}</h1>}</Grid>
        <Grid item xs={12} md={12} lg={2} className={classes.viewPageContainer}>
          <Button
            rel='noopener'
            href={`/${conversation?.slug}/conversations/${conversation?.id}`}
            target='_blank'
            variant='contained'
            startIcon={<i className='far fa-external-link-square'></i>}
            className={classes.viewPageButton}
          >
            View Saved Page
          </Button>
        </Grid>
      </Grid>
      <Edit {...props} />
    </div>
  )
}

export default flow(
  withRouter,
  withTranslation,
  connectAdminThemeV2,
  observer,
  inject<FIXME, FIXME>(
    (
      {
        mst: {
          auth: { user, loaded },
          topics,
          conversations,
          dialogs: { showDialog }
        }
      }: { mst: IStore },
      {
        match: {
          params: { conversationId: conversationIdString }
        },
        history
      }
    ) => {
      const id = parseInt(conversationIdString, 10)

      const conversation = conversations.list.find((d) => d.id === id)
      !conversation && conversations.shouldLoad(id, () => conversations.loadById(id))

      const topic = conversation && topics.list.find((d) => d.slug === conversation.slug)
      conversation && !topic && topics.shouldLoad(conversation.slug, () => topics.loadBySlug(conversation.slug))

      if (loaded && conversation && !conversation?.canEdit) {
        history.push('/')
      }

      return {
        showPremiumEventTab: conversation?.isPremium && user?.isPartnerAdmin(),
        user,
        conversation,
        topic,
        showDialog
      }
    }
  )
)(HostEdit)

const Styles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: '2em'
    },
    header: {
      maxWidth: '50em',
      margin: '0 auto'
    },
    viewPageButton: {
      whiteSpace: 'nowrap'
    },
    viewPageContainer: {
      marginRight: '2em'
    }
  })
)
