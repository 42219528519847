import { Breadcrumbs, Button, Typography } from '@material-ui/core'
import { flow } from 'lodash'
import { inject } from 'mobx-react'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { IStore } from '../../../../store/models'
import SummaryContainer from '../partials/SummaryContainer'
import { IUser } from '../../../../store/models/User'
import AdminMetaTags from '../../../shared/AdminMetaTags'
import { Link } from 'react-router-dom'
import OrganizationForm from './Form'
import PageLoadingAnimation from '../../../shared/PageLoadingAnimation'
import ManagePartners from './ManagePartners'
import { IOrganization } from '../../../../store/models/Organization/Organization'

interface Props {
  organization?: IOrganization
  showManagePartners?: boolean
  user: IUser
}

const Edit = ({ organization, history, user }: Props & RouteComponentProps) => {
  const [managePartners, setManagePartners] = useState(false)

  return organization ? (
    <>
      <AdminMetaTags title='Organizations' pageType='Edit' slug={organization?.id.toString()} />

      <Breadcrumbs aria-label='breadcrumb'>
        <Link color='primary' to='/admin/settings'>
          Settings
        </Link>
        <Typography color='textPrimary'>Edit Organization</Typography>
      </Breadcrumbs>

      <SummaryContainer
        title={organization?.name}
        topRight={
          user?.isSuperAdmin() ? (
            <Button
              color={managePartners ? 'secondary' : 'default'}
              onClick={() => setManagePartners(!managePartners)}
              variant='contained'
              startIcon={<i className='far fa-project-diagram'></i>}
            >
              Manage Partners
            </Button>
          ) : undefined
        }
      >
        <div className='row'>
          <div className='col-xs-12 form-lg fat-top'>
            <Typography>AEXID: {organization.aexid}</Typography>
            <OrganizationForm organization={organization} page={'edit'} onSave={(data) => data && history.push('/admin/settings')} />
          </div>
        </div>

        <div className='fat-bottom'>
          <ManagePartners organization={organization} showPartnersSelector={managePartners} />
        </div>
      </SummaryContainer>
    </>
  ) : (
    <PageLoadingAnimation />
  )
}

export default flow(
  observer,
  inject<FIXME, FIXME>(
    (
      {
        mst: {
          auth: { user },
          organizations
        }
      }: {
        mst: IStore
      },
      {
        match: {
          params: { id }
        }
      }
    ) => {
      const organization = organizations.list.find((d) => d.id.toString() === id)
      const organizationLoading = organizations.loadingIds.includes(id)
      !organization && organizations.shouldLoad(id, () => organizations.loadById(id))

      return {
        user,
        organization,
        organizationLoading
      }
    }
  ),
  withRouter
)(Edit)
