import React from 'react'
import { Link } from 'react-router-dom'
import { flow } from 'lodash'
import Avatar from '../../../users/Avatar'
import InfiniteList from '../../../shared/InfiniteListMst'
import withTranslation, { WithTranslation } from '../../../hocs/withTranslation'
import { observer } from 'mobx-react'
import { IConversation } from '../../../../store/models/Conversation'
import LoadingAnimation from '../../../shared/LoadingAnimation'
import { IGuest } from '../../../../store/models/User'
import { Paper, Typography } from '@material-ui/core'

const GuestList = ({ conversation, t }: { conversation: IConversation } & WithTranslation) => {
  if (!conversation) return <LoadingAnimation />
  const { host, guests, showGuestList } = conversation
  const Item = ({ item: guest }: { item: IGuest }) => {
    return (
      <>
        {guest && (
          <div className='col-xs-12 col-sm-4 center-xs hover-item' style={{ minWidth: '7rem', paddingBottom: '.5rem', paddingTop: '.5rem' }}>
            <div className='box fat-bottom-xs'>
              <Link to={`/users/${guest.id}`}>
                <Avatar {...guest} size='primary' />
                <p className='header-20 flat-top '>{`${guest.firstName} ${guest.lastName}`}</p>
              </Link>
            </div>
          </div>
        )}
      </>
    )
  }

  const EmptyMessage = () => {
    return (
      <div>
        <p className='italic'>{t('conversation.emptyMessage')}</p>
      </div>
    )
  }

  return (
    showGuestList && (
      <Paper>
        <Typography className='reg-bottom'>Attendees ({conversation.guestCount})</Typography>
        <InfiniteList
          initQuery={conversation.loadGuests}
          query={(args: any) => {
            conversation.loadGuests(args, true)
          }}
          {...{ items: guests, itemsLoading: conversation.loadingIds.includes('guests') }}
          item={Item}
          emptyMessage={EmptyMessage}
          wrapperClass={'row'}
          height={'30rem'}
          hasMoreItems={conversation.hasMoreGuests}
        />
      </Paper>
    )
  )
}

export default flow(observer, withTranslation)(GuestList)
