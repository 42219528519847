import appAnalytics from '../analytics'
import { post, get } from './utils'

class AuthApi {
  static login(credentials, data) {
    return post(`/authentication`, {
      noToken: true,
      body: JSON.stringify({ auth: credentials, locale: data.locale })
    })
      .then((d) => {
        appAnalytics.event({
          category: 'User',
          action: 'Logged In'
        })
        return d
      })
      .then(({ json }) => {
        return json
      })
  }

  static getCurrentUser() {
    return get(`/authentication`, { noToken: true, headers: { AUTHORIZATION: `${localStorage.jwt}` } })
  }
}

export default AuthApi
