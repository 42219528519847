import { Grid, Paper, Typography, Button, IconButton, Icon, createStyles, makeStyles, Theme } from '@material-ui/core'
import { action } from 'mobx'
import React from 'react'

const ZoomConnected = () => {
  const classes = useStyles()
  return (
    <Grid container alignItems='center'>
      <Grid item xs={11}>
        <Paper variant='outlined' className={classes.root}>
          <Typography variant='subtitle1' className={classes.title}>
            Your zoom account is now connected
          </Typography>
          <Typography variant='body2' className={classes.description}>
            You can close this page
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default ZoomConnected

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2em',
      margin: '0'
    },
    actionButton: {
      backgroundColor: '#F7BF14',
      ['&:hover']: {
        backgroundColor: '#F7BF14'
      }
    },
    title: {
      marginBottom: '.5em'
    },
    description: {
      marginBottom: '1em'
    },
    iconButton: {
      backgroundColor: theme.palette.primary.main,
      color: '#ffffff',
      marginLeft: '.5em',
      boxShadow: '0px 1px 4px 1px rgb(0 0 0 / 12%)'
    },
    icon: {
      fontSize: '1em'
    }
  })
)
