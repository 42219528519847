import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// eslint-disable-next-line
import Navigation from './layout/navigation/index' // but why?

import defaultMetaTags from '../components/hocs/defaultMetaTags'
import getRoutes from '../routes'
import * as currentUserActions from '../actions/currentUserActions'
import scrollTopAfterNavigation from './hooks/scrollTopAfterNavigation'

export function App(props) {
  const dispatch = useDispatch()
  const logged_in = useSelector((state) => state.session.active)

  // Load current user from session
  useEffect(() => {
    if (logged_in) {
      dispatch(currentUserActions.getCurrentUser())
    }
  }, [logged_in, dispatch])

  // Scroll to top of page whenever navigation changes
  scrollTopAfterNavigation()

  return <div className='container-fluid'>{getRoutes()}</div>
}

export default defaultMetaTags(App)
