import { Breadcrumbs, Button, Typography } from '@material-ui/core'
import { flow } from 'lodash'
import { inject } from 'mobx-react'
import { observer } from 'mobx-react'
import React from 'react'
import { withRouter } from 'react-router'
import { IStore } from '../../../../store/models'
import { IPartner } from '../../../../store/models/Partner'
import EditPartnerPage from '../../../partners/Edit'
import SummaryContainer from '../partials/SummaryContainer'
import { IUser } from '../../../../store/models/User'
import AdminMetaTags from '../../../shared/AdminMetaTags'
import { Link } from 'react-router-dom'

interface Props {
  user: IUser
  partner?: IPartner
}

const Edit = ({ user, partner }: Props) => {
  const partnerType = 'Customer'
  return (
    <div>
      <AdminMetaTags title='Customers' pageType='Edit' slug={partner?.slug} />

      <Breadcrumbs aria-label='breadcrumb'>
        <Link color='primary' to='/admin'>
          Dashboard
        </Link>
        <Typography color='textPrimary'>Edit {partnerType}</Typography>
      </Breadcrumbs>
      <SummaryContainer
        title={partner?.name}
        topRight={
          <Button rel='noopener' href={`/${partner?.slug}`} target='_blank' variant='contained' disableElevation startIcon={<i className='fas fa-eye'></i>}>
            View Saved {partnerType}
          </Button>
        }
      >
        <EditPartnerPage />
      </SummaryContainer>
    </div>
  )
}

export default flow(
  observer,
  inject<FIXME, FIXME>(
    (
      {
        mst: {
          auth: { user },
          partners
        }
      }: {
        mst: IStore
      },
      {
        match: {
          params: { slug: rawSlug }
        }
      }
    ) => {
      const slug = rawSlug?.toLowerCase()
      const partner = partners.list.find((d) => d.slug.toLowerCase() === slug)
      const partnerLoading = partners.loadingIds.includes(slug)
      !partner && partners.shouldLoad(slug, () => partners.loadBySlug(slug))

      return {
        user,
        partner,
        partnerLoading
      }
    }
  ),
  withRouter
)(Edit)
