import documentsApi from '../../../api/DocumentsApi'
import { types, Instance, getRoot } from 'mobx-state-tree'
import TopicBase from './TopicBase'
import Document from '../base/Document'
import { snakify } from '../../../decorators/textTools'
import { RootAccessable } from '../helpers/RootAccessable'

const TopicDocuments = types
  .compose(
    'TopicDocuments',
    TopicBase,
    RootAccessable,
    types.model({
      hostGuide: types.maybeNull(Document),
      marketingToolKit: types.maybeNull(Document),
      additionalFiles: types.optional(types.array(Document), [])
    })
  )
  .actions((self) => ({
    canUploadDocument: () => {
      const { user } = self.getAuth()
      return user?.isSuperAdmin() || (user?.isPartnerAdmin() && self.allowDocumentUpload)
    },
    removeAdditionalFile: (id: number) => {
      self.additionalFiles.replace(self.additionalFiles.filter((sq) => sq.id !== id))
    },
    addToAdditionalFiles: (json) => {
      self.additionalFiles.push(json)
    },
    setAdditionalFiles(val: any) {
      self.additionalFiles = val
    },
    setHostGuide(val: any) {
      self.hostGuide = val
    },
    deleteHostGuide: () => {
      self.hostGuide = null
    },
    deleteMarketingToolKit: () => {
      self.marketingToolKit = null
    },
    setMarketingToolKit(val: any) {
      self.marketingToolKit = val
    },
    addToDocuments: (data) => {
      if (data.documentType === 'hostGuide') {
        self.hostGuide = data
      }
      if (data.documentType === 'marketingToolKit') {
        self.marketingToolKit = data
      }
      if (data.documentType === 'additionalFiles') {
        self.additionalFiles.push(data)
      }
    },
    removeFromDocuments: (id) => {
      if (self.hostGuide && self.hostGuide.id === id) {
        self.hostGuide = null
      }
      if (self.marketingToolKit && self.marketingToolKit.id === id) {
        self.marketingToolKit = null
      }
      if (self.additionalFiles.find((d) => d.id === id)) {
        self.additionalFiles.replace(self.additionalFiles.filter((d) => d.id !== id))
      }
    }
  }))
  .actions((self) => ({
    loadTopicDocuments(reload = false) {
      const loadName = 'documents'

      if (self.isLoading(loadName) || (self.isLoaded(loadName) && !reload)) return
      self.startLoading(loadName)

      self.additionalFiles.clear()
      return documentsApi.getAll('topic', self.id).then(({ response: { ok }, json }) => {
        if (ok) {
          json.map(self.addToDocuments)
        }
        self.stopLoading(loadName)
      })
    },
    deleteDocument: (id) => {
      return documentsApi.destroy('topic', self.id, id).then(({ response: { ok, status, statusText }, json }) => {
        if (ok) {
          self.removeFromDocuments(id)
          self.showAlert('Document removed')
        } else {
          statusText && self.showAlert(statusText)
        }
      })
    },
    createDocument: (data, docType = 'additionalFiles') => {
      data.document_type = docType

      return documentsApi.create('topic', self.id, { ...snakify(data), documentable_id: self.id }).then(({ response: { ok, status, statusText }, json }) => {
        if (ok) {
          self.addToDocuments(json)
          self.showAlert('Document created!')
          return { data: json }
        } else {
          statusText && self.showAlert(statusText)
          return { error: json }
        }
      })
    },
    assignDocumentGroup: (documentIds, newGroupId) => {
      return documentsApi.assignGroup('topic', self.id, documentIds, newGroupId)
    }
  }))

export interface IDocument extends Instance<typeof Document> {}
export interface ITopicDocuments extends Instance<typeof TopicDocuments> {}
export default TopicDocuments
