import React, { useEffect } from 'react'
import ResponsiveBarCard from './ResponsiveBarCard'
import palette from '../../../../themes/palette'
import { Button, Menu, MenuItem } from '@material-ui/core'
import { flow } from 'lodash'
import { inject, observer } from 'mobx-react'
import { IStore } from '../../../../store/models'
import { IGraphData } from '../../../../store/models/GraphData'
import { getSnapshot } from 'mobx-state-tree'

const FILTER_OPTIONS = [
  { label: 'By Conversation', value: 'conversation' },
  { label: 'By Topic', value: 'topic' }
]

const TotalAttendees = ({ selectedPartnerId, graphData, loadAnalyticsData, scopedBy, setScopedBy, loading }: Props & InjectedProps) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  useEffect(() => {
    setScopedBy('conversation')
    loadAnalyticsData(undefined, { reload: true })
  }, [loadAnalyticsData, setScopedBy])

  useEffect(() => {
    loadAnalyticsData(undefined, { reload: true })
  }, [loadAnalyticsData, selectedPartnerId])

  const handleClose = (menu_name: string) => () => {
    setAnchorEl(null)
    setScopedBy(menu_name)
    loadAnalyticsData(undefined, { reload: true })
  }
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <ResponsiveBarCard
      title='Total Attendees (Past Conversations)'
      yKeyName='Attendees'
      graphData={graphData}
      chartColor={palette.darkBlue}
      loading={loading}
      id={'ic-admin-bar-chart-attendees'}
      headerAction={
        <>
          <Button aria-controls='fade-menu' aria-haspopup='true' onClick={handleOpenMenu} color='primary'>
            {FILTER_OPTIONS.find((fo) => fo.value === scopedBy)?.label}
            <i className='thin-left-padding fas fa-chevron-down header-14'></i>
          </Button>

          <Menu id='fade-menu' anchorEl={anchorEl} keepMounted open={open} onClose={handleClose} color='primary'>
            {FILTER_OPTIONS.map(({ label, value }) => (
              <MenuItem selected={scopedBy === value} key={value} value={value} color='primary' onClick={handleClose(value)}>
                {label}
              </MenuItem>
            ))}
          </Menu>
        </>
      }
    />
  )
}

interface Props {}

interface InjectedProps {
  selectedPartnerId?: number
  graphData: IGraphData[]
  loadAnalyticsData(arg?: FIXME, opt?: FIXME)
  scopedBy: FIXME
  setScopedBy(val: FIXME)
  loading?: boolean
}

export default flow(
  observer,
  inject<InjectedProps, Props>(
    ({
      mst: {
        analytics: { attendeesBar },
        partners: { selectedPartnerId }
      }
    }: {
      mst: IStore
    }) => {
      const { graphData, loadAnalyticsData, setScopedBy, scopedBy, getDataName, loadingIds } = attendeesBar
      return {
        graphData: getSnapshot(graphData),
        loadAnalyticsData,
        scopedBy,
        setScopedBy,
        selectedPartnerId,
        loading: loadingIds.includes(getDataName())
      }
    }
  )
)(TotalAttendees)
