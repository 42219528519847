import React, { Component } from 'react'
import { isEmpty } from 'lodash'
import { inject, observer } from 'mobx-react'
import { IFilterable, IStore } from '../../../../store/models'
import { withRouter } from 'react-router'
import { flow } from 'lodash'
import PartnerActions from '../partials/PartnerActions'
import DataTable, { IColData, ValueLabel } from '../../../shared/DataTableFilterable'
import { IPartner } from '../../../../store/models/Partner'
import { Link } from 'react-router-dom'
import { IOrganization } from '../../../../store/models/Organization/Organization'

interface Props {
  itemAction?(partner: IPartner)
  hideSearch?: boolean
  summaryVersion?: boolean
  filterItem?(item: any): any[]
  organization?: IOrganization
}

interface InjectedProps {
  user: any
  filterable?: IFilterable
  items?: IPartner[]
  loading: boolean
}

class PartnersTable extends Component<Props & InjectedProps, any> {
  render() {
    const { user, items, loading, filterable, itemAction, hideSearch, filterItem, summaryVersion = false } = this.props

    const columnData: IColData[] = summaryVersion
      ? [
          { name: 'id', title: 'ID', width: 90, align: 'right', sortable: true },
          { name: 'name', title: 'Name', sortable: true },
          { name: 'slug', title: 'Slug', sortable: true },
          { name: 'actions', title: 'Actions', width: 100, align: 'center' }
        ]
      : [
          { name: 'id', title: 'ID', width: 90, align: 'right', sortable: true },
          { name: 'name', title: 'Name', width: 300, sortable: true },
          { name: 'language', title: 'Language' },
          { name: 'location', title: 'Location', sortable: true },
          { name: 'slug', title: 'Slug', sortable: true },
          { name: 'churnzeroSync', title: 'CZ sync', width: 100, sortable: true },
          { name: 'actions', title: 'Actions', width: 220, align: 'center' }
        ]

    if (!filterable || !items) {
      return <></>
    }
    const filteredItem = filterItem ? items.filter(filterItem) : items
    return (
      <DataTable
        hideSearch={hideSearch}
        height={'calc(100vh / 3)'}
        data={filteredItem}
        columns={columnData}
        loading={loading}
        filterable={filterable}
        rightColumns={['actions']}
        searchPlaceholder={user.isSuperAdmin() ? 'Search (Name, Slug)' : 'Search'}
        getCellValue={(row: IPartner, columnName: string) => {
          switch (columnName) {
            case 'name':
              return (
                <ValueLabel
                  value={
                    <Link rel='noopener' title={row.name} to={`/${row.slug}`} target='blank' color='primary'>
                      {row.name}
                    </Link>
                  }
                />
              )
            case 'churnzeroSync':
              return row[columnName] && <i className='fas fa-check opacity-5'></i>
            case 'actions':
              return <PartnerActions partner={row} itemAction={itemAction} />
            default:
              return <ValueLabel value={row[columnName]} />
          }
        }}
      />
    )
  }
}

export const OrganizationPartnersTable = flow(
  observer,
  inject<InjectedProps, Props>(
    (
      {
        mst: {
          auth: { user }
        }
      }: {
        mst: IStore
      },
      { organization }
    ) => {
      return {
        user,
        filterable: organization,
        items: organization?.partners,
        loading: organization?.loadingIds.includes('partners_table') || false
      }
    }
  ),
  withRouter
)(PartnersTable)

export default flow(
  observer,
  inject<InjectedProps, Props>(
    ({
      mst: {
        auth: { user },
        partners
      }
    }: {
      mst: IStore
    }) => {
      return {
        user,
        filterable: partners,
        items: partners.list,
        loading: partners.loadingIds.includes('partners_table')
      }
    }
  ),
  withRouter
)(PartnersTable)
