import React, { Component, useState, useEffect } from 'react'
import FontIcon from '@material-ui/core/Icon'
import { history } from '../../browserHistory'
import { capitalize } from '../../../decorators/textTools'
import withTranslation from '../../hocs/withTranslation'
import Dialog from '../../DialogWrapper'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { DialogTitle, DialogActions, Button, CircularProgress } from '@material-ui/core'
import { showDialog } from '../../../actions/dialogActions'
import RsvpQuestionaire from './RsvpQuestionaire'
import LoadingAnimation from '../../shared/LoadingAnimation'

const TopicSubscribeDialog = ({ t, dialog }: Props) => {
  const topic = dialog.data.topic
  const { subscriptionEnabled, subscriptionSurveyUrl, subscriptionDescription } = topic

  const hasQuestions = topic?.surveyQuestions.length > 0
  const hasSurvey = subscriptionSurveyUrl
  const [step, setStep] = useState(subscriptionDescription ? 1 : hasQuestions ? 2 : 3)
  const redirectToSubscriptionSurveyUrl = () => {
    subscriptionEnabled && subscriptionSurveyUrl && window.open(subscriptionSurveyUrl) && dialog.closeDialog()
    return true
  }
  const [done, setDone] = useState(false)
  useEffect(() => {
    if (step === 3) {
      topic.topicSubscribe().then(() => {
        setDone(true)
        topic.showAlert("You've subscribed to this topic.")
      })
      setStep(4)
    }
  }, [step, topic])

  const nextStep = () => {
    switch (step) {
      case 1: //subscription description
        hasQuestions ? setStep(2) : setStep(3)
        break
      case 2: //on questions
        setStep(3)
        break
      case 4: //thank you
        hasSurvey ? redirectToSubscriptionSurveyUrl() : dialog.closeDialog()
        break
      default:
        dialog.closeDialog()
        break
    }
  }
  return (
    <Dialog dialog={dialog} name='conversationInvitationRequestSent' type='noPadding'>
      {step === 1 && ( //subscription description
        <div>
          <DialogTitle disableTypography>Topic Subscription</DialogTitle>
          <section className='pane skinny-pane minimal-grey-pane'>
            <div className='row center-xs'>
              <div className='col-xs-12'>
                <p>{subscriptionDescription}</p>
              </div>
            </div>
          </section>
          <DialogActions>
            <Button onClick={dialog.closeDialog}>Cancel</Button>
            <Button onClick={nextStep} endIcon={<NavigateNextIcon />} color='primary' variant='contained'>
              Next
            </Button>
          </DialogActions>
        </div>
      )}
      {step === 2 && ( //questions
        <div className='fat-left'>
          <DialogTitle disableTypography>RSVP Questions</DialogTitle>
          <RsvpQuestionaire topic={topic} onDone={nextStep} onCancel={dialog.closeDialog} />
        </div>
      )}
      {step === 3 && ( //spinner
        <>
          <DialogTitle disableTypography>Processing...</DialogTitle>
          <section className='pane reg-pane'>
            {/* <div className='row reg-bottom'> */}
            <div className='col-xs-12 center-xs'>
              <LoadingAnimation />
            </div>
          </section>
        </>
      )}
      {step === 4 && ( //thank you
        <div>
          <DialogTitle disableTypography>{t('convos.woohoo')}</DialogTitle>
          <section className='pane reg-pane'>
            <div className='row reg-bottom'>
              <div className='col-xs-12 center-xs'>
                <div className='box'>{done ? <FontIcon className='fa fa-paper-plane-o header-36' /> : <CircularProgress />}</div>
              </div>
            </div>
            <div className='row'>
              <div className='col-xs-12 center-xs'>
                <h1 className='flat header-24'>
                  {t('convos.welcome', {
                    siteName: capitalize(window.siteTitle)
                  })}
                </h1>
              </div>
            </div>
          </section>

          <section className='pane skinny-pane minimal-grey-pane'>
            <div className='row center-xs'>
              <div className='col-xs-12'>
                <p>{t('convos.updateConfirm')}</p>
              </div>
            </div>
          </section>

          <DialogActions>
            <Button onClick={dialog.closeDialog}>Close</Button>
            {subscriptionEnabled && subscriptionSurveyUrl && (
              <Button onClick={redirectToSubscriptionSurveyUrl} color='primary' variant='contained'>
                Proceed to Survey
              </Button>
            )}
          </DialogActions>
        </div>
      )}
    </Dialog>
  )
}
type Props = {
  t: any
  dialog: any
}

// export default TopicInvitationRequestSentDialog
export default withTranslation(TopicSubscribeDialog)
