const defaultPalette = {
  //  Re-Brand!
  blue100: '#6ebef4',
  blue300: '#1293ea',
  blue500: '#0e75bb',
  blue700: '#0c619b',
  blue900: '#094d7c',
  deepOrange100: '#facdb8',
  deepOrange300: '#f38a59',
  deepOrange500: '#f06829',
  deepOrange700: '#d74e0f',
  deepOrange900: '#b7430d',
  green100: '#d3e9b5',
  green300: '#a4d167',
  green500: '#8dc540',
  green700: '#7bae35',
  green900: '#69942d',
  grey50: '#ffffff',
  grey100: '#f6f7f9',
  grey200: '#dfe0e2',
  grey300: '#cdcfd2',
  grey400: '#a9adb2',
  grey500: '#898a8e',
  grey600: '#707174',
  grey700: '#555658',
  grey800: '#212121',
  grey900: '#000000',
  greyA100: '#00000014',
  indigo100: '#ac91d3',
  indigo300: '#7448b6',
  indigo500: '#5d3a92',
  indigo700: '#4e307a',
  indigo900: '#2e1064',
  lightBlue100: '#a9ddf3',
  lightBlue300: '#50bae6',
  lightBlue500: '#23a8e0',
  lightBlue700: '#1c93c5',
  lightBlue900: '#187da7',
  lime100: '#eaedba',
  lime300: '#d2d869',
  lime500: '#c6ce40',
  lime700: '#b4bc30',
  lime900: '#9aa129',
  magenta100: '#e495be',
  magenta300: '#d0438d',
  magenta500: '#b32d73',
  magenta700: '#982662',
  magenta900: '#7f0048',
  orange100: '#fcdab3',
  orange300: '#f8aa51',
  orange500: '#f69220',
  orange700: '#da7809',
  orange900: '#a95d07',
  purple100: '#d97ad5',
  purple300: '#b934b2',
  purple500: '#91298c',
  purple700: '#772272',
  purple900: '#5c1a59',
  red100: '#f69d9c',
  red300: '#f26f6d',
  red500: '#ee413e',
  red700: '#e51814',
  red900: '#b61310',
  teal100: '#c4e6d8',
  teal300: '#7dc7a9',
  teal500: '#59b891',
  teal700: '#48a780',
  teal900: '#388465',
  yellow100: '#fce7a8',
  yellow300: '#f9cc45',
  yellow500: '#f7bf14',
  yellow700: '#e1ac08',
  yellow900: '#c19307',

  //  COCO
  cocoBlueLight: '#677b8c',
  cocoGreen: '#007e59',
  cocoGrey: '#a8abac',
  cocoGreyLight: '#dbdcdf',
  cocoGreyLightest: '#f4f5f5',
  cocoMinty: '#d2e6e1',

  //  SEO
  facebook: '#1877f2',
  linkedin: '#0a66c2',
  twitter: '#1da1f2'
}

export const palette = {
  white: defaultPalette.grey50,
  black: defaultPalette.grey900,
  red: defaultPalette.red500,
  darkOrange: defaultPalette.deepOrange500,
  lightDarkOrange: defaultPalette.orange300,
  lightOrange: defaultPalette.orange500,
  yellow: defaultPalette.yellow500,
  lightYellow: defaultPalette.yellow300,
  lightestYellow: defaultPalette.yellow100,
  darkYellow: defaultPalette.yellow700,
  chartreuse: defaultPalette.lime500,
  green: defaultPalette.green500,
  teal: defaultPalette.teal900,
  skyBlue: defaultPalette.lightBlue700,
  darkBlue: defaultPalette.blue500,
  lightDarkBlue: `${defaultPalette.blue500}10`,
  lightBlue: defaultPalette.lightBlue300,
  purple: defaultPalette.indigo500,
  magenta: defaultPalette.purple500,
  violet: defaultPalette.magenta500,
  darkestgrey: defaultPalette.grey800,
  darkgrey: defaultPalette.grey800,
  darkergrey: defaultPalette.grey700,
  grey: defaultPalette.grey600,
  lightergrey: defaultPalette.grey500,
  lightgrey: defaultPalette.grey400,
  lightlightgrey: defaultPalette.grey300,
  lightestgrey: defaultPalette.grey200,
  nothinGrey: defaultPalette.grey100,
  soSoGrey: defaultPalette.greyA100,

  cocoLightBlue: defaultPalette.cocoBlueLight,
  adminBlue: defaultPalette.blue500,
  richAdminBlue: defaultPalette.lightBlue300,

  ...defaultPalette
}

export default palette
