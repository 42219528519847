import { connect } from 'react-redux'
import React, { Component } from 'react'
import { formValueSelector, change } from 'redux-form'
import { map, flow, isEmpty, isEqual, isUndefined, isNull } from 'lodash'
import InfiniteScroll from 'react-infinite-scroller'
import TopicCard from '../topics/Card'
import ConversationCard from '../conversations/Card'
import { connectTopicTheme } from '../../connectTheme'
import CardLoadingAnimation from './CardLoadingAnimation'
import withTranslation from '../hocs/withTranslation'

export class InfiniteLocationList extends Component {
  constructor(props, context) {
    super(props, context)
    this.handleScroll = this.handleScroll.bind(this)
    this.initCoords = this.initCoords.bind(this)
    this.state = {
      list: [],
      hasMoreItems: true,
      limit: props.limit,
      newSearch: false,
      initCoords: this.initCoords(),
      pageCount: 1
    }
  }

  componentWillReceiveProps(nextProps) {
    const { items } = nextProps
    const { coordinates, venueType } = this.props
    const { list } = this.state

    const newList = map(items)
    const hasMoreItems = isEqual(list, newList) ? false : true

    this.setState({
      list: newList,
      hasMoreItems: hasMoreItems,
      newSearch: false
    })

    if (!isEqual(nextProps.coordinates, coordinates) && !isNull(nextProps.coordinates)) {
      // handle new location search
      this.setState({
        newSearch: true,
        initCoords: null
      })
    }

    if (!isEqual(nextProps.venueType, venueType) && !isNull(nextProps.venueType)) {
      // handle new venueType search
      this.setState({
        newSearch: true,
        pageCount: 1
      })
    }
  }

  componentDidUpdate() {
    if (this.state.newSearch === true && this.props.itemsLoading !== true) {
      // handle new location search
      //this.scroll.pageLoaded = 1
      this.handleScroll(1)
    }
  }

  initCoords() {
    const { locationSlug } = this.props
    if (isNull(locationSlug)) return null

    switch (locationSlug) {
      case 'atlanta':
        return [33.7489954, -84.3879824]
      default:
        return null
    }
  }

  handleScroll(page) {
    const { coordinates, slug, pages } = this.props
    const { limit, initCoords, pageCount } = this.state
    var offset = limit * (pageCount - 1)
    const properLocation = coordinates || initCoords || ''
    const venueType = isUndefined(this.props.venueType) ? null : this.props.venueType

    if (pages && pages < page) return

    if (page === 1) {
      this.props.initQuery({
        limit,
        offset,
        coordinates: properLocation,
        slug,
        venueType
      })
    } else {
      this.props.query({
        limit,
        offset,
        coordinates: properLocation,
        slug,
        venueType
      })
    }
    this.setState({ pageCount: pageCount + 1 })
  }

  render() {
    const { items, itemsLoading, itemType, header, transparentHeader, sectionClass, slug, t, trailingItem, pageStart = 0 } = this.props
    const { list } = this.state

    if (items == null) return null

    const Filters = this.props.filters

    const headerClass = transparentHeader ? 'transparent-header-section' : 'header-section'

    const Item = (props) => {
      const { item } = props
      if (itemType === 'conversation') {
        return <ConversationCard slug={slug} {...item} conversation={item} key={item.id} />
      } else if (itemType === 'topic') {
        return <TopicCard topic={item} key={item.id} />
      }
    }

    const List = () => {
      if (isEmpty(list) && !itemsLoading && !trailingItem)
        return global.coco || itemType === 'topic' ? (
          <h2 className='align-center'>{t('common.noItemsTopics')}</h2>
        ) : (
          <h2 className='align-center'>{t('common.noItemsConversations')}</h2>
        )
      return (
        <div className='row'>
          {list.map((item, index) => (
            <Item item={item} key={index} />
          ))}
          {trailingItem && trailingItem()}
        </div>
      )
    }

    const headerSection = () => (
      <section className={`pane thin-pane ${headerClass}`}>
        <div className='row center-xs'>
          <h2 className='header-32 header-24-sm flat-bottom'>{header}</h2>
        </div>
        {Filters && <Filters updateForm={this.props.updateForm} />}
      </section>
    )

    return (
      <div>
        {header && headerSection()}

        <section className={`pane reg-pane clear-pane ${sectionClass}`}>
          <InfiniteScroll loadMore={this.handleScroll} hasMore={this.state.hasMoreItems && !itemsLoading} threshold={2000} pageStart={pageStart}>
            <List />
            {itemsLoading ? (
              <div key={Math.random()}>
                <CardLoadingAnimation />
              </div>
            ) : null}
          </InfiniteScroll>
        </section>
      </div>
    )
  }
}

export default flow(connectTopicTheme, withTranslation, connect(mapStateToProps, mapDispatchToProps), connect(mapFormStateToProps))(InfiniteLocationList)

function mapStateToProps(state, ownProps) {
  const { getter } = ownProps

  return {
    ...ownProps,
    ...getter(state, ownProps)
  }
}

function mapFormStateToProps(state, ownProps) {
  const { formName } = ownProps
  const selector = formValueSelector(formName)
  const { coordinates, venueType } = selector(state, 'address', 'coordinates', 'venueType')
  return {
    coordinates: isUndefined(coordinates) ? '' : coordinates,
    formName,
    venueType
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  const { formName, initQuery, query } = ownProps
  return {
    initQuery: (args) => dispatch(initQuery(args)),
    query: (args) => dispatch(query(args)),
    updateForm: (val, field) => dispatch(change(formName, field, val))
  }
}
