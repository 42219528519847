import React, { useContext, useEffect } from 'react'

import { AuthContext } from '../../Providers/AuthProvider'
import { useHistory, useLocation, useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import { createStyles, Drawer, Icon, ListItemIcon, makeStyles, MenuItem, MenuList, Theme, Toolbar, Typography } from '@material-ui/core'
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt'
import ChatIcon from '@material-ui/icons/Chat'
import { Link } from 'react-router-dom'
import { IMembershipUser } from '../../../store/models/User/MembershipUser'
import LoadingAnimation from '../../shared/LoadingAnimation'
import { palette } from '../../../themes'

const DrawerMenu = ({ membership }: { membership: IMembershipUser | undefined }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const { user, loaded } = useContext(AuthContext)
  const classes = useStyles()
  const location = useLocation()
  const { action, membership_id, tab } = useParams<{ action: string; membership_id?: string; tab?: string }>()

  if (loaded && !user) {
    history.push('/login')
  }

  const rootName = '/memberships'
  const tabNames = ['details', 'members', 'plan', 'community', 'analytics', 'engagement', 'actions', 'leaderboard', 'feedback']

  useEffect(() => {
    if (!action && membership) {
      history.push(`/memberships/details/${membership.membershipId}`)
    }
  }, [action, history, membership])

  if (!membership) {
    return (
      <>
        <LoadingAnimation />
      </>
    )
  }

  const multiSeat = membership.seatLimit && membership.seatLimit > 1
  const showMembersTab = multiSeat && membership.isManager()
  const showAnalyticsTab = multiSeat && membership.isManager()
  const showPaymentTab = membership.isOwner()

  return (
    <div className='toolbar-main-links no-margin'>
      <Drawer
        className={classes.drawer}
        variant='permanent'
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          <MenuList>
            {[
              { name: tabNames[0], label: 'Journeys', app_url: `${rootName}/${tabNames[0]}/${membership.membershipId}`, MenuIcon: JourneyIcon },
              // { name: tabNames[3], label: 'Community', app_url: `${rootName}/${tabNames[3]}/${membership.membershipId}`, MenuIcon: CommunityIcon },
              ...(showMembersTab ? [{ name: 'members', label: 'Members', app_url: `${rootName}/${tabNames[1]}/${membership.membershipId}/active`, MenuIcon: MembersIcon }] : []),
              ...(showAnalyticsTab
                ? [
                    {
                      name: tabNames[4],
                      label: 'Analytics',
                      app_url: `${rootName}/${tabNames[4]}/${membership.membershipId}`,
                      MenuIcon: AnalyticsIcon
                      // subMenu: [
                      //   { name: tabNames[5], label: 'Engagement', app_url: `${rootName}/${tabNames[4]}/${membership.membershipId}/${tabNames[5]}`, MenuIcon: EngagementIcon },
                      //   { name: tabNames[6], label: 'Actions', app_url: `${rootName}/${tabNames[4]}/${membership.membershipId}/${tabNames[6]}`, MenuIcon: ActionsIcon },
                      //   { name: tabNames[7], label: 'Leaderboard', app_url: `${rootName}/${tabNames[4]}/${membership.membershipId}/${tabNames[7]}`, MenuIcon: LeadershipIcon },
                      //   { name: tabNames[8], label: 'Feedback', app_url: `${rootName}/${tabNames[4]}/${membership.membershipId}/${tabNames[8]}`, MenuIcon: FeedbackIcon }
                      // ]
                    },
                    { name: tabNames[5], label: 'Engagement', app_url: `${rootName}/${tabNames[5]}/${membership.membershipId}`, MenuIcon: EngagementIcon },
                    { name: tabNames[6], label: 'Actions', app_url: `${rootName}/${tabNames[6]}/${membership.membershipId}`, MenuIcon: ActionsIcon },
                    { name: tabNames[7], label: 'Leaderboard', app_url: `${rootName}/${tabNames[7]}/${membership.membershipId}`, MenuIcon: LeadershipIcon },
                    { name: tabNames[8], label: 'Feedback', app_url: `${rootName}/${tabNames[8]}/${membership.membershipId}`, MenuIcon: FeedbackIcon }
                  ]
                : []),
              ...(showPaymentTab ? [{ name: 'plan', label: 'Subscription', app_url: `${rootName}/${tabNames[2]}/${membership.membershipId}`, MenuIcon: SubscriptionIcon }] : [])
            ].map(({ name, label, app_url, MenuIcon = undefined, subMenu = undefined, tab_name }: FIXME) => {
              const childrenNames = subMenu ? subMenu.map((s) => s.name) : []
              const showSubMenus = childrenNames.includes(tab) || action === name
              const selected = childrenNames.length === 0 && name === action
              return (
                <>
                  <Link key={label} to={app_url} style={{ color: 'inherit' }}>
                    <MenuItem className={[classes.menuListItem, ...(childrenNames.includes(tab) ? [classes.activeParent] : [])].join(' ')} selected={selected}>
                      {MenuIcon && (
                        <ListItemIcon>
                          <MenuIcon />
                        </ListItemIcon>
                      )}
                      <Typography variant='inherit' noWrap>
                        {label}
                      </Typography>
                    </MenuItem>
                  </Link>
                  {showSubMenus &&
                    subMenu?.map(({ name: sub_name, label: sub_label, app_url: sub_app_url, MenuIcon: SubMenuIcon = undefined }: FIXME) => {
                      return (
                        <Link key={sub_label} to={sub_app_url} style={{ color: 'inherit' }}>
                          <MenuItem className={classes.subMenuListItem} selected={tab === sub_name}>
                            {SubMenuIcon && (
                              <ListItemIcon>
                                <SubMenuIcon />
                              </ListItemIcon>
                            )}
                            <Typography variant='inherit' noWrap>
                              {sub_label}
                            </Typography>
                          </MenuItem>
                        </Link>
                      )
                    })}
                </>
              )
            })}
          </MenuList>
        </div>
      </Drawer>
    </div>
  )
}
const JourneyIcon = (props) => {
  return <Icon className='fas fa-road' />
}
const CommunityIcon = (props) => {
  return <Icon className='fas fa-list-ul' />
}
const MembersIcon = (props) => {
  return <Icon className='fas fa-users' />
}
const SubscriptionIcon = (props) => {
  return <Icon className='far fa-hands-helping' />
}
const AnalyticsIcon = (props) => {
  return <Icon className='fas fa-analytics' />
}
const EngagementIcon = (props) => {
  return <Icon className='fal fa-chart-network' />
}
const ActionsIcon = (props) => {
  return <Icon className='far fa-check-double' />
}
const LeadershipIcon = (props) => {
  return <Icon className='fas fa-list' />
}
const FeedbackIcon = (props) => {
  return <Icon className='fas fa-comments-alt' />
}
export default DrawerMenu
const drawerWidth = 250
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      ['& .Mui-selected']: {
        backgroundColor: palette.yellow500
      },
      ['& .MuiIcon-root']: {
        color: palette.grey500,
        minWidth: '2em'
      }
    },
    drawerPaper: {
      width: drawerWidth
    },
    drawerContainer: {
      overflow: 'auto',
      paddingTop: theme.spacing(2),
      height: '100%'
    },
    activeParent: {
      paddingTop: theme.spacing(1.7),
      paddingBottom: theme.spacing(1.7),
      fontWeight: 700,
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    menuListItem: {
      paddingTop: theme.spacing(1.7),
      paddingBottom: theme.spacing(1.7)
    },
    subMenuListItem: {
      paddingLeft: theme.spacing(3),
      paddingTop: theme.spacing(1.7),
      paddingBottom: theme.spacing(1.7)
    }
  })
)
