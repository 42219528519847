import { Collapse, createStyles, List, ListItem, ListItemIcon, ListItemText, makeStyles, Theme } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

export interface INavMenu {
  label: string
  url: string
  internal?: boolean
  selected?: boolean
  icon?: string | FIXME
}

export const NavList = ({ label, menus, onClick, defaultOpen = false }: { onClick?(v?: INavMenu); label: string; menus: INavMenu[]; defaultOpen?: boolean }) => {
  const [open, setOpen] = React.useState(defaultOpen)
  const { t } = useTranslation()
  const history = useHistory()
  const classes = Styles()
  const handleClick = (e) => {
    setOpen(!open)
  }
  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemText primary={t(label)} classes={{ primary: classes.header }} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      {menus.map((n) => (
        <Collapse in={open} timeout='auto' unmountOnExit key={n.label}>
          <List component='div' disablePadding>
            <ListItem
              selected={n?.selected}
              classes={{ selected: classes.selected }}
              button
              onClick={() => {
                setOpen(false)
                onClick && onClick(n)
                if (n.internal) {
                  history.push(n.url)
                } else {
                  window.location.href = n.url
                }
              }}
            >
              {n.icon && <ListItemIcon classes={{ root: classes.icon }}>{typeof n.icon === 'string' ? <i className={n.icon}></i> : <n.icon />}</ListItemIcon>}
              <ListItemText primary={t(n.label)} classes={{ primary: classes.label }} />
            </ListItem>
          </List>
        </Collapse>
      ))}
    </>
  )
}

export default NavList
const Styles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      minWidth: '1.5em',
      fontSize: '1.5em'
    },
    selected: {
      backgroundColor: `${theme.palette.primary.main} !important`
    },
    header: {
      fontWeight: 500
    },
    label: {
      fontWeight: 400
    }
  })
)
