import { toFormData } from './FormData'
import { deleteApi, post, put, get } from './utils'
import queryString from 'query-string'

class StoriesApi {
  create(data: any) {
    const formData = toFormData('story', data)
    return post('/stories', {
      noContentTypeJson: true,
      body: formData
    })
  }
  update(id, story: any) {
    return put(`/stories/${id}`, { body: JSON.stringify({ story }) })
  }
  getAll(args) {
    const defaultParams = { page: 0, site_name: window.siteName }
    const params = queryString.stringify({ ...defaultParams, ...args })
    return get(`/admin/stories?${params}`)
  }

  getStoriesForTopic({ topic_id }) {
    const defaultParams = { offset: 0 }
    const params = queryString.stringify({ ...defaultParams, topic_id })
    return get(`/topics/${topic_id}/stories?${params}`)
  }
}

export default new StoriesApi()
