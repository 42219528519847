import React from 'react'

const CardLoadingAnimation = () => {
  const ShimmerCard = () => (
    <div className='col-xs-12 col-sm-4'>
      <div className='card'>
        <div className='shimmer-container'>
          <div className='shimmer-content'>
            <div className='shimmer-animated'>
              <div className='shimmer-box' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <div className='shimmer'>
      <div className='row'>
        <ShimmerCard />
        <ShimmerCard />
        <ShimmerCard />
      </div>
      <div className='row'>
        <ShimmerCard />
        <ShimmerCard />
        <ShimmerCard />
      </div>
    </div>
  )
}

export default CardLoadingAnimation
