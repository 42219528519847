import React from 'react'
import { flow } from 'lodash'

import ConversationListCard from '../../conversations/ListCard'
import { connectTopicTheme } from '../../../connectTheme'
import withTranslation from '../../hocs/withTranslation'
import LoadingAnimation from '../../shared/LoadingAnimation'
import { inject, observer } from 'mobx-react'
import { IStore } from '../../../store'
import moment from 'moment-timezone'

const PastConversationsList = (props: any) => {
  const { conversations, isHost, t, loadingList } = props

  if (loadingList) return <LoadingAnimation size={40} />

  return (
    <div>
      <div className='huge-bottom'>
        <>
          {!!conversations.length && (
            <h2 className='dividing-header header-14 giant-top xtra-fat-bottom font-normal'>
              <span>{window.coco ? t('conversation.pastTopics') : t('conversation.pastConversations')}</span>
            </h2>
          )}
        </>
        {[...conversations]
          .sort((b, a) => moment(a.startsAt).diff(moment(b.startsAt)))
          .map((conversation) => (
            <ConversationListCard conversation={conversation} isHost={isHost} key={conversation.id} past={true} />
          ))}
      </div>
    </div>
  )
}

export default flow(
  connectTopicTheme,
  withTranslation,
  observer,
  inject<any, any>(({ mst }: { mst: IStore }, ownProps) => {
    const {
      auth: { user },
      conversations,
      conversations: { allPastConversations }
    } = mst

    user && conversations.shouldLoad('hostedList', conversations.loadHostedConversations)

    return {
      user,
      conversations: allPastConversations,
      listCount: allPastConversations.length,
      loadingList: conversations.loadingIds.includes('hostedList')
    }
  })
)(PastConversationsList)
