import { types, Instance, getRoot, getSnapshot } from 'mobx-state-tree'
import ConversationInvitationsApi from '../../../api/ConversationInvitationsApi'
import UserConversationsApi from '../../../api/UserConversationsApi'
import ConversationInvitationRequestsApi from '../../../api/ConversationInvitationRequestsApi.js'
import GuestsApi from '../../../api/GuestsApi'
import ConversationBase from './ConversationBase'

import { Guest } from '../User'
import { IUserConversation } from '../User'
import { AfterLoginActionable } from '../helpers/AfterLoginActionable'
import { Alertable } from '../helpers/Alertable'
import { Dialogable } from '../helpers/Dialogable'
import { LoadableStatus } from '../helpers/LoadableStatus'
import MessageGuestsApi from '../../../api/MessageGuestsApi'

const ConversationGuests = types
  .compose(
    'ConversationGuests',
    ConversationBase,
    Dialogable,
    AfterLoginActionable,
    types.model({
      guests: types.optional(types.array(Guest), []),
      guestsLimit: types.optional(types.number, 99),
      guestsOffset: types.optional(types.number, 0),
      partnerConsented: types.optional(types.boolean, false),
      questionAnswered: types.optional(types.boolean, false),
      requestingInvite: types.optional(types.boolean, false),
      attendingProcess: types.optional(types.boolean, false),
      hasMoreGuests: types.optional(types.boolean, true),
      verifiedApprovalToken: types.maybe(types.string)
    })
  )
  .views((self) => ({
    get hasGuests() {
      return self.guestCount && self.guestCount > 0
    }
  }))
  .actions((self) => ({
    setGuests(val: any) {
      self.guests.replace(val)
    },
    setAttendingProcess(val: boolean) {
      self.attendingProcess = val
    },
    setQuestionAnswered(val: boolean) {
      self.questionAnswered = val
    },
    addToGuest(user: any) {
      self.guests.push(user)
    },
    removeGuest(userId: number) {
      self.guests.replace(self.guests.filter((g) => g.id === userId))
    },
    addGuest(user) {
      if (!self.guests.find((g) => g.id === user.id)) {
        self.guests.push(user)
      }
    },
    addMoreToGuest(user: any[]) {
      self.guests.replace([...self.guests, ...user])
    },
    setHasMoreGuests: (val: boolean) => {
      self.hasMoreGuests = val
    },
    setVerifiedApprovalToken: (val?: string) => {
      self.verifiedApprovalToken = val
    },
    reloadUserInvitationRequests: () => {
      const {
        auth: { user }
      } = getRoot(self)
      user.loadUserInvitationRequests({ reload: true })
      user.loadUserConversations()
    }
  }))
  .actions((self) => ({
    loadGuests({ limit = self.guestsLimit, offset = self.guestsOffset } = {}, append = false) {
      if (self.isLoading('guests')) return
      self.guestsLimit = limit
      self.guestsOffset = offset

      self.startLoading('guests')
      return GuestsApi.getAll({ conversationId: self.id, limit, offset }).then(({ response: { ok }, json }) => {
        if (ok) {
          if (json.length === 0) {
            self.setHasMoreGuests(false)
          } else {
            if (append) {
              self.addMoreToGuest(json)
            } else {
              self.setGuests(json)
            }
          }
        }
        self.stopLoading('guests')
      })
    },
    removeGuestFromList: (userId: number) => {
      const found = self.guests.find((g) => g.id === userId)
      if (found) {
        self.guests.replace(self.guests.filter((g) => g.id !== userId))
        const {
          auth: { user }
        } = getRoot(self)
        if (user.id === userId) {
          user.loadUserConversations()
        }
      }
    },
    redeemApprovalToken: (values) => {
      return ConversationInvitationsApi.redeemApprovalToken(values)
        .then(({ json, response }) => {
          if (response.ok && json.success) {
            localStorage.removeItem('approval')
            self.setVerifiedApprovalToken(undefined)
            self.reloadUserInvitationRequests()

            self.setHasMoreGuests(true)
            ;(self as any)?.loadGuests({ offset: 0 })
          }
        })
        .catch((error) => {
          throw error
        })
    },
    createUserConversation: ({ conversationId, userId }) => {
      return UserConversationsApi.create({ conversationId, userId })
        .then((response) => {
          if (response.response.ok) {
            self.showDialog('joinedConversation', response.json)
          }
        })
        .catch((error) => {
          throw error
        })
    },
    attend: async () => {
      //  to be overriden
    },
    createConversationInvitationRequest: async (val) => {
      //  to be overriden
    }
  }))
  .views((self) => ({
    isHost: () => false
  }))
  .actions((self) => ({
    isGuest() {
      const {
        auth: { user }
      } = getRoot(self)
      return user && user.userConversations.map((g: IUserConversation) => g.conversationId).includes(self.id)
    },
    attended() {
      const { user } = self.getAuth()
      return user && user.userConversations.find((g: IUserConversation) => g.conversationId === self.id)?.attended
    }
  }))
  .actions((self) => ({
    isAttending: () => self.isGuest() || self.isHost,
    attend: async () => {
      const {
        auth: { user },
        topics
      } = getRoot(self)

      if (!user) {
        self.setAfterLoginAction('attend')
        self.showDialog('signup')
        return
      }

      const topic = topics.list.find((c) => c.id === self.topicId)

      if (topic?.surveyQuestions.length === 0) {
        await topic?.loadSurveyQuestions()
      }

      const { partnerConsent, partnerConsentTitle, partnerConsentBody } = self

      if (topic?.surveyQuestions && topic.surveyQuestions.length > 0 && !self.questionAnswered) {
        self.showDialog('surveyQuestion', {
          questions: topic?.surveyQuestions,
          conversationId: self.id,
          conversation: self,
          topic,
          nextAction: () => {
            self.setQuestionAnswered(true)
            self.attend()
          }
        })
        return
      }

      self.setAttendingProcess(false)
      if (partnerConsent && !self.partnerConsented) {
        self.setAttendingProcess(true)
        self.showDialog('partnerConsent', {
          conversationId: self.id,
          partnerConsentTitle,
          partnerConsentBody
        })
        return
      }

      return UserConversationsApi.create({ conversationId: self.id, userId: user.id }).then(({ response: { ok } }) => {
        if (ok) {
          user
            .loadUserConversations()
            .then(() => {
              self.loadGuests()
              self.addGuest(getSnapshot(user))
              const { loadMyConversations } = self.getConversations()
              loadMyConversations({ reload: true })
            })
            .then(() => {
              self.showAlert('Attending')
              self.showDialog('attendingConversation', { conversation: self })
            })
        }
      })
    },
    unattend: ({ userId, message /*, isGuest*/ }: any, { showDialog = false, successAlert = 'Not Attending', dialog = 'removeGuest' } = {}) => {
      const {
        auth: { user }
      } = getRoot(self)

      const unattendUserId = userId || user.id
      if (showDialog) {
        if (dialog === 'removeGuest') {
          self.showDialog(dialog, { userId, conversation: self, successAlert })
        } else {
          self.showDialog(dialog, { successAlert, conversationId: self.id, userId: unattendUserId, isGuest: true })
        }
        return
      }
      return UserConversationsApi.destroy({ conversationId: self.id, userId: unattendUserId, message }).then(({ response: { ok, statusText }, json }: any) => {
        if (ok) {
          self.removeGuestFromList(unattendUserId)
          const { loadMyConversations } = self.getConversations()
          loadMyConversations({ reload: true })
          if (user.id === unattendUserId) {
            user.loadUserConversations()
          }
          self.showAlert(successAlert)
        } else {
          self.showAlert(statusText)
        }
      })
    },
    createConversationInvitation(values: { message?: string; recipientEmail?: string; conversationId: number; senderId: number; inviteAllMembers?: boolean }) {
      return ConversationInvitationsApi.create(values).then(({ response: { ok }, json }) => {
        if (ok) {
          return { result: json }
        } else {
          return { errors: json }
        }
      })
    },
    emailGuests() {
      const {
        auth: { user }
      } = getRoot(self)
      self.showDialog('emailGuests', { currentUserId: user?.id, host: self.host, conversation: self })
    },
    messageHost: () => {
      const {
        auth: { user }
      } = getRoot(self)

      if (!user) {
        self.setAfterLoginAction('messageHost')
        self.showDialog('signup')
        return
      }

      self.showDialog('emailHost', { conversation: self })
    },
    requestInvite: () => {
      const {
        auth: { user }
      } = getRoot(self)

      if (!user) {
        self.setAfterLoginAction('requestInvite')
        self.showDialog('signup')
        return
      }

      const { partnerConsent, partnerConsentTitle, partnerConsentBody } = self

      if (partnerConsent && !self.partnerConsented) {
        self.requestingInvite = true
        self.showDialog('partnerConsent', {
          conversationId: self.id,
          partnerConsentTitle,
          partnerConsentBody
        })
      } else {
        self.requestingInvite = false
        self.createConversationInvitationRequest({})
      }
    },
    invite: ({ skipProfileImage = false, hideShare = false } = {}) => {
      const {
        auth: { user }
      } = getRoot(self)

      if (!user) {
        self.setAfterLoginAction('invite')
        self.showDialog('signup')
        return
      }
      self.showDialog('emailInvite', { conversationId: self.id, conversation: self, step: 2, skipProfileImage, hideShare })
    },
    verifyApprovalToken: (token) => {
      return ConversationInvitationsApi.verifyApprovalToken(token)
        .then(({ json, response }) => {
          if (response.ok && json.success !== false) {
            const { conversationId, approvalToken } = json

            localStorage.setItem(
              'approval',
              JSON.stringify({
                conversationId,
                approvalToken
              })
            )
            self.setVerifiedApprovalToken(approvalToken)
            const {
              auth: { user }
            } = getRoot(self)

            !user && self.showDialog('login')
          }
        })
        .catch((error) => {
          throw error
        })
    },
    updateConversationInvitationRequest: (data) => {
      const accepted = data?.invite_accepted === 'true'

      const dialog = accepted ? 'invite_accepted' : 'invite_denied'

      return ConversationInvitationRequestsApi.update(data)
        .then(({ response: { ok }, json }) => {
          if (ok) {
            self.closeDialog('login')
            self.showDialog(dialog)

            self.reloadUserInvitationRequests()
          } else {
            self.closeDialog('login')
            self.showAlert(json?.errors)
          }
        })
        .catch((error) => {
          throw error
        })
    },
    acceptInvitation: ({ approvalToken, userId }) => {
      self.createUserConversation({ conversationId: self.id, userId }).then(() => {
        self.redeemApprovalToken({ approvalToken })
      })
    },
    createCsvExport: () => {
      const { downloads } = getRoot(self)
      return downloads.createCsvExport('guest', { conversationId: self.id }).then(() => {
        downloads.loadFilter()
      })
    },
    messageGuests: (message?: string) => {
      const { user } = self.getAuth()
      if (message) {
        return MessageGuestsApi.create({ conversationId: self.id, senderId: user?.id, content: message }).then(({ response: { ok }, json }) => {
          if (ok) {
            self.showAlert('Sent')
          }
        })
      } else {
        self.showDialog('emailGuests', { conversation: self, sender: user })
        return Promise.resolve()
      }
    }
  }))

export interface IConversationGuests extends Instance<typeof ConversationGuests> {}
export default ConversationGuests
