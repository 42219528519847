import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as passwordResetsActions from '../../actions/passwordResetsActions'
import { getUsePasswordResetProps } from '../../getters/passwordResetsGetters'
import UsePasswordReset from './UseForm'

export class CreatePasswordResetContainer extends Component {
  render() {
    const props = this.props

    return <UsePasswordReset usePasswordReset={this.usePasswordReset} {...props} />
  }

  usePasswordReset = (values) => {
    const { actions, token } = this.props
    return actions.usePasswordReset(token, values)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(passwordResetsActions, dispatch)
  }
}

export default connect(getUsePasswordResetProps, mapDispatchToProps)(CreatePasswordResetContainer)
