import { Button, Link, DialogTitle, Tooltip, IconButton, Grid } from '@material-ui/core'
import React, { useState } from 'react'
import { IDocument } from '../../store/models/base/Document'
import DialogWrapper from '../DialogWrapper'

interface Props {
  document: IDocument
  onEdit?(e: any)
  onDelete?(e: any)
  editable?: boolean
}

const DocumentItem = ({ document, onEdit, onDelete, editable = true }: Props) => {
  const [deleteModal, setdeleteModal] = useState(false)
  const fileUrl = document.file?.url || '/'
  const fileName = document.fileName()

  return (
    <Grid container key={document.id} className='thin-bottom'>
      <Grid item xs={10}>
        <a href={fileUrl} target='blank'>
          {fileName}
        </a>
      </Grid>
      <Grid item xs={2}>
        <Tooltip title='Copy link'>
          <IconButton
            size='small'
            className='reg-left'
            onClick={() => {
              navigator.clipboard.writeText(fileUrl)
              document.showAlert('Copied link to clipboard.')
            }}
          >
            <i className='fas fa-copy opacity-6'></i>
          </IconButton>
        </Tooltip>
        {editable && (
          <>
            {document.canEdit() && onEdit && (
              <Button className='reg-left' variant='contained' onClick={onEdit}>
                Edit
              </Button>
            )}

            {document.canEdit() && onDelete && (
              <Tooltip title='Delete document'>
                <IconButton className='thin-left' size='small' onClick={() => setdeleteModal(true)}>
                  <i className='far fa-trash-alt opacity-6'></i>
                </IconButton>
              </Tooltip>
            )}
          </>
        )}
        <DialogWrapper open={deleteModal} afterClose={() => setdeleteModal(false)} type='noPadding'>
          <DialogTitle>Are you sure?</DialogTitle>

          <div className='row center-xs'>
            <div className='col-xs-12'>
              <Button onClick={() => setdeleteModal(false)} className='reg-bottom flat-top btn-xl btn-block lowercase capitalize'>
                Cancel
              </Button>
              <Button onClick={onDelete} variant='contained' color='primary' className='reg-bottom flat-top btn-xl btn-block lowercase capitalize'>
                Delete
              </Button>
            </div>
          </div>
        </DialogWrapper>
      </Grid>
    </Grid>
  )
}

export default DocumentItem
