import React, { useEffect } from 'react'
import { Button } from '@material-ui/core'
import { observer } from 'mobx-react'
import { useForm } from 'react-hook-form'
import { getSnapshot, isStateTreeNode } from 'mobx-state-tree'
import { FileControl, TextFieldControl } from '../utils'
import * as validations from '../validations/fieldLevelValidations'

const SponsorForm = (props: FIXME) => {
  const { handleSubmit, errors, formState, control, watch, reset } = useForm({ mode: 'onBlur' })
  const { isDirty, isSubmitting } = formState
  const { section, sponsor, closeForm, action } = props

  useEffect(() => {
    const newSponsor = isStateTreeNode(sponsor) ? getSnapshot(sponsor) : sponsor || {}

    reset(newSponsor, {
      errors: false, // errors will not be reset
      dirtyFields: false, // dirtyFields will not be reset
      isDirty: false, // dirty will not be reset
      isSubmitted: true,
      touched: false,
      isValid: true,
      submitCount: true
    })
  }, [sponsor, reset])

  useEffect(() => {
    sponsor?.setDirty(isDirty)
  }, [sponsor, isDirty])

  const handleSave = handleSubmit((data: any, e) => {
    const updateData = { ...(isStateTreeNode(sponsor) ? getSnapshot(sponsor) : sponsor || {}), ...data }

    const afterSave = () => {
      reset(undefined, {
        errors: false, // errors will not be reset
        dirtyFields: false, // dirtyFields will not be reset
        isDirty: false, // dirty will not be reset
        isSubmitted: true,
        touched: false,
        isValid: true,
        submitCount: true
      })
      closeForm && closeForm()
    }

    action === 'create' && section.createSponsor(updateData).then(afterSave)
    action === 'update' && sponsor.updateSponsor(updateData).then(afterSave)
  })

  const formLogo = watch('logo')
  const previewImage = formLogo && formLogo.url ? formLogo.url : formLogo ? URL.createObjectURL(formLogo) : undefined

  return (
    <div className='fat-top-padding xtra-fat-bottom'>
      <form onSubmit={handleSave}>
        {previewImage && <img src={previewImage} className='max-width' alt='logo' />}

        <FileControl name='logo' control={control} defaultValue={sponsor?.logo} error={errors && errors.logo} label={'logo'} />

        <TextFieldControl
          name='logoLink'
          control={control}
          rules={{ validate: validations.http }}
          defaultValue={sponsor?.logoLink || ''}
          error={errors && errors.logoLink}
          label={'Logo Link'}
        />

        <div className='row no-gutters reg-top'>
          <div className='col-xs-12 no-gutters end-xs'>
            <Button disabled={!isDirty || isSubmitting} type='submit' className='reg-left' color='secondary' variant='contained'>
              Update
            </Button>{' '}
            {sponsor && (
              <Button
                color='secondary'
                onClick={() => {
                  closeForm && closeForm()
                  sponsor.deleteSponsor()
                }}
                className='reg-right'
                variant='contained'
              >
                Delete
              </Button>
            )}
            <Button onClick={() => closeForm && closeForm()} className='reg-right'>
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default observer(SponsorForm)
