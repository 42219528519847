import React from 'react'
import { AddressAutocompleteControl, TextFieldControl } from '../../utils'
import { StepProps } from './NewStepper'
import { required, minAttendance } from '../../validations/fieldLevelValidations'
import withTranslation, { WithTranslation } from '../../hocs/withTranslation'

const HomeLocationStep = ({ t, control, errors, dirtyFields, setValue, watch }: StepProps & WithTranslation) => {
  const venue = watch('venueType') === 'restaurant' ? 'Restaurant' : 'Venue'
  const showAddressHint = dirtyFields.location && !dirtyFields.placeId
  return (
    <>
      <div>
        <TextFieldControl name='venueName' control={control} error={errors && errors.venueName} label={t('conversation.venueNameLabel', { venue })} />

        <AddressAutocompleteControl
          types={['address']}
          name='location'
          control={control}
          required={true}
          label={t('common.address')}
          helperText={t('conversation.addressHint')}
          onChange={(val) => {
            // setValue('location', '', { shouldDirty: true })
            setValue('address', val?.address, { shouldDirty: true })
            setValue('city', val?.city, { shouldDirty: true })
            setValue('placeId', val?.place_id, { shouldDirty: true })
          }}
        />

        {showAddressHint ? (
          <p
            className='material-error'
            dangerouslySetInnerHTML={{
              __html: t('signup.addressHint', {
                url: window.coco ? 'mailto:support@fbconversations.com' : 'mailto:support@inclusivv.co'
              })
            }}
          />
        ) : null}

        <p>{t('conversation.addressNote')}</p>

        <div className='reg-top'>
          <TextFieldControl
            label={t('conversation.maxAttendeesLabel')}
            rules={{ validate: { required, minAttendance } }}
            name='maxAttendees'
            control={control}
            defaultValue={8}
            type='number'
            className='reg-top'
            error={errors && errors.maxAttendees}
          />
        </div>
      </div>
    </>
  )
}

export default withTranslation(HomeLocationStep)
