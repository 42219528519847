import React, { Component } from 'react'
import { flow, isEmpty } from 'lodash'
import { Link } from 'react-router-dom'
import queryString from 'query-string'
import UserAvatar from '../users/Avatar'
import withTranslation from '../hocs/withTranslation'
import { observer, inject } from 'mobx-react'
import { IStore } from '../../store/models'
import PageLoadingAnimation from '../shared/PageLoadingAnimation'
import { IUser, IUserConnection } from '../../store/models/User'
import { RouteComponentProps, withRouter } from 'react-router'
import { IconButton, Tooltip } from '@material-ui/core'

const ActionsCompleted = observer(({ user }) => {
  return (
    <div className='row no-right-gutter thin-bottom middle-xs'>
      <div className='col-xs-2 no-left-gutter center-xs'>
        <img className={'img-responsive'} src={'https://civicdinners-manual-storage.s3.amazonaws.com/users/fa-solid_hand-sparkles.svg'} style={{ margin: '0 auto' }} />
      </div>
      <div className='col-xs-10 header-18 no-left-gutter'>Completed {user.userActions.filter((a) => a.finishedAt).length} actions</div>
    </div>
  )
})

class UserProfile extends Component<Props & InjectedProps & RouteComponentProps> {
  componentDidUpdate() {
    const { user, currentUser } = this.props
    this.handleRequestConnectionParams()
    this.handleAcceptConnectionParams()
    if (currentUser) currentUser?.loadUserConnections(user?.id)
  }

  handleRequestConnectionParams = () => {
    const { location, user, history, currentUser, userConnectionConversationId, loadedCurrentUser, showDialog } = this.props
    const { connect } = queryString.parse(location.search)

    if (connect && loadedCurrentUser) {
      if (currentUser && userConnectionConversationId) {
        user?.showRequestDialog({ userId: currentUser?.id, requestedUserId: user.id, conversationId: userConnectionConversationId })
        if (location.search) history.replace(location.pathname, null)
      } else if (!currentUser) {
        showDialog('signup')
      }
    }
  }

  handleAcceptConnectionParams = () => {
    const { location, user, history } = this.props
    const { it, uid, did } = queryString.parse(location.search)

    if (it && uid) {
      user?.updateUserConnection({ id: it, userId: uid, conversationId: did })
      if (location.search) history.replace(location.pathname, null)
    }
  }

  render() {
    const { user, currentUser, t, masquerade, history, userConnection, userConnectionConversationId, viewingSelf } = this.props
    if (!user) {
      return <PageLoadingAnimation />
    }
    const {
      fullName,
      createdConversationsCount,
      registeredConversationsCount,
      invitedUserCount,
      title,
      id,
      companyName,
      bio,
      magicWand,
      facebookLink,
      twitterLink,
      instagramLink,
      linkedinLink,
      websiteLink,
      favoriteFood,
      favoriteRestaurant,
      city,
      stateLevel,
      interests,
      pronoun,
      public: publicProfile
    } = user

    if (isEmpty(user)) return null

    user && user.shouldLoad('userActions', user.loadUserActions)

    const connectedMessage = () => {
      return userConnection && userConnection?.accepted ? <div>{t('user.connected')}</div> : <div>{t('user.connectionPending')}</div>
    }

    const removeConnection = () => {
      return (
        userConnection &&
        userConnection?.accepted && (
          <div className='col-xs-9 col-xs-offset-2 header-16 no-left-gutter '>
            <Link onClick={() => currentUser?.deleteUserConnection(userConnection)} to='#'>
              {t('user.removeConnection')}
            </Link>
          </div>
        )
      )
    }

    const userConnectionActions = () => (
      <div className='row no-right-gutter thin-bottom middle-xs'>
        <div className='col-xs-2 no-left-gutter center-xs'>
          <i className='fal fa-comments dark-grey-text header-26' />
        </div>
        <div className='col-xs-9 header-18 no-left-gutter'>
          {userConnectionConversationId && (
            <Link onClick={() => user?.showRequestDialog({ userId: currentUser?.id, requestedUserId: user.id, conversationId: userConnectionConversationId })} to='#'>
              {t('user.requestConnectiion')}
            </Link>
          )}
          {!!userConnection && connectedMessage()}
        </div>
        {!!userConnection && removeConnection()}
      </div>
    )

    const EditLink = () =>
      currentUser && currentUser.id === user.id ? (
        <Link to={`/users/${id}/edit`} className='white-text header-14'>
          {t('user.editYourProfile')}
        </Link>
      ) : null

    const FacebookLink = () => {
      if (!facebookLink) return null
      return (
        <div className='col-xs-2 col-sm-1 no-left-gutter'>
          <a href={facebookLink} target='_blank' rel='noopener noreferrer'>
            <i className='fab fa-facebook gold-text header-30' />
          </a>
        </div>
      )
    }

    const TwitterLink = () => {
      if (!twitterLink) return null
      return (
        <div className='col-xs-2 col-sm-1 no-left-gutter'>
          <a href={twitterLink} target='_blank' rel='noopener noreferrer'>
            <i className='fa fa-twitter gold-text header-30' />
          </a>
        </div>
      )
    }

    const InstagramLink = () => {
      if (!instagramLink) return null
      return (
        <div className='col-xs-2 col-sm-1 no-left-gutter'>
          <a href={instagramLink} target='_blank' rel='noopener noreferrer'>
            <i className='fa fa-instagram gold-text header-30' />
          </a>
        </div>
      )
    }

    const LinkedinLink = () => {
      if (!linkedinLink) return null
      return (
        <div className='col-xs-2 col-sm-1 no-left-gutter'>
          <a href={linkedinLink} target='_blank' rel='noopener noreferrer'>
            <i className='fa fa-linkedin gold-text header-30' />
          </a>
        </div>
      )
    }

    const WebsiteLink = () => {
      if (!websiteLink) return null
      return (
        <div className='col-xs-2 col-sm-1 no-left-gutter'>
          <a href={websiteLink} target='_blank' rel='noopener noreferrer'>
            <i className='fad fa-link gold-text header-30' />
          </a>
        </div>
      )
    }

    const Bio = () => {
      if (!bio) return null
      return (
        <div className='row no-left-gutter fat-bottom'>
          <div className='col-xs-12 col-md-9 no-left-gutter'>
            <p className='header-18 light-grey-text flat-bottom'>{t('user.bio')}</p>
            <p className='header-20 flat-top'>{bio}</p>
          </div>
        </div>
      )
    }

    const UserInterests = () => {
      if (!interests || interests.length === 0) {
        return <></>
      }
      return (
        <div className='row no-left-gutter fat-bottom'>
          <div className='col-xs-12 col-md-9 no-left-gutter'>
            <p className='header-18 light-grey-text flat-bottom'>Interests</p>
            <p className='header-20 flat-top'>{interests?.join(', ')}</p>
          </div>
        </div>
      )
    }

    const MagicWand = () => {
      if (!magicWand) return null
      return (
        <div className='row no-left-gutter fat-bottom'>
          <div className='col-xs-12 no-left-gutter'>
            <p className='header-18 light-grey-text flat-bottom'>{t('user.magicwand')}</p>
            <p className='header-20 flat-top'>{magicWand}</p>
          </div>
        </div>
      )
    }

    const FavoriteFood = () => {
      if (!favoriteFood) return null
      return (
        <div className='row no-left-gutter fat-bottom'>
          <div className='col-xs-12 no-left-gutter'>
            <p className='header-18 light-grey-text flat-bottom'>{t('user.foodLabel')}</p>
            <p className='header-20 flat-top'>{favoriteFood}</p>
          </div>
        </div>
      )
    }

    const FavoriteRestaurant = () => {
      if (!favoriteRestaurant) return null
      return (
        <div className='row no-left-gutter fat-bottom'>
          <div className='col-xs-12 no-left-gutter'>
            <p className='header-18 light-grey-text flat-bottom'>{t('user.restaurantLabel')}</p>
            <p className='header-20 flat-top'>{favoriteRestaurant}</p>
          </div>
        </div>
      )
    }

    const stateLevelAddition = !!stateLevel ? `, ${stateLevel}` : null

    const Location = () => (
      <div className='row no-right-gutter thin-bottom middle-xs'>
        <div className='col-xs-2 no-left-gutter center-xs'>
          <i className='fa fa-map-marker dark-grey-text header-26' />
        </div>
        <div className='col-xs-9 header-18 no-left-gutter'>
          {city}
          {stateLevelAddition}
        </div>
      </div>
    )

    const HostedConversations = () => {
      if (!createdConversationsCount || createdConversationsCount < 1) return null
      return (
        <div className='row no-right-gutter thin-bottom middle-xs'>
          <div className='col-xs-2 no-left-gutter center-xs'>
            <i className='fas fa-comments dark-grey-text header-26' />
          </div>
          <div className='col-xs-10 header-18 no-left-gutter'>{t('user.hostedConversations', { count: createdConversationsCount })}</div>
        </div>
      )
    }

    const RegisteredConversations = () => {
      if (!registeredConversationsCount || registeredConversationsCount < 1) return null
      return (
        <div className='row no-right-gutter thin-bottom middle-xs'>
          <div className='col-xs-2 no-left-gutter center-xs'>
            <i className='fas fa-calendar-check dark-grey-text header-26' />
          </div>
          <div className='col-xs-10 header-18 no-left-gutter'>{t('user.attendedConversations', { count: registeredConversationsCount })}</div>
        </div>
      )
    }

    const InvitedUser = () => {
      if (!invitedUserCount || invitedUserCount < 1) return null
      return (
        <div className='row no-right-gutter thin-bottom middle-xs'>
          <div className='col-xs-2 no-left-gutter center-xs'>
            <i className='fal fa-envelope-open-text dark-grey-text header-26' />
          </div>
          <div className='col-xs-9 header-18 no-left-gutter'>{t('user.invitedPeople', { count: invitedUserCount })}</div>
        </div>
      )
    }

    const userTitle = () => {
      if (!title) return null

      const companyAddition = !!companyName ? ` at ${companyName}` : null

      return (
        <div>
          <h1 className='header-24'>
            {title}
            {companyAddition}
          </h1>
        </div>
      )
    }

    const socialLinks = () => (
      <>
        <FacebookLink />
        <InstagramLink />
        <LinkedinLink />
        <TwitterLink />
        <WebsiteLink />
      </>
    )

    return (
      <div>
        <section className='pane thin-pane darkgrey-pane'>
          <div className='row'>
            <div className='col-xs-12 col-md-3 center-xs width-60-container'>
              <UserAvatar {...user} size='profile-primary' />
              <EditLink />
              {currentUser?.isSuperAdmin() && (
                <Tooltip title='Masquerade'>
                  <IconButton
                    size='small'
                    onClick={() => {
                      history.push('/login')
                      masquerade(user.id)
                    }}
                  >
                    <i className='far fa-theater-masks opacity-8' style={{ color: '#ffffff' }}></i>
                  </IconButton>
                </Tooltip>
              )}
            </div>

            <div className='col-xs-12 col-md-9'>
              <h1 className='header-56 flat-bottom'>{fullName}</h1>
              {publicProfile && pronoun && (
                <h6 className='header-24 flat-bottom' style={{ marginTop: '.5rem' }}>
                  {pronoun}
                </h6>
              )}

              {publicProfile && userTitle()}

              <div className='row no-gutters'>
                <div className='col-xs-12 col-sm-8 no-gutters'>
                  <div className='row no-gutters middle-xs'>{publicProfile && socialLinks()}</div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='pane reg-pane'>
          <div className='row'>
            <div className='col-xs-12 col-md-4 no-gutters'>
              <Location />
              {publicProfile && <HostedConversations />}
              {publicProfile && <ActionsCompleted user={user} />}
              {publicProfile && <RegisteredConversations />}
              {publicProfile && <InvitedUser />}
              {(userConnectionConversationId || !!userConnection) && !viewingSelf && userConnectionActions()}
            </div>

            <div className='col-xs-12 col-md-8'>
              {publicProfile && (
                <div>
                  <Bio />
                  <UserInterests />
                  <MagicWand />
                  <FavoriteFood />
                  <FavoriteRestaurant />
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    )
  }
}

interface Props {
  t: any
  match: any
}
interface InjectedProps {
  currentUser?: IUser
  user?: IUser
  loadingUser: boolean
  userConnection?: IUserConnection
  userConnectionConversationId?: any
  loadedCurrentUser: boolean
  showDialog(name: string)
  masquerade(id: number)
  viewingSelf: boolean
}

export default flow(
  withTranslation,
  withRouter,
  observer,
  inject<InjectedProps, Props>(({ mst }: { mst: IStore }, ownProps) => {
    const { auth, users, dialogs } = mst
    const {
      match: {
        params: { id }
      }
    } = ownProps
    const userId = parseInt(id, 10)
    const user = users.list.find((u) => u.id === userId)
    !user && users.shouldLoad(userId, users.getUser(userId))
    const currentUser = auth.user

    return {
      currentUser,
      user,
      userConnectionCount: currentUser?.userConnections.length,
      userConnection: currentUser?.userConnections.find((uc) => uc),
      userConnectionConversationId: currentUser?.userConnectionConversationId,
      loadingUser: users.loadingIds.includes(userId),
      loadedCurrentUser: auth.loaded,
      showDialog: dialogs.showDialog,
      viewingSelf: currentUser?.id === user?.id,
      masquerade: auth.masquerade
    }
  })
)(UserProfile)
