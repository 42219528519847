import React from 'react'
import { observer, inject } from 'mobx-react'
import { IStore } from '../../store/models'

export default function mstReduxBridge(Component: FIXME) {
  return inject<any, any>(({ mst: { dialogs, alertMessages } }: { mst: IStore }) => {
    return {
      alertMessages,
      dialogs
    }
  })(
    observer(
      class extends React.PureComponent {
        render() {
          return (
            <div>
              <Component {...this.props} />
            </div>
          )
        }
      }
    )
  )
}
