import React from 'react'
import { Card, CardHeader, CardContent, Grid } from '@material-ui/core'
import { ResponsiveBar } from '@nivo/bar'
import classes from '../../../../stylesheets/admin/ResponsiveBarCard.module.scss'
import palette from '../../../../themes/palette'
import { IsMobile } from '../../../hooks/useWindowSize'
import { truncate } from 'lodash'
import BarGraphToolTip from './BarGraphToolTip'

interface Props {
  title: string
  graphData: any
  chartColor: string
  headerAction?: React.ReactNode
  yKeyName?: string
  loading?: boolean
  id?: string
}

const ResponsiveBarCard = ({ title, graphData = [], chartColor, headerAction, yKeyName = 'y', loading, id }: Props) => {
  const data = graphData.map(({ x, y }: any) => ({ x, [yKeyName]: y }))
  return (
    <Grid item xs={12}>
      <Card className='max-height' id={id}>
        <CardHeader title={title} action={headerAction} />
        <CardContent>
          <div className={classes.responsiveBarContainer}>
            {
              <ResponsiveBar
                data={data}
                indexBy='x'
                keys={[yKeyName]}
                margin={{ top: 0, right: 20, bottom: 50, left: IsMobile() ? 100 : 200 }}
                padding={0.3}
                layout='horizontal'
                valueScale={{ type: 'linear' }}
                enableGridY={false}
                enableGridX={true}
                colors={[chartColor]}
                borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  format: (value) => (Number(value) % 1 === 0 ? Number(value).toFixed() : ''),
                  tickSize: 0
                }}
                axisLeft={{
                  tickSize: 0,
                  format: (v: any) =>
                    truncate(v.replace(/ \([0-9].*\)$/, ''), {
                      length: IsMobile() ? 15 : 30
                    })
                }}
                labelTextColor={palette.white}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                theme={{
                  fontSize: 12,
                  textColor: '#a2a3a8',
                  legends: {
                    text: {
                      fontWeight: 600,
                      fill: '#6d6e71',
                      fontFamily: 'avenir, sans-serif'
                    }
                  }
                }}
                tooltip={(input) => <BarGraphToolTip input={input} />}
                legends={[
                  {
                    dataFrom: 'keys',
                    anchor: 'bottom',
                    direction: 'row',
                    justify: false,
                    translateX: -2,
                    translateY: 40,
                    itemsSpacing: 6,
                    itemDirection: 'left-to-right',
                    itemWidth: 75,
                    itemHeight: 10,
                    itemOpacity: 1,
                    symbolSize: 0,
                    symbolShape: 'square',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)'
                  }
                ]}
              />
            }
          </div>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default ResponsiveBarCard
