import React, { useEffect } from 'react'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import * as validations from '../validations/fieldLevelValidations'
import { useForm } from 'react-hook-form'
import { TextFieldControl, SelectControl, WysiwygControl, localOptions, FileButtonControl, SwitchControl } from '../utils'
import { camelCase } from 'lodash'
import classnames from 'classnames'
import i18next from 'i18next'
import { capitalize, snakify } from '../../decorators/textTools'
import { observer, inject } from 'mobx-react'
import { IStore } from '../../store/models'
import { Chip, Tooltip } from '@material-ui/core'
import moment from 'moment'

// eslint-disable-next-line complexity
const PartnerForm = ({ partner, organizations, onSave, onDirty, submit, createPartner, onSubmit, user }: FIXME) => {
  const { handleSubmit, errors, formState, control, watch, setError, reset, clearErrors, setValue } = useForm({ mode: 'onBlur' })
  const { isDirty, isSubmitting, dirtyFields } = formState

  useEffect(() => {
    partner && partner.setDirty(isDirty)
    onDirty && onDirty(isDirty)
  }, [partner, isDirty, onDirty])

  const displayError = (error) => {
    if (error) {
      for (const fieldName in error) {
        setError(camelCase(fieldName), {
          type: 'manual',
          message: error[fieldName]
        })
      }
    }
  }

  const afterSave = async ({ data = undefined, error = undefined }: { error?: any; data?: any } = {}) => {
    onDirty && onDirty(false)
    if (error) {
      displayError(error)
    } else {
      data && reset()
      partner?.setDirty(false)
      onSave && onSave(data)
    }
    onSubmit && onSubmit(false)
    return data
  }

  const handleSave = handleSubmit(({ timezones, track, ...updateData }: any, e) => {
    onSubmit && onSubmit(true)
    clearErrors()
    const timezonesUpdates = dirtyFields?.timezones ? { timezones } : {}
    if (partner) {
      partner
        .updatePartner({ ...updateData, ...timezonesUpdates, tracks_attributes: [snakify({ ...track, ...(partner?.tracks[0] ? { id: partner?.tracks[0]?.id } : {}) })] })
        .then(afterSave)
    } else {
      createPartner({
        metaDescription: i18next.t('meta.defaultDescription'),
        metaTitle: `${capitalize(window.siteTitle)} - ${updateData.name}`,
        ...updateData,
        ...timezonesUpdates,
        tracks_attributes: [snakify({ ...track })]
      }).then(afterSave)
    }
  })

  useEffect(() => {
    onDirty && onDirty(isDirty && !isSubmitting)
  }, [onDirty, isDirty, isSubmitting])

  useEffect(() => {
    if (isDirty && submit && !isSubmitting) {
      handleSave()
    }

    submit && onDirty && onDirty(false)
  }, [submit, isDirty, isSubmitting, handleSave, onDirty])

  const currentFeaturedConversations = watch('featuredConversations') === undefined ? partner?.featuredConversations : watch('featuredConversations')
  const organizationsOptions = organizations && organizations.list.map((o) => ({ label: o.name, value: o.id }))
  const track = partner?.tracks[0]

  return (
    <Card className='no-padding xtra-huge-bottom no-shadow'>
      <form onSubmit={handleSave}>
        {organizations && (
          <SelectControl
            name='organizationId'
            control={control}
            defaultValue={partner?.organizationId}
            error={errors && errors.organizationId}
            label={'Organization'}
            options={organizationsOptions}
            className='reg-bottom'
          />
        )}

        <TextFieldControl
          name='name'
          control={control}
          defaultValue={partner?.name || ''}
          error={errors && errors.name}
          label={'Name'}
          required
          rules={{ validate: validations.required }}
          className='reg-bottom'
        />

        <TextFieldControl
          name='slug'
          control={control}
          defaultValue={partner?.slug || ''}
          error={errors && errors.slug}
          label={'Slug'}
          required
          rules={{ validate: { v1: validations.required, v2: validations.notSlugFriendly } }}
          className='reg-bottom'
        />

        <TextFieldControl
          name='website'
          control={control}
          defaultValue={partner?.website || ''}
          error={errors && errors.website}
          label={'Website'}
          rules={{ validate: validations.http }}
          className='reg-bottom'
        />

        {user?.isSuperAdmin() && (
          <SwitchControl
            name='userConnections'
            control={control}
            defaultValue={partner?.userConnections || false}
            error={errors && errors.userConnections}
            label={'Enable Guest Connections'}
            helperText='When enabled, guests will receive email communications inviting guests to connect with each other.'
            className='fat-top fat-bottom'
          />
        )}

        <WysiwygControl name='about' control={control} defaultValue={partner?.about || ''} error={errors && errors.about} label={'Description'} className='reg-bottom' />

        <SelectControl
          name='locale'
          control={control}
          defaultValue={partner?.locale?.toString() || 'en_US'}
          error={errors && errors.locale}
          label={'Language'}
          options={localOptions}
          required
          rules={{ validate: validations.required }}
          className='reg-bottom'
        />

        <SwitchControl
          name='featuredConversations'
          control={control}
          defaultValue={partner?.featuredConversations || false}
          error={errors && errors.featuredConversations}
          label={'Featured Events Section'}
          helperText='When enabled, the section appears if events are promoted to this section from the Conversation page settings.'
          className='fat-top fat-bottom'
          onChange={(val) => {
            if (!partner?.featuredConversationsTitle && !watch('featuredConversationsTitle')) {
              setValue('featuredConversationsTitle', 'Featured Events', { shouldDirty: true })
            }
          }}
        />

        <TextFieldControl
          name='featuredConversationsTitle'
          control={control}
          defaultValue={partner?.featuredConversationsTitle}
          error={errors && errors.featuredConversationsTitle}
          label={'Featured Events Section Title'}
          required={watch('featuredConversations')}
          rules={currentFeaturedConversations ? { validate: validations.required } : undefined}
          className={classnames('reg-bottom', ...(currentFeaturedConversations ? [] : ['hide']))}
        />

        <FileButtonControl
          className='reg-top reg-bottom'
          name='backgroundImage'
          control={control}
          defaultValue={partner?.backgroundImage}
          error={errors && errors.backgroundImage}
          helperText='Background Image - for best results, use a photo with a width no smaller than 2732px wide. Height can be variable but 1500px is ideal.'
          onDelete={() => {
            setValue('remove_backgroundImage', true, { shouldDirty: true })
          }}
          deletable
        />

        <FileButtonControl
          className='reg-top reg-bottom'
          name='profileImage'
          control={control}
          defaultValue={partner?.profileImage}
          error={errors && errors.profileImage}
          helperText='Profile Image - for best results, image should be 400px x 400px'
          onDelete={() => {
            setValue('remove_profileImage', true, { shouldDirty: true })
          }}
          deletable
        />

        {/* <div className='fat-top fat-bottom'>
          <p className='xtra-fat-top'>Choose Region</p>
          <div className='label reg-top bold header-20'>Note: If no area is selected, the topic will be available globally.</div>
          <MapRegionControl
            name='regionCoordinates'
            control={control}
            defaultValue={partner ? Array.from(partner.regionCoordinates) : []}
          error={errors && errors.regionCoordinates}
            className='reg-bottom'
          />
        </div> */}

        <TextFieldControl name='location' control={control} defaultValue={partner?.location || ''} error={errors && errors.location} label={'Location'} className='reg-bottom' />

        <TextFieldControl
          name='facebookLink'
          control={control}
          defaultValue={partner?.facebookLink}
          error={errors && errors.facebookLink}
          label={'Facebook link'}
          rules={{ validate: validations.http }}
          className='reg-bottom'
        />

        <TextFieldControl
          name='twitterLink'
          control={control}
          defaultValue={partner?.twitterLink}
          error={errors && errors.twitterLink}
          label={'Twitter link'}
          rules={{ validate: validations.http }}
          className='reg-bottom'
        />

        <TextFieldControl
          name='instagramLink'
          control={control}
          defaultValue={partner?.instagramLink}
          error={errors && errors.instagramLink}
          label={'Instagram link'}
          rules={{ validate: validations.http }}
          className='reg-bottom'
        />
        {user.isSuperAdmin() && (
          <div className='fat-top'>
            <Tooltip title='Enabled by Super Admins only.' arrow>
              <Chip label='Super Admin' color='secondary' className='fat-top' style={{ backgroundColor: '#e495be40', color: '#212121' }} />
            </Tooltip>
            <SwitchControl
              name='churnzeroSync'
              control={control}
              defaultValue={partner?.churnzeroSync || false}
              error={errors && errors.churnzeroSync}
              label={'Sync with Churnzero'}
              helperText='Enables Churnzero integration for this client'
              className='fat-top fat-bottom'
            />

            <SwitchControl
              name='enableSurveyScoring'
              control={control}
              defaultValue={partner?.enableSurveyScoring || false}
              error={errors && errors.enableSurveyScoring}
              label={'Enable Scoring on RSVP Questions'}
              helperText='Enables you to assign score value to each option on multiple-choice RSVP questions.'
              className='fat-top fat-bottom'
            />

            <SwitchControl
              name='allowDocumentUpload'
              control={control}
              defaultValue={partner?.allowDocumentUpload || false}
              error={errors && errors.allowDocumentUpload}
              label={'Allow Document Upload'}
              helperText='Allow Partner admin to upload document'
              className='fat-top fat-bottom'
            />

            <SwitchControl
              name='allowVideos'
              control={control}
              defaultValue={partner?.allowVideos || false}
              error={errors && errors.allowVideos}
              label={'Allow Videos'}
              helperText='Allow Partner admin to upload videos'
              className='fat-top fat-bottom'
            />

            <SwitchControl
              name='privateLabel'
              control={control}
              defaultValue={partner?.privateLabel || false}
              error={errors && errors.privateLabel}
              label={'Enable Private Label'}
              helperText='Enables Private Label'
              className='fat-top fat-bottom'
            />

            <SwitchControl
              name='isJourney'
              control={control}
              defaultValue={partner?.isJourney || false}
              error={errors && errors.isJourney}
              label={'Is Journey'}
              helperText='Enables Journey for this client'
              className='fat-top fat-bottom'
            />
            {(watch('isJourney') !== undefined ? watch('isJourney') : partner?.isJourney) && (
              <>
                <TextFieldControl
                  name='track.name'
                  control={control}
                  defaultValue={track?.name}
                  error={errors && errors.track?.name}
                  label={'Journey Name'}
                  rules={{ validate: validations.required }}
                  required
                  className='reg-bottom'
                />

                <TextFieldControl
                  name='track.dealName'
                  control={control}
                  defaultValue={track?.dealName}
                  error={errors && errors.track?.dealName}
                  label={'Journey Deal Name'}
                  rules={{ validate: validations.required }}
                  required
                  className='fat-bottom'
                />
              </>
            )}

            <TextFieldControl
              name='maxTopics'
              control={control}
              defaultValue={partner?.maxTopics !== null && partner?.maxTopics !== undefined ? partner?.maxTopics.toString() : 6}
              error={errors && errors.maxTopics}
              label={'Maximum Topics'}
              rules={{ validate: { min: validations.minNumber(0) } }}
              className='reg-bottom fat-top'
              type='number'
            />

            <SelectControl
              name='timezones'
              label='Timezones'
              multiple={true}
              control={control}
              error={errors && errors.timezones}
              defaultValue={partner?.timezones || []}
              rules={{ validate: validations.required }}
              options={moment.tz.names().map((value) => ({ label: value, value }))}
            />
            <TextFieldControl
              name='googleTagId'
              control={control}
              defaultValue={partner?.googleTagId}
              error={errors && errors?.googleTagId}
              label={'Google Tag Id'}
              className='fat-bottom reg-top'
            />
          </div>
        )}
        <Button disabled={!isDirty || isSubmitting} type='submit' className='giant-top' color='secondary' variant='contained'>
          Submit
        </Button>
      </form>
    </Card>
  )
}

export default inject<FIXME, FIXME>(
  ({
    mst: {
      auth: { user }
    }
  }: {
    mst: IStore
  }) => {
    return { user }
  }
)(observer(PartnerForm))
