import React from 'react'
import { Box, Grid } from '@material-ui/core'
import StatsColumn from './StatsColumn'
import { humanize } from '../../../../decorators/textTools'

interface Props {
  totalStats: any
  inLineStats: any
}

const TopLineStats = ({ totalStats = {}, inLineStats = [] }: Props) => {
  const { key, count } = totalStats
  const threeCol = inLineStats.length > 4

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={threeCol ? 4 : 8}>
        <Box fontSize={14} fontWeight={500}>
          {humanize(key)}
        </Box>

        <Grid container spacing={0} alignItems='center'>
          <Grid item>
            <Box fontSize={34} lineHeight={1.2} fontWeight={500}>
              {count}
            </Box>
          </Grid>
        </Grid>
      </Grid>

      {threeCol && <StatsColumn inLineStats={inLineStats.slice(4)} />}

      <StatsColumn inLineStats={inLineStats.slice(0, 4)} />
    </Grid>
  )
}

export default TopLineStats
