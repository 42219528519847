import { Breadcrumbs, Button, Typography } from '@material-ui/core'
import { flow } from 'lodash'
import { inject } from 'mobx-react'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { withRouter, RouteComponentProps, Prompt } from 'react-router'
import { IStore } from '../../../../store/models'
import { ITopic } from '../../../../store/models/Topic'
import EditTopicPage from '../../../topics/Edit'
import { UNSAVED_FORM_WARNING } from '../../../utils/constants'
import GroupedButtons from '../partials/GroupedButtons'
import SummaryContainer from '../partials/SummaryContainer'
import AdminMetaTags from '../../../shared/AdminMetaTags'
import { Link } from 'react-router-dom'
interface Props {
  topic?: ITopic
}

const Edit = ({ topic, history }: Props & RouteComponentProps) => {
  const [submit, setSubmit] = useState(false)
  const [dirty, setDirty] = useState(false)
  const [tabIndex, setTabIndex] = useState(0)
  const [submitting, setSubmitting] = useState(false)
  return (
    <div>
      <AdminMetaTags title='Topics' pageType='Edit' slug={topic?.slug} />

      <Breadcrumbs aria-label='breadcrumb'>
        <Link color='primary' to='/admin/topics'>
          Topics
        </Link>
        <Typography color='textPrimary'>Edit Topic</Typography>
      </Breadcrumbs>
      <SummaryContainer
        title={topic?.title}
        topRight={
          <GroupedButtons>
            <Button rel='noopener' href={`/${topic?.slug}`} target='blank' variant='contained' startIcon={<i className='far fa-external-link-square'></i>}>
              View Saved Page
            </Button>{' '}
            {[0].includes(tabIndex) && (
              <Button
                disabled={!topic?.dirty || submit || submitting}
                color='primary'
                onClick={() => {
                  setSubmit(true)
                }}
                variant='contained'
                disableElevation
              >
                Save Changes
              </Button>
            )}
          </GroupedButtons>
        }
      >
        <EditTopicPage
          submit={submit}
          onTabChange={(index) => setTabIndex(index)}
          onDirty={(d) => {
            setDirty(d)
            setSubmit(false)
          }}
          onSubmit={(val) => setSubmitting(val)}
        />
        <Prompt when={dirty && topic?.dirty} message={UNSAVED_FORM_WARNING} />
      </SummaryContainer>
    </div>
  )
}

// export default Edit

export default flow(
  observer,
  inject<FIXME, FIXME>(
    (
      {
        mst: { topics }
      }: {
        mst: IStore
      },
      {
        match: {
          params: { slug }
        }
      }
    ) => {
      const topic = topics.list.find((d) => d.slug === slug)
      !topic && topics.shouldLoad(slug, () => topics.loadBySlug(slug))

      return {
        topic,
        topicLoading: topics.loadingIds.includes(slug)
      }
    }
  ),
  withRouter
)(Edit)
