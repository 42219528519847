import React from 'react'
import NpsAnswersSummary from './NpsAnswersSummary'
import AdminMetaTags from '../../../shared/AdminMetaTags'

interface Props {}

const NpsAnswers = (props: Props) => {
  return (
    <>
      <AdminMetaTags title='Feedback' />
      <NpsAnswersSummary />
    </>
  )
}

export default NpsAnswers
