import React, { useState } from 'react'
import { size, isEmpty } from 'lodash'
import { Button } from '@material-ui/core'

import SponsorForm from './FormMst'
import SponsorShow from './Show'
import { observer } from 'mobx-react'

const SponsorsEdit = ({ section }: FIXME) => {
  const [newForm, setnewForm] = useState(false)
  const [sponsor, setsponsor] = useState<any>(null)

  const closeNewSponsor = () => {
    setnewForm(false)
    setsponsor(undefined)
    sponsor?.setDirty(false)
  }
  let sponsorThumbs: any = null
  const sponsors = section.sponsors
  if (!isEmpty(sponsors)) {
    sponsorThumbs = sponsors.map((s) => <SponsorShow key={s.id} sponsor={s} onClick={(item) => setsponsor(item)} />)
  }

  return (
    <div>
      <div className='row middle-xs xtra-fat-bottom xtra-fat-top'>
        {sponsorThumbs}

        <div className='col-xs-6 relative fat-bottom'>
          {newForm ? (
            <SponsorForm
              sponsor={null}
              action='create'
              section={section}
              sectionId={section.id}
              form={`NewSponsorForm-id${size(sponsors) > 0 ? size(sponsors) + 1 : size(sponsors)}`}
              closeForm={closeNewSponsor}
            />
          ) : sponsor ? (
            <SponsorForm section={section} sponsor={sponsor} action='update' sectionId={section.id} form={`EditSponsorForm-id${sponsor.id}`} closeForm={closeNewSponsor} />
          ) : (
            <Button
              onClick={() => {
                setnewForm(true)
              }}
              variant='contained'
              color='primary'
              className='fat-top'
            >
              Add a Sponsor
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default observer(SponsorsEdit)
