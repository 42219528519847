import React from 'react'
import { Typography } from '@material-ui/core'

interface Props {
  title: string
  value: string | number | undefined
}

export const ValueBox = ({ title, value }: Props) => {
  return (
    <>
      <Typography component='h2' variant='subtitle2' color='textSecondary' gutterBottom align='center'>
        {title}
      </Typography>
      <Typography component='p' variant='h4' color='textPrimary' align='center'>
        {value}
      </Typography>
    </>
  )
}
