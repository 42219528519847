import { assign, merge, omit } from 'lodash'

import * as types from '../actions/actionTypes'
import initialState from './initialState'

export const users = (state = initialState.users, action) => {
  var user

  switch (action.type) {
    // NOTE: this is kicked off by a conversation being loaded
    case types.LOAD_DINNER_SUCCESS:
      return assign({}, state, {
        list: merge({}, state.list, action.users)
      })

    case types.LOAD_DINNERS_SUCCESS:
      return assign({}, state, {
        list: merge({}, state.list, action.users)
      })

    case types.LOAD_NEW_DINNERS_SUCCESS:
      return assign({}, state, {
        list: merge({}, state.list, action.users)
      })

    case types.LOAD_CONVERSATION_SUCCESS:
      return assign({}, state, {
        list: merge({}, state.list, action.users)
      })

    case types.LOAD_NEW_CONVERSATION_DINNERS_SUCCESS:
      return assign({}, state, {
        list: merge({}, state.list, action.users)
      })

    case types.LOAD_CONVERSATION_DINNERS_SUCCESS:
      return assign({}, state, {
        list: merge({}, state.list, action.users)
      })

    case types.LOAD_NEW_PARTNER_DINNERS_SUCCESS:
      return assign({}, state, {
        list: merge({}, state.list, action.users)
      })

    case types.LOAD_PARTNER_DINNERS_SUCCESS:
      return assign({}, state, {
        list: merge({}, state.list, action.users)
      })

    case types.LOAD_USERS_SUCCESS:
      return assign({}, state, {
        error: null,
        list: merge({}, state.list, action.users),
        loading: false
      })

    case types.LOAD_USERS_FAILED:
      return assign({}, state, {
        error: action.data,
        loading: false
      })

    case types.USERS_LOADING:
      return assign({}, state, {
        error: null,
        loading: true
      })

    case types.LOAD_USER_SUCCESS:
      user = action.data
      return assign({}, state, {
        error: null,
        list: merge({}, state.list, { [user.id]: user }),
        loading: false
      })

    case types.LOAD_CURRENT_USER_SUCCESS:
      user = action.user
      return assign({}, state, {
        list: merge({}, state.list, { [user.id]: user })
      })

    case types.UPDATE_USER_SUCCESS:
      user = action.data.json.user
      return assign({}, state, {
        list: merge({}, state.list, { [user.id]: user })
      })

    case types.UPDATE_USER_FAILED:
      return assign({}, state, {
        error: action.data,
        loading: false
      })

    case types.DELETE_USER_SUCCESS:
      return assign({}, state, {
        error: null,
        loading: false,
        list: omit(state.list, action.data)
      })

    default:
      return state
  }
}

export default users
