import { isAlive, types } from 'mobx-state-tree'

export const LoadableStatus = types
  .model({
    loadingIds: types.optional(types.array(types.union(types.number, types.string)), []),
    loadedIds: types.optional(types.array(types.union(types.number, types.string)), [])
  })
  .actions((self) => ({
    isLoading: (id: number | string) => {
      return self.loadingIds.includes(id)
    },
    isLoaded: (id: number | string) => {
      return self.loadedIds.includes(id)
    },

    startLoading: (id: number | string) => {
      if (self.loadingIds.includes(id)) return
      self.loadingIds.push(id)
    },
    stopLoading: (id: number | string, showLoaded: boolean = true) => {
      if (!isAlive(self)) return
      const newIds = self.loadingIds.filter((n) => n !== id)
      self.loadingIds.replace(newIds)
      if (showLoaded && !self.loadedIds.includes(id)) {
        self.loadedIds.push(id)
      }
    }
  }))
  .actions((self) => ({
    shouldLoad: (loadName: string | number, loader: any) => {
      const loaded = self.loadedIds.includes(loadName) || self.loadingIds.includes(loadName)
      if (!loaded && loader) {
        return loader()
      }
      return Promise.resolve(loaded)
    }
  }))
