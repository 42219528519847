import TopicActionsApi from '../../../api/topics/ActionsApi'
import ActionsApi from '../../../api/ActionsApi'
import UserActionsApi from '../../../api/UserActionsApi'
import { types, Instance, isAlive, getParent, getRoot } from 'mobx-state-tree'
import { removeNullStringValue } from '../utils'
import TopicBase from './TopicBase'
import { camelify, snakify } from '../../../decorators/textTools'
import { Alertable } from '../helpers/Alertable'
import Editable from '../helpers/Editable'
import { RootAccessable } from '../helpers/RootAccessable'

const TopicAction = types
  .compose(
    'TopicAction',
    Alertable,
    Editable,
    RootAccessable,
    types.model({
      id: types.identifierNumber,

      title: types.maybeNull(types.string),
      description: types.maybeNull(types.string),
      resourceLink: types.maybeNull(types.string),
      resourceText: types.maybeNull(types.string),
      rank: types.maybeNull(types.number)
    })
  )
  .actions((self) => ({
    updateAction: (data) => {
      return ActionsApi.update(snakify(data)).then(({ response: { ok, status, statusText }, json }) => {
        if (ok) {
          self.updateMe(camelify(json))
          self.showAlert('Action successfully updated')
        }
      })
    },
    deleteAction: () => {
      const parent: any = getParent(self, 2)
      return parent.deleteAction(self.id)
    },
    createUserAction: (conversationId: number, options?: { finished?: boolean }) => {
      const { user } = self.getAuth()
      return UserActionsApi.create({ actionId: self.id, userId: user?.id, conversationId, options }).then(({ response: { ok }, json }) => {
        if (ok) {
          // self.setUserActions(json)
        }
      })
    }
  }))

export const TopicActions = types
  .compose(
    'TopicActions',
    TopicBase,
    types.model({
      topicActions: types.optional(types.array(TopicAction), [])
    })
  )
  .actions((self) => ({
    setTopicActions(val: any[]) {
      val.forEach((d) => removeNullStringValue(d))
      self.topicActions.replace(val)
    },
    addToActions: (data) => {
      self.topicActions.push(data)
    },
    removeFromActions: (id) => {
      self.topicActions.replace(self.topicActions.filter((ca) => ca.id !== id))
    }
  }))
  .actions((self) => ({
    loadTopicActions: () => {
      if (self.isLoading('topicActions')) {
        return
      }
      self.startLoading('topicActions')
      return TopicActionsApi.getAll(self.id).then((res) => {
        const { ok } = res.response
        if (!isAlive(self)) return
        if (ok) {
          self.setTopicActions(res.json)
        }
        self.stopLoading('topicActions')
      })
    },
    createAction: (data) => {
      return ActionsApi.create(snakify({ ...data, topicId: self.id })).then(({ response: { ok, status, statusText }, json }) => {
        if (ok) {
          self.addToActions(camelify(json))
          self.showAlert('Action successfully created')
        }
      })
    },
    deleteAction: (id: number) => {
      return ActionsApi.destroy({ id }).then(({ response: { ok, status, statusText }, json }) => {
        if (ok) {
          self.removeFromActions(id)
          self.showAlert('Action successfully removed')
        }
      })
    }
  }))

export interface ITopicAction extends Instance<typeof TopicAction> {}
export interface ITopicActions extends Instance<typeof TopicActions> {}
export default TopicActions
