import React, { useState } from 'react'
import { Button, Card, Container, Divider, Grid, IconButton, Tooltip } from '@material-ui/core'
import { ITopicLmsItem } from '../../store/models/Topic/TopicLmsCourses'
import VideoPlayer from './VideoPlayer'
import { ItemForm } from './ItemForm'
import { useParamTopic } from '../Providers'
import LoadingAnimation from '../shared/LoadingAnimation'

interface Props {
  lmsItem: ITopicLmsItem
  onDelete?()
}

export const LmsListItem = ({ lmsItem, onDelete }: Props) => {
  const [edit, setEdit] = useState(false)
  const { topic } = useParamTopic()

  if (!topic) {
    return <LoadingAnimation />
  }

  return (
    <Card className='reg-bottom'>
      <Container>
        <Grid container className='reg-top reg-bottom'>
          {lmsItem.file?.url && (
            <Grid item xs={12} sm={6}>
              <VideoPlayer videoLink={lmsItem.file?.url} hideClose={true} autoPlay={false} />
            </Grid>
          )}

          <Grid item xs={11}>
            {lmsItem.name}
          </Grid>
          <Grid item xs={1}>
            {lmsItem.sortIndex}
          </Grid>
          <Grid item xs={12}>
            {lmsItem.description}
          </Grid>
          <Grid item xs={12}>
            <Button className='reg-top reg-bottom reg-right' variant='contained' onClick={() => setEdit(!edit)}>
              {edit ? 'Cancel' : 'Edit'}
            </Button>
            <Button
              className='reg-top reg-bottom'
              variant='contained'
              onClick={() => {
                if ((window as FIXME).confirm('Are you sure?')) {
                  lmsItem.deleteLmsItem().then(() => {
                    onDelete && onDelete()
                  })
                }
              }}
            >
              Delete
            </Button>
            <Tooltip title='Copy link'>
              <IconButton
                size='small'
                className='reg-left'
                onClick={() => {
                  navigator.clipboard.writeText(`${window.location.origin}/${topic.slug}/videos/${lmsItem.id}`)
                  lmsItem.showAlert('Copied link to clipboard.')
                }}
              >
                <i className='fas fa-copy opacity-6'></i>
              </IconButton>
            </Tooltip>
            {/* <Button
              className='reg-top reg-bottom'
              variant='contained'
              onClick={() => {
                lmsItem.deleteLmsItem()
                onDelete && onDelete()
              }}
            >
              Remove
            </Button> */}
          </Grid>
          {edit && (
            <Grid item xs={12}>
              <ItemForm
                lmsItem={lmsItem}
                onSubmit={(data) => {
                  return lmsItem.updateLmsItem(data).then(() => setEdit(false))
                }}
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </Card>
  )
}
