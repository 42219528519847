import React from 'react'
import { useDrag, useDrop, DragSourceMonitor, DropTargetMonitor } from 'react-dnd'
import { XYCoord } from 'dnd-core'
import { DraggableItemType } from './ItemType'

interface DraggableItemProps {
  id: number
  index: number
  // onMove: (dragIndex: number, hoverIndex: number) => void
  moveItem: (dragIndex: number, hoverIndex: number) => void
  children: React.ReactNode
}

const DraggableItem: React.FC<DraggableItemProps> = ({ id, index, moveItem, children }) => {
  const ref = React.useRef<HTMLDivElement>(null)

  // const [, drag] = useDrag({
  //   type: DraggableItemType.QUESTION_OPTION,
  //   item: { id, index },
  //   collect: (monitor: DragSourceMonitor) => ({
  //     isDragging: monitor.isDragging()
  //   }),
  //   end: () => {
  //     if (ref.current) {
  //       ref.current.style.opacity = '1'
  //     }
  //   }
  // })

  // drag(ref)

  const [, drop] = useDrop({
    accept: DraggableItemType.QUESTION_OPTION,
    hover(item: { id: number; index: number }, monitor) {
      if (item.index === index) {
        return
      }
      moveItem(item.index, index)
      item.index = index
    },
    drop(item: any, monitor) {
      moveItem(item.index, index)
    }
  })

  const [{ isDragging }, drag] = useDrag({
    type: DraggableItemType.QUESTION_OPTION,
    item: () => {
      return { id, index }
    },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging()
    }),
    end: (_item: { id: number; index: number }, monitor: DragSourceMonitor) => {
      if (!monitor.didDrop()) {
        // Call moveItem with the same index to trigger a re-render of the list
        moveItem(index, index)
      }
    }
  })

  const opacity = isDragging ? 0.4 : 1
  const style: React.CSSProperties = { cursor: 'move', opacity }

  drag(drop(ref))

  return (
    <div
      ref={ref}
      // onDragOver={(e) => {
      //   e.preventDefault()
      // }}
      style={style}
    >
      {children}
    </div>
  )
}

export default DraggableItem
