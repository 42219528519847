import React from 'react'
import Button from '@material-ui/core/Button'

import withTranslation from '../hocs/withTranslation'
import Dialog from '../DialogWrapper'
import { observer, inject } from 'mobx-react'
import { IStore } from '../../store/models'
import { withRouter } from 'react-router'
import { IUser } from '../../store/models/User'
import { IDialog } from '../../store/models/Dialog'
import { flow } from 'lodash'

interface Props {
  t?: any
  history?: any
  data: any
  dialog: IDialog
}
interface InjectedProps {
  user?: IUser
  loadingUser: boolean
}

const DeleteUserDialog = ({ t, dialog, user, history }: Props & InjectedProps) => {
  const handleClose = (e) => {
    e.stopPropagation()
    e.preventDefault()
    dialog.closeDialog()
  }

  const handleSave = (e) => {
    e.stopPropagation()
    e.preventDefault()
    user
      ? user.deleteUser().then(() => {
          dialog.closeDialog()
          window.location.href = '/'
        })
      : dialog.closeDialog()
  }

  return (
    <Dialog name='deleteUser'>
      <div className='align-center'>
        <h2>{t('user.sureDelete')}</h2>
        <p>{t('user.cantundo')}</p>

        <form onSubmit={handleSave}>
          <div className='row center-xs'>
            <div className='col-xs-12'>
              <Button className='reg-bottom xtra-fat-top' onClick={handleClose} variant='contained' color='secondary'>
                {t('user.cancelDeleteAccount')}
              </Button>
            </div>
          </div>

          <div className='row center-xs'>
            <div className='col-xs-12'>
              <Button type='submit' className='reg-bottom'>
                {t('user.confirmDeleteAccount')}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Dialog>
  )
}

export default flow(
  withRouter,
  withTranslation,
  observer,
  inject<InjectedProps, Props>(({ mst }: { mst: IStore }, ownProps) => {
    const { users } = mst
    const {
      dialog: { data }
    } = ownProps
    const id = (data as any).id
    const user = users.list.find((u) => u.id === id)
    const loadingUser = users.loadingIds.includes(id)
    if (!user && !loadingUser) {
      users.getUser(id)
    }
    return {
      user,
      loadingUser
    }
  })
)(DeleteUserDialog)
