import { types, Instance } from 'mobx-state-tree'
import AlertMessages from './AlertMessage'
import Analytics from './Analytics'
import Organizations from './Organizations'
import Auth from './Auth'
import Topics from './Topics'
import Tracks from './Tracks'
import Dialogs from './Dialog'
import Conversations from './Conversations'
import Downloads from './Downloads'
import Partners from './Partners'
import Users from './Users'
import { AttendeesBar, AttendeesLine, ConversationsBar, ConversationsLine } from './AnalyticsData'
import Nps from './Nps'
import Stories from './Stories'
import NpsAnswers from './NpsAnswers'
import TopicSubscribers from './TopicSubscribers'

export const Root = types
  .model('Root', {
    alertMessages: AlertMessages,
    dialogs: Dialogs,
    auth: Auth,
    users: Users,
    organizations: Organizations,
    partners: Partners,
    tracks: Tracks,
    topics: Topics,
    conversations: Conversations,
    downloads: Downloads,
    analytics: Analytics,
    nps: Nps,
    nps_answers: NpsAnswers,
    stories: Stories,
    topic_subscribers: TopicSubscribers
  })
  .actions((self) => ({
    reset: () => {
      self.auth = Auth.create()
      self.users = Users.create()
      self.topics = Topics.create()
      self.conversations = Conversations.create()
      self.downloads = Downloads.create()
      self.nps = Nps.create()
      self.topic_subscribers = TopicSubscribers.create()
      self.analytics = Analytics.create({
        attendeesBar: AttendeesBar.create({}),
        conversationsBar: ConversationsBar.create({}),
        conversationsLine: ConversationsLine.create({}),
        attendeesLine: AttendeesLine.create({})
      })
    },
    softReset: () => {
      self.auth.softReset()
      self.users = Users.create()
      self.topics = Topics.create()
      self.conversations = Conversations.create()
      self.downloads = Downloads.create()
      self.nps = Nps.create()
      self.nps_answers = NpsAnswers.create()
      self.analytics = Analytics.create({
        attendeesBar: AttendeesBar.create({}),
        conversationsBar: ConversationsBar.create({}),
        conversationsLine: ConversationsLine.create({}),
        attendeesLine: AttendeesLine.create({})
      })
    }
  }))

export interface IRoot extends Instance<typeof Root> {}
export default Root
