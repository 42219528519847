import React from 'react'
import AddressAutocomplete from '../AddressAutocomplete.js'
import withTranslation from '../hocs/withTranslation'

const LocationFilter = (props) => (
  <AddressAutocomplete
    name='location'
    label={props.t('home.locationPlaceholder')}
    types={[]}
    updateForm={props.updateForm}
    classes={{ root: 'find-conversation-filter' }}
    inputProps={{
      disableUnderline: true,
      classes: {
        root: 'location-filter-input-root',
        input: 'location-filter-input'
      }
    }}
  />
)

export default withTranslation(LocationFilter)
