import React, { useEffect, useState } from 'react'
import { Route, withRouter } from 'react-router'

import StoryCollectionPage from '../components/StoryCollection'
import PartnerPage from '../components/partners/Show'
import TopicPage from '../components/topics/Show'
import TopicVideos from '../components/topics/Show/Videos'
import TopicVideo from '../components/topics/Show/Video'
import EditTopicPage from '../components/topics/Edit'
import ConversationPage from '../components/conversations/Show/New'
import NewConversationPage from '../components/conversations/New'
import NewSynthConversationPage from '../components/conversations/Synth'
import EditConversationPage from '../components/conversations/Edit/HostEdit'
import FetchConversationWrapper from '../components/conversations/FetchWrapper'
import defaultMetaTags from '../components/hocs/defaultMetaTags'
import { get } from '../api/utils'
import { redirectableConvo } from '../regionalRecords'
import mstReduxBridge from '../components/hocs/mstReduxBridge'
import LoadingAnimation from '../components/shared/LoadingAnimation'
import DocumentList from '../components/documents/DocumentList'
import LoggedInOnly, { LoggedInPageWithProviders, PageWithProviders } from './LoggedInOnly'
import ParamTopicProvider from '../components/Providers/ParamTopicProvider'
import ParamConversationProvider from '../components/Providers/ParamConversationProvider'
import ParamPartnerProvider from '../components/Providers/ParamPartnerProvider'
import { IPartner, ITopic } from '../store'
import { Container, Typography } from '@material-ui/core'

const EditTopic = (props) => (
  <LoggedInOnly validation={({ topic }) => !!topic?.canEdit}>
    <EditTopicPage {...props} />
  </LoggedInOnly>
)

const TopicConversation = PageWithProviders(
  mstReduxBridge((props) => (
    <FetchConversationWrapper {...props}>
      <ConversationPage {...props} />
    </FetchConversationWrapper>
  )),
  [ParamTopicProvider, ParamConversationProvider]
)

const NewTopicConversation = LoggedInPageWithProviders(NewConversationPage, [ParamTopicProvider])
const NewSynthTopicConversation = LoggedInPageWithProviders(NewSynthConversationPage, [ParamTopicProvider])

export const SlugPage = withRouter(
  ({
    match: {
      params: { slug: rawSlug }
    }
  }) => {
    const slug = rawSlug.toLowerCase()
    const [myState, setMyState] = useState<{ topic: ITopic | null; partner: IPartner | null }>({ topic: null, partner: null })
    const [slugChecked, setSlugChecked] = useState(false)

    useEffect(() => {
      setSlugChecked(false)
      get(`/slugs/${slug}`).then(({ json: { partner, topic } }) => {
        setMyState({ partner, topic })
        setSlugChecked(true)
      })
    }, [slug])

    const { partner, topic } = myState

    if (slugChecked && partner) {
      const PartnerPageWithMeta = mstReduxBridge(defaultMetaTags((props) => <PartnerPage slug={slug} {...props} />))
      return (
        <Route
          key={slug}
          path={'/:slug/:token?/:action?'}
          render={PageWithProviders(
            (props) => (
              <PartnerPageWithMeta {...props} />
            ),
            [ParamPartnerProvider]
          )}
          exact
        />
      )
    } else if (slugChecked && topic /* || redirectableConvo(slug)*/) {
      const ConvoPageWithMeta = PageWithProviders(mstReduxBridge(defaultMetaTags((props) => <TopicPage slug={slug} {...props} />)), [ParamTopicProvider])

      return (
        <>
          <Route path='/:slug/conversations/:conversationId/story' component={StoryCollectionPage} exact />
          <Route
            path='/:slug/conversations/:conversationId/edit/:tab?'
            component={LoggedInPageWithProviders(EditConversationPage, [ParamTopicProvider, ParamConversationProvider])}
            exact
          />
          <Route path='/:slug/conversations/:conversationId/a/:action?' component={TopicConversation} exact />
          <Route path='/:slug/conversations/:conversationId' component={TopicConversation} exact />
          <Route path='/:slug/conversations/:conversationId/nps' component={TopicConversation} exact />
          <Route path='/:slug/dinners/:conversationId' component={TopicConversation} exact />
          <Route path='/:slug/documents/:groupId?' component={DocumentList} exact />
          {!topic.hidden && (
            <>
              <Route path='/:slug/videos/:video_id' component={PageWithProviders(TopicVideo, [ParamTopicProvider])} exact />
              <Route path='/:slug/videos' component={PageWithProviders(TopicVideos, [ParamTopicProvider])} exact />
              <Route path='/:slug/new' component={NewTopicConversation} exact />
              <Route path='/:slug/old' component={NewSynthTopicConversation} exact />
              <Route path='/:slug/edit' component={EditTopic} exact />
              <Route path='/:slug/edit/t/:tab?' component={EditTopic} exact />
              <Route path='/:slug/a/:action?/:action_id?' render={(props) => <ConvoPageWithMeta {...props} />} exact />
              <Route path='/:slug/conversations/a/:action?' component={TopicPage} exact />
              <Route path='/:slug/conversations' component={TopicPage} exact />
              <Route path='/:slug' render={(props) => <ConvoPageWithMeta {...props} />} exact />
            </>
          )}
        </>
      )
    }
    return slugChecked ? <PageNotFound /> : <LoadingAnimation />
  }
)

const PageNotFound = () => (
  <Container>
    <Typography variant='h5'>Oops... page not found.</Typography>
  </Container>
)
