import { assign, keys, omit } from 'lodash'
import initialState from './initialState'
import { LOADING_USER_DINNERS, LOAD_USER_DINNERS_SUCCESS, LOAD_USER_DINNERS_FAILED, CREATE_USER_DINNER_SUCCESS, DELETE_USER_DINNER_SUCCESS } from '../actions/actionTypes'

export const userConversations = (state = initialState.userConversations, action) => {
  switch (action.type) {
    case LOADING_USER_DINNERS:
      return assign({}, state, {
        loading: true,
        error: null
      })

    case LOAD_USER_DINNERS_SUCCESS:
      return assign({}, state, {
        loading: false,
        error: null,
        list: assign({}, action.userConversations)
      })

    case LOAD_USER_DINNERS_FAILED:
      return assign({}, state, {
        loading: false,
        error: action.data
      })

    case CREATE_USER_DINNER_SUCCESS:
      return assign({}, state, {
        loadingConversations: false,
        errorConversations: null,
        list: assign({}, state.list, action.userConversations)
      })

    case DELETE_USER_DINNER_SUCCESS:
      return assign({}, state, {
        loadingConversations: false,
        errorConversations: null,
        list: omit(state.list, keys(action.userConversations))
      })

    default:
      return state
  }
}

export default userConversations
