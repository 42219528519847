import * as types from '../actions/actionTypes'
import { assign, omit } from 'lodash'
import initialState from './initialState'

export const guests = (state = initialState.guests, action) => {
  switch (action.type) {
    case types.LOAD_GUESTS_FAILED:
      return assign({}, state, {
        error: action.data,
        loading: false
      })

    case types.GUESTS_LOADING:
      return assign({}, state, {
        error: null,
        loading: true
      })

    case types.LOAD_GUESTS_SUCCESS:
      return assign({}, state, {
        error: null,
        loading: false,
        list: assign({}, state.list, action.guests)
      })

    case types.LOAD_NEW_GUESTS_SUCCESS:
      return assign({}, state, {
        error: null,
        loading: false,
        list: assign({}, action.guests)
      })

    case types.REMOVE_GUEST:
      return assign({}, state, {
        error: null,
        loading: false,
        list: omit(state.list, action.userId)
      })

    case types.ADD_GUEST:
      return assign({}, state, {
        error: null,
        loading: false,
        list: assign({}, state.list, action.guests)
      })

    default:
      return state
  }
}

export default guests
