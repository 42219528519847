import React, { useEffect } from 'react'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import * as validations from '../../../validations/fieldLevelValidations'
import { useForm } from 'react-hook-form'
import { CheckboxControl, TextFieldControl } from '../../../utils'
import { camelCase, flow } from 'lodash'
import withTranslation from '../../../hocs/withTranslation'

// eslint-disable-next-line complexity
const OrganizationForm = ({ organization, onSave, onDirty, submit, createOrganization, onSubmit, t }: FIXME) => {
  const { handleSubmit, errors, formState, control, watch, setError, reset, clearErrors, setValue } = useForm({ mode: 'onBlur' })
  const { isDirty, isSubmitting, dirtyFields } = formState

  useEffect(() => {
    organization && organization.setDirty(isDirty)
    onDirty && onDirty(isDirty)
  }, [organization, isDirty, onDirty])

  const displayError = (error) => {
    if (error) {
      for (const fieldName in error) {
        setError(camelCase(fieldName), {
          type: 'manual',
          message: error[fieldName]
        })
      }
    }
  }

  const afterSave = async ({ data = undefined, error = undefined }: { error?: any; data?: any } = {}) => {
    onDirty && onDirty(false)
    if (error) {
      displayError(error)
    } else {
      data && reset(data)
      organization?.setDirty(false)
      onSave && onSave(data)
    }
    onSubmit && onSubmit(false)
    return data
  }

  const handleSave = handleSubmit((data: any, e) => {
    onSubmit && onSubmit(true)
    clearErrors()
    organization ? organization.updateOrganization(data).then(afterSave) : createOrganization(data).then(afterSave)
  })

  useEffect(() => {
    onDirty && onDirty(isDirty && !isSubmitting)
  }, [onDirty, isDirty, isSubmitting])

  useEffect(() => {
    if (isDirty && submit && !isSubmitting) {
      handleSave()
    }

    submit && onDirty && onDirty(false)
  }, [submit, isDirty, isSubmitting, handleSave, onDirty])

  return (
    <Card className='no-padding xtra-huge-bottom no-shadow huge-top'>
      <form onSubmit={handleSave}>
        <TextFieldControl
          name='name'
          control={control}
          defaultValue={organization?.name || ''}
          error={errors && errors.name}
          label={'Organization Name'}
          required
          rules={{ validate: validations.required }}
          className='reg-bottom'
        />
        <CheckboxControl
          name='churnzeroSync'
          label={t('organization.churnzeroSync')}
          control={control}
          defaultValue={organization?.churnzeroSync}
          error={errors && errors.churnzeroSync}
        />
        <br />
        <Button disabled={!isDirty || isSubmitting} type='submit' className='giant-top' color='secondary' variant='contained'>
          Save
        </Button>
      </form>
    </Card>
  )
}

export default flow(withTranslation)(OrganizationForm)
