import React from 'react'
import { RadioGroupControl } from '../../utils'
import { StepProps } from './NewStepper'
import withTranslation, { WithTranslation } from '../../hocs/withTranslation'

const HomeConversationType = ({ t, control }: StepProps & WithTranslation) => {
  return (
    <>
      <RadioGroupControl
        name='conversationType'
        control={control}
        options={[
          {
            label: t('conversation.potLuckLabel'),
            value: 'potluck'
          },
          {
            label: t('conversation.mainCourseLabel'),
            value: 'main_course_provided'
          },
          {
            label: t('conversation.fullConversationLabel'),
            value: 'full_conversation_provided'
          },
          {
            label: t('conversation.noFood'),
            value: 'no_food_provided'
          },
          {
            label: t('common.other'),
            value: 'other'
          }
        ]}
      />
    </>
  )
}

export default withTranslation(HomeConversationType)
