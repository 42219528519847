import React from 'react'
import { TextField, Select, Radio, RadioGroup, Checkbox, FormControl, InputLabel, FormControlLabel, FormHelperText, Switch } from '@material-ui/core'
import Datetime from 'react-datetime'
import { withStyles } from '@material-ui/core/styles'
import InputBase from '@material-ui/core/InputBase'
// These are for creating a bridge between redux-form and material-ui

export const renderTextField = ({ label, input, meta: { touched, invalid, error }, ...custom }) => (
  <TextField label={label} placeholder={label} error={touched && invalid} helperText={touched && error} fullWidth={true} {...input} {...custom} />
)

export const renderFormHelper = ({ touched, error }) => {
  if (!(touched && error)) {
    return
  } else {
    return <FormHelperText>{touched && error}</FormHelperText>
  }
}

const CDInput = withStyles((theme) => ({
  input: {
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    textAlign: 'left'
  }
}))(InputBase)

export const renderSelectField = ({ input, label, meta: { touched, error }, variant, className, inputProps, formControlProps, children, SelectDisplayProps, ...custom }) => (
  <FormControl error={touched && error} className={className} variant={variant} fullWidth>
    <InputLabel shrink>{label}</InputLabel>
    <Select {...input} {...custom} {...inputProps} {...SelectDisplayProps} displayEmpty>
      {children}
    </Select>
    {renderFormHelper({ touched, error })}
  </FormControl>
)

export const renderFilterSelectField = ({ input, label, meta: { touched, error }, variant, className, inputProps, formControlProps, children, SelectDisplayProps, ...custom }) => (
  <FormControl error={touched && error} className={className} variant={variant} fullWidth>
    <InputLabel shrink>{label}</InputLabel>
    <Select input={<CDInput fullWidth />} {...input} {...custom} {...inputProps} {...SelectDisplayProps} displayEmpty>
      {children}
    </Select>
    {renderFormHelper({ touched, error })}
  </FormControl>
)

export const renderCheckbox = ({ input, label, disabled, color = 'primary' }) => (
  <FormControlLabel control={<Checkbox checked={!!input.value} onChange={input.onChange} disabled={disabled} color={color} />} label={label} />
)

export const renderRadio = ({ color = 'primary' }) => <Radio color={color} />

export const renderRadioGroup = ({ input, ...rest }) => <RadioGroup {...input} {...rest} onChange={(_event, value) => input.onChange(value)} {...rest} />

export const renderSwitch = ({ input, label, color, ...rest }) => (
  <FormControlLabel control={<Switch checked={!!input.value} onChange={input.onChange} color={color} />} label={label} {...rest} />
)

// NOTE: This doesn't work well for time pickers, only date pickers; manually update redux form for time pickers
// Specifically onChange unmounts picker after one click:
// https://github.com/YouCanBookMe/react-datetime/issues/208
// Attempting to use as controlled component is a mistake since onBlur doesn't work when 'open' is controlled:
// https://github.com/YouCanBookMe/react-datetime/issues/509
// When another component that handles timezones appears, we should switch
export const renderDateTimeField = ({ input, ...rest }) => (
  <Datetime {...input} onChange={(value) => input.onChange(value)} onViewModeChange={(value) => input.onViewModeChange(value)} {...rest} />
)
