import { Button, Grid } from '@material-ui/core'
import { isEmpty, identity, pick, pickBy, camelCase } from 'lodash'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { IDocument } from '../../store/models/Topic/TopicDocuments'
import { FileButtonControl, SelectControl, TextFieldControl } from '../utils'
import * as validations from '../validations/fieldLevelValidations'
import { ITopicLmsCourse, ITopicLmsItem } from '../../store/models/Topic/TopicLmsCourses'
import VideoPlayer from './VideoPlayer'
import { LmsListItem } from './LmsListItem'
import { useParamTopic } from '../Providers'

interface Props {
  lmsCourse: ITopicLmsCourse
}

export const LmsCourse = ({ lmsCourse }: Props) => {
  const { topic } = useParamTopic()
  return (
    <div className='fat-bottom'>
      {lmsCourse.lmsItems.map((lmsItem) => (
        <LmsListItem lmsItem={lmsItem} key={lmsItem.id} onDelete={() => topic?.loadTopicLmsCourses()} />
      ))}
    </div>
  )
}
