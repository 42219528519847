import React, { Component } from 'react'
import { withRouter } from 'react-router'

import Signup1Dialog from './users/Signup/Signup1Dialog'
import Signup2Dialog from './users/Signup/Signup2Dialog'
import LoginDialog from './users/Login/LoginDialog'
import UpdateConversationDialog from './conversations/UpdateConversationDialog'
import DeleteConversationDialog from './conversations/deleteConversationDialog'
import DeleteRSVPDialog from './conversations/DeleteRSVPDIalog'
import JoinedConversationDialog from './conversations/joinedConversationDialog'
import CreatedConversationDialog from './conversations/createdConversationDialog/CreatedConversationDialog'
import AttendingConversationDialog from './conversations/AttendingDialog'
import EmailInviteDialog from './conversations/EmailInviteDialog/EmailInviteDialog'
import EmailInviteMemberDialog from './users/MyMembership/EmailInviteDialog/EmailInviteMemberDialog'
import ProfileImageDialog from './users/ProfileImageDialog'
import InvitationDialogContainer from './invitations/InvitationDialogContainer'
import ConversationInvitationRequestDialog from './topics/partials/ConversationInvitationRequestDialog'
import ConversationInvitationRequestSentDialog from './topics/partials/ConversationInvitationRequestSentDialog'
import TopicSubscribeDialog from './topics/partials/TopicSubscribeDialog'
import TopicInviteHostDialog from './topics/partials/InviteHostDialog'
import SpecificConversationInvitationRequestSentDialog from './topics/partials/SpecificConversationInvitationRequestSentDialog'
import StoryCollectionSuccess from './StoryCollection/SuccessDialog'
import ConversationInvitationRequestAcceptedDialog from './conversations/ConversationInvitationRequestAcceptedDialog'
import ConversationInvitationRequestDeniedDialog from './conversations/ConversationInvitationRequestDeniedDialog'
import EmailGuestsDialog from './conversations/EmailGuestsDialog'
import EmailHostDialog from './conversations/emailHostDialog'
import EmailsSentDialog from './conversations/EmailsSentDialog'
import SurveyQuestionDialog from './conversations/surveyQuestionDialog/surveyQuestionDialog'
import DeleteSurveyQuestionDialog from './survey-questions/deleteSurveyQuestionDialog'
import PartnerConsentDialog from './conversations/partnerConsentDialog'
import DeleteUserDialog from './users/DeleteUserDialog'
import RemoveGuestDialog from './conversations/RemoveGuestDialog'
import UserConnectionRequestDialog from './users/UserConnectionRequestDialog'
import UserConnectionRequestSentDialog from './users/UserConnectionRequestSentDialog'
import UserConnectionAcceptDialog from './users/UserConnectionAcceptDialog'
import { observer } from 'mobx-react'
import EmailReinviteDialog from './conversations/EmailInviteDialog/EmailReinviteDialog'
import EmailInviteSentDialog from './conversations/EmailInviteDialog/EmailInviteSentDialog'
import { flow } from 'lodash'
import { connectDialogTheme } from '../connectTheme'
import { DefaultThemeProvider } from '../themes'
import NpsDialog from './surveys/NpsDialog'
import Nps2Dialog from './surveys/Nps2Dialog'
import Nps3Dialog from './surveys/Nps3Dialog'
import StoryShowDialog from './StoryCollection/StoryShowDialog'
import NpsUserDialog from './surveys/NpsUserDialog'

class Dialogs extends Component<FIXME, FIXME> {
  render() {
    const { dialog } = this.props

    const DialogComp = [
      //  Session
      { name: 'login', component: LoginDialog },
      { name: 'signup', component: Signup1Dialog },
      { name: 'signup2', component: Signup2Dialog },

      //  Conversation
      { name: 'updateConversation', component: UpdateConversationDialog },
      { name: 'deleteRSVP', component: DeleteRSVPDialog },
      { name: 'createdConversation', component: CreatedConversationDialog },
      { name: 'attendingConversation', component: AttendingConversationDialog },
      { name: 'emailInviteSent', component: EmailInviteSentDialog },
      { name: 'emailInvite', component: EmailInviteDialog },
      { name: 'emailInviteMember', component: EmailInviteMemberDialog },
      { name: 'emailReinvite', component: EmailReinviteDialog },
      { name: 'deleteConversation', component: DeleteConversationDialog },
      { name: 'joinedConversation', component: JoinedConversationDialog },
      { name: 'emailGuests', component: EmailGuestsDialog },
      { name: 'emailHost', component: EmailHostDialog },
      { name: 'emailsSent', component: EmailsSentDialog },
      { name: 'surveyQuestion', component: SurveyQuestionDialog },
      { name: 'deleteSurveyQuestion', component: DeleteSurveyQuestionDialog },
      { name: 'partnerConsent', component: PartnerConsentDialog },
      { name: 'removeGuest', component: RemoveGuestDialog },

      //  Story collection
      { name: 'storyCollectionSuccess', component: StoryCollectionSuccess },
      { name: 'showStory', component: StoryShowDialog },

      //  Users
      { name: 'profileImage', component: ProfileImageDialog },
      { name: 'deleteUser', component: DeleteUserDialog },
      { name: 'userConnectionRequest', component: UserConnectionRequestDialog },
      { name: 'userConnectionRequestSent', component: UserConnectionRequestSentDialog },
      { name: 'userConnectionAccept', component: UserConnectionAcceptDialog },

      // Invitation Requests
      { name: 'conversationInvitationRequest', component: ConversationInvitationRequestDialog },
      { name: 'conversationInvitationRequestSent', component: ConversationInvitationRequestSentDialog },
      { name: 'topicSubscribe', component: TopicSubscribeDialog },
      { name: 'topicInvitHost', component: TopicInviteHostDialog },
      { name: 'specificConversationInvitationRequestSentDialog', component: SpecificConversationInvitationRequestSentDialog },
      { name: 'invite_accepted', component: ConversationInvitationRequestAcceptedDialog },
      { name: 'invite_denied', component: ConversationInvitationRequestDeniedDialog },

      // Invitations
      { name: 'invitation', component: InvitationDialogContainer },

      // NPS
      { name: 'nps', component: NpsDialog },
      { name: 'nps2', component: Nps2Dialog },
      { name: 'nps3', component: Nps3Dialog },
      { name: 'npsUser', component: NpsUserDialog }
    ].find((d) => d.name === dialog.name)?.component

    return (
      <div>
        <DefaultThemeProvider>{DialogComp && <DialogComp dialog={dialog} data={dialog.data} match={this.props.match} />}</DefaultThemeProvider>
      </div>
    )
  }
}

export default flow(connectDialogTheme, withRouter, observer)(Dialogs)
