import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import { ITopic } from '../../../../store'
import { ValueLabel } from '../../../shared/DataTableFilterable'
import TopicActions from './TopicActions'
import CheckIcon, { Icon } from '@material-ui/core'

export const TopicCellValue = (row: ITopic, columnName: string) => {
  switch (columnName) {
    case 'title':
      return (
        <ValueLabel
          value={
            <Link rel='noopener' title={row.title} to={`/${row.slug}`} target='blank' color='primary'>
              {row.title}
            </Link>
          }
        />
      )
    case 'createdAt':
      return <ValueLabel value={row.createdAt && moment(row.createdAt).format('L')} />
    case 'startsAt':
      return <ValueLabel value={row.startsAt && moment(row.startsAt).format('L')} />
    case 'endsAt':
      return <ValueLabel value={row.endsAt && moment(row.endsAt).format('L')} />
    case 'active':
      return <ValueLabel value={row.active ? 'Active' : 'Archived'} />
    case 'actions':
      return <TopicActions topic={row} />
    case 'isTemplate':
      return row.isTemplate && <Icon className='fal fa-check' fontSize='small' />
    case 'partnerName':
      return <ValueLabel value={row.isTemplate ? <span style={{ color: 'lightgrey' }}>{row[columnName]}</span> : row[columnName]} />
    case 'blank':
      return ''
    default:
      return <ValueLabel value={row[columnName]} />
  }
}

export default TopicCellValue
