import { createSelector } from 'reselect'
import { mapValues, assign, filter } from 'lodash'
import moment from 'moment-timezone'
import i18n from 'i18next'

import { getUsers } from './userGetters'
import coordinateDecorator from '../decorators/coordinateDecorator'
import { capitalize } from '../decorators/textTools'

const getConversationsList = createSelector(
  getConversationsRaw,
  d => d.list
)

const getHostedConversationsList = createSelector(
  getHostedConversationsRaw,
  d => d.list
)

const dayBefore = conversation =>
  !!moment(conversation.startsAt)
    .subtract(1, 'days')
    .isBefore(moment())

const expired = conversation =>
  !!moment(conversation.endsAt)
    .add(1, 'days')
    .isBefore(moment())
const linkExpired = conversation =>
  !!moment(conversation.endsAt)
    .add(30, 'minutes')
    .isBefore(moment())

const isVirtual = conversation => conversation.venueType === 'virtual'

export const getConversations = createSelector(
  getConversationsList,
  getUsers,
  (conversations, users) => {
    return mapValues(conversations, conversation => {
      const { host: hostId, coordinates, guestCount } = conversation

      return assign({}, conversation, {
        host: users[hostId] || {},
        hasGuests: hasGuests(guestCount),
        coordinates: coordinateDecorator(coordinates),
        dateLocally: dateLocally(conversation),
        dayLocally: dayLocally(conversation),
        startsAtLocally: startsAtLocally(conversation),
        endsAtLocally: endsAtLocally(conversation),
        timeZoneAbbr: timeZoneAbbr(conversation),
        neighborhood: neighborhoodOrCity(conversation),
        isPublic: conversation.guestPolicy === 'public',
        approvalOnly: conversation.guestPolicy === 'approval_only',
        isPrivate: conversation.guestPolicy === 'private',
        isVirtual: isVirtual(conversation),
        isOtherConversation: conversation.venueType === 'other',
        isHomeConversation: conversation.venueType === 'home',
        isRestaurantConversation: conversation.venueType === 'restaurant',
        expired: expired(conversation),
        venueName: !!isVirtual(conversation)
          ? i18n.t('conversation.virtualTypeLabel', {
              eventType: capitalize(conversation.virtualEventTypeSingular)
            })
          : conversation.venueName,
        showVirtualLink: !!(isVirtual(conversation) && !!conversation.virtualLink && dayBefore(conversation) && !linkExpired(conversation))
      })
    })
  }
)

export const conversationWithExtra = (conversation, users) => {
  const { host: hostId, coordinates, guestCount } = conversation

  return {
    ...conversation,
    ...(users ? { host: users[hostId] } : {}),
    hasGuests: hasGuests(guestCount),
    coordinates: coordinateDecorator(coordinates),
    dateLocally: dateLocally(conversation),
    dayLocally: dayLocally(conversation),
    startsAtLocally: startsAtLocally(conversation),
    endsAtLocally: endsAtLocally(conversation),
    timeZoneAbbr: timeZoneAbbr(conversation),
    neighborhood: neighborhoodOrCity(conversation),
    isPublic: conversation.guestPolicy === 'public',
    approvalOnly: conversation.guestPolicy === 'approval_only',
    isPrivate: conversation.guestPolicy === 'private',
    isVirtual: isVirtual(conversation),
    isOtherConversation: conversation.venueType === 'other',
    isHomeConversation: conversation.venueType === 'home',
    isRestaurantConversation: conversation.venueType === 'restaurant',
    expired: expired(conversation),
    venueName: !!isVirtual(conversation)
      ? i18n.t('conversation.virtualTypeLabel', {
          eventType: capitalize(conversation.virtualEventTypeSingular)
        })
      : conversation.venueName,
    showVirtualLink: !!(isVirtual(conversation) && !!conversation.virtualLink && dayBefore(conversation) && !linkExpired(conversation))
  }
}

export const getHostedConversations = createSelector(
  getHostedConversationsList,
  getUsers,
  (conversations, users) => {
    return mapValues(conversations, conversation => {
      return conversationWithExtra(conversation, users)
    })
  }
)

export function dateLocally({ startsAt, timezone, locale }) {
  return capitalize(
    moment(startsAt)
      .locale(locale)
      .tz(timezone)
      .format('LL')
  )
}

export function dayLocally({ startsAt, timezone, locale }) {
  return moment(startsAt)
    .locale(locale)
    .tz(timezone)
    .format('ddd')
}

export function yearLocally({ startsAt, timezone }) {
  return moment(startsAt)
    .tz(timezone)
    .format('YYYY')
}

export function dayLocallyFull({ startsAt, timezone, locale }) {
  return moment(startsAt)
    .locale(locale)
    .tz(timezone)
    .format('dddd')
}

export function startsAtLocally({ startsAt, timezone, locale }) {
  return moment(startsAt)
    .locale(locale)
    .tz(timezone)
    .format('LT')
}

export function endsAtLocally({ endsAt, timezone, locale }) {
  return moment(endsAt)
    .tz(timezone)
    .locale(locale)
    .format('LT')
}

export function timeZoneAbbr({ timezone }) {
  return moment()
    .tz(timezone)
    .format('zz')
}

export function neighborhoodOrCity({ neighborhood, city }) {
  return neighborhood ? neighborhood : city
}

export const getCurrentConversation = createSelector(
  getCurrentConversationId,
  getConversations,
  (conversationId, conversations) => {
    return conversations[conversationId]
  }
)

function getCurrentConversationId(state, props) {
  const { conversationId } = props.params || props.match

  return parseInt(conversationId, 10)
}

export function hasGuests(guestCount) {
  return guestCount > 0
}

export const getErrorConversation = createSelector(
  getConversationsRaw,
  d => d.errorConversation
)

export const getLoadingConversation = createSelector(
  getConversationsRaw,
  d => d.loadingConversation
)

export const getLoadingConversations = createSelector(
  getConversationsRaw,
  d => d.loadingConversations
)

export const getLoadingTopicConversations = createSelector(
  getConversationsRaw,
  d => d.loadingTopicConversations
)

function getConversationsRaw(state) {
  return state.conversations
}

function getHostedConversationsRaw(state) {
  return state.hostedConversations
}

export const getLoadingHostedConversations = createSelector(
  getHostedConversationsRaw,
  d => d.loading
)

export const getLoadingPartnerConversations = createSelector(
  getConversationsRaw,
  d => d.loadingPartnerConversations
)

export function filterExpiredConversations(conversations) {
  return filter(
    conversations,
    d =>
      !moment(d.endsAt)
        .add(4, 'hour')
        .isBefore(moment())
  )
}

export function filterHiddenConversations(conversations) {
  return filter(conversations, d => !d.hidden)
}

export function filterActiveConversations(conversations) {
  return filter(conversations, d =>
    moment(d.endsAt)
      .add(4, 'hour')
      .isBefore(moment())
  )
}
