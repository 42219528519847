import React, { useState } from 'react'
import { Button, Container, createStyles, Grid, InputAdornment, makeStyles, MenuItem, Paper, Select, Tab, Tabs, TextField, Theme, Tooltip, Typography } from '@material-ui/core'
import { observer } from 'mobx-react'
import moment from 'moment'
import { IMembershipUser, IMembershipUserBase } from '../../../store/models/User/MembershipUser'
import { ITrack } from '../../../store'
import MembersTable from './MembersTable'
import InvitedMembersTable from './InvitedMembersTable'
import RemovedMembersTable from './RemovedMembersTable'
import { useHistory, useParams } from 'react-router'

interface Props {
  membership: IMembershipUser
  track: ITrack
}

export const MyMembers = observer(({ membership, track }: Props) => {
  const classes = useStyles()
  const history = useHistory()
  const [search, setSearch] = useState('')
  const { action, tab, membership_id } = useParams<{ action: string; membership_id?: string; tab?: string }>()
  const tabNames = ['active', 'invited', 'removed']
  return (
    <div>
      {membership.isManager() && (
        <div>
          <Grid container direction='row' alignItems='center' justify='space-between'>
            <Grid item>
              <Typography variant='h6' className='reg-bottom reg-top'>
                Members
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='start'>
                      <i className='fas fa-search opacity-5'></i>
                    </InputAdornment>
                  )
                }}
                size='small'
                fullWidth
                variant='standard'
                placeholder='Search'
                value={search}
                onChange={(e) => setSearch(e.currentTarget.value)}
              ></TextField>
            </Grid>
            <Grid item>
              <Tooltip title='Invite by Email'>
                <Button
                  variant='contained'
                  color='secondary'
                  size='small'
                  className='reg-right'
                  startIcon={<i className='fa fa-envelope' style={{ fontSize: '.9rem' }}></i>}
                  onClick={() => membership && membership.showInviteMemberDialog()}
                >
                  Invite by Email
                </Button>
              </Tooltip>

              <Tooltip title='Copy Invitation Link'>
                <Button
                  variant='outlined'
                  color='secondary'
                  size='small'
                  className='reg-right'
                  startIcon={<i className='far fa-link' style={{ fontSize: '.9rem' }}></i>}
                  onClick={() => {
                    if (membership) {
                      navigator.clipboard.writeText(membership.inviteLink())
                      membership.showAlert('Invite link copied')
                    }
                  }}
                >
                  Invite Link
                </Button>
              </Tooltip>

              <Tooltip title='Download CSV'>
                <Button
                  variant='outlined'
                  color='secondary'
                  size='small'
                  startIcon={<i className='fas fa-external-link-alt' style={{ fontSize: '.9rem' }}></i>}
                  onClick={() => {
                    if (membership) {
                      membership.downloadCsv().then(() => history.push('/downloads'))
                    }
                  }}
                >
                  Download CSV
                </Button>
              </Tooltip>
            </Grid>
          </Grid>

          <Tabs
            value={tab && tabNames.indexOf(tab) !== -1 ? tabNames.indexOf(tab) : 0}
            onChange={(e, val) => {
              history.push(`/memberships/members/${membership_id}/${tabNames[val]}`)
            }}
            indicatorColor='secondary'
            textColor='secondary'
            variant='fullWidth'
            centered
          >
            <Tab label={`Active (${membership.fullList.filter((item) => item.status === 'active').length}/${membership.seatLimit})`} />
            <Tab label={`Invited (${membership.pendingList.length})`} />
            <Tab label={`Removed (${membership.fullList.filter((item) => item.status === 'removed').length})`} />
          </Tabs>

          {(!tab || tab === 'active') && (
            <Paper className={classes.section}>
              <MembersTable membership={membership} track={track} search={search?.toLowerCase()} />
            </Paper>
          )}
          {tab === 'invited' && (
            <Paper className={classes.section}>
              <InvitedMembersTable membership={membership} track={track} search={search?.toLowerCase()} />
            </Paper>
          )}
          {tab === 'removed' && (
            <Paper className={classes.section}>
              <RemovedMembersTable membership={membership} track={track} search={search?.toLowerCase()} />
            </Paper>
          )}
        </div>
      )}
    </div>
  )
})
export default MyMembers

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    members: {
      // maxHeight: 'calc(100vh / 3)'
      // overflow: 'auto'
    },
    section: {
      marginTop: '2rem',
      paddingTop: '.25rem'
    },
    title: {
      margin: '1rem'
    }
  })
)
