import React, { useState } from 'react'
import { map, size, flow } from 'lodash'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { makeStyles, createStyles, Theme, Fade, Button, Card, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'

import PageLoadingAnimation from '../shared/PageLoadingAnimation'
import SurveyQuestionForm from './Form'
import { observer } from 'mobx-react'
import { inject } from 'mobx-react'
import { Prompt, withRouter } from 'react-router'
import { IStore, ITopic } from '../../store/models'
import { UNSAVED_FORM_WARNING } from '../utils/constants'
import palette from '../../themes/palette'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
interface IProps {
  topic: ITopic
}
const SurveyQuestionsEdit = (props: InjectedProps & IProps) => {
  const { questions = [], topic, loading } = props
  const [newForm, setnewForm] = useState(false)
  const [dirty, setdirty] = useState(false)
  const [expanded, expand] = useState<number>(0)
  const classes = useStyles()
  const handleNewQuestion = () => {
    setnewForm(true)
  }

  const closeNewQuestion = () => {
    setnewForm(false)
  }

  if (loading) {
    return <PageLoadingAnimation />
  }

  return (
    <div>
      <Prompt when={!!questions.find((a) => a.dirty) || dirty} message={UNSAVED_FORM_WARNING} />
      <DndProvider backend={HTML5Backend}>
        <div className='reg-bottom'>
          {map(questions, (question, index) => (
            <Accordion key={question.id} onChange={() => expand(expanded === question.id ? 0 : question.id)} expanded={expanded === question.id}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={question.id} className={expanded === question.id ? classes.active : undefined}>
                {question.question}
              </AccordionSummary>
              <AccordionDetails style={{ display: 'block' }} id={question.id}>
                <SurveyQuestionForm question={question} action='update' topic={topic} form={`SurveyQuestionForm-id${question.id}`} />
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
        {newForm && questions.length < 10 && (
          <Fade in={newForm}>
            <Card className='reg-padding'>
              <div>
                <SurveyQuestionForm
                  question={null}
                  action='create'
                  topic={topic}
                  form={`NewSectionForm-id${size(questions) > 0 ? size(questions) + 1 : size(questions)}`}
                  closeForm={closeNewQuestion}
                  onDirty={(val) => setdirty(val)}
                />
              </div>
            </Card>
          </Fade>
        )}
        <div>
          {!newForm && questions.length < 10 && (
            <Button startIcon={<i className='fa fa-plus opacity-6' />} onClick={handleNewQuestion} variant='contained' color='primary'>
              Add a Question
            </Button>
          )}
        </div>
      </DndProvider>
    </div>
  )
}

interface InjectedProps {
  questions?: any
  loading: boolean
}

export default flow(
  observer,
  inject<InjectedProps, IProps>(
    (
      {
        mst: { topics }
      }: {
        mst: IStore
      },
      ownProps
    ) => {
      const { topic } = ownProps
      topic && topic.shouldLoad('surveyQuestions', topic.loadSurveyQuestions)

      return {
        questions: topic.surveyQuestions,
        loading: topics.loadingIds.includes('surveyQuestions')
      }
    }
  ),
  withRouter
)(SurveyQuestionsEdit)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    active: {
      backgroundColor: palette.lightYellow
    }
  })
)
