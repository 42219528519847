import { deleteApi, post, put } from './utils'
import { toFormData } from './FormData'

class LmsItemsApi {
  static create(data) {
    const formData = toFormData('lms_item_data', data)
    return post(`/lms_items`, {
      noContentTypeJson: true,
      body: formData
    })
  }

  static update(data) {
    const formData = toFormData('lms_item_data', data)
    return put(`/lms_items/${data.id}`, {
      noContentTypeJson: true,
      // body: JSON.stringify({ lmsCourseData: data })
      body: formData
    })
  }

  static destroy(id) {
    return deleteApi(`/lms_items/${id}`)
  }
}

export default LmsItemsApi
