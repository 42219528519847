import { types, Instance } from 'mobx-state-tree'
import { Alertable, Dialogable, LoadableStatus } from '../helpers'
import Editable from '../helpers/Editable'
import { RootAccessable } from '../helpers/RootAccessable'

import { Host } from '../User'

const ConversationBase = types.compose(
  'ConversationBase',
  RootAccessable,
  LoadableStatus,
  Alertable,
  Editable,
  Dialogable,
  types.model({
    id: types.identifierNumber,

    //  required
    title: types.maybeNull(types.optional(types.string, '')),
    slug: types.string,
    host: types.maybeNull(Host),

    //  String - Optional
    locale: types.optional(types.string, 'us_EN'),
    timezone: types.optional(types.string, 'America/New_York'),
    partnerConsent: types.maybeNull(types.boolean),
    synthetic: types.maybeNull(types.boolean),
    partnerConsentBody: types.maybeNull(types.string),
    topicId: types.maybeNull(types.number),
    guestCount: types.maybeNull(types.number),
    partnerConsentTitle: types.maybeNull(types.string)
  })
)

export interface IConversationBase extends Instance<typeof ConversationBase> {}
export default ConversationBase
