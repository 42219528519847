import React, { Component } from 'react'
import { size } from 'lodash'
import { Button, Card } from '@material-ui/core'

import SectionForm from './FormMst'
import SponsorsEdit from '../sponsors/EditMst'
import { observer } from 'mobx-react'
import { UNSAVED_FORM_WARNING } from '../utils/constants'
import { Prompt } from 'react-router'

class SectionsEdit extends Component<FIXME, FIXME> {
  state = {
    newForm: false
  }
  componentDidMount() {
    this.props.sectionable && this.props.sectionable.loadSections()
  }

  componentDidUpdate(prevProps) {
    if (this.props.sectionable && this.props.sectionable !== prevProps.sectionable) {
      this.props.sectionable.loadSections()
    }
  }

  handleNewSection = () => {
    this.setState({ newForm: true })
  }

  closeNewSection = () => {
    this.setState({ newForm: false })
    this.props.section?.setDirty(false)
  }

  render() {
    const { sectionable, type } = this.props

    const { newForm } = this.state

    const sections = sectionable?.sections || []
    const dirtySection = !!sections.find((a) => a.dirty)
    const dirtySponsor = !!sections.find((a) => a.sponsors.find((sp) => sp.dirty))

    return (
      <div>
        <Prompt when={dirtySection || dirtySponsor || newForm} message={UNSAVED_FORM_WARNING} />
        {sections.map((section) => (
          <Card key={section.id} className='no-padding xtra-huge-bottom no-shadow'>
            <SectionForm sectionable={sectionable} section={section} action='update' form={`SectionForm-id${section.id}`} />

            <SponsorsEdit section={section} />
          </Card>
        ))}

        {newForm ? (
          <Card className='no-padding xtra-huge-bottom no-shadow'>
            <SectionForm
              section={null}
              action='create'
              sectionableType={type}
              sectionableId={sectionable.id}
              sectionable={sectionable}
              form={`NewSectionForm-id${size(sections) > 0 ? size(sections) + 1 : size(sections)}`}
              closeForm={this.closeNewSection}
              onSave={this.closeNewSection}
            />
          </Card>
        ) : (
          <Button startIcon={<i className='fa fa-plus opacity-6' />} onClick={this.handleNewSection} variant='contained' color='primary'>
            Add a Section
          </Button>
        )}
      </div>
    )
  }
}

export default observer(SectionsEdit)
