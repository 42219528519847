import React, { useEffect } from 'react'
import { Button } from '@material-ui/core'
import SummaryContainer from '../partials/SummaryContainer'
import TopicsTable from './TopicsTable'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import SummaryStats from '../partials/SummaryStats'
import { withRouter, RouteComponentProps } from 'react-router'
import { IPartner } from '../../../../store/models/Partner'
import { inject, observer } from 'mobx-react'
import { flow } from 'lodash'
import { IStore } from '../../../../store/models'

const TopicsSummary = ({ history, topics_total, conversations_total, hosts_total, guests_total, loadStats }: Props & InjectedProps & RouteComponentProps) => {
  useEffect(() => {
    loadStats()
  }, [loadStats])

  return (
    <div>
      <SummaryContainer
        title={'Topic Summary'}
        topRight={
          <Button
            color='primary'
            onClick={() => {
              history.push('/admin/topics')
            }}
          >
            All Topics <ArrowForwardIcon />
          </Button>
        }
      >
        <SummaryStats
          data={[
            { title: 'Total Topics', value: topics_total },
            { title: 'Total Conversations', value: conversations_total },
            { title: 'Total Unique Hosts', value: hosts_total },
            { title: 'Total Guests', value: guests_total }
          ]}
        />

        <TopicsTable />
      </SummaryContainer>
    </div>
  )
}

interface Props {
  partner?: IPartner
}

interface InjectedProps {
  topics_total?: number
  conversations_total?: number
  hosts_total?: number
  guests_total?: number
  loadStats(val?: any)
}

export default flow(
  observer,
  inject<InjectedProps, Props>(
    ({
      mst: {
        partners: { topics_total, conversations_total, hosts_total, guests_total, loadStats },
        topics: { setTimeScope, timeScope }
      }
    }: {
      mst: IStore
    }) => {
      timeScope !== 'active' && setTimeScope('active')
      return {
        topics_total,
        conversations_total,
        hosts_total,
        guests_total,
        loadStats
      }
    }
  ),
  withRouter
)(TopicsSummary)
