import React from 'react'
import { AddressAutocompleteControl, RadioGroupControl, TextFieldControl } from '../../utils'
import { required, http, maxLength2000, maxDailyCoAttendance, minAttendance } from '../../validations/fieldLevelValidations'
import { StepProps } from './NewStepper'
import withTranslation, { WithTranslation } from '../../hocs/withTranslation'
import VirtualLocationZoom from './VirtualLocationZoom'

const VirtualLocationStep = ({ t, control, watch, errors, setValue, dirtyFields, clearErrors }: StepProps & WithTranslation) => {
  const customVirtualType = watch('virtualType') === 'custom'
  // const zoomVirtualType = watch('virtualType') === 'zoom'
  const showAddressHint = dirtyFields.location && !dirtyFields.placeId

  return (
    <>
      <div>
        <p className='bold'>{t('conversation.virtualLinkHeader')}</p>
        <RadioGroupControl
          name='virtualType'
          control={control}
          defaultValue={'dailyco'}
          onChange={(val) => {
            if (val === 'custom') {
              setValue('virtualLink', '')
              setValue('guestInstructions', '')
            } else if (val === 'dailyco') {
              clearErrors('virtualLink')
              setValue('guestInstructions', t('conversation.dailycoHint'))
            }
          }}
          options={[
            {
              label: t('conversation.dailycoLabel'),
              value: 'dailyco'
            },
            // {
            //   label: t('conversation.useZoomLabel'),
            //   value: 'zoom'
            // },
            {
              label: t('conversation.virtualCustomLabel'),
              value: 'custom'
            }
          ]}
        />
        {/* {zoomVirtualType && <VirtualLocationZoom />} */}
        {customVirtualType && (
          <TextFieldControl
            name='virtualLink'
            control={control}
            error={errors && errors.virtualLink}
            className='fat-top'
            multiline={true}
            rules={{ validate: http, required }}
            label={t('conversation.virtualLinkLabel')}
          />
        )}

        <TextFieldControl
          name='guestInstructions'
          control={control}
          error={errors && errors.guestInstructions}
          defaultValue={t('conversation.defaultVirtualGuest')}
          className='reg-top'
          multiline={true}
          rules={{ validate: maxLength2000, required }}
          label={customVirtualType ? t('conversation.virtualGuestLabel') : t('conversation.guestLabel')}
        />

        <AddressAutocompleteControl
          types={['(regions)']}
          name='location'
          control={control}
          label={t('conversation.locationLabel')}
          className='reg-top'
          onChange={(val) => {
            setValue('city', val?.city, { shouldDirty: true })
            setValue('placeId', val?.place_id, { shouldDirty: true })
          }}
        />

        {showAddressHint ? (
          <p
            className='material-error'
            dangerouslySetInnerHTML={{
              __html: t('signup.addressHint', {
                url: window.coco ? 'mailto:support@fbconversations.com' : 'mailto:support@inclusivv.co'
              })
            }}
          />
        ) : null}

        <div className='reg-top'>
          <TextFieldControl
            label={t('conversation.maxAttendeesLabel')}
            rules={{ validate: { ...(customVirtualType ? { required, minAttendance } : { required, minAttendance, maxDailyCoAttendance }) } }}
            name='maxAttendees'
            control={control}
            defaultValue={8}
            type='number'
            className='reg-top'
            error={errors && errors.maxAttendees}
          />
        </div>
      </div>
    </>
  )
}

export default withTranslation(VirtualLocationStep)
