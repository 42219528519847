import { createSelector } from 'reselect'
import { filter } from 'lodash'
import { getCurrentSectionId } from './sectionGetters'

export const getSponsorsRaw = state => state.sponsors

const getSponsorsList = createSelector(
  getSponsorsRaw,
  s => s.list
)

export const getSponsors = createSelector(
  getSponsorsList,
  sponsors => {
    return sponsors
  }
)

export const getSectionSponsors = createSelector(
  getCurrentSectionId,
  getSponsors,
  (sectionId, sponsors) => {
    return filter(sponsors, s => s.sectionId === sectionId)
  }
)

export const getErrorSponsors = createSelector(
  getSponsorsRaw,
  s => s.error
)

export const getLoadingSponsors = createSelector(
  getSponsorsRaw,
  s => s.loading
)
