import React, { Component, useEffect } from 'react'
import Section from './Section.js'
import { map, isEmpty } from 'lodash'
import withTranslation from '../hocs/withTranslation'
import { withRouter } from 'react-router'
import { flow } from 'lodash'
import { observer } from 'mobx-react'
import { ISectionables } from '../../store/models/helpers/Sectionables'

interface Props {
  sectionable: ISectionables
  t: any
  showSectionHeader?: boolean
  title?: string
}
const Sections = ({ sectionable, t, showSectionHeader = true, title }: Props) => {
  useEffect(() => {
    sectionable?.loadSections()
  }, [sectionable])

  const { sections } = sectionable

  if (isEmpty(sections)) return null

  const smallSections = sections.filter((s) => s.size === 'small')
  const mediumSections = sections.filter((s) => s.size === 'medium')
  const largeSections = sections.filter((s) => s.size === 'large')

  return (
    <div>
      {showSectionHeader && (
        <section className={'pane thin-pane header-section-grey'}>
          <div className='row center-xs'>
            <h2 className='header-32 flat-bottom'>{title || t('convos.supportBy')}</h2>
          </div>
        </section>
      )}

      {map(largeSections, (section) => (
        <Section key={section.id} {...section} />
      ))}

      {map(mediumSections, (section) => (
        <Section key={section.id} {...section} />
      ))}

      {map(smallSections, (section) => (
        <Section key={section.id} {...section} />
      ))}
    </div>
  )
}

export default flow(observer, withRouter, withTranslation)(Sections)
