import React, { Component } from 'react'
import { map as mapVals, isUndefined, isEmpty } from 'lodash'
import { getCenter } from 'geolib'
import Button from '@material-ui/core/Button'

const google = window.google
let polygon: FIXME = []
let map: FIXME = []

export class MapWrapper extends Component<FIXME, FIXME> {
  render() {
    const { regionCoordinates, page } = this.props

    if (page === 'edit' && isUndefined(regionCoordinates)) {
      return null
    }

    return (
      <div>
        <RegionMap {...(this.props as any)} />
      </div>
    )
  }
}

interface Coord {
  latitude: FIXME
  longitude: FIXME
}
interface Props {
  regionCoordinates: Coord[]
  updateForm(val: any, action: string)
}

export class RegionMap extends Component<Props> {
  mapRef

  componentDidMount() {
    const { updateForm } = this.props
    if (!updateForm) throw new Error('you must provide an `updateForm` prop to use the RegionMap component')

    this.loadMap()
  }

  loadMap = (newCoords = undefined) => {
    const { updateForm, regionCoordinates: regionCoordinatesProps } = this.props

    const regionCoordinates = newCoords || regionCoordinatesProps
    const maps = google.maps

    const { latitude = 37.09024, longitude = -100.4179324 } = findCenter(regionCoordinates) as any

    const mapConfig = {
      center: new maps.LatLng(latitude, longitude),
      zoom: 3
    }

    map = new maps.Map(this.mapRef, mapConfig)

    const destinations = new maps.MVCArray()
    const coords = buildPathCoords(regionCoordinates)
    coords.forEach((c) => destinations.push(c))

    const polygonOptions = {
      path: destinations,
      strokeColor: '#922A8E'
    }

    polygon = new maps.Polygon(polygonOptions)

    polygon.setMap(map)

    maps.event.addListener(map, 'click', (e) => {
      //  TODO: Clean this
      const currentPath = polygon.getPath()
      currentPath.push(e.latLng)

      const polygonArray: FIXME = []
      currentPath.forEach((xy, i) => {
        polygonArray.push(xy.lat(), xy.lng())
      })

      updateForm(polygonArray, 'region_coordinates')
    })
  }

  clearMap = () => {
    //  TODO: Clean this
    const { updateForm } = this.props

    polygon.setMap(null)

    updateForm([], 'region_coordinates')

    this.loadMap([] as any)
  }

  render() {
    return (
      <div>
        <div
          ref={(r) => (this.mapRef = r)}
          style={{
            width: '600px',
            height: '400px'
          }}
        />
        <Button className='reg-top' color='primary' onClick={this.clearMap} variant='contained'>
          Clear
        </Button>
      </div>
    )
  }
}

function buildPathCoords(regionCoordinates) {
  if (isEmpty(regionCoordinates)) return []

  return mapVals(regionCoordinates, (coords) => new google.maps.LatLng(coords.latitude, coords.longitude))
}

function findCenter(regionCoordinates) {
  if (!regionCoordinates || isEmpty(regionCoordinates) || regionCoordinates.length < 2) return {}
  return getCenter(regionCoordinates)
}
