import React from 'react'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import { useForm } from 'react-hook-form'
import { TextFieldControl, FileButtonControl } from '../../../utils'
import { camelCase } from 'lodash'

import { useParamPartner } from '../../../Providers'
import LoadingAnimation from '../../../shared/LoadingAnimation'
import { ImagePreview } from '../../../utils/ImagePreview'
// eslint-disable-next-line complexity
const PrivateLabelForm = (props: FIXME) => {
  const { handleSubmit, errors, formState, control, watch, setError, reset, clearErrors, setValue } = useForm({ mode: 'onBlur' })
  const { isDirty, isSubmitting } = formState
  const { partner } = useParamPartner()

  if (!partner) return <LoadingAnimation />

  const displayError = (error) => {
    if (error) {
      for (const fieldName in error) {
        setError(camelCase(fieldName), {
          type: 'manual',
          message: error[fieldName]
        })
      }
    }
  }

  const afterSave = async ({ data = undefined, error = undefined }: { error?: any; data?: any } = {}) => {
    if (error) {
      displayError(error)
    } else {
      data && reset()
    }
    return data
  }

  const handleSave = handleSubmit((data: any, e) => {
    clearErrors()
    partner.updatePartner(data).then(afterSave)
    // return true
  })

  return (
    <section className='fat-left-padding fat-right-padding'>
      <Card className='xtra-huge-bottom no-shadow padding'>
        <div className='flex-row flex-center left-padding padding-left'>
          <form onSubmit={handleSave}>
            <section className='pane thin-reg-pane'>
              <ImagePreview img={partner?.logoImage} />
              <FileButtonControl
                className='reg-top reg-bottom'
                name='logoImage'
                control={control}
                defaultValue={partner?.logoImage}
                error={errors && errors.logoImage}
                helperText='Logo Image - for best results, image should be 930px x 206px'
                onDelete={() => {
                  setValue('remove_logoImage', true, { shouldDirty: true })
                }}
                deletable
              />
            </section>

            <section className='pane thin-reg-pane'>
              <ImagePreview img={partner?.privateLabelEmailHeaderImage} />
              <FileButtonControl
                label='Email Header Image'
                className='reg-top reg-bottom'
                name='privateLabelEmailHeaderImage'
                control={control}
                defaultValue={partner?.privateLabelEmailHeaderImage}
                error={errors && errors.privateLabelEmailHeaderImage}
                helperText='Email Header Image - for best results, image should be 930px x 206px'
                onDelete={() => {
                  setValue('remove_privateLabelEmailHeaderImage', true, { shouldDirty: true })
                }}
                deletable
              />
            </section>
            <TextFieldControl
              name='featuredTopicHeader'
              control={control}
              defaultValue={partner?.featuredTopicHeader}
              error={errors && errors.featuredTopicHeader}
              label={'Featured Topic Header'}
              className='reg-bottom'
            />
            <TextFieldControl
              name='upcomingConversationHeader'
              control={control}
              defaultValue={partner?.upcomingConversationHeader}
              error={errors && errors.upcomingConversationHeader}
              label={'Upcoming Conversation Header'}
              className='reg-bottom'
            />
            <TextFieldControl
              name='featuredConversationHeader'
              control={control}
              defaultValue={partner?.featuredConversationHeader}
              error={errors && errors.featuredConversationHeader}
              label={'Featured Conversation Header'}
              className='reg-bottom'
            />
            <TextFieldControl
              name='supportedByHeader'
              control={control}
              defaultValue={partner?.supportedByHeader}
              error={errors && errors.supportedByHeader}
              label={'Supported By Header'}
              className='reg-bottom'
            />

            <Button disabled={!isDirty || isSubmitting} type='submit' className='giant-top' color='secondary' variant='contained'>
              Submit
            </Button>
          </form>
        </div>
      </Card>
    </section>
  )
}

export default PrivateLabelForm
