import i18next from 'i18next'
import resources from './locales'
import { initReactI18next } from 'react-i18next'

import 'moment/min/locales'

const debug = process.env.NODE_ENV !== 'production'

i18next
  .use(initReactI18next)
  .init({
    fallbackLng: 'en_US',
    debug,
    resources,
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })
  .then(function () {
    if (debug) console.log('Initialized i18n library')
  })

// Add access to i18n lib globally if in dev env
if (process.env.NODE_ENV !== 'production') {
  global.i18n = i18next
}

export default i18next
