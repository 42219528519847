import React from 'react'
import { createStyles, Icon, makeStyles, Popover, Theme, Typography } from '@material-ui/core'

interface Props {
  message: string
  iconClass: string
}

export const PopoverIcon = (props: Props) => {
  const { message, iconClass = 'fas fa-question-circle' } = props

  const [anchorEl, setAnchorEl] = React.useState(null)
  const classes = useStyles()
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  return (
    <>
      <Icon
        aria-describedby={id}
        className={iconClass}
        fontSize='inherit'
        color={open ? 'primary' : undefined}
        style={{ ...(open ? { color: '#0976BC' } : { color: '#6D6E71' }) }}
        onClick={handleClick}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={3}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
      >
        <Typography className={classes.popoverContent}>{message}</Typography>
      </Popover>
    </>
  )
}

export default PopoverIcon

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popoverContent: { maxWidth: '12rem', margin: '.7rem', fontSize: '12px', lineHeight: '18px', color: '#212121' }
  })
)
