import React, { Component } from 'react'
import { FacebookShareButton } from 'react-share'
import FontIcon from '@material-ui/core/Icon'
import { DialogTitle } from '@material-ui/core'

import withTranslation from '../../hocs/withTranslation'

export class CreatedConversationContentStepThree extends Component<FIXME, FIXME> {
  render() {
    const { slug, id } = this.props.conversation
    const { t, hideShare } = this.props

    const FbButton = () => (
      <div className='row center-xs'>
        <div className='col-xs-12 no-gutters'>
          <FacebookShareButton
            url={`https://${window.envFriendlyDomain}/${slug}/conversations/${id}`}
            className='btn-xl facebook-share-btn reg-bottom header-18 white-btn'
            // variant='contained'
          >
            {t('conversation.fbShare')}
          </FacebookShareButton>
        </div>
      </div>
    )

    return (
      <div>
        <DialogTitle disableTypography>{t('invitation.success')}</DialogTitle>

        <section className='pane reg-pane'>
          <div className='row reg-bottom'>
            <div className='col-xs-12 center-xs'>
              <div className='box'>
                <FontIcon className='fal fa-paper-plane header-36' />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-xs-12 center-xs'>
              <h1 className='flat header-24'>{t('conversation.inviteSent')}</h1>
            </div>
          </div>
        </section>

        {!hideShare && (
          <section className='pane minimal-grey-pane fat-top-padding big-bottom-padding'>
            <FbButton />
          </section>
        )}
      </div>
    )
  }
}

export default withTranslation(CreatedConversationContentStepThree)
