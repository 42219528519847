import { createMuiTheme } from '@material-ui/core/styles'
import { getFontFamily, defaulTypography, defaultButtonOverride } from './'
import palette from './palette'

export const DialogTheme = () =>
  createMuiTheme({
    palette: {
      primary: {
        main: window.coco ? palette.white : palette.yellow,
        contrastText: window.coco ? palette.darkestgrey : palette.darkestgrey
      },
      secondary: {
        main: window.coco ? palette.cocoGreen : palette.magenta,
        contrastText: window.coco ? palette.white : palette.white
      }
    },
    overrides: {
      MuiContainer: {
        root: {
          fontFamily: getFontFamily()
        }
      },
      MuiTypography: defaulTypography,
      MuiInputBase: {
        input: {
          fontFamily: getFontFamily()
        }
      },
      MuiButton: {
        ...defaultButtonOverride,
        root: {
          fontFamily: getFontFamily(),
          color: palette.grey800,
          boxShadow: 'rgba(0, 0, 0, 0) 0px 1px 4px'
        },
        contained: {
          fontFamily: getFontFamily(),
          boxShadow: 'rgba(0, 0, 0, 0.14) 0px 1px 4px',
          color: palette.grey800,
          backgroundColor: palette.grey50,
          '&.link': {
            color: palette.white,
            backgroundColor: palette.blue500,
            '&:hover': {
              backgroundColor: palette.blue900
            },
            '&:focus': {
              backgroundColor: palette.blue700
            }
          }
        },
        outlined: {
          fontFamily: getFontFamily(),
          boxShadow: 'rgba(0, 0, 0, 0) 0px 1px 4px',
          color: palette.grey800,
          backgroundColor: palette.grey50,
          '&.link': {
            color: palette.blue500,
            border: `1px solid ${palette.blue500}`
          }
        },
        text: {
          fontFamily: getFontFamily(),
          boxShadow: 'rgba(0, 0, 0, 0) 0px 1px 4px',
          color: palette.grey800,
          backgroundColor: palette.grey50,
          '&:hover': {
            boxShadow: 'rgba(0, 0, 0, 0.14) 0px 1px 4px'
          },
          '&.link': {
            color: palette.blue500
          }
        },
        label: {
          textTransform: 'none',
          fontWeight: window.coco ? 400 : 500,
          letterSpacing: 0
        }
      },
      MuiInput: {
        underline: {
          '&:after': {
            borderBottom: '1px solid rgba(0, 0, 0, 0.42)'
          },
          '&:hover:not($disabled):before': {
            borderBottom: '1px solid rgba(0, 0, 0, 0.42)'
          }
        }
      },
      MuiDialog: {
        root: { fontFamily: getFontFamily() }
      },
      MuiDialogTitle: {
        root: {
          textAlign: 'center',
          margin: 0,
          ...(window.coco
            ? {
                color: palette.white,
                backgroundColor: palette.cocoGreen,
                fontSize: '1.4em'
                // fontWeight: 700,
              }
            : {
                color: '#212121',
                backgroundColor: '#ffffff'
              }),
          borderBottom: '1px solid rgb(224, 224, 224)'
        }
      },
      MuiIcon: {
        root: {
          color: palette.blue500
        }
      },
      MuiFormLabel: {
        root: {
          '&$focused': {
            color: palette.teal
          }
        }
      }
    }
  })
