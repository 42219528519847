import * as types from '../actions/actionTypes'
import { assign, omit } from 'lodash'
import initialState from './initialState'

export const pendingRequestedGuests = (state = initialState.pendingRequestedGuests, action) => {
  switch (action.type) {
    case types.LOAD_PENDING_REQUESTED_GUESTS_FAILED:
      return assign({}, state, {
        error: action.data,
        loading: false
      })

    case types.PENDING_REQUESTED_GUESTS_LOADING:
      return assign({}, state, {
        error: null,
        loading: true
      })

    case types.LOAD_PENDING_REQUESTED_GUESTS_SUCCESS:
      return assign({}, state, {
        error: null,
        loading: false,
        list: assign({}, action.pendingRequestedGuests)
      })

    case types.REMOVE_PENDING_REQUESTED_GUEST:
      return assign({}, state, {
        error: null,
        loading: false,
        list: omit(state.list, action.userId)
      })

    default:
      return state
  }
}

export default pendingRequestedGuests
