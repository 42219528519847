import { Input } from '@material-ui/core'
import { SecurityOutlined } from '@material-ui/icons'
import { Grid } from '@material-ui/core'
import React from 'react'
import classes from '../../../../stylesheets/admin/GraphToolTip.module.scss'

interface Props {
  input: any
  yLabel: string | undefined
}

const LineGraphToolTip = ({ input, yLabel }: Props) => {
  const {
    serieId,
    data: { xFormatted, yFormatted },
    color
  } = input

  return (
    <div className={`${classes.lineContainer}`}>
      <div className={`${classes.header} bold thin-bottom`}>{xFormatted}</div>
      <Grid container>
        <Grid item xs={9}>
          <Grid container>
            <Grid item xs={3}>
              <div className={classes.colorSwatch} style={{ backgroundColor: color }}></div>
            </Grid>
            <Grid item xs={9} className={classes.colorSwatchLabel}>
              {yLabel ? yLabel : serieId}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3} className={classes.colorSwatchData}>
          <Grid container>
            <Grid item xs={12} className='align-right'>
              {yFormatted}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default LineGraphToolTip
