class GoogleUsersApi {
  static create(responseData) {
    const { sub: uid, given_name: first_name, family_name: last_name, picture: profile_image, email, signedUpTopicId, signedUpConversationId, locale, accessToken } = responseData

    const request = new Request(`${process.env.REACT_APP_API_HOST}/google_users`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({
        user: {
          uid,
          email,
          first_name,
          last_name,
          profile_image
        },
        locale,
        accessToken,
        site_name: global.siteName
      })
    })

    return fetch(request)
      .then((response) => {
        return response.json()
      })
      .catch((error) => {
        return error
      })
  }
}

export default GoogleUsersApi

// "iss": "https://accounts.google.com", // The JWT's issuer
// "nbf":  161803398874,
// "aud": "314159265-pi.apps.googleusercontent.com", // Your server's client ID
// "sub": "3141592653589793238", // The unique ID of the user's Google Account
// "hd": "gmail.com", // If present, the host domain of the user's GSuite email address
// "email": "elisa.g.beckett@gmail.com", // The user's email address
// "email_verified": true, // true, if Google has verified the email address
// "azp": "314159265-pi.apps.googleusercontent.com",
// "name": "Elisa Beckett",
//                           // If present, a URL to user's profile picture
// "picture": "https://lh3.googleusercontent.com/a-/e2718281828459045235360uler",
// "given_name": "Elisa",
// "family_name": "Beckett",
// "iat": 1596474000, // Unix timestamp of the assertion's creation time
// "exp": 1596477600, // Unix timestamp of the assertion's expiration time
// "jti": "abc161803398874def"
