import React, { ReactElement, useContext } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { ParamTopicContext } from '../../../Providers'
import { observer } from 'mobx-react'

interface Props {}

export const Video = observer(
  (props: Props): ReactElement => {
    const { topic } = useContext(ParamTopicContext)
    const classes = Styles()
    if (!topic || !topic?.videoLink) return <></>
    const { videoLink, title } = topic

    return (
      <section className={'pane thin-pane center-xs lightestgrey-pane'}>
        <div className='row content-cage'>
          <div className='col-xs-12'>
            <div className='header-20 black-text center-xs'>
              <iframe
                className={classes.videoIframe}
                src={videoLink}
                title={title}
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                allowFullScreen
              />
            </div>
          </div>
        </div>
      </section>
    )
  }
)

const Styles = makeStyles((theme: Theme) =>
  createStyles({
    videoIframe: {
      height: '315px',
      width: '560px',
      margin: 'auto',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '100%'
      }
    }
  })
)
