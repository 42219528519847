import 'string.prototype.startswith'

import React from 'react'
import ReactDOM from 'react-dom'

import mst from './store/models'
import configureStore from './store/configureStore'
import Root from './components/Root'
import history from './components/browserHistory'
import './stylesheets/index.js'
import './global.js'
import './i18n'

const store = configureStore()

ReactDOM.render(<Root mst={mst} store={store} history={history} />, document.getElementById('root'))
