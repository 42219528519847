import React from 'react'
import { TextFieldControl } from '../../utils'
import { required, maxLength2000 } from '../../validations/fieldLevelValidations'
import { StepProps } from './NewStepper'
import withTranslation, { WithTranslation } from '../../hocs/withTranslation'
import { Typography } from '@material-ui/core'

const GuestInstruction = ({ t, control, watch, errors, guestInstructionsHint }: StepProps & WithTranslation) => {
  const customVirtualType = watch('virtualType') === 'custom'

  return (
    <>
      <div>
        <Typography variant='subtitle2' className='fat-top thin-bottom'>
          A note to guests
        </Typography>
        <Typography variant='body2'>Leave instructions for your guests</Typography>
        <TextFieldControl
          name='guestInstructions'
          control={control}
          error={errors && errors.guestInstructions}
          defaultValue={t('conversation.defaultVirtualGuest')}
          className='reg-top'
          multiline={true}
          rows={3}
          rules={{ validate: maxLength2000, required }}
          // label={customVirtualType ? t('conversation.virtualGuestLabel') : t('conversation.guestLabel')}
          helperText={'This will appear as a note from the host in the event page details'}
          variant='outlined'
        />
      </div>
    </>
  )
}

export default withTranslation(GuestInstruction)
