import { createSelector } from 'reselect'

export const getUserConversationInvitationsRaw = state => state.conversationInvitations

const getUserConversationInvitationsList = createSelector(
  getUserConversationInvitationsRaw,
  dir => dir.list
)

export const getApprovedConversationInvitations = createSelector(
  getUserConversationInvitationsList,
  invitations => {
    return invitations
  }
)
