import React, { useEffect } from 'react'
import { flow } from 'lodash'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import LoadingAnimation from '../../shared/LoadingAnimation'
import Avatar from '../../users/Avatar.js'
import withTranslation from '../../hocs/withTranslation'
import { observer } from 'mobx-react'
import { IConversation } from '../../../store/models/Conversation'
import { createStyles, Grid, ListItemSecondaryAction, makeStyles, Paper } from '@material-ui/core'
import appAnalytics from '../../../analytics'

interface Props {
  conversation: IConversation
  t: FIXME
}

const PendingGuests = ({ conversation, t }: Props) => {
  useEffect(() => {
    if (!conversation.loadingIds.includes('pendingInvite') && !conversation.loadedIds.includes('pendingInvite')) {
      conversation.loadPendingInvite()
    }
  }, [conversation])
  const classes = Styles()
  return (
    <div>
      <h3>{t('conversation.invitations')}</h3>
      <Paper elevation={3} className={classes.paper}>
        <Grid container justify='space-between'>
          <Grid item>
            <h4>
              {t('conversation.pendingInvitations')} {`(${conversation.pendingInvite.length})`}
            </h4>
          </Grid>
          {conversation.pendingInvite.length > 0 && (
            <Grid item>
              <Button
                variant='contained'
                color='primary'
                onClick={() => {
                  appAnalytics.event({
                    category: 'Conversation',
                    action: 'Resend All Invites'
                  })
                  conversation.resendAllPendingInvite()
                }}
              >
                {t('conversation.resendAllPending')}
              </Button>
            </Grid>
          )}
        </Grid>
        {conversation.loadingIds.includes('pendingInvite') ? (
          <LoadingAnimation />
        ) : conversation.pendingInvite.length ? (
          <List>
            <Divider variant='inset' component='li' />
            {conversation.pendingInvite.map((guest) => (
              <div key={guest.id}>
                <ListItem button>
                  <ListItemAvatar>
                    <Avatar profileImage={guest.profileImage} hasProfileImage={guest.hasProfileImage} size={'thumb'} />
                  </ListItemAvatar>
                  <ListItemText primary={guest?.recipientEmail} />
                  <ListItemSecondaryAction>
                    <Button
                      onClick={() => {
                        appAnalytics.event({
                          category: 'Conversation',
                          action: 'Resend Invite'
                        })
                        guest.resendInvite()
                      }}
                      color='primary'
                    >
                      {t('conversation.resendInvite')}
                    </Button>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider variant='inset' component='li' />
              </div>
            ))}
          </List>
        ) : (
          <p className='italic'>{t('conversation.noPendingInvites')}</p>
        )}
      </Paper>
    </div>
  )
}
const Styles = makeStyles((theme) =>
  createStyles({
    paper: {
      padding: '1em 1em'
    }
  })
)
export default flow(observer, withTranslation)(PendingGuests)
