import React from 'react'
import { flow } from 'lodash'
import { GoogleMap, Marker, withGoogleMap, InfoWindow } from 'react-google-maps'
import silverMapStyles from '../../../stylesheets/silverMapStyles.json'
import { observer } from 'mobx-react'
import { IConversation } from '../../../store/models/Conversation'
import { ITopic } from '../../../store/models/Topic'
import ActionPaneSwitcher from './ActionPaneSwitcher'

const MapPane = ({ conversation, topic }: { conversation: IConversation; topic: ITopic }) => {
  const { venueName, formattedAddress, coordinates } = conversation
  const [latitude, longitude] = coordinates
  const Map = withGoogleMap(() => (
    <GoogleMap defaultZoom={15} defaultCenter={{ lat: typeof latitude === 'number' ? latitude + 0.0025 : 0, lng: longitude }} defaultOptions={{ styles: silverMapStyles }}>
      <Marker position={{ lat: latitude, lng: longitude }}>
        <InfoWindow>
          <div>
            <p className='header-16 header-14-xs bold thin-bottom'>{venueName}</p>
            <p className='header-16 header-14-xs flat-top'>{formattedAddress}</p>
          </div>
        </InfoWindow>
      </Marker>
    </GoogleMap>
  ))

  return (
    <>
      {conversation.isPremium && conversation.showMap && (
        <div>
          <Map
            // googleMapURL='https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places'
            // loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: '230px' }} />}
            mapElement={<div style={{ height: '100%' }} />}
          />
        </div>
      )}
    </>
  )
}

export default flow(observer)(MapPane)
