import React, { Component } from 'react'
import { flow } from 'lodash'
import { Button, Card } from '@material-ui/core'

import ConversationForm from '../Form'
import ManageGuests from './ManageGuests'
import { connectAdminThemeV2 } from '../../../connectTheme'
import ConversationNotificationsEdit from '../../conversation-notifications/Edit'
import PremiumEventForm from './PremiumEventForm'
import withTranslation from '../../hocs/withTranslation'
import AppTabs from '../../shared/AppTabs'
import { inject, observer } from 'mobx-react'
import { IStore } from '../../../store/models'
import { withRouter } from 'react-router'
import PageLoadingAnimation from '../../shared/PageLoadingAnimation'
import HostTools from './HostTools'
import EditSynth from './EditSynth'

class EditConversationPage extends Component<FIXME, FIXME> {
  componentDidUpdate(prevProps: FIXME) {
    const {
      conversation,
      changeLanguage,
      history,
      loaded,
      match: {
        params: { slug }
      }
    } = this.props

    if (loaded && conversation !== prevProps.conversation && !conversation?.canEdit) {
      history.push(`/${slug}`)
    }

    if (conversation && conversation !== prevProps.conversation && conversation.locale) {
      changeLanguage(conversation.locale)
    }
  }

  handleDelete = () => {
    const { conversation } = this.props
    conversation.deleteConversation()
  }

  render() {
    const {
      topic,
      conversation,
      params,
      showPremiumEventTab,
      t,
      match: {
        params: { slug, tab, conversationId }
      },
      history
    } = this.props

    if (!topic || !conversation) {
      return <PageLoadingAnimation />
    }
    if (!conversation.canEdit) return null

    const tab_names = ['', 'guests', 'host-tools', ...(showPremiumEventTab ? ['premium', 'emails'] : [])]
    let activeTab = tab_names.indexOf(tab)
    activeTab = activeTab === -1 ? 0 : activeTab

    const handleTabChange = (tabIndex) => {
      history.push(`/${slug}/conversations/${conversationId}/edit/${tab_names[tabIndex]}`)
    }

    if (conversation.synthetic) {
      return <EditSynth {...this.props} />
    }

    return (
      <AppTabs
        style={{ margin: '1.5em auto 0 auto', maxWidth: '50em' }}
        index={activeTab}
        onChange={handleTabChange}
        tabs={[
          {
            label: t('conversation.generalDetails'),
            content: (
              <>
                <h1 className='page-header'>{t('conversation.generalDetails')}</h1>
                <Card className='no-padding xtra-huge-bottom no-shadow'>
                  <ConversationForm conversation={conversation} isHost={conversation.isHost} page='edit' onSave={() => history.push(`/${slug}/conversations/${conversationId}`)} />
                </Card>
              </>
            )
          },
          {
            label: t('conversation.manageGuests'),
            content: (
              <>
                <h1 className='page-header'>Manage Guests</h1>
                <ManageGuests params={params} conversation={conversation} />
              </>
            )
          },
          {
            label: 'Host Tools',
            content: (
              <>
                <h1 className='page-header'>Host Tools</h1>
                <HostTools params={params} conversation={conversation} />
              </>
            )
          },
          ...(showPremiumEventTab
            ? [
                {
                  label: 'Premium Features',
                  content: (
                    <>
                      <h1 className='page-header reg-bottom'>Premium Features</h1>
                      <PremiumEventForm conversation={conversation} />
                    </>
                  )
                },
                {
                  label: t('conversation.customEmails'),
                  content: (
                    <>
                      <h1 className='page-header reg-bottom'>{t('conversation.customEmails')}</h1>
                      <ConversationNotificationsEdit conversation={conversation} />
                    </>
                  )
                }
              ]
            : [])
        ]}
      />
    )
  }
}

export default flow(
  observer,
  inject<FIXME, FIXME>(
    (
      {
        mst: {
          auth: { user, loaded },
          topics,
          conversations,
          dialogs: { showDialog }
        }
      }: { mst: IStore },
      {
        match: {
          params: { slug, conversationId }
        }
      }
    ) => {
      const id = parseInt(conversationId, 10)
      const topicsList = topics.list
      const topic = topicsList.find((d) => d.slug === slug)
      const conversation = conversations.list.find((d) => d.id === id)
      if (!conversation && !conversations.loadingIds.includes(id)) {
        conversations.loadById(id)
      }
      if (!topic && !topics.loadingIds.includes(slug)) {
        topics.loadBySlug(slug)
      }
      const showPremiumEventTab = user?.isPartnerAdmin() && conversation?.isPremium

      return {
        loaded,
        conversation,
        topic,
        deleteConversation: (data: FIXME) => {
          // eslint-disable-next-line no-console
          console.log('deleteConversation', data)
        },
        showDialog,
        showPremiumEventTab
      }
    }
  ),

  withRouter,
  connectAdminThemeV2,
  withTranslation
)(EditConversationPage)
