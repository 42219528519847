import { deleteApi, post, put, get } from './utils'

class SectionsApi {
  static getSections(type, sectionableId) {
    return get(`/sections/${type}/${sectionableId}`).catch((error) => {
      return error
    })
  }

  static create(data) {
    return post(`/sections`, {
      body: JSON.stringify(data)
    })
  }

  static update(data) {
    return put(`/sections/${data.id}`, {
      body: JSON.stringify(data)
    })
  }

  static destroy(id) {
    return deleteApi(`/sections/${id}`).catch((error) => {
      return error
    })
  }
}

export default SectionsApi
