import React, { useCallback, useEffect } from 'react'
import { Button, Chip, Grid, Tooltip, Typography } from '@material-ui/core'
import { pick, pickBy, identity, camelCase } from 'lodash'
import moment from 'moment'
import { observer, inject } from 'mobx-react'
import { useForm, FormProvider } from 'react-hook-form'

import { camelify, capitalize, snakify } from '../../decorators/textTools'
import withTranslation from '../hocs/withTranslation'
import { TextFieldControl, RadioGroupControl, AddressAutocompleteControl, CheckboxControl, SelectControl, DateControl, TimeControl } from '../utils'
import { required, http, maxDailyCoAttendance, isValidDate, handleStartEndAt, minAttendance, isValidTime } from '../validations/fieldLevelValidations'
import { IStore } from '../../store/models'
import TimeZoneApi from '../../api/TimeZoneApi'
import { Prompt } from 'react-router'
import { UNSAVED_FORM_WARNING } from '../utils/constants'
import SuitableDiets from './Edit/SuitableDiets'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import HostAssign from './Edit/HostAssign'

// eslint-disable-next-line complexity
const ConversationForm = ({ user, conversation, t, onSave, showDialog }: FIXME) => {
  const formMethods = useForm({ mode: 'onChange', shouldUnregister: false })
  const { handleSubmit, errors, formState, control, watch, setValue, clearErrors, setError, reset, trigger } = formMethods

  const { isDirty, isSubmitting, dirtyFields } = formState

  const displayError = (error) => {
    if (error) {
      for (const fieldName in error) {
        setError(camelCase(fieldName), {
          type: 'manual',
          message: error[fieldName]
        })
      }
    }
  }

  const handleSave = handleSubmit((data: any, e) => {
    const { privateEvent, location: raw_location, suitableDiets, ...rest } = pick(data, Object.keys(pickBy(dirtyFields, identity)))
    const { venue_name, ...location } = raw_location || {}
    const updateData = {
      ...camelify(location),
      ...(suitableDiets ? { suitableDiets: snakify(suitableDiets) } : {}),
      ...rest,
      ...handleStartEndAt(data),
      ...(privateEvent === undefined ? {} : { guestPolicy: privateEvent ? 'approval_only' : 'public' })
    }

    if ((conversation.isHost || user.isSuperAdmin()) && conversation.guestCount > 0) {
      showDialog('updateConversation', { values: { slug: conversation.slug, id: conversation.id, ...updateData } })
      onSave && onSave()
    } else {
      conversation.updateConversation(updateData).then(onSave).catch(displayError)
    }
    reset(data, { isDirty: false, isSubmitted: false })
  })

  const topic = conversation.topic

  const venueWord = conversation.isRestaurantConversation ? t('conversation.restaurantTypeLabel') : t('conversation.venue')

  const location = watch('location')
  // const virtualType = watch('virtualType')
  const getCurrentVal = (name) => {
    return watch(name) === undefined ? conversation[name] : watch(name)
  }
  const isVirtual = getCurrentVal('venueType') === 'virtual'
  const isCustomVirtualType = getCurrentVal('virtualType') === 'custom'
  const isHomeConversation = getCurrentVal('venueType') === 'home'
  const isRestaurantConversation = getCurrentVal('venueType') === 'restaurant'
  const isOtherConversation = getCurrentVal('venueType') === 'other'

  // const updateCityPlace = () => {
  //   if (conversation && location && location !== conversation.city) {
  //     setValue('city', location.city, { shouldDirty: true })
  //     setValue('placeId', location.place_id, { shouldDirty: true })
  //   }
  // }

  // useEffect(() => {
  //   if (conversation && location && location !== conversation.city) {
  //     if (location.city) {
  //       setValue('city', location.city, { shouldDirty: true })
  //     }
  //     if (location.place_id) {
  //       setValue('placeId', location.place_id, { shouldDirty: true })
  //     }
  //   }
  // }, [dirtyFields.location, conversation, location, setValue])

  const conversationType = watch('conversationType')
  const userFirstName = user?.firstName

  const updateGuestInstructions = useCallback(
    (val: string) => {
      if (!watch('guestInstructions')) {
        setValue('guestInstructions', val, { shouldDirty: true })
      }
    },
    [setValue, watch]
  )

  useEffect(() => {
    if (conversationType === 'potluck') {
      updateGuestInstructions(t('conversation.guestInstructions'))
    }
    if (['catered', 'main_course_provided'].includes(conversationType)) {
      updateGuestInstructions(t('conversation.mainCourseInstructions'))
    }
    if (['fully_catered', 'full_conversation_provided'].includes(conversationType)) {
      updateGuestInstructions(t('conversation.fullConversationInstructions'))
    }
    if (['no_food_provided'].includes(conversationType)) {
      updateGuestInstructions(t('conversation.noFoodInstructions'))
    }
    if (['other'].includes(conversationType)) {
      updateGuestInstructions(t('conversation.otherFoodInstructions'))
    }
  }, [conversationType, setValue, t, updateGuestInstructions])

  const handleVirtualType = (val) => {
    if (val === 'custom') {
      setValue('virtualLink', '', { shouldDirty: true })
      updateGuestInstructions('')
      clearErrors('maxAttendees')
    } else {
      updateGuestInstructions(t('conversation.dailycoHint'))
    }
  }
  const handleVenueType = (val) => {
    // setValue('location', '', { shouldDirty: true })
    if (val === 'virtual') {
      setValue('city', '', { shouldDirty: true })
      setValue('venueName', '', { shouldDirty: true })
      setValue('virtualType', 'dailyco', { shouldDirty: true })
      updateGuestInstructions(t('conversation.venueTypeGuestInstructions'))
    }
    if (val === 'restaurant') {
      setValue('venueName', '', { shouldDirty: true })
      setValue('virtualType', '', { shouldDirty: true })
      updateGuestInstructions(t('conversation.restaurantVenueDesc'))
    }
    if (val === 'home') {
      setValue('venueName', t('conversation.homeVenueName', { firstName: userFirstName }), { shouldDirty: true })
      setValue('virtualType', '', { shouldDirty: true })
      updateGuestInstructions(t('conversation.privateVenueDesc'))
    }
    if (val === 'other') {
      setValue('venueName', '', { shouldDirty: true })
      setValue('virtualType', '', { shouldDirty: true })
      setValue('guestInstructions', '', { shouldDirty: true })
    }
  }

  const handleLocation = (val) => {
    if (val) {
      if (isRestaurantConversation || isOtherConversation) {
        const venue_name = val?.venue_name || val?.data?.structured_formatting?.main_text
        const city = val?.data?.structured_formatting?.secondary_text
        venue_name && !watch('venueName') && setValue('venueName', venue_name, { shouldDirty: true })
        city && setValue('city', city, { shouldDirty: true })
      }

      if (isVirtual || isHomeConversation) {
        setValue('city', val.name, { shouldDirty: true })
        setValue('placeId', val.value || '', { shouldDirty: true })
      }

      val?.value &&
        TimeZoneApi.getByPlaceId(val.value)
          .then(({ timeZoneId }) => {
            setValue('timezone', timeZoneId, { shouldDirty: true })
          })
          .catch((err) => {
            console.error({ err })
          })
    }
  }

  const attendanceValidator = isVirtual
    ? { required, minAttendance, ...(watch('virtualType') === 'dailyco' || (!watch('virtualType') && conversation.virtualType === 'dailyco') ? { maxDailyCoAttendance } : {}) }
    : { required, minAttendance }

  const currentTimezone = watch('timezone') || conversation?.timezone
  const isFormValid = Object.keys(errors).length === 0

  const defaultLocation = conversation.formattedAddress || conversation.address || conversation.location || conversation.city || ''

  const timezones = topic?.timezones?.length ? Array.from(topic?.timezones) : moment.tz.names()
  return (
    <div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSave}>
            <HostAssign />

            <div className='fat-bottom'>
              <TextFieldControl
                label={t('conversation.maxAttendees')}
                rules={{ validate: { required, minAttendance } }}
                name='maxAttendees'
                control={control}
                defaultValue={(conversation && conversation.maxAttendees?.toString()) || ''}
                type='number'
                error={errors && errors.maxAttendees}
              />
            </div>

            <div>
              <div className='label'>{t('conversation.venueTypeLabel')}</div>
              <RadioGroupControl
                name='venueType'
                control={control}
                defaultValue={conversation ? conversation.venueType : ''}
                onChange={handleVenueType}
                options={[
                  {
                    label: t('conversation.virtualTypeLabel', {
                      eventType: capitalize(conversation.virtualEventTypeSingular)
                    }),
                    className: `hide ${(topic.virtualConversations || conversation?.venueType === 'virtual') && 'block'}`,
                    value: 'virtual'
                  },
                  {
                    label: t('conversation.restaurantTypeLabel'),
                    className: `hide ${(topic.restaurantConversations || conversation?.venueType === 'restaurant') && 'block'}`,
                    value: 'restaurant'
                  },
                  {
                    label: t('conversation.homeTypeLabel'),
                    className: `hide ${(topic.homeConversations || conversation?.venueType === 'home') && 'block'}`,
                    value: 'home'
                  },
                  {
                    label: t('common.other'),
                    className: `hide ${(topic.otherConversations || conversation?.venueType === 'other') && 'block'}`,
                    value: 'other'
                  }
                ]}
              />
            </div>
            {!isVirtual && (
              <div className='fat-top'>
                <TextFieldControl
                  name='guestInstructions'
                  control={control}
                  defaultValue={conversation && conversation.guestInstructions?.toString()}
                  error={errors && errors.guestInstructions}
                  label={t('conversation.guestInstructionsLabel')}
                  multiline={true}
                  rows={4}
                />
              </div>
            )}

            <TextFieldControl name='address' defaultValue={conversation.address || ' '} control={control} className='hide' />
            <TextFieldControl name='city' defaultValue={conversation.city || ' '} control={control} className='hide' />
            <TextFieldControl name='placeId' defaultValue={conversation.placeId || ' '} control={control} className='hide' />

            {(isOtherConversation || isHomeConversation) && (
              <div className='fat-top'>
                <div className='label'>Food Options</div>

                <RadioGroupControl
                  name='conversationType'
                  control={control}
                  defaultValue={conversation ? conversation.conversationType : ''}
                  required
                  error={errors && errors.conversationType}
                  rules={{ validate: required }}
                  options={[
                    {
                      label: t('conversation.potLuckLabel'),
                      value: 'potluck'
                    },
                    {
                      label: t('conversation.mainCourseLabel'),
                      value: 'main_course_provided'
                    },
                    {
                      label: t('conversation.fullConversationLabel'),
                      value: 'full_conversation_provided'
                    },
                    {
                      label: t('conversation.noFood'),
                      value: 'no_food_provided'
                    },
                    {
                      label: t('common.other'),
                      value: 'other'
                    }
                  ]}
                />
              </div>
            )}
            {!isRestaurantConversation && !isVirtual && (
              <>
                <div className='label reg-top'>Special Diets</div>
                <SuitableDiets conversation={conversation} />
              </>
            )}
            {isVirtual && (
              <>
                <div>
                  <p className='bold'>{t('conversation.virtualLinkHeader')}</p>
                  <RadioGroupControl
                    name='virtualType'
                    control={control}
                    defaultValue={conversation ? conversation.virtualType : ''}
                    error={errors && errors.virtualType}
                    onChange={handleVirtualType}
                    options={[
                      {
                        label: t('conversation.virtualCustomLabel'),
                        value: 'custom'
                      },
                      {
                        label: t('conversation.dailycoLabel'),
                        value: 'dailyco'
                      }
                    ]}
                  />
                </div>

                <div className='fat-top'>
                  <AddressAutocompleteControl
                    types={['(regions)']}
                    name='location'
                    control={control}
                    label={t('conversation.locationLabel')}
                    defaultValue={conversation.city || ''}
                    className='reg-bottom'
                    onChange={handleLocation}
                    required
                    rules={{ validate: required }}
                  />

                  {isCustomVirtualType && (
                    <TextFieldControl
                      name='virtualLink'
                      control={control}
                      defaultValue={(conversation && conversation.virtualLink?.toString()) || ''}
                      error={errors && errors.virtualLink}
                      label={t('conversation.virtualLinkLabel')}
                      className={!isVirtual ? 'hide' : 'reg-bottom'}
                      required
                      rules={{ validate: http, required }}
                    />
                  )}

                  <TextFieldControl
                    name='guestInstructions'
                    control={control}
                    defaultValue={conversation && conversation.guestInstructions?.toString()}
                    error={errors && errors.guestInstructions}
                    label={isVirtual ? t('conversation.joinInstructions') : t('conversation.guestLabel')}
                    className='reg-bottom'
                    multiline={true}
                  />

                  {/* <div className='reg-top'>
                    <TextFieldControl
                      name='maxAttendees'
                      control={control}
                      label={t('conversation.maxAttendees')}
                      value='8'
                      type='number'
                      defaultValue={(conversation && conversation.maxAttendees?.toString()) || ''}
                      rules={{ validate: attendanceValidator }}
                      error={errors && errors.maxAttendees}
                      className='reg-bottom'
                    />
                  </div> */}
                </div>
              </>
            )}

            {(isHomeConversation || isOtherConversation) && (
              <div className='fat-top'>
                <AddressAutocompleteControl
                  types={['address']}
                  name='location'
                  control={control}
                  label={t('conversation.conversationLocationLabel')}
                  defaultValue={defaultLocation}
                  className='reg-bottom'
                  required
                  rules={{ validate: required }}
                  onChange={(val) => {
                    setValue('address', val?.address, { shouldDirty: true })
                    setValue('city', val?.city, { shouldDirty: true })
                    setValue('placeId', val?.place_id, { shouldDirty: true })
                  }}
                />

                <TextFieldControl
                  name='venueName'
                  control={control}
                  defaultValue={conversation?.venueName || ''}
                  error={errors && errors.venueName}
                  label={t('conversation.venueNameLabel', { venue: venueWord })}
                  className='reg-bottom'
                />
              </div>
            )}
            {isRestaurantConversation && (
              <div className='fat-top'>
                <AddressAutocompleteControl
                  name='location'
                  control={control}
                  label={t('conversation.conversationLocationLabel')}
                  defaultValue={defaultLocation}
                  types={['establishment']}
                  error={errors && (errors.location || errors.location)}
                  className='reg-bottom'
                  onChange={handleLocation}
                  required
                  rules={{ validate: required }}
                />
                <TextFieldControl
                  name='venueName'
                  control={control}
                  defaultValue={conversation?.venueName || ''}
                  error={errors && errors.venueName}
                  label={t('conversation.publicVenueNameLabel', { venue: venueWord })}
                  required
                  rules={{ validate: required }}
                />

                {/* <div className='reg-top'>
                  <TextFieldControl
                    name='maxAttendees'
                    control={control}
                    label={t('conversation.maxAttendees')}
                    value='8'
                    type='number'
                    defaultValue={(conversation && conversation.maxAttendees?.toString()) || ''}
                    rules={{ validate: attendanceValidator }}
                    error={errors && errors.maxAttendees}
                    className='reg-bottom'
                    helperText={
                      watch('virtualType') === 'dailyco' || (!watch('virtualType') && conversation.virtualType === 'dailyco')
                        ? 'If you want to host a conversation with 10 or more guests, we recommend using your own Zoom or BlueJeans link that allows for breakout rooms. For an optimal experience on daily.co, we recommend no more than 12 guests'
                        : ''
                    }
                  />
                </div> */}
              </div>
            )}
            <div className='fat-top'>
              <div className='label reg-top'>{t('common.date')}</div>
              <DateControl
                name='startsAtDate'
                control={control}
                error={errors && errors.startsAtDate}
                defaultValue={conversation ? moment(conversation.startsAt) : moment()}
                rules={{
                  validate: (tm) => {
                    return !isValidDate(topic.startsAt, topic.endsAt)(moment(tm)) ? 'Invalid date' : undefined
                  }
                }}
                dateProps={{
                  inputProps: {
                    min: topic.startsAt && moment().isBefore(moment(topic.startsAt)) ? topic.startsAt : moment().format('yyyy-MM-DD'),
                    max: topic.endsAt ? topic.endsAt : undefined
                  }
                }}
              />
              <div className='label reg-top'>{t('common.startTime')}</div>
              <TimeControl
                name='startsAt'
                error={errors && errors.startsAt}
                control={control}
                rules={{ validate: (startsAtVal) => (!isValidTime(moment(startsAtVal), moment(watch('endsAt'))) ? t('convos.validTime') : undefined) }}
                defaultValue={conversation && moment.tz(conversation.startsAt, currentTimezone)}
                onChange={(val: any) => {
                  if (!val) return
                  trigger('endsAt')
                  setValue('startsAt', val, { shouldDirty: true })
                }}
              />
              <div className='label reg-top'>{t('common.endTime')}</div>
              <TimeControl
                name='endsAt'
                control={control}
                defaultValue={conversation && moment.tz(conversation.endsAt, currentTimezone)}
                rules={{ validate: (endsAtVal) => (!isValidTime(moment(watch('startsAt')), moment(endsAtVal)) ? t('convos.validTime') : undefined) }}
                error={errors && errors.endsAt}
                onChange={(val: any) => {
                  if (!val) return
                  trigger('startsAt')
                  setValue('endsAt', val, { shouldDirty: true })
                }}
              />

              <SelectControl
                name='timezone'
                label={t('common.timeZone')}
                className='reg-top'
                control={control}
                defaultValue={conversation && conversation.timezone}
                error={errors && errors.timezone}
                options={(timezones.length > 0 ? Array.from(timezones) : moment.tz.names()).map((value) => ({ label: value, value }))}
              />
            </div>

            <div className='fat-top'>
              <Typography variant='subtitle2' className='reg-bottom'>
                Event Settings
              </Typography>
              <CheckboxControl
                name='privateEvent'
                label={'Invite Only'}
                control={control}
                defaultValue={conversation.guestPolicy === 'public' ? false : conversation.guestPolicy === 'approval_only' ? true : undefined}
                error={errors && errors.privateEvent}
              />
              <Typography variant='caption' component='div' style={{ marginLeft: '2rem' }}>
                Just like a private party, the host’s job is to send invitations and manage the guest list. The host will need to monitor and approve requests to attend made by
                uninvited guests. We recommend this option for those hosting in private residences, as the location will be hidden from unconfirmed attendees.
              </Typography>
            </div>

            <div>
              <CheckboxControl
                name='registrationClosed'
                label={t('conversation.closeRegistration', { event: conversation.eventTypeSingular })}
                control={control}
                defaultValue={conversation && conversation.registrationClosed}
                error={errors && errors.registrationClosed}
              />
              <Typography variant='caption' component='div' style={{ marginLeft: '2rem' }}>
                Close registration and mark the conversation as full.
              </Typography>
            </div>

            <div>
              <CheckboxControl
                name='inviteFriends'
                label={'Encourage Guests to Invite Others'}
                control={control}
                defaultValue={conversation && conversation.inviteFriends}
                error={errors && errors.inviteFriends}
              />
              <Typography variant='caption' component='div' style={{ marginLeft: '2rem' }}>
                Show buttons to encourage others to Invite Guests and Share the event on social channels in the conversation page actions bar.
              </Typography>
            </div>

            <div className='fat-top'>
              <Typography variant='subtitle2' className='reg-bottom'>
                Visibility Settings
              </Typography>
              <CheckboxControl
                name='hidden'
                label={`Hide ${conversation.eventTypeSingular} Card`}
                control={control}
                defaultValue={conversation && conversation.hidden}
                error={errors && errors.hidden}
              />
              <Typography variant='caption' component='div' style={{ marginLeft: '2rem' }}>
                Hide the conversation card on the Topic and Customer Pages.
              </Typography>
            </div>
            {user.isPartnerAdmin() && (
              <>
                <div>
                  <CheckboxControl
                    name='topicFeatured'
                    label={`Feature ${conversation.eventTypeSingular} on Topic Page`}
                    control={control}
                    defaultValue={conversation && conversation.topicFeatured}
                    error={errors && errors.topicFeatured}
                  />
                  <Typography variant='caption' component='div' style={{ marginLeft: '2rem' }}>
                    Show the conversation card in the Featured Event section on the Topic Page. Note that this section must be enabled on the edit Topic Page settings for the
                    section to be visible.
                  </Typography>
                </div>

                <div>
                  <CheckboxControl
                    name='partnerFeatured'
                    label={`Feature ${conversation.eventTypeSingular} on Customer Page`}
                    control={control}
                    defaultValue={conversation && conversation.partnerFeatured}
                    error={errors && errors.partnerFeatured}
                  />
                  <Typography variant='caption' component='div' style={{ marginLeft: '2rem' }}>
                    Show the conversation card in the Featured Event section on the Customer Page. Note that this section must be enabled on the edit Customer Page settings for the
                    section to be visible.
                  </Typography>
                </div>
              </>
            )}

            {user.isSuperAdmin() && (
              <div>
                <SelectControl
                  name='eventType'
                  label={
                    <>
                      {t('conversation.eventType')}
                      <Tooltip title='Enabled by Super Admins only.' arrow>
                        <Chip
                          label='Super Admin'
                          color='secondary'
                          className={'reg-left'}
                          style={{
                            backgroundColor: '#e495be40',
                            color: '#212121'
                          }}
                          size='small'
                        />
                      </Tooltip>
                    </>
                  }
                  className='fat-top'
                  control={control}
                  defaultValue={conversation && conversation.eventType}
                  error={errors && errors.eventType}
                  options={[
                    { label: 'Standard', value: 'dinner' },
                    { label: 'Premium', value: 'premium_event' }
                  ]}
                />

                <div className='label flat-top'>{t('conversation.changeToPremium')}</div>
                <div className='label flat-top'>{t('conversation.warnPermanentAction')}</div>
              </div>
            )}
            <Grid container justify='space-between' className='reg-bottom xtra-fat-top'>
              <Grid item>
                <Button disabled={!isDirty || isSubmitting || !isFormValid} type='submit' color='secondary' variant='contained'>
                  Save Changes
                </Button>
              </Grid>
              <Grid item>
                {conversation.canDelete() && (
                  <Button onClick={() => conversation.deleteConversation()} variant='contained' style={{ color: '#EE413E' }}>
                    {conversation.synthetic ? 'Delete' : t('conversation.cancelEvent')}
                  </Button>
                )}
              </Grid>
            </Grid>
          </form>
          <Prompt when={(isDirty && Object.keys(dirtyFields).length !== 0) || isSubmitting} message={UNSAVED_FORM_WARNING} />
        </FormProvider>
      </MuiPickersUtilsProvider>
    </div>
  )
}

export default inject<FIXME, FIXME>(
  ({
    mst: {
      auth: { user },
      dialogs: { showDialog }
    }
  }: {
    mst: IStore
  }) => {
    return { user, showDialog }
  }
)(observer(withTranslation(ConversationForm)))
