import React from 'react'
import { Link } from 'react-router-dom'

import { observer } from 'mobx-react'
import DataTable, { IColData, ValueLabel } from '../../shared/DataTableFilterable'
import MembersActions from './MembersActions'
import { IMembershipUser, IMembershipUserBase } from '../../../store/models/User/MembershipUser'
import moment from 'moment-timezone'
import { ITrack } from '../../../store'
import { Button, MenuItem, Select } from '@material-ui/core'
import { sortBy } from 'lodash'
import { useAuth } from '../../Providers'
import Avatar from '../../users/Avatar'

interface IProps {
  membership: IMembershipUser
  track: ITrack
  search?: string
  hideConnection?: boolean
}

const searchList = (list: IMembershipUserBase[], word) => {
  return list.filter((item) => {
    return item.user?.firstName?.toLowerCase().match(word) || item.user?.lastName?.toLowerCase().match(word) || item.email?.toLowerCase()?.match(word)
  })
}
const sortList = (list: IMembershipUserBase[], sorted_id?: string, sorted_priority?: boolean) => {
  const getDataFromColumn = (obj?: IMembershipUserBase): any => {
    if (sorted_id === 'name') {
      return obj?.user?.fullName
    }
    if (sorted_id === 'subscriptionDate') {
      return obj?.startsAt
    }
    return obj && sorted_id ? obj[sorted_id] : undefined
  }

  if (sorted_id) {
    const sorted = sortBy(list, [(obj) => getDataFromColumn(obj)])
    return sorted_priority ? sorted.reverse() : sorted
  } else {
    return list
  }
}
const CommunityTable = observer((props: IProps) => {
  const { membership, track, search, hideConnection } = props

  const { user } = useAuth()

  const columnData: IColData[] = [
    { name: 'picture', title: ' ', width: 150, sortable: true },
    { name: 'name', title: 'Member', width: 150, sortable: true },
    { name: 'attendedConversationCount', title: 'Conversations Attended', align: 'center', width: 150, sortable: true },
    { name: 'actionsCompletedCount', title: 'Actions Completed', align: 'center', width: 130, sortable: true },
    { name: 'storiesCount', title: 'Reflections Shared', align: 'center', width: 130, sortable: true },
    ...(!hideConnection ? [{ name: 'connected', title: 'Connected?', width: 250, sortable: true }] : [])
    // { name: 'actions', title: ' ', width: 100, align: 'center' }
  ]

  const list = membership.fullList.filter((item) => item.status === 'active')
  const sortedList = sortList(search ? searchList(list, search) : list, membership.sortedId, membership.sortedPriority)
  return (
    <DataTable
      data={sortedList}
      columns={columnData}
      loading={membership.loadingIds.includes('fullList')}
      filterable={membership}
      rightColumns={['actions']}
      height={'calc(100vh - 18rem)'}
      noDataMsg='None'
      hidePaging
      hideSearch
      getCellValue={(row: IMembershipUserBase, columnName: string) => {
        const row_user = row.user
        switch (columnName) {
          case 'name':
            return (
              <ValueLabel
                value={
                  <Link rel='noopener' title={row.user?.fullName} to={`/users/${row_user?.id}`} target='blank' color='primary'>
                    {row.user?.fullName}
                  </Link>
                }
              />
            )
          case 'connected':
            // const is_connected = row?.user?.id && user?.connections.includes(row?.user?.id)
            return (
              <ValueLabel
                value={
                  row?.user?.id && user?.connections.includes(row?.user?.id) ? (
                    <Button disabled={true}>Connected</Button>
                  ) : row?.user?.id && user?.connectionsRequested.includes(row?.user?.id) ? (
                    <Button disabled={true}>Requested</Button>
                  ) : (
                    <Button
                      onClick={() => {
                        row?.user?.id &&
                          user
                            ?.createUserConnection({ requested_user_id: row?.user?.id })
                            .then(() => {
                              user.addToUserConnectionRequested(row?.user?.id)
                              user.showAlert('Connection Requested')
                            })
                            .then(() => membership.loadFullList(true))
                      }}
                    >
                      Request Connection
                    </Button>
                  )
                }
              />
            )
          case 'subscriptionDate':
            return <ValueLabel value={`${moment(row.startsAt).format('L')} - ${moment(row.endsAt).format('L')}`} />
          case 'picture':
            return (
              <ValueLabel
                value={
                  <Link rel='noopener' title='User Profile' to={`/users/${row_user?.id}`} target='blank' color='primary'>
                    <Avatar profileImage={row_user && row_user.profileImage} hasProfileImage={row_user && row_user.hasProfileImage} size={'thumb'} />
                  </Link>
                }
              />
            )
          case 'profile':
            return (
              <ValueLabel
                value={
                  <Link rel='noopener' title='User Profile' to={`/users/${row_user?.id}`} target='blank' color='primary'>
                    {`/users/${row.id}`}
                  </Link>
                }
              />
            )
          case 'actions':
            return <MembersActions member={row} membership={membership} />
          default:
            return <ValueLabel value={row[columnName]} />
        }
      }}
    />
  )
})

const RoleSelect = observer(({ membershipUser, membership }: { membershipUser: IMembershipUserBase; membership: IMembershipUser; track: ITrack }) => {
  if (membershipUser.isOwner() && !membership.isOwner()) {
    return <>Owner</>
  }
  return (
    <Select value={membershipUser.role} onChange={(e) => membershipUser.updateRole(e.target.value)}>
      {membership.isOwner() && <MenuItem value={'owner'}>Owner</MenuItem>}
      <MenuItem value={'manager'}>Manager</MenuItem>
      <MenuItem value={'user'}>User</MenuItem>
    </Select>
  )
})

export default CommunityTable
