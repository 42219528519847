import React, { useState } from 'react'
import NewTopicPage from '../../../topics/New'
import SummaryContainer from '../partials/SummaryContainer'
import withTranslation from '../../../hocs/withTranslation'
import { Breadcrumbs, Button, Typography } from '@material-ui/core'
import { withRouter, RouteComponentProps } from 'react-router'
import AdminMetaTags from '../../../shared/AdminMetaTags'
import { Link } from 'react-router-dom'
import { flow } from 'lodash'

interface Props {
  t: any
}

const New = ({ location, t }: Props & RouteComponentProps) => {
  const [dirty, setdirty] = useState(false)
  const [submit, setSubmit] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  return (
    <div>
      <AdminMetaTags title='Topics' pageType='New' />

      <Breadcrumbs aria-label='breadcrumb'>
        <Link color='primary' to='/admin/topics'>
          Topics
        </Link>
        <Typography color='textPrimary'>New Topic</Typography>
      </Breadcrumbs>
      <SummaryContainer
        title={t('convos.newConvo')}
        topRight={
          <div>
            <Button
              disabled={!dirty || submit || submitting}
              color='primary'
              onClick={() => {
                setSubmit(true)
              }}
              variant='contained'
              disableElevation
            >
              Save Changes
            </Button>
          </div>
        }
      >
        <NewTopicPage
          submit={submit}
          copy={(location as any)?.state?.topic}
          onDirty={(d) => {
            setdirty(d)
            setSubmit(false)
          }}
          onSubmit={(val) => setSubmitting(val)}
        />
      </SummaryContainer>
    </div>
  )
}

export default flow(withTranslation, withRouter)(New)
