import React from 'react'
import { Link } from 'react-router-dom'

import { observer } from 'mobx-react'
import DataTable, { IColData, ValueLabel } from '../../shared/DataTableFilterable'
import { IMemberInvite, IMembershipUser, IMembershipUserBase } from '../../../store/models/User/MembershipUser'
import moment from 'moment-timezone'
import { ITrack } from '../../../store'
import { MenuItem, Select } from '@material-ui/core'
import { sortBy } from 'lodash'

interface IProps {
  membership: IMembershipUser
  track: ITrack
  search?: string
}
const searchList = (list: IMemberInvite[], word) => {
  return list.filter((item) => {
    return item.recipientEmail?.toLowerCase()?.match(word)
  })
}
const sortList = (list: IMemberInvite[], sorted_id?: string, sorted_priority?: boolean) => {
  const getDataFromColumn = (obj?: IMemberInvite): any => {
    return obj && sorted_id ? obj[sorted_id] : undefined
  }

  if (sorted_id) {
    const sorted = sortBy(list, [(obj) => getDataFromColumn(obj)])
    return sorted_priority ? sorted.reverse() : sorted
  } else {
    return list
  }
}

const InvitedMembersTable = observer((props: IProps) => {
  const { membership, track, search } = props

  const columnData: IColData[] = [
    { name: 'recipientEmail', title: 'Email', width: 350, sortable: true },
    { name: 'createdAt', title: 'Sent', width: 200, sortable: true }
    // { name: 'actions', title: ' ', width: 100, align: 'center' }
  ]
  const sortedList = sortList(search ? searchList(membership.pendingList, search) : membership.pendingList, membership.sortedId, membership.sortedPriority)
  return (
    <DataTable
      data={sortedList}
      columns={columnData}
      loading={membership.loadingIds.includes('fullList')}
      filterable={membership}
      rightColumns={['actions']}
      height={'calc(100vh - 29rem)'}
      noDataMsg='None'
      hidePaging
      hideSearch
      getCellValue={(row: IMemberInvite, columnName: string) => {
        switch (columnName) {
          case 'createdAt':
            return <ValueLabel value={moment(row.createdAt).format('L')} />
          default:
            return <ValueLabel value={row[columnName]} />
        }
      }}
    />
  )
})

export default InvitedMembersTable
