import { deleteApi, get } from '../utils'
import queryString from 'query-string'

class npsAnswersApi {
  getAll(args) {
    const defaultParams = { page: 0, site_name: window.siteName }
    const params = queryString.stringify({ ...defaultParams, ...args })
    return get(`/admin/nps_answers?${params}`)
  }

  destroy(id: number) {
    return deleteApi(`/nps_answers/${id}`)
  }
}

export default new npsAnswersApi()
