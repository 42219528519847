import React, { Component } from 'react'
import { Skeleton } from '@material-ui/lab'
import { Button, InputBase, Divider, InputAdornment, Typography, TablePagination, Icon, Tooltip } from '@material-ui/core'
import { observer } from 'mobx-react'
import { VirtualTable, Grid, TableHeaderRow, TableFixedColumns } from '@devexpress/dx-react-grid-material-ui'
import { IFilterable } from '../../store/models/helpers/Filterable'

class DataTableFilterable extends Component<Props, State> {
  componentDidMount() {
    this.props.filterable.loadFilter({ reset: !this.props.topicId })
  }

  onFormSubmit = (e: any) => {
    e.preventDefault()
    this.props.filterable.loadFilter()
  }

  handleSearch = (e: any) => {
    e.preventDefault()
    this.props.filterable.setSearch(e.target.value)
  }

  handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      const { filterable } = this.props
      filterable.loadFilter()
    }
  }

  handlePaging = (e: any, val) => {
    e.preventDefault()
    const { filterable } = this.props
    filterable.setPage(val)
    filterable.loadFilter()
  }

  handlePageSize = (e: any) => {
    e.preventDefault()
    const { filterable } = this.props
    filterable.setPageSize(e.target.value)
    filterable.setPage(0)
    filterable.loadFilter()
  }

  handleSorting = (name: string) => (e: any) => {
    const { filterable } = this.props
    e.preventDefault()
    filterable.setSortedPriority(name === filterable.sortedId ? !filterable.sortedPriority : false)
    filterable.setPage(0)
    filterable.setSortingId(name)
    filterable.loadFilter()
  }

  render() {
    const {
      loading,
      columns = [],
      data = [],
      pageSizeOptions = [10, 15, 50, 100],
      hideFilter,
      hidePaging,
      hideSearch,
      height = 'calc(100vh - 25.5em)',
      searchOnly = false,
      filterable,
      leftColumns,
      rightColumns,
      getCellValue,
      searchPlaceholder,
      noDataMsg = "No results found. Try adjusting your search to find what you're looking for."
    } = this.props
    const { totalCount, search, sortedId, pageSize, page } = filterable

    const colExt: ColumnExtension[] = columns.map((c) => ({ ...c, columnName: c.name, wordWrapEnabled: true }))

    return (
      <div>
        <form onSubmit={this.onFormSubmit}>
          {!hideSearch && !hideFilter && (
            <>
              <div className='reg-padding'>
                <InputBase
                  value={search}
                  onChange={this.handleSearch}
                  onKeyPress={this.handleKeyPress}
                  placeholder={searchPlaceholder || 'Search'}
                  fullWidth
                  startAdornment={
                    !searchOnly && (
                      <InputAdornment position='start'>
                        <i className='far fa-search'></i>
                      </InputAdornment>
                    )
                  }
                />
              </div>
              <Divider />

              <Button style={{ display: 'none' }} />
            </>
          )}
          {loading && (
            <Skeleton variant='rect' animation='wave'>
              <div style={{ width: '100vw', height }}></div>
            </Skeleton>
          )}
          {!loading && data.length > 0 && (
            <Grid getCellValue={getCellValue} rows={data} columns={columns}>
              <VirtualTable columnExtensions={colExt} height={height} />
              <TableHeaderRow
                contentComponent={({ align, column, children, ...restProps }: any) => {
                  const isActive = sortedId && sortedId === column.name
                  const headerStyle = {
                    ...(column.minWidth ? { minWidth: column.minWidth } : {}),
                    ...(column.sortable ? { cursor: 'pointer' } : {}),
                    fontWeight: isActive ? 800 : 500
                  }
                  return (
                    <TableHeaderRow.Content
                      style={headerStyle}
                      onClick={column.sortable ? this.handleSorting(column.name) : undefined}
                      align={align || column.align}
                      column={column}
                      {...restProps}
                    >
                      {children}
                      {column.sortable && isActive ? (
                        filterable.sortedPriority ? (
                          <i className='far fa-arrow-down thin-left-padding'></i>
                        ) : (
                          <i className='far fa-arrow-up thin-left-padding'></i>
                        )
                      ) : null}

                      {column.icon && (
                        <Tooltip title={column.hint} placement='top'>
                          <Icon className='fa fa-info-circle' color='primary' style={{ marginLeft: '.5em', fontSize: '1em' }} />
                        </Tooltip>
                      )}
                    </TableHeaderRow.Content>
                  )
                }}
              />
              <TableFixedColumns leftColumns={leftColumns} rightColumns={rightColumns} />
            </Grid>
          )}
          {!loading && data.length === 0 && (
            <Typography gutterBottom variant='h5' component='h2' align='center' className='reg-padding' style={{ height }}>
              {noDataMsg}
            </Typography>
          )}

          {!hideFilter && !hidePaging && (
            <TablePagination
              rowsPerPageOptions={pageSizeOptions}
              component='div'
              count={totalCount || 0}
              rowsPerPage={pageSize || 10}
              page={page}
              onChangePage={this.handlePaging}
              onChangeRowsPerPage={this.handlePageSize}
            />
          )}
        </form>
      </div>
    )
  }
}
interface SearchArgs {
  search: string
  page_size: number | string
  page: number
  sorted_id?: string
  sorted_priority?: boolean
}
interface State extends SearchArgs {}
interface Props {
  loading?: boolean
  size?: 'small' | 'medium' | undefined
  height?: string | number | undefined
  hideFilter?: boolean
  hidePaging?: boolean
  hideSearch?: boolean
  searchOnly?: boolean
  columns: IColData[]
  data: any[]
  noDataMsg?: string
  pageSize?: number
  pageSizeOptions?: (number | { label: any; value: any })[]
  leftColumns?: string[]
  rightColumns?: string[]
  filterable: IFilterable
  searchPlaceholder?: string
  getCellValue?(row: any, columnName: string): any
  icon?: string
  topicId?: number
}

export interface IColData {
  name: string
  title?: string
  width?: number
  minWidth?: number
  sortable?: boolean
  align?: 'left' | 'center' | 'right' | undefined
  icon?: string
  hint?: string
}

interface ColumnExtension {
  columnName: string
  width?: number | string
  align?: 'left' | 'right' | 'center'
  wordWrapEnabled?: boolean
}

export const ValueLabel = ({ value, empty, title }: { value?: string | React.ReactNode | undefined; empty?: string | React.ReactNode | undefined; title?: string }) => (
  <div title={title ? title : typeof value === 'string' ? value : undefined}>{value ? value : empty !== undefined ? empty : '-'}</div>
)

export default observer(DataTableFilterable)
