import { Button, Card, Paper } from '@material-ui/core'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParamPartner } from '../../../Providers'
import { TextFieldControl } from '../../../utils'
import * as validations from '../../../validations/fieldLevelValidations'

type Props = {
  //  todo
}

export default observer(function Sso(props: Props) {
  const [newForm, setNewForm] = useState(false)
  const { handleSubmit, errors, formState, control, reset } = useForm({ mode: 'onBlur' })
  const { isDirty, isSubmitting, isValid } = formState

  const { partner } = useParamPartner()
  const handleSave = handleSubmit((data: any, e) => {
    partner?.updatePartner({ sso_infos_attributes: [data] }).then((resp) => {
      reset({}, { isDirty: false })
    })
  })

  return (
    <div>
      <Button variant='outlined' onClick={() => setNewForm((prevSt) => !prevSt)}>
        {!newForm ? 'Add New' : 'Cancel'}
      </Button>
      {newForm && (
        <form onSubmit={handleSave}>
          <TextFieldControl
            name='domain'
            control={control}
            error={errors && errors.domain}
            label={'Domain'}
            required
            rules={{ validate: { required: validations.required } }}
            className='reg-bottom'
          />
          <TextFieldControl
            name='login'
            control={control}
            error={errors && errors.login}
            label={'SSO URL'}
            required
            rules={{ validate: { required: validations.required, http: validations.http } }}
            className='reg-bottom'
          />
          <TextFieldControl
            name='metadata'
            control={control}
            error={errors && errors.metadata}
            label={'Metadata'}
            required
            rules={{ validate: validations.required }}
            className='reg-bottom'
          />

          <Button disabled={!isValid || !isDirty || isSubmitting} type='submit' className='giant-top' color='secondary' variant='contained'>
            Submit
          </Button>
        </form>
      )}
      <div>
        {partner?.ssoInfos.map((sso) => (
          <Paper key={sso.id} variant='outlined' className='reg-top reg-left-padding reg-top-padding reg-bottom-padding reg-right-padding'>
            <div className='reg-bottom'>Domain: {sso.domain}</div>
            <div className='reg-bottom'>SSO URL: {sso.login}</div>
            <div className='reg-bottom'>Metadata: {sso.metadata}</div>
            <Button
              variant='outlined'
              onClick={() => {
                if ((window as FIXME).confirm('Are you sure you want to delete this SSO info')) {
                  partner?.updatePartner({ sso_infos_attributes: [{ id: sso.id, _destroy: '1' }] })
                }
              }}
            >
              Delete
            </Button>
          </Paper>
        ))}
      </div>
    </div>
  )
})
