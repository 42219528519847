import { UPDATE_TAB_POSITION } from '../actions/actionTypes'
import { assign } from 'lodash'
import initialState from './initialState'

export const tabs = (state = initialState.tabs, action) => {
  switch (action.type) {
    case UPDATE_TAB_POSITION:
      return assign({}, state, {
        position: action.position
      })

    default:
      return state
  }
}

export default tabs
