import { Button, createStyles, Grid, IconButton, makeStyles, Paper, Theme, Tooltip, Typography } from '@material-ui/core'
import { observer } from 'mobx-react'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { IConversation, ITrack } from '../../../../store'
import { IMembership } from '../../../../store/models/Track/Membership'
import { useParamPartner } from '../../../Providers'
import { usePartnerTracks } from '../../../Providers/PartnerTracksProvider'
import DataTable, { IColData, ValueLabel } from '../../../shared/DataTableFilterable'
import { Link } from 'react-router-dom'
import MembersListDialog from './MembersListDialog'
import ConversationCard from '../../../conversations/Card'
import AddConversationDialog from './AddConversationDialog'
import LoadingAnimation from '../../../shared/LoadingAnimation'
import EditMemberDialog from './EditMemberDialog'
import AppTabs from '../../../shared/AppTabs'
import AddMembershipDialog from './AddMembershipDialog'
import { get } from '../../../../api/utils'
import TrackForm from './TrackForm'

interface Props {}

export const Tracks = observer((props: Props) => {
  const { partner } = useParamPartner()
  const { loadTracks, tracks, trackLoaded, trackLoading } = usePartnerTracks()
  const reloadList = useCallback(() => {
    if (partner && !trackLoaded && !trackLoading) {
      loadTracks(partner.id)
    }
  }, [partner, trackLoaded, trackLoading, loadTracks])
  useEffect(() => {
    reloadList()
  }, [partner, reloadList])

  if (tracks.length === 0) {
    return <LoadingAnimation />
  }

  return (
    <div>
      {tracks.map((track) => (
        <Track key={track.id} track={track} reloadList={reloadList} />
      ))}
    </div>
  )
})

export default Tracks

const TrackConversations = ({ conversations }: { conversations: IConversation[] }) => (
  <section className={'pane thin-pane'}>
    <div className='row center-xs'>
      {conversations.map((conversation) => (
        <ConversationCard key={conversation.id} conversation={conversation} rootClassName={'col-xs-12 col-sm-6'} />
      ))}
    </div>
  </section>
)

const Track = observer(({ track, reloadList }: { track: ITrack; reloadList?() }) => {
  const [tabIndex, setTabIndex] = useState(0)
  const columnData: IColData[] = [
    { name: 'owners', title: 'Owner', width: 250 },
    { name: 'totalMembers', title: 'Members', width: 90, align: 'center' },
    { name: 'seatLimit', title: 'Seat Limit', width: 90, align: 'center', sortable: true },
    { name: 'hubspotDealId', title: 'Hubspot Deal ID', width: 150, sortable: true },
    { name: 'active', title: 'Status', width: 90, sortable: true },
    { name: 'amount', title: 'Amount', width: 90, sortable: true },
    { name: 'dealClosedAt', title: 'Deal Closed', width: 150, sortable: true },
    { name: 'startsAt', title: 'Starts', width: 150, sortable: true },
    { name: 'endsAt', title: 'Ends', width: 150, sortable: true },
    { name: 'id', title: 'ID', width: 90, align: 'right', sortable: true },
    { name: 'dealName', title: 'Deal Name', width: 250, sortable: true },
    { name: 'companyName', title: 'Company Name', width: 200 },

    { name: 'actions', title: 'Actions', width: 150, align: 'center' }
  ]
  const [selectedMembership, setselectedMembership] = useState<IMembership | undefined>(undefined)
  const [editType, setEditType] = useState<'list' | 'edit' | undefined>(undefined)
  const [tracks, setTracks] = React.useState<ITrack[]>([])

  useEffect(() => {
    get('/admin/tracks').then(({ json }) => {
      setTracks(json)
    })
  }, [setTracks])
  const { partner } = useParamPartner()
  const classes = useStyles()
  return (
    <Paper elevation={0}>
      {selectedMembership && editType === 'list' && (
        <MembersListDialog
          membership={selectedMembership}
          onClose={() => {
            setselectedMembership(undefined)
            setEditType(undefined)
          }}
        />
      )}
      {selectedMembership && editType === 'edit' && (
        <EditMemberDialog
          membership={selectedMembership}
          tracks={tracks}
          onClose={() => {
            setselectedMembership(undefined)
            setEditType(undefined)
          }}
        />
      )}
      <Typography variant='h5' className='thin-bottom'>
        {track.name}
      </Typography>

      <AppTabs
        index={tabIndex}
        onChange={(tIndex) => {
          setTabIndex(tIndex)
          // history.push(`/admin/partners/${partner.slug}/edit/t/${this.tab_names[tabIndex]}`)
        }}
        noBottom={true}
        tabs={[
          {
            label: 'Memberships',
            wide: true,
            content: (
              <>
                <Typography variant='h6' className='reg-bottom'>
                  Membership Subscriptions ({track.memberships.length}) &nbsp;
                  <AddMembershipDialog track={track} />
                </Typography>
                <Paper elevation={0}>
                  <DataTable
                    height={'calc(100vh / 3)'}
                    data={track?.list}
                    columns={columnData}
                    loading={track.loadingIds.includes('memberships_table')}
                    filterable={track}
                    rightColumns={['actions']}
                    searchPlaceholder={'Search'}
                    getCellValue={(row: IMembership, columnName: string) => {
                      switch (columnName) {
                        case 'name':
                          return <ValueLabel value={row.dealName} />
                        case 'active':
                          return <ValueLabel value={row.active ? 'Active' : 'Inactive'} />
                        case 'totalMembers':
                          return (
                            <ValueLabel
                              value={row.totalMembers}
                              // onClick={(e) => setselectedMembership(row)}
                            />
                          )
                        case 'startsAt':
                          return <ValueLabel value={moment(row.startsAt).format('L')} />
                        case 'dealClosedAt':
                          return <ValueLabel value={moment(row.dealClosedAt).format('L')} />
                        case 'endsAt':
                          return <ValueLabel value={moment(row.endsAt).format('L')} />
                        case 'owners':
                          return <ValueLabel value={row.owners.map((o) => `${o.user?.fullName} (${o.email})`).join(', ')} />
                        case 'actions':
                          return (
                            <MembershipActions
                              membership={row}
                              onClick={(e, m, eType) => {
                                setselectedMembership(m)
                                setEditType(eType)
                              }}
                              onReload={() => reloadList && reloadList()}
                            />
                          )
                        default:
                          return <ValueLabel value={row[columnName]} />
                      }
                    }}
                  />
                </Paper>
              </>
            )
          },
          {
            label: 'Conversations',
            wide: true,
            content: (
              <>
                <Grid container justify='space-between'>
                  <Grid item>
                    <Typography variant='h6' className='reg-bottom'>
                      Conversations ({track.conversations.length}){' '}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <AddConversationDialog track={track} />
                  </Grid>
                </Grid>
                <Paper elevation={0} className={`${classes.conversations} reg-bottom`}>
                  <TrackConversations conversations={track.conversations} />
                  {/* <section className={'pane thin-pane'}>
          <div className='row center-xs'>
            {partner && track.conversations.map((conversation) => <ConversationCard key={conversation.id} conversation={conversation} rootClassName={'col-xs-12 col-sm-6'} />)}
          </div>
        </section> */}
                </Paper>
              </>
            )
          },
          {
            label: 'Settings',
            wide: false,
            content: (
              <>
                <Typography variant='h6' className='reg-bottom'>
                  Customize your Journey Page
                  <Tooltip title='Copy journey link to clipboard'>
                    <Button
                      className='reg-left'
                      onClick={() => {
                        navigator.clipboard.writeText(`${window.location.origin}/${partner?.slug}/journey`)
                        partner?.showAlert('Link copied to clipboard')
                      }}
                    >
                      <i className={'fas fa-link'} />
                    </Button>
                  </Tooltip>
                </Typography>
                <Paper elevation={0} className={`${classes.conversations} reg-bottom`}>
                  <TrackForm partner={partner} />
                </Paper>
              </>
            )
          }
        ]}
        contentStyle={{ height: 'calc(100vh - 29em)', overflowY: 'auto' }}
      />
    </Paper>
  )
})

const MembershipActions = ({ membership, onClick, onReload }: { membership: IMembership; onClick?(e, m: IMembership, eType: 'list' | 'edit'); onReload?() }) => {
  const [edit, setEdit] = useState(false)
  return (
    <Grid container direction='row' justify='space-around' alignItems='center'>
      {/* {edit && (
        <EditMemberDialog
          membership={membership}
          onClose={() => {
            setEdit(false)
            onReload && onReload()
          }}
        />
      )} */}
      <Tooltip title='View Profile'>
        <Link to={`/users/${membership.owners[0]?.user?.id}`} target='_blank'>
          <IconButton size='small'>
            <i className='fas fa-user opacity-8'></i>
          </IconButton>
        </Link>
      </Tooltip>
      <Tooltip title='Edit Membership'>
        <IconButton size='small' onClick={(e) => onClick && onClick(e, membership, 'edit')}>
          <i className='fas fa-pen opacity-8'></i>
        </IconButton>
      </Tooltip>
      <Tooltip title='Manage Members'>
        <IconButton size='small' onClick={(e) => onClick && onClick(e, membership, 'list')}>
          <i className='fas fa-list-ul opacity-8'></i>
        </IconButton>
      </Tooltip>
    </Grid>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { marginTop: '1em' },
    delete: {
      color: '#E51814'
    },
    conversations: {
      // maxHeight: 'calc(100vh / 3)',
      overflow: 'auto'
    }
  })
)
