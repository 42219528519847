import { Container, Grid, IconButton, Tooltip } from '@material-ui/core'
import { observer } from 'mobx-react'
import React from 'react'
import { IPartner } from '../../../../store'
import { IOrganization } from '../../../../store/models/Organization/Organization'
import PartnersTable, { OrganizationPartnersTable } from '../Partners/PartnersTable'

interface Props {
  organization: IOrganization
  filterItem?(item: any): any[]
  showPartnersSelector?: boolean
}

const ManagePartners = ({ organization, showPartnersSelector = false }: Props) => {
  return (
    <>
      {showPartnersSelector ? (
        <Grid container direction='row'>
          <Grid item xs={5}>
            <Container>
              <h2>Associated Customers</h2>
              <OrganizationPartnersTable hideSearch={true} summaryVersion={true} organization={organization} />
            </Container>
          </Grid>
          <Grid item xs={6}>
            <Container>
              <h2>All Customers</h2>
              <PartnersTable
                itemAction={(partner: IPartner) =>
                  partner &&
                  partner.organizationId !== organization.id && (
                    <Tooltip title='Add to Organization'>
                      <IconButton size='small' onClick={() => partner.updatePartner({ organizationId: organization.id }).then(() => organization.loadFilter())}>
                        <i className='fas fa-plus opacity-8'></i>
                      </IconButton>
                    </Tooltip>
                  )
                }
              />
            </Container>
          </Grid>
        </Grid>
      ) : (
        <Grid container direction='row' justify='center'>
          <Grid item xs={12}>
            <div className='row'>
              <div className='fat-top'>
                <h2>Customers</h2>
                <OrganizationPartnersTable hideSearch={true} organization={organization} />
              </div>
            </div>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default observer(ManagePartners)
