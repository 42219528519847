import React from 'react'

import { flow } from 'lodash'
import { observer } from 'mobx-react'
import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import { MediaFilePreview } from '../utils/AvatarUploader'
import { IStory } from '../../store/models/Stories'
import { StoryAuthor } from './StoryAuthor'
import { StoryContext } from './contexts'
import MetaTags from 'react-meta-tags'
interface Props {
  story: IStory
}

const StoryShow = ({ story }: Props) => {
  const classes = Styles()
  if (!story) return <></>
  const metaTitle = story.headline
  const metaDescription = story.content
  const hasMetaImage = story.asset?.url || 'https://civicdinners-manual-storage.s3.amazonaws.com/civicdinners_newsocialimage.png'
  return (
    <div className={classes.root}>
      <MetaTags>
        <title>{metaTitle}</title>
        <meta name='title' content={metaTitle} />
        <meta name='description' content={metaDescription} />
        <meta property='og:title' content={metaTitle} />
        <meta property='og:image' content={hasMetaImage} />
        <meta property='og:description' content={metaDescription} />
        <meta property='og:url' content={story.storyLink} />
        <meta name='author' content={story?.authorName}></meta>
        <meta name='twitter:creator' content={story?.authorName} />
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:title' content={metaTitle} />
        <meta name='twitter:image' content={hasMetaImage} />
        <meta name='twitter:description' content={metaDescription} />
        <meta name='twitter:creator' content={story?.authorName} />
      </MetaTags>
      <StoryContext.Provider value={story}>
        <Grid container direction='column' spacing={1}>
          <Grid item xs={12}>
            <MediaFilePreview file={story.asset} className={classes.preview} />
          </Grid>
          <Grid item xs={12}>
            <StoryAuthor />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='subtitle1'>{story.headline}</Typography>
            <Typography variant='body2' className={classes.spacer}>
              {story.content}
            </Typography>
          </Grid>
        </Grid>
      </StoryContext.Provider>
    </div>
  )
}

const Styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '1em auto',
      padding: '1em',
      maxWidth: '25em'
    },
    subHeaderContainer: {
      textAlign: 'center',
      margin: '0 auto',
      backgroundColor: '#F6F7F9',
      minHeight: '10em',
      padding: '1em 0'
    },
    successHeader: {
      textAlign: 'center',
      margin: '.4em auto',
      maxWidth: '18em'
    },

    shareStory: {
      maxWidth: '15em',
      margin: '0 auto'
    },

    icon: {
      margin: '0 auto 1em auto',
      maxWidth: '3em !important'
    },
    spacer: {
      marginTop: '1em'
    },
    shareIcon: {
      fontSize: '1em'
    },
    preview: {
      maxHeight: '184px'
    }
  })
)

export default flow(observer)(StoryShow)
