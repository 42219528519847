import React, { Component } from 'react'
import { flow, isEmpty, isUndefined, isNull } from 'lodash'
import InfiniteScroll from 'react-infinite-scroller'
import TopicCard from '../topics/Card'
import ConversationCard from '../conversations/Card'
import { connectTopicTheme } from '../../connectTheme'
import CardLoadingAnimation from './CardLoadingAnimation'
import withTranslation from '../hocs/withTranslation'
import { observer } from 'mobx-react'

//  TODO: fix the FIXME and cleanup
export class InfiniteLocationList extends Component<FIXME, FIXME> {
  componentDidMount() {
    if (!this.props.itemsLoading && this.props.hasMoreItems) {
      this.handleScroll(1)
    }
  }

  initCoords = () => {
    const { locationSlug } = this.props
    if (isNull(locationSlug)) return null

    switch (locationSlug) {
      case 'atlanta':
        return [33.7489954, -84.3879824]
      default:
        return null
    }
  }

  handleScroll = (page: number) => {
    const { coordinates, slug, pages } = this.props
    const { limit = 10 } = this.props
    const offset = limit * (page - 1)
    const properLocation = coordinates || this.initCoords() || ''
    const venueType = isUndefined(this.props.venueType) ? null : this.props.venueType

    if (pages && pages < page) return

    if (page === 1) {
      this.props.initQuery({
        limit,
        offset,
        coordinates: properLocation,
        slug,
        venueType
      })
    } else {
      this.props.query({
        limit,
        offset,
        coordinates: properLocation,
        slug,
        venueType
      })
    }
  }

  render() {
    const { items, itemsLoading, itemType, header, transparentHeader, sectionClass, slug, t, trailingItem, pageStart = 0, hideOnEmpty, featured } = this.props
    const list = items

    if (items === null) return null

    const Filters = this.props.filters

    const headerClass = transparentHeader ? 'transparent-header-section' : 'header-section-grey'

    const Item = (props) => {
      const { item } = props
      if (itemType === 'conversation') {
        return <ConversationCard slug={slug} {...item} conversation={item} key={item.id} featured={featured} />
      } else if (itemType === 'topic') {
        return <TopicCard topic={item} key={item.id} featured={featured} />
      }
      return null
    }

    const List = () => {
      if (isEmpty(list) && !itemsLoading && !trailingItem) {
        return window.coco || itemType === 'topic' ? (
          <h2 className='align-center'>{t('common.noItemsTopics')}</h2>
        ) : (
          <h2 className='align-center'>{t('common.noItemsConversations')}</h2>
        )
      }
      return (
        <div className='row'>
          {list.map((item: any) => (
            <Item key={item.id} item={item} />
          ))}
          {trailingItem && trailingItem()}
        </div>
      )
    }

    const headerSection = () => (
      <section className={`pane thin-pane ${headerClass}`}>
        <div className='row center-xs'>
          <h2 className='header-32 header-24-sm flat-bottom'>{header}</h2>
        </div>
        {Filters && <Filters updateForm={this.props.updateForm} />}
      </section>
    )

    if (hideOnEmpty && list.length === 0) {
      return <></>
    }

    return (
      <div>
        {header && headerSection()}

        <section className={`pane reg-pane clear-pane ${sectionClass}`}>
          <InfiniteScroll loadMore={this.handleScroll} hasMore={this.props.hasMoreItems && !itemsLoading} threshold={2000} pageStart={pageStart}>
            <List />
            {itemsLoading ? (
              <div key={Math.random()}>
                <CardLoadingAnimation />
              </div>
            ) : null}
          </InfiniteScroll>
        </section>
      </div>
    )
  }
}

export default flow(observer, connectTopicTheme, withTranslation)(InfiniteLocationList)
