import { types, Instance } from 'mobx-state-tree'
import { LoadableStatus } from './helpers/LoadableStatus'
import AnalyticsApi from '../../api/AnalyticsApi'
import { GraphData, LineGraphData } from './GraphData'
import { AnalyticsTotalStats } from './AnalyticsTotalStats'
import { AnalyticsInlineStats } from './AnalyticsInlineStats'
import moment from 'moment'
import { RootAccessable } from './helpers/RootAccessable'

export const BaseAnalyticsData = types
  .compose(
    LoadableStatus,
    RootAccessable,
    types.model({
      graphData: types.array(GraphData),
      totalStats: types.maybe(AnalyticsTotalStats),
      inlineStats: types.array(AnalyticsInlineStats),
      scopedBy: types.maybe(types.string),
      startsAt: types.maybe(types.string),
      endsAt: types.maybe(types.string)
    })
  )
  .actions((self) => ({
    setScopedBy: (val: string) => {
      self.scopedBy = val
    },
    setStartsAt: (val: string) => {
      self.startsAt = val
    },
    setEndsAt: (val: string) => {
      self.endsAt = val
    },
    setAnalyticsData: ({ graph_data, total_stats, inline_stats }: any) => {
      self.graphData.replace(graph_data)
      self.totalStats = total_stats
      self.inlineStats = inline_stats
    },
    getDataName: () => {
      return 'data_name'
    }
  }))
  .actions((self) => ({
    callApi: (args) => {
      return Promise.reject()
    }
  }))
  .actions((self) => ({
    loadAnalyticsData: (args = {}, { reload = false } = {}) => {
      const data_name = self.getDataName()

      if (self.isLoading(data_name)) return
      if (!reload && self.loadedIds.includes(data_name)) return

      self.graphData.replace([])

      self.startLoading(data_name)

      return self.callApi(args).then(({ response: { ok }, json }) => {
        self.stopLoading(data_name)
        if (ok) {
          self.setAnalyticsData(json)
        }
        self.stopLoading(data_name)
      })
    }
  }))

export const AttendeesBar = types.compose(LoadableStatus, BaseAnalyticsData, types.model({})).actions((self) => ({
  getDataName: () => {
    return 'AttendeesBar'
  },
  callApi: (partner_ids) => {
    const { selectedPartnerId } = self.getPartners()
    return AnalyticsApi.getAttendeesBarData(partner_ids, { scopedBy: self.scopedBy, ...(selectedPartnerId ? { partner_id: selectedPartnerId } : {}) })
  },
  afterCreate: () => {
    self.setScopedBy('conversation')
  }
}))

export const ConversationsBar = types.compose(LoadableStatus, BaseAnalyticsData, types.model({})).actions((self) => ({
  getDataName: () => {
    return 'ConversationsBar'
  },
  callApi: (partner_ids) => {
    const { selectedPartnerId } = self.getPartners()
    return AnalyticsApi.getConversationsBarData(partner_ids, { ...(selectedPartnerId ? { partner_id: selectedPartnerId } : {}) })
  }
}))

export const ConversationsLine = types
  .compose(
    LoadableStatus,
    BaseAnalyticsData,
    types.model({
      scopedBy: types.optional(types.string, 'all')
    })
  )
  .actions((self) => ({
    getDataName: () => {
      return 'ConversationsLine'
    },
    callApi: (partner_ids) => {
      const { selectedPartnerId } = self.getPartners()
      const partnerParams = partner_ids && partner_ids.length ? { partner_ids } : selectedPartnerId ? { partner_id: selectedPartnerId } : {}
      const dateRangeParams = self.scopedBy === 'date_range' ? { startsAt: self.startsAt, endsAt: self.endsAt } : {}
      return AnalyticsApi.getConversationsLineData({ ...partnerParams, ...dateRangeParams, scopedBy: self.scopedBy })
    }
  }))

export const AttendeesLine = types
  .compose(
    LoadableStatus,
    BaseAnalyticsData,
    types.model({
      graphData: types.array(LineGraphData),
      scopedBy: types.optional(types.string, 'all')
    })
  )
  .actions((self) => ({
    getDataName: () => {
      return 'AttendeesLine'
    },
    callApi: (partner_ids) => {
      const { selectedPartnerId } = self.getPartners()
      const partnerParams = partner_ids && partner_ids.length ? { partner_ids } : selectedPartnerId ? { partner_id: selectedPartnerId } : {}
      const dateRangeParams = self.scopedBy === 'date_range' ? { startsAt: self.startsAt, endsAt: self.endsAt } : {}
      return AnalyticsApi.getAttendeesLineData({ ...partnerParams, ...dateRangeParams, scopedBy: self.scopedBy })
    }
  }))

export interface IBaseAnalyticsData extends Instance<typeof BaseAnalyticsData> {}
