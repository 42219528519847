import * as types from './actionTypes'
import mst from '../store/models'

export const showDialog = (name, data) => {
  mst.dialogs.showDialog(name, data)
  return {
    type: types.SHOW_DIALOG,
    dialog: name,
    data: data
  }
}

export const closeDialog = (name) => {
  mst.dialogs.closeDialog(name)
  return {
    type: types.CLOSE_DIALOG,
    dialog: name
  }
}
