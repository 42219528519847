import React, { Component } from 'react'

import { inject, observer } from 'mobx-react'
import { IStore, IStory, Story } from '../../../../store/models'
import { withRouter } from 'react-router'
import { flow } from 'lodash'
import DataTable, { IColData, ValueLabel } from '../../../shared/DataTableFilterable'
import { Grid, IconButton, Tooltip, Link as MuiLink } from '@material-ui/core'
import { truncate } from 'lodash'

import moment from 'moment'

const StoryActions = observer(({ row }: { row: IStory }) => {
  return (
    <>
      <Tooltip title='View Story'>
        <IconButton size='small' onClick={() => row.show()}>
          <i className='fas fa-search opacity-8'></i>
        </IconButton>
      </Tooltip>
      <Tooltip title={row.visible ? 'Hide' : 'Show'}>
        <IconButton size='small' onClick={() => row.updateVisibility(!row.visible)}>
          {row.visible ? <i className='fas fa-eye-slash opacity-8' /> : <i className='fas fa-eye opacity-8' />}
        </IconButton>
      </Tooltip>
    </>
  )
})
const ShowVisivility = observer(({ row }: { row: IStory }) => <ValueLabel value={row.visible ? 'Visible' : 'Hidden'} />)
class StoriesTable extends Component<any, any> {
  render() {
    const { items, loading, filterable } = this.props

    const columnData: IColData[] = [
      { name: 'id', title: 'ID', width: 80, sortable: true },
      { name: 'title', title: 'Conversation Topic', width: 250, sortable: true },
      { name: 'startsAt', title: 'Date of Conversation', width: 150, sortable: true },
      { name: 'asset', title: 'Video or Image Link', width: 300 },
      { name: 'prompt', title: 'Prompt Text', width: 250, sortable: true },
      { name: 'visible', title: 'Visibility', width: 100, sortable: true },
      { name: 'headline', title: 'Headline', width: 250, sortable: true },
      { name: 'content', title: 'Response', width: 350, sortable: true },
      { name: 'firstName', title: 'First Name', sortable: true },
      { name: 'lastName', title: 'Last Name', sortable: true },
      { name: 'createdAt', title: 'Date of Submission', width: 150, sortable: true },
      { name: 'actions', title: 'Actions', align: 'center' }
    ]

    return (
      <DataTable
        height={'calc(100vh - 16em)'}
        data={items}
        columns={columnData}
        loading={loading}
        filterable={filterable}
        rightColumns={['actions']}
        hideSearch={true}
        getCellValue={(row: IStory, columnName: string) => {
          switch (columnName) {
            case 'title':
              return (
                <ValueLabel
                  value={
                    <MuiLink rel='noopener' title={row.title} href={row.topicLink} target='blank' color='primary'>
                      {row.title}
                    </MuiLink>
                  }
                />
              )
            case 'actions':
              return (
                <Grid container direction='row' justify='space-around' alignItems='center'>
                  <StoryActions row={row} />
                </Grid>
              )
            case 'startsAt':
              return <ValueLabel value={row.startsAt && moment(row.startsAt).format('L')} />
            case 'createdAt':
              return <ValueLabel value={row.createdAt && moment(row.createdAt).format('L')} />
            case 'visible':
              return <ShowVisivility row={row} />
            case 'prompt':
              return <ValueLabel value={truncate(row.prompt?.toString(), { length: 30, omission: '...' })} />
            case 'asset':
              return (
                <a href={`${row.asset?.url}`} target='blank'>
                  <ValueLabel value={truncate(row.asset?.url?.toString(), { length: 45, omission: '...' })} />
                </a>
              )
            case 'headline':
              return <ValueLabel value={truncate(row.headline, { length: 35, omission: '...' })} />
            case 'content':
              return <ValueLabel value={truncate(row.content, { length: 45, omission: '...' })} />
            default:
              return <ValueLabel value={row[columnName]} />
          }
        }}
      />
    )
  }
}

export default flow(
  observer,
  inject<FIXME, FIXME>(
    ({
      mst: {
        auth: { user },
        stories
      }
    }: {
      mst: IStore
    }) => {
      return {
        user,
        stories,
        filterable: stories,
        items: stories.list,
        loading: stories.loadingIds.includes('stories_table')
      }
    }
  ),
  withRouter
)(StoriesTable)
