import * as types from './actionTypes'
import { isNull, isUndefined, isEmpty } from 'lodash'
import publicIP from 'public-ip'
import UsersApi from '../api/UsersApi'

export const Loading = () => ({
  type: types.USER_COORDS_LOADING
})

export const loadUserCoordsSuccess = json => ({
  type: types.LOAD_USER_COORDS_SUCCESS,
  ...json
})

export const loadUserCoordsFailed = json => ({
  type: types.LOAD_USER_COORDS_FAILED,
  data: json
})

export function getLocationFromIP() {
  return function(dispatch) {
    dispatch(Loading())

    publicIP.v4().then(ip => {
      if (isNull(ip) || isUndefined(ip) || isEmpty(ip)) return dispatch(loadUserCoordsFailed())

      return UsersApi.getLocationFromIP(ip)
        .then(res => {
          const { ok } = res.response

          if (ok) {
            const { latitude, longitude } = res.json
            let userCoords = ''
            if ((!isNull(latitude) && !isUndefined(latitude)) || (!isNull(longitude) && !isUndefined(longitude))) {
              userCoords = JSON.stringify([latitude, longitude])
            }
            dispatch(loadUserCoordsSuccess({ userCoords }))
          } else {
            dispatch(loadUserCoordsSuccess())
          }
        })
        .catch(error => {
          throw error
        })
    })
  }
}
