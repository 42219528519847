import { Breadcrumbs, Typography } from '@material-ui/core'
import React from 'react'
import NewPartnerPage from '../../../partners/New'
import SummaryContainer from '../partials/SummaryContainer'
import { IStore } from '../../../../store/models'
import { inject } from 'mobx-react'
import { observer } from 'mobx-react'
import { flow } from 'lodash'
import { IUser } from '../../../../store/models/User'
import AdminMetaTags from '../../../shared/AdminMetaTags'
import { Link } from 'react-router-dom'

interface Props {
  user: IUser
}

const New = ({ user }: Props) => {
  const partnerType = 'Customer'
  return (
    <div>
      <AdminMetaTags title='Customers' pageType='New' />

      <Breadcrumbs aria-label='breadcrumb'>
        <Link color='primary' to='/admin'>
          Dashboard
        </Link>
        <Typography color='textPrimary'>New Customer</Typography>
      </Breadcrumbs>
      <SummaryContainer title={'New Customer'}>
        <NewPartnerPage />
      </SummaryContainer>
    </div>
  )
}

// export default New
export default flow(
  observer,
  inject<FIXME, FIXME>(
    ({
      mst: {
        auth: { user }
      }
    }: {
      mst: IStore
    }) => {
      return {
        user
      }
    }
  )
)(New)
