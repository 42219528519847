import { SubmissionError } from 'redux-form'
import { normalize } from 'normalizr'
import i18n from 'i18next'

import {
  LOADING_DINNER,
  LOAD_DINNER_SUCCESS,
  LOAD_DINNER_FAILED,
  DELETE_DINNER_SUCCESS,
  LOADING_DINNERS,
  LOAD_DINNERS_SUCCESS,
  LOAD_NEW_DINNERS_SUCCESS,
  LOAD_DINNERS_FAILED,
  CONVERSATION_DINNERS_LOADING,
  LOAD_CONVERSATION_DINNERS_FAILED,
  LOAD_NEW_CONVERSATION_DINNERS_SUCCESS,
  LOAD_CONVERSATION_DINNERS_SUCCESS,
  PARTNER_DINNERS_LOADING,
  LOAD_PARTNER_DINNERS_FAILED,
  LOAD_PARTNER_DINNERS_SUCCESS,
  LOAD_NEW_PARTNER_DINNERS_SUCCESS
} from './actionTypes'
import conversationsApi from '../api/ConversationsApi'
import topicConversationsApi from '../api/topics/ConversationsApi'
import partnerConversationsApi from '../api/partners/ConversationsApi'
import { showAlertMessage } from './alertMessageActions'
import { history } from '../components/browserHistory.js'
import { conversationSchema, conversationsSchema } from '../normalizers/conversationNormalizers'
import { showDialog, closeDialog } from './dialogActions.js'
import { snakify } from '../decorators/textTools'

export const loadingConversations = () => ({
  type: LOADING_DINNERS
})

export const loadConversationsSuccess = (json) => {
  const flatData = normalize(json, conversationsSchema).entities

  return {
    type: LOAD_DINNERS_SUCCESS,
    ...flatData
  }
}

export const loadNewConversationsSuccess = (json) => {
  const flatData = normalize(json, conversationsSchema).entities

  return {
    type: LOAD_NEW_DINNERS_SUCCESS,
    ...flatData
  }
}

export const loadConversationsFailed = (json) => ({
  type: LOAD_DINNERS_FAILED,
  data: json
})

export const loadingConversation = () => ({
  type: LOADING_DINNER
})

export const loadConversationSuccess = (json) => {
  const flatData = normalize(json, conversationSchema).entities

  return {
    type: LOAD_DINNER_SUCCESS,
    ...flatData
  }
}

export const loadConversationFailed = (json) => ({
  type: LOAD_DINNER_FAILED,
  data: json
})

export const deleteConversationSuccess = (id) => ({
  type: DELETE_DINNER_SUCCESS,
  data: id
})

function showAlert(dispatch, message) {
  dispatch(showAlertMessage({ message }))
}

export const topicConversationsLoading = () => ({
  type: CONVERSATION_DINNERS_LOADING
})

export const loadTopicConversationsFailed = (json) => ({
  type: LOAD_CONVERSATION_DINNERS_FAILED,
  data: json
})

export const loadNewTopicConversationsSuccess = (json) => ({
  type: LOAD_NEW_CONVERSATION_DINNERS_SUCCESS,
  ...json
})

export const loadTopicConversationsSuccess = (json) => ({
  type: LOAD_CONVERSATION_DINNERS_SUCCESS,
  ...json
})

export const partnerConversationsLoading = () => ({
  type: PARTNER_DINNERS_LOADING
})

export const loadPartnerConversationsFailed = (json) => ({
  type: LOAD_PARTNER_DINNERS_FAILED,
  data: json
})

export const loadPartnerConversationsSuccess = (json) => {
  return {
    type: LOAD_PARTNER_DINNERS_SUCCESS,
    ...json
  }
}

export const loadNewPartnerConversationsSuccess = (json) => {
  return {
    type: LOAD_NEW_PARTNER_DINNERS_SUCCESS,
    ...json
  }
}

export function loadConversations({ limit, offset, coordinates, venueType }) {
  return function (dispatch) {
    dispatch(loadingConversations())

    return conversationsApi
      .getAll({ limit, offset, coordinates, venueType })
      .then((res) => {
        const { ok, status, statusText } = res.response
        if (ok) {
          dispatch(loadConversationsSuccess(res.json))
        } else {
          dispatch(loadConversationsFailed({ status, statusText }))
        }
      })
      .catch((error) => {
        throw error
      })
  }
}

export function loadNewConversations({ limit, offset, coordinates, venueType }) {
  return function (dispatch) {
    dispatch(loadingConversations())

    return conversationsApi
      .getAll({ limit, offset, coordinates, venueType })
      .then((res) => {
        const { ok, status, statusText } = res.response
        if (ok) {
          dispatch(loadNewConversationsSuccess(res.json))
        } else {
          dispatch(loadConversationsFailed({ status, statusText }))
        }
      })
      .catch((error) => {
        throw error
      })
  }
}

export function loadConversation(id) {
  return function (dispatch) {
    dispatch(loadingConversation())

    return conversationsApi
      .get(id)
      .then((res) => {
        const { ok, status, statusText } = res.response
        if (ok) {
          dispatch(loadConversationSuccess(res.json))
        } else {
          dispatch(loadConversationsFailed({ status, statusText }))
        }
      })
      .catch((error) => {
        throw error
      })
  }
}

export function createConversation(formData) {
  return function (dispatch) {
    return conversationsApi
      .create(formData)
      .then((res) => {
        if (res.response.ok) {
          const { id, slug } = res.json
          //  TODO: format conversation to make this work
          //  but find a better way to do this dance dance
          const flatData = normalize([res.json], conversationsSchema).entities
          const conversation = flatData.conversations[id]
          // console.log({ id, slug, conversation, flatData, json: res.json })
          dispatch(loadConversationSuccess(res.json))

          history.push(`/${slug}/conversations/${id}`)

          exposeConversationCreatePixel()

          dispatch(showDialog('createdConversation', { step: 1, conversation }))
        } else {
          throw new SubmissionError(res.json)
        }
      })
      .catch((error) => {
        throw error
      })
  }
}

export function updateConversation(formData) {
  formData = snakify(formData)

  return function (dispatch) {
    return conversationsApi
      .update(formData)
      .then((response) => {
        if (response.response.ok) {
          dispatch(closeDialog('updateConversation'))

          // history.push(`/${formData.slug}/conversations/${formData.id}`)

          showAlert(dispatch, i18n.t('conversation.updated'))
        } else {
          throw new SubmissionError(response.json)
        }
      })
      .catch((error) => {
        throw error
      })
  }
}

export function deleteConversation(conversation) {
  return function (dispatch) {
    return conversationsApi
      .destroy(conversation.id, conversation.destroyMessage)
      .then(({ response }) => {
        if (response.ok) {
          dispatch(deleteConversationSuccess(conversation.id))

          dispatch(closeDialog('deleteConversation'))

          showAlert(dispatch, i18n.t('conversation.deleted'))
          history.push(`/${conversation.slug}`)
        } else {
          showAlert(dispatch, response.statusText)
        }
      })
      .catch((error) => {
        throw error
      })
  }
}

export function loadNewTopicConversations({ limit, offset, coordinates, slug, venueType }) {
  return function (dispatch) {
    dispatch(topicConversationsLoading())

    return topicConversationsApi
      .getAll({ limit, offset, coordinates, slug, venueType })
      .then((res) => {
        const { ok, status, statusText } = res.response

        if (ok) {
          const flatData = normalize(res.json, conversationsSchema).entities
          dispatch(loadNewTopicConversationsSuccess(flatData))
        } else {
          dispatch(loadTopicConversationsFailed({ status, statusText }))
        }
      })
      .catch((error) => {
        throw error
      })
  }
}

export function loadTopicConversations({ limit, offset, coordinates, slug, venueType }) {
  return function (dispatch) {
    dispatch(topicConversationsLoading())

    return topicConversationsApi
      .getAll({ limit, offset, coordinates, slug, venueType })
      .then((res) => {
        const { ok, status, statusText } = res.response

        if (ok) {
          const flatData = normalize(res.json, conversationsSchema).entities
          dispatch(loadTopicConversationsSuccess(flatData))
        } else {
          dispatch(loadTopicConversationsFailed({ status, statusText }))
        }
      })
      .catch((error) => {
        throw error
      })
  }
}

function exposeConversationCreatePixel() {
  if (process.env.NODE_ENV !== 'production') return
  window.fbq('trackSingleCustom', '1904101133142530', 'ConversationCreate')
}

export function loadPartnerConversations({ limit, offset, coordinates, slug, venueType }) {
  return function (dispatch) {
    dispatch(partnerConversationsLoading())

    return partnerConversationsApi
      .getAll({ limit, offset, coordinates, slug, venueType })
      .then((res) => {
        const { ok, status, statusText } = res.response

        if (ok) {
          const flatData = normalize(res.json, conversationsSchema).entities
          dispatch(loadPartnerConversationsSuccess(flatData))
        } else {
          dispatch(loadPartnerConversationsFailed({ status, statusText }))
        }
      })
      .catch((error) => {
        throw error
      })
  }
}

export function loadNewPartnerConversations({ limit, offset, coordinates, slug, venueType }) {
  return function (dispatch) {
    dispatch(partnerConversationsLoading())

    return partnerConversationsApi
      .getAll({ limit, offset, coordinates, slug, venueType })
      .then((res) => {
        const { ok, status, statusText } = res.response
        if (ok) {
          const flatData = normalize(res.json, conversationsSchema).entities
          dispatch(loadNewPartnerConversationsSuccess(flatData))
        } else {
          dispatch(loadPartnerConversationsFailed({ status, statusText }))
        }
      })
      .catch((error) => {
        throw error
      })
  }
}
