import React, { useEffect } from 'react'
import { flow, sortBy } from 'lodash'
import Card from '@material-ui/core/Card'

import ConversationNotificationsForm from './Form'
import { inject, observer } from 'mobx-react'
import { IConversation } from '../../store/models/Conversation'
import { Accordion, AccordionDetails, AccordionSummary, Chip, createStyles, makeStyles, Theme, Typography, CircularProgress } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import palette from '../../themes/palette'
import { IStore } from '../../store'

interface Props {
  conversation: IConversation
}

const ConversationNotificationsEdit = ({ conversation }: Props) => {
  useEffect(() => {
    if (!conversation.loadingIds.includes('notifications') && !conversation.loadedIds.includes('notifications')) {
      conversation.loadConversationNotifications()
    }
  }, [conversation])

  const sorting = [
    'pre_premium_event',
    'during_conversation_guest',
    'during_conversation_host',
    'post_conversation_1_guest',
    'post_conversation_1_host',
    'post_conversation_2_guest',
    'post_conversation_2_host',
    'post_premium_event'
  ]

  const sorted = (notifications) => {
    return notifications.slice().sort((a, b) => sorting.indexOf(a.name) - sorting.indexOf(b.name))
  }

  const notificationPreNames = ['pre_premium_event']
  const notificationDuringNames = ['during_conversation_guest', 'during_conversation_host']
  const notificationPostNames = ['post_conversation_1_guest', 'post_conversation_1_host', 'post_conversation_2_guest', 'post_conversation_2_host']

  const notificationPreSorter = (a) => notificationPreNames.indexOf(a.name)
  const notificationDuringSorter = (a) => notificationDuringNames.indexOf(a.name)
  const notificationPostSorter = (a) => notificationPostNames.indexOf(a.name)

  const preFilter = (n) => notificationPreNames.includes(n.name)
  const duringFilter = (n) => notificationDuringNames.includes(n.name)
  const postFilter = (n) => notificationPostNames.includes(n.name)

  const notifications_pre = sortBy(conversation.notifications?.filter(preFilter), notificationPreSorter)
  const notifications_during = sortBy(conversation.notifications?.filter(duringFilter), notificationDuringSorter)
  const notifications_post = sortBy(conversation.notifications?.filter(postFilter), notificationPostSorter)

  return (
    <div>
      <NotificationGroup group='Pre Conversation' conversation={conversation} notifications={notifications_pre} />
      <NotificationGroup group='During Conversation' conversation={conversation} notifications={notifications_during} />
      <NotificationGroup group='Post Conversation' conversation={conversation} notifications={notifications_post} />
    </div>
  )
}

const NotificationGroup = observer(({ conversation, notifications, group }) => {
  const classes = useStyles()
  const status = `${notifications.filter((n) => n.active).length}/${notifications.length} Active`
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div className={classes.accordionSummary}>
          <Typography>
            <strong>{group}</strong>
          </Typography>
          {conversation.loadingIds.includes('notifications') ? <CircularProgress size='small' /> : <Chip label={status} size='small' className={classes.chip} />}
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetail}>
        {notifications &&
          notifications.map((notification) => (
            <div key={`notification-${notification.id}`}>
              <div className='thin-bottom'>
                <span className='header-20 bold' style={{ color: palette.darkestgrey }}>
                  {notification.label}
                </span>
                {notification.active && (
                  <span className='fat-left'>
                    <Chip label='Active' size='small' className={classes.activeChip} />
                  </span>
                )}
              </div>
              <div className='header-16 opacity-6 italic reg-bottom'>{notification.description}</div>
              <ConversationNotificationsForm notification={notification} conversation={conversation} form={`NotificationForm-id${notification.id}`} />
            </div>
          ))}
      </AccordionDetails>
    </Accordion>
  )
})
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular
    },
    chip: { borderRadius: 11 },
    activeChip: { color: palette.darkgrey, backgroundColor: 'rgba(0, 126, 89, 0.2)', borderRadius: 11 },
    accordionDetail: { display: 'block', backgroundColor: '#f9f9f9', padding: '1em' },
    accordionSummary: { display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }
  })
)

export default flow(observer)(ConversationNotificationsEdit)
