import { includes } from 'lodash'
import Churnzero from './analytics/churnzero'

let inclusivvDomains = ['www.inclusivv.co', 'inclusivv.co', 'app.inclusivv.co', 'localhost:3000', 'demo.inclusivv.co', 'release.inclusivv.co']
let cocoDomains = ['www.fbconversations.com', 'fbconversations.com', 'localhost:3006']
let devDomains = ['localhost:3000', 'localhost:3006']
let demoDomains = ['demo.inclusivv.co']
let releaseDomains = ['release.inclusivv.co']

const formattedDomain = () => {
  let host = window.location.host
  if (includes(devDomains, host)) return host
  if (includes(demoDomains, host)) return host.replace('demo.', '')
  if (includes(releaseDomains, host)) return host.replace('release.', '')
  return includes(host, 'app') ? host : 'app.' + host
}

const envFriendlyFormattedDomain = () => {
  let host = window.location.host
  if (includes(devDomains, host)) return host
  if (includes(demoDomains, host)) return host
  if (includes(releaseDomains, host)) return host
  return includes(host, 'app') ? host : 'app.' + host
}

console.log('NODE_ENV:', process.env.NODE_ENV)

global.domain = global.domain || formattedDomain()

global.envFriendlyDomain = global.envFriendlyDomain || envFriendlyFormattedDomain()

console.log('domain: ', global.domain)

global.coco = includes(cocoDomains, global.domain) || process.env.REACT_APP_COCO === 'true'
global.inclusivv = includes(inclusivvDomains, global.domain) || !global.coco

const siteTitle = () => {
  if (global.coco) return 'community topic'
  if (global.inclusivv) return 'inclusivv'
  return 'inclusivv'
}

const siteName = () => {
  if (global.coco) return 'community_conversations'
  if (global.inclusivv) return 'inclusivv'
  return 'inclusivv'
}

;(function () {
  if (global.coco) {
    var link = document.querySelector("link[rel*='icon']") || document.createElement('link')
    link.type = 'image/x-icon'
    link.rel = 'shortcut icon'
    link.href = 'https://civicdinners-manual-storage.s3.amazonaws.com/fb_favicon.png'
    document.getElementsByTagName('head')[0].appendChild(link)
  }
})()

global.siteTitle = siteTitle()
global.siteName = siteName()

document.body.classList.add(global.siteName)

// Fullstory Start only in production
if (!localStorage.jwt && ![...devDomains].includes(global.domain)) {
  if (process.env.NODE_ENV === 'production') {
    window.setupFullstory && window.setupFullstory()
  }
}
window.fsIdentify = (id, data) => {
  window.FS && window.FS.identify(id, data)
}

window.captchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY || '6LdA6SEUAAAAAL-Q_dVHOVexbDRuhF2vcAc5blYj'

window.allowInPerson = process.env.REACT_APP_IN_PERSON === 'false' ? false : true
window.debugMode = process.env.REACT_APP_DEBUG_MODE === 'true'

const getChurnzeroKey = () => process.env.REACT_APP_CHURNZERO_APP_KEY || '1!69rhwS9xChzDGCx3FI22in-LmLAIylBkZ4Q-LMnb8KAtB80'
const getChurnzero = () => new Churnzero({ apiKey: getChurnzeroKey() })
window.REACT_APP_ATTENDEE_EVENT_BASEURL = process.env.REACT_APP_ATTENDEE_EVENT_BASEURL || 'https://events.inclusivv.co'
window.OKTA_LOGIN_URL = process.env.REACT_APP_OKTA_LOGIN_URL || 'https://dev-6653144.okta.com/app/dev-6653144_inclusivvappdev_1/exk4ijpved6olWwRz5d7/sso/saml'
// window.CHURNZERO_APP_KEY = getChurnzeroKey()
// new Churnzero({ apiKey: getChurnzeroKey() })
window.churnZero = getChurnzero()
window.MEMBER_ENABLED_PARTNERS = [307, 313, 328]
