import React, { useContext } from 'react'
import DialogWrapper from '../../DialogWrapper'
import { flow } from 'lodash'
import withTranslation, { WithTranslation } from '../../hocs/withTranslation'
import { withRouter } from 'react-router'
import { IDialog } from '../../../store/models/Dialog'
import { AddressAutocompleteControl, CheckboxControl, ChipGroupControl, TextFieldControl } from '../../utils'
import { required } from '../../validations/fieldLevelValidations'
import { useForm } from 'react-hook-form'
import { Button, createStyles, FormHelperText, InputLabel, makeStyles } from '@material-ui/core'
import { AuthContext } from '../../Providers/AuthProvider'
import LoadingAnimation from '../../shared/LoadingAnimation'
import { camelify } from '../../../decorators/textTools'

interface Props {
  dialog: IDialog
}
const INTEREST_NAMES = [
  'Advocacy',
  'BIPOC',
  'Business',
  'Cities',
  'Common Ground',
  'Connection',
  'Culture',
  'Diversity & Inclusion',
  'Entrepreneurship',
  'Ethics',
  'Faith',
  'Government',
  'Health & Wellness',
  'Human Rights',
  'Leadership',
  'Learning',
  'LGBTQIA+',
  'Money',
  'Parenting',
  'Philanthropy',
  'Policy',
  'Sustainability',
  'Tech',
  'Women',
  'Youth'
]

export const INTERESTS = INTEREST_NAMES.map((n) => ({
  label: n,
  value: n
}))

const Signup2Dialog = ({ dialog, t }: Props & WithTranslation) => {
  const { handleSubmit, errors, formState, control, setValue } = useForm({ mode: 'onBlur', shouldUnregister: false })
  const { isDirty, isSubmitting } = formState
  const { user } = useContext(AuthContext)

  if (!user) {
    return <LoadingAnimation />
  }

  const handleSave = handleSubmit(({ id, location, ...data }: any, e) => {
    user.updateProfile({ ...camelify(location), ...data }).then(() => dialog.closeDialog())
  })

  const classes = Styles()

  return (
    <DialogWrapper dialog={dialog} name='signup2'>
      <div className={classes.root}>
        <h3 className={classes.header}>{t('auth.signupCompleteAccountHeader')}</h3>
        <div className={classes.subheader}>
          <p>{t('auth.signupCompleteAccountSubHeader')}</p>
        </div>

        <form onSubmit={handleSave}>
          <TextFieldControl label={'city'} name='city' value={user.city} control={control} className='hide' />
          <TextFieldControl label={'stateLevel'} name='stateLevel' value={user.stateLevel} control={control} className='hide' />
          <div className='fat-bottom'>
            <AddressAutocompleteControl
              types={['(regions)']}
              name='location'
              control={control}
              label={t('auth.signupWhatLocation')}
              helperText={t('auth.signupWhatLocationHelper')}
              defaultValue={user.location || ''}
              className='reg-bottom'
              onChange={({ city = '', state_level = '' }: any = {}) => {
                setValue('city', city)
                setValue('stateLevel', state_level)
              }}
              error={errors && errors.location}
              required
              rules={{ validate: (val) => required(val) && t('auth.addressError') }}
            />
            {errors.location && (
              <FormHelperText error>
                <p
                  className={classes.addressHelper}
                  dangerouslySetInnerHTML={{
                    __html: t('auth.addressErrorHelper', {
                      url: window.coco ? 'mailto:support@fbconversations.com' : 'mailto:support@inclusivv.co'
                    })
                  }}
                />
              </FormHelperText>
            )}
          </div>
          <div className='fat-bottom'>
            <InputLabel shrink className={classes.label}>
              {t('auth.signupInterest')}
            </InputLabel>
            <ChipGroupControl
              name='interests'
              helperText={t('auth.signupSelectAllApply')}
              control={control}
              defaultValue={Array.from(user.interests || [])}
              error={errors && errors.interests}
              options={INTERESTS}
            />
          </div>
          {/* <div className='fat-bottom'>
            <InputLabel shrink className={classes.label}>
              {t('auth.signupInterestedIn')}
            </InputLabel>
            <FormHelperText>{t('auth.signupSelectAllApply')}</FormHelperText>
            <div>
              <CheckboxControl
                name='conversationRoleInterest[attending]'
                label={t('auth.attending')}
                control={control}
                defaultValue={user?.conversationRoleInterest?.attending === 'true'}
                error={errors && errors?.conversationRoleInterest?.attending}
              />
            </div>
            <div>
              <CheckboxControl
                name='conversationRoleInterest[hosting]'
                label={t('auth.hosting')}
                control={control}
                defaultValue={user?.conversationRoleInterest?.hosting === 'true'}
                error={errors && errors?.conversationRoleInterest?.hosting}
              />
            </div>
          </div> */}
          <div className={classes.actionContainer}>
            <Button size='large' disabled={!isDirty || isSubmitting} type='submit' color='primary' variant='contained' className={classes.completeSetup}>
              {t('auth.completeSetup')}
            </Button>
          </div>
        </form>
      </div>
    </DialogWrapper>
  )
}

const Styles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: '2em',
      fontamily: 'Avenir'
    },
    header: {
      textAlign: 'center',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '32px'
    },
    subheader: {
      maxWidth: '17em',
      textAlign: 'center',
      margin: '0 auto'
    },
    actionContainer: {
      maxWidth: '304px',
      margin: '1em auto',
      textAlign: 'center'
    },
    completeSetup: {
      marginTop: '1em',
      width: '265px',
      height: '48px',
      fontStyle: 'normal',
      fontWeight: 800,
      fontSize: '18px',
      lineHeight: '26px'
    },
    label: {
      color: '#212122',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '1.5em',
      lineHeight: '24px'
    },
    addressHelper: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px'
    }
  })
)

export default flow(withTranslation, withRouter)(Signup2Dialog)
