import React, { ReactElement, useEffect } from 'react'
import { Card, createStyles, makeStyles } from '@material-ui/core'
import { useHistory, useLocation } from 'react-router'
import LoginForm from './LoginForm'
import LoginFormSso from './LoginFormSso'
import { useAuth } from '../../Providers'

interface Props {}

export default function LoginPage(props: Props): ReactElement {
  const history = useHistory()
  const location = useLocation()
  const classes = Styles()
  const { user } = useAuth()
  useEffect(() => {
    if (user && user.isCommunityAdmin()) {
      history.push('/admin')
    } else {
      user && history.push('/myconversations')
    }
  }, [user, history])

  return (
    <Card className={classes.root}>
      {location.pathname === '/sso/login' ? (
        <LoginFormSso
          onSignUp={() => {
            history.push('/join')
          }}
          sso={true}
        />
      ) : (
        <LoginForm
          onSignUp={() => {
            history.push('/join')
          }}
        />
      )}
    </Card>
  )
}
const Styles = makeStyles((theme) =>
  createStyles({
    root: {
      maxWidth: '600px',
      margin: '10em auto'
    }
  })
)
