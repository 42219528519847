import React, { useEffect, useState } from 'react'
import { Button, createStyles, Divider, Grid, makeStyles, Menu, MenuItem, Paper, Theme, Typography } from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import { IMembershipUser, IMembershipUserBase } from '../../../../store/models/User/MembershipUser'
import { IStore, ITrack } from '../../../../store'
import { get } from '../../../../api/utils'
import { useParams } from 'react-router'
import LoadingAnimation from '../../../shared/LoadingAnimation'
import SummaryStats from '../../../admin/v2/partials/SummaryStats'
import { flow } from 'lodash'

interface Props {}
interface InjectedProps {
  selectedPartnerId?: number
}

interface AnalyticsProps {
  average_recommendation?: string
  total_conversations_held?: string
  total_attendees?: string
  attendees_providing_feedback?: string
  felt_heared?: string
  felt_empathy?: string
  felt_deeper_understanding?: string
  felt_more_commited_to_take_action?: string
  key_idea_and_takeaways?: string[]
  feedback_on_overall_exp?: string[]
  conversation_list?: { id: number; name: string }[]
}

const FeedbackSummary = ({ selectedPartnerId }: Props & InjectedProps) => {
  const classes = useStyles()
  const [loaded, setLoaded] = useState(false)
  const [conversationId, setConversationId] = useState<number | undefined>(undefined)
  const [data, setData] = useState<AnalyticsProps>({ key_idea_and_takeaways: [], feedback_on_overall_exp: [] })
  const { action, membership_id } = useParams<{ action: string; membership_id?: string }>()
  useEffect(() => {
    get(`/admin/analytics/nps_data${selectedPartnerId ? `?partner_id=${selectedPartnerId}${conversationId ? `&conversation_id=${conversationId}` : ''}` : ''}`).then(
      ({ json, response: { ok } }) => {
        if (ok) {
          setData(json)
        }
        setLoaded(true)
      }
    )
  }, [selectedPartnerId, conversationId])

  if (!loaded) {
    return <LoadingAnimation />
  }
  return (
    <div style={{ margin: '1rem' }}>
      <div>
        <Grid container spacing={3}>
          <Grid item className={classes.grid}>
            <MembershipAnalytics1 data={data} onConversationSelect={selectedPartnerId ? (cId) => setConversationId(cId) : undefined} />
          </Grid>
          <Grid item xs={12} className={classes.grid}>
            <MembershipAnalytics2 data={data} />
          </Grid>
          <Grid item xs={12} className={classes.grid}>
            <Paper className={classes.section}>
              <Grid container>
                <Grid item xs={12} className={classes.grid} sm={6}>
                  <MembershipAnalytics3 data={data} />
                </Grid>
                <Grid item xs={12} className={classes.grid} sm={6}>
                  <MembershipAnalytics4 data={data} />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
// export default FeedbackSummary
export default flow(
  observer,
  inject<InjectedProps, Props>(
    ({
      mst: {
        partners: { selectedPartnerId }
      }
    }: {
      mst: IStore
    }) => {
      return {
        selectedPartnerId
      }
    }
  )
)(FeedbackSummary)

const MembershipAnalytics1 = ({ data, onConversationSelect }: Props & { data: AnalyticsProps; onConversationSelect?(cid: number) }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedConversationId, setselectedConversationId] = useState<number | undefined>(undefined)
  const open = Boolean(anchorEl)
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (convoId?: number) => (event) => {
    setAnchorEl(null)
    setselectedConversationId(convoId)
    onConversationSelect && convoId && onConversationSelect(convoId)
  }
  return (
    <Paper className={classes.section}>
      <Typography variant='h3' color='textSecondary' className='thin-bottom thin-top thin-left'>
        <strong>Feedback Summary</strong>
        {onConversationSelect && (
          <>
            <Button aria-controls='fade-menu' aria-haspopup='true' onClick={handleOpenMenu} color='primary'>
              <Typography variant='h6' component='p'>
                {selectedConversationId} {selectedConversationId ? data.conversation_list?.find((p) => p.id === selectedConversationId)?.name : 'All Conversations'}{' '}
                <i className='thin-left-padding fas fa-chevron-down header-14'></i>
              </Typography>
            </Button>
            <Menu id='fade-menu' anchorEl={anchorEl} keepMounted open={open} onClose={handleClose(undefined)} color='primary'>
              {data.conversation_list?.map(({ id, name }) => (
                <MenuItem selected={selectedConversationId === id} key={id} value={name} color='primary' onClick={handleClose(id)}>
                  {name}
                </MenuItem>
              ))}
            </Menu>
          </>
        )}
      </Typography>

      <SummaryStats
        bottomDivider={false}
        data={[
          { title: 'Average Recommendation', value: data.average_recommendation },
          { title: 'Total Conversations Held', value: data.total_conversations_held },
          { title: 'Total Attendees', value: data.total_attendees },
          { title: 'Attendees Providing Feedback', value: data.attendees_providing_feedback }
        ]}
      />
    </Paper>
  )
}

const MembershipAnalytics2 = ({ data }: Props & { data: AnalyticsProps }) => {
  const classes = useStyles()
  return (
    <Paper className='reg-top-padding reg-bottom-padding'>
      <SummaryStats
        bottomDivider={false}
        data={[
          { title: 'Felt their voice was heard and valued', value: data.felt_heared },
          { title: 'Felt a greater sense of empathy with others', value: data.felt_deeper_understanding },
          { title: 'Felt a deeper understanding of the topic', value: data.felt_deeper_understanding },
          { title: 'Felt more committed to taking action', value: data.felt_more_commited_to_take_action }
        ]}
      />
    </Paper>
  )
}

const MembershipAnalytics3 = ({ data }: Props & { data: AnalyticsProps }) => {
  const classes = useStyles()
  return (
    <div className='fat-left reg-bottom-padding reg-right reg-top'>
      <Grid container justify='space-between'>
        <Grid item xs={12}>
          <Typography variant='h6' component={'div'}>
            <strong>Key Ideas, takeaways, or actions people plan to take</strong>
          </Typography>
          <Divider className='thin-top thin-bottom' />
        </Grid>
        <Grid item xs={12}>
          {data.key_idea_and_takeaways?.map((d) => (
            <Typography key={d} color='textSecondary' className='reg-bottom' variant='body1' component={'div'}>
              {d}
            </Typography>
          ))}
        </Grid>
      </Grid>
    </div>
  )
}

const MembershipAnalytics4 = ({ data }: Props & { data: AnalyticsProps }) => {
  const classes = useStyles()
  return (
    <div className='reg-left reg-bottom-padding reg-right reg-top'>
      <Grid container justify='space-between'>
        <Grid item xs={12}>
          <Typography variant='h6' component={'div'}>
            <strong>Recent feedback on overall experience</strong>
          </Typography>
          <Divider className='thin-top thin-bottom' />
        </Grid>
        <Grid item xs={12}>
          {data.feedback_on_overall_exp?.map((d) => (
            <Typography key={d} color='textSecondary' variant='body1' className='reg-bottom' component={'div'}>
              {d}
            </Typography>
          ))}
        </Grid>
      </Grid>
    </div>
  )
}

// const ConversationOverallAnalytics = ({ membership, track, data }: Props & { data: AnalyticsProps }) => {
//   const classes = useStyles()
//   return (
//     <Paper className={classes.section}>
//       <Typography variant='h6' className='reg-bottom reg-top reg-left'>
//         Conversations - Overall
//       </Typography>
//       <div className='reg-left reg-bottom-padding'>
//         <AnalyticValue name='Conversations Available' value={data.overall.conversations_available} />
//         <AnalyticValue name='Total Invites' value={data.overall.total_invites} />
//         <AnalyticValue name='Total Registrations' value={data.overall.total_registrations} />
//         <AnalyticValue name='RSVP Rate' value={data.overall.rsvp_rate} />
//         <AnalyticValue name='Total Attendees' value={data.overall.total_attendees} />
//         <AnalyticValue name='Attendance Rate' value={data.overall.attendance_rate} />
//       </div>
//     </Paper>
//   )
// }

// const ConversationUniqueAnalytics = ({ membership, track, data }: Props & { data: AnalyticsProps }) => {
//   const classes = useStyles()
//   return (
//     <Paper className={classes.section}>
//       <Typography variant='h6' className='reg-bottom reg-top reg-left'>
//         Conversations - Unique
//       </Typography>
//       <div className='reg-left reg-bottom-padding'>
//         <AnalyticValue name='Active Members' value={data.unique.active_members} />
//         <AnalyticValue name='Unique Members Invited' value={data.unique.unique_members_invited} />
//         <AnalyticValue name='Unique Members Registered' value={data.unique.unique_members_registered} />
//         <AnalyticValue name='RSVP Rate' value={data.unique.rsvp_rate} />
//         <AnalyticValue name='Unique Members Attended' value={data.unique.unique_members_attended} />
//         <AnalyticValue name='Attendance Rate' value={data.unique.attendance_rate} />
//       </div>
//     </Paper>
//   )
// }

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      // backgroundColor: '#00ff00',
      width: '100%'
    },
    section: {
      // marginTop: '2rem',
      paddingTop: '.25rem',
      minHeight: '10rem'
      // backgroundColor: '#ff0000',
      // width: '100%'
    }
  })
)
