import React, { useEffect, useState } from 'react'
import { Divider, Grid, Paper, Box } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import { flow, camelCase } from 'lodash'
import moment from 'moment-timezone'

import * as validations from '../validations/fieldLevelValidations'
import PageLoadingAnimation from '../shared/PageLoadingAnimation'

import { inject, observer } from 'mobx-react'
import withTranslation from '../hocs/withTranslation'
import { IStore } from '../../store/models'
import { useForm } from 'react-hook-form'
import { FileControl, MapRegionControl, TextFieldControl, DateTimeControl, CheckboxControl, SelectControl, WysiwygControl, localOptions } from '../utils'
import { pick, pickBy, identity } from 'lodash'
import { getSnapshot, isStateTreeNode } from 'mobx-state-tree'
import { Chip, createStyles, FormHelperText, makeStyles, Theme, Tooltip, Typography } from '@material-ui/core'
import { capitalize } from '../../decorators/textTools'
import { palette } from '../../themes'
import { HexColorPicker } from 'react-colorful'
import { Collapse } from '@material-ui/core'
import { DefaultThemeProvider } from '../../themes/DefaultThemeProvider'

// eslint-disable-next-line complexity
const TopicForm = ({ user, topic: topicOriginal, loadingTopic, currentUser, onSave, copy, onDirty, submit, createTopic, onSubmit, partners, t }: FIXME) => {
  const topic = topicOriginal || copy

  if (copy) {
    topic.setIsTemplate(false)
  }
  const isNew = !topic
  const { handleSubmit, errors, formState, control, watch, setError, reset, clearErrors, setValue } = useForm({ mode: 'onBlur' })
  const classes = useStyles()

  const { isDirty, isSubmitting, dirtyFields, isValid } = formState

  const [saved, setSaved] = useState(false)
  useEffect(() => {
    topicOriginal ? topicOriginal.setDirty(isDirty) : onDirty && onDirty(isDirty)
  }, [topicOriginal, isDirty, onDirty])

  const displayError = (error) => {
    if (error) {
      for (const fieldName in error) {
        setError(camelCase(fieldName), {
          type: 'manual',
          message: error[fieldName]
        })
      }
    }
  }

  const afterSave = async ({ data = undefined, error = undefined }: { error?: any; data?: any } = {}) => {
    onDirty && onDirty(false)
    if (error) {
      displayError(error)
    } else {
      data && reset(data)
      setSaved(true)
      topic && topic.setDirty && topic.setDirty(false)
      onSave && onSave(data)
    }
    onSubmit && onSubmit(false)
    return data
  }

  const handleSave = handleSubmit(async (data: any, e) => {
    const { location, ...rest } = data
    const updateData = { ...(isStateTreeNode(copy) ? getSnapshot(copy) : copy || {}), ...(topicOriginal ? pick(rest, Object.keys(pickBy(dirtyFields, identity))) : rest) }

    if (updateData.startsAt) {
      updateData.startsAt = updateData.startsAt.toISOString()
    }
    if (updateData.endsAt) {
      updateData.endsAt = updateData.endsAt.toISOString()
    }
    onSubmit && onSubmit(true)
    clearErrors()
    if (topicOriginal) {
      topicOriginal.updateTopic(updateData).then(afterSave)
    } else {
      createTopic({ metaDescription: t('meta.defaultDescription'), metaTitle: `${capitalize(window.siteTitle)} - ${updateData.title}`, ...updateData }).then(afterSave)
    }
  })

  const archiveTopic = () => {
    const endsAt = new Date()
    topicOriginal.updateTopic({ endsAt: endsAt.toISOString() }).then(afterSave)
  }
  const activateTopic = () => {
    if (topicOriginal.unarchivable) {
      ;(window as any).alert('This account has reached its maximum number of active Topics. Please contact Customer Support to increase the Topic limit.')
    } else {
      topicOriginal.updateTopic({ endsAt: '' }).then(afterSave)
    }
  }

  useEffect(() => {
    onDirty && onDirty(isDirty && !isSubmitting)
  }, [onDirty, isDirty, isSubmitting])

  useEffect(() => {
    if (isDirty && submit && !isSubmitting) {
      handleSave()
    }

    submit && onDirty && onDirty(false)
  }, [topic, submit, isDirty, isSubmitting, handleSave, onDirty])

  // eslint-disable-next-line no-console
  // console.log({ isDirty, isSubmitting, dirtyFields, errors })

  const { isSuperAdmin } = currentUser

  if (loadingTopic) {
    return <PageLoadingAnimation />
  }

  const HostGuideAlert = () => {
    if (!topic?.hostGuideUrl) {
      return (
        <div className='row no-gutters reg-bottom'>
          <div className='col-xs-12 center-xs no-gutters'>
            <div className='alert-bar alert-bar-warning thin-top header-16 italic'>
              <p className='flat-top flat-bottom'>This topic currently has no host guide</p>
            </div>
          </div>
        </div>
      )
    } else return null
  }

  const defaultValues = {
    brandSingular: window.coco ? 'Community Conversation' : 'Inclusivv conversation',
    eventTypeSingular: window.coco ? 'topic' : 'conversation',
    eventTypePlural: window.coco ? 'topics' : 'conversations',
    virtualEventTypeSingular: window.coco ? 'virtual topic' : 'online conversation',
    virtualEventTypePlural: window.coco ? 'virtual topics' : 'online conversations'
  }

  const partnerOptions =
    partners && partners.list.map((partner) => ({ label: `${partner.name} (${partner.slug})`, value: partner.id, disabled: partner.topicsCount >= partner.maxTopic }))
  // const selected = partnerOptions.find((p) => p.value === topic?.partnerId)

  const [showAdditionalFields, setShowAdditionalFields] = useState(false)

  const handleCheckboxChange = (event) => {
    setShowAdditionalFields(event.target.checked)
  }

  return (
    <Card className='no-padding xtra-huge-bottom no-shadow fat-top'>
      <HostGuideAlert />

      {isNew && (
        <div className='fat-bottom'>
          <Typography variant='h5'>Create a New Topic</Typography>
          <Typography variant='body1'>Super admins create new topics by completing required fields and saving the form.</Typography>
        </div>
      )}

      <form onSubmit={handleSave}>
        {user && user.isSuperAdmin() && (
          <>
            <div className='fat-bottom'>
              <CheckboxControl
                name='isTemplate'
                control={control}
                defaultValue={topic?.isTemplate}
                error={errors && errors.isTemplate}
                label={
                  <>
                    Template
                    <Tooltip title='Enabled by Super Admins only.' arrow>
                      <Chip label='Super Admin' color='secondary' className={`reg-left ${classes.adminChip}`} size='small' />
                    </Tooltip>
                  </>
                }
                fullWidth
                color='primary'
              />
            </div>

            {!topic?.isTemplate && (
              <div className='fat-bottom'>
                <SelectControl
                  name='partnerId'
                  control={control}
                  defaultValue={topic?.partnerId}
                  required={!watch('isTemplate')}
                  error={errors && errors.partnerId}
                  label={
                    <>
                      Select Customer Name From List
                      <Tooltip title='Enabled by Super Admins only.' arrow>
                        <Chip label='Super Admin' color='secondary' className={`reg-left ${classes.adminChip}`} />
                      </Tooltip>
                    </>
                  }
                  options={partnerOptions}
                  className='reg-bottom'
                  onChange={(val) => {
                    const partnerName = partnerOptions.find((po) => po.value === val)?.label
                    partnerName && !watch('partnerName') && setValue('partnerName', partnerName)
                  }}
                  helperText='Select a Customer from the list to connect this topic to the Customer page. If selected, this will populate the Customer name input below. Visible to Super Admins Only'
                />
              </div>
            )}
          </>
        )}

        <div className='fat-bottom'>
          <Typography variant='subtitle2' className='reg-bottom'>
            Topic
          </Typography>
          <Typography variant='body1' color='textSecondary' component='i'>
            Basic content configured by the Inclusivv team. This info is shown on the topic page and card as well as all conversation pages and cards.
          </Typography>
        </div>

        <TextFieldControl
          name='title'
          control={control}
          defaultValue={topic?.title?.toString() || ''}
          error={errors && errors.title}
          label={'Title'}
          required
          rules={{ validate: validations.required }}
          className='reg-bottom'
        />

        <WysiwygControl
          name='description'
          control={control}
          defaultValue={topic?.description || 'Nothing'}
          error={errors && errors.description}
          label={'Subtitle'}
          className='reg-bottom'
        />
        <FormHelperText className='reg-bottom'>A one-sentence description of the topic of conversation.</FormHelperText>

        <WysiwygControl
          name='about'
          control={control}
          defaultValue={topic?.about || ''}
          error={errors && errors.about}
          label={'Description'}
          helperText='This text shows on the Topic page only.'
        />

        <FormHelperText className='reg-bottom'>Brief summary of the conversation.</FormHelperText>

        <TextFieldControl
          name='subtitle'
          control={control}
          defaultValue={topic?.subtitle?.toString() || ''}
          error={errors && errors.subtitle}
          label={'Optional Topic Page Hero Text'}
          helperText='This text shows on the Topic page only.'
          className='reg-bottom'
        />

        <TextFieldControl
          name='videoLink'
          control={control}
          defaultValue={topic?.videoLink?.toString() || ''}
          error={errors && errors.videoLink}
          label={'Optional Topic Overview Video Link'}
          helperText='Enter an optional YouTube or Vimeo URL using the Embed version of the URL. The video will show on the Topic page in the About This Topic section.'
          rules={{ validate: validations.http }}
          className='reg-bottom'
        />

        <TextFieldControl
          name='slug'
          control={control}
          defaultValue={topic?.slug || ''}
          error={errors && errors.slug}
          label={'Slug'}
          required
          rules={{ validate: validations.required }}
        />

        <FileControl
          name='icon'
          control={control}
          defaultValue={topic?.icon}
          error={errors && errors.icon}
          label={'icon'}
          className='xtra-fat-top'
          helperText='Icon - for best results, use an image no smaller 240px x 240px'
          color='primary'
          onDelete={() => {
            setValue('remove_icon', true, { shouldDirty: true })
          }}
          deletable
        />

        <div className='reg-bottom'>
          <DateTimeControl
            name='startsAt'
            control={control}
            defaultValue={topic?.startsAt ? moment(topic?.startsAt) : undefined}
            error={errors && errors.startsAt}
            label={'Start date'}
            dateProps={{ displayTimeZone: topic?.timezone || moment.tz.guess(), timeFormat: false, closeOnSelect: true, locale: topic?.locale }}
          />

          {!topic?.active && !user.isSuperAdmin() && (
            <TextFieldControl
              name='endsAt'
              control={control}
              defaultValue={moment(topic?.endsAt).format('MM/YY/YYYY')}
              helperText='Archived'
              disabled={true}
              label={'End date'}
              className='reg-top'
            />
          )}
          {(topic?.active || user.isSuperAdmin()) && (
            <DateTimeControl
              name='endsAt'
              control={control}
              defaultValue={topic?.endsAt ? moment(topic?.endsAt) : undefined}
              error={errors && errors.endsAt}
              label={'End date'}
              helperText='After the end date, the Topic will be archived and removed from your site.'
              dateProps={{ disabled: true, displayTimeZone: topic?.timezone || moment.tz.guess(), timeFormat: false, closeOnSelect: true, locale: topic?.locale }}
            />
          )}
        </div>

        <TextFieldControl
          name='partnerName'
          control={control}
          defaultValue={topic?.partnerName?.toString() || ''}
          error={errors && errors.partnerName}
          label={'Customer Name'}
          className='reg-bottom'
        />

        <FileControl
          className='reg-bottom'
          name='partnerLogo'
          control={control}
          defaultValue={topic?.partnerLogo}
          error={errors && errors.partnerLogo}
          helperText='Customer logo - for best results, use a photo no smaller than 600px wide'
          onDelete={() => {
            setValue('remove_partnerLogo', true, { shouldDirty: true })
          }}
          deletable
        />

        <TextFieldControl
          name='partnerLogoLink'
          control={control}
          defaultValue={topic?.partnerLogoLink?.toString() || ''}
          error={errors && errors.partnerLogoLink}
          label={'Customer Logo Link'}
          className='reg-bottom'
          helperText="Only enter the Customer slug name. Ex: 'ARC', or 'DecaturGA'"
        />

        <TextFieldControl
          name='attendeeSurveyLink'
          control={control}
          defaultValue={topic?.attendeeSurveyLink?.toString() || ''}
          error={errors && errors.attendeeSurveyLink}
          label={'Survey Form Link'}
          rules={{ validate: validations.http }}
          className='reg-bottom'
        />

        <TextFieldControl
          name='surveyLink'
          control={control}
          defaultValue={topic?.surveyLink?.toString() || ''}
          error={errors && errors.surveyLink}
          label={'Survey Results Link'}
          rules={{ validate: validations.http }}
          className='reg-bottom'
        />

        <TextFieldControl
          name='cardBanner'
          control={control}
          defaultValue={topic?.cardBanner?.toString() || ''}
          error={errors && errors.cardBanner}
          label={'Card banner'}
          className='reg-bottom'
        />

        <TextFieldControl
          name='emailSignOff'
          control={control}
          defaultValue={topic?.emailSignOff?.toString() || ''}
          error={errors && errors.emailSignOff}
          label={'Email sign off'}
          helperText={"The default is 'The Inclusivv Team'. Adding a new sign off overwrites this."}
          className='reg-bottom'
        />
        <TextFieldControl
          name='themeColor'
          control={control}
          defaultValue={topic?.themeColor || ''}
          error={errors && errors.themeColor}
          label={'Theme color'}
          helperText={'Ex: #f3f3f3'}
          required
          rules={{ required: validations.required, validate: validations.colorHex }}
        />
        <HexColorPicker
          defaultValue={topic?.themeColor || ''}
          color={watch('themeColor')}
          onChange={(val) => setValue('themeColor', val, { shouldDirty: true, shouldValidate: true })}
          className='reg-bottom'
        />

        <SelectControl
          name='locale'
          control={control}
          defaultValue={topic?.locale?.toString() || 'en_US'}
          error={errors && errors.locale}
          label={'Language'}
          options={localOptions}
          required
          rules={{ validate: validations.required }}
          className='reg-bottom'
        />

        {isSuperAdmin && (
          <div>
            <div className='row no-gutters reg-bottom'>
              <div className='col-xs-12 no-left-gutter'>
                <TextFieldControl
                  name='brandSingular'
                  control={control}
                  defaultValue={topic?.brandSingular || defaultValues.brandSingular}
                  error={errors && errors.brandSingular}
                  label={'Brand'}
                  required
                  rules={{ validate: validations.required }}
                />
              </div>
            </div>

            <div className='row no-gutters reg-bottom'>
              <div className='col-xs-12 col-sm-6 no-left-gutter'>
                <TextFieldControl
                  name='eventTypeSingular'
                  control={control}
                  defaultValue={topic?.eventTypeSingular || defaultValues.eventTypeSingular}
                  error={errors && errors.eventTypeSingular}
                  label={'In-person Event Type - Singular'}
                  required
                  rules={{ validate: validations.required }}
                />
              </div>

              <div className='col-xs-12 col-sm-6 no-right-gutter'>
                <TextFieldControl
                  name='eventTypePlural'
                  control={control}
                  defaultValue={topic?.eventTypePlural || defaultValues.eventTypePlural}
                  error={errors && errors.eventTypePlural}
                  label={'In-person Event Type - Plural'}
                  required
                  rules={{ validate: validations.required }}
                />
              </div>
            </div>

            <div className='row no-gutters reg-bottom'>
              <div className='col-xs-12 col-sm-6 no-left-gutter'>
                <TextFieldControl
                  name='virtualEventTypeSingular'
                  control={control}
                  defaultValue={topic?.virtualEventTypeSingular || defaultValues.virtualEventTypeSingular}
                  error={errors && errors.virtualEventTypeSingular}
                  label={'Online Event Type - Singular'}
                  required
                  rules={{ validate: validations.required }}
                />
              </div>

              <div className='col-xs-12 col-sm-6 no-right-gutter'>
                <TextFieldControl
                  name='virtualEventTypePlural'
                  control={control}
                  defaultValue={topic?.virtualEventTypePlural || defaultValues.virtualEventTypePlural}
                  error={errors && errors.virtualEventTypePlural}
                  label={'Online Event Type - Plural'}
                  required
                  rules={{ validate: validations.required }}
                />
              </div>
            </div>
          </div>
        )}

        {false && (
          <div className='xtra-fat-top'>
            <p className='xtra-fat-top'>Choose Region</p>
            <div className='label reg-top bold header-20'>Note: If no area is selected, the topic will be available globally.</div>
            <MapRegionControl
              name='regionCoordinates'
              control={control}
              defaultValue={topic?.regionCoordinates}
              error={errors && errors.regionCoordinates}
              className='reg-bottom'
            />
          </div>
        )}
        <div className='xtra-fat-top'>
          <div>
            <CheckboxControl
              name='visibleOnlyPartners'
              control={control}
              defaultValue={topic?.visibleOnlyPartners}
              error={errors && errors.visibleOnlyPartners}
              label={'Hide from Home Page'}
              className='reg-bottom'
              fullWidth
              color='primary'
            />
          </div>
          <div>
            <CheckboxControl
              name='hidden'
              control={control}
              defaultValue={topic?.hidden}
              error={errors && errors.hidden}
              label={'Hide everywhere ( disables topic page )'}
              className='reg-bottom'
              fullWidth
              color='primary'
            />
          </div>
          {user.isSuperAdmin() && (
            <div>
              <CheckboxControl
                name='storiesEnabled'
                control={control}
                defaultValue={topic?.storiesEnabled}
                error={errors && errors.storiesEnabled}
                label={'Collect and Display Stories'}
                fullWidth
                color='primary'
              />
              <FormHelperText className='fat-left-padding reg-bottom'>
                When enabled, guests and hosts will be prompted through email to submit their story 24 hours after their conversation ends. Published stories will be displayed in
                story wall sections on the topic page and all related conversation pages. Published stories can be shared to social media by all logged in users.
              </FormHelperText>
            </div>
          )}
          <div>
            <CheckboxControl
              name='partnerConsent'
              control={control}
              defaultValue={topic?.partnerConsent}
              error={errors && errors.partnerConsent}
              label={'Add consent step to event sign up'}
              className='reg-bottom'
              fullWidth
              color='primary'
            />
          </div>
          <div>
            <CheckboxControl
              name='showHowItWorks'
              control={control}
              defaultValue={topic?.showHowItWorks}
              error={errors && errors.showHowItWorks}
              label={'Show "What to Expect" sections'}
              fullWidth
              color='primary'
            />
            <FormHelperText className='fat-left-padding reg-bottom'>
              When enabled, the Topic page and the summary page for each conversation using this Topic will display a section labeled “What to Expect” that contains standard
              content related to the Inclusivv Conversation Framework.
            </FormHelperText>
          </div>
        </div>

        {(watch('partnerConsent') === undefined ? topic?.partnerConsentTitle : watch('partnerConsent')) && (
          <div className='reg-bottom-padding'>
            <TextFieldControl
              name='partnerConsentTitle'
              control={control}
              defaultValue={topic?.partnerConsentTitle?.toString() || 'Subscribe to Our Customer Emails'}
              error={errors && errors.partnerConsentTitle}
              label={'Modal Title'}
            />

            <WysiwygControl
              name='partnerConsentBody'
              control={control}
              defaultValue={topic?.partnerConsentBody?.toString() || 'I agree to receive client emails'}
              error={errors && errors.partnerConsentBody}
              label={'Consent body content'}
              className='reg-bottom'
            />
          </div>
        )}

        <div>
          <h3 className='fat-top'>Conversation Venues Allowed</h3>
          <CheckboxControl
            name='virtualConversations'
            control={control}
            defaultValue={topic?.virtualConversations || true}
            error={errors && errors.virtualConversations}
            label={'Online'}
            disabled={!window.allowInPerson}
            color='primary'
          />
          <CheckboxControl
            name='restaurantConversations'
            control={control}
            defaultValue={topic?.restaurantConversations || false}
            error={errors && errors.restaurantConversations}
            label={'Restaurant'}
            disabled={!window.allowInPerson}
            color='primary'
          />
          <CheckboxControl
            name='homeConversations'
            control={control}
            defaultValue={topic?.homeConversations || false}
            error={errors && errors.homeConversations}
            label={'Home'}
            disabled={!window.allowInPerson}
            color='primary'
          />
          <CheckboxControl
            name='otherConversations'
            control={control}
            defaultValue={topic?.otherConversations || false}
            error={errors && errors.otherConversations}
            label={'Office / Other Venues'}
            disabled={!window.allowInPerson}
            color='primary'
          />
        </div>

        <div className='giant-top'>
          <h3>Topic Subscription</h3>
          <CheckboxControl
            name='subscriptionEnabled'
            control={control}
            defaultValue={topic?.subscriptionEnabled}
            error={errors && errors.subscriptionEnabled}
            label={'Enable Topic Subscription'}
            color='primary'
          />
          <FormHelperText className='fat-left-padding reg-bottom'>Allows Users to subscribe to this Topic</FormHelperText>

          <Collapse in={watch('subscriptionEnabled') === undefined ? topic?.subscriptionEnabled : watch('subscriptionEnabled')}>
            <div className='reg-top'>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <TextFieldControl
                    name='subscriptionCtaLabel'
                    control={control}
                    defaultValue={topic?.subscriptionCtaLabel?.toString() || ''}
                    error={errors && errors.subscriptionCtaLabel}
                    label={'Subscription Button Label'}
                  />
                </Grid>
                <Grid item xs={4}>
                  <DefaultThemeProvider>
                    <Button variant='contained' color='primary' fullWidth={true}>
                      {watch('subscriptionCtaLabel') || 'Get Updates'}
                    </Button>
                  </DefaultThemeProvider>
                  <FormHelperText>Preview</FormHelperText>
                </Grid>
              </Grid>
            </div>

            <div className='reg-top'>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <TextFieldControl
                    name='unsubscribeCtaLabel'
                    control={control}
                    defaultValue={topic?.unsubscribeCtaLabel?.toString() || ''}
                    error={errors && errors.unsubscribeCtaLabel}
                    label={'Unsubscribe Button Label'}
                  />
                </Grid>
                <Grid item xs={4}>
                  <DefaultThemeProvider>
                    <Button variant='contained' color='primary' fullWidth={true}>
                      {watch('unsubscribeCtaLabel') || 'Unsubscribe'}
                    </Button>
                  </DefaultThemeProvider>
                  <FormHelperText>Preview</FormHelperText>
                </Grid>
              </Grid>

              <div className='fat-top'>
                <TextFieldControl
                  name='subscriptionDescription'
                  control={control}
                  defaultValue={topic?.subscriptionDescription?.toString()}
                  error={errors && errors.subscriptionDescription}
                  label={'Subscription Description'}
                  multiline={true}
                />
                <FormHelperText className='reg-bottom'>Brief description of why people should subscribe.</FormHelperText>
              </div>

              <div className='fat-top'>
                <TextFieldControl
                  name='subscriptionSurveyUrl'
                  control={control}
                  defaultValue={topic?.subscriptionSurveyUrl?.toString() || ''}
                  error={errors && errors.subscriptionSurveyUrl}
                  label={'Subscription Survey Link'}
                  rules={{ validate: validations.http }}
                  helperText='Enter an optional URL for an external survey. Users who subscribe will be re-redirected to the survey link at the end of the subscription process.'
                  className='reg-bottom'
                />
              </div>
            </div>
          </Collapse>
        </div>

        <div className='giant-top'>
          <Button variant='contained' className='reg-right' onClick={() => history.back()}>
            Cancel
          </Button>

          <Button disabled={!isDirty || isSubmitting || !isValid} type='submit' color='primary' variant='contained'>
            {isNew ? 'Create Topic' : 'Submit'}
          </Button>
        </div>
      </form>

      {topic?.active && (
        <Button className='reg-top' style={{ color: palette.red500 }} onClick={archiveTopic}>
          Archive this Topic
        </Button>
      )}

      {!topic?.active && user.isSuperAdmin() && (
        <Button className='reg-top' style={{ color: palette.blue500 }} onClick={activateTopic}>
          Un-archive this Topic
        </Button>
      )}
    </Card>
  )
}

export default flow(
  observer,
  withTranslation,
  inject<FIXME, FIXME>(
    ({
      mst: {
        auth: { user },
        dialogs: { showDialog },
        topics: { createTopic },
        partners
      }
    }: {
      mst: IStore
    }) => {
      if (partners) {
        partners.setPageSize(1000)
        // partners.loadFilter({})
        partners.shouldLoad('partners_table', () => partners.loadFilter())
      }

      return {
        currentUser: user,
        user,
        showDialog,
        createTopic,
        partners
      }
    }
  )
)(TopicForm)
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    adminChip: {
      backgroundColor: '#e495be40',
      color: '#212121'
    }
  })
)
