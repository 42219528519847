import React from 'react'

import { observer } from 'mobx-react'
import { Link, Typography } from '@material-ui/core'
import { useParamConversation } from '../../../Providers'
import LoadingAnimation from '../../../shared/LoadingAnimation'

export const ConversationLocation = observer(() => {
  const { conversation, conversationLoading, isGuest } = useParamConversation()
  if (!conversation || conversationLoading) return <LoadingAnimation />
  if (!conversation.isHomeConversation && !conversation.isRestaurantConversation && !conversation.isOtherConversation) {
    return <></>
  }
  const showAddress = conversation.address && (conversation.isRestaurantConversation || conversation.isOtherConversation || (conversation.isHomeConversation && isGuest))
  return (
    <>
      <Typography variant='body2' style={{ paddingRight: '.5rem' }}>
        {conversation.venueName && <div>{conversation.venueName}</div>}
        <>
          {showAddress && <div>{conversation.address}</div>}
          {conversation.city && (
            <div>
              {conversation.city}
              {conversation.stateLevel && `, ${conversation.stateLevel}`}
            </div>
          )}
        </>
        {showAddress && (
          <div style={{ marginTop: '.5rem' }}>
            <Link
              target='_blank'
              style={{
                color: '#0E75BB',
                fontFamily: 'Helvetica Neue',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '15px',
                lineHeight: '20px',
                letterSpacing: '0.25px'
              }}
              href={`https://maps.google.com/?daddr=${conversation.address}`}
            >
              Get Directions
            </Link>
          </div>
        )}
      </Typography>
    </>
  )
})
