import { bindActionCreators } from 'redux'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, formValueSelector, change } from 'redux-form'
import { ReactTitle } from 'react-meta-tags'
import getTopicListProps from '../../getters/topicListGetter'
import getTopicIndexProps from '../../getters/TopicsIndexGetter'
import { loadTopics, loadNewTopics } from '../../actions/topicActions'
import withTranslation from '../hocs/withTranslation'
import { capitalize } from '../../decorators/textTools'
import InfiniteLocationList from '../shared/InfiniteLocationList'

class TopicsPage extends Component {
  componentDidMount() {
    this.props.changeLanguage('en_US')
  }

  render() {
    if (this.props == null) return null

    const { t } = this.props

    return (
      <div className='topics'>
        <ReactTitle title={`${capitalize(global.siteTitle)} - ${t('meta.allTopics')}`} />

        <InfiniteLocationList
          limit={9}
          initQuery={(args) => loadNewTopics(args)}
          query={(args) => loadTopics(args)}
          formName={'TopicForm'}
          itemType='topic'
          itemLabel={t('common.topic')}
          header={t('home.chooseConvo')}
          transparentHeader
          getter={getTopicListProps}
        />
      </div>
    )
  }
}

const formName = 'TopicsPage'
TopicsPage = reduxForm({ form: formName })(TopicsPage)
const selector = formValueSelector(formName)

const mapFormStateToProps = (state) => {
  const filterCoordinates = selector(state, 'coordinates')

  if (!Array.isArray(filterCoordinates)) return { filterCoordinates }

  return {
    filterCoordinates: {
      latitude: filterCoordinates[0],
      longitude: filterCoordinates[1]
    },
    formName
  }
}

TopicsPage = connect(mapFormStateToProps)(TopicsPage)

const mapStateToProps = (state, ownProps) => {
  const props = getTopicIndexProps(state)

  props.initialValues = {
    coordinates: props.currentUser.coordinates
  }

  return props
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateForm: (val, field) => change(formName, field, val)
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(TopicsPage))
