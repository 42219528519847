import React, { useContext } from 'react'

import { AuthContext } from '../../Providers/AuthProvider'
import { useHistory, useLocation } from 'react-router'
import { useTranslation } from 'react-i18next'
import { Avatar, createStyles, Drawer, Icon, ListItemIcon, makeStyles, MenuList, Theme, Toolbar, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt'
import ChatIcon from '@material-ui/icons/Chat'
import { Link } from 'react-router-dom'
import palette from '../../../themes/palette'
import MuiMenuItem from '@material-ui/core/MenuItem'

const DrawerMenu = (props) => {
  const history = useHistory()
  const location = useLocation()
  const getPage = () => {
    if (location.pathname.match(/^\/myconversations/)) return 'myconversations'
    if (location.pathname.match(/^\/favorite/)) return 'favorite'
  }
  const page = getPage()

  const { t } = useTranslation()
  const { user, loaded } = useContext(AuthContext)
  const classes = useStyles()

  if (loaded && !user) {
    history.push('/login')
  }

  return (
    <div className='toolbar-main-links no-margin'>
      <Drawer
        className={classes.drawer}
        variant='permanent'
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          <MenuList>
            {(user
              ? [
                  { label: 'My Conversations', app_url: 'myconversations', MenuIcon: MyConvos },
                  { label: 'My Organization', app_url: 'myorganization', MenuIcon: () => null },
                  { label: 'My Profile', app_url: `/users/${user.id}`, MenuIcon: MyProfile },
                  ...(user.membershipUsers.length > 0 ? [{ label: 'My Membership', app_url: '/memberships', MenuIcon: MembershipIcon }] : []),
                  { label: 'My Favorites', app_url: 'favorite', MenuIcon: FavIcon },
                  { label: 'Attend a Public Conversation', app_url: 'conversations', MenuIcon: ChatIcon },
                  { label: 'Host a Public Conversation', app_url: 'topics', MenuIcon: ViewQuiltIcon, id: 'ic-admin-nav-topics' },
                  { label: 'Join a Journey', url: 'https://www.inclusivv.co/membership', target: 'blank', MenuIcon: JoinIcon, id: 'ic-admin-nav-conversations' }
                ]
              : []
            ).map(({ label, url, app_url, MenuIcon, target }) => (
              <div key={label}>
                {app_url === 'myorganization' ? (
                  <div>
                    <MenuItem className={classes.menuListItem} disabled>
                      <ListItemIcon>
                        <MenuIcon />
                      </ListItemIcon>
                      <Typography variant='inherit' noWrap>
                        {label}
                      </Typography>
                    </MenuItem>
                    {user?.likes
                      .filter((l) => l.likableType === 'Partner')
                      .map((ap) => (
                        <Link key={ap.id} to={ap.url || '/'} target={target} style={{ color: 'inherit' }}>
                          <MenuItem className={classes.menuListItem}>
                            <ListItemIcon>
                              <Avatar style={{ ...(ap.themeColor ? { backgroundColor: ap.themeColor as any } : {}) }} alt={ap.name || ''} src={ap.icon?.primary?.url || ''} />
                            </ListItemIcon>
                            <Typography variant='inherit' noWrap>
                              {ap.name}
                            </Typography>
                          </MenuItem>
                        </Link>
                      ))}
                  </div>
                ) : (
                  <Link to={app_url ? app_url : { pathname: url }} target={target} style={{ color: 'inherit' }}>
                    <MenuItem selected={page === app_url} className={classes.menuListItem}>
                      <ListItemIcon>
                        <MenuIcon />
                      </ListItemIcon>
                      <Typography variant='inherit' noWrap>
                        {label}
                      </Typography>
                    </MenuItem>
                  </Link>
                )}
              </div>
            ))}
          </MenuList>
        </div>
      </Drawer>
    </div>
  )
}
const MembershipIcon = (props) => {
  return <Icon className='fas fa-users' style={{ width: '1.4em' }} />
}
const JoinIcon = (props) => {
  return <Icon className='fas fa-clipboard-user' />
}
const MyProfile = (props) => {
  return <Icon className='fas fa-user' />
}
const FavIcon = (props) => {
  return <Icon className='fas fa-heart' />
}
const MyConvos = (props) => {
  return <Icon className='fas fa-th-list' />
}
export default DrawerMenu
const drawerWidth = 300
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '&$selected': {
        color: palette.black,
        backgroundColor: palette.lightYellow
      },
      '&$selected:hover': {
        color: palette.black,
        backgroundColor: palette.lightYellow
      }
    },
    selected: {},
    drawer: {
      width: drawerWidth,
      flexShrink: 0
    },
    drawerPaper: {
      width: drawerWidth
    },
    drawerContainer: {
      overflow: 'auto',
      paddingTop: theme.spacing(2),
      height: '100%'
    },
    menuListItem: {
      paddingTop: theme.spacing(1.7),
      paddingBottom: theme.spacing(1.7)
    }
  })
)

const MenuItem = withStyles({
  root: {
    '&$selected': {
      color: palette.black,
      backgroundColor: palette.lightYellow
    },
    '&$selected:hover': {
      color: palette.black,
      backgroundColor: palette.lightYellow
    }
  },
  selected: {}
})(MuiMenuItem)
