import { toFormData } from './FormData'
import { deleteApi, post, put, get } from './utils'
import queryString from 'query-string'

class FavoriteApi {
  create(likable: any) {
    return post('/favorites', {
      body: JSON.stringify({ likable })
    })
  }

  // topicSubscribe(topicId: number) {
  //   return post('/subscribe', {
  //     body: JSON.stringify({ likable: { likable_id: topicId, likable_type: 'Topic', kind: 'subscribe' } })
  //   })
  // }
  destroy(id: number) {
    return deleteApi(`/favorites/${id}`)
  }
}

export default new FavoriteApi()
