import React from 'react'
import FontIcon from '@material-ui/core/Icon'
import { DialogTitle } from '@material-ui/core'
import Dialog from '../../DialogWrapper'
import withTranslation from '../../hocs/withTranslation'

const EmailInviteSentDialog = ({ dialog, t }) => {
  return (
    <Dialog dialog={dialog} name='emailInviteSent' type='noPadding'>
      <div>
        <DialogTitle disableTypography>{t('invitation.success')}</DialogTitle>

        <section className='pane reg-pane'>
          <div className='row reg-bottom'>
            <div className='col-xs-12 center-xs'>
              <div className='box'>
                <FontIcon className='fal fa-paper-plane header-36' />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-xs-12 center-xs'>
              <h1 className='flat header-24'>{t('conversation.inviteSent')}</h1>
            </div>
          </div>
        </section>
      </div>
    </Dialog>
  )
}

export default withTranslation(EmailInviteSentDialog)
