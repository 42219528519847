import React from 'react'
import GuestsSummary from './GuestsSummary'
import AdminMetaTags from '../../../shared/AdminMetaTags'

interface Props {}

const Attendees = (props: Props) => {
  return (
    <>
      <AdminMetaTags title='Attendees' />
      <GuestsSummary />
    </>
  )
}

export default Attendees
