import React, { useState } from 'react'
import { IPartner } from '../../../../store/models/Partner'
import { inject, observer } from 'mobx-react'
import { flow } from 'lodash'
import { IStore } from '../../../../store/models'
import { Button, Menu, MenuItem, Typography } from '@material-ui/core'
import palette from '../../../../themes/palette'
import { withRouter, RouteComponentProps } from 'react-router'
import { IUser } from '../../../../store/models/User'

interface Props {
  user: IUser
  partnerSlugs: string[]
  partnersList: IPartner[]
  loaded?: boolean
  selectedPartnerId?: number
  selectPartner(val: any)
  loadAllFilters()
}

const PartnerSelector = ({ user, partnerSlugs, partnersList, selectPartner, selectedPartnerId, loadAllFilters, history }: Props & RouteComponentProps) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (partnerId?: number) => (event) => {
    setAnchorEl(null)
    selectPartner && selectPartner(partnerId)
    loadAllFilters && loadAllFilters()
  }
  const buttonStyle = { margin: '.5em', padding: '.5em 2em', color: palette.darkestgrey }
  const buttonIconStyle = { fontSize: '1em' }
  const buttonProps: any = {
    size: 'small',
    variant: 'contained',
    style: buttonStyle,
    disableElevation: true
  }

  const partner = partnerSlugs.length === 1 ? partnersList[0] : partnersList.find((p) => p.id === selectedPartnerId)

  return (
    <>
      {partnerSlugs.length === 0 ? (
        <>
          <Typography variant='h6' component='span'>
            All Customers
          </Typography>
        </>
      ) : (
        <>
          {partnerSlugs.length === 1 ? (
            <>
              {partner && (
                <>
                  <Typography variant='h6' component='span'>
                    {partner.name}
                  </Typography>{' '}
                  <Button {...buttonProps} onClick={() => history.push(`/admin/partners/${partner?.slug}/edit`)} startIcon={<i className='fas fa-pen' style={buttonIconStyle}></i>}>
                    Edit Customer
                  </Button>
                  <Button
                    {...buttonProps}
                    onClick={() => {
                      window.open(`/${partner?.slug}`)
                    }}
                    startIcon={<i className='fas fa-eye' style={buttonIconStyle}></i>}
                  >
                    View Saved Customer
                  </Button>
                </>
              )}
            </>
          ) : (
            <>
              <Button aria-controls='fade-menu' aria-haspopup='true' onClick={handleOpenMenu} color='primary'>
                <Typography variant='h6' component='p'>
                  {selectedPartnerId ? partnersList.find((p) => p.id === selectedPartnerId)?.name : 'All Customers'}{' '}
                  <i className='thin-left-padding fas fa-chevron-down header-14'></i>
                </Typography>
              </Button>
              {partner && (
                <>
                  <Button {...buttonProps} onClick={() => history.push(`/admin/partners/${partner?.slug}/edit`)} startIcon={<i className='fas fa-pen' style={buttonIconStyle}></i>}>
                    Edit Customer
                  </Button>
                  <Button
                    {...buttonProps}
                    onClick={() => {
                      window.open(`/${partner?.slug}`)
                    }}
                    startIcon={<i className='fas fa-eye' style={buttonIconStyle}></i>}
                  >
                    View Saved Customer
                  </Button>
                </>
              )}
              <Menu id='fade-menu' anchorEl={anchorEl} keepMounted open={open} onClose={handleClose(undefined)} color='primary'>
                {partnersList.length > 0 && (
                  <MenuItem selected={selectedPartnerId === undefined} value={undefined} color='primary' onClick={handleClose(undefined)}>
                    {'All Customers'}
                  </MenuItem>
                )}
                {partnersList.map(({ id, name }) => (
                  <MenuItem selected={selectedPartnerId === id} key={id} value={name} color='primary' onClick={handleClose(id)}>
                    {name}
                  </MenuItem>
                ))}
              </Menu>
            </>
          )}
        </>
      )}
    </>
  )
}

export default flow(
  withRouter,
  observer,
  inject<FIXME, FIXME>(
    ({
      mst: {
        auth: { user },
        partners,
        topics,
        conversations
      }
    }: {
      mst: IStore
    }) => {
      const partnerSlugs: string[] = user?.partnerSlugs?.map((ps) => ps.toLowerCase()) || []
      let partnersList: IPartner[] = []
      const defaultPartner = partnerSlugs && partnerSlugs[0] && partners.list.find((p) => p.slug === partnerSlugs[0])

      if (partnerSlugs.length === 1 && !partners.selectedPartnerId && defaultPartner) {
        partners.selectPartner(defaultPartner.id)
      }
      if (partnerSlugs.length > 0) {
        partnersList = partners.list.filter((d) => partnerSlugs.includes(d.slug.toLowerCase()))
        if (partnersList.length !== partnerSlugs.length) {
          partnerSlugs.forEach((ps) => partners.shouldLoad(ps, () => partners.loadBySlug(ps)))
        }
      }

      return {
        user,
        partnerSlugs,
        partnersList,
        selectPartner: partners.selectPartner,
        selectedPartnerId: partners.selectedPartnerId,
        loadAllFilters: () => {
          topics.loadFilter()
          conversations.loadFilter()
          partners.loadStats(partners.selectedPartnerId ? [partners.selectedPartnerId] : [], { reload: true })
        }
      }
    }
  )
)(PartnerSelector)
