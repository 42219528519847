import React from 'react'
import MetaTags from 'react-meta-tags'

const CustomMetaTags = ({ ...props }) => {
  const {
    record: { metaTitle, metaDescription, metaImage, hasMetaImage }
  } = props

  return (
    <MetaTags>
      <title>{metaTitle}</title>
      <meta name='description' content={metaDescription} />
      <meta property='og:image' content={hasMetaImage ? metaImage.primary.url : 'https://civicdinners-manual-storage.s3.amazonaws.com/civicdinners_newsocialimage.png'} />
      <meta property='og:description' content={metaDescription} />
    </MetaTags>
  )
}

export default CustomMetaTags
