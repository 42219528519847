import { Button, DialogTitle, FormControl, FormHelperText, Grid, IconButton, InputLabel, makeStyles, MenuItem, Select, TextField, Tooltip, Typography } from '@material-ui/core'
import { ColDef, DataGrid, ValueGetterParams } from '@material-ui/data-grid'
import { groupBy } from 'lodash'
import React, { useEffect, useState } from 'react'
import { ITopicDocuments } from '../../store'
import { IDocument } from '../../store/models/base'
import DialogWrapper from '../DialogWrapper'
import LoadingAnimation from '../shared/LoadingAnimation'
import { SelectControl } from '../utils'
import DocumentItem from './DocumentItem'

interface Props {
  documents: IDocument[]
  groupId: string
  documentable: ITopicDocuments
  groups: string[]
  editable?: boolean
}

const DocumentGroup = ({ groupId, documents, documentable, groups, editable = true }: Props) => {
  const groupLink = (id) => `https://${window.location.host}/${documentable.slug}/documents/${id}`

  return (
    <section>
      <Grid container className='fat-top thin-bottom'>
        <Grid item xs={10}>
          {groupId ? (
            <strong>
              <a href={groupLink(groupId)} target='blank'>
                {groupId}
              </a>
            </strong>
          ) : (
            <strong>
              <a href={groupLink(groupId)} target='blank'>
                (Ungrouped)
              </a>
            </strong>
          )}{' '}
          <Typography variant='caption'>
            <i style={{ color: 'grey' }}>{documents && (documents.length > 1 ? `(${documents.length} files)` : '(1 file)')}</i>
          </Typography>
          {editable && <AssignDocumentGroup documents={documents} groups={groups} documentable={documentable} />}
        </Grid>
        <Grid item xs={2}>
          <Tooltip title='Copy group link'>
            <IconButton
              size='small'
              className='reg-left'
              onClick={() => {
                navigator.clipboard.writeText(groupLink(groupId))
                documentable.showAlert('Copied link to clipboard.')
              }}
            >
              <i className='fas fa-copy opacity-8'></i>
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>

      {(documents as IDocument[]).map((document) => (
        <DocumentItem key={document.id} document={document} onDelete={() => documentable?.deleteDocument(document.id)} editable={editable} />
      ))}
    </section>
  )
}
export default DocumentGroup

export const AssignDocumentGroup = ({ documents, groups, documentable }: { documents: IDocument[]; groups: string[]; documentable: ITopicDocuments }) => {
  const classes = useStyles()
  const [show, setShow] = useState(false)
  const [term, setTerm] = useState('')
  const [group, setGroup] = useState('')
  const [filtered, setFiltered] = useState(documents)
  const [selected, setSelected] = useState<string[]>([])
  const [newGroup, setNewGroup] = useState('')

  const columns: ColDef[] = [{ field: 'fileName', headerName: 'File', width: 400 }]
  const rows = filtered.map((d) => ({ id: d.id, fileName: d.fileName() }))

  useEffect(() => {
    setFiltered(term === '' ? documents : documents.filter((d) => d.fileName().includes(term)))
  }, [term, documents])

  const assignGroup = () => {
    const groupIds = documents.filter((d) => selected.includes(d.id.toString())).map((d) => d.id)
    const assignTo = newGroup ? newGroup : group
    documentable.assignDocumentGroup(groupIds, assignTo).then(() => {
      setShow(false)
      documentable.loadTopicDocuments(true)
    })
  }

  return (
    <>
      <Tooltip title='Assign to group'>
        <IconButton size='small' className='reg-left' onClick={() => setShow(true)}>
          <i className='fas fa-ellipsis-v opacity-8'></i>
        </IconButton>
      </Tooltip>
      <DialogWrapper open={show} afterClose={() => setShow(false)} type='noPadding'>
        <DialogTitle>Assign to group</DialogTitle>
        <div style={{ display: 'flex', flexDirection: 'row', flex: 1, padding: '2em' }}>
          <TextField label='Search' onChange={(e) => setTerm(e.target.value)} fullWidth={true} />
        </div>
        {documents && (
          <div style={{ height: 600, width: '100%' }}>
            <DataGrid autoPageSize={true} rows={rows} columns={columns} checkboxSelection onSelectionChange={(data) => setSelected(data.rowIds as string[])} />
          </div>
        )}

        <div style={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'space-between', padding: '1em' }}>
          <FormControl className={classes.formControl}>
            <InputLabel id='group_selector'>Select Group</InputLabel>
            <Select
              displayEmpty
              labelId='group_selector'
              required={true}
              fullWidth={true}
              onChange={({ target: { value } }) => setGroup(value as string)}
              defaultValue=''
              className={classes.selectEmpty}
            >
              {groups.map((id, i: number) => (
                <MenuItem key={i} value={id}>
                  {id}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField label='New Group' onChange={(e) => setNewGroup(e.target.value)} fullWidth={true} />
          </FormControl>

          <Button onClick={assignGroup} disabled={newGroup === '' && group === ''} type='submit' color='secondary' variant='contained'>
            Assign
          </Button>
        </div>
      </DialogWrapper>
    </>
  )
}
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}))
