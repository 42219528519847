import { post, put } from './utils'

class PasswordResetsApi {
  static createPasswordReset(formData) {
    return post(`/password_resets?site_name=${global.siteName}`, {
      noToken: true,
      body: JSON.stringify({
        passwordReset: formData,
        locale: formData.locale
      })
    })
  }

  static usePasswordReset(token, formData) {
    return put(`/password_resets/${token}`, {
      noToken: true,
      body: JSON.stringify({ user: formData, locale: formData.locale })
    })
  }
}

export default PasswordResetsApi
