import React, { Component } from 'react'
import { connect } from 'react-redux'
import FontIcon from '@material-ui/core/Icon'
import Button from '@material-ui/core/Button'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import { flow } from 'lodash'
import { DialogTitle } from '@material-ui/core'

import { createInvitation } from '../../actions/InvitationActions'
import { renderTextField } from '../validations/fieldRenderers'
import * as validations from '../validations/fieldLevelValidations'
import withTranslation from '../hocs/withTranslation'

export class InvitationDialog extends Component {
  handleSubmit = ({ emails }) => {
    const { createInvitation, currentUserId, language } = this.props

    var arrayedEmails = emails.split(',')

    for (let email of arrayedEmails) {
      var values = {
        recipientEmail: email,
        senderId: currentUserId,
        locale: language
      }

      createInvitation(values)
    }
  }

  render() {
    const { pristine, submitting, handleSubmit, hasRequiredFields, t } = this.props

    return (
      <div>
        <DialogTitle disableTypography>{t('invitation.invite')}</DialogTitle>

        <section className='pane thin-reg-pane'>
          <div className='row reg-bottom'>
            <div className='col-xs-12 center-xs'>
              <div className='box'>
                <FontIcon className='fal fa-envelope-open-text header-36' />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-xs-12 center-xs'>
              <h1 className='flat header-24'>{t('invitation.inviteByEmail')}</h1>
            </div>
          </div>
        </section>

        <section className='pane minimal-grey-pane big-top-padding big-bottom-padding'>
          <form onSubmit={handleSubmit(this.handleSubmit)}>
            <div className='row reg-bottom'>
              <div className='col-xs-12'>
                <Field
                  name='emails'
                  component={renderTextField}
                  label={t('conversation.emailLabel')}
                  helperText={t('conversation.emailHint')}
                  validate={[validations.required, validations.emails]}
                />
              </div>
            </div>

            <div className='row center-xs fat-top'>
              <div className='col-xs-12'>
                <Button
                  label={t('conversation.sendInvite')}
                  type='submit'
                  className='reg-bottom flat-top btn-xl btn-block lowercase capitalize'
                  disabled={pristine || submitting || !hasRequiredFields}
                  variant='contained'
                  color='primary'
                >
                  {t('conversation.sendInvite')}
                </Button>
              </div>
            </div>
          </form>
        </section>
      </div>
    )
  }
}

const formName = 'InvitationsForm'

const selector = formValueSelector(formName)

const mapStateToProps = (state, ownProps) => {
  const { emails } = selector(state, 'emails', 'message')

  var props = {}
  props.hasRequiredFields = emails
  return props
}

export default flow(reduxForm({ form: formName }), connect(mapStateToProps, { createInvitation }))(withTranslation(InvitationDialog))
