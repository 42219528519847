import { types, Instance } from 'mobx-state-tree'
import UserActionsApi from '../../../api/users/UserActionsApi'
import ActionsApi from '../../../api/UserActionsApi'

import { UserBase } from './UserBase'
import Editable from '../helpers/Editable'

export const UserAction = types
  .compose(
    'UserAction',
    Editable,
    types.model('UserAction', {
      id: types.identifierNumber,
      actionId: types.maybeNull(types.number),
      conversationId: types.maybeNull(types.number),
      topicId: types.maybeNull(types.number),
      committedAt: types.maybeNull(types.string),
      finishedAt: types.maybeNull(types.string),
      userId: types.number
    })
  )
  .actions((self) => ({
    updateAction: (data) => {
      return ActionsApi.update({ userActionId: self.id, data }).then(({ response: { ok, status, statusText }, json }) => {
        if (ok) {
          self.updateMe(json)
        }
      })
    },
    deleteAction: () => {
      return ActionsApi.delete(self.id)
    }
  }))

export const UserActions = types
  .compose(
    'UserActions',
    UserBase,
    types.model({
      userActions: types.optional(types.array(UserAction), [])
    })
  )
  .actions((self) => ({
    setUserActions(data: any) {
      try {
        self.userActions.replace(data)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('oops')
      }
    }
  }))
  .actions((self) => ({
    loadUserActions: ({ reload = false } = {}) => {
      const listName = 'userActions'
      if (self.isLoading(listName) || (!reload && self.isLoaded(listName))) {
        return
      }
      self.startLoading(listName)
      return UserActionsApi.getAll(self.id).then(({ response: { ok }, json }) => {
        if (ok) {
          self.setUserActions(json)
        }
        self.stopLoading(listName)
      })
    }
  }))

export interface IUserAction extends Instance<typeof UserAction> {}
export interface IUserActions extends Instance<typeof UserActions> {}
export default UserActions
