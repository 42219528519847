import TopicNotificationsApi from '../../../api/TopicNotificationsApi'
import { types, Instance, isAlive } from 'mobx-state-tree'
import { removeNullStringValue } from '../utils'
import TopicBase from './TopicBase'
import testEmailsApi from '../../../api/TestEmailsApi'
import { Alertable } from '../helpers/Alertable'
import i18n from 'i18next'
import topicNotificationsApi from '../../../api/TopicNotificationsApi'
import { camelify } from '../../../decorators/textTools'
import Editable from '../helpers/Editable'

const TopicNotification = types
  .compose(
    'TopicNotification',
    Alertable,
    Editable,
    types.model({
      id: types.identifierNumber,
      active: types.maybeNull(types.boolean),
      content: types.maybeNull(types.string),
      topicId: types.number,
      description: types.maybeNull(types.string),
      label: types.maybeNull(types.string),
      name: types.maybeNull(types.string),
      sendDate: types.maybeNull(types.string),
      subject: types.maybeNull(types.string)
    })
  )
  .actions((self) => ({
    updateTopicNotification: (formData, { dirty = false, alert = 'Email successfully updated' } = {}) => {
      return topicNotificationsApi.update({ ...formData, id: self.id }).then(({ response: { ok }, json }) => {
        if (ok) {
          self.showAlert(alert)
          const notif = camelify(json)
          self.updateMe(notif, { dirty })
          return { data: notif }
        }
      })
    }
  }))

export const TopicNotifications = types
  .compose(
    'TopicNotifications',
    TopicBase,
    types.model({
      topicNotifications: types.optional(types.array(TopicNotification), [])
    })
  )
  .actions((self) => ({
    setTopicNotifications(val: any[]) {
      val.forEach((d) => removeNullStringValue(d))
      self.topicNotifications.replace(val.sort((a, b) => a.id - b.id))
    },
    updateListInList: (data) => {
      self.topicNotifications.replace(self.topicNotifications.filter((n) => (n.id === data.id ? data : n)))
    }
  }))
  .actions((self) => ({
    loadTopicNotifications: () => {
      if (self.isLoading('topicNotifications')) {
        return
      }
      self.startLoading('topicNotifications')
      return TopicNotificationsApi.getAll(self.slug).then(({ response: { ok }, json }) => {
        if (!isAlive(self)) return
        if (ok) {
          self.setTopicNotifications(json.topic_notifications)
        }
        self.stopLoading('topicNotifications')
      })
    },
    sendTestEmail: (data) => {
      return testEmailsApi.create({ ...data, topic_id: self.id }).then((response) => {
        if (response.response.ok) {
          self.showAlert(i18n.t('conversationNotifications.testEmailSuccess'))
        } else {
          self.showAlert(i18n.t('conversationNotifications.testEmailFail'))
        }
      })
    }
  }))

export interface ITopicNotification extends Instance<typeof TopicNotification> {}
export interface ITopicNotifications extends Instance<typeof TopicNotifications> {}
export default TopicNotifications
