import React, { ReactElement } from 'react'
import { Grid } from '@material-ui/core'
import ConversationsOverTime from './ConversationsOverTime'
import TotalAttendees from './TotalAttendees'
import TotalConversations from './TotalConversations'
import AttendeesOverTime from './AttendeesOverTime'
import Stories from './Stories'
import FeedbackSummary from './FeedbackSummary'

export default function AnalyticsSummary(): ReactElement {
  return (
    <Grid container spacing={3} id='ic-admin-analytics'>
      <FeedbackSummary />
      <AttendeesOverTime />
      <ConversationsOverTime />
      <TotalAttendees />
      <TotalConversations />
      <Stories />
    </Grid>
  )
}
