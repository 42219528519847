import queryString from 'query-string'
import appAnalytics from '../analytics'
import { toFormData } from './FormData'
import parseGenericObject from './parseGenericObject'
import { get, put, post, deleteApi } from './utils'

class PartnersApi {
  static getAll(args) {
    const defaultParams = { page: 0, site_name: global.siteName }
    const params = queryString.stringify({ ...defaultParams, ...args })

    return get(`/partners?${params}`)
  }

  static get(slug) {
    return get(`/partners/${slug}`)
  }

  static create(data) {
    return post(`/partners`, {
      noContentTypeJson: true,
      body: parseGenericObject(data, undefined, 'partner') //toFormData('partner', data)
    }).then((d) => {
      appAnalytics.event({
        category: 'Partner',
        action: 'Partner Created'
      })
      return d
    })
  }

  static update(data, withImage = false) {
    return put(`/partners/${data.id}`, {
      noContentTypeJson: true,
      body: toFormData('partner', data)
    }).then((d) => {
      return d
    })
  }

  static destroy(id) {
    return deleteApi(`/partners/${id}`)
  }
}

export default PartnersApi
