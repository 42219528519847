import { types, Instance } from 'mobx-state-tree'

let MESSAGE_COUNTER = 0

export const AlertMessage = types.model('AlertMessage', {
  id: types.identifierNumber,
  open: types.optional(types.boolean, true),
  message: types.string
})

export const AlertMessages = types
  .model('AlertMessages', {
    list: types.array(AlertMessage)
  })

  .actions((self) => ({
    showAlert(message: string) {
      if (!message || typeof message !== 'string') return
      // if (self.list.find((m) => m.message === message)) return
      self.list.push({ message, id: ++MESSAGE_COUNTER })
    },
    clearAlert: () => {
      self.list.replace([])
    },
    closeAlert() {
      self.list.pop()
    }
  }))
  .actions((self) => ({
    afterCreate: () => {
      if (localStorage.reload_message) {
        self.showAlert(localStorage.reload_message)
        localStorage.removeItem('reload_message')
      }
    }
  }))

export interface IAlertMessage extends Instance<typeof AlertMessage> {}
export interface IAlertMessages extends Instance<typeof AlertMessages> {}
export default AlertMessages
