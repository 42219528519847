import { types, Instance } from 'mobx-state-tree'
import { Alertable } from '../helpers/Alertable'
import { Dialogable } from '../helpers/Dialogable'
import { LoadableStatus } from '../helpers/LoadableStatus'

const TopicBase = types
  .compose(
    'TopicBase',
    LoadableStatus,
    Dialogable,
    Alertable,
    types.model({
      id: types.identifierNumber,
      partnerId: types.maybeNull(types.integer),
      isTemplate: types.maybeNull(types.boolean),
      allowDocumentUpload: types.maybeNull(types.boolean),
      active: types.maybeNull(types.boolean),
      title: types.string,
      endsAt: types.maybeNull(types.string),
      startsAt: types.maybeNull(types.string),
      timezone: types.maybeNull(types.string),
      slug: types.maybeNull(types.string),
      partnerName: types.maybeNull(types.string),
      language: types.maybeNull(types.string),
      createdAt: types.maybeNull(types.string),
      dirty: types.optional(types.boolean, false),
      invitedHostIds: types.optional(types.array(types.number), [])
    })
  )
  .actions((self) => ({
    setDirty: (val: boolean) => {
      self.dirty = val
    },
    updateMe: (val, { dirty = false } = {}) => {
      Object.assign(self, val)
      self.dirty = dirty
    }
  }))

export interface ITopicBase extends Instance<typeof TopicBase> {}
export default TopicBase
