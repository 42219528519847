import appAnalytics from '../../../analytics'
import { get, deleteApi } from '../../utils'

class PartnerAdminsApi {
  static getAll({ partnerId, limit, offset }) {
    return get(`/partners/${partnerId}/admins?limit=${limit}&offset=${offset}`)
  }
  static deleteAdmin({ partnerId, userId }) {
    return deleteApi(`/partners/${partnerId}/admins/${userId}`).then((d) => {
      return d
    })
  }
}

export default PartnerAdminsApi
