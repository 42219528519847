import React, { useContext, useState } from 'react'
import Button from '@material-ui/core/Button'
import { flow } from 'lodash'

import * as validations from '../validations/fieldLevelValidations'
import withTranslation, { WithTranslation } from '../hocs/withTranslation'
import { useForm } from 'react-hook-form'
import { TextFieldControl } from '../utils'
import { AuthContext } from '../Providers/AuthProvider'
import { createStyles, makeStyles } from '@material-ui/core'

const CreatePasswordResetForm = ({ t }: WithTranslation) => {
  const { handleSubmit, errors, formState, control } = useForm({ mode: 'onBlur' })
  const { isDirty, isSubmitting } = formState
  const { passwordReset } = useContext(AuthContext)
  const [message, setmessage] = useState()
  const onSubmit = (data: any) => {
    passwordReset(data).then((resp: any) => {
      if (resp) {
        resp?.message && setmessage(resp.message)
      }
    })
  }
  const classes = Styles()
  if (message) {
    return (
      <div className='align-center'>
        <p>{message}</p>
      </div>
    )
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className='form-md form-centered xtra-fat-top'>
        <TextFieldControl
          name='email'
          label={t('common.email')}
          rules={{ validate: { required: validations.required, email: validations.email } }}
          control={control}
          error={errors?.email}
          className='fat-bottom'
        />

        <div className='align-center'>
          <Button size='large' disabled={!isDirty || isSubmitting} type='submit' color='primary' variant='contained' className={classes.button}>
            {t('auth.resetPassword')}
          </Button>
        </div>
      </form>
    </div>
  )
}

const Styles = makeStyles((theme) =>
  createStyles({
    button: {
      marginTop: '1em',
      width: '265px',
      height: '48px',
      fontFamily: 'Avenir',
      fontStyle: 'normal',
      fontWeight: 800,
      fontSize: '18px',
      lineHeight: '26px'
    }
  })
)

export default flow(withTranslation)(CreatePasswordResetForm)
