import SectionsApi from '../../../api/SectionsApi'
import SponsorsApi from '../../../api/SponsorsApi'
import { types, Instance, isAlive, getParent } from 'mobx-state-tree'
import { LoadableStatus } from '../helpers/LoadableStatus'
import { removeNullStringValue } from '../utils'
import { Alertable } from '../helpers/Alertable'
import { camelify } from '../../../decorators/textTools'
import SectionSponsorsApi from '../../../api/sections/SponsorsApi'
import { snakify } from '../../../decorators/textTools'
import Sponsor from '../Sponsor'
import Editable from '../helpers/Editable'

const Section = types
  .compose(
    'Section',
    LoadableStatus,
    Alertable,
    Editable,
    types.model({
      id: types.identifierNumber,
      header: types.maybeNull(types.string),
      size: types.maybeNull(types.string),
      sponsors: types.optional(types.array(Sponsor), [])
    })
  )
  .actions((self) => ({
    setSponsors(val: any[]) {
      val.forEach((d) => removeNullStringValue(d))
      self.sponsors.replace(val.sort((a, b) => a.id - b.id))
    },
    updateInList: (data) => {
      self.sponsors.replace(self.sponsors.filter((n) => (n.id === data.id ? data : n)))
    },
    addToSponsors: (data) => {
      self.sponsors.push(data)
    },
    removeFromSponsors(id: number) {
      self.sponsors.replace(self.sponsors.filter((cs) => cs.id !== id))
    }
  }))
  .actions((self) => ({
    deleteSection: () => {
      const parent: any = getParent(self, 2)
      parent && parent.deleteSection(self.id)
    },
    updateSection: (data) => {
      return SectionsApi.update(snakify({ ...data, id: self.id })).then(({ response: { ok, status, statusText }, json }) => {
        if (ok) {
          const newData = camelify(json)
          self.updateMe(newData)
          self.showAlert('Section successfully updated')
        }
      })
    },
    createSponsor: (data) => {
      return SponsorsApi.create(snakify({ ...data, section_id: self.id }))
        .then(({ response: { ok, status, statusText }, json }) => {
          if (ok) {
            const newData = camelify(json)
            self.addToSponsors(newData)
            self.showAlert('Sponsor successfully created')
            return { savedData: newData, ok, status, statusText }
          }
        })
        .catch((error) => {
          throw error
        })
    },
    loadSectionSponsors() {
      return SectionSponsorsApi.getAll(self.id).then(({ response: { ok, status, statusText }, json }) => {
        if (ok) {
          self.setSponsors(json)
        }
      })
    },
    deleteSponsor: (id) => {
      return SponsorsApi.destroy(id)
        .then(({ response: { ok, status, statusText }, json }) => {
          if (ok) {
            self.removeFromSponsors(id)
            self.showAlert('Sponsor deleted')
          }
        })
        .catch((error) => {
          throw error
        })
    }
  }))

export const Sectionables = types
  .compose(
    'Sectionables',
    LoadableStatus,
    Alertable,
    types.model({
      id: types.identifierNumber,
      sections: types.optional(types.array(Section), [])
    })
  )
  .actions((self) => ({
    sectionableType: () => 'Topic',
    setSections(val: any[]) {
      val.forEach((d) => removeNullStringValue(d))
      self.sections.replace(val.sort((a, b) => a.id - b.id))
    },
    removeFromSections(id: number) {
      self.sections.replace(self.sections.filter((cs) => cs.id !== id))
    },
    addToSections: (data) => {
      self.sections.push(data)
    }
  }))
  .actions((self) => ({
    loadSections: ({ loadSponsors = true } = {}) => {
      if (self.isLoading('sections')) {
        return
      }
      self.startLoading('sections')
      return SectionsApi.getSections(self.sectionableType(), self.id).then(({ response: { ok }, json }) => {
        if (!isAlive(self)) return
        if (ok) {
          self.setSections(json)
          if (loadSponsors) {
            self.sections.map((cs) => cs.loadSectionSponsors())
          }
        }
        self.stopLoading('sections')
      })
    },

    deleteSection: (id) => {
      return SectionsApi.destroy(id)
        .then(({ response: { ok, status, statusText }, json }) => {
          if (ok) {
            self.removeFromSections(id)
            self.showAlert('Section deleted')
          }
        })
        .catch((error) => {
          throw error
        })
    },
    createSection(data) {
      return SectionsApi.create({ ...data, sectionableType: self.sectionableType(), sectionableId: self.id })
        .then(({ response: { ok, status, statusText }, json }) => {
          if (ok) {
            self.addToSections(camelify(json))
            self.showAlert('Section successfully created')
          }
        })
        .catch((error) => {
          throw error
        })
    }
  }))

export interface ISection extends Instance<typeof Section> {}
export interface ISectionables extends Instance<typeof Sectionables> {}
export default Sectionables
