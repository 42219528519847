import { Button, createStyles, Divider, Icon, makeStyles, Menu, MenuItem, PopoverOrigin, Typography } from '@material-ui/core'
import { camelCase, flow } from 'lodash'
import { observer } from 'mobx-react'
import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { FacebookShareButton, TwitterShareButton } from 'react-share'
import appAnalytics from '../../../analytics'
import { IConversation } from '../../../store'
import withTranslation, { WithTranslation } from '../../hocs/withTranslation'
import classnames from 'classnames'

interface Props {
  conversation: IConversation
  trigger?: FIXME
  anchorOrigin?: PopoverOrigin | undefined
  transformOrigin?: PopoverOrigin | undefined
  onExport?()
  hideMoreActionButtons?: boolean
}

const ShareDropdown = ({
  conversation,
  t,
  onExport,
  trigger,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left'
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'left'
  },
  hideMoreActionButtons = false
}: Props & WithTranslation & RouteComponentProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const classes = Styles()
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const labelColor = '#000000'
  const color = '#000000'
  return (
    <div>
      {trigger ? (
        <div onClick={handleClick}>{trigger}</div>
      ) : (
        <Button endIcon={<Icon className='fas fa-caret-down' />} variant='contained' onClick={handleClick}>
          <span className={classes.shareLabel}>{t('conversation.share')}</span>
          <span className={classes.moreActionLabel}>{t('conversation.moreActions')}</span>
        </Button>
      )}

      <Menu anchorEl={anchorEl} getContentAnchorEl={null} keepMounted open={Boolean(anchorEl)} onClose={handleClose} anchorOrigin={anchorOrigin} transformOrigin={transformOrigin}>
        <MenuItem onClick={handleClose} className={classes.dropdownItems}>
          <FacebookShareButton
            onClick={() => {
              appAnalytics.event({
                category: 'Conversation',
                action: 'Share on Facebook'
              })
            }}
            className={classes.button}
            url={conversation.conversationLink}
            hashtag={camelCase(conversation.slug)}
            quote={t('socialMedia.facebookConversationBodyHost', { conversationName: conversation.title })}
          >
            <i className={classnames('fab fa-facebook', classes.icon)} style={{ color }} />
            <Typography component='span' classes={{ root: classes.label }} style={{ color: labelColor }}>
              {t('socialMedia.shareOnFacebookLong')}
            </Typography>
          </FacebookShareButton>
        </MenuItem>

        <MenuItem onClick={handleClose} className={classes.dropdownItems}>
          <Button
            onClick={() => {
              appAnalytics.event({
                category: 'Conversation',
                action: 'Share on Facebook'
              })
              window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${conversation.conversationLink}`, '_blank', 'width=600,height=600')
            }}
            className={classnames('react-share__ShareButton', classes.button)}
          >
            <i className={classnames('fab fa-linkedin-in', classes.icon)} style={{ color }} />
            <Typography component='span' classes={{ root: classes.label }} style={{ color: labelColor }}>
              {t('socialMedia.shareOnLinkedInLong')}
            </Typography>
          </Button>
        </MenuItem>

        <MenuItem onClick={handleClose} className={classes.dropdownItems}>
          <TwitterShareButton
            onClick={() => {
              appAnalytics.event({
                category: 'Conversation',
                action: 'Share on Twitter'
              })
            }}
            className={classes.button}
            url={conversation.conversationLink}
            hashtags={[camelCase(conversation.slug)]}
            title={t('socialMedia.twitterConversationBodyHost', { conversationName: conversation.title })}
          >
            <i className={classnames('fab fa-twitter', classes.icon)} style={{ color }} />
            <Typography component='span' classes={{ root: classes.label }} style={{ color: labelColor }}>
              {t('socialMedia.shareOnTwitterLong')}
            </Typography>
          </TwitterShareButton>
        </MenuItem>

        <MenuItem onClick={handleClose} className={classes.dropdownItems}>
          <Button
            onClick={() => {
              navigator.clipboard.writeText(conversation.conversationLink)
              conversation.showAlert(t('socialMedia.copiedLink'))
            }}
            className={classnames('react-share__ShareButton', classes.button)}
          >
            <i className={classnames('fas fa-link', classes.icon)} style={{ color }} />
            <Typography component='span' classes={{ root: classes.label }} style={{ color: labelColor }}>
              {t('socialMedia.copyLinkLong')}
            </Typography>
          </Button>
        </MenuItem>

        {!hideMoreActionButtons && onExport && (
          <div className={classes.moreActionLabel}>
            <Divider />

            <MenuItem onClick={handleClose} className={classes.dropdownItems}>
              <Button onClick={() => conversation.createCsvExport().then(onExport)} className={classnames('react-share__ShareButton', classes.button)}>
                <i className={classnames('fas fa-cloud-download-alt', classes.icon)} style={{ color }} />
                <Typography component='span' classes={{ root: classes.label }} style={{ color: labelColor }}>
                  {t('conversation.exportGuestCSV')}
                </Typography>
              </Button>
            </MenuItem>

            <Divider />

            <MenuItem onClick={handleClose} className={classes.dropdownItems}>
              <Button onClick={() => window.open('/downloads', '_blank')} className={classnames('react-share__ShareButton', classes.button)}>
                <i className={classnames('fas fa-external-link', classes.icon)} style={{ color }} />
                <Typography component='span' classes={{ root: classes.label }} style={{ color: labelColor }}>
                  {t('common.myDownloads')}
                </Typography>
              </Button>
            </MenuItem>
          </div>
        )}
      </Menu>
    </div>
  )
}
export default flow(observer, withRouter, withTranslation)(ShareDropdown)
const Styles = makeStyles((theme) =>
  createStyles({
    header: {
      width: '50em',
      margin: '0 auto'
    },
    icon: {
      minWidth: '30px'
    },
    label: {
      fontFamily: 'avenir, sans-serif !important',
      color: '#ffffff',
      fontSize: '18px !important',
      whiteSpace: 'nowrap',
      fontWeight: 400,
      alignItems: 'flex-end'
    },
    button: {
      backgroundColor: 'transparent',
      border: 'none',
      padding: '0',
      font: 'inherit',
      color: 'inherit',
      cursor: 'pointer',
      outline: 'none',
      minWidth: '8em',
      '&:hover': {
        textDecoration: 'underline !important'
      }
    },

    shareLabel: {
      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    },
    moreActionLabel: {
      [theme.breakpoints.up('lg')]: {
        display: 'none'
      }
    },
    dropdownItems: {
      margin: '.5em 0'
    }
  })
)
