import fetch from 'isomorphic-fetch'

class TimeZoneApi {
  static get(coordinates) {
    const key = 'AIzaSyDz2K09VVCnj1JtEi--STBbZ99JAepBWqw'
    const time = +Math.floor(Date.now() / 1000)

    return fetch(`https://maps.googleapis.com/maps/api/timezone/json?location=${coordinates}&timestamp=${time}&key=${key}`, {
      method: 'GET'
    })
      .then((response) => response.json().then((json) => ({ json, response })))
      .catch((error) => {
        return error
      })
  }

  static getByPlaceId = (placeId) => {
    return new Promise((resolve, reject) => {
      if (!placeId) reject()
      const geocoder = new window.google.maps.Geocoder()
      geocoder
        .geocode({ placeId }, (results, status) => {
          if (status !== 'OK' && results) {
            reject()
            return
          }

          if (results && results[0]) {
            const data = results[0]
            const {
              geometry: { location }
            } = data
            this.get([location?.lat(), location?.lng()])
              .then(({ response: { ok }, json }) => {
                if (ok) {
                  const { timeZoneId, timeZoneName } = json
                  resolve({ timeZoneId, timeZoneName })
                } else {
                  reject()
                }
              })
              .catch(reject)
          } else {
            reject()
          }
        })
        .catch(reject)
    })
  }
}

export default TimeZoneApi
