import React, { Component } from 'react'

import { inject, observer } from 'mobx-react'
import { IStore } from '../../../../store/models'
import { withRouter } from 'react-router'
import { flow } from 'lodash'
import DataTable, { IColData, ValueLabel } from '../../../shared/DataTableFilterable'
import { Link } from 'react-router-dom'
import { IDownload } from '../../../../store/models/Downloads'
import moment from 'moment'
import DownloadActions from '../partials/DownloadActions'

class DownloadsTable extends Component<any, any> {
  render() {
    const { items, loading, filterable, history, height = 'calc(100vh / 3)' } = this.props

    const columnData: IColData[] = [
      { name: 'csvType', title: 'CSV Type' },
      { name: 'csvFile', title: 'Details' },
      { name: 'createdAt', title: 'Requested At' },
      { name: 'actions', title: 'Actions', align: 'center' }
    ]
    return (
      <DataTable
        height={height}
        data={items}
        columns={columnData}
        loading={loading}
        filterable={filterable}
        rightColumns={['actions']}
        hidePaging
        hideSearch
        hideFilter
        noDataMsg={'No Downloads'}
        getCellValue={(row: IDownload, columnName: string) => {
          const DownloadTypes = {
            user: 'All Attendees List',
            conversation: 'All Conversations List',
            guest: 'Conversation Guest List',
            survey_question: 'Survey Question',
            nps_answer: 'NPS Answers',
            membership_user: 'Membership Users',
            topic_subscribe: 'Topic Subscribers'
          }
          const downloadType = row.csvType ? DownloadTypes[row.csvType] : ''

          switch (columnName) {
            case 'csvType':
              return <ValueLabel value={downloadType} />
            case 'createdAt':
              return <ValueLabel value={moment(row[columnName]).format('ddd MMM Do, LT')} />
            case 'csvFile':
              return (
                <ValueLabel
                  value={
                    row?.parameters ? (
                      <>
                        {row.parameters.conversationId && (
                          <>
                            Conversation{' '}
                            <Link
                              title={`Conversation ${row.parameters.conversationId}`}
                              to={`/${row.conversationSlug}/conversations/${row?.parameters?.conversationId}`}
                              color='primary'
                            >
                              {row.id}
                            </Link>
                          </>
                        )}
                        {row.parameters.membershipId && (
                          <>
                            Membership{' '}
                            <Link title={`Membership ${row.parameters.membershipId}`} to={`/memberships/members/${row.parameters.membershipId}`} color='primary'>
                              {row.parameters.membershipId}
                            </Link>
                          </>
                        )}
                        {row.parameters.topicId && <>Topic {row.parameters.topicId}</>}
                        {row.parameters.selectedMembershipId && <>Membership {row.parameters.selectedMembershipId}</>}
                      </>
                    ) : (
                      ''
                    )
                  }
                />
              )
            case 'actions':
              return <DownloadActions download={row} />
            default:
              return <ValueLabel value={row[columnName]} />
          }
        }}
      />
    )
  }
}

export default flow(
  observer,
  inject<FIXME, FIXME>(({ mst: { downloads } }: { mst: IStore }) => {
    return {
      filterable: downloads,
      items: downloads.list,
      loading: downloads.loadingIds.includes('my_downloads')
    }
  }),
  withRouter
)(DownloadsTable)
