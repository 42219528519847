import React, { useContext, useEffect } from 'react'
import DialogWrapper from '../../DialogWrapper'

import { IDialog } from '../../../store/models/Dialog'
import { DialogContext } from '../../Providers/DialogProvider'
import LoginForm from './LoginForm'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
interface Props {
  dialog: IDialog
}

const Signup1Dialog = ({ dialog }: Props) => {
  const { showDialog } = useContext(DialogContext)
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const { hosting, conversationInviteLink } = dialog.data as any

  useEffect(() => {
    pathname === '/login' && dialog.closeDialog()
  }, [pathname, dialog])

  const title = conversationInviteLink ? t('auth.inviteLogin') : t('auth.login')
  return (
    <DialogWrapper dialog={dialog} name='login' closeIcon={true}>
      <LoginForm
        title={title}
        onLogin={() => {
          dialog.closeDialog()
        }}
        onSignUp={() => {
          dialog.closeDialog()
          showDialog('signup')
        }}
        hosting={hosting}
      />
    </DialogWrapper>
  )
}

export default Signup1Dialog
