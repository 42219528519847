import fetch from 'isomorphic-fetch'
import queryString from 'query-string'

export const jwtRequestHeaders = (noContentTypeJson = false, noJwt = false) => {
  return {
    ...(noContentTypeJson ? {} : { 'Content-Type': 'application/json' }),
    ...(noJwt ? {} : { AUTHORIZATION: `Bearer ${localStorage.jwt}` })
  }
}

export const responseGetter = (response: any) => {
  return response.json().then((json: any) => ({ json, response }))
}

export const get = (url: string, { headers = {}, init = {}, noContentTypeJson = false, noToken = false } = {}) => {
  return fetch(`${process.env.REACT_APP_API_HOST}${url}`, {
    method: 'GET',
    headers: { ...jwtRequestHeaders(noContentTypeJson, noToken), ...headers },
    ...init
  })
    .then(responseGetter)
    .catch((error) => {
      return error
    })
}

type IApiParams = { body?: any; init?: any; noContentTypeJson?: boolean; noToken?: boolean }

export const post = (url: string, { body = undefined, init = {}, noContentTypeJson = false, noToken = false }: IApiParams = {}) => {
  return fetch(`${process.env.REACT_APP_API_HOST}${url}`, {
    method: 'POST',
    headers: jwtRequestHeaders(noContentTypeJson, noToken),
    ...init,
    body
  })
    .then(responseGetter)
    .catch((error) => {
      return error
    })
}

export const put = (url: string, { body = undefined, init = {}, noContentTypeJson = false, noToken = false }: IApiParams = {}) => {
  return fetch(`${process.env.REACT_APP_API_HOST}${url}`, {
    method: 'PUT',
    headers: jwtRequestHeaders(noContentTypeJson, noToken),
    ...init,
    body
  })
    .then(responseGetter)
    .catch((error) => {
      return error
    })
}

export const deleteApi = (url: string, { body = undefined, init = {}, noContentTypeJson = false, noToken = false } = {}) => {
  const params = body ? `?${queryString.stringify(JSON.parse(body as any))}` : ''
  return fetch(`${process.env.REACT_APP_API_HOST}${url}${params}`, {
    method: 'DELETE',
    headers: jwtRequestHeaders(noContentTypeJson, noToken),
    ...init
  })
    .then(responseGetter)
    .catch((error) => {
      return error
    })
}
