export * from './defaulTypography'
export * from './defaultFontFamily'
export * from './cocoFontFamily'

export const defaultButtonOverride = {
  textSizeSmall: {
    fontSize: '14px',
    lineHeight: '22px'
  },
  textSizeLarge: {
    fontSize: '16px',
    lineHeight: '26px'
  }
}
