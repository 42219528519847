import { Button } from '@material-ui/core'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { IUser } from '../../../../../store'
import { useAuth, useParamConversation, useUsers } from '../../../../Providers'
import OwnerAssignDialog from './OwnerAssignDialog'

type Props = {
  // test
  onSelect(u: IUser): void
}

export default function OwnerAssign({ onSelect }: Props) {
  const [open, setOpen] = useState(false)
  const [owner, setowner] = useState<IUser | undefined>(undefined)
  const { user } = useAuth()
  return (
    <div className='fat-bottom'>
      <div className='label'>Owner</div>
      {owner && (
        <Link to={`/users/${owner?.id}`} target='blank'>
          {owner?.firstName} {owner?.lastName}
        </Link>
      )}
      {user?.isSuperAdmin() && (
        <>
          <Button
            variant='outlined'
            className='reg-left'
            size='small'
            onClick={() => {
              setOpen(true)
            }}
          >
            {owner ? 'Change' : 'Select'}
          </Button>
          <OwnerAssignDialog
            open={open}
            onClose={(u: IUser) => {
              onSelect(u)
              setowner(u)
              setOpen(false)
            }}
          />
        </>
      )}
    </div>
  )
}
