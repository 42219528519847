import React, { useContext } from 'react'
import { Avatar, createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import { StoryContext } from './contexts'
import { useHistory } from 'react-router'

export const StoryAuthor = () => {
  const story = useContext(StoryContext)
  const classes = useStyles()
  const history = useHistory()
  return (
    <Grid
      container
      className={classes.profile}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        history.push(`/users/${story?.userId}`)
      }}
    >
      <Grid item>
        <Avatar className={classes.avatar} src={`${story?.userAvatar?.url}`} />
      </Grid>
      <Grid item>
        <Typography variant='body1'>
          {story?.firstName} {story?.lastName}
        </Typography>
        {story?.userLocation && story?.userLocation?.trim() !== ',' && <Typography variant='caption'>{story?.userLocation}</Typography>}
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    profile: {
      cursor: 'pointer'
    },
    avatar: {
      margin: '0 .5em .5em 0'
    }
  })
)
