import queryString from 'query-string'

import { get } from '../utils'

class PartnerTopicsApi {
  static getAll(slug, args) {
    const defaultParams = { page: 0, site_name: global.siteName }
    const params = queryString.stringify({ ...defaultParams, ...args })

    return get(`/partners/${slug}/topics?${params}`)
  }
}

export default PartnerTopicsApi
