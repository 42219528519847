import { types, Instance } from 'mobx-state-tree'
import { post } from '../../api/utils'

const Nps = types
  .model('Nps', {
    id: types.maybe(types.string),
    token: types.maybe(types.string),
    score: types.maybe(types.string)
  })
  .actions((self) => ({
    setNps: (val: string) => {
      self.id = val
    },
    setToken: (val: string) => {
      self.token = val
    }
  }))
  .actions((self) => ({
    saveNps: (data) => {
      const { first_name, last_name, email } = data
      if (email && first_name && last_name) {
        return post('/nps_answers_synth', { body: JSON.stringify(data) })
      } else {
        return post('/nps_answers', { body: JSON.stringify(data) })
      }
    }
  }))

export interface INps extends Instance<typeof Nps> {}
export default Nps
