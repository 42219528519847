import { types, Instance, getParent } from 'mobx-state-tree'

export const Dialog = types
  .model('Dialog', {
    name: types.string,
    data: types.frozen({})
  })
  .actions((self) => ({
    closeDialog: () => {
      const parent: any = getParent(self, 2)
      parent.closeDialog(self.name)
    }
  }))

export const Dialogs = types
  .model('Dialogs', {
    list: types.array(Dialog)
  })

  .actions((self) => ({
    closeDialog(name: string) {
      self.list.replace(self.list.filter((n) => n.name !== name))
    }
  }))
  .actions((self) => ({
    showDialog(name: string, data: any = {}) {
      // eslint-disable-next-line no-console
      // console.log('showDialog', { name, data })
      const found = self.list.find((d) => d.name === name)
      if (!found) {
        self.list.unshift({ name, data })
        return
      } else if (found && found !== self.list[0]) {
        self.closeDialog(found.name)
        self.list.unshift({ name, data })
        return
      }
    },
    closeDialog(name: string) {
      // eslint-disable-next-line no-console
      // console.log('closeDialog', name)
      self.list.replace(self.list.filter((n) => n.name !== name))
    }
  }))

export interface IDialogs extends Instance<typeof Dialogs> {}
export interface IDialog extends Instance<typeof Dialog> {}
export default Dialogs
