import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import Button from '@material-ui/core/Button'
import withTranslation from '../hocs/withTranslation'
import i18next from 'i18next'
import { assign } from 'lodash'
import * as validations from '../validations/fieldLevelValidations'
import { renderTextField } from '../validations/fieldRenderers'

export class UseResetPasswordForm extends Component {
  componentDidMount() {
    const { location } = this.props

    // elevate this to app level at some point if we need locale url params in other places
    if (location.query) {
      this.props.changeLanguage(location.query.locale)
    }
  }

  handleSubmit = (values) => {
    values = assign({}, values, { locale: i18next.language })
    return this.props.usePasswordReset(values)
  }

  render() {
    const { handleSubmit } = this.props
    const { hasRequiredFields, hasMatchingPasswords, t } = this.props

    return (
      <div>
        <div className='row hugest-bottom'>
          <div className='col-xs-12 form-lg'>
            <form onSubmit={handleSubmit(this.handleSubmit)}>
              <Field name='password' component={renderTextField} label={t('auth.password')} type='password' validate={[validations.required]} />

              <Field
                name='password_confirmation'
                component={renderTextField}
                label={t('auth.confirmPass')}
                type='password'
                validate={[validations.required, validations.passwordConfirmation]}
                className='reg-bottom'
              />

              <div className='align-center'>
                <Button
                  label={t('auth.resetPassword')}
                  color='primary'
                  disabled={!(hasRequiredFields && hasMatchingPasswords)}
                  type='submit'
                  className={'btn-xl login-button'}
                  variant='contained'
                >
                  {t('auth.resetPassword')}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

const formName = 'UseResetPasswordForm'
UseResetPasswordForm = reduxForm({ form: formName })(UseResetPasswordForm)

const selector = formValueSelector(formName)

const mapStateToProps = (state, props) => {
  const { password, password_confirmation: passwordConfirmation } = selector(state, 'email', 'firstName', 'lastName', 'password', 'password_confirmation')

  return {
    hasRequiredFields: password && passwordConfirmation,
    hasMatchingPasswords: password && passwordConfirmation && password === passwordConfirmation,
    ...props
  }
}

UseResetPasswordForm = connect(mapStateToProps)(UseResetPasswordForm)

export default withTranslation(UseResetPasswordForm)
