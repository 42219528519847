import { Grid, IconButton, Tooltip } from '@material-ui/core'
import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import appAnalytics from '../../../../analytics'
import { IStore } from '../../../../store/models'
import { inject } from 'mobx-react'
import { observer } from 'mobx-react'
import { flow } from 'lodash'
import { IUser } from '../../../../store/models/User'
import { useAuth } from '../../../Providers'

interface Props {
  attendee: IUser
}
interface InjectedProps {
  user?: IUser
  masquerade(id: number): Promise<any>
}

const AttendeesActions = ({ attendee, history, masquerade }: Props & InjectedProps & RouteComponentProps) => {
  const { user: authUser } = useAuth()
  return (
    <Grid container direction='row' justify='space-around' alignItems='center'>
      <Tooltip title='Masquerade'>
        <IconButton
          size='small'
          onClick={() => {
            history.push('/login')
            masquerade(attendee.id)
          }}
        >
          <i className='far fa-theater-masks opacity-8'></i>
        </IconButton>
      </Tooltip>
      {authUser?.isSuperAdmin() && (
        <Tooltip title='Edit'>
          <IconButton
            size='small'
            onClick={() => {
              history.push(`/admin/attendees/${attendee.id}/edit`)
            }}
          >
            <i className='fas fa-pen opacity-8'></i>
          </IconButton>
        </Tooltip>
      )}
    </Grid>
  )
}

export default flow(
  observer,
  inject<InjectedProps, Props>(
    ({
      mst: {
        auth: { masquerade }
      }
    }: {
      mst: IStore
    }) => {
      return {
        masquerade
      }
    }
  ),
  withRouter
)(AttendeesActions)
