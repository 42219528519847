import React, { useEffect } from 'react'
import { flow } from 'lodash'

import { ITopicAction, IConversation } from '../../store'
import { observer } from 'mobx-react'
import { Button, Checkbox, Chip, createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import { useAuth } from '../Providers'

interface Props {
  action: ITopicAction
  conversation: IConversation
}

const ActionListItem = ({ conversation, action }: Props) => {
  const { user } = useAuth()
  const classes = useStyles()

  useEffect(() => {
    user && user.shouldLoad('userActions', user.loadUserActions)
  }, [user])

  if (!user) {
    return <></>
  }

  const reloadList = () => user.loadUserActions({ reload: true })
  const userAction = user.userActions.find((ua) => ua.actionId === action.id)
  const finished = !!userAction?.finishedAt

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={2} sm={1} className={classes.leftPane}>
          <Checkbox
            classes={{ checked: classes.checked }}
            color='primary'
            checked={finished}
            onChange={(e, val) => {
              userAction
                ? userAction.updateAction({ finishedAt: val ? new Date() : null }).then(reloadList)
                : action.createUserAction(conversation.id, { finished: true }).then(reloadList)
            }}
          />
        </Grid>
        <Grid item xs={10} className={classes.mainPane}>
          <div className='thin-bottom'>
            {!finished && <Chip label='To Do' classes={{ root: classes.chip }} size='small' />}
            {finished && <Chip label='Done' color='primary' classes={{ root: classes.chipActive }} size='small' />}
          </div>
          <Typography variant='subtitle1' className={classes.title}>
            {action.title}
          </Typography>
          <Typography variant='body2' className={classes.description}>
            {action.description}
          </Typography>
          {action.resourceText && (
            <Button size='large' variant='contained' classes={{ root: classes.actionButton }} href={action.resourceLink || '#'} disabled={!action.resourceLink} target='blank'>
              {action.resourceText}
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  )
}

export default flow(observer)(ActionListItem)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '50em',
      padding: '.5em 0',
      margin: '0 auto',
      ['&:not(:last-child)']: {
        borderBottom: '2px solid #CDCFD2'
      }
    },
    actionButton: {
      backgroundColor: '#F7BF14',
      ['&:hover']: {
        backgroundColor: '#F7BF14',
        opacity: 1
      }
    },
    title: {
      marginBottom: '.5em'
    },
    description: {
      marginBottom: '1em'
    },
    icon: {
      backgroundColor: theme.palette.primary.main,
      color: '#ffffff'
    },
    chip: {
      borderRadius: '4px'
    },
    chipActive: {
      borderRadius: '4px',
      backgroundColor: '#D3E9B5',
      color: '#000000'
    },
    mainPane: {
      padding: '.5em .5em .5em 0'
    },
    leftPane: {
      textAlign: 'center'
    },
    checked: {
      color: '#8DC540 !important'
    }
  })
)
