import React, { useEffect } from 'react'
import { Button } from '@material-ui/core'

import * as validations from '../validations/fieldLevelValidations'

import { useForm } from 'react-hook-form'
import { getSnapshot, isStateTreeNode } from 'mobx-state-tree'
import { SelectControl, TextFieldControl } from '../utils'
import { observer } from 'mobx-react'

const SectionForm = (props) => {
  const { handleSubmit, errors, formState, control, reset } = useForm({ mode: 'onBlur' })
  const { isDirty, isSubmitting } = formState
  const { section, sectionable, onSave, action } = props

  useEffect(() => {
    section?.setDirty(isDirty)
  }, [section, isDirty])

  const handleSave = handleSubmit((data: any, e) => {
    const updateData = { ...(isStateTreeNode(section) ? getSnapshot(section) : section || {}), ...data }

    const afterCreate = () => {
      reset(action === 'update' ? data : undefined, {
        errors: false, // errors will not be reset
        dirtyFields: false, // dirtyFields will not be reset
        isDirty: false, // dirty will not be reset
        isSubmitted: true,
        touched: false,
        isValid: true,
        submitCount: true
      })
      onSave && onSave()
    }
    action === 'update' && section.updateSection(updateData).then(afterCreate)
    action === 'create' && sectionable.createSection(updateData).then(afterCreate)
  })

  return (
    <form onSubmit={handleSave}>
      <div className='row bottom-xs'>
        <div className='col-xs-12 col-sm-4'>
          <TextFieldControl
            name='header'
            control={control}
            defaultValue={section?.header?.toString() || ''}
            error={errors && errors.header}
            label={'Section header'}
            required
            rules={{ validate: validations.required }}
          />
        </div>

        <div className='col-xs-12 col-sm-4'>
          <SelectControl
            name='size'
            label='Section size'
            className='max-width'
            control={control}
            defaultValue={section?.size}
            error={errors && errors.size}
            required
            rules={{ validate: validations.required }}
            options={[
              { value: 'large', label: 'Large' },
              { value: 'medium', label: 'Medium' },
              { value: 'small', label: 'Small' }
            ]}
          />
        </div>

        <div className='col-xs-12 col-sm-4 align-right'>
          <Button disabled={!isDirty || isSubmitting} color='secondary' type='submit' className='reg-right' variant='contained'>
            Update
          </Button>
          {action === 'update' && (
            <Button
              className='reg-right'
              onClick={() => {
                section?.deleteSection()
              }}
            >
              Delete
            </Button>
          )}
        </div>
      </div>
    </form>
  )
}

export default observer(SectionForm)
