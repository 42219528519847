import { Auth, IAuth } from './Auth'
import { Conversations, IConversations } from './Conversations'
import { Topics, ITopics } from './Topics'
import { Tracks, ITracks } from './Tracks'
import { IPartners, Partners } from './Partners'
import { IOrganizations, Organizations } from './Organizations'
import Dialogs, { IDialogs } from './Dialog'
import AlertMessages, { IAlertMessages } from './AlertMessage'
import { Analytics, IAnalytics } from './Analytics'
import { connectReduxDevtools } from 'mst-middlewares'
import Users, { IUsers } from './Users'
import Root from './Root'
import Downloads, { IDownloads } from './Downloads'
import { AttendeesBar, AttendeesLine, ConversationsBar, ConversationsLine } from './AnalyticsData'
import Nps, { INps } from './Nps'
import Stories, { IStories } from './Stories'
import NpsAnswers, { INpsAnswers } from './NpsAnswers'
import TopicSubscribers, { ITopicSubscribers } from './TopicSubscribers'

const alertMessages = AlertMessages.create({})
const dialogs = Dialogs.create({})
const auth = Auth.create({})
const users = Users.create({})
const partners = Partners.create({})
const topics = Topics.create({})
const tracks = Tracks.create({})
const conversations = Conversations.create({})
const downloads = Downloads.create({})
const organizations = Organizations.create({})
const nps = Nps.create({})
const nps_answers = NpsAnswers.create({})
const topic_subscribers = TopicSubscribers.create({})
const analytics = Analytics.create({
  attendeesBar: AttendeesBar.create({}),
  conversationsBar: ConversationsBar.create({}),
  conversationsLine: ConversationsLine.create({}),
  attendeesLine: AttendeesLine.create({})
})
const stories = Stories.create({})

export interface IStore {
  alertMessages: IAlertMessages
  auth: IAuth
  organizations: IOrganizations
  partners: IPartners
  topics: ITopics
  tracks: ITracks
  conversations: IConversations
  users: IUsers
  analytics: IAnalytics
  dialogs: IDialogs
  downloads: IDownloads
  nps: INps
  nps_answers: INpsAnswers
  stories: IStories
  topic_subscribers: ITopicSubscribers
}

const root: IStore = Root.create({
  alertMessages,
  auth,
  organizations,
  partners,
  topics,
  tracks,
  conversations,
  users,
  dialogs,
  downloads,
  analytics,
  nps,
  nps_answers,
  stories,
  topic_subscribers
})
if (process.env.NODE_ENV !== 'production') {
  connectReduxDevtools(require('remotedev'), root)
}
export default root
export * from './User'
export * from './Track'
export * from './Topic'
export * from './Partner'
export * from './Conversation'
export * from './helpers'
export * from './Stories'
