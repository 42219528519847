import { reduce, join, isEmpty } from 'lodash'
import i18n from 'i18next'

import { lowercase } from './textTools'

export function conversationInstructionsDecorator(conversation) {
  return conversation.guestInstructions
}

export function suitableDietsDecorator(conversation) {
  const dietKeysToWords = {
    vegetarian: lowercase(i18n.t('conversation.vegetarian')),
    vegan: lowercase(i18n.t('conversation.vegan')),
    glutenFree: i18n.t('conversation.glutenFree'),
    pescatarian: i18n.t('conversation.pescatarian'),
    omnivore: lowercase(i18n.t('conversation.omnivoreLabel')),
    dairyFree: i18n.t('conversation.dairyFreeLabel'),
    nutAllergy: i18n.t('conversation.nutAllergy')
  }

  const words = reduce(
    conversation.suitableDiets,
    (sofar, value, key) => {
      if (!value) return sofar

      var text = ''

      if (dietKeysToWords[key]) {
        text = dietKeysToWords[key]
      } else if (key === 'other') {
        text = value
      } else {
        throw new Error(`unexpected diet ${key}`)
      }

      return [...sofar, text]
    },
    []
  )

  if (isEmpty(words)) return

  const list = join(words, ', ')
  const text = i18n.t('conversation.suitableDiets', {
    eventTypeSingular: conversation.eventTypeSingular,
    diets: list
  })

  return text
}
