import queryString from 'query-string'

import { get } from '../utils'
class PartnerConversationsApi {
  static getAll(args) {
    const defaultParams = { page: 0, site_name: global.siteName }
    const params = queryString.stringify({ ...defaultParams, ...args })
    return get(`/partners/${args.slug}/conversations?${params}`)
  }
}

export default PartnerConversationsApi
