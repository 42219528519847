import { deleteApi, post, put } from './utils'

class UserLmsItemsApi {
  static create(data) {
    return post(`/user_lms_items`, {
      body: JSON.stringify({ user_lms_item: data })
    })
  }

  static update({ userLmsItemId, data }) {
    return put(`/user_lms_items/${userLmsItemId}`, {
      body: JSON.stringify({ user_lms_item: data })
    })
  }

  static delete(id) {
    return deleteApi(`/user_lms_items/${id}`)
  }
}

export default UserLmsItemsApi
