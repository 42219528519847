import React from 'react'
import { Grid } from '@material-ui/core'
import { ReactTitle } from 'react-meta-tags'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import './TermsOfService.scss'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      color: 'black'
    },
    indented: {
      color: 'black',
      textIndent: '0.5in'
    }
  })
)

const TermsOfService = () => {
  const styles = useStyles()
  return (
    <div className='tos'>
      <ReactTitle title='Terms of Service' />
      <Grid container direction='row' justify='space-around' alignItems='center'>
        <Grid item md={8}>
          <div className='pane fat-pane'>
            <p className='c21' style={{ textAlign: 'center', textTransform: 'uppercase', fontFamily: 'Times New Roman Bold, serif' }}>
              <span className='c6 c8'>INCLUSIVV</span>
            </p>

            <p className='c21 c20'>
              <span className='c7 c6 c19'></span>
            </p>
            <p className='c21'>
              <span className='c19 c7 c6'>TERMS OF SERVICE</span>
            </p>
            <p className='c21 c20'>
              <span className='c17 c7'></span>
            </p>
            <p className='c21'>
              <span className='c16 c1'>Effective as of: Jan 26, 2022</span>
            </p>
            <p className='c21 c20'>
              <span className='c7 c17'></span>
            </p>
            <p className='c3 c14'>
              <span>This Terms of Service Agreement (including all of other terms and policies referenced herein, this &ldquo;</span>
              <span className='c1'>Agreement</span>
              <span>&rdquo;) constitute a legal agreement between Civic Dinners, Inc. d/b/a Inclusivv, a Delaware public benefit corporation (&ldquo;</span>
              <span className='c1'>Inclusivv</span>
              <span>&rdquo;) and the party who accepts it, whether a legal entity or an individual (the &ldquo;</span>
              <span className='c1'>Customer</span>
              <span>&rdquo; or &ldquo;</span>
              <span className='c1'>you</span>
              <span>
                &rdquo;). This Agreement governs the Customer&rsquo;s and Customer Users&rsquo; use of, and dealings with, Inclusivv, the Platform (as defined below), Services (as
                defined below), and any other products, services, websites, applications, and integrated services provided by Inclusivv hereunder. Inclusivv and the Customer may be
                referred to herein, individually, as a &ldquo;
              </span>
              <span className='c1'>Party</span>
              <span>&rdquo;, and collectively, as the &ldquo;</span>
              <span className='c1'>Parties</span>
              <span className='c0'>&rdquo;.</span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span className='c6'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span>
                By registering to use, logging into, accessing, or using the Platform, the Services, Additional Services (if available), and/or Materials (all as defined below), by
                purchasing any use rights, access rights, or subscription plans to the same, or otherwise indicating your acceptance to this Agreement whenever the option is
                presented to you (including via a checkbox, sign-up button, or through the initial registration process): (a) you are acknowledging that you have read and
                understand the most current version of this Agreement; (b) you are representing that you are eighteen (18) years of age or older and of legal age to enter into a
                binding contract with Inclusivv; and (c) you are accepting this Agreement and agree that you are legally bound by its terms. Further, if you are entering into this
                Agreement on behalf of legal entity, government entity, or other organization type, (a &ldquo;
              </span>
              <span className='c1'>Legal Entity</span>
              <span className='c0'>
                &rdquo;) that is not a natural person, you acknowledge, represent, and warrant that you have all of the requisite rights, power, and authority to bind such Legal
                Entity to this Agreement and that you are expressly doing so.
              </span>
            </p>
            <p className='c10'>
              <span className='c0'></span>
            </p>
            <br />
            <p className='c13'>
              <span className='c6'>1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c7 c6'>Changes to this Agreement</span>
              <span>.</span>
            </p>
            <p className='c10'>
              <span className='c9 c6'></span>
            </p>
            <p className='c3'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>1.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Changes to this Agreement</span>
              <span className='c0'>
                . Inclusivv may revise and update this Agreement from time to time in its sole discretion. In such a case, Inclusivv will provide the Customer with advance notice
                of such changes (either via electronic mail, through the Platform, and/or through the Customer&rsquo;s account).
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>1.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Effective Date of Changes</span>
              <span>
                . Changes to this Agreement become effective on the date on which Inclusivv posts the latest version, which is the date appearing next to the label &ldquo;Effective
                as of&rdquo; at the top of this Agreement (the &ldquo;
              </span>
              <span className='c1'>Agreement Revision Date</span>
              <span className='c0'>
                &rdquo;). The Customer is provided with links to review the most current version of this Agreement at any time on the Inclusivv&rsquo;s website, and via links made
                available to the Customer during login to the Platform. The Customer acknowledges and agrees that registering for, purchasing subscriptions to, logging into,
                accessing, or using the Platform, Services, Additional Services (if available), and/or Materials after any Agreement Revision Date indicates the Customer&rsquo;s
                acceptance to the version of the Agreement in effect on that date, which are binding upon the Customer. Notwithstanding the foregoing, to the extent that the
                Customer has elected to purchase a subscription to the Platform and/or Services for a defined Term specified in the Order, changes to this Agreement shall become
                binding on the Customer after the conclusion of the Term specified in the Order.
              </span>
            </p>
            <p className='c10'>
              <span className='c9 c6'></span>
            </p>
            <br />
            <p className='c13'>
              <span className='c6'>2. </span>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c7 c6'>Inclusivv Services</span>
              <span className='c0'>. </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span className='c1'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Services - General</span>
              <span className='c0'>
                . Inclusivv brings people together to have conversations that matter. With the help of its proprietary Platform and Conversations, Inclusivv creates powerful
                dialogs that inspire positive social change and helps people organize and take action together.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span className='c6'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>2.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Services</span>
              <span className='c0'>
                . During the Term, the Customer will have the option to purchase a subscription to the Services pursuant to one or more Orders that it enters into with Inclusivv.
                As part of the Services selected and purchased, the Customer and its Users will have access to one or more Conversations, as set forth in the applicable Order,
                together with any accompanying Materials. The Customer and its Users shall have access to each Conversation that it elects to purchase beginning on the Conversation
                Availability Date and through the duration of the corresponding Conversation Term.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>2.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Grant of Licenses to Conversations &amp; Materials</span>
              <span>
                . Subject to and conditioned upon the Customer&rsquo;s compliance with all terms and conditions set forth in this Agreement, Inclusivv hereby grants to the Customer
                a non-exclusive, non-sublicensable, non-assignable, non-transferable, revocable, limited license (the &ldquo;
              </span>
              <span className='c1'>Content License</span>
              <span>&rdquo;) to access and use the Conversations and Materials </span>
              <span className='c15'>solely</span>
              <span>
                : (a) as part of the Customer&rsquo;s subscription to the Services; (b) for use with the Customer&rsquo;s Users; (c) in the Territory, if and as set forth in the
                Order; and (d) for the shorter of the Term of this Agreement{' '}
              </span>
              <span className='c15'>or </span>
              <span className='c0'>the specific Conversation Term applicable to the specific Conversation and Materials, as set forth in the Order.</span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>2.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Use Restrictions</span>
              <span className='c0'>
                . The Customers shall not, and shall ensure that its Customer Users do not, directly or indirectly: (a) access or use (including making any copies of) the
                Conversations or Materials beyond the scope of any license granted hereunder; (b) provide any person who is not a User with access to or use of the Conversations or
                Materials; (c) copy, adapt, or otherwise create derivative works or improvements, whether or not copyrightable, of the Conversations or Materials or any part
                thereof; (d) utilize the Conversations or Materials, or any portion thereof (or guide, instruct, or train any &nbsp;person, including any third party to design or
                create) any product or service that is substantially similar to the Conversations or Materials or that competes with the Services; (e) remove, delete, alter, or
                obscure any copyright, trademark, patent, or other intellectual property or proprietary rights notices provided on or with the Conversations or Materials, including
                any copy thereof; or (f) rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer, or otherwise make available the Conversations or Materials, to
                any third party for any reason, without the express, written consent of Inclusivv.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span className='c1'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Third Party Materials</span>
              <span>
                . The Conversations and Materials may include, incorporate, utilize, or work with software, tools, applications, content, data, or other materials, including
                related documentation (&ldquo;
              </span>
              <span className='c1'>Third Party Materials</span>
              <span>&rdquo;), that are owned by one or more third parties (&ldquo;</span>
              <span className='c1 c25'>Third Party Licensors</span>
              <span>
                &rdquo;). Such Third Party Materials may be provided to the Customer under terms that are different from, or that are in addition to, this Agreement (&ldquo;
              </span>
              <span className='c1'>Third Party Agreements</span>
              <span className='c0'>
                &rdquo;). The Customer will be provided with the opportunity to review such Third Party Agreements, and it must accept the terms thereof before utilizing the
                corresponding Third Party Materials. If the Customer elects not to accept certain Third Party Agreements, the Customer acknowledges that it may not have access to
                the corresponding Third Party Materials, which may impact the Customer&rsquo;s and Users&rsquo; access to the Platform and the Services. The Customer acknowledges
                and agrees that any Third Party Agreements it accepts are between it and the corresponding Third Party Licensors, and Inclusivv is not a party to any such Third
                Party Agreements (unless Inclusivv is a signatory and enters into the same in writing). Inclusivv is not responsible for any Third Party Materials provided by Third
                Party Licensors, and expressly disclaims any and all liabilities that may arise in connection therewith.
              </span>
            </p>
            <p className='c2 c14'>
              <span className='c0'></span>
            </p>
            <br />
            <p className='c3'>
              <span className='c6'>3.</span>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c7 c6'>Additional Services</span>
              <span className='c0'>.</span>
            </p>
            <p className='c2'>
              <span className='c16 c1'></span>
            </p>
            <p className='c3'>
              <span className='c1'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Additional Services - General</span>
              <span className='c0'>
                . Inclusivv may offer and make available to the Customer certain Additional Services, which may include, for example, assistance with the development of additional
                Conversations not currently offered by Inclusivv, or assistance with live facilitation services for in-person and/or virtual Conversation Events. The scope,
                schedule, delivery, and fees for Additional Services will be as set forth in the applicable Order(s). The Customer must have an active subscription to the Services
                in order to access the Additional Services.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>3.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Development of Conversations</span>
              <span>
                . Inclusivv may make available to the Customer the option to develop new and unique Conversations that are not otherwise offered by Inclusivv as part of the
                Services (&ldquo;
              </span>
              <span className='c1'>New Conversations</span>
              <span className='c0'>
                &rdquo;). The specific terms and specifications for New Conversations, including, for example, topics, structure, timing, and fees, shall be set forth in the
                corresponding Order. If the Parties agree to develop New Conversations, Inclusivv will collaborate with the Customer to create such New Conversations by combining
                Customer ideas and feedback with Inclusivv&rsquo; proprietary process and insight. The Parties acknowledge that the Inclusivv&rsquo; unique insight, experience, and
                its proprietary process for developing Conversations is an integral part of the creation of each New Conversation. Accordingly, each New Conversation shall be
                deemed to be a Conversation for the purposes of, and as defined in, this Agreement and Inclusivv shall have the right, title, and interest in and to all such New
                Conversations developed hereunder.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>3.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Facilitation Services</span>
              <span className='c15'>. </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If Facilitation Services are made available to the Customer, and
                upon entry by the Parties into one or more applicable Order(s), Inclusivv will provide facilitation personnel (each, a &ldquo;
              </span>
              <span className='c1'>Facilitator</span>
              <span className='c0'>
                &rdquo;) to lead one or more of the Customer&rsquo;s Conversation Events, as set forth in one or more Orders. The specific number of Facilitators, Conversation
                Events at which Facilitators are needed, dates, times, and fees, shall be set forth in the corresponding Order.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span className='c0'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In the event that the Parties agree that Inclusivv
                will provide certain Facilitation Services on-site, Inclusivv may request that the Customer provide Inclusivv with safety policies and procedures applicable to the
                venue where each corresponding Conversation Event shall be held. The Customer shall ensure that such safety policies and procedures are followed at each venue and
                each Conversation Event and that the Facilitator is generally provided a safe working environment. If Inclusivv or a Facilitator reasonably believe that attending a
                Conversation Event or continuing the provision of Facilitation Services may place such Facilitator&rsquo;s personal health or safety at risk, the Facilitator or
                Inclusivv may immediately terminate such Facilitation Services. Inclusivv shall not be liable to the Customer if Facilitation Services are terminated for reasons of
                risk to personal health or safety.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span className='c0'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Unless otherwise specified in an Order,
                Facilitators are independent contractors of Inclusivv. While Inclusivv takes reasonable measures to recruit and train Facilitators, Inclusivv does not provide any
                representations or warranties as to the education, skills, or abilities of any individual Facilitator. &nbsp;
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <br />
            <p className='c3'>
              <span className='c6'>4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c6 c7'>Inclusivv Platform</span>
              <span className='c0'>. </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>4.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Platform - General</span>
              <span>
                . As part of the Services, Inclusivv will provide the Customer with access to its proprietary Platform, through which the Customer and Customer Users may have the
                ability to: (a) view and access Conversations and Materials; (b) schedule Conversation Events; (c) invite Users to attend Conversation Events; (d) host online
                Conversation Events; (e) maintain contact lists, User attendee data, and other information about Conversation Events created, hosted, or facilitated by the Customer
                or Customer Users; and (f) gather and maintain analytical data about Conversation Events and Users. The Platform may also allow the Customer to manage its account
                with Inclusivv, purchase access to additional Conversations, and request Additional Services. Inclusivv will also provide the Customer with the ability to designate
                one or more Customer Users as administrators (the &ldquo;
              </span>
              <span className='c1'>Administrator Users</span>
              <span className='c0'>
                &rdquo;) of the Customer&rsquo;s account to the Platform. Administrator Users will have the ability to access the administrative section of the Customer&rsquo;s
                account on the Platform and will have the ability to manage other Customer Users, manage Conversations, make purchases, and manage Customer information among other
                functions. The Customer is responsible for ensuring the security of the individual accounts and passwords of its Administrative Users, along with Administrative
                User actions in connection with the Platform and the Services.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>4.2</span>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>Grant of Licenses to Platform</span>
              <span>
                . Subject to the terms and conditions of this Agreement, including any additional terms set forth in any applicable Order, for the duration of the Term, Inclusivv
                grants to the Customer a non-exclusive, non-transferable, non-assignable (except as set out in{' '}
              </span>
              <span className='c7'>Section 16.7</span>
              <span className='c0'>
                &nbsp;below), limited right to access and use the Platform (as such Platform may be modified, revised, and updated by Inclusivv, from time to time, in accordance
                with this Agreement), solely for the purpose of accessing and using the Services. The license granted herein shall allow the Customer access and use of the Platform
                only during the Term and within the Territory.
              </span>
            </p>
            <p className='c2 c14'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>4.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Maintenance</span>
              <span className='c0'>
                . The Customer acknowledges that certain maintenance activities regarding the Platform may be necessary or appropriate, from time to time, including bug fixes,
                software updates, feature updates, and the addition of new applications and new modules. In most instances, the Inclusivv infrastructure is designed to support
                updates by the Inclusivv engineering and support teams without the need to interrupt the Platform. Where such maintenance activities are not reasonably anticipated
                to materially impact the Customer&rsquo;s use of the Services, Inclusivv will have no obligation to provide notice to the Customer regarding such maintenance
                activities, although Inclusivv generally does so, in the ordinary course, at least twenty-four (24) hours in advance of the same. If Inclusivv reasonably determines
                that maintenance activities will require unavailability or outage of the Services in excess of ten (10) consecutive minutes, then Inclusivv will give the Customer
                reasonable advance written notice of the same. Inclusivv will use commercially reasonable efforts to perform routine scheduled maintenance during non-business hours
                (U.S. Eastern Time).
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>4.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Platform Helpdesk Services</span>
              <span>. Unless a separate service level agreement (an &ldquo;</span>
              <span className='c1'>SLA</span>
              <span className='c0'>
                &rdquo;) is agreed to by Inclusivv and the Customer, for additional consideration, and reflected on an Order, Inclusivv will provide customer support services, as
                available, via email and telephone during its normal business hours (typically 9:00am &ndash; 5:00pm U.S. Eastern Time, Monday through Friday, excluding holidays).
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>4.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Platform Restrictions</span>
              <span className='c0'>
                . The Customer shall not, and shall ensure that its Customer Users do not, directly or indirectly: (a) access or use the Platform beyond the scope of any license
                granted hereunder; (b) knowingly transmit or upload any material through the Platform containing viruses, trojan horses, worms, time bombs, cancelbots, or any other
                programs with the intent or effect of damaging, destroying, disrupting or otherwise impairing Inclusivv&rsquo;, or any other person&rsquo;s or entity&rsquo;s,
                network, computer system, or other equipment; (c) interfere with or disrupt the Platform, networks or servers connected to the Inclusivv systems or violate the
                regulations, policies or procedures of such networks or servers, including unlawful or unauthorized altering of any of the information submitted through the
                Platform; (d) attempt to gain unauthorized access to the Platform, other Inclusivv customers&rsquo; computer systems or networks using the Platform through any
                means; (e) bypass or breach any security device or protection used by the Platform or access or use the Platform other than through the use of then valid access
                credentials; (f) utilize the Platform to design or build, or guide, instruct, or train any third party to design or build any product or service with
                functionalities substantially similar to the Platform or that competes with the Platform; (g) rent, lease, lend, sell, sublicense, assign, distribute, publish,
                transfer, or otherwise make available the Platform, or any features, functionality, components, elements or parts of the Platform, to any third party who is not a
                User; or (h) use the Platform for any unlawful purpose or in violation of any applicable law or in a way infringing upon any Third Party&rsquo;s intellectual
                property or any other proprietary rights.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <br />
            <p className='c3'>
              <span className='c6'>5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c7 c6'>Customer Data</span>
              <span className='c0'>.</span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>5.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Responsibilities</span>
              <span>
                . The Customer is solely responsible for Customer Data and, to the extent within its control, the Customer will ensure that Customer Data complies with applicable
                laws and regulations. The Customer agrees that it will not provide any Sensitive Data to Inclusivv. If the Customer discovers that any Customer Data does include
                Sensitive Data, the Customer will promptly notify Inclusivv and, and upon such notification, Inclusivv&rsquo; sole obligation will be to delete the Sensitive Data
                in its control or possession. The Customer, not Inclusivv, will have sole responsibility for the accuracy, quality, integrity, legality, reliability,
                appropriateness, and intellectual property ownership of or right to use all Customer Data. All Personal Data will be processed and secured by Inclusivv in
                accordance with Inclusivv&rsquo; Privacy Policy, which is available at &nbsp;
              </span>
              <span className='c7 c18'>
                <a
                  className='c26'
                  href='https://www.google.com/url?q=https://www.civicdinners.com/privacy_policy&amp;sa=D&amp;source=editors&amp;ust=1642636192122360&amp;usg=AOvVaw3UwAyBkZ7_z9LRDcyndOHJ'
                >
                  https://www.civicdinners.com/privacy_policy
                </a>
              </span>
              <span className='c0'>&nbsp;(as the same may be updated from time to time by Inclusivv).</span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>5.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ownership of Customer Data</span>
              <span>
                . As between the Customer and Inclusivv, the Customer owns all Customer Data and, except as specifically provided in this Agreement or otherwise agreed to in
                writing between the Parties, Inclusivv has no right to access or use such Customer Data. During the Term, the Customer grants to Inclusivv a non-exclusive,
                non-transferable, non-assignable (except as set forth in{' '}
              </span>
              <span className='c7'>Section 16.7</span>
              <span className='c0'>
                &nbsp;below), royalty-free license to access and use Customer Data for the purposes of providing the Services to the Customer and to monitor and improve the
                Services provided to the Customer. Inclusivv will not: (a) modify Customer Data, (b) disclose Customer Data except as compelled by law or as expressly permitted in
                writing by the Customer, or (c) access Customer Data except to provide the Services to the Customer, prevent or address service or technical problems, or at the
                Customer&rsquo;s request in connection with Customer support matters.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>5.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ownership and Use of Participant Data</span>
              <span className='c0'>
                . In the course of using the Services, certain Participants may provide Participant Data while signing up for, and participating in, certain Conversations. To the
                extent that the Customer uploads such Participant Data to the Platform itself, the Customer shall own such data. To the extent that Participants sign up to the
                Platform, the Services, or individual Conversations themselves, then Inclusivv shall own such Participant Data. For any Participant Data not owned by Inclusivv, the
                Customer hereby grants to Inclusivv a non-exclusive, irrevocable, perpetual, worldwide, assignable, sublicensable, transferrable, fully-paid-up, and royalty-free
                license to access and use Participant Data collected by or utilizing the Platform or Services, including information about Participants, surveys taken by
                Participants, and any feedback or other information provided by Participants. Inclusivv shall have the right to use such Participant Data for the purposes of
                improving the Services, for product development and marketing, for optimizing its performance or metrics, to offer as part of its products and services, and
                otherwise as may be permitted by law. Further, Inclusivv shall have the right to contact and interact with Participants, and to offer to them additional products or
                services.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>5.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Use of De-identified Data</span>
              <span className='c0'>
                . The Customer acknowledges and agrees that Inclusivv may compile aggregated and de-identified data or metrics from all or part of the Customer&rsquo;s use of the
                Services, provided that such aggregated results will not contain information that could be used to individually identify the Customer or its Users. Inclusivv shall
                use such aggregated data in non-personally identifiable form for the purposes of improving the Services, for product development and marketing, for optimizing its
                performance or metrics, to offer as part of its products and services, and otherwise as may be permitted by law. Such aggregated de-identified data shall belong to
                Inclusivv and shall be considered Inclusivv&rsquo; Confidential Information.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>5.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Data Protection Compliance</span>
              <span>. To the extent Inclusivv processes Customer Data protected by Data Protection Laws, as defined in the Inclusivv Data Processing Agreement (the &ldquo;</span>
              <span className='c1'>DPA</span>
              <span>
                &rdquo;) as a processor on Customer&#39;s behalf, Customer and Inclusivv shall be subject to and comply with the DPA, which is incorporated into and forms an
                integral part of this Agreement. The DPA sets out Inclusivv&#39; obligations with respect to data protection and security when processing Customer Data on
                Customer&#39;s behalf in connection with the Service. The current version of the{' '}
              </span>
              <span>DPA is available at [URL]</span>
              <sup>
                <a href='#cmnt1' id='cmnt_ref1'>
                  [a]
                </a>
              </sup>
              <span className='c0'>.</span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <br />
            <p className='c3'>
              <span className='c6'>6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c7 c6'>Customer Obligations</span>
              <span className='c0'>.</span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>6.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Technical Requirements</span>
              <span>
                . The Customer must have the required equipment, software, and Internet access to be able to use the Platform. Acquiring, installing, maintaining, and operating
                equipment, any Customer software, and Internet access is solely the Customer&rsquo;s responsibility, except as otherwise expressly provided in an Order. Inclusivv
                neither represents nor warrants that the Services will be accessible through{' '}
              </span>
              <span className='c15'>all</span>
              <span className='c0'>&nbsp;web browser releases or all versions of tablets, smartphones, or other computing devices</span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>6.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Use of the Services</span>
              <span className='c0'>.</span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span className='c0'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Except as permitted
                under this Agreement or as required by law, the Customer shall not, and shall not knowingly permit others, in using the Services to: &nbsp;(i) defame, abuse,
                harass, stalk, threaten, or otherwise violate or infringe the legal rights (such as rights of privacy, publicity, and intellectual property) of others or Inclusivv,
                or interfere with another party&rsquo;s use of the Services; (ii) publish, ship, distribute, or disseminate any harmful, infringing, fraudulent, tortious, or
                unlawful material or information (including any unsolicited commercial communications); (iii) misrepresent, or in any other way falsely identify, the
                Customer&rsquo;s identity or affiliation, including through impersonation or altering any technical information in communications using the Services.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Inclusivv has no obligation
                to monitor the Customer&rsquo;s use of the Services;{' '}
              </span>
              <span className='c15'>provided however</span>
              <span className='c0'>
                , Inclusivv reserves the right, upon confirmation of material non-compliance with the terms of the Agreement, to monitor such use, and to review, retain and
                disclose any information as necessary to ensure compliance with the terms of the Agreement, and to satisfy or cooperate with any applicable law, regulation, legal
                process or governmental request.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span className='c0'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Customer agrees to comply with
                all applicable local, state, national and foreign laws, treaties and regulations in connection with Customer&rsquo;s and its Customer User&rsquo;s use of the
                Services, including those related to data privacy. The Customer is responsible for any breach of this Agreement by Customer Users. The Customer agrees that it will
                promptly notify Inclusivv of any violation of this Section.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <br />
            <p className='c3'>
              <span className='c6'>7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c7 c6'>Intellectual Property Rights</span>
              <span className='c0'>.</span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span className='c6'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>7.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Inclusivv Intellectual Property</span>
              <span className='c0'>
                . All title, ownership rights, and Intellectual Property Rights in and to the Platform and the Services, including without limitation, Conversations (including all
                New Conversations) and Materials, all Inclusivv Marks, and all Derivative Works of the foregoing, are and will remain owned by Inclusivv or its licensors (if and as
                applicable). The Customer acknowledges that the Conversations (including all New Conversations) and Materials are and shall remain proprietary information of
                Inclusivv. The Customer&rsquo;s right to use the Services is limited to the rights expressly granted in this Agreement and the applicable Order(s). All rights not
                expressly granted to the Customer are reserved and retained by Inclusivv and its licensors.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>7.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Safeguard of Inclusivv Intellectual Property Rights</span>
              <span className='c0'>
                . The Customer acknowledges that Inclusivv has expended considerable effort in the development of the Platform and the Services, including Conversations, Materials,
                and all Intellectual Property Rights associated therewith and contained therein. Accordingly, the Customer shall (and shall ensure that its Customer Users)
                safeguard access to the Conversations and Materials with the same level of care with which it safeguards its own valuable proprietary information and property from
                misappropriation, theft, misuse, or unauthorized access, but in any event, not less than a commercially reasonable standard of care to safeguard.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span className='c1'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;No Sale or Transfer of Intellectual Property Rights
              </span>
              <span className='c0'>
                . The Customer acknowledges and agrees that the Conversations (including all New Conversations) and Materials to which it receives access in connection with the
                Services are provided under license, and are not sold, assigned, or transferred to the Customer. Inclusivv expressly reserves and shall retain its entire right,
                title, and interest in and to the Conversations and Materials, all Intellectual Property Rights arising out of or relating thereto, and all improvements and
                Derivative Works thereof. The Customer does not acquire any rights or interests in or to the Conversations (including any New Conversations), Materials, or any of
                their components by virtue of this Agreement, other than to use the same for the duration of the applicable Conversation Term(s), in accordance with the license
                granted by this Agreement, and subject to all terms, conditions, and restrictions hereof.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>7.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Feedback</span>
              <span>
                . From time to time the Customer and/or its Customer Users may provide to Inclusivv certain information, suggestions, corrections, or other feedback in connection
                with the Platform, Services, Conversations (including New Conversations), or Materials (the &ldquo;
              </span>
              <span className='c1'>Feedback</span>
              <span className='c0'>
                &rdquo;). All Feedback shall be the sole and exclusive property of Inclusivv, who shall be the sole and exclusive owner of all the right, title, and interest
                thereto and all Intellectual Property Rights in connection therewith. &nbsp;The Customer hereby assigns (and shall ensure that its Customer Users assign) to
                Inclusivv any and all rights that the Customer may have or acquire in such Feedback.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>7.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Copyrights</span>
              <span>
                . The Customer acknowledges Inclusivv&rsquo; exclusive rights in and to the Conversations, Materials, and all components thereof and, further, acknowledges that the
                Conversations, Materials, and the copyrights to them are unique and original to Inclusivv. Further, the Parties acknowledge and agree that, even if Conversations,
                Materials, or any portions thereof are not copyrightable by law, the parties agree to treat all such items{' '}
              </span>
              <span className='c15'>as if</span>
              <span className='c0'>
                &nbsp;they are copyrightable under U.S. law, with Inclusivv or its licensors (if and as applicable) hold all right, title, and interest to such copyrights. The
                Customer shall not, at any time during or after the Term of this Agreement, dispute, challenge, or contest, directly or indirectly, Inclusivv or its licensors
                exclusive right, title, and interest in and to the Conversations, Materials, and all components thereof and/or the copyrights or the validity thereof. Each Party
                agrees that Inclusivv will have the right to seek and receive injunctive or other equitable relief for any violation of this Section by the Customer, in addition to
                any other rights and remedies that Inclusivv may have at law.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>7.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Customer Intellectual Property</span>
              <span className='c0'>
                . The Customer shall retain ownership of its own Intellectual Property Rights that it develops or acquires independently outside of the scope of this Agreement, and
                nothing in this Agreement shall assign any ownership of the Customer&rsquo;s Intellectual Property Rights to Inclusivv.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <br />
            <p className='c3'>
              <span className='c6'>8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c7 c6'>Payment of Fees</span>
              <span className='c0'>.</span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3 c14'>
              <span className='c1'>8.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Platform Fees</span>
              <span>. The specific fees for the use of the Platform (&ldquo;</span>
              <span className='c1'>Platform Fees</span>
              <span className='c0'>
                &rdquo;) are set forth in the applicable Order. Unless otherwise specified on the applicable Order, all Platform Fees are due and payable on an annual basis, in
                advance.
              </span>
            </p>
            <p className='c2 c14'>
              <span className='c0'></span>
            </p>
            <p className='c3 c14'>
              <span className='c1'>8.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Services Fees</span>
              <span>. The specific fees for access to and the use of Conversations and Materials (the &ldquo;</span>
              <span className='c1'>Services Fees</span>
              <span className='c0'>
                &rdquo;) associated therewith are set forth in the applicable Order. Unless otherwise specified on the applicable Order, all Services Fees are due and payable in
                full for each Conversation Term, in advance, at the beginning of such Conversation Term.
              </span>
            </p>
            <p className='c2 c14'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>8.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Additional Services Fees</span>
              <span>. The specific fees for Additional Services (the &ldquo;</span>
              <span className='c1'>Additional Services Fees</span>
              <span className='c0'>
                &rdquo;) associated therewith are set forth in the applicable Order. The milestones, timing, and terms for the payment of Additional Services Fees are set forth in
                the applicable Order.
              </span>
            </p>
            <p className='c2 c14'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>8.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Payment</span>
              <span className='c0'>
                . Unless otherwise expressly set forth in an Order, each Inclusivv invoice will be due and payable within fifteen (15) days after receipt by the Customer, except
                for any amounts subject to a bona fide dispute by the Customer. The Customer agrees to notify Inclusivv within fifteen (15) days after receipt of an invoice if
                there are any disputed amounts. The Parties will endeavor in good faith to resolve any dispute within fifteen (15) days of the date of notice of such dispute. All
                undisputed amounts past due in excess of fifteen (15) days will be charged a fee of 1.5% of the outstanding balance per month, or the highest amount allowed by law,
                whichever is lower.
              </span>
            </p>
            <p className='c2 c14'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>8.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Changes to Fees</span>
              <span>
                . The Customer acknowledges and agrees that Inclusivv may adjust the rates of the Platform Fees, Services Fees, and Additional Services Fees (collectively, the
                &ldquo;
              </span>
              <span className='c1'>Fees</span>
              <span className='c0'>
                &rdquo;) from time to time, with advance notice to the Customer. Notwithstanding the foregoing, Inclusivv shall (a) not increase the Platform Fees during the
                current Term then in effect; (b) not increase the Services Fees for any Conversations during the corresponding Conversation Term then in effect; and (c) not
                increase the Additional Services Fees during the project to which such Additional Services Fees apply.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>8.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Taxes</span>
              <span>
                . The Fees do not include any taxes, levies, duties, or similar governmental assessments of any nature, including, for example, value-added, sales, use, or
                withholding taxes, assessable by any jurisdiction whatsoever (collectively, &ldquo;
              </span>
              <span className='c1'>Taxes</span>
              <span className='c0'>
                &rdquo;). The Customer is responsible for paying all Taxes associated with its purchases under this Agreement and any Order. If Inclusivv has the legal obligation
                to pay or collect Taxes for which the Customer is responsible under this Section, Inclusivv will invoice the Customer and the Customer will pay that amount (unless
                the Customer provides Inclusivv with a valid tax exemption certificate authorized by the appropriate taxing authority). Inclusivv is solely responsible for taxes
                assessable against Inclusivv based on its income, property, and employees.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>8.7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Suspension of Service and Acceleration</span>
              <span className='c0'>
                . If any amount owing by the Customer under this or any other Agreement with Inclusivv that is not subject to a good faith dispute is thirty (30) or more days
                overdue, Inclusivv may, without limiting its other rights and remedies, accelerate the Customer&rsquo;s unpaid fee obligations under such Order so that all such
                obligations become immediately due and payable, and suspend any and all services until such amounts are paid in full. The Customer will continue to be charged
                applicable Fees during any period of suspension. If the Customer or Inclusivv initiates termination of this Agreement, the Customer will be obligated to pay the
                balance due on the Customer&rsquo;s account. Inclusivv reserves the right to impose a reconnection fee in the event the Customer&rsquo;s account is suspended and
                the Customer later requests access to the Platform. Any use of the Services in violation of the Agreement by the Customer that, in Inclusivv&rsquo; reasonable
                judgment, threatens the security, integrity, or availability of Inclusivv&rsquo; services or that of its other customers, may result in Inclusivv immediately
                suspending the Services and/or access to the Platform, however, Inclusivv will use commercially reasonable efforts under the circumstances to provide the Customer
                with notice and an opportunity to remedy such violation or threat prior to such suspension.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span className='c6'>9.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c7 c6'>Non-Disclosure; Confidentiality; and Data Protection</span>
              <span className='c0'>.</span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>9.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Disclosure</span>
              <span>
                . Each Party may disclose to the other Party certain Confidential Information of such Party or of such Party&rsquo;s associated companies, distributors, licensors,
                suppliers, or customers. For purposes of this Agreement, &ldquo;
              </span>
              <span className='c1'>Confidential Information</span>
              <span>
                &rdquo; means information, that is of value to its owner and is treated as confidential (including all information which is subject to treatment as a &lsquo;trade
                secret&rsquo; under applicable law); the &ldquo;
              </span>
              <span className='c1'>Disclosing Party</span>
              <span>
                &rdquo; refers to the Party disclosing Confidential Information hereunder, whether such disclosure is directly from the Disclosing Party or through the Disclosing
                Party&rsquo;s employees or agents; and &ldquo;
              </span>
              <span className='c1'>Recipient</span>
              <span className='c0'>
                &rdquo; refers to the Party receiving any Confidential Information hereunder, whether such disclosure is received directly or through Recipient&rsquo;s employees or
                agents.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>9.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Requirement of Confidentiality</span>
              <span className='c0'>.</span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span className='c0'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Recipient agrees to hold all Confidential
                Information disclosed to the Recipient by the Disclosing Party in confidence and not to, directly or indirectly, copy, reproduce, distribute, manufacture,
                duplicate, reveal, report, publish, disclose, cause to be disclosed, or otherwise transfer the Confidential Information disclosed by the Disclosing Party to any
                third party, or utilize the Confidential Information disclosed by the Disclosing Party for any purpose whatsoever other than as expressly contemplated by the
                Agreement.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span className='c0'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Customer acknowledges that the
                Services and the Documentation, as well as all pricing aspects of Orders issued in connection with this Agreement, are the Confidential Information of Inclusivv.
                Inclusivv acknowledges that all data input by the Customer or End Users into the Services is the Confidential Information of the Customer.
              </span>
            </p>
            <p className='c3'>
              <span className='c0'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </p>
            <p className='c3'>
              <span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;With regard to all Confidential Information,
                the obligations in this Section shall continue for the Term of the Agreement and for a period of three (3) years thereafter;{' '}
              </span>
              <span className='c15'>provided, however</span>
              <span className='c0'>
                , that, with respect to any Confidential Information which is a trade secret under applicable law, the obligations shall continue in perpetuity for so long as such
                information is considered a trade secret.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The foregoing obligations shall not apply if
                and to the extent that: &nbsp;(i) the Recipient establishes that the information communicated was publicly known at the time of the Recipient&rsquo;s receipt or has
                become publicly known other than by a breach of this Agreement; (ii) prior to disclosure hereunder was already in the Recipient&rsquo;s possession without
                restriction as evidenced by appropriate documentation; (iii) subsequent to any disclosure hereunder, the information is obtained by the Recipient on a
                non-confidential basis from a third party who has the right to disclose such information; or (iv) was developed by the Recipient without any use of any of the
                Confidential Information as evidenced by appropriate documentation. Notwithstanding anything to the contrary herein, if the Recipient is ordered by an
                administrative agency or other governmental body of competent jurisdiction to disclose the Confidential Information, then the Recipient may disclose the requested
                Confidential Information;{' '}
              </span>
              <span className='c15'>provided however</span>
              <span className='c0'>
                , that, the Recipient shall first notify the Disclosing Party prior to disclosure, if allowed by law, in order to give the Disclosing Party a reasonable opportunity
                to seek an appropriate protective order or waive compliance with the terms of this Agreement and shall disclose only that part of the Confidential Information which
                the Recipient is required to disclose.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>9.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Protection of Confidential Information</span>
              <span className='c0'>
                . The Recipient will use at least the same degree of care to safeguard Confidential Information that it uses to protect its own confidential and proprietary
                information, and, in any event not less than a reasonable degree of care under the circumstances. Except as otherwise provided herein, the Parties shall only use
                Confidential Information to the extent required for the proper use and delivery of the Services.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>9.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Return of Materials</span>
              <span className='c0'>
                . Upon the request of the Disclosing Party or upon the expiration or termination of the Agreement, the Recipient shall promptly destroy or deliver to the Disclosing
                Party its Confidential Information and any notes, extracts or other reproductions in whole or in part relating thereto, without retaining any copy thereof.
                Notwithstanding the foregoing, the Recipient shall be permitted to retain such copies of Confidential Information as may be reasonably necessary for legal or
                recordkeeping purposes, including such copies as are embedded in the automated backup of electronic data processing systems.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>9.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Equitable Remedies</span>
              <span className='c0'>
                . The Parties acknowledge that disclosure or use of the other Party&rsquo;s Confidential Information in violation of the Agreement may cause irreparable harm to the
                Disclosing Party for which monetary damages may be an inadequate remedy and difficult to ascertain. Each Party agrees that the Disclosing Party will have the right
                to seek injunctive or other equitable relief for any violation of the confidentiality provisions of the Agreement by the Recipient, in addition to any other rights
                and remedies that the Disclosing Party may have at law.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span className='c6'>10.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c7 c6'>Term and Termination</span>
              <span className='c0'>.</span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>10.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Initial Term</span>
              <span className='c0'>
                . The Agreement shall commence on the Effective Date indicated on the first executed Order between the Parties. The Agreement shall continue through the Initial
                Term indicated on the first Order and through any Renewal Terms (if any), defined and indicated on the first Order or amendments to the first Order, or any
                successive Order executed between the Parties. Except as otherwise specified in an Order, the Agreement will automatically renew for additional periods equal to the
                expiring Term, unless either Party gives notice of non-renewal at least sixty (60) days before the end of the then expiring Term. Unless earlier terminated as
                provided herein, this Agreement shall continue in full force and effect until the termination or expiration of the Initial Term or last Renewal Term in effect.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span className='c6'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>10.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Termination</span>
              <span className='c0'>
                . Without prejudice to any other remedies and in addition to any other termination rights herein, the Parties shall have the right to terminate the Agreement as
                provided below:
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span className='c0'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;By either Party if the other Party
                commits a material breach of this Agreement and such breach remains uncured thirty (30) days after written notice of such breach is delivered to such other Party;
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span className='c0'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;By either Party if the other Party makes an
                assignment for the benefit of creditors, or commences or has commenced against it any proceeding in bankruptcy, insolvency, or reorganization pursuant to bankruptcy
                laws, laws of debtor&rsquo;s moratorium or similar laws;
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span className='c0'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;By Inclusivv if any amounts hereunder
                which are due and owing and not reasonably in dispute remain unpaid for more than fifteen (15) days following written notice of such unpaid amounts being delivered
                to the Customer;
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;By either Party, upon notice, in the event of any
                documented verbal or written abuse (including threats of abuse or retribution) by any employee, officer, agent, or representative of the other Party that is
                directed toward any employee, officer, agent, or representative of such Party;{' '}
              </span>
              <span className='c15'>provided that</span>
              <span className='c0'>
                &nbsp;prior notice and a reasonable opportunity to address and reasonably remediate the same shall be provided before any such termination notice may be served
                effectively hereunder; or
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span className='c0'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(e)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If the Customer is granted rights
                to utilize the Platform and/or the Services pursuant to an Evaluation, either Party may terminate this Agreement at any time up to and including the last day of the
                Evaluation Term.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span className='c6'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>10.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Effect</span>
              <span>
                . Upon expiration or termination of this Agreement for any reason, all rights and licenses granted by Inclusivv hereunder to the Customer will immediately cease;
                the Customer shall cease the use of the Platform, Services, Conversations, and Materials (and promptly return any of the foregoing in its possession to the
                Inclusivv). Upon any expiration or termination of this Agreement, Inclusivv shall make the Customer&rsquo;s information reasonably available to it for a period of
                thirty (30) days. Upon any duly effected termination of this Agreement by the Customer pursuant to{' '}
              </span>
              <span className='c7'>Section 10.2(a), (b), or (d)</span>
              <span>
                , Inclusivv shall promptly refund any pre-paid but unearned fees to the Customer. Upon any duly effected termination of this Agreement by Inclusivv, pursuant to{' '}
              </span>
              <span className='c7'>Section 10.2</span>
              <span className='c0'>
                , all Fees subject to a payment plan that have not yet been paid by the Customer shall automatically accelerate and become due and payable in full on the date of
                termination.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span className='c6'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>10.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Survival</span>
              <span>
                . Termination of this Agreement or any schedule or Order will not affect the provisions regarding each Party&rsquo;s treatment of Confidential Information,
                treatment of Intellectual Property Rights, provisions relating to payments of Fees due, indemnification provisions, the provisions of{' '}
              </span>
              <span className='c7'>Section 16</span>
              <span className='c0'>
                , and provisions limiting or disclaiming a Party&rsquo;s liability, all of which shall expressly survive such termination. Further, any right or obligation of the
                parties in this Agreement which, by its nature, should survive termination or expiration hereof, will survive any such termination or expiration of this Agreement.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span className='c6'>11.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c7 c6'>Representations and Warranties; Disclaimer</span>
              <span className='c0'>.</span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>11.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mutual Representations and Warranties</span>
              <span className='c0'>. Each Party represents and warrants that it has the legal power and authority to enter into this Agreement.</span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>11.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Inclusivv Representations and Warranties</span>
              <span className='c0'>
                . Inclusivv represents and warrants that: &nbsp;(i) it will provide the Services in a manner consistent with general industry standards reasonably applicable to the
                provision thereof and (ii) the Platform delivered under the Agreement will operate substantially in conformity with its Documentation under normal use and
                circumstances. The Customer&rsquo;s sole and exclusive remedy and Inclusivv&rsquo; sole obligation for a breach of the warranties in this Section will be the
                correction or re-performance of the nonconforming Service by Inclusivv. If, after reasonable efforts, neither remedy is commercially available, Inclusivv may cancel
                this Agreement and refund to the Customer a pro-rata portion of the Fees (i.e., all Fees that have been paid but not used by the Customer from the date of notice by
                the Customer of such breach). &nbsp;
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>11.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Customer Representations and Warranties</span>
              <span className='c0'>
                . The Customer represents and warrants that: &nbsp;(i) it is of the legal age to enter into a contract in the area in which the Customer resides; (ii) the Customer
                owns or has a license to use and has obtained all consents and approvals necessary for the provision and use of all of the Customer Data that is placed on,
                transmitted via, or recorded by the Services; and (iii) the provision and use of Customer Data as contemplated by this Agreement and the Services does not and will
                not violate any Customer privacy policy, terms of use, or other agreement to which the Customer is a party or any law or regulation to which Customer is subject.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>11.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Disclaimer</span>
              <span className='c0'>.</span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span className='c0'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;OTHER THAN AS EXPRESSLY SET FORTH IN THIS AGREEMENT, NEITHER
                INCLUSIVV, ITS AFFILIATES, LICENSORS OR SUPPLIERS, NOR THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, SHAREHOLDERS, AGENTS OR REPRESENTATIVES MAKES ANY EXPRESS OR
                IMPLIED WARRANTIES, CONDITIONS, OR REPRESENTATIONS TO THE CUSTOMER, OR ANY OTHER PERSON OR ENTITY WITH RESPECT TO THE PLATFORM, THE SERVICES, OR OTHERWISE REGARDING
                THE AGREEMENT, WHETHER ORAL OR WRITTEN, EXPRESS, IMPLIED OR STATUTORY, AND, EXCEPT AS EXPRESSLY SET FORTH IN THIS AGREEMENT, THE PLATFORM, SERVICES, AND ANY
                ADDITIONAL SERVICES &nbsp;ARE PROVIDED TO THE CUSTOMER ON AN &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; BASIS, AND ARE FOR COMMERCIAL USE ONLY.
              </span>
            </p>
            <p className='c10'>
              <span className='c0'></span>
            </p>
            <p className='c13'>
              <span className='c0'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;WITHOUT LIMITING
                THE FOREGOING, ANY IMPLIED WARRANTY OR CONDITION OF MERCHANTABILITY, THE IMPLIED WARRANTY OR CONDITION OF FITNESS FOR A PARTICULAR PURPOSE, AND THOSE ARISING FROM A
                COURSE OF DEALING OR USAGE OF TRADE ARE EXPRESSLY EXCLUDED AND DISCLAIMED. NO WARRANTY IS MADE THAT USE OF THE PLATFORM OR SERVICES WILL BE TIMELY, ERROR-FREE OR
                UNINTERRUPTED, THAT ANY NON-MATERIAL ERRORS OR DEFECTS IN THE PLATFORM OR SERVICES WILL BE CORRECTED, THAT THE SYSTEM THAT MAKES THE PLATFORM OR SERVICES AVAILABLE
                WILL BE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE PLATFORM OR SERVICES WILL OPERATE IN COMBINATION WITH HARDWARE, SOFTWARE, SYSTEMS OR DATA NOT
                PROVIDED OR RECOMMENDED BY INCLUSIVV, THAT THE OPERATION OF THE PLATFORM OR SERVICES WILL BE SECURE, OR THAT THE SERVICES FUNCTIONALITY WILL MEET THE
                CUSTOMER&rsquo;S REQUIREMENTS. THE CUSTOMER ASSUMES ALL RESPONSIBILITY FOR DETERMINING WHETHER THE SERVICES OR THE INFORMATION GENERATED THEREBY IS ACCURATE OR
                SUFFICIENT FOR THE CUSTOMER&rsquo;S PURPOSE.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span className='c6'>12.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c7 c6'>Indemnification</span>
              <span className='c0'>.</span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>12.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Customer Indemnification</span>
              <span>
                . The Customer will indemnify, defend, and hold harmless Inclusivv and its respective directors, officers, employees and agents from and against any and all claims,
                losses, damages, suits, fees, judgments, costs and expenses, (including reasonable attorney&rsquo;s fees) (each, a &ldquo;
              </span>
              <span className='c1'>Claim</span>
              <span className='c0'>&rdquo;) or any claims by the Customer or any third party which may arise from or relate to this Agreement or the Customer Data.</span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>12.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Procedure for Handling Indemnification Claims</span>
              <span>
                . In the event of any Claim, Inclusivv will give reasonable notice of any such Claim to the Customer; provided that the failure to provide such notice will not
                relieve the Customer of its obligations unless such failure prejudices the Customer&rsquo;s ability to defend the Claim). The Customer will be entitled if it so
                elects in a notice promptly delivered to Inclusivv, to immediately take control of the defense, settlement and investigation of any Claim and to employ and engage
                attorneys reasonably acceptable to Inclusivv to handle and defend the same, at the Customer&rsquo;s sole cost. Inclusivv will cooperate in all reasonable respects,
                at the Customer&rsquo;s cost and request, in the investigation, trial and defense of such Claim and any related appeals. The Customer will not consent to the entry
                of any judgment or enter into any settlement with respect to a Claim without Inclusivv&rsquo; prior written consent, which may be withheld in its sole discretion.
                Inclusivv may also, at its own cost, participate through its attorneys or otherwise in such investigation, trial and defense of any Claim and related appeals.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span className='c6'>13.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c7 c6'>Limitation of Liability</span>
              <span className='c0'>.</span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(a)</span>
              <span className='c6'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span>
                THE CUSTOMER AGREES THAT INCLUSIVV&rsquo; LIABILITY ARISING OUT OF OR RELATED TO THIS AGREEMENT, NO MATTER THE THEORY OF LIABILITY, WILL NOT EXCEED IN THE AGGREGATE
                THE TOTAL FEES PAID OR OWED BY THE CUSTOMER UNDER THIS AGREEMENT DURING THE THEN-CURRENT ORDER OR, IF LESS, IN THE SIX (6) MONTHS IMMEDIATELY PRECEDING THE DATE OF
                THE EVENT GIVING RISE TO THE CLAIM. THE CUSTOMER AGREES THAT INCLUSIVV IS NOT RESPONSIBLE IN ANY WAY FOR DAMAGES CAUSED BY THIRD PARTIES WHO MAY USE OUR SERVICES,
                INCLUDING BUT NOT LIMITED TO PEOPLE WHO COMMIT INTELLECTUAL PROPERTY INFRINGEMENT, DEFAMATION, TORTIOUS INTERFERENCE WITH ECONOMIC RELATIONS, OR ANY OTHER
                ACTIONABLE CONDUCT TOWARDS THE CUSTOMER. INCLUSIVV IS NOT RESPONSIBLE FOR ANY LOSSES INCURRED AS THE RESULT OF THE CUSTOMER&rsquo;S DECISION TO USE THE SERVICES.
                THE CUSTOMER IS RESPONSIBILE FOR DETERMINING THE SUITABILITY OF THE SERVICES FOR THE CUSTOMER&rsquo;S PURPOSES. INCLUSIVV IS NOT RESPONSIBLE FOR ANY FAILURE ON THE
                PART OF A THIRD PARTY SERVICE PROVIDER.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span className='c0'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;INCLUSIVV IS NOT LIABLE FOR ANY FAILURE OF THE GOODS OR SERVICES
                OF INCLUSIVV OR A THIRD PARTY, INCLUDING ANY FAILURES OR DISRUPTIONS, UNTIMELY DELIVERY, SCHEDULED OR UNSCHEDULED, INTENTIONAL OR UNINTENTIONAL, ON THE PLATFORM
                WHICH PREVENT ACCESS TO THE PLATFORM TEMPORARILY OR PERMANENTLY. THE PROVISION OF THE SERVICES TO THE CUSTOMER IS CONTINGENT ON THE CUSTOMER&rsquo;S AGREEMENT WITH
                THIS AND ALL OTHER SECTIONS OF THIS AGREEMENT.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span className='c0'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;THE PARTIES AGREE THAT IN NO EVENT WILL EITHER PARTY HAVE ANY
                LIABILITY TO THE OTHER PARTY FOR ANY LOST PROFITS OR REVENUES OR FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, COVER, SPECIAL, EXEMPLARY OR PUNITIVE DAMAGES, NO
                MATTER HOW CAUSED OR THE THEORY OF LIABILITY, AND WHETHER OR NOT A PARTY HAS BEEN ADVISED THAT SUCH DAMAGES ARE POSSIBLE.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span className='c0'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;For jurisdictions that do not allow Inclusivv to limit its
                liability: Notwithstanding any provision of this Agreement, if the Customer&rsquo;s jurisdiction has provisions specific to waiver or liability that conflict with
                the above then Inclusivv&rsquo; liability is limited to the smallest extent possible by law. Specifically, in those jurisdictions not allowed, Inclusivv does not
                disclaim liability for: (a) death or personal injury caused by its negligence or that of any of its officers, employees or agents; or (b) fraudulent
                misrepresentation; or (c) any liability which it is not lawful to exclude either now or in the future.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span className='c0'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(e)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;IF THE CUSTOMER IS A RESIDENT OF A JURISDICTION THAT REQUIRES A
                SPECIFIC STATEMENT REGARDING RELEASE, THEN THE FOLLOWING APPLIES. FOR EXAMPLE, CALIFORNIA RESIDENTS MUST, AS A CONDITION OF THIS AGREEMENT, WAIVE THE APPLICABILITY
                OF CALIFORNIA CIVIL CODE SECTION 1542, WHICH STATES, &ldquo;A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS
                OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR.&rdquo; THE
                CUSTOMER HEREBY WAIVES THIS SECTION OF THE CALIFORNIA CIVIL CODE. THE CUSTOMER HEREBY WAIVES ANY SIMILAR PROVISION IN LAW, REGULATION, OR CODE THAT HAS THE SAME
                INTENT OR EFFECT AS THE ABOVE RELEASE.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span className='c6'>14.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c7 c6'>Special Terms for Government Customers</span>
              <span className='c0'>
                . If the Customer is a U.S. government entity, the Customer agrees that the Platform provided by Inclusivv is deemed to be &ldquo;commercial computer
                software&rdquo; and &ldquo;commercial computer software documentation&rdquo; pursuant to Defense Federal Acquisition Regulation Supplement, codified under Chapter 2
                of Title 48, United States Code of Federal Regulations, Section 227.7202, and Federal Acquisition Regulation, codified in Title 48 of the United States Code of
                Federal Regulations, Section 12.12. Any use, modification, reproduction, release, performance, display, or disclosure of the Services by the United States
                Government is governed solely by this Agreement and is prohibited except to the extent expressly permitted by this Agreement.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span className='c6'>15.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c7 c6'>Insurance</span>
              <span className='c0'>
                . Inclusivv and the Customer shall each procure and maintain or cause to be procured and maintained, at the sole expense of each respective Party and for the
                duration of this Agreement, insurance policies with financially responsible insurance companies, effective through primary and excess coverage in amounts customary
                for the nature of this Agreement.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span className='c6'>16.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c7 c6'>Miscellaneous</span>
              <span className='c0'>.</span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>16.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Publicity</span>
              <span className='c0'>
                . The Customer agrees that Inclusivv may (i) utilize the Customer&rsquo;s name and logo on its website and refer to it as its customer; (ii) mention the Customer
                during events, panels, presentations, and events; (iii) include the Customer along with its name and logo in Inclusivv&rsquo; marketing and sales materials; (iv)
                prepare a case study showcasing the Customer and its use of the Platform and/or the Services.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span className='c6'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>16.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Governing Law</span>
              <span className='c0'>
                . This Agreement will be exclusively construed, governed, and enforced in all respects in accordance with the internal laws (excluding all conflict of law rules) of
                the State of Delaware (US) and any applicable federal laws of the United States of America.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span className='c6'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>16.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Arbitration</span>
              <span>
                . Except for claims arising from unauthorized disclosures of Confidential Information, or either Party&rsquo;s violation of the other Party&rsquo;s Intellectual
                Property Rights, to which traditional court remedies will apply, any controversy or claim arising out of or relating to this Agreement, or the breach, termination,
                or validity thereof, shall be determined exclusively by final and binding arbitration, as administered in accordance with the Commercial Arbitration Rules (the
                &ldquo;
              </span>
              <span className='c1'>Rules</span>
              <span>&rdquo;) of the American Arbitration Association (&ldquo;</span>
              <span className='c1'>AAA</span>
              <span>
                &rdquo;) by an Arbitrator appointed in accordance with the said Rules. The place of arbitration shall be Atlanta, Georgia and judgment on the award rendered by the
                Arbitrator may be entered in any court with jurisdiction. The arbitration shall be conducted in the English language. The arbitration shall be conducted by one (1)
                neutral and impartial arbitrator (the &ldquo;
              </span>
              <span className='c1'>Arbitrator</span>
              <span className='c0'>
                &rdquo;). &nbsp;The Parties must agree to appoint a neutral Arbitrator, but if the Parties fail to reach agreement within ten (10) days, either Party may petition
                the AAA to select the Arbitrator of its choosing, and the AAA&rsquo;s decision in connection therewith shall be binding upon the Parties. The Arbitrator shall have
                the sole power to rule on matters of jurisdiction, arbitrability, timeliness of claims, issue preclusion, and to grant permanent equitable relief. Notwithstanding
                the foregoing, to the extent that it is necessary to prevent irreparable harm that may be caused to a Party by the breach of this Agreement, that Party will be
                entitled to equitable relief (including an injunction or preservation of evidence) in any court of law having proper jurisdiction, in addition to all other
                available remedies. The Parties agree that the prevailing Party in any arbitration action hereunder shall be entitled to receive, in addition to all other damages
                and awards, the costs incurred by such Party in conducting the arbitration, including reasonable attorneys&rsquo; fees and expenses, and arbitration costs. In
                addition to and not in limitation of the foregoing mandatory arbitration requirements, to the extent that it is necessary to prevent irreparable harm that may be
                caused to a Party by the breach of this Agreement, that Party will be entitled to equitable relief &ndash; including an injunction or preservation of evidence
                &ndash; in any court of law having proper jurisdiction, in addition to all other available remedies.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span className='c6'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>16.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;International Conventions</span>
              <span>.</span>
              <span className='c6'>&nbsp;</span>
              <span className='c0'>
                The Parties agree that the United Nations Convention on Contracts for the International Sale of Goods will not apply in any respect to this Agreement or the Parties
                hereunder.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span className='c6'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>16.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;No Conflicts</span>
              <span className='c0'>
                . Notwithstanding the content of any Customer purchase order or any other document or record, whether in writing or electronic, relating to the subject matter of
                this Agreement, the terms of the Agreement shall govern and any conflicting, inconsistent, or additional terms contained in such documents shall be null and void.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span className='c6'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>16.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Communications; Notices</span>
              <span className='c0'>
                . Any notice under this Agreement must be given in writing. Inclusivv may provide notice to the Customer via email or through the Customer&rsquo;s account.
                Inclusivv&rsquo; notices to the Customer will be deemed given upon the first business day after Inclusivv sends it. The Customer may provide notice to us by post to
                the main headquarters address of Inclusivv, Inc., Attn: Legal, which Inclusivv will provide to the Customer upon request. The Customer&rsquo;s notices to Inclusivv
                will be deemed given upon receipt by Inclusivv.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span className='c6'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>16.7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Assignment</span>
              <span className='c0'>
                . The Customer agrees that it will not assign this Agreement without the prior written consent of Inclusivv. Inclusivv may assign its rights and obligations under
                this Agreement (in whole or in part) without the Customer&rsquo;s consent. Any attempted assignment in violation of this Section will be void.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span className='c6'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>16.8&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Severability</span>
              <span className='c0'>
                . In case any one or more of the provisions of this Agreement should be invalid, illegal or unenforceable in any respect, the validity, legality and enforceability
                of the remaining provisions contained herein shall not in any way be affected or impaired thereby.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span className='c6'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>16.9&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Entire Agreement</span>
              <span className='c0'>
                . This Agreement constitutes the entire agreement between the Parties concerning the subject matter hereof and supersedes all written or oral prior agreements or
                understandings with respect thereto. All Schedules attached to the Agreement or subsequently added hereto by mutual consent of the Parties are incorporated into
                this Agreement for all purposes. In the event of a conflict between the Agreement, any Schedule, or any Order, the Agreement will control, unless the Schedule or
                Order expressly states that the Schedule or Order shall supersede the applicable provision of these Terms and Conditions. In the event of a conflict between any
                Schedule and any Order, the Schedule will control, unless the Order expressly states that the Order shall supersede the applicable provision of the Schedule.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span className='c6'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>16.10&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Waiver</span>
              <span className='c0'>
                . No failure or delay by Inclusivv to exercise any right or remedy provided under the Agreement or by law shall constitute a waiver of that or any other right or
                remedy, nor shall it prevent or restrict the further exercise of that or any other right or remedy. No single or partial exercise of such right or remedy shall
                prevent or restrict the further exercise of that or any other right or remedy.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span className='c6'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>16.11&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Modifications</span>
              <span>. This Agreement may be amended by Inclusivv as provided in </span>
              <span className='c7'>Section 1</span>
              <span>
                &nbsp;hereof. &nbsp;Furthermore, Inclusivv reserves the right, in its sole discretion, to make any changes to the Platform, &nbsp;Services, and any ancillary items
                or materials that it deems necessary or useful to: (i) maintain or enhance the quality or delivery of the Services or the Platform, (ii) the competitive strength of
                or market for the Services or Platform, (iii) the Services&rsquo; or Platform&rsquo;s cost efficiency or performance; or (iv) to comply with applicable law;{' '}
              </span>
              <span className='c15'>provided</span>
              <span className='c0'>&nbsp;that no such changes have the effect of materially degrading the functionality of the Services or Platform.</span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span className='c6'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>16.12&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Force Majeure</span>
              <span>.</span>
              <span className='c6'>&nbsp;</span>
              <span>
                Inclusivv shall not be liable for any failure or delay in the performance of any of its obligations (other than confidentiality obligations) if prevented from doing
                so by a cause or causes beyond its reasonable control (a &ldquo;
              </span>
              <span className='c1'>Force Majeure Event</span>
              <span className='c0'>
                &rdquo;). Without limiting the generality of the foregoing, Force Majeure Events include fires, floods, terrorism, strikes, blackouts, war, restraints of
                government, utility or communications failures or interruptions, failures of third party vendors, internet slow-downs or failures, or other causes that are beyond a
                Party&rsquo;s reasonable control. Failure to meet due dates or time schedules resulting from a Force Majeure Event will extend the due dates or time schedules for
                reasonable periods of time as determined by the Parties in good faith.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>16.13&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Relationship</span>
              <span className='c0'>
                . The relationship between the Parties created by this Agreement is that of independent contractors and not partners, joint venturers, or agents. Except as
                expressly agreed by the Parties, neither party will be deemed to be an employee, agent, partner, or legal representative of the other for any purpose and neither
                will have any right, power, or authority to create any obligation or responsibility on behalf of the other.
              </span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span className='c6'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c1'>16.14&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;No Third-Party Beneficiaries</span>
              <span>.</span>
              <span className='c6'>&nbsp;</span>
              <span className='c0'>The Agreement is personal to the Parties and no third parties shall be considered beneficiaries hereof, for any purposes.</span>
            </p>
            <p className='c2'>
              <span className='c9 c6'></span>
            </p>
            <br />
            <br />
            <p className='c13'>
              <span className='c6'>17.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className='c7 c6'>Definitions</span>
              <span className='c0'>. In addition to the terms otherwise defined in this Agreement or an Order, the following terms have the definitions below:</span>
            </p>
            <p className='c4'>
              <span className='c0'></span>
            </p>
            <p className='c12'>
              <span>&ldquo;</span>
              <span className='c1'>Additional Services</span>
              <span className='c0'>
                &rdquo; means services offered by Inclusivv that are different in scope to the Services, and which are provided in addition thereto, for separate consideration.
              </span>
            </p>
            <p className='c4'>
              <span className='c0'></span>
            </p>
            <p className='c12'>
              <span>&ldquo;</span>
              <span className='c1'>Conversations</span>
              <span className='c0'>
                &rdquo; means content, writings, and works of authorship that are provided by Inclusivv in connection with the Services and that are purposefully designed to begin,
                facilitate, and curate group conversations among Users.
              </span>
            </p>
            <p className='c4'>
              <span className='c0'></span>
            </p>
            <p className='c12'>
              <span>&ldquo;</span>
              <span className='c1'>Conversation Availability Date</span>
              <span className='c0'>
                &rdquo; means the date on which a specific Conversation becomes available to the Customer for its use, as indicated in the applicable Order.
              </span>
            </p>
            <p className='c4'>
              <span className='c0'></span>
            </p>
            <p className='c12'>
              <span>&ldquo;</span>
              <span className='c1'>Conversation Event</span>
              <span className='c0'>
                &rdquo; means a specific event, whether virtual or in-person, during which Users are invited to and participate in a specific Conversation to which the Customer has
                purchased access. Each Conversation Event takes place on a date and at a time selected by the Customer and/or Customer Users.
              </span>
            </p>
            <p className='c4'>
              <span className='c0'></span>
            </p>
            <p className='c12'>
              <span>&ldquo;</span>
              <span className='c1'>Conversation Term</span>
              <span className='c0'>
                &rdquo; means the period of time during which a specific Conversation is available for the Customer&rsquo;s use, as indicated in the applicable Order.
              </span>
            </p>
            <p className='c4'>
              <span className='c0'></span>
            </p>
            <p className='c12'>
              <span>&ldquo;</span>
              <span className='c1'>Customer</span>
              <span className='c0'>&rdquo; has the meaning set forth in the preamble.</span>
            </p>
            <p className='c4'>
              <span className='c0'></span>
            </p>
            <p className='c12'>
              <span>&ldquo;</span>
              <span className='c1'>Customer Users</span>
              <span className='c0'>
                &rdquo; means all of the Customer&rsquo;s officers, employees, agents, and representatives who are natural persons and who are expressly authorized by the Customer
                to access and use the Platform and the Services.
              </span>
            </p>
            <p className='c4'>
              <span className='c0'></span>
            </p>
            <p className='c12'>
              <span>&ldquo;</span>
              <span className='c1'>Customer Data</span>
              <span className='c0'>
                &rdquo; means any content, data, information, or materials that the Customer or Customer Users submit or upload to, or store within, the Platform including, but not
                limited to, Personal Data. For clarity, the term &ldquo;Customer Data&rdquo; expressly excludes Participant Data.
              </span>
            </p>
            <p className='c4'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&ldquo;</span>
              <span className='c1'>Derivative Works</span>
              <span>
                &rdquo; means any suggestions, contributions, enhancements, improvements, additions, modifications, of or to the Platform, Conversations, Materials, or to any
                Intellectual Property Rights contained therein.
              </span>
            </p>
            <p className='c4'>
              <span className='c0'></span>
            </p>
            <p className='c12'>
              <span>&ldquo;</span>
              <span className='c1'>Documentation</span>
              <span className='c0'>
                &rdquo; means the user documentation and any other operating, training, and reference manuals relating to the use of the Platform, as supplied by Inclusivv to the
                Customer, as well as any derivative works thereof.
              </span>
            </p>
            <p className='c4'>
              <span className='c0'></span>
            </p>
            <p className='c12'>
              <span>&ldquo;</span>
              <span className='c1'>Effective Date</span>
              <span>&rdquo;</span>
              <span className='c6'>&nbsp;</span>
              <span className='c0'>means the date on which the Agreement commences, as indicated in the first Order.</span>
            </p>
            <p className='c4'>
              <span className='c0'></span>
            </p>
            <p className='c12'>
              <span>&ldquo;</span>
              <span className='c1'>Evaluation</span>
              <span className='c0'>
                &rdquo; means, if made available to the Customer by Inclusivv, the Customer&rsquo;s right to access and utilize the Services, Additional Services, and the Platform
                for the purpose of evaluating the same. The terms and conditions of this Agreement shall apply to the Evaluation. The availability of an Evaluation, specific terms,
                and Fees therefor, if an Evaluation made available to the Customer, are set forth in the first Order.
              </span>
            </p>
            <p className='c4'>
              <span className='c0'></span>
            </p>
            <p className='c12'>
              <span>&ldquo;</span>
              <span className='c1'>Evaluation Term</span>
              <span className='c0'>
                &rdquo; means the period of time, if any, for which an Evaluation is in effect. The duration of an Evaluation, if an Evaluation made available to the Customer, is
                forth in the first Order. Upon completion of the Evaluation Term, the Initial Term shall automatically commence without any further action required of the parties
                unless this Agreement is earlier terminated in accordance herewith.
              </span>
            </p>
            <p className='c4'>
              <span className='c0'></span>
            </p>
            <p className='c12'>
              <span>&ldquo;</span>
              <span className='c1'>Facilitation Services</span>
              <span className='c0'>&rdquo; means on-site (in-person) or virtual facilitation services at a Conversation Event provided by persons engaged by Inclusivv.</span>
            </p>
            <p className='c4'>
              <span className='c0'></span>
            </p>
            <p className='c12'>
              <span>&ldquo;</span>
              <span className='c1'>Initial Term</span>
              <span>&rdquo;</span>
              <span className='c6'>&nbsp;</span>
              <span>means the first term of this Agreement, as indicated in the first Order.</span>
            </p>
            <p className='c4'>
              <span className='c0'></span>
            </p>
            <p className='c12'>
              <span>&ldquo;</span>
              <span className='c1'>Intellectual Property Rights</span>
              <span className='c0'>
                &rdquo; means all industrial and other intellectual property rights comprising or relating to: &nbsp;(a) patents; (b) trademarks; (c) internet domain names, whether
                or not trademarks, registered by any authorized private registrar or governmental authority, web addresses, web pages, website and URLs; (d) works of authorship,
                expressions, designs and design registrations, whether or not copyrightable, including copyrights and copyrightable works, software and firmware, application
                programming interfaces, architecture, files, records, schematics, data, data files, and databases and other specifications and documentation; (e) trade secrets; (f)
                semiconductor chips, mask works and the like; and (g) all industrial and other intellectual property rights, and all rights, interests and protections that are
                associated with, equivalent or similar to, or required for the exercise of, any of the foregoing, however arising, in each case whether registered or unregistered
                and including all registrations and applications for, and renewals or extensions of, these rights or forms of protection under the laws of any jurisdiction
                throughout in any part of the world.
              </span>
            </p>
            <p className='c4'>
              <span className='c0'></span>
            </p>
            <p className='c3'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&ldquo;</span>
              <span className='c1'>Marks</span>
              <span>&rdquo; means service marks, trademarks, trade names, logos, and any modifications to the foregoing.</span>
            </p>
            <p className='c4'>
              <span className='c0'></span>
            </p>
            <p className='c12'>
              <span>&ldquo;</span>
              <span className='c1'>Materials</span>
              <span className='c0'>
                &rdquo; means all data, images, documentation, audio, video, audiovisual content, artistic works, writings in any media, and any other content provided by Inclusivv
                to the Customer in connection with Conversations under this Agreement.
              </span>
            </p>
            <p className='c10'>
              <span className='c0'></span>
            </p>
            <p className='c12'>
              <span>&ldquo;</span>
              <span className='c1'>Order</span>
              <span className='c0'>
                &rdquo; means the ordering document in the form of an order form, specifying the Services to be provided by Inclusivv to the Customer, including any addenda and
                supplements thereto, and the material terms related thereto. An Order may be a web form hosted by Inclusivv that is accepted by the Customer through its agents.
              </span>
            </p>
            <p className='c4'>
              <span className='c0'></span>
            </p>
            <p className='c12'>
              <span>&ldquo;</span>
              <span className='c1'>Participant</span>
              <span className='c0'>
                &rdquo; means an individual person who participates in one or more Conversations. As used in this Agreement, the term &ldquo;Participant&rdquo; may include Users.
              </span>
            </p>
            <p className='c4'>
              <span className='c0'></span>
            </p>
            <p className='c12'>
              <span>&ldquo;</span>
              <span className='c1'>Participant Data</span>
              <span className='c0'>
                &rdquo; means any content, data, information, or materials of or about individuals, including any Third Party Users, who sign up for or attend Conversations or who
                sign up for, access, or use the Platform.
              </span>
            </p>
            <p className='c4'>
              <span className='c0'></span>
            </p>
            <p className='c12'>
              <span>&ldquo;</span>
              <span className='c1'>Personal Data</span>
              <span className='c0'>
                &rdquo; means all information relating to a person that identifies such person or could reasonably be used to identify such person, including but not limited to,
                first and last name, home address, billing address, or other physical address, email address, telephone number, and Sensitive Data, if any, sent to Inclusivv via
                the Platform or in connection with the Services.
              </span>
            </p>
            <p className='c4'>
              <span className='c0'></span>
            </p>
            <p className='c12'>
              <span>&ldquo;</span>
              <span className='c1'>Platform</span>
              <span className='c0'>&rdquo; means the online software platform provided by Inclusivv in connection with the Services.</span>
            </p>
            <p className='c4'>
              <span className='c0'></span>
            </p>
            <p className='c12'>
              <span>&ldquo;</span>
              <span className='c1'>Renewal Term</span>
              <span>&rdquo;</span>
              <span className='c6'>&nbsp;</span>
              <span>means each term of the Agreement following the completion of the Initial Term, as indicated in the first Order.</span>
            </p>
            <p className='c4'>
              <span className='c9 c6'></span>
            </p>
            <p className='c12'>
              <span>&ldquo;</span>
              <span className='c1'>Services</span>
              <span className='c0'>
                &rdquo; means all services provided by Inclusivv to the Customer under this Agreement including but not limited to access to, and use of, the Conversations and
                Materials, as are more specifically described in an Order.
              </span>
            </p>
            <p className='c4'>
              <span className='c0'></span>
            </p>
            <p className='c12'>
              <span>&ldquo;</span>
              <span className='c1'>Sensitive Data</span>
              <span className='c0'>
                &rdquo; means any Personal Data that requires a heightened degree of protection by applicable law, including but not limited to, (a) social security number,
                passport number, driver&rsquo;s license number, or similar identifier (or any portion thereof); (b) financial information or credit or debit card number (other than
                the truncated (last four digits) of a credit or debit card); (c) genetic, biometric or health information; (d) racial, ethnic, political or religious affiliation;
                (e) trade union membership; or (f) information about a person&rsquo;s sexual life or sexual orientation.
              </span>
            </p>
            <p className='c4'>
              <span className='c0'></span>
            </p>
            <p className='c12'>
              <span>&ldquo;</span>
              <span className='c1'>Term</span>
              <span className='c0'>&rdquo; means, collectively, the Evaluation Term, Initial Term, and each Renewal Term.</span>
            </p>
            <p className='c4'>
              <span className='c0'></span>
            </p>
            <p className='c12'>
              <span>&ldquo;</span>
              <span className='c1'>Territory</span>
              <span className='c0'>
                &rdquo; means the specific geographic area, if applicable, in which the Customer may use the Services. The Territory is set forth in the Order.
              </span>
            </p>
            <p className='c4'>
              <span className='c0'></span>
            </p>
            <p className='c12'>
              <span>&ldquo;</span>
              <span className='c1'>Third Party Users</span>
              <span className='c0'>
                &rdquo; means natural persons who are not Customer Users, and who are expressly authorized by the Customer to access and use the Platform and the Services.
              </span>
            </p>
            <p className='c4'>
              <span className='c0'></span>
            </p>
            <p className='c12'>
              <span>&ldquo;</span>
              <span className='c1'>Users</span>
              <span className='c0'>&rdquo; means, collectively, Customer Users and Third Party Users.</span>
            </p>
            <p className='c4'>
              <span className='c0'></span>
            </p>
            <p className='c2'>
              <span className='c9 c6'></span>
            </p>
            <p className='c2'>
              <span className='c9 c6'></span>
            </p>
            <p className='c2'>
              <span className='c9 c6'></span>
            </p>
            <p className='c2'>
              <span className='c9 c6'></span>
            </p>
            <p className='c2'>
              <span className='c9 c6'></span>
            </p>
            <p className='c2'>
              <span className='c9 c6'></span>
            </p>
            <p className='c2'>
              <span className='c6 c9'></span>
            </p>
            <p className='c2'>
              <span className='c9 c6'></span>
            </p>
            <p className='c2'>
              <span className='c9 c6'></span>
            </p>
            <p className='c2'>
              <span className='c9 c6'></span>
            </p>
            <p className='c2'>
              <span className='c9 c6'></span>
            </p>
            <p className='c2'>
              <span className='c9 c6'></span>
            </p>
            <p className='c2'>
              <span className='c9 c6'></span>
            </p>
            <p className='c2'>
              <span className='c9 c6'></span>
            </p>
            <p className='c2'>
              <span className='c9 c6'></span>
            </p>
            <p className='c2'>
              <span className='c9 c6'></span>
            </p>
            <p className='c2'>
              <span className='c9 c6'></span>
            </p>
            <p className='c2'>
              <span className='c0'></span>
            </p>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default TermsOfService
