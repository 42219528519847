import * as types from '../actions/actionTypes'
import { assign } from 'lodash'
import initialState from './initialState'

export const topicsPartners = (state = initialState.topicsPartners, action) => {
  switch (action.type) {
    case types.LOAD_CONVERSATIONS_PARTNERS_SUCCESS:
      return assign({}, state, {
        error: null,
        loading: false,
        list: assign({}, action.topicsPartners)
      })

    case types.CREATE_CONVERSATIONS_PARTNERS_SUCCESS:
      return assign({}, state, {
        error: null,
        loading: false,
        list: assign({}, state.list, action.topicsPartners)
      })

    default:
      return state
  }
}

export default topicsPartners
