import React from 'react'
import { inject, observer } from 'mobx-react'
import { flow } from 'lodash'
import { IStore } from '../../store'
import { IDialog } from '../../store/models/Dialog'
import Dialogs from '../../components/Dialogs'

const DialogProvider = ({ children, dialog, showDialog, closeDialog }: Props & InjectedProps) => {
  return (
    <DialogContext.Provider value={{ showDialog, closeDialog }}>
      {children}
      {dialog && <Dialogs dialog={dialog} />}
    </DialogContext.Provider>
  )
}

interface Props {
  children?: React.ReactNode | React.ReactNode[]
}
interface InjectedProps {
  dialog: IDialog | false
  showDialog(name: string, data?: any): void
  closeDialog(name: string): void
}

export const DialogContext = React.createContext({
  showDialog: (name: string, data?: any) => {
    // console.log({ name, data })
  },
  closeDialog: (name: string) => {
    // console.log({ name })
  }
})

export default flow(
  observer,
  inject<InjectedProps, Props>(({ mst }: { mst: IStore }, ownProps) => {
    const { dialogs } = mst
    return {
      dialog: dialogs.list.length > 0 && dialogs.list[0],
      showDialog: dialogs.showDialog,
      closeDialog: dialogs.closeDialog
    }
  })
)(DialogProvider)
