import { chunk, map } from 'lodash'

function coordinateDecorator(coordsArray) {
  if (coordsArray.length === 2)
    return {
      latitude: coordsArray[0],
      longitude: coordsArray[1]
    }

  const coordPairs = chunk(coordsArray, 2)

  return map(coordPairs, ([latitude, longitude]) => {
    return {
      latitude,
      longitude
    }
  })
}

export default coordinateDecorator
