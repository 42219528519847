import * as types from '../actions/actionTypes'
import { assign } from 'lodash'
import initialState from './initialState'

export const partnerStats = (state = initialState.partnerStats, action) => {
  switch (action.type) {
    case types.LOAD_PARTNER_STATS_SUCCESS:
      return assign({}, state, {
        error: null,
        loading: false,
        list: assign({}, action.partnerStats)
      })

    case types.PARTNER_STATS_LOADING:
      return assign({}, state, {
        error: null,
        loading: true
      })

    default:
      return state
  }
}

export default partnerStats
