import React from 'react'

import Dialog from '../DialogWrapper'
import { flow } from 'lodash'
import { observer } from 'mobx-react'
import { IDialog } from '../../store/models/Dialog'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import StoryShow from './StoryShow'
import ShareStory from './ShareStory'

interface Props {
  dialog: IDialog
}

const StoryShowDialog = ({ dialog }: Props) => {
  const classes = Styles()
  const { story } = dialog.data as any

  return (
    <Dialog dialog={dialog} name='showStory' type='noPadding' closeIcon={true} closeClass={classes.closeIcon}>
      <StoryShow story={story} />
      <div style={{ padding: '0 3em 1em 3em' }}>
        <ShareStory story={story} color='#898A8E' noLabel={true} spacing={1} iconClassNames={classes.shareIcon} />
      </div>
    </Dialog>
  )
}

const Styles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitle: { backgroundColor: '#ffffff', color: '#000000' },
    subHeaderContainer: {
      textAlign: 'center',
      margin: '0 auto',
      backgroundColor: '#F6F7F9',
      minHeight: '10em',
      padding: '1em 0'
    },

    content: {
      margin: '1em auto',
      padding: '1em',
      maxWidth: '25em'
    },

    closeIcon: {
      color: '#818285'
    },
    shareIcon: {
      fontSize: '1em'
    }
  })
)

export default flow(observer)(StoryShowDialog)
