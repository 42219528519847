import { types, getRoot } from 'mobx-state-tree'

export const Alertable = types
  .model({})
  .views((self) => ({
    get alertMessages() {
      const { alertMessages } = getRoot(self)
      return alertMessages
    }
  }))
  .actions((self) => ({
    showAlert(message: string) {
      self.alertMessages?.showAlert(message)
    }
  }))
