import React from 'react'
import { Card, Typography } from '@material-ui/core'
import { observer } from 'mobx-react'
import moment from 'moment'
import { IMembershipUser } from '../../../store/models/User/MembershipUser'
import { ITrack } from '../../../store'

interface Props {
  membership: IMembershipUser
  track: ITrack
}

export const MyMembershipPlan = observer(({ membership, track }: Props) => {
  return (
    <Card style={{ marginTop: '1rem', padding: '1rem' }}>
      <Typography variant='subtitle2' className='reg-bottom'>
        {membership.dealName}
      </Typography>
      <Typography variant='body2' className='reg-bottom'>
        Journey: {track.name}
      </Typography>
      <Typography variant='body2' className='reg-bottom'>
        12 month access to attend a monthly conversation
      </Typography>
      {membership.seatLimit && membership.seatLimit > 1 && (
        <Typography variant='body2' className='reg-bottom'>
          Seats: {membership.seatLimit} ({membership.membersCount} active)
        </Typography>
      )}
      <Typography variant='body2' className='reg-bottom'>
        Renewal Date: {moment(membership.endsAt).format('MM/DD/yyyy')}
      </Typography>
      <Typography variant='body2' className='reg-bottom'>
        Payment Date: {moment(membership.startsAt).format('MM/DD/yyyy')}
      </Typography>
      <Typography variant='body2' className='reg-bottom'>
        Total Payment: {`$${membership.price}`}
      </Typography>
      <Typography variant='body2' className='reg-bottom'>
        Contact Info: {membership.email}
      </Typography>
    </Card>
  )
})
export default MyMembershipPlan
