import { types, Instance } from 'mobx-state-tree'
import { LoadableStatus } from './helpers/LoadableStatus'
import PartnerStatsApi from '../../api/partners/StatsApi'

export const PartnersStats = types
  .compose(
    'PartnersStats',
    LoadableStatus,
    types.model({
      topics_total: types.optional(types.number, 0),
      conversations_total: types.optional(types.number, 0),
      hosts_total: types.optional(types.number, 0),
      users_total: types.optional(types.number, 0),
      guests_total: types.optional(types.number, 0),
      uniq_guests_total: types.optional(types.number, 0),
      upcoming_conversations: types.optional(types.number, 0),
      past_conversations: types.optional(types.number, 0)
    })
  )
  .actions((self) => ({
    setStats: ({ topics_total, conversations_total, hosts_total, guests_total, uniq_guests_total, upcoming_conversations, past_conversations, users_total }: any) => {
      self.topics_total = topics_total
      self.conversations_total = conversations_total
      self.hosts_total = hosts_total
      self.guests_total = guests_total
      self.users_total = users_total
      self.upcoming_conversations = upcoming_conversations
      self.past_conversations = past_conversations
      self.uniq_guests_total = uniq_guests_total
    }
  }))
  .actions((self) => ({
    loadStats: (partner_ids, { reload = false } = {}) => {
      if (self.isLoading('partner_stats') || (!reload && self.loadedIds.includes('partner_stats'))) return

      self.startLoading('partner_stats')

      return PartnerStatsApi.getStatsForPartnerIds(partner_ids).then(({ response: { ok }, json }) => {
        self.stopLoading('partner_stats')
        if (ok) {
          self.setStats(json)
        }
        self.stopLoading('partner_stats')
      })
    }
  }))

export interface IPartnersStats extends Instance<typeof PartnersStats> {}
export default PartnersStats
