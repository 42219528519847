import React from 'react'
import InvitationList from './partials/InvitationList'

const Pending = ({ partner }) => {
  return (
    <>
      <h4>Invited</h4>
      <InvitationList partner={partner} />
    </>
  )
}

export default Pending
