import React, { Component } from 'react'
import FontIcon from '@material-ui/core/Icon'
import { DialogTitle } from '@material-ui/core'

import withTranslation from '../../hocs/withTranslation'
import Dialog from '../../DialogWrapper'

class SpecificConversationInvitationRequestSentDialog extends Component {
  render() {
    const { t, dialog } = this.props

    return (
      <Dialog dialog={dialog} name='specificConversationInvitationRequestSentDialog' type='noPadding'>
        <div>
          <DialogTitle disableTypography>{t('convos.thanks')}</DialogTitle>

          <section className='pane reg-pane'>
            <div className='row reg-bottom'>
              <div className='col-xs-12 center-xs'>
                <div className='box'>
                  <FontIcon className='fa fa-paper-plane-o header-36' />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-xs-12 center-xs'>
                <h1 className='flat header-24'>{t('convos.reqSent')}</h1>
              </div>
            </div>
          </section>

          <section className='pane minimal-grey-pane'>
            <div className='row center-xs'>
              <div className='col-xs-12'>
                <p>{t('convos.acceptNotify')}</p>
              </div>
            </div>
          </section>
        </div>
      </Dialog>
    )
  }
}
export default withTranslation(SpecificConversationInvitationRequestSentDialog)
