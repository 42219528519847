import { types, Instance } from 'mobx-state-tree'
import InviteAdminApi from '../../../api/partners/Admins/inviteAdminApi'
import PartnerAdminsApi from '../../../api/partners/Admins/PartnerAdminsApi'
import PartnerBase from './PartnerBase'
// import { LoadableStatus } from '../helpers'
import { LoadableStatus, Alertable } from '../helpers'
import { UserBase } from '../User'

export const PartnerAdmin = types.compose('PartnerAdmin', UserBase, types.model({}))

export const PartnerAdmins = types
  .compose(
    'PartnerAdmins',
    PartnerBase,
    LoadableStatus,
    Alertable,
    types.model({
      admins: types.array(PartnerAdmin)
    })
  )
  .actions((self) => ({
    acceptInvitation: (token: string, loadPartner: any) => {
      return InviteAdminApi.acceptInvitation({ partnerId: self.id, token }).then(() => {
        self.showAlert('Invitation accepted')
        // dispatch(showAlertMessage({ message: 'Invitation accepted' }))
        // loadPartner && loadPartner()
      })
    },

    setAdmins: (val: any) => {
      self.admins.replace(val)
    },
    removeAdminFromList: (userId: number) => {
      self.admins.replace(self.admins.filter((admin) => admin.id !== userId))
    }
  }))
  .actions((self) => ({
    loadAdmins: () => {
      const listName = 'admins'
      if (self.isLoading(listName)) {
        return
      }

      self.startLoading(listName)
      return PartnerAdminsApi.getAll({ partnerId: self.id, limit: 50, offset: 0 })
        .then(({ response: { ok }, json }) => {
          self.stopLoading(listName)
          if (ok) {
            self.setAdmins(json)
          }
        })
        .catch(() => {
          self.stopLoading(listName)
        })
    },
    deleteAdmin: (userId) => {
      return PartnerAdminsApi.deleteAdmin({ partnerId: self.id, userId }).then(({ response: { ok }, json }) => {
        if (ok) {
          self.removeAdminFromList(userId)
        }
      })
    }
  }))

export interface IPartnerAdmins extends Instance<typeof PartnerAdmins> {}
export default PartnerAdmins
