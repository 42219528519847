import React from 'react'
import { Button } from '@material-ui/core'
import SummaryContainer from '../partials/SummaryContainer'
import PartnersTable from './PartnersTable'
import { observer } from 'mobx-react'
import { inject } from 'mobx-react'
import { flow } from 'lodash'
import { IStore } from '../../../../store/models'
import { IUser } from '../../../../store/models/User'
import { RouteComponentProps, withRouter } from 'react-router'

interface Props {
  user?: IUser
}

const PartnersSummary = ({ user, history }: Props & RouteComponentProps) => {
  return (
    <div>
      <SummaryContainer
        title={'Customers'}
        topRight={
          user?.isSuperAdmin() ? (
            <Button onClick={() => history.push('/admin/partners/new')} variant='contained' startIcon={<i className='far fa-plus-circle'></i>}>
              New Customer
            </Button>
          ) : undefined
        }
      >
        <PartnersTable />
      </SummaryContainer>
    </div>
  )
}

export default flow(
  withRouter,
  observer,
  inject<FIXME, FIXME>(
    ({
      mst: {
        auth: { user }
      }
    }: {
      mst: IStore
    }) => {
      return {
        user
      }
    }
  )
)(PartnersSummary)
