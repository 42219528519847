import { post, get } from './utils'

class surveyAnswersApi {
  static create(data) {
    return post(`/survey_answers`, {
      body: JSON.stringify({ survey_question: data })
    })
  }
  static bulkCreate(survey_answers) {
    return post(`/survey_answers/bulk_create`, {
      body: JSON.stringify({ survey_answers })
    })
  }
  static getAll() {
    return get(`/nps_answers`)
  }
}

export default surveyAnswersApi
