import React from 'react'
import { Button } from '@material-ui/core'
import SummaryContainer from '../partials/SummaryContainer'
import StoriesTable from './StoriesTable'
import { observer } from 'mobx-react'
import { inject } from 'mobx-react'
import { flow } from 'lodash'
import { IStore } from '../../../../store/models'
import { IUser } from '../../../../store/models/User'
import { RouteComponentProps, withRouter } from 'react-router'

interface Props {
  user?: IUser
}

const StoriesSummary = ({ user, history }: Props & RouteComponentProps) => {
  return (
    <div>
      <SummaryContainer title='Stories'>
        <StoriesTable />
      </SummaryContainer>
    </div>
  )
}

export default flow(
  withRouter,
  observer,
  inject<FIXME, FIXME>(
    ({
      mst: {
        auth: { user }
      }
    }: {
      mst: IStore
    }) => {
      return {
        user
      }
    }
  )
)(StoriesSummary)
