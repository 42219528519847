import React from 'react'
import { Button, Grid, IconButton, Tooltip } from '@material-ui/core'
import { observer } from 'mobx-react'
import { flow } from 'lodash'
import { IUser } from '../../../store/models/User'
import { IMembershipUser } from '../../../store/models/User/MembershipUser'
import { useAuth } from '../../Providers'

interface Props {
  member: IMembershipUser
}

const MembersActions = ({ member }: Props) => {
  const { user } = useAuth()
  const isOwner = member.role === 'owner'
  const isMine = member?.user?.id === user?.id || isOwner
  return (
    <Grid container direction='row' justify='space-around' alignItems='center'>
      <Tooltip title='Remove member'>
        <Button
          style={!isMine ? { color: '#EE413E' } : undefined}
          onClick={() => {
            member.removeMembership()
          }}
          disabled={isMine}
        >
          Remove
        </Button>
      </Tooltip>
    </Grid>
  )
}

export default flow(observer)(MembersActions)
