import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useUserTracks } from '../../Providers/UserTracksProvider'

import { useParamPartner } from '../../Providers/ParamPartnerProvider'
import { useAuth } from '../../Providers'
import Dialog from '../../DialogWrapper'
import LoadingAnimation from '../../shared/LoadingAnimation'

export const MembershipManager = () => {
  const { token, action } = useParams<{ token?: string; action?: string }>()
  const { user, loaded, showSignup } = useAuth()
  const [params, setparams] = useState<{ token?: string; action?: string }>()
  const history = useHistory()
  const { partner } = useParamPartner()
  const { tracks, joinMembership, reloadTracks, loadHsMembership } = useUserTracks()
  const [showDialog, setShowDialog] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [conversations, setConversations] = useState<any[]>([])

  useEffect(() => {
    if (!user && loaded && token) {
      showSignup()
    }
  }, [user, loaded, showSignup, token])

  useEffect(() => {
    if (user && partner && token && tracks && !processing) {
      setProcessing(true)
      if (token === 'new' && partner && user) {
        loadHsMembership()
          .then(user.reloadProfile)
          .then(() => history.push(`/${partner?.slug}/journey`))
      } else {
        setProcessing(true)
        setparams({ token, action })
        setShowDialog(true)
        joinMembership(token, action).then((membership) => {
          if (membership) {
            reloadTracks()
            setShowDialog(true)
            setShowSuccess(true)
            if (membership?.user_conversations) {
              setConversations(Array.isArray(membership?.user_conversations) ? membership?.user_conversations : [membership?.user_conversations])
            }
          } else {
            setShowDialog(false)
            history.replace(`/${partner?.slug}`)
          }
        })
      }
    }
  }, [user, partner, token, action, history, tracks, joinMembership, reloadTracks, setShowDialog, processing, loadHsMembership, loaded, showSignup])

  return (
    <>
      <Dialog
        open={showDialog}
        dialog={{
          closeDialog: () => {
            history.replace(`/${partner?.slug}`)
            setShowDialog(false)
            user?.reloadProfile()
          }
        }}
        title={showSuccess ? 'Membership' : 'Loading Membership'}
      >
        <div className='align-center'>
          <div className='row center-xs xtra-fat-top fat-bottom'>
            <div className='col-xs-12'>
              {conversations.length > 0 ? (
                <div>{conversations.length === 1 ? 'Joined a Conversation!' : 'Joined All the Conversations!'}</div>
              ) : showSuccess ? (
                <div>You are now a member!</div>
              ) : (
                <></>
              )}
              {!showSuccess && <LoadingAnimation />}
            </div>
          </div>
        </div>
      </Dialog>
    </>
  )
}
