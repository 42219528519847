import React, { Component } from 'react'
import { connect } from 'react-redux'
import FontIcon from '@material-ui/core/Icon'

import Dialog from '../DialogWrapper'
import withTranslation from '../hocs/withTranslation'
import { DialogTitle } from '@material-ui/core'

class ConversationInvitationRequestAcceptedDialog extends Component {
  render() {
    const { t, dialog } = this.props

    return (
      <Dialog dialog={dialog} name='invite_accepted'>
        <div>
          <DialogTitle disableTypography>{t('conversation.acceptRSVPHeader')}</DialogTitle>

          <section className='pane reg-pane'>
            <div className='row reg-bottom'>
              <div className='col-xs-12 center-xs'>
                <div className='box'>
                  <FontIcon className='fa fa-paper-plane-o header-36' />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-xs-12 center-xs no-padding'>
                <h1 className='flat header-24'>{t('conversation.acceptRSVPmsg')}</h1>
              </div>
            </div>
          </section>
        </div>
      </Dialog>
    )
  }
}

export default connect(null)(withTranslation(ConversationInvitationRequestAcceptedDialog))
