import { normalize } from 'normalizr'
import { SubmissionError } from 'redux-form'
import { assign, isEmpty, get } from 'lodash'
import * as types from './actionTypes'
import partnersApi from '../api/PartnersApi'
import partnerStatsApi from '../api/partners/StatsApi'
import { showAlertMessage } from './alertMessageActions'
import { history } from '../components/browserHistory'
import { partnerSchema, partnersSchema } from '../normalizers/partnerNormalizers.js'
import RegionalPartnersApi from '../api/RegionalPartnersApi'
import { getRegionalRecordByArea } from '../regionalRecords'

export const Loading = () => ({
  type: types.PARTNERS_LOADING
})

export const loadPartnersSuccess = (json) => ({
  type: types.LOAD_PARTNERS_SUCCESS,
  ...json
})

export const loadPartnersFailed = (json) => ({
  type: types.LOAD_PARTNERS_FAILED,
  data: json
})

export const loadPartnerSuccess = (json) => ({
  type: types.LOAD_PARTNER_SUCCESS,
  ...json
})

export const LoadingPartnerStats = () => ({
  type: types.PARTNER_STATS_LOADING
})

export const loadPartnerStatsSuccess = (json) => ({
  type: types.LOAD_PARTNER_STATS_SUCCESS,
  ...json
})

const showAlert = (dispatch, message) => dispatch(showAlertMessage({ message }))

export function loadPartners(args = {}) {
  return function (dispatch) {
    dispatch(Loading())

    return partnersApi
      .getAll(args)
      .then((res) => {
        const { ok, status, statusText } = res.response

        if (ok) {
          const flatData = normalize(res.json, partnersSchema).entities

          dispatch(loadPartnersSuccess(flatData))
        } else {
          dispatch(loadPartnersFailed({ status, statusText }))
        }
      })
      .catch((error) => {
        throw error
      })
  }
}

export function loadPartner(slug) {
  return function (dispatch) {
    dispatch(Loading())

    return partnersApi
      .get(slug)
      .then((res) => {
        const { ok, status, statusText } = res.response

        if (ok) {
          const flatData = normalize(res.json, partnerSchema).entities

          dispatch(loadPartnerSuccess(flatData))
        } else {
          dispatch(loadPartnersFailed({ status, statusText }))
        }
      })
      .catch((error) => {
        throw error
      })
  }
}

export function createPartner(formData) {
  return function (dispatch) {
    return partnersApi
      .create(formData)
      .then((response) => {
        if (response.response.ok) {
          const { slug } = response.json

          history.push(`/admin/partners/${slug}/edit`)

          showAlert(dispatch, 'Partner successfully created')
        } else {
          throw new SubmissionError(response.json)
        }
      })
      .catch((error) => {
        throw error
      })
  }
}

export function updatePartner(formData) {
  return function (dispatch) {
    return partnersApi
      .update(formData)
      .then((response) => {
        if (response.response.ok) {
          const { slug } = response.json

          // history.push(`/partners/${slug}`)
          window?.location?.reload()

          showAlert(dispatch, 'Partner successfully updated')
        } else {
          throw new SubmissionError(response.json)
        }
      })
      .catch((error) => {
        throw error
      })
  }
}

export function loadPartnerStats(slug) {
  return function (dispatch) {
    dispatch(LoadingPartnerStats())

    return partnerStatsApi
      .getAll(slug)
      .then((res) => {
        const { ok } = res.response

        if (ok) {
          let data = assign({}, { partnerStats: res.json })
          dispatch(loadPartnerStatsSuccess(data))
        }
      })
      .catch((error) => {
        throw error
      })
  }
}

export function redirectToPartner(data) {
  return (dispatch, getState) => {
    const { item: userCoords } = getState().userCoords

    const defaultSlug = 'uk'

    if (isEmpty(userCoords)) return history.push(`/${defaultSlug}`) // no user coords, go to default

    return RegionalPartnersApi.getAll(data.siteId)
      .then((res) => {
        const { ok } = res.response

        if (ok) {
          let noLoad = false
          let partner = getRegionalRecordByArea(res.json, userCoords)

          if (partner) {
            const flatData = normalize(partner, partnerSchema).entities
            dispatch(loadPartnerSuccess(flatData))
            noLoad = true
          }

          let slug = get(partner, 'slug', defaultSlug)

          history.push({
            pathname: `/${slug}`,
            state: { noLoad }
          })
        }
      })
      .catch((error) => {
        throw error
      })
  }
}
