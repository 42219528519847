import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as passwordResetsActions from '../../actions/passwordResetsActions'
import CreatePasswordResetsForm from './CreateForm'
import { getCreatePasswordResetProps } from '../../getters/passwordResetsGetters'
import withTranslation, { WithTranslation } from '../hocs/withTranslation'
import { observer } from 'mobx-react'
import { flow } from 'lodash'

const CreatePasswordResetContainer = ({ t }: WithTranslation) => {
  const [isOpen, setisOpen] = useState(false)

  const handleOpen = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setisOpen(true)
  }

  if (!isOpen) {
    return (
      <div className='reg-top header-14 align-center'>
        <a onClick={handleOpen}>{t('auth.forgotPassword')}</a>
      </div>
    )
  }

  return (
    <div>
      <CreatePasswordResetsForm />
    </div>
  )
}

export default flow(observer, withTranslation)(CreatePasswordResetContainer)
