import React from 'react'
import { flow, sortBy } from 'lodash'
import TopicNotificationsForm from './Form'
import TopicNotificationsContentForm from './ContentForm'
import { Prompt, withRouter } from 'react-router'
import { inject } from 'mobx-react'
import { observer } from 'mobx-react'
import { IStore } from '../../store/models'
import { ITopicNotification } from '../../store/models/Topic/TopicNotifications'
import { UNSAVED_FORM_WARNING } from '../utils/constants'
import { Accordion, AccordionDetails, AccordionSummary, Chip, CircularProgress, createStyles, Icon, makeStyles, Popover, Theme, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import palette from '../../themes/palette'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular
    },
    chip: { borderRadius: 11 },
    activeChip: { color: palette.darkgrey, backgroundColor: 'rgba(0, 126, 89, 0.2)', borderRadius: 11 },
    accordionDetail: { display: 'block' },
    accordionSummary: { display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', color: palette.darkestgrey },
    popoverContent: { maxWidth: '12rem', margin: '.5rem', fontSize: '12px', lineHeight: '15px' }
  })
)

const TopicsNotificationsEdit = ({ topic, contents, slug, notifications_during, notifications_post }: FIXME) => {
  const classes = useStyles()
  const status = `${contents.filter((n) => n.active).length}/${contents.length} Active`
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <div className={classes.root}>
      <Prompt when={!![...notifications_during, ...notifications_post].find((a) => a.dirty) || !!contents.find((a) => a.dirty)} message={UNSAVED_FORM_WARNING} />
      <Typography variant='body1' className='header-16 fat-bottom' style={{ color: palette.darkestgrey }}>
        Create and activate emails sent to guests and hosts before, during, and after the conversation. Style your text, add links, and include other system data using Dynamic
        Tags.
        <Icon
          aria-describedby={id}
          className={'fas fa-question-circle'}
          fontSize='inherit'
          color={open ? 'primary' : undefined}
          style={{ ...(open ? {} : { color: '#6D6E71' }) }}
          onClick={handleClick}
        />
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
        >
          <Typography className={classes.popoverContent}>
            Dynamic tags allow you to insert system data like guest names, survey links, and actions directly into your email.
          </Typography>
        </Popover>
      </Typography>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
          <div className={classes.accordionSummary}>
            <Typography>
              <strong>Pre-Conversation Content</strong>
            </Typography>
            {topic.loadingIds.includes('topicNotifications') ? <CircularProgress size='small' /> : <Chip label={status} size='small' className={classes.chip} />}
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetail}>
          <Typography className='header-16 opacity-6 italic fat-bottom' style={{ color: palette.darkestgrey }}>
            Add purpose-built content to the system-generated emails sent to guests and hosts prior to their conversation. Share relevant resource links and more using Dynamic
            Tags.
          </Typography>

          {contents &&
            contents.map((content: ITopicNotification) => (
              <div key={`content-${content.id}`}>
                <div className='thin-bottom'>
                  <span className='header-20 bold' style={{ color: palette.darkestgrey }}>
                    {content.label}
                  </span>
                  {content.active && (
                    <span className='fat-left'>
                      <Chip label='Active' size='small' className={classes.activeChip} />
                    </span>
                  )}
                </div>
                <div className='header-16 opacity-6 italic reg-bottom'>{content.description ? content.description : null}</div>
                <TopicNotificationsContentForm notification={content} topic={topic} dynamicTags={false} form={`NotificationForm-id${content.id}`} />
              </div>
            ))}
        </AccordionDetails>
      </Accordion>

      <NotificationGroup
        group='Immediately After Conversation'
        topic={topic}
        notifications={notifications_during}
        hint='Activate conversation guests and hosts with custom content sent immediately after the conversation ends. Leverage Dynamic Tags to gather feedback through a survey or direct attendees to take action by adding your Actions Checklist.'
      />
      <NotificationGroup
        group='Post-Conversation'
        topic={topic}
        notifications={notifications_post}
        hint='Schedule and send up to 2 follow-up emails to conversation guests and/or hosts to re-engage users after their conversation has occurred. We recommend using Dynamic Tags to add personalization, share surveys, and prompt users to take action!'
      />
    </div>
  )
}

const NotificationGroup = observer(({ topic, notifications, group, hint }) => {
  const classes = useStyles()
  const status = `${notifications.filter((n) => n.active).length}/${notifications.length} Active`
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div className={classes.accordionSummary}>
          <Typography>
            <strong>{group}</strong>
          </Typography>
          {topic.loadingIds.includes('topicNotifications') ? <CircularProgress size='small' /> : <Chip label={status} size='small' className={classes.chip} />}
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetail}>
        {hint && <Typography className='header-16 opacity-6 italic fat-bottom'>{hint}</Typography>}
        {notifications &&
          notifications.map((notification) => (
            <div key={`notification-${notification.id}`}>
              <div className='thin-bottom'>
                <span className='header-20 bold' style={{ color: palette.darkestgrey }}>
                  {notification.label}
                </span>
                {notification.active && (
                  <span className='fat-left'>
                    <Chip label='Active' size='small' className={classes.activeChip} />
                  </span>
                )}
              </div>

              <TopicNotificationsForm key={notification.id} topic={topic} notification={notification} form={`NotificationForm-id${notification.id}`} />
            </div>
          ))}
      </AccordionDetails>
    </Accordion>
  )
})

const contentNames = ['pre_conversation_content_guest', 'pre_conversation_content_host']
const contentFilter = (n) => contentNames.includes(n.name)
const contentSorter = (a) => contentNames.indexOf(a.name)
const notificationDuringNames = ['during_conversation_guest', 'during_conversation_host']
const notificationPostNames = ['post_conversation_1_guest', 'post_conversation_1_host', 'post_conversation_2_guest', 'post_conversation_2_host']
const notificationFilter = (n) => !contentNames.includes(n.name)
const notificationDuringSorter = (a) => notificationDuringNames.indexOf(a.name)
const notificationPostSorter = (a) => notificationPostNames.indexOf(a.name)

const duringFilter = (n) => notificationDuringNames.includes(n.name)
const postFilter = (n) => notificationPostNames.includes(n.name)

export default flow(
  observer,
  inject<FIXME, FIXME>(
    (
      {
        mst: { topics }
      }: {
        mst: IStore
      },
      ownProps
    ) => {
      const {
        topic,
        match: {
          params: { slug }
        }
      } = ownProps

      topic.shouldLoad('topicNotifications', () => topic.loadTopicNotifications(slug))

      return {
        slug,
        topic,
        contents: sortBy(topic.topicNotifications?.filter(contentFilter), contentSorter),
        notifications_during: sortBy(topic.topicNotifications?.filter(duringFilter), notificationDuringSorter),
        notifications_post: sortBy(topic.topicNotifications?.filter(postFilter), notificationPostSorter),
        topicNotificationsLoading: topic.loadingIds.includes('topicNotifications')
      }
    }
  ),
  withRouter
)(TopicsNotificationsEdit)
