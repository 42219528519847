import SponsorsApi from '../../api/SponsorsApi'
import { types, Instance, getParent } from 'mobx-state-tree'

import { Alertable } from './helpers/Alertable'
import { camelify } from '../../decorators/textTools'
import { MetaImage } from './base/BaseObjects'
import { snakify } from '../../decorators/textTools'
import Editable from './helpers/Editable'

const Sponsor = types
  .compose(
    'Sponsor',
    Alertable,
    Editable,
    types.model({
      id: types.identifierNumber,

      logo: types.maybeNull(MetaImage),
      logoLink: types.maybeNull(types.string),
      sectionId: types.maybeNull(types.number)
    })
  )
  .actions((self) => ({
    deleteSponsor: () => {
      const parent: any = getParent(self, 2)
      parent && parent.deleteSponsor(self.id)
    }
  }))
  .actions((self) => ({
    updateSponsor: (data) => {
      return SponsorsApi.update(snakify({ ...data, id: self.id })).then(({ response: { ok, status, statusText }, json }) => {
        if (ok) {
          const newData = camelify(json)
          self.updateMe(newData)
          self.showAlert('Sponsor successfully updated')
          return { savedData: newData, ok, status, statusText }
        } else {
          return { ok, status, statusText }
        }
      })
    }
  }))

export interface ISponsor extends Instance<typeof Sponsor> {}
export default Sponsor
