import React from 'react'
import AddToCalendar from '../../utils/react-add-to-calendar'
import { Button, ClickAwayListener, createStyles, Link, makeStyles, Paper, Popper, ThemeProvider } from '@material-ui/core'
import { capitalize } from '../../../decorators/textTools'
import withTranslation, { WithTranslation } from '../../hocs/withTranslation'
import { IConversation } from '../../../store'
import classnames from 'classnames'
import appAnalytics from '../../../analytics'
import { DefaultThemeProvider } from '../../../themes'
import { useAuth } from '../../Providers'

interface Props {
  conversation?: IConversation
}
const CalendarDropdownLink = ({ conversation, t }: Props & WithTranslation) => {
  const [open, setOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const anchorRef = React.useRef<HTMLButtonElement>(null)
  const { user } = useAuth()
  const classes = Styles()

  const handleClose = () => {
    setAnchorEl(null)
    open && setOpen(false)
  }
  const handleToggle = () => {
    !open &&
      appAnalytics.event({
        category: 'Conversation',
        action: 'Add to My Calendar'
      })
    setOpen((prevOpen) => !prevOpen)
  }

  if (!conversation || !user) return <></>

  const { title, userVirtualLink, guestInstructions, formattedAddress, isVirtual, startsAt, endsAt, brandSingular, virtualEventTypeSingular } = conversation

  const buildTitle = () => {
    if (isVirtual) {
      return `${title} ${capitalize(virtualEventTypeSingular)}`
    } else {
      return `${title} ${brandSingular}`
    }
  }

  const buildDescription = () => {
    if (isVirtual && !!guestInstructions) {
      return `<strong>${title} ${capitalize(virtualEventTypeSingular)} ${t(
        'conversation.calLink'
      )}</strong><br/><a href=${userVirtualLink} target="_blank">${userVirtualLink}</a><br/><br/><strong>${t('conversation.calJoinInstructions')}</strong><br/>${guestInstructions}`
    }
    if (isVirtual && !guestInstructions) {
      return `<strong>${title} ${capitalize(virtualEventTypeSingular)} ${t('conversation.calLink')}</strong><br/><a href=${userVirtualLink} target="_blank">${userVirtualLink}</a>`
    } else {
      return t('conversation.calAttending', { title, brandSingular })
    }
  }

  const buildLocation = () => {
    if (isVirtual) return `${capitalize(t('conversation.calLink'))} ${userVirtualLink}`
    else return formattedAddress
  }

  const event = {
    title: buildTitle(),
    location: buildLocation(),
    startTime: startsAt,
    endTime: endsAt,
    description: buildDescription()
  }

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <DefaultThemeProvider>
        <div style={{ margin: '.5rem 0' }}>
          <Link
            ref={anchorRef}
            // size='large'
            // fullWidth
            // variant='contained'
            // color={'primary'}
            onClick={handleToggle}
            // endIcon={<i className={'fas fa-calendar-day'} />}
            classes={{ root: classes.link }}
          >
            {t('conversation.addToCalendar')}
          </Link>
          <Popper open={open} anchorEl={anchorRef.current} role={undefined} onClick={handleToggle}>
            <Paper elevation={5} className={classes.dropdownContainer}>
              <AddToCalendar
                optionsOpen={true}
                event={event}
                buttonLabel={t('conversation.addToCalendar')}
                useFontAwesomeIcons={true}
                rootClass={classnames('react-add-to-calendar', classes.cal_root)}
                buttonIconClass={classnames('react-add-to-calendar__icon--', classes.cal_icon)}
                buttonWrapperClass={classnames('react-add-to-calendar__wrapper', classes.cal_buttonWrapper)}
                dropdownClass={classnames('react-add-to-calendar__dropdown', classes.cal_dropdown)}
                buttonClassOpen={classnames('react-add-to-calendar__button--light', classes.cal_buttonOpen)}
                buttonClassClosed={classnames('react-add-to-calendar__button', classes.cal_buttonClosed)}
              />
            </Paper>
          </Popper>
        </div>
      </DefaultThemeProvider>
    </ClickAwayListener>
  )
}

const Styles = makeStyles((theme) =>
  createStyles({
    dropdownContainer: {
      // width: '316px',
      zIndex: 999,
      top: '0',
      left: '0',
      padding: '1px 0'
    },
    link: {
      whiteSpace: 'nowrap',
      color: '#0E75BB',
      fontWeight: 500,
      fontSize: '13px',
      lineHeight: '22px',
      ['&:hover']: {
        backgroundColor: '#ffffff'
      }
    },
    cal_root: {
      color: '#737374',
      minWidth: '11em'
    },
    cal_icon: {},
    cal_buttonWrapper: {
      fontSize: '22px',
      lineHeight: '24px',
      color: '#0099C1 !important',
      display: 'none'
    },
    cal_buttonOpen: {},
    cal_buttonClosed: {},
    cal_dropdown: {
      '& li': {
        fontSize: '1em !important',
        alignItems: 'flex-end',
        fontWeight: 400,
        whiteSpace: 'nowrap',
        marginBottom: '.7em !important',
        paddingLeft: '.7em',
        '& i': {
          color: '#737374 !important',
          fontSize: '1.4em'
        },
        '& :hover': {
          textDecoration: 'underline'
        }
      }
    }
  })
)

export default withTranslation(CalendarDropdownLink)
