import { createMuiTheme, ThemeOptions } from '@material-ui/core/styles'
import { merge } from 'lodash'
import { defaultThemeData } from './defaultTheme'
import palette from './palette'
import { defaultButtonOverride, defaulTypography, getFontFamily } from './partials'

export const TopicTheme = (themeable?: any) =>
  createMuiTheme(
    merge({}, defaultThemeData, {
      palette: {
        primary: {
          main: themeable?.themeColor || palette.yellow500,
          contrastText: palette.white
        },
        secondary: {
          main: window.coco ? palette.white : palette.magenta500,
          contrastText: palette.magenta900
        }
      },
      overrides: {
        MuiTypography: defaulTypography,
        MuiButton: {
          ...defaultButtonOverride,
          root: { fontFamily: getFontFamily() },
          contained: {
            fontFamily: getFontFamily(),
            backgroundColor: palette.white,
            color: palette.darkestgrey,
            boxShadow: '0px 1px 4px 1px rgba(0, 0, 0, 0.12)',
            fontWeight: 800,
            '&:hover': {
              opacity: 0.5
            },
            '&$disabled': {
              color: palette.grey,
              backgroundColor: palette.lightestgrey
            }
          },
          label: {
            textTransform: 'none',
            fontWeight: window.coco ? 400 : 500,
            letterSpacing: 0,
            whiteSpace: 'nowrap'
          }
        },
        MuiInput: {
          underline: {
            '&:after': {
              borderBottom: '1px solid rgba(0, 0, 0, 0.42)'
            },
            '&:hover:not($disabled):before': {
              borderBottom: '1px solid rgba(0, 0, 0, 0.42)'
            }
          }
        },
        MuiDialogTitle: {
          root: {
            backgroundColor: window.coco ? palette.cocoGreen : palette.skyBlue,
            color: palette.white,
            textAlign: 'center',
            fontSize: '1.4em',
            fontWeight: 700
          }
        },
        MuiCircularProgress: {
          colorPrimary: {
            color: window.coco ? palette.cocoLightBlue : palette.teal
          }
        }
      }
    } as ThemeOptions)
  )
