import React from 'react'
import Button from '@material-ui/core/Button'
import { flow } from 'lodash'

import { connectTopicTheme } from '../../connectTheme.js'
import Dialog from '../DialogWrapper'
import { observer } from 'mobx-react'
import { inject } from 'mobx-react'
import { IStore } from '../../store/models/index.js'

const DeleteSurveyQuestionDialog = (props: FIXME) => {
  const { dialog, topic, questionId } = props

  const handleSubmit = (e) => {
    e.stopPropagation()
    e.preventDefault()
    topic.deleteQuestion(questionId, { confirm: false }).then(dialog.closeDialog)
  }

  return (
    <Dialog dialog={dialog} name='deleteSurveyQuestion'>
      <div className='align-center'>
        <h2 className='fat-bottom'>Are you sure you want to remove this question?</h2>
        <p>You will no longer be able to retrieve responses.</p>
        <form onSubmit={handleSubmit}>
          <div className='row center-xs xtra-fat-top'>
            <div className='col-xs-12'>
              <Button type='submit' className='reg-bottom' color='secondary' variant='contained'>
                Yes, remove this question
              </Button>
            </div>
          </div>

          <div className='row center-xs reg-bottom'>
            <div className='col-xs-12'>
              {/* eslint-disable-next-line */}
              <a className='underline fat-bottom' onClick={() => dialog.closeDialog()}>
                {"Nevermind, I'll keep it"}
              </a>
            </div>
          </div>
        </form>
      </div>
    </Dialog>
  )
}

export default flow(
  connectTopicTheme,
  observer,
  inject<FIXME, FIXME>(
    (
      {
        mst: { topics }
      }: {
        mst: IStore
      },
      { data: { id, topicId } }
    ) => {
      const topic = topics.list.find((d) => d.id === topicId)
      return { topic, questionId: id }
    }
  )
)(DeleteSurveyQuestionDialog)
