import { deleteApi, post, put } from './utils'

class ActionsApi {
  static create(data) {
    return post(`/actions`, {
      body: JSON.stringify({ actionData: data })
    })
  }

  static update(data) {
    return put(`/actions/${data.id}`, {
      body: JSON.stringify({ actionData: data })
    })
  }

  static destroy(data) {
    return deleteApi(`/actions/${data.id}`)
  }
}

export default ActionsApi
