import React from 'react'
import { Button, Card, FormHelperText, Typography } from '@material-ui/core'
import { flow } from 'lodash'
import withTranslation from '../../hocs/withTranslation'
import { CheckboxControl, FileControl, WysiwygControl } from '../../utils'
import { observer } from 'mobx-react'
import { useForm } from 'react-hook-form'
import { IConversation } from '../../../store/models/Conversation/index.js'
import { TextFieldControl } from '../../utils'
import * as validations from '../../validations/fieldLevelValidations'

interface Props {
  t: any
  conversation: IConversation
}
const PremiumEventForm = ({ t, conversation }: Props) => {
  const { handleSubmit, errors, formState, control, reset } = useForm()

  const { isDirty, isSubmitting } = formState

  const handleSave = handleSubmit((val: any, e) => {
    conversation.updateConversation(val).then(({ error = undefined, data = undefined }: any = {}) => {
      reset(data)
    })
  })

  return (
    <Card className='no-padding xtra-huge-bottom no-shadow'>
      <form onSubmit={handleSave}>
        <div className='fat-bottom'>
          {' '}
          <FileControl
            name='backgroundImage'
            control={control}
            defaultValue={conversation?.backgroundImage}
            error={errors && errors.backgroundImage}
            className='reg-top'
            helperText={t('conversation.eventBGLabel')}
          />
        </div>
        {/* <FileControl
          name='partnerLogo'
          control={control}
          defaultValue={conversation?.partnerLogo}
          error={errors && errors.partnerLogo}
          className='reg-top'
          helperText={t('conversation.partnerLogoLabel')}
        /> */}

        <div className='fat-bottom'>
          <TextFieldControl
            required
            rules={{ validate: validations.required }}
            className='reg-bottom'
            label={'Title'}
            name='title'
            defaultValue={conversation.title || ' '}
            control={control}
            error={errors && errors.title}
          />

          <FormHelperText>This is the title of your Conversation. Use this field to override the title inherited from the Topic.</FormHelperText>
        </div>

        <div className='fat-bottom'>
          <WysiwygControl
            name='description'
            control={control}
            defaultValue={conversation?.description || 'Nothing'}
            error={errors && errors.description}
            label={'Subtitle'}
            className='reg-bottom'
          />
          <FormHelperText>This is the subtitle of your Conversation. Use this field to override the subtitle inherited from the Topic.</FormHelperText>
        </div>

        <WysiwygControl name='about' control={control} defaultValue={conversation?.about || ''} error={errors && errors.about} label={'Description'} />
        <FormHelperText>
          This is the summary of what attendees will discuss during the conversation. Use this input to override the content input at the Topic level.
        </FormHelperText>

        <WysiwygControl name='schedule' control={control} defaultValue={conversation?.schedule || ''} error={errors && errors.schedule} label={t('common.schedule')} />
        <FormHelperText className='fat-bottom' component='div'>
          Use this input to create a schedule to let attendees know what to expect during your event. This is especially useful for large events leveraging breakout rooms or small
          groups.
        </FormHelperText>

        <Typography variant='subtitle2' className='fat-top' component='div'>
          Premium Settings
        </Typography>
        <div>
          <CheckboxControl
            name='showGuestList'
            control={control}
            defaultValue={conversation?.showGuestList}
            error={errors && errors.showGuestList}
            label={t('conversation.showGuestList')}
            className='reg-top'
            fullWidth
          />
        </div>
        <div>
          <CheckboxControl
            name='showMap'
            control={control}
            defaultValue={conversation?.showMap}
            error={errors && errors.showMap}
            label={t('conversation.showMap')}
            className='reg-top'
            fullWidth
          />
        </div>
        <div>
          <CheckboxControl
            name='showFaqs'
            control={control}
            defaultValue={conversation?.showFaqs}
            error={errors && errors.showFaqs}
            label={t('conversation.showFaqs')}
            className='reg-top'
            fullWidth
          />
        </div>

        <Button disabled={!isDirty || isSubmitting} type='submit' className='reg-bottom xtra-fat-top' color='primary' variant='contained'>
          {t('common.submit')}
        </Button>
      </form>
    </Card>
  )
}

export default flow(observer, withTranslation)(PremiumEventForm)
