import React, { useEffect } from 'react'
import { flow } from 'lodash'

import Dialog from '../../../DialogWrapper'
import { observer } from 'mobx-react'
import { Button, Container, Grid, IconButton, Paper, Tooltip } from '@material-ui/core'
import { IMembership } from '../../../../store/models/Track/Membership'
import DataTable, { IColData, ValueLabel } from '../../../shared/DataTable'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { IMembershipUser } from '../../../../store/models/User/MembershipUser'
import { AdminThemeProvider, DefaultThemeProvider } from '../../../../themes'

const MembersListDialog = observer(({ membership, onClose }: { membership?: IMembership; onClose?() }) => {
  const membershipUser = membership?.owners[0]

  useEffect(() => {
    const mu = membership?.owners[0]
    if (mu) {
      mu.loadFullList()
    }
  }, [membership])

  const isLoading = membership?.owners[0].loadingIds.includes('fullList')

  return (
    <Dialog dialog={{ closeDialog: () => onClose && onClose() }} open={!!membership} name='emailHost' maxWidth='md' title='Manage Members'>
      <AdminThemeProvider>
        <section className='pane big-top-padding big-bottom-padding'>
          <Container>
            <Grid container direction='row' alignItems='center' justify='space-between'>
              <Grid item></Grid>
              <Grid item>
                <Tooltip title='Copy Invitation Link'>
                  <Button
                    variant='contained'
                    color='primary'
                    size='small'
                    startIcon={<i className='far fa-link' style={{ fontSize: '.9rem' }}></i>}
                    onClick={() => {
                      if (membershipUser) {
                        navigator.clipboard.writeText(membershipUser.inviteLink())
                        membershipUser.showAlert('Invite link copied')
                      }
                    }}
                  >
                    Invite Link
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
          </Container>
        </section>
      </AdminThemeProvider>
      <section className='pane big-top-padding big-bottom-padding'>
        <MembersList list={membershipUser?.fullList.filter((mu) => mu.status === 'active') as any} isLoading={isLoading} count={membershipUser?.fullList.length} />
      </section>
    </Dialog>
  )
})
const MembersList = observer(({ list, isLoading, count }: { list?: IMembershipUser[]; isLoading?: boolean; count?: number }) => {
  const columnData: IColData[] = [
    { name: 'firstName', title: 'First Name', width: 120 },
    { name: 'lastName', title: 'Last Name', width: 120 },
    { name: 'email', title: 'Email', width: 150 },
    { name: 'city', title: 'City', width: 100 },
    { name: 'stateLevel', title: 'State', width: 50 },
    { name: 'createdAt', title: 'Joined', width: 200 },
    { name: 'active', title: 'Active', width: 50 },
    { name: 'role', title: 'Role', width: 80 },
    { name: 'status', title: 'Status', width: 80 },

    { name: 'actions', title: 'Actions', width: 100, align: 'center' }
  ]
  if (!list) {
    return <></>
  }

  return (
    <div className='row reg-bottom'>
      <div className='col-xs'>
        <Paper elevation={0}>
          <DataTable
            hideFilter={true}
            height={'calc(100vh / 3)'}
            data={list}
            columns={columnData}
            loading={isLoading}
            rightColumns={['actions']}
            getCellValue={(row: IMembershipUser, columnName: string) => {
              switch (columnName) {
                case 'firstName':
                  return <ValueLabel value={row?.user?.firstName} />
                case 'lastName':
                  return <ValueLabel value={row?.user?.lastName} />
                case 'email':
                  return <ValueLabel value={row?.email} />
                case 'city':
                  return <ValueLabel value={row?.user?.city} />
                case 'stateLevel':
                  return <ValueLabel value={row?.user?.stateLevel} />
                case 'createdAt':
                  return <ValueLabel value={`${moment(row.createdAt).format('L')} (${moment(row.createdAt).fromNow()})`} />
                case 'active':
                  return <ValueLabel value={row.active.toString()} />

                case 'actions':
                  return (
                    <MemberActions
                      membership_user={row}
                      onClick={(e, m) => {
                        m.removeMembership()
                      }}
                    />
                  )
                default:
                  return <ValueLabel value={row[columnName]} />
              }
            }}
          />
        </Paper>
      </div>
    </div>
  )
})

const MemberActions = observer(({ membership_user, onClick }: { membership_user: IMembershipUser; onClick?(e, m: IMembershipUser) }) => {
  if (membership_user.status !== 'active') {
    return <ValueLabel value={membership_user.status} />
  }
  return (
    <Grid container direction='row' justify='space-around' alignItems='center'>
      <Tooltip title='View Profile'>
        <Link to={`/users/${membership_user?.user?.id}`} target='_blank'>
          <IconButton size='small'>
            <i className='fas fa-user opacity-8'></i>
          </IconButton>
        </Link>
      </Tooltip>
      {!membership_user.owner && (
        <Tooltip title='Remove Member'>
          <IconButton size='small' onClick={(e) => onClick && onClick(e, membership_user)}>
            <i className='fas fa-user-minus opacity-8'></i>
          </IconButton>
        </Tooltip>
      )}
    </Grid>
  )
})

export default MembersListDialog
