import React from 'react'
import FontIcon from '@material-ui/core/Icon'
import Avatar from '@material-ui/core/Avatar'

const UserAvatar = ({ profileImage, hasProfileImage, size, placeHolder = undefined }) => {
  if (hasProfileImage) {
    if (size === 'thumb') {
      return <Avatar src={profileImage.thumb.url} alt='Profile thumbnail' className='avatar-image-thumb' />
    } else if (size === 'medium') {
      return (
        <div className='avatar avatar-image-medium'>
          <img src={profileImage.primary.url} alt='Profile medium' />
        </div>
      )
    } else if (size === 'primary') {
      return (
        <div className='avatar avatar-image-primary'>
          <img src={profileImage.primary.url} alt='Profile primary' />
        </div>
      )
    } else if (size === 'profile-primary') {
      return (
        <div className='avatar'>
          <img src={profileImage.primary.url} alt='Profile' />
        </div>
      )
    }
  } else {
    if (size === 'thumb') {
      return placeHolder || <FontIcon className='fal fa-user-circle avatar-image-thumb' />
    } else if (size === 'medium') {
      return (
        placeHolder || (
          <div className='avatar avatar-image-medium opacity-3'>
            <FontIcon className='fal fa-user-circle' />
          </div>
        )
      )
    } else if (size === 'primary') {
      return (
        placeHolder || (
          <div className='avatar avatar-image-primary'>
            <FontIcon className='fal fa-user-circle' />
          </div>
        )
      )
    } else if (size === 'profile-primary') {
      return (
        placeHolder || (
          <div className='avatar avatar-image-profile-primary'>
            <FontIcon className='fad fa-user-circle white-text' />
          </div>
        )
      )
    }
  }
  return <></>
}

export default UserAvatar
