import React from 'react'
import FlatButton from '@material-ui/core/Button'
import i18n from 'i18next'

import Dialog from '../DialogWrapper'
import ProfileImageUploader from '../users/ProfileImageUploader'
import { observer } from 'mobx-react'

const ProfileImageDialog = ({ dialog }) => {
  const uploader = (
    <div>
      <h2>{i18n.t('user.addProfilePic')}</h2>
      <ProfileImageUploader onUpload={dialog.closeDialog} />
      <div className='reg-top reg-bottom'>
        <FlatButton onClick={dialog.closeDialog} variant='text'>
          {i18n.t('user.addProfilePicDeny')}
        </FlatButton>
      </div>
    </div>
  )

  return (
    <Dialog dialog={dialog} name='profileImage'>
      <div className='row'>
        <div className='col-xs-12 center-xs'>{uploader}</div>
      </div>
    </Dialog>
  )
}

export default observer(ProfileImageDialog)
