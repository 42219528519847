import React, { useEffect } from 'react'
import { DateTimeControl, SelectControl, DateControl, TimeControl } from '../../utils'
import moment from 'moment'
import { StepProps } from './NewStepper'
import { isValidDate, isValidTime, required } from '../../validations/fieldLevelValidations'
import withTranslation, { WithTranslation } from '../../hocs/withTranslation'

const DateStep = ({ topic, t, control, errors, setValue, setError, clearErrors, watch, trigger }: StepProps & WithTranslation) => {
  useEffect(() => {
    if (topic) {
      setValue('startsAtDate', moment().add(24, 'hours'), { shouldDirty: true })
      setValue('startsAt', moment().add(24, 'hours'), { shouldDirty: true })
      setValue('endsAt', moment().add(25, 'hours'), { shouldDirty: true })
    }
  }, [topic, setValue])

  const timezones = topic?.timezones?.length ? Array.from(topic?.timezones) : moment.tz.names()
  return (
    <>
      <div>
        <div className='fat-top'>
          <DateControl
            name='startsAtDate'
            control={control}
            error={errors && errors.startsAtDate}
            defaultValue={watch('startsAtDate') || moment().add(24, 'hours')}
            rules={{
              validate: (tm) => {
                return !isValidDate(topic.startsAt, topic.endsAt)(moment(tm)) ? 'Invalid date' : undefined
              }
            }}
            dateProps={{
              inputProps: {
                min: topic.startsAt && moment().isBefore(moment(topic.startsAt)) ? topic.startsAt : moment().format('yyyy-MM-DD'),
                max: topic.endsAt ? topic.endsAt : undefined
              }
            }}
          />

          <div className='label reg-top'>{t('common.startTime')}</div>
          <TimeControl
            name='startsAt'
            error={errors && errors.startsAt}
            control={control}
            rules={{ validate: (startsAtVal) => (!isValidTime(moment(startsAtVal), moment(watch('endsAt'))) ? t('convos.validTime') : undefined) }}
            defaultValue={moment().add(24, 'hours')}
            onChange={(val: any) => {
              if (!val) return
              // if (watch('endsAt')) {
              trigger('endsAt')
              // }
              setValue('startsAt', val, { shouldDirty: true })
            }}
          />
          <div className='label reg-top'>{t('common.endTime')}</div>
          <TimeControl
            name='endsAt'
            control={control}
            defaultValue={moment().add(25, 'hours')}
            rules={{ validate: (endsAtVal) => (!isValidTime(moment(watch('startsAt')), moment(endsAtVal)) ? t('convos.validTime') : undefined) }}
            error={errors && errors.endsAt}
            onChange={(val: any) => {
              if (!val) return
              setValue('endsAt', val, { shouldDirty: true })
              trigger('startsAt')
            }}
          />

          <SelectControl
            name='timezone'
            label={t('common.timeZone')}
            className='fat-top'
            control={control}
            defaultValue={(topic && topic.timezone) || 'US/Eastern'}
            error={errors && errors.timezone}
            options={timezones.map((value) => ({ label: value, value }))}
          />
        </div>
      </div>
    </>
  )
}

export default withTranslation(DateStep)
