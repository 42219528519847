import { get } from './utils'

class RegionalPartnersApi {
  static getAll(siteId) {
    return get(`/sites/${siteId}/regional_partners`, {
      noToken: true
    })
  }
}

export default RegionalPartnersApi
