import React, { Component } from 'react'
import { Grid } from '@material-ui/core'
import { ReactTitle } from 'react-meta-tags'
import withTranslation from '../hocs/withTranslation'

class CookiePolicy extends Component {
  render() {
    const { t } = this.props

    return (
      <section className='pane fat-pane'>
        <Grid container justify='center'>
          <Grid item md={8}>
            <div className='cookie-policy'>
              <div className='WordSection1'>
                <p className='MsoNormal' align='center' style={{ textAlign: 'center' }}>
                  <b>INCLUSIVV</b>
                </p>
                <p className='MsoNormal' align='center' style={{ textAlign: 'center' }}>
                  &nbsp;
                </p>
                <p className='MsoNormal' align='center' style={{ textAlign: 'center' }}>
                  <b>
                    <u>COOKIE POLICY</u>
                  </b>
                </p>
                <p className='MsoNormal' align='center' style={{ textAlign: 'center' }}>
                  <b>&nbsp;</b>
                </p>
                <p className='MsoNormal' align='center' style={{ textAlign: 'center' }}>
                  <b>
                    <i>Effective as of: Jan 26, 2022</i>
                  </b>
                </p>
                <p className='MsoNormal' align='center' style={{ textAlign: 'center' }}>
                  &nbsp;
                </p>
                <p className='MsoNormal'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Civic Dinners, Inc. d/b/a Inclusivv, a Delaware public benefit corporation, together with its
                  subsidiaries and affiliates&nbsp;(collectively, “
                  <b>
                    <i>Inclusivv</i>
                  </b>
                  ”, “
                  <b>
                    <i>us</i>
                  </b>
                  ” or “
                  <b>
                    <i>we</i>
                  </b>
                  ”) understand that your privacy is important to you and are committed to being transparent about the technologies it uses. This Cookie Policy (this “
                  <b>
                    <i>Policy</i>
                  </b>
                  ”) explains how and why cookies, web beacons, pixels, clear gifs, and other similar technologies (collectively, “
                  <b>
                    <i>Cookies and Other Tracking Technologies</i>
                  </b>
                  ”) may be stored on and accessed from your device when you use or visit any website or app that posts a link to this Policy (collectively, the “
                  <b>
                    <i>Services</i>
                  </b>
                  ”). This Policy should be read together with our&nbsp;
                  <a href='https://www.civicdinners.com/privacy_policy'>Privacy Policy</a>
                  &nbsp;and our&nbsp;
                  <a href='https://www.civicdinners.com/terms'>Terms of Service</a>.
                </p>
                <p className='MsoNormal'>
                  <br />
                  <b>
                    1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <u>Changes to this Policy</u>
                  </b>
                  . We may change this Policy at any time. Any change in this Policy will become effective when we make the revised Policy available on or through the Services.
                </p>
                <p className='MsoNormal' align='left' style={{ textAlign: 'left' }}>
                  &nbsp;
                </p>
                <p className='MsoNormal'>
                  <b>
                    2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <u>Cookies and Other Technologies</u>
                  </b>
                  . A cookie is a small text file that can be stored on and accessed from your device when you visit our Services, to the extent you agree. The other tracking
                  technologies work similarly to cookies and place small data files on your devices or monitor your website activity to enable us to collect information about how
                  you use our Services. This allows our Services to recognize your device from those of other users of the Services. The information provided below about cookies
                  also applies to these other tracking technologies. You can find more information at&nbsp;
                  <a href='http://www.allaboutcookies.org/'>www.allaboutcookies.org</a>
                  &nbsp;and&nbsp;
                  <a href='http://www.youronlinechoices.eu/'>www.youronlinechoices.eu</a>.
                </p>
                <p className='MsoNormal'>
                  <b>&nbsp;</b>
                </p>
                <p className='MsoNormal'>
                  <b>
                    3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <u>How We Use Cookies and Other Tracking Technologies</u>
                  </b>
                  .
                </p>
                <p className='MsoNormal'>&nbsp;</p>
                <p className='MsoNormal'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; We use Cookies and Other Tracking Technologies to
                  identify you and your interests, to remember your preferences, and to track your use of our Services. We also use Cookies and Other Tracking Technologies to
                  control access to certain content on our Services, protect the Services, and to process any requests that you make of us.
                </p>
                <p className='MsoNormal'>&nbsp;</p>
                <p className='MsoNormal'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To administer our Services and for research
                  purposes, we also have contracted with third party service providers to track and analyze statistical usage and volume information from our Services users. These
                  third party service providers use persistent Cookies to help us to improve the user experience, manage our content on our Services, and analyze how users navigate
                  and utilize the Services.
                </p>
                <p className='MsoNormal'>
                  <br />
                  <b>4.</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                  <b>
                    <u>First and Third Party Cookies</u>
                  </b>
                  .
                </p>
                <p className='MsoNormal'>&nbsp;</p>
                <p className='MsoNormal'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; “
                  <b>
                    <i>First party Cookies</i>
                  </b>
                  ” are cookies that belong to us and that we place on your device.{' '}
                </p>
                <p className='MsoNormal'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </p>
                <p className='MsoNormal'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; “
                  <b>
                    <i>Third party Cookies</i>
                  </b>
                  ” are cookies that another party places on your device through our Services. We use are from third party companies, such as{' '}
                  <a href='https://policies.google.com/technologies/cookies?hl=en-US' target='blank'>
                    Youtube
                  </a>
                  . Analytics to provide us with web analytics and intelligence about our sites which may also be used to provide measurement services and target ads. These
                  companies use programming code to collect information about your interaction with our sites, such as the pages you visit, the links you click on and how long you
                  are on our sites. This code is only active while you are on our Services. For more information on how these companies collect and use information on our behalf,
                  please refer to their privacy policies.{' '}
                </p>
                <p className='MsoNormal'>&nbsp;</p>
                <p className='MsoNormal'>
                  <b>
                    5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <u>Cookies We Use</u>
                  </b>
                  . Depending on the Services, we use the following types of cookies:
                </p>
                <p className='MsoNormal'>&nbsp;</p>
                <p className='MsoNormal'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                  <b>
                    <i>5.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Necessary Cookies</i>
                  </b>
                  . Necessary Cookies are essential to enable your use of the Services and to identify and prevent security risks, such as storing your session information to
                  prevent others from changing your password without your username and password.
                </p>
                <p className='MsoNormal'>&nbsp;</p>
                <p className='MsoNormal'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                  <b>
                    <i>5.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Preference Cookies</i>
                  </b>
                  . Preference Cookies are used to remember your settings and preferences and to improve your experience on our website and in using our Services. For example, we
                  may use these Cookies to remember your language preferences.
                </p>
                <p className='MsoNormal'>&nbsp;</p>
                <p className='MsoNormal'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                  <b>
                    <i>5.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Session Cookies</i>
                  </b>
                  . Session Cookies are stored only temporarily during a browsing session and are deleted from your device when you close the browser. We use these Cookies to
                  support the functionality of the Services and to understand your use of the Services —that is, which pages you visit, which links you use, and how long you stay
                  on each page.
                </p>
                <p className='MsoNormal'>&nbsp;</p>
                <p className='MsoNormal'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                  <b>
                    <i>5.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Statistics Cookies</i>
                  </b>
                  . Statistics Cookies help collect information about your use of the Services and enable us to improve the way it works. These Cookies give us aggregated
                  information that we use to monitor the Service’s performance, count page visits, spot technical errors, see how users reach the Services, and measure the
                  effectiveness of marketing (including emails).
                </p>
                <p className='MsoNormal'>&nbsp;</p>
                <p className='MsoNormal'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                  <b>
                    <i>5.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Marketing Cookies</i>
                  </b>
                  . Marketing Cookies are used to show users display advertisements across websites. The intention is to display ads that are relevant and engaging for the
                  individual user and thereby more valuable for publishers and third-party advertisers. We do not display ads for any website besides our own.
                </p>
                <p className='MsoNormal'>&nbsp;</p>
                <p className='MsoNormal'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                  <b>
                    <i>5.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Unclassified Cookies</i>
                  </b>
                  . Unclassified Cookies are Cookies that we are in the process of classifying, together with the providers of individual Cookies.
                </p>
                <p className='MsoNormal'>&nbsp;</p>
                <p className='MsoNormal'>
                  <b>
                    6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <u>Managing Cookies</u>
                  </b>
                  .
                </p>
                <p className='MsoNormal'>&nbsp;</p>
                <p className='MsoNormal'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; If you do not want Cookies to be dropped on your
                  device, you can adjust the setting of your Internet browser to reject the setting of all or some Cookies and to alert you when a Cookie is placed on your device.
                  For further information about how to do so, please see the specific browser procedures below or see&nbsp;
                  <a href='http://www.allaboutcookies.org/'>www.allaboutcookies.org</a>
                  &nbsp;. Please note that if you use your browser settings to block all Cookies (including strictly necessary Cookies) you may not be able to access or use all or
                  parts or functionalities of our Services.
                </p>
                <p className='MsoNormal'>&nbsp;</p>
                <p className='MsoNormal' style={{ marginLeft: '1.0in' }}>
                  (i)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                  <a href='https://support.google.com/chrome/answer/95647?hl=en' target='_blank'>
                    Chrome
                  </a>
                </p>
                <p className='MsoNormal' style={{ marginLeft: '1.0in' }}>
                  &nbsp;
                </p>
                <p className='MsoNormal' style={{ marginLeft: '1.0in' }}>
                  (ii)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                  <a href='https://support.apple.com/kb/PH19255?locale=en_US' target='_blank'>
                    Safari
                  </a>
                </p>
                <p className='MsoNormal' style={{ marginLeft: '1.0in' }}>
                  &nbsp;
                </p>
                <p className='MsoNormal' style={{ marginLeft: '1.0in' }}>
                  (iii)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                  <a href='https://support.apple.com/en-us/HT201265' target='_blank'>
                    Safari Mobile (iPhone and iPads)
                  </a>
                </p>
                <p className='MsoNormal' style={{ marginLeft: '1.0in' }}>
                  &nbsp;
                </p>
                <p className='MsoNormal' style={{ marginLeft: '1.0in' }}>
                  (iv)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                  <a href='https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored' target='_blank'>
                    Firefox
                  </a>
                </p>
                <p className='MsoNormal' style={{ marginLeft: '1.0in' }}>
                  &nbsp;
                </p>
                <p className='MsoNormal' style={{ marginLeft: '1.0in' }}>
                  (v)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                  <a href='https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies' target='_blank'>
                    Microsoft Edge
                  </a>
                </p>
                <p className='MsoNormal'>&nbsp;</p>
                <p className='MsoNormal'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; If you want to remove previously-stored Cookies,
                  you can manually delete the Cookies at any time. However, this will not prevent the Services from placing further Cookies on your device unless and until you
                  adjust your Internet browser setting as described above.
                </p>
                <p className='MsoNormal'>&nbsp;</p>
                <p className='MsoNormal'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; For more information on the development of
                  user-profiles and the use of targeting/advertising Cookies, please see&nbsp;
                  <a href='http://www.youronlinechoices.eu/'>www.youronlinechoices.eu</a>
                  &nbsp;if you are located in Europe or&nbsp;
                  <a href='http://www.aboutads.info/choices'>www.aboutads.info/choices</a>
                  &nbsp;if in the United States.
                </p>
                <p className='MsoNormal' align='left' style={{ textAlign: 'left' }}>
                  <br />
                  <b>
                    7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <u>How We Use Other Technologies</u>
                  </b>
                  .
                </p>
                <p className='MsoNormal'>&nbsp;</p>
                <p className='MsoNormal'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; We may collect information about whether you open
                  or click any links in the knowledge, research or event communications that we send to you through web beacons.
                </p>
                <p className='MsoNormal'>&nbsp;</p>
                <p className='MsoNormal'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; A web beacon is a graphic image, which is placed
                  on a website or in an email that alone or in conjunction with cookies compile information about your usage of or your interaction with a Inclusivv website or
                  email. For example, we may add web beacons to the knowledge, research or event communications that we send to you to determine whether you have opened our email
                  or clicked a link. The analysis gathered helps us improve the effectiveness of content and format of our websites and email communications.
                </p>
                <p className='MsoNormal' align='left' style={{ textAlign: 'left' }}>
                  &nbsp;
                </p>
                <p className='MsoNormal'>
                  <b>
                    8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <u>Managing Web Beacons</u>
                  </b>
                  . You may avoid web beacons by configuring your email reader program to disable the functionality that enables remote images to load and by refraining from
                  clicking on any links in email messages.
                </p>
                <p className='MsoNormal' align='left' style={{ textAlign: 'left' }}>
                  &nbsp;
                </p>
                <p className='MsoListParagraph' style={{ marginLeft: '0in' }}>
                  <b>
                    9.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <u>Contact Us</u>
                  </b>
                  . If you have any questions or concerns about this Policy, please contact us via email at <a />
                  <a href='mailto:info@inclusivv.co'>info@inclusivv.co</a>.
                </p>
                <p className='MsoNormal' align='left' style={{ textAlign: 'left' }}>
                  &nbsp;
                </p>
              </div>
            </div>
          </Grid>
        </Grid>
      </section>
    )
  }
}

export default withTranslation(CookiePolicy)
