import { types, Instance } from 'mobx-state-tree'
import UserConnectionsApi from '../../../api/UserConnectionsApi'
import UserUserConnectionsApi from '../../../api/users/UserConnectionsApi'
import { Dialogable, LoadableStatus, Alertable } from '../helpers'
import { UserBase } from './UserBase'
import i18n from 'i18next'
import appAnalytics from '../../../analytics'

export const UserConnection = types.model('UserConnection', {
  id: types.identifierNumber,
  userId: types.number,
  requestedUserId: types.number,
  accepted: types.optional(types.boolean, false),
  conversationId: types.maybeNull(types.number),
  messsage: types.maybeNull(types.string)
})

export const UserConnections = types
  .compose(
    'UserConnections',
    UserBase,
    LoadableStatus,
    Dialogable,
    Alertable,
    types.model({
      userConnections: types.optional(types.array(UserConnection), []),
      userConnectionConversationId: types.maybeNull(types.number)
    })
  )
  .actions((self) => ({
    setUserConnections(data: any) {
      self.userConnections.replace([data])
    },
    setUserConnectionConversationId(val: any) {
      self.userConnectionConversationId = val
    },
    removeFromList: () => {
      self.userConnections.replace([])
    }
  }))
  .actions((self) => ({
    loadUserConnections: (userId) => {
      if (self.isLoading('userConnections')) {
        return
      }
      self.startLoading('userConnections')
      return UserUserConnectionsApi.getAll(userId).then(({ response, json }) => {
        if (response.ok) {
          if (response.status === 200) {
            // User Connection Found
            self.setUserConnectionConversationId(null)
            self.setUserConnections(json)
          } else if (response.status === 202) {
            // User Connection Authorized
            self.setUserConnectionConversationId(json.conversation_id)
          } else if (response.status === 401) {
            // User Connection Not Authorized
            self.setUserConnectionConversationId(null)
          }
          self.stopLoading('userConnections')
        }
      })
    },
    showRequestDialog: (data) => {
      appAnalytics.event({
        category: 'User',
        action: 'Request Connection',
        conversationId: data.conversationId
      })
      self.showDialog('userConnectionRequest', data)
    },
    createUserConnection: (data: any) => {
      return UserConnectionsApi.create({ user_id: self.id, ...data })
        .then(({ response: { ok, statusText }, json }) => {
          if (ok) {
            self.setUserConnections(json)
            self.closeDialog('userConnectionRequest')
            self.showDialog('userConnectionRequestSent')
          } else {
            self.showAlert(statusText)
          }
        })
        .catch((error) => {
          throw error
        })
    },
    updateUserConnection: (data: any) => {
      return UserConnectionsApi.update(data)
        .then(({ response: { ok, statusText }, json }) => {
          if (ok) {
            self.showDialog('userConnectionAccept', data)
          } else {
            self.showAlert(statusText)
          }
        })
        .catch((error) => {
          throw error
        })
    },
    deleteUserConnection: (data) => {
      return UserConnectionsApi.delete(data).then(({ response: { ok } }) => {
        if (ok) {
          self.showAlert(i18n.t('user.connectionRemoved'))
          self.removeFromList()
        }
      })
    }
  }))

export interface IUserConnection extends Instance<typeof UserConnection> {}
export interface IUserConnections extends Instance<typeof UserConnections> {}
export default UserConnections
