import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import i18n from 'i18next'
import { showDialog } from '../../actions/dialogActions'
import { connectTopicTheme } from '../../connectTheme'
import withTranslation from '../hocs/withTranslation'
import { withRouter } from 'react-router'
import { map } from 'lodash'
import { ABOUT_LINKS, EXPLORE_LINKS } from './navigation/Links'
import { Typography } from '@material-ui/core'

class Footer extends PureComponent {
  handleDialog = (type, data) => () => this.props.showDialog(type, data)

  render() {
    const SocialMediaItems = [
      {
        icon: 'fa-facebook-f',
        link: 'https://www.facebook.com/inclusivv'
      },
      {
        icon: 'fa-instagram',
        link: 'https://www.instagram.com/inclusivv.co'
      },
      {
        icon: 'fa-medium-m',
        link: 'https://medium.com/@CivicDinners'
      },
      {
        icon: 'fa-twitter',
        link: 'http://www.twitter.com/inclusivv'
      },
      {
        icon: 'fa-linkedin-in',
        link: 'https://www.linkedin.com/company/inclusivv'
      }
    ]

    const SocialMedia = () => (
      <section className='pane reg-pane darkgrey-pane no-gutters'>
        <div className='row center-xs around-xs flex-center-sm no-gutters'>
          {map(SocialMediaItems, (item) => (
            <div key={item.link} className='col-xs-2 col-sm-1 no-gutters'>
              <a href={item.link} target='_blank' rel='noopener noreferrer'>
                <span className='fa-stack header-28 fa-2x'>
                  <i className='fas fa-circle fa-stack-2x white-text' />
                  <i className={`fab ${item.icon} fa-stack-1x darker-grey-text`} />
                </span>
              </a>
            </div>
          ))}
        </div>
      </section>
    )

    const aboutColumn = () => {
      if (!global.inclusivv) return null

      return (
        <div className='col-xs-6 col-sm-2'>
          <p className='bold'>{i18n.t('nav.about')}</p>
          <p className={'no-margin'}>
            <a href={ABOUT_LINKS.find((al) => al.label === 'nav.ourStory')?.url} target='_blank' rel='noopener noreferrer'>
              {i18n.t('nav.ourStory')}
            </a>
          </p>
        </div>
      )
    }

    const hostingColumn = () => {
      if (!global.inclusivv) return null

      return (
        <div className='col-xs-6 col-sm-2'>
          <p className='bold'>{i18n.t('nav.hosting')}</p>
          <p className={'no-margin'}>
            <a href={EXPLORE_LINKS.find((al) => al.label === 'nav.howToHost')?.url} target='_blank' rel='noopener noreferrer'>
              {i18n.t('nav.howToHost')}
            </a>
          </p>
          <p className={'no-margin'}>
            <a href='https://help.inclusivv.co/en/articles/2279553-hosting-faq' target='_blank' rel='noopener noreferrer'>
              {i18n.t('footer.hostfaq')}
            </a>
          </p>
        </div>
      )
    }

    const Links = () => (
      <>
        {/* <section className='pane reg-pane darkestgrey-pane' style={{ paddingBottom: '6rem' }}>
          <div className='row around-xs'>
            {aboutColumn()}

            {hostingColumn()}

            <div className='col-xs-6 col-sm-2'>
              <p className='bold capitalize'>{global.siteTitle}</p>

              <p className={'no-margin'}>
                <Link to='/privacy_policy' target='blank'>
                  {i18n.t('footer.privacy')}
                </Link>
              </p>
              <p className={'no-margin'}>
                <Link to='/cookie_policy' target='blank'>
                  {i18n.t('footer.cookie')}
                </Link>
              </p>
            </div>
          </div>
        </section> */}
        <section className='pane reg-pane darkestgrey-pane' style={{ paddingBottom: '1rem' }}>
          <div className='row around-xs'>
            <Typography variant='caption'>
              © 2017-{new Date().getFullYear()}{' '}
              <a href='https://www.inclusivv.co' style={{ color: '#ffffff' }} target='_blank'>
                {/* {global.siteTitle} */}
                Inclusivv
              </a>{' '}
              |{' '}
              <Link to='/privacy_policy' style={{ color: '#ffffff' }} target='_blank'>
                {i18n.t('footer.privacy')}
              </Link>{' '}
              |{' '}
              <Link to='/cookie_policy' target='blank' style={{ color: '#ffffff' }}>
                {i18n.t('footer.cookie')}
              </Link>{' '}
              |{' '}
              <a href={EXPLORE_LINKS.find((al) => al.label === 'nav.howToHost')?.url} target='_blank' rel='noopener noreferrer' style={{ color: '#ffffff' }}>
                {i18n.t('nav.howToHost')}
              </a>{' '}
              |{' '}
              <a href='https://help.inclusivv.co/en/articles/2279553-hosting-faq' target='_blank' rel='noopener noreferrer' style={{ color: '#ffffff' }}>
                {i18n.t('footer.hostfaq')}
              </a>
            </Typography>
          </div>
        </section>
      </>
    )

    const ComDLinks = () => (
      <section className='pane reg-pane lightestgrey-pane' style={{ textAlign: 'center', paddingBottom: '6rem' }}>
        <p
          dangerouslySetInnerHTML={{
            __html: i18n.t('footer.fbCollab', {
              link: 'https://www.inclusivv.co'
            })
          }}
        />
        <div className='row around-xs'>
          <div className='col-xs-12 col-sm-4 center-xs header-12-sm'>
            <p className={'no-margin'}>
              <Link to='/terms-of-service'>{i18n.t('footer.terms')}</Link> &#183;
              <Link to='/privacy_policy'>{i18n.t('footer.privacy')}</Link> &#183;
              <Link to='/cookie_policy'>{i18n.t('footer.cookie')}</Link>
            </p>
          </div>
        </div>
      </section>
    )

    return (
      <div>
        {/* {!global.coco && <SocialMedia />} */}

        <Links />
      </div>
    )
  }
}

// function mapStateToProps(state, ownProps) {}

function mapDispatchToProps(dispatch) {
  return {
    showDialog: (type, data) => dispatch(showDialog(type, data))
  }
}

export default connect(null, mapDispatchToProps)(connectTopicTheme(withTranslation(withRouter(Footer))))
