import React from 'react'
import { Button, Card } from '@material-ui/core'

import { useForm } from 'react-hook-form'
import { FacebookShareUploaderControl, TextFieldControl } from '../utils'
import { Prompt } from 'react-router'
import { UNSAVED_FORM_WARNING } from '../utils/constants'

const SocialMediaEditBase = ({ item, onSave }: FIXME) => {
  const { handleSubmit, errors, formState, control, reset } = useForm({ mode: 'onBlur' })
  const { isDirty, isSubmitting } = formState

  const handleSave = handleSubmit((data: any) => {
    onSave &&
      onSave(data).then(({ metaTitle, metaDescription }) => {
        reset(
          { metaTitle, metaDescription },
          {
            errors: false, // errors will not be reset
            dirtyFields: false, // dirtyFields will not be reset
            isDirty: false, // dirty will not be reset
            isSubmitted: true,
            touched: false,
            isValid: true,
            submitCount: true
          }
        )
      })
  })

  return (
    <Card className='no-padding xtra-huge-bottom no-shadow'>
      <form onSubmit={handleSave}>
        <div className='reg-bottom'>
          <FacebookShareUploaderControl name='metaImage' image={item?.metaImage} hasImage={item?.hasMetaImage} control={control} error={errors && errors.metaImage} />
        </div>

        <div className='reg-bottom'>
          <TextFieldControl
            name='metaTitle'
            control={control}
            defaultValue={item?.metaTitle || ''}
            error={errors && errors.metaTitle}
            label='Title for SEO and Social Media'
            className='reg-bottom'
          />
        </div>

        <TextFieldControl
          name='metaDescription'
          control={control}
          defaultValue={item?.metaDescription || ''}
          error={errors && errors.metaDescription}
          label='Description for SEO and Social Media'
          className='reg-bottom'
          multiline={true}
          rows={3}
        />
        <div className='row no-gutters xtra-fat-top'>
          <div className='col-xs-12 no-gutters end-xs'>
            <Button disabled={!isDirty || isSubmitting} type='submit' className='reg-left' color='secondary' variant='contained'>
              Update
            </Button>
          </div>
        </div>
        <Prompt when={isDirty && !isSubmitting} message={UNSAVED_FORM_WARNING} />
      </form>
    </Card>
  )
}

export default SocialMediaEditBase
