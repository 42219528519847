import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Card from '@material-ui/core/Card'

import * as validations from '../validations/fieldLevelValidations'
import PageLoadingAnimation from '../shared/PageLoadingAnimation'
import Button from '@material-ui/core/Button'
import withTranslation from '../hocs/withTranslation'
import { withRouter } from 'react-router'
import { observer, inject } from 'mobx-react'
import { IStore } from '../../store/models'
import { useForm } from 'react-hook-form'
import { AddressAutocompleteControl, AvatarControl, TextFieldControl, CheckboxControl, ChipGroupControl } from '../utils'
import { IUser } from '../../store/models/User'
import { IDialog } from '../../store/models/Dialog'
import { flow, identity, pick, pickBy, camelCase } from 'lodash'
import { camelify } from '../../decorators/textTools'
import { InputLabel } from '@material-ui/core'
import { INTERESTS } from './Signup/Signup2Dialog'
import { palette } from '../../themes'

const UserProfile = ({ t, user, loadingUser, history, showDialog, afterSave, reloadUser }: Props & InjectedProps) => {
  const { handleSubmit, errors, formState, control, watch, setValue, setError, reset } = useForm({ mode: 'onBlur' })

  const { isDirty, isSubmitting, dirtyFields } = formState
  const location = watch('location')
  const [userLoaded, setUserLoaded] = useState(false)

  useEffect(() => {
    if (user && location && location !== user.city) {
      setValue('city', location.city, { shouldDirty: true })
      setValue('placeId', location.place_id, { shouldDirty: true })
    }
  }, [dirtyFields.location, user, location, setValue])

  useEffect(() => {
    reloadUser().then(() => setUserLoaded(true))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!user && userLoaded) {
      setUserLoaded(false)
      reloadUser().then(() => setUserLoaded(true))
    }
    // eslint-disable-next-line
  }, [user, userLoaded])

  if (!userLoaded || (!user && loadingUser)) {
    return <PageLoadingAnimation />
  }

  if (!user) {
    return null
  }

  const deleteUserDialog = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    showDialog('deleteUser', { id: user.id })
  }

  const displayError = (error) => {
    if (error) {
      for (const fieldName in error) {
        setError(camelCase(fieldName), {
          type: 'manual',
          message: error[fieldName]
        })
      }
    }
  }

  const onSave = (data: any) => {
    const { location: locationData, ...rest } = data
    const updateData = { ...camelify(locationData), ...pick(rest, Object.keys(pickBy(dirtyFields, identity))) }
    user
      .updateProfile(updateData)
      .then(() => (afterSave ? afterSave() : history.push(`/users/${user.id}`)))
      .catch(displayError)
  }

  return (
    <div className='very-light-grey-pane'>
      <div className='col-xs-12 center-margin form-xl fat-top-padding no-gutters'>
        <div className='row no-gutters'>
          <div className='col-xs-6 no-gutters'>
            <div className='header-24 bold thin-bottom'>
              {t('user.editProfile')} ({user.fullName})
            </div>
          </div>
          <div className='col-xs-6 no-gutters end-xs'>
            <Link to={`/users/${user.id}`} className='link-text header-18'>
              <i className='far fa-long-arrow-left' />
              &nbsp;
              {t('user.backToProfile')}
            </Link>
          </div>
        </div>
        <Card className='fat-padding xtra-fat-bottom'>
          <form onSubmit={handleSubmit(onSave)}>
            <div className='label thin-bottom'>{t('user.profileImage')}</div>

            <AvatarControl name='profileImage' control={control} avatarSize={'primary'} profileImage={user.profileImage} hasProfileImage={user.hasProfileImage} />

            <TextFieldControl
              name='firstName'
              label={t('common.firstName')}
              defaultValue={user.firstName}
              rules={{ validate: { required: validations.required } }}
              control={control}
              error={errors?.firstName}
              className='fat-top fat-bottom'
            />

            <TextFieldControl
              name='lastName'
              label={t('common.lastName')}
              defaultValue={user.lastName}
              rules={{ validate: { required: validations.required } }}
              control={control}
              error={errors?.lastName}
              className='fat-bottom'
            />

            <TextFieldControl
              name='email'
              label={t('user.emailLabel')}
              defaultValue={user.email}
              rules={{ validate: { required: validations.required, email: validations.email } }}
              control={control}
              error={errors?.email}
              className='fat-bottom'
              disabled={user.sso}
            />

            <AddressAutocompleteControl
              name='location'
              control={control}
              label={t('user.locationLabel')}
              defaultValue={user?.city}
              rules={{ validate: { required: validations.required } }}
              error={errors?.location}
              className='fat-bottom'
            />

            <TextFieldControl name='city' defaultValue={user.city} control={control} className='hide' />
            <TextFieldControl name='placeId' defaultValue={user.placeId} control={control} className='hide' />

            <TextFieldControl name='pronoun' label={t('user.pronoun')} defaultValue={user.pronoun} control={control} error={errors?.pronoun} className='fat-bottom' />
            <TextFieldControl name='title' label={t('user.titleLabel')} defaultValue={user.title} control={control} error={errors?.title} className='fat-bottom' />

            <TextFieldControl
              name='phoneNumber'
              label={t('user.phoneNumber')}
              defaultValue={user.phoneNumber}
              control={control}
              error={errors?.phoneNumber}
              className='fat-bottom'
            />

            <TextFieldControl
              name='company_name'
              label={t('user.companyLabel')}
              defaultValue={user.companyName}
              control={control}
              error={errors?.companyName}
              className='fat-bottom'
            />

            <TextFieldControl
              name='twitterLink'
              label={t('user.twitterLink')}
              defaultValue={user.twitterLink}
              control={control}
              error={errors?.twitterLink}
              className='fat-bottom'
              rules={{ validate: validations.http }}
            />

            <TextFieldControl
              name='facebookLink'
              label={t('user.facebookLink')}
              defaultValue={user.facebookLink}
              control={control}
              error={errors?.facebookLink}
              className='fat-bottom'
              rules={{ validate: validations.http }}
            />

            <TextFieldControl
              name='instagramLink'
              label={t('user.instagramLink')}
              defaultValue={user.instagramLink}
              control={control}
              error={errors?.instagramLink}
              className='fat-bottom'
              rules={{ validate: validations.http }}
            />

            <TextFieldControl
              name='linkedinLink'
              label={t('user.linkedinLink')}
              defaultValue={user.linkedinLink}
              control={control}
              error={errors?.linkedinLink}
              className='fat-bottom'
              rules={{ validate: validations.http }}
            />

            <TextFieldControl
              name='websiteLink'
              label={t('user.webLink')}
              defaultValue={user.websiteLink}
              control={control}
              error={errors?.websiteLink}
              className='fat-bottom'
              rules={{ validate: validations.http }}
            />

            <TextFieldControl
              name='bio'
              label={t('user.bio')}
              defaultValue={user.bio}
              control={control}
              error={errors?.bio}
              className='fat-bottom textarea-label'
              multiline={true}
              rows={3}
            />

            <div className='fat-bottom'>
              <InputLabel>{t('auth.signupInterest')}</InputLabel>

              {user && user?.interests && (
                <ChipGroupControl
                  name='interests'
                  helperText={t('auth.signupSelectAllApply')}
                  control={control}
                  defaultValue={Array.from(user?.interests || [])}
                  error={errors && errors.interests}
                  options={INTERESTS}
                />
              )}
            </div>

            <TextFieldControl
              name='magicWand'
              label={t('user.magicwand')}
              defaultValue={user.magicWand}
              control={control}
              error={errors?.magicWand}
              className='fat-bottom textarea-label'
              multiline={true}
              rows={3}
            />

            <TextFieldControl
              name='favoriteFood'
              label={t('user.foodLabel')}
              defaultValue={user.favoriteFood}
              control={control}
              error={errors?.favoriteFood}
              className='fat-bottom'
            />

            <TextFieldControl
              name='favoriteRestaurant'
              label={t('user.restaurantLabel')}
              defaultValue={user.favoriteRestaurant}
              control={control}
              error={errors?.favoriteRestaurant}
              className='fat-bottom'
            />

            <CheckboxControl
              name='public'
              label={t('user.publicLabel')}
              control={control}
              defaultValue={user.public}
              error={errors && errors.public}
              className='fat-bottom fat-top'
            />

            <div className='row no-gutters xtra-fat-bottom'>
              <Button disabled={!isDirty || isSubmitting} type='submit' variant='contained' color='primary'>
                {t('common.submit')}
              </Button>
            </div>

            {/* eslint-disable-next-line */}
            <a onClick={deleteUserDialog} style={{ color: palette.red500 }}>
              {t('user.deleteProfile')}
            </a>
          </form>
        </Card>
      </div>
    </div>
  )
}

interface Props {
  t?: any
  history?: any
  data: any
  dialog: IDialog
  match?: any
  afterSave?()
}

interface InjectedProps {
  user?: IUser
  loadingUser: boolean
  showDialog(name: string, data: any)
  reloadUser()
}

export default flow(
  withRouter,
  withTranslation,
  observer,
  inject<InjectedProps, Props>(({ mst }: { mst: IStore }, ownProps) => {
    const { users, dialogs, auth } = mst
    const {
      match: {
        params: { id }
      }
    } = ownProps
    const userId = parseInt(id, 10)
    if (!auth.user || (auth.user.id !== userId && auth.user.isSuperAdmin())) {
      // ownProps.history.push('/login')
    }
    const user = users.list.find((u) => u.id === userId)
    const loadingUser = users.loadingIds.includes(userId)
    if (!user && !loadingUser) {
      // users.shouldLoad(userId, () => users.loadById(userId))
    }
    userId && users.shouldLoad(userId, () => users.loadById(userId))

    return {
      counts: users.list.length,
      user,
      loadingUser,
      showDialog: dialogs.showDialog,
      reloadUser: () => {
        return users.loadById(userId)
      }
    }
  })
)(UserProfile)
