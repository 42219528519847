import { types, Instance } from 'mobx-state-tree'
import { Alertable } from '../helpers/Alertable'
import { Dialogable } from '../helpers/Dialogable'
import Editable from '../helpers/Editable'
import { LoadableStatus } from '../helpers/LoadableStatus'

export const PartnerBase = types.compose(
  'PartnerBase',
  LoadableStatus,
  Dialogable,
  Alertable,
  Editable,
  types.model({
    id: types.identifierNumber,
    name: types.string,
    slug: types.string
  })
)

export interface IPartnerBase extends Instance<typeof PartnerBase> {}
export default PartnerBase
