import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import { flow, sortBy } from 'lodash'
import withTranslation from '../hocs/withTranslation'
import { observer } from 'mobx-react'
import { useForm } from 'react-hook-form'
import { TextFieldControl, WysiwygControl, SwitchControl, SelectControl } from '../utils'
import { IConversation } from '../../store/models/Conversation'
import { IConversationNotification } from '../../store/models/Conversation/ConversationNotifications'
import { snakify } from '../../decorators/textTools'
import * as validations from '../validations/fieldLevelValidations'
import { Card, CircularProgress, FormControlLabel, FormHelperText, InputLabel, Switch } from '@material-ui/core'
import { Label } from '@material-ui/icons'

interface Props {
  conversation: IConversation
  notification: IConversationNotification
  t: any
}
const ConversationNotificationsForm = ({ notification, conversation, t }: Props) => {
  const { handleSubmit, errors, formState, control, watch, reset } = useForm({ mode: 'onBlur' })
  const { isDirty, isSubmitting } = formState
  const [inProgress, setInProgress] = useState(false)

  const afterCreate = ({ data = undefined }: any = {}) => {
    data && reset(data)
  }

  const handleSave = handleSubmit((val: any, e) => {
    if (notification.type === 'TopicNotification') {
      const newNotification = snakify({ ...notification, ...val })
      delete newNotification.id
      conversation.createConversationNotification(newNotification, conversation.id).then(() => conversation.loadConversationNotifications())
    } else {
      notification.update(val).then(afterCreate)
    }
  })

  const currentActiveState = watch('active') !== undefined ? watch('active') : notification?.active

  const toggleActiveStatus = (e) => {
    setInProgress(true)
    const newVal = !notification.active

    if (notification.type === 'TopicNotification') {
      const newNotification = snakify({ ...notification, active: newVal })
      delete newNotification.id
      conversation.createConversationNotification(newNotification, conversation.id).then(() => conversation.loadConversationNotifications())
    } else {
      notification
        .update({ active: newVal }, { dirty: notification.dirty, alert: `Email ${newVal ? 'activated' : 'disabled'}` })
        .then(afterCreate)
        .then(() => setInProgress(false))
        .catch(() => setInProgress(false))
    }
  }

  return (
    <Card key={notification.id} className='xtra-huge-bottom no-shadow reg-padding'>
      <form onSubmit={handleSave}>
        <TextFieldControl
          name='subject'
          control={control}
          defaultValue={notification?.subject || ''}
          error={errors && errors.subject}
          label={t('convoNotifications.subjectLabel')}
          required
          rules={{ validate: validations.required }}
          className='reg-bottom'
        />

        {notification.sendDate && (
          <div>
            <SelectControl
              name='sendDate'
              label={t('convoNotifications.sendDateLabel')}
              className='reg-top'
              control={control}
              defaultValue={notification.sendDate}
              error={errors && errors.sendDate}
              options={[
                { value: '18_hours', label: '1 day' },
                { value: '3_days', label: '3 days' },
                { value: '1_week', label: '1 week' },
                { value: '2_weeks', label: '2 weeks' },
                { value: '3_weeks', label: '3 weeks' },
                { value: '1_month', label: '1 month' },
                { value: '2_months', label: '2 months' }
              ]}
            />
          </div>
        )}

        <WysiwygControl name='content' control={control} defaultValue={notification?.content || ''} error={errors && errors.content} dynamicTags={true} />
        <div className='row no-gutters reg-top thin-left'>
          <div className='col-xs-4 no-gutters'>
            <FormControlLabel
              disabled={inProgress}
              control={<Switch checked={notification?.active || false} onChange={toggleActiveStatus} />}
              label={
                inProgress ? (
                  <CircularProgress size='small' />
                ) : (
                  <InputLabel>{currentActiveState ? t('convoNotifications.activated') : t('convoNotifications.notActivated')}</InputLabel>
                )
              }
            />
          </div>
          <div className='col-xs-8 no-gutters end-xs'>
            <Button
              onClick={() => {
                notification.sendTestEmail({
                  testSubject: watch('subject') || notification?.subject,
                  testContent: watch('content') || notification?.content
                })
              }}
              variant='text'
            >
              {t('convoNotifications.sendTestEmail')}
            </Button>
            <Button disabled={!isDirty || isSubmitting} type='submit' color='secondary' variant='contained' className='reg-left'>
              {t('common.save')}
            </Button>
          </div>
        </div>
      </form>
    </Card>
  )
}

export default flow(observer, withTranslation)(ConversationNotificationsForm)
