import { types, Instance } from 'mobx-state-tree'
import OrganizationsApi from '../../api/OrganizationsApi'
import { Alertable } from './helpers/Alertable'
import { Filterable, IFilterableArgs } from './helpers/Filterable'
import { LoadableStatus } from './helpers/LoadableStatus'
import Organization from './Organization'
import { removeNullStringValue } from './utils'

import { snakify } from '../../decorators/textTools'

export const Organizations = types
  .compose(
    'Organizations',
    LoadableStatus,
    Alertable,
    Filterable,
    types.model({
      list: types.optional(types.array(Organization), [])
    })
  )
  .actions((self) => ({
    addToList(data: any) {
      removeNullStringValue(data)
      const found = self.list.find((o) => o.id === data.id)
      if (found) {
        Object.assign(found, data)
      } else {
        self.list.push(data)
      }
    },
    setList(data: any[], totalCount?: number) {
      data.forEach((d) => removeNullStringValue(d))
      self.list.replace(data)
      self.setTotalCount(totalCount)
    }
  }))
  .actions((self) => ({
    loadOrganizationsTable: (args: IFilterableArgs) => {
      if (self.isLoading('organizations_table')) {
        return
      }
      self.startLoading('organizations_table')
      self.updateArgs(args)

      return OrganizationsApi.getAll(args).then(({ response: { ok, statusText }, json: { organizations, total } }) => {
        if (ok) {
          self.setList(organizations, total)
        } else {
          self.showAlert(statusText)
        }
        self.stopLoading('organizations_table')
      })
    }
  }))
  .actions((self) => ({
    loadFilter: ({ reset = false, clear = true } = {}) => {
      if (reset) {
        self.page = 0
        self.pageSize = 15
        self.search = ''
      }

      if (clear) {
        self.list.replace([])
      }

      const { search, pageSize: page_size, page, sortedId: sorted_id, sortedPriority: sorted_priority } = self
      return self.loadOrganizationsTable({ search, page_size, page, sorted_id, sorted_priority })
    },
    afterCreate: () => {
      self.setSortingId('id')
      self.setSortedPriority(true)
    },
    createOrganization: (data: any) => {
      return OrganizationsApi.create(snakify(data)).then(({ response: { ok }, json }) => {
        if (ok) {
          self.addToList(json)
          return { data: json }
        } else {
          return { error: json }
        }
      })
    }
  }))
  .actions((self) => ({
    loadById: (id: string) => {
      if (self.isLoading(id)) {
        return
      }
      self.startLoading(id)
      return OrganizationsApi.get(id).then(({ response: { ok }, json }) => {
        if (ok) {
          self.addToList(json)
        }
        self.stopLoading(id)
      })
    }
  }))

export interface IOrganizations extends Instance<typeof Organizations> {}
export default Organizations
