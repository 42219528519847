import React, { useEffect, useState } from 'react'
import { Button, Tab, Tabs, Badge, Typography } from '@material-ui/core'
import { flow } from 'lodash'
import { inject } from 'mobx-react'
import { observer } from 'mobx-react'
import { IStore } from '../../../../store/models'
import { IUser } from '../../../../store/models/User'
import SummaryContainer from '../partials/SummaryContainer'
import GuestsTable from './GuestsTable'
import { withRouter, RouteComponentProps } from 'react-router'

interface Props {
  user?: IUser
  guests_total: number
  uniq_guests_total: number
  hosts_total: number
  users_total: number
  createCsvExport(val: string)
  setAttendeesType(string)
  loadFilter(args?: any)
  loadStats()
}

const GuestsSummary = (props: Props & RouteComponentProps) => {
  const [index, setIndex] = useState(0)

  const { user, createCsvExport, history, setAttendeesType, loadFilter, guests_total, users_total, uniq_guests_total, hosts_total, loadStats } = props
  useEffect(() => {
    setAttendeesType('guests')
    loadFilter({ reset: true, clear: true })
    loadStats()
    return () => {
      setAttendeesType('guests')
    }
  }, [loadStats, setAttendeesType, loadFilter])
  const [exported, setExported] = useState(false)
  const [exporting, setExporting] = useState(false)

  const numberFotmatter = (n) => n.toString().replace(/(.)(?=(\d{3})+$)/g, '$1,')
  return (
    <div>
      <SummaryContainer
        title={'Guests/Hosts'}
        id='ic-admin-attendees'
        topRight={
          user && (user.isSuperAdmin() || user.isPartnerAdmin()) ? (
            <div>
              <Button
                variant='contained'
                disabled={exporting}
                startIcon={<i className='fal fa-file-export' />}
                onClick={() => {
                  setExporting(true)
                  createCsvExport('user').then(() => {
                    setExported(true)
                    setExporting(false)
                  })
                }}
              >
                Export All Attendees CSV
              </Button>
              {exported && (
                <Button
                  className='reg-left'
                  startIcon={<i className='fas fa-cloud-download-alt' />}
                  onClick={() => {
                    history.push('/admin/analytics/downloads')
                  }}
                >
                  My Downloads
                </Button>
              )}
            </div>
          ) : undefined
        }
      >
        <Tabs
          value={index}
          onChange={(e, val) => {
            setIndex(val)

            if (val === 2) {
              setAttendeesType('all')
            } else if (val === 1) {
              setAttendeesType('hosts')
            } else {
              setAttendeesType('guests')
            }
            loadFilter({ reset: true, clear: true })
          }}
          indicatorColor='primary'
          textColor='primary'
          variant='fullWidth'
          centered
        >
          <Tab
            label={
              <>
                Guests
                <Typography className='thin-left-padding' variant='caption' component='span' color='textSecondary'>
                  ({numberFotmatter(uniq_guests_total)})
                </Typography>
              </>
            }
          />
          <Tab
            label={
              <>
                Hosts
                <Typography className='thin-left-padding' variant='caption' component='span' color='textSecondary'>
                  ({numberFotmatter(hosts_total)})
                </Typography>
              </>
            }
          />
          {user?.isSuperAdmin() && (
            <Tab
              label={
                <>
                  All Users
                  <Typography className='thin-left-padding' variant='caption' component='span' color='textSecondary'>
                    ({numberFotmatter(users_total)})
                  </Typography>
                </>
              }
            />
          )}
          {/* {user?.isSuperAdmin() && (
            <>
              <Tab label={'All Users'} />
              <Typography className='thin-left-padding' variant='caption' component='span' color='textSecondary'>
                ({users_total})
              </Typography>
            </>
          )} */}
        </Tabs>

        <GuestsTable />
      </SummaryContainer>
    </div>
  )
}

export default flow(
  withRouter,
  observer,
  inject<FIXME, FIXME>(
    ({
      mst: {
        auth: { user },
        users: { setAttendeesType, loadFilter },
        downloads: { createCsvExport },
        partners: { guests_total, uniq_guests_total, hosts_total, loadStats, users_total }
      }
    }: {
      mst: IStore
    }) => {
      return {
        user,
        createCsvExport,
        setAttendeesType,
        loadFilter,
        guests_total,
        uniq_guests_total,
        hosts_total,
        users_total,
        loadStats
      }
    }
  )
)(GuestsSummary)
