import { types, Instance } from 'mobx-state-tree'
import TracksApi from '../../api/TracksApi'
import { Alertable } from './helpers/Alertable'
import { LoadableStatus } from './helpers/LoadableStatus'
import { RootAccessable } from './helpers/RootAccessable'
import Track from './Track'
import { removeNullStringValue } from './utils'

export const Tracks = types
  .compose(
    'Tracks',
    LoadableStatus,
    Alertable,
    RootAccessable,
    types.model({
      list: types.optional(types.array(Track), []),
      userList: types.optional(types.array(Track), [])
    })
  )
  .actions((self) => ({
    addToList(data: any) {
      removeNullStringValue(data)
      const found = self.list.find((p) => p.id === data.id)
      if (found) {
        Object.assign(found, data)
      } else {
        self.list.push(data)
      }
    },
    setList(data: any) {
      removeNullStringValue(data)
      self.list.replace(data)
    },
    setUserList(data: any) {
      removeNullStringValue(data)
      self.userList.replace(data)
    }
  }))
  .actions((self) => ({
    loadTracks: () => {
      const listName = 'all'
      if (self.isLoading(listName)) {
        return
      }
      self.startLoading(listName)
      return TracksApi.getAll().then(({ response: { ok }, json }) => {
        if (ok) {
          self.setUserList(json)
        }
        self.stopLoading(listName)
      })
    },
    joinMembership: (token: string, joinConversation?: string) => {
      const { user } = self.getAuth()
      if (!user) return Promise.reject()
      return TracksApi.joinMembership(token, joinConversation).then(({ response: { ok }, json }) => {
        if (ok) {
          self.showAlert('You are a member now')
          return true
        } else {
          if (json.error) self.showAlert(json.error)
          return false
        }
      })
    },
    loadHsMembership: () => {
      return TracksApi.loadHsMembership()
    },
    loadByPartnerId: (partnerId: number) => {
      if (self.isLoading(partnerId)) {
        return Promise.resolve()
      }
      self.startLoading(partnerId)
      return TracksApi.getByPartnerId(partnerId).then(({ response: { ok }, json }) => {
        if (ok) {
          self.setList(json)
        }
        self.stopLoading(partnerId)
      })
    }
  }))

export interface ITracks extends Instance<typeof Tracks> {}
export default Tracks
