import { assign } from 'lodash'
import React from 'react'
import { useAuth, useParamTopic } from '../../../Providers'
import appAnalytics from '../../../../analytics'
import { useTranslation } from 'react-i18next'
import ButtonIfLoggedIn from '../../../utils/ButtonIfLoggedIn'
import { observer } from 'mobx-react'
import Dialog from '../../../DialogWrapper'
import { Container } from '@material-ui/core'
type Props = {
  //  props
}

export const SubscribeButton = observer((props: Props) => {
  const { topic } = useParamTopic()
  const { user, showLogin, loaded } = useAuth()
  const { t } = useTranslation()

  if (!topic) return null
  if (!topic.subscriptionEnabled) return null
  const subscribed = topic && user && user.likes?.find((f) => f.likableId === topic.id && f.likableType === 'Topic' && f.kind === 'subscribe')
  const [showUnsubscribedDialog, setShowUnsubscribedDialog] = React.useState(false)

  const label = !subscribed ? topic.subscriptionCtaLabel : topic.unsubscribeCtaLabel
  const newOptions = {
    fullWidth: true,
    className: 'btn-xl',
    onClick: (e) => {
      appAnalytics.event({
        category: 'Topic',
        action: 'Subscribe'
      })

      if (!topic) return
      if (loaded && !user) {
        showLogin()
        return
      }

      if (subscribed) {
        topic.topicUnsubscribe(subscribed.id).then(() => setShowUnsubscribedDialog(true))
      } else {
        if (topic.surveyQuestions.length === 0) {
          topic.loadSurveyQuestions().then(() => {
            topic.showDialog('topicSubscribe', { topic })
          })
        } else {
          topic.showDialog('topicSubscribe', { topic })
        }
      }
    },
    color: subscribed ? '' : 'primary',
    disabled: false,
    label: label || t('convos.getUpdates')
  }

  return (
    <>
      <div className='col-xs-12 col-sm-4'>
        <div className='box'>
          <ButtonIfLoggedIn {...newOptions} />
        </div>
      </div>
      <Dialog
        open={showUnsubscribedDialog}
        dialog={{
          closeDialog: () => setShowUnsubscribedDialog(false)
        }}
        title={'Topic Unsubscribed'}
      >
        <Container>
          <div className='fat-top fat-bottom'>
            You will no longer receive updates regarding this Topic. If you unsubscribed in error, simply click the subscription button again.
          </div>
        </Container>
      </Dialog>
    </>
  )
})
