import { types, Instance } from 'mobx-state-tree'

export const Invite = types.model({
  id: types.identifierNumber,
  createdAt: types.string,
  invitableType: types.maybeNull(types.string),
  deniedAt: types.maybeNull(types.string),
  senderId: types.maybeNull(types.number),
  recipientEmail: types.maybeNull(types.string)
})
export interface IInvite extends Instance<typeof Invite> {}
export default Invite
