// session
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS'
export const LOG_IN_FAILED = 'LOG_IN_FAILED'
export const LOG_OUT = 'LOG_OUT'

// user coords
export const USER_COORDS_LOADING = 'USER_COORDS_LOADING'
export const LOAD_USER_COORDS_SUCCESS = 'LOAD_USER_COORDS_SUCCESS'
export const LOAD_USER_COORDS_FAILED = 'LOAD_USER_COORDS_FAILED'

// current user
export const LOAD_CURRENT_USER_START = 'LOAD_CURRENT_USER_START'
export const LOAD_CURRENT_USER_SUCCESS = 'LOAD_CURRENT_USER_SUCCESS'
export const REMOVE_CURRENT_USER_SUCCESS = 'REMOVE_CURRENT_USER_SUCCESS'

// topics
export const LOAD_CONVERSATIONS_SUCCESS = 'LOAD_CONVERSATIONS_SUCCESS'
export const LOAD_NEW_CONVERSATIONS_SUCCESS = 'LOAD_NEW_CONVERSATIONS_SUCCESS'
export const LOAD_CONVERSATIONS_FAILED = 'LOAD_CONVERSATIONS_FAILED'
export const CONVERSATIONS_LOADING = 'CONVERSATIONS_LOADING'
export const CONVERSATION_LOADING = 'CONVERSATION_LOADING'
export const LOAD_CONVERSATION_SUCCESS = 'LOAD_CONVERSATION_SUCCESS'
export const DELETE_CONVERSATION_SUCCESS = 'DELETE_CONVERSATION_SUCCESS'
export const LOAD_CONVERSATION_DINNERS_SUCCESS = 'LOAD_CONVERSATION_DINNERS_SUCCESS'
export const LOAD_NEW_CONVERSATION_DINNERS_SUCCESS = 'LOAD_NEW_CONVERSATION_DINNERS_SUCCESS'
export const LOAD_CONVERSATION_DINNERS_FAILED = 'LOAD_CONVERSATION_DINNERS_FAILED'
export const CONVERSATION_DINNERS_LOADING = 'CONVERSATION_DINNERS_LOADING'
export const RANKED_CONVERSATIONS_LOADING = 'RANKED_CONVERSATIONS_LOADING'
export const LOAD_RANKED_CONVERSATIONS_FAILED = 'LOAD_RANKED_CONVERSATIONS_FAILED'
export const LOAD_RANKED_CONVERSATIONS_SUCCESS = 'LOAD_RANKED_CONVERSATIONS_SUCCESS'

export const CONVERSATION_OPTIONS_LOADING = 'CONVERSATION_OPTIONS_LOADING'
export const LOAD_CONVERSATION_OPTIONS_SUCCESS = 'LOAD_CONVERSATION_OPTIONS_SUCCESS'
export const LOAD_CONVERSATION_OPTIONS_FAILED = 'LOAD_CONVERSATION_OPTIONS_FAILED'

// topicNotifications
export const LOAD_CONVERSATION_NOTIFICATIONS_SUCCESS = 'LOAD_CONVERSATION_NOTIFICATIONS_SUCCESS'
export const LOAD_CONVERSATION_NOTIFICATIONS_FAILED = 'LOAD_CONVERSATION_NOTIFICATIONS_FAILED'
export const CONVERSATION_NOTIFICATIONS_LOADING = 'CONVERSATION_NOTIFICATIONS_LOADING'

// conversationNotifications
export const LOAD_DINNER_NOTIFICATIONS_SUCCESS = 'LOAD_DINNER_NOTIFICATIONS_SUCCESS'
export const LOAD_DINNER_NOTIFICATIONS_FAILED = 'LOAD_DINNER_NOTIFICATIONS_FAILED'
export const DINNER_NOTIFICATIONS_LOADING = 'DINNER_NOTIFICATIONS_LOADING'

// conversations
export const LOADING_DINNER = 'LOADING_DINNER'
export const LOAD_DINNER_SUCCESS = 'LOAD_DINNER_SUCCESS'
export const LOAD_DINNER_FAILED = 'LOAD_DINNER_FAILED'
export const DELETE_DINNER_SUCCESS = 'DELETE_DINNER_SUCCESS'

export const LOADING_DINNERS = 'LOADING_DINNERS'
export const LOAD_DINNERS_SUCCESS = 'LOAD_DINNERS_SUCCESS'
export const LOAD_NEW_DINNERS_SUCCESS = 'LOAD_NEW_DINNERS_SUCCESS'
export const LOAD_DINNERS_FAILED = 'LOAD_DINNERS_SUCCESS'

export const CREATE_USER_DINNER_SUCCESS = 'CREATE_USER_DINNER_SUCCESS'
export const DELETE_USER_DINNER_SUCCESS = 'DELETE_USER_DINNER_SUCCESS'

export const LOADING_HOSTED_DINNERS = 'LOADING_HOSTED_DINNERS'
export const LOAD_HOSTED_DINNERS_SUCCESS = 'LOAD_HOSTED_DINNERS_SUCCESS'
export const LOAD_HOSTED_DINNERS_FAILED = 'LOAD_HOSTED_DINNERS_FAILED'

// users
export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS'
export const LOAD_USERS_FAILED = 'LOAD_USERS_FAILED'
export const USERS_LOADING = 'USERS_LOADING'
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'

// guests
export const GUESTS_LOADING = 'GUESTS_LOADING'
export const LOAD_GUESTS_SUCCESS = 'LOAD_GUESTS_SUCCESS'
export const LOAD_NEW_GUESTS_SUCCESS = 'LOAD_NEW_GUESTS_SUCCESS'
export const LOAD_GUESTS_FAILED = 'LOAD_GUESTS_FAILED'
export const ADD_GUEST = 'ADD_GUEST'
export const REMOVE_GUEST = 'REMOVE_GUEST'

// pending requested guests
export const PENDING_REQUESTED_GUESTS_LOADING = 'PENDING_REQUESTED_GUESTS_LOADING'
export const LOAD_PENDING_REQUESTED_GUESTS_SUCCESS = 'LOAD_PENDING_REQUESTED_GUESTS_SUCCESS'
export const LOAD_PENDING_REQUESTED_GUESTS_FAILED = 'LOAD_PENDING_REQUESTED_GUESTS_FAILED'
export const REMOVE_PENDING_REQUESTED_GUEST = 'REMOVE_PENDING_REQUESTED_GUEST'
export const ACCEPT_PENDING_REQUEST_FAILED = 'ACCEPT_PENDING_REQUEST_FAILED'

// pending invited guests
export const PENDING_INVITED_GUESTS_LOADING = 'PENDING_INVITED_GUESTS_LOADING'
export const LOAD_PENDING_INVITED_GUESTS_SUCCESS = 'LOAD_PENDING_INVITED_GUESTS_SUCCESS'
export const LOAD_PENDING_INVITED_GUESTS_FAILED = 'LOAD_PENDING_INVITED_GUESTS_FAILED'

// user conversations
export const LOADING_USER_DINNERS = 'LOADING_USER_DINNERS'
export const LOAD_USER_DINNERS_SUCCESS = 'LOAD_USER_DINNERS_SUCCESS'
export const LOAD_USER_DINNERS_FAILED = 'LOAD_USER_DINNERS_FAILED'

// partners
export const PARTNERS_LOADING = 'PARTNERS_LOADING'
export const LOAD_PARTNERS_SUCCESS = 'LOAD_PARTNERS_SUCCESS'
export const LOAD_PARTNERS_FAILED = 'LOAD_PARTNERS_FAILED'
export const LOAD_PARTNER_SUCCESS = 'LOAD_PARTNER_SUCCESS'
export const LOAD_PARTNER_DINNERS_SUCCESS = 'LOAD_PARTNER_DINNERS_SUCCESS'
export const LOAD_NEW_PARTNER_DINNERS_SUCCESS = 'LOAD_NEW_PARTNER_DINNERS_SUCCESS'
export const LOAD_PARTNER_DINNERS_FAILED = 'LOAD_PARTNER_DINNERS_FAILED'
export const PARTNER_DINNERS_LOADING = 'PARTNER_DINNERS_LOADING'
export const LOAD_PARTNER_CONVERSATIONS_SUCCESS = 'LOAD_PARTNER_CONVERSATIONS_SUCCESS'
export const LOAD_MORE_PARTNER_CONVERSATIONS_SUCCESS = 'LOAD_MORE_PARTNER_CONVERSATIONS_SUCCESS'
export const LOAD_PARTNER_CONVERSATIONS_FAILED = 'LOAD_PARTNER_CONVERSATIONS_FAILED'
export const PARTNER_CONVERSATIONS_LOADING = 'PARTNER_CONVERSATIONS_LOADING'

// partner admins
export const PARTNER_ADMINS_LOADING = 'PARTNER_ADMINS_LOADING'
export const LOAD_PARTNER_ADMINS_SUCCESS = 'LOAD_PARTNER_ADMINS_SUCCESS'
export const LOAD_NEW_PARTNER_ADMINS_SUCCESS = 'LOAD_NEW_PARTNER_ADMINS_SUCCESS'
export const LOAD_PARTNER_ADMINS_FAILED = 'LOAD_PARTNER_ADMINS_FAILED'
export const REMOVE_PARTNER_ADMIN = 'REMOVE_PARTNER_ADMIN'
export const INVITE_PARTNER_ADMIN_LOADING = 'INVITE_PARTNER_ADMIN_LOADING'
export const INVITE_PARTNER_ADMIN_SUCCESS = 'INVITE_PARTNER_ADMIN_SUCCESS'
export const INVITE_PARTNER_ADMIN_FAILED = 'INVITE_PARTNER_ADMIN_FAILED'
export const PENDING_INVITED_PARTNER_ADMINS_LOADING = 'PENDING_INVITED_PARTNER_ADMINS_LOADING'
export const LOAD_PENDING_INVITED_PARTNER_ADMINS_SUCCESS = 'LOAD_PENDING_INVITED_PARTNER_ADMINS_SUCCESS'
export const LOAD_NEW_PENDING_INVITED_PARTNER_ADMINS_SUCCESS = 'LOAD_NEW_PENDING_INVITED_PARTNER_ADMINS_SUCCESS'
export const LOAD_PENDING_INVITED_PARTNER_ADMINS_FAILED = 'LOAD_PENDING_INVITED_PARTNER_ADMINS_FAILED'

// partner stats
export const PARTNER_STATS_LOADING = 'PARTNER_STATS_LOADING'
export const LOAD_PARTNER_STATS_SUCCESS = 'LOAD_PARTNER_STATS_SUCCESS'

// sections
export const SECTIONS_LOADING = 'SECTIONS_LOADING'
export const LOAD_SECTIONS_SUCCESS = 'LOAD_SECTIONS_SUCCESS'
export const LOAD_NEW_SECTIONS_SUCCESS = 'LOAD_NEW_SECTIONS_SUCCESS'
export const LOAD_SECTIONS_FAILED = 'LOAD_SECTIONS_FAILED'
export const DELETE_SECTIONS_SUCCESS = 'DELETE_SECTIONS_SUCCESS'

// sponsors
export const SPONSORS_LOADING = 'SPONSORS_LOADING'
export const LOAD_SPONSORS_SUCCESS = 'LOAD_SPONSORS_SUCCESS'
export const LOAD_SPONSORS_FAILED = 'LOAD_SPONSORS_FAILED'
export const DELETE_SPONSORS_SUCCESS = 'DELETE_SPONSORS_SUCCESS'

// topics partners
export const LOAD_CONVERSATIONS_PARTNERS_SUCCESS = 'LOAD_CONVERSATIONS_PARTNERS_SUCCESS'
export const CREATE_CONVERSATIONS_PARTNERS_SUCCESS = 'CREATE_CONVERSATIONS_PARTNERS_SUCCESS'

// alert message
export const SHOW_ALERT_MESSAGE = 'SHOW_ALERT_MESSAGE'
export const CLOSE_ALERT_MESSAGE = 'CLOSE_ALERT_MESSAGE'

// dialog
export const SHOW_DIALOG = 'SHOW_DIALOG'
export const CLOSE_DIALOG = 'CLOSE_DIALOG'

// tabs
export const UPDATE_TAB_POSITION = 'UPDATE_TAB_POSITION'

// password reset
export const PASSWORD_RESETS_CREATE_SUCCESS = 'PASSWORD_RESETS_CREATE_SUCCESS'
export const PASSWORD_RESETS_CREATE_ERROR = 'PASSWORD_RESETS_CREATE_ERROR'
export const PASSWORD_RESETS_USE_SUCCESS = 'PASSWORD_RESETS_USE_SUCCESS'
export const PASSWORD_RESETS_USE_ERROR = 'PASSWORD_RESETS_USE_ERROR'

// csv exports
export const LOADING_CSV_EXPORT = 'LOADING_CSV_EXPORT'
export const LOAD_CSV_EXPORT_SUCCESS = 'LOAD_CSV_EXPORT_SUCCESS'
export const LOAD_CSV_EXPORT_FAILED = 'LOAD_CSV_EXPORT_FAILED'

// conversation invitations
export const VERIFY_APPROVAL_TOKEN_SUCCESS = 'VERIFY_APPROVAL_TOKEN_SUCCESS'
export const REDEEM_APPROVAL_TOKEN_SUCCESS = 'REDEEM_APPROVAL_TOKEN_SUCCESS'

// conversation invitation requests
export const USER_INVITATION_REQUESTS_LOADING = 'USER_INVITATION_REQUESTS_LOADING'
export const LOAD_USER_INVITATION_REQUESTS_SUCCESS = 'LOAD_USER_INVITATION_REQUESTS_SUCCESS'
export const LOAD_USER_INVITATION_REQUESTS_FAILED = 'LOAD_USER_INVITATION_REQUESTS_FAILED'
export const REMOVE_USER_INVITATION_REQUESTS = 'REMOVE_USER_INVITATION_REQUESTS'

// conversations table
export const LOADING_DINNERS_TABLE = 'LOADING_DINNERS_TABLE'
export const LOAD_DINNERS_TABLE_SUCCESS = 'LOAD_DINNERS_TABLE_SUCCESS'
export const LOAD_DINNERS_TABLE_FAILED = 'LOAD_DINNERS_TABLE_FAILED'

// topics table
export const LOADING_CONVERSATIONS_TABLE = 'LOADING_CONVERSATIONS_TABLE'
export const LOAD_CONVERSATIONS_TABLE_SUCCESS = 'LOAD_CONVERSATIONS_TABLE_SUCCESS'
export const LOAD_CONVERSATIONS_TABLE_FAILED = 'LOAD_CONVERSATIONS_TABLE_FAILED'

// users table
export const LOADING_USERS_TABLE = 'LOADING_USERS_TABLE'
export const LOAD_USERS_TABLE_SUCCESS = 'LOAD_USERS_TABLE_SUCCESS'
export const LOAD_USERS_TABLE_FAILED = 'LOAD_USERS_TABLE_FAILED'

// invitations
export const INVITE_CREATE_SUCCESS = 'INVITE_CREATE_SUCCESS'
export const INVITE_REDEEM_SUCCESS = 'INVITE_REDEEM_SUCCESS'

// survey questions
export const SURVEY_QUESTIONS_LOADING = 'SURVEY_QUESTIONS_LOADING'
export const LOAD_SURVEY_QUESTIONS_SUCCESS = 'LOAD_SURVEY_QUESTIONS_SUCCESS'
export const LOAD_NEW_SURVEY_QUESTIONS_SUCCESS = 'LOAD_NEW_SURVEY_QUESTIONS_SUCCESS'
export const LOAD_SURVEY_QUESTIONS_FAILED = 'LOAD_SURVEY_QUESTIONS_FAILED'
export const DELETE_SURVEY_QUESTION_SUCCESS = 'DELETE_SURVEY_QUESTION_SUCCESS'

// survey answers
export const LOADING_SURVEY_ANSWERS = 'LOADING_SURVEY_ANSWERS'
export const LOAD_SURVEY_ANSWERS_SUCCESS = 'LOAD_SURVEY_ANSWERS_SUCCESS'
export const LOAD_SURVEY_ANSWERS_FAILED = 'LOAD_SURVEY_ANSWERS_FAILED'

// actions
export const ACTIONS_LOADING = 'ACTIONS_LOADING'
export const LOAD_ACTIONS_SUCCESS = 'LOAD_ACTIONS_SUCCESS'
export const LOAD_ACTIONS_FAILED = 'LOAD_ACTIONS_FAILED'
export const CREATE_ACTIONS_SUCCESS = 'CREATE_ACTIONS_SUCCESS'
export const DELETE_ACTIONS_SUCCESS = 'DELETE_ACTIONS_SUCCESS'

// user actions
export const LOADING_USER_ACTIONS = 'LOADING_USER_ACTIONS'
export const LOAD_USER_ACTIONS_SUCCESS = 'LOAD_USER_ACTIONS_SUCCESS'
export const LOAD_USER_ACTIONS_FAILED = 'LOAD_USER_ACTIONS_FAILED'
export const CREATE_USER_ACTION_SUCCESS = 'CREATE_USER_ACTION_SUCCESS'
export const UPDATE_USER_ACTION_SUCCESS = 'UPDATE_USER_ACTION_SUCCESS'
