import React from 'react'
import { CheckboxControl, TextFieldControl } from '../../utils'
import { StepProps } from './NewStepper'
import withTranslation, { WithTranslation } from '../../hocs/withTranslation'
import { Typography } from '@material-ui/core'

const SuitableDiets = ({ topic, t, control, watch }: StepProps & WithTranslation) => {
  const dietTypes = [
    { value: 'vegetarian', label: t('conversation.vegetarian') },
    { value: 'vegan', label: t('conversation.vegan') },
    { value: 'pescatarian', label: t('signup.pescatarian') },
    { value: 'nut_allergy', label: t('signup.nutAllergy') },
    { value: 'gluten_free', label: t('signup.gluten') },
    { value: 'dairy_free', label: t('conversation.dairyFree') }
  ]
  const otherDietTypes = []
  if (watch('conversationType') === 'no_food_provided') {
    return <></>
  }
  return (
    <>
      {/* <p>
        {t('conversation.suitableEventType', {
          eventType: topic.eventTypeSingular
        })}
      </p> */}
      <Typography variant='subtitle2' className='fat-top thin-bottom'>
        Special Diets <small>(optional)</small>
      </Typography>
      <Typography variant='body2'>What special diets will your meal accommodate?</Typography>
      <Typography variant='caption'>Select all that apply</Typography>
      <div>
        {dietTypes.map(({ label, value }) => (
          <div key={value}>
            <CheckboxControl name={`suitableDiets[${value}]`} label={label} control={control} />
          </div>
        ))}
        {watch('suitableDiets[other_diet]') &&
          otherDietTypes.map(({ label, value }) => (
            <div key={value}>
              <CheckboxControl name={`suitableDiets[${value}]`} label={label} control={control} />
            </div>
          ))}
        {watch('suitableDiets[other_diet]') && <TextFieldControl name='suitableDiets[other]' control={control} label={t('common.other')} defaultValue={''} />}
      </div>
    </>
  )
}

export default withTranslation(SuitableDiets)
