import * as types from '../actions/actionTypes'
import { assign } from 'lodash'
import initialState from './initialState'

export const pendingInvitedGuests = (state = initialState.pendingInvitedGuests, action) => {
  switch (action.type) {
    case types.LOAD_PENDING_INVITED_GUESTS_FAILED:
      return assign({}, state, {
        error: action.data,
        loading: false
      })

    case types.PENDING_INVITED_GUESTS_LOADING:
      return assign({}, state, {
        error: null,
        loading: true
      })

    case types.LOAD_PENDING_INVITED_GUESTS_SUCCESS:
      return assign({}, state, {
        error: null,
        loading: false,
        list: assign({}, action.pendingInvitedGuests)
      })

    default:
      return state
  }
}

export default pendingInvitedGuests
