import { Card, CardActions, CardContent, Grid, IconButton, Tooltip, Typography } from '@material-ui/core'
import { flow } from 'lodash'
import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { IStore } from '../../store/models'
import { inject, observer } from 'mobx-react'
import { ITopic } from '../../store/models/Topic'
import { IDocument } from '../../store/models/base/Document'

import CardMedia from '@material-ui/core/CardMedia'
import { makeStyles } from '@material-ui/core/styles'

interface Props {
  topic: ITopic
}

const DocumentList = ({
  topic,
  match: {
    params: { groupId }
  }
}: Props & RouteComponentProps<{ groupId?: string }>) => {
  return (
    <div className='col-xs-12 xtra-fat-bottom' style={{ minHeight: '100vh' }}>
      <div className='box'>
        <h1>Documents</h1>
        <Grid container direction='row' alignItems='center' spacing={2}>
          {topic &&
            topic.additionalFiles.filter((d) => (groupId ? d.groupId === groupId : true)).map((document) => <DocumentCard document={document} key={document.id} />)}
        </Grid>
      </div>
    </div>
  )
}

const DocumentCard = ({ document }: { document: IDocument }) => {
  const classes = useStyles()
  const fileUrl = document.file?.url || '/'
  const previewUrl = document.preview?.url || '/'
  const downloadUrl = document.download?.url || '/'
  return (
    <Grid item xs={6} sm={4} md={3} lg={2} xl={1}>
      <Card>
        <a href={fileUrl} target='blank'>
          <CardMedia className={classes.media} image={previewUrl} title='Preview' />
        </a>
        <CardContent style={{ minHeight: '2.5rem', wordWrap: 'break-word' }}>
          <Typography variant='caption'>{document.fileName()}</Typography>
        </CardContent>
        <CardActions>
          <Tooltip title='Download'>
            <a href={downloadUrl}>
              <IconButton aria-label='download' size='small'>
                <i className='fas fa-cloud-download opacity-4'></i>
              </IconButton>
            </a>
          </Tooltip>
        </CardActions>
      </Card>
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  media: {
    height: 0,
    // paddingTop: '56.25%' // 16:9
    paddingTop: '100%'
  }
}))

export default flow(
  observer,
  inject<FIXME, FIXME>(
    (
      {
        mst: { topics }
      }: {
        mst: IStore
      },
      {
        match: {
          params: { slug }
        }
      }
    ) => {
      const topic = topics.list.find((d) => d.slug === slug)
      !topic && topics.shouldLoad(slug, () => topics.loadBySlug(slug))
      topic && topic.shouldLoad('documents', topic.loadTopicDocuments)
      return {
        topic,
        topicLoading: topics.loadingIds.includes(slug)
      }
    }
  ),
  withRouter
)(DocumentList)
