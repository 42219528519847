import { get } from './utils'

class GuestsApi {
  static get(id) {
    return get(`/topics_partners/${id}`)
  }

  static getAll({ conversationId, limit, offset }) {
    return get(`/conversations/${conversationId}/guests?limit=${limit}&offset=${offset}`)
  }
}

export default GuestsApi
