import React, { Component } from 'react'
import { connect } from 'react-redux'

import selectors from '../../../getters/sponsorsFormGetter'

const sponsorsFormGetter = selectors.sponsorsFormGetter

class Sponsor extends Component {
  render() {
    const { size, logo, logoLink } = this.props

    const colSize = () => {
      switch (size) {
        case 'large':
          return 'col-xs-6 col-sm-4'
        case 'medium':
          return 'col-xs-6 col-sm-3'
        case 'small':
          return 'col-xs-6 col-sm-2'
        default:
          return ''
      }
    }

    const link = () => (logoLink ? logoLink : '#')

    return (
      <div className={colSize()}>
        <div className='box'>
          <a href={link()}>
            <img className='img-responsive center-margin' src={logo.large.url} alt='logo' />
          </a>
        </div>
      </div>
    )
  }
}

export default connect(sponsorsFormGetter, null)(Sponsor)
