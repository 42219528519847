import { SubmissionError } from 'redux-form'
import i18n from 'i18next'

import * as types from './actionTypes'
import PasswordResetsApi from '../api/PasswordResetsApi'
import { showAlertMessage } from './alertMessageActions'
import { history } from '../components/browserHistory'

const showAlert = (dispatch, message) => dispatch(showAlertMessage({ message }))

export function createPasswordResetSuccess(data) {
  return { type: types.PASSWORD_RESETS_CREATE_SUCCESS, ...data }
}

export function createPasswordReset(data) {
  return function(dispatch) {
    return PasswordResetsApi.createPasswordReset(data)
      .then(({ json }) => {
        if (json.error) {
          throw new SubmissionError({ _error: json.error })
        } else if (json.errors) {
          throw new SubmissionError(json.errors)
        } else dispatch(createPasswordResetSuccess(json))
      })
      .catch(error => {
        throw error
      })
  }
}

export function passwordResetSuccess(data) {
  return { type: types.PASSWORD_RESETS_CREATE_SUCCESS, ...data }
}

export function usePasswordReset(token, data) {
  return function(dispatch) {
    return PasswordResetsApi.usePasswordReset(token, data)
      .then(({ json }) => {
        if (json.error) {
          throw new SubmissionError({ _error: json.error })
        } else if (json.errors) {
          throw new SubmissionError(json.errors)
        } else {
          dispatch(passwordResetSuccess(json))
          history.push({
            pathname: '/',
            state: { forward: true }
          })
          showAlert(dispatch, i18n.t('auth.resetPasswordSuccess'))
        }
      })
      .catch(error => {
        throw error
      })
  }
}
