import React from 'react'

import Dialog from '../../../DialogWrapper'
import { observer } from 'mobx-react'
import { Button, Paper } from '@material-ui/core'
import { IMembership } from '../../../../store/models/Track/Membership'
import moment from 'moment'
import { useForm } from 'react-hook-form'
import { TextFieldControl, SelectControl, DateTimeControl } from '../../../utils'
import * as validations from '../../../validations/fieldLevelValidations'
import LoadingAnimation from '../../../shared/LoadingAnimation'
import { ITrack } from '../../../../store'
import { useAuth } from '../../../Providers'

const EditMemberDialog = ({ membership, onClose, tracks }: { membership?: IMembership; onClose?(); tracks: ITrack[] }) => {
  const { handleSubmit, errors, formState, control, watch, setError, reset, clearErrors, setValue } = useForm({ mode: 'onBlur' })
  const { isDirty, isSubmitting, dirtyFields, isValid } = formState
  const { user } = useAuth()

  const handleSave = handleSubmit(({ startsAt, endsAt, ...data }: any, e) => {
    membership?.updateMembership({ ...data, startsAt: startsAt.toString(), endsAt: endsAt.toString() }).then(() => {
      onClose && onClose()
    })
  })

  if (!membership) {
    return <LoadingAnimation />
  }

  return (
    <Dialog dialog={{ closeDialog: () => onClose && onClose() }} open={!!membership} name='editMembershipDialog' type='fatPadding' title='Edit Membership Subscription'>
      <section className='pane big-top-padding big-bottom-padding'>
        <div className='row reg-bottom'>
          <div className='col-xs'>
            <Paper elevation={0}>
              <form onSubmit={handleSave}>
                <TextFieldControl
                  name='companyName'
                  control={control}
                  defaultValue={membership.companyName}
                  error={errors && errors.companyName}
                  label={'Company Name'}
                  className='reg-bottom'
                />

                <TextFieldControl
                  name='seatLimit'
                  control={control}
                  defaultValue={membership.seatLimit}
                  error={errors && errors.seatLimit}
                  label={'Seat Limit'}
                  rules={{ validate: { min: validations.minNumber(1) } }}
                  // className='reg-bottom'
                  type='number'
                />

                <DateTimeControl
                  name='startsAt'
                  control={control}
                  defaultValue={membership?.startsAt ? moment(membership?.startsAt) : undefined}
                  error={errors && errors.startsAt}
                  label={'Start date'}
                  className='reg-bottom'
                  dateProps={{ timeFormat: false, closeOnSelect: true }}
                />
                <div className='reg-bottom'>
                  <DateTimeControl
                    name='endsAt'
                    control={control}
                    defaultValue={membership?.endsAt ? moment(membership?.endsAt) : undefined}
                    error={errors && errors.endsAt}
                    label={'End date'}
                    dateProps={{ timeFormat: false, closeOnSelect: true }}
                  />
                </div>

                <SelectControl
                  name='active'
                  label='Status'
                  control={control}
                  defaultValue={(!!membership.active).toString()}
                  error={errors && errors.active}
                  className='reg-bottom'
                  required
                  rules={{ validate: validations.required }}
                  options={[
                    { value: 'true', label: 'Active' },
                    { value: 'false', label: 'Inactive' }
                  ]}
                />

                {user?.isSuperAdmin() && (
                  <SelectControl
                    name='trackId'
                    label='Journey'
                    control={control}
                    defaultValue={membership.trackId}
                    error={errors && errors.trackId}
                    className='reg-bottom'
                    required
                    rules={{ validate: validations.required }}
                    options={tracks.map((track) => ({ label: track.dealName, value: track.id }))}
                  />
                )}

                <div className='giant-top'>
                  <Button variant='contained' className='reg-right' onClick={() => onClose && onClose()}>
                    Cancel
                  </Button>

                  <Button disabled={!isDirty || isSubmitting || !isValid} type='submit' color='primary' variant='contained'>
                    Update
                  </Button>
                </div>
              </form>
            </Paper>
          </div>
        </div>
      </section>
    </Dialog>
  )
}

export default observer(EditMemberDialog)
