import fetch from 'isomorphic-fetch'

class UserLmsItemsApi {
  static requestHeaders() {
    return {
      'Content-Type': 'application/json',
      AUTHORIZATION: `Bearer ${localStorage.jwt}`
    }
  }

  static getAll(userId) {
    const headers = this.requestHeaders()

    return fetch(`${process.env.REACT_APP_API_HOST}/users/${userId}/user_lms_items`, {
      method: 'GET',
      headers: headers
    })
      .then((response) => response.json().then((json) => ({ json, response })))
      .catch((error) => {
        return error
      })
  }
}

export default UserLmsItemsApi
