import React, { useContext } from 'react'
import DialogWrapper from '../../DialogWrapper'
import { IDialog } from '../../../store/models/Dialog'
import { DialogContext } from '../../Providers/DialogProvider'
import Signup1Form from './Signup1Form'
import { useHistory } from 'react-router'

interface Props {
  dialog: IDialog
}

const Signup1Dialog = ({ dialog }: Props) => {
  const history = useHistory()
  const { showDialog } = useContext(DialogContext)

  const moveToNextStep = () => {
    dialog.closeDialog()
    !window.coco && showDialog('signup2')
  }

  const { hosting } = dialog.data as any
  const openLoginDialog = () => {
    dialog.closeDialog()
    showDialog('login')
  }

  return (
    <DialogWrapper dialog={dialog} name='signup' closeIcon={true}>
      <Signup1Form onSignUp={moveToNextStep} onLogin={openLoginDialog} hosting={hosting} />
    </DialogWrapper>
  )
}

export default Signup1Dialog
