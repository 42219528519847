import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useForm } from 'react-hook-form'
import Dialog from '../../../DialogWrapper'
import FontIcon from '@material-ui/core/Icon'
import { Button, InputLabel, Typography } from '@material-ui/core'
import { FileButtonControl, TextFieldControl } from '../../../utils'
import { useTranslation } from 'react-i18next'
import * as validations from '../../../validations/fieldLevelValidations'
import Papa from 'papaparse'
import { palette } from '../../../../themes'
import DataTable, { ValueLabel } from '../../../shared/DataTable'
import { useHistory } from 'react-router'

const EmailInviteMemberDialog = observer((props) => {
  const {
    dialog,
    dialog: {
      data: { membershipUser }
    }
  } = props
  const { t } = useTranslation()
  const history = useHistory()
  const [csvUpload, setCsvUpload] = useState(false)
  const [csvEmails, setCsvEmails] = useState([])
  const [csvValidated, setCsvValidated] = useState<boolean | undefined>(undefined)
  const { handleSubmit, errors, formState, control, setValue, setError } = useForm({ mode: 'onChange' })
  const { isDirty, isSubmitting } = formState

  useEffect(() => {
    if (csvEmails && csvEmails.length > 0) {
      if (csvEmails.find((item) => validations.emails(item))) {
        setCsvValidated(false)
        setError('csvFile', { message: 'Invalid file' })
      } else {
        setCsvValidated(true)
      }
    } else {
      setCsvValidated(false)
    }
  }, [csvEmails, setCsvEmails, setError])

  const afterClose = () => {
    // dialog.closeDialog()
  }

  const handleSave = handleSubmit(({ emails, message }: any, e) => {
    membershipUser &&
      membershipUser?.sendInviteEmail(csvUpload ? csvEmails.join(',') : emails, message).then(() => {
        membershipUser.loadFilter()
        dialog.closeDialog()
        history.push(`/memberships/members/${membershipUser.membershipId}/invited`)
      })
  })

  const csvHandler = (file) => {
    if (!file) {
      setCsvUpload(false)
      setCsvEmails([])
      setCsvValidated(undefined)
      return
    }
    Papa.parse(file, {
      complete: (results) => {
        const { data } = results
        const emailIndex = data[0].findIndex((item) => {
          return item.toLowerCase() === 'email' || item.includes('@')
        })
        const withHeader = !data[0][emailIndex].includes('@')
        setCsvEmails(
          data
            .map((item) => item[emailIndex])
            .slice(withHeader ? 1 : 0)
            .filter((item) => !!item)
        )
        setCsvUpload(true)
      }
    })
  }

  return (
    <Dialog dialog={dialog} name='emailInviteMember' afterClose={afterClose} type='noPadding' title='Invite Members'>
      <section className='pane thin-reg-pane'>
        <div className='row reg-bottom'>
          <div className='col-xs-12 center-xs'>
            <div className='box'>
              <FontIcon style={{ fontSize: '3rem' }} className='fal fa-envelope-open-text' />
            </div>
          </div>
        </div>
        <Typography variant='h6' align='center'>
          Invite by Email
        </Typography>
      </section>

      <section className='pane minimal-grey-pane big-top-padding big-bottom-padding'>
        <form onSubmit={handleSave}>
          <div className='row xtra-fat-bottom'>
            <div className='col-xs-12'>
              <Typography variant='body2'>
                Invite people to join your Inclusivv Membership. Enter one or more email addresses in the To field, add a Message, and click Send Invitation. Alternatively, you can
                upload a CSV file of email addresses.
              </Typography>
            </div>
          </div>
          {!csvUpload && (
            <div className='row reg-bottom'>
              <div className='col-xs-12'>
                <TextFieldControl
                  rules={{ validate: { required: validations.required, emails: validations.emails } }}
                  label={t('conversation.to')}
                  helperText={'Enter individual valid email addresses separated by commas or use the Upload button to import a bulk list.'}
                  name='emails'
                  variant='outlined'
                  control={control}
                  error={errors && errors.emails}
                  multiline
                />
              </div>
            </div>
          )}

          {csvValidated && (
            <div className='row xtra-fat-bottom'>
              <div className='col-xs-12'>
                <EmailList emails={csvEmails} />
              </div>
            </div>
          )}
          <div className='row xtra-fat-bottom'>
            <div className='col-xs-12'>
              {csvValidated && (
                <>
                  {csvEmails.length} <i className='fas fa-check-circle opacity-8' style={{ color: palette.green }}></i>
                </>
              )}
              <InputLabel shrink>CSV File</InputLabel>
              <FileButtonControl label='CSV File' className='reg-bottom' name='csvFile' accept='.csv' control={control} error={errors && errors.csvFile} onChange={csvHandler} />
            </div>
          </div>

          <div className='row xtra-fat-bottom'>
            <div className='col-xs-12 align-left'>
              <TextFieldControl
                rules={{ validate: validations.required }}
                name='message'
                variant='outlined'
                multiline={true}
                rows={4}
                label={t('conversation.messageLabel')}
                control={control}
                error={errors && errors.message}
                helperText={"We'll send this message along with a link to be added as a member."}
              />
              {/* <div className='label'>{t('conversation.insertMessage')}</div> */}
            </div>
          </div>

          <div className='row center-xs'>
            <div className='col-xs-12'>
              <Button
                type='submit'
                className='xtra-fat-bottom flat-top btn-xl btn-block lowercase capitalize'
                disabled={!isDirty || isSubmitting}
                variant='contained'
                color='primary'
              >
                Send Invitation
              </Button>
            </div>
          </div>
        </form>
      </section>
    </Dialog>
  )
})
export default EmailInviteMemberDialog

const EmailList = ({ emails }) => {
  return (
    <DataTable
      data={emails}
      columns={[{ name: 'email', title: 'Email' }]}
      loading={false}
      height={'15rem'}
      hidePaging
      hideFilter
      getCellValue={(row: FIXME, columnName: string) => {
        switch (columnName) {
          default:
            return <ValueLabel value={row} />
        }
      }}
    />
  )
}
