import React from 'react'
import { Button, createStyles, Grid, makeStyles, Paper } from '@material-ui/core'
import { observer } from 'mobx-react'

interface Props {
  title?: string | React.ReactNode
  topRight?: any
  children?: any
  id?: string
}

const SummaryContainer = ({ title, children, topRight, id }: Props) => {
  const classes = Styles()
  return (
    <div>
      <Grid container direction='row' justify='space-between' alignItems='center' className={classes.header}>
        {title && (
          <Grid item>
            <h1>{title}</h1>
          </Grid>
        )}
        {topRight && (
          <Grid item xs={12} md={12} lg={2} className={classes.topRightContainer}>
            {topRight}
          </Grid>
        )}
      </Grid>
      <Paper variant='outlined' id={id}>
        {children}
      </Paper>
    </div>
  )
}

export default observer(SummaryContainer)
const Styles = makeStyles((theme) =>
  createStyles({
    header: {
      margin: '0 auto'
    },
    topRightContainer: {
      marginBottom: '1em',
      textAlign: 'right',
      [theme.breakpoints.down('md')]: {
        textAlign: 'left'
      }
    }
  })
)
