import { types, Instance } from 'mobx-state-tree'
import UsersApi from '../../api/UsersApi'
import User from './User'
import { LoadableStatus } from './helpers/LoadableStatus'
import { Alertable } from './helpers/Alertable'
import { removeNullStringValue } from './utils'
import CsvExportsApi from '../../api/CsvExportsApi'
import MessageApi from '../../api/MessagesApi'

import { Filterable } from './helpers/Filterable'
import UsersTableApi from '../../api/UsersTableApi'
import { Dialogable } from './helpers'
import { RootAccessable } from './helpers/RootAccessable'

export const Users = types
  .compose(
    'Users',
    LoadableStatus,
    Alertable,
    Dialogable,
    Filterable,
    RootAccessable,
    types.model({
      list: types.optional(types.array(User), []),
      attendees_type: types.optional(types.string, 'guests')
    })
  )
  .actions((self) => ({
    addList(user: any) {
      removeNullStringValue(user)
      const found = self.list.find((u) => u.id === user.id)
      if (found) {
        found.updateSelf(user)
      } else {
        self.list.push(user)
      }
    },
    setAttendeesType(val: string) {
      self.attendees_type = val
    },
    setList(data: any[], totalCount?: number) {
      data.forEach((user) => removeNullStringValue(user))
      self.list.replace(data)
      self.setTotalCount(totalCount)
    }
  }))
  .actions((self) => ({
    loadById(userId: number) {
      if (self.isLoading(userId)) {
        return Promise.resolve()
      }
      self.startLoading(userId)
      return UsersApi.get(userId).then(({ response: { ok }, json }) => {
        if (ok) {
          self.addList(json)
        }
        self.stopLoading(userId)
      })
    },
    loadUsers: (args: { search?: string; page_size?: number; page?: number; sorted_id?: string; sorted_priority?: string }) => {
      if (self.isLoading('users_table')) {
        return
      }
      self.startLoading('users_table')
      const api_func = self.attendees_type === 'all' ? UsersTableApi.getUsers : self.attendees_type === 'guests' ? UsersTableApi.getGuests : UsersTableApi.getHosts

      return api_func(args).then(({ response: { ok, statusText }, json: { pages, users, total: totalCount } }) => {
        if (ok) {
          self.setList(users, totalCount)
          self.setPages(pages)
        } else {
          // self.showAlert(statusText)
        }
        self.stopLoading('users_table')
      })
    }
  }))
  .actions((self) => ({
    getUser(id: number, { load = true }: { load?: boolean } = {}) {
      const found = self.list.find((d) => d.id === id)
      if (!found && load) {
        self.loadById(id)
      }
      return found
    },
    createCsvExport({ parameters }: { parameters?: any } = {}) {
      const { user } = self.getAuth()
      user?.trackOnChurnzero() && window.churnZero.track('Count CSV Export', 'users')

      return CsvExportsApi.create({ csvType: 'user', parameters })
        .then((res) => {
          const { ok } = res.response

          if (ok) {
            self.showAlert('Exported')
          }
        })
        .catch((error) => {
          throw error
        })
    },
    loadFilter: ({ reset = false, clear = true } = {}) => {
      if (reset) {
        self.page = 0
        self.pageSize = 15
        self.search = ''
      }
      if (clear) {
        self.list.replace([])
      }
      const { search, pageSize: page_size, page, sortedId: sorted_id, sortedPriority: sorted_priority } = self
      return self.loadUsers({ search, page_size, page, sorted_id, sorted_priority: (!!sorted_priority).toString() })
    },
    afterCreate: () => {
      self.setSortedPriority(true)
      self.setSortingId('id')
    },
    messageUsers: (data) => {
      return MessageApi.create(data)
        .then((response) => {
          if (response.response.ok) {
            self.closeDialog(data.dialog)
            self.showDialog('emailsSent')
          }
        })
        .catch((error) => {
          throw error
        })
    }
  }))

export interface IUsers extends Instance<typeof Users> {}
export default Users
