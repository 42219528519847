import React from 'react'
import { capitalize } from '../../../decorators/textTools'
import { RadioGroupControl } from '../../utils'
import { StepProps } from './NewStepper'
import withTranslation from '../../hocs/withTranslation'
import { IStore } from '../../../store/models'
import { flow } from 'lodash'
import { observer } from 'mobx-react'
import { inject } from 'mobx-react'
import { IUser } from '../../../store/models/User'
import { WithTranslation } from 'react-i18next'

interface InjectedProps {
  user: IUser
}

const ConversationLocationStep = ({ topic, t, control, setValue, user }: StepProps & InjectedProps & WithTranslation) => {
  return (
    <>
      <p>
        {t('conversation.holdEvent', {
          eventType: topic.eventTypeSingular
        })}
      </p>
      <div>
        <RadioGroupControl
          name='venueType'
          control={control}
          defaultValue={'virtual'}
          options={[
            ...(topic.virtualConversations
              ? [
                  {
                    label: t('conversation.virtualTypeLabel', {
                      eventType: capitalize(topic.virtualEventTypeSingular)
                    }),
                    value: 'virtual'
                  }
                ]
              : []),
            ...(topic.restaurantConversations && window.allowInPerson
              ? [
                  {
                    label: t('conversation.restaurantTypeLabel'),
                    value: 'restaurant'
                  }
                ]
              : []),
            ...(topic.homeConversations && window.allowInPerson
              ? [
                  {
                    label: t('conversation.homeTypeLabel'),
                    value: 'home'
                  }
                ]
              : []),
            ...(topic.otherConversations && window.allowInPerson
              ? [
                  {
                    label: t('conversation.otherTypeLabel'),
                    value: 'other'
                  }
                ]
              : [])
          ]}
        />
      </div>
    </>
  )
}

export default flow(
  observer,
  inject<FIXME, FIXME>(
    ({
      mst: {
        auth: { user }
      }
    }: {
      mst: IStore
    }) => {
      return {
        user
      }
    }
  ),

  withTranslation
)(ConversationLocationStep)
