import React from 'react'
import FontIcon from '@material-ui/core/Icon'
import Button from '@material-ui/core/Button'
import { observer, inject } from 'mobx-react'
import withTranslation from '../hocs/withTranslation'
import Dialog from '../DialogWrapper'
import { IStore } from '../../store/models'
import { DialogTitle } from '@material-ui/core'

const UpdateConversationDialog = ({ dialog, t, conversation }: FIXME) => {
  const saveConversation = (notifyGuests: boolean) => {
    conversation.updateConversation({ ...dialog.data.values, notifyGuests })
    dialog.closeDialog()
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()
    saveConversation(true)
  }

  return (
    <Dialog dialog={dialog} name='updateConversation' type='thin'>
      <div>
        <DialogTitle disableTypography>{t('conversation.readyToEmail')}</DialogTitle>

        <section className='pane thin-reg-pane'>
          <div className='row reg-bottom'>
            <div className='col-xs-12 center-xs'>
              <div className='box'>
                <FontIcon className='fa fa-envelope-o header-36' />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-xs-12 center-xs'>
              <h1 className='flat header-24'>{t('conversation.weSendEmail')}</h1>
            </div>
          </div>
        </section>

        <section className='pane minimal-grey-pane big-top-padding big-bottom-padding'>
          <form onSubmit={handleSubmit}>
            <div className='row center-xs'>
              <div className='col-xs-12'>
                <Button type='submit' className='reg-bottom flat-top btn-xl btn-block lowercase capitalize' color='primary' variant='contained'>
                  {t('conversation.sendMessage')}
                </Button>
              </div>
            </div>

            <div className='row center-xs'>
              <div className='col-xs-12'>
                <Button onClick={() => saveConversation(false)} className='reg-bottom flat-top btn-xl btn-block lowercase capitalize' color='primary' variant='contained'>
                  {t('common.skip')}
                </Button>
              </div>
            </div>
          </form>
        </section>
      </div>
    </Dialog>
  )
}

export default inject<FIXME, FIXME>(
  (
    {
      mst: {
        auth: { user },
        conversations
      }
    }: {
      mst: IStore
    },
    {
      dialog: {
        data: {
          values: { id }
        }
      }
    }
  ) => {
    return { user, conversation: conversations.list.find((d) => d.id === id) }
  }
)(observer(withTranslation(UpdateConversationDialog)))
