import * as types from '../actions/actionTypes'
import { assign } from 'lodash'
import initialState from './initialState'

export const conversationNotifications = (state = initialState.conversationNotifications, action) => {
  switch (action.type) {
    case types.LOAD_DINNER_NOTIFICATIONS_FAILED:
      return assign({}, state, {
        error: action.data,
        loading: false
      })

    case types.DINNER_NOTIFICATIONS_LOADING:
      return assign({}, state, {
        error: null,
        loading: true,
        list: {}
      })

    case types.LOAD_DINNER_NOTIFICATIONS_SUCCESS:
      return assign({}, state, {
        error: null,
        loading: false,
        list: assign({}, action.conversationNotifications)
      })

    default:
      return state
  }
}

export default conversationNotifications
