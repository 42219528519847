import { assign } from 'lodash'

import { LOADING_USERS_TABLE, LOAD_USERS_TABLE_SUCCESS, LOAD_USERS_TABLE_FAILED } from '../actions/actionTypes.js'
import initialState from './initialState'

export const usersTable = (state = initialState.usersTable, action) => {
  switch (action.type) {
    case LOADING_USERS_TABLE:
      return assign({}, state, {
        loadingUsersTable: true,
        errorUsersTable: null
      })

    case LOAD_USERS_TABLE_SUCCESS:
      return assign({}, state, {
        loadingUsersTable: false,
        errorUsersTable: null,
        tableData: assign({}, state.tableData, action.data)
      })

    case LOAD_USERS_TABLE_FAILED:
      return assign({}, state, {
        loadingUsersTable: false,
        errorUsersTable: action.data
      })

    default:
      return state
  }
}

export default usersTable
