import React from 'react'
import FontIcon from '@material-ui/core/Icon'
import { flow } from 'lodash'
import { IDialog } from '../../store/models/Dialog'
import { IUser } from '../../store/models/User'
import { IStore } from '../../store/models'
import { observer, inject } from 'mobx-react'
import { connectTopicTheme } from '../../connectTheme.js'
import withTranslation from '../hocs/withTranslation'
import Dialog from '../DialogWrapper'
import { DialogTitle } from '@material-ui/core'

interface Props {
  t?: any
  dialog: IDialog
  currentUser: IUser
}

const UserConnectionAcceptDialog = ({ t, dialog, currentUser }: Props) => {
  return (
    <Dialog dialog={dialog} name='conversationInvitationRequestSent' type='noPadding'>
      <div>
        <DialogTitle disableTypography>{t('userConnection.connectionAccepted')}</DialogTitle>

        <section className='pane reg-pane'>
          <div className='row reg-bottom'>
            <div className='col-xs-12 center-xs'>
              <div className='box'>
                <FontIcon className='fa fa-paper-plane-o header-36' />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-xs-12 center-xs'>
              <h1 className='flat header-24'>{t('userConnection.congrats')}</h1>
            </div>
          </div>
        </section>

        <section className='pane skinny-pane minimal-grey-pane'>
          <div className='row center-xs'>
            <div className='col-xs-12'>
              <p>{currentUser ? t('userConnection.signInToSeeLoggedIn') : t('userConnection.signInToSee')}</p>
            </div>
          </div>
        </section>
      </div>
    </Dialog>
  )
}

interface InjectedProps {
  currentUser?: IUser
}

export default flow(
  connectTopicTheme,
  withTranslation,
  observer,
  inject<InjectedProps, Props>(({ mst }: { mst: IStore }) => {
    const { auth } = mst

    return {
      currentUser: auth.user
    }
  })
)(UserConnectionAcceptDialog)
