import * as types from '../actions/actionTypes'
import { assign } from 'lodash'
import initialState from './initialState'

export const conversationInvitations = (state = initialState.conversationInvitations, action) => {
  switch (action.type) {
    case types.VERIFY_APPROVAL_TOKEN_SUCCESS:
      return assign({}, state, {
        loading: false,
        error: null,
        list: action.conversationInvitation
      })

    case types.REDEEM_APPROVAL_TOKEN_SUCCESS:
      return assign({}, state, {
        loading: false,
        error: null,
        list: {}
      })

    default:
      return state
  }
}

export default conversationInvitations
