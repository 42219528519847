import React from 'react'
import { Button, Grid, Menu, MenuItem } from '@material-ui/core'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'

import withTranslation from '../../hocs/withTranslation'
import { logOutUser } from '../../../actions/sessionActions'
import Avatar from '../../users/Avatar'
import { observer } from 'mobx-react'
import { flow } from 'lodash'
import { TALK_TO_EXPERTS_LINK } from './Links'
import { useAuth } from '../../Providers'
import { useTranslation } from 'react-i18next'

export default flow(
  withTranslation,
  observer
)((props: FIXME) => {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const onMenuClick = ({ currentTarget }) => setAnchorEl(currentTarget)
  const { user } = useAuth()
  const { t } = useTranslation()

  if (!user) {
    return <></>
  }
  const handleClose = () => setAnchorEl(null)
  const handleLogout = (event) => {
    event.preventDefault()
    dispatch(logOutUser(t('auth.signoutConfirm', { name: user.firstName })))
    handleClose()
  }
  const history = useHistory()
  // const showTalkToExpert = window.inclusivv //&& !user.isCommunityAdmin()
  return (
    <Grid container direction='row' spacing={2} alignItems='center'>
      {/* {showTalkToExpert && (
        <Grid item xs={6} style={{ textAlign: 'right' }}>
          <Button href={TALK_TO_EXPERTS_LINK} variant='contained' color='primary' className='toolbar-nav-link coco-session-btn no-wrap'>
            {"Let's Chat"}
          </Button>
        </Grid>
      )} */}
      <Grid item style={{ textAlign: 'right' }}>
        <Button disableElevation href={'/myconversations'} color='primary' className='toolbar-nav-link coco-session-btn no-wrap'>
          {t('nav.myConversations')}
        </Button>
      </Grid>
      {user.membershipUsers.length > 0 && (
        <Grid item style={{ textAlign: 'right' }}>
          <Button disableElevation href='/memberships' color='primary' className='toolbar-nav-link coco-session-btn no-wrap'>
            My Membership
          </Button>
        </Grid>
      )}
      {user.isCommunityAdmin() && (
        <Grid item style={{ textAlign: 'right' }}>
          <Button disableElevation href='/admin' color='primary' className='toolbar-nav-link coco-session-btn no-wrap'>
            {t('nav.myOrganization')}
          </Button>
        </Grid>
      )}
      <Grid item style={{ textAlign: 'right' }}>
        <Button disableElevation href='https://help.inclusivv.co/en/' color='primary' className='toolbar-nav-link coco-session-btn no-wrap' target='blank'>
          Help Center
        </Button>
      </Grid>
      <Grid item>
        <ListItem onClick={onMenuClick} className='cursor-pointer'>
          <ListItemAvatar>
            <Avatar profileImage={user.profileImage} hasProfileImage={user.hasProfileImage} size={'thumb'} />
          </ListItemAvatar>
          <ListItemText primary={user.fullName} />
        </ListItem>

        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          getContentAnchorEl={null}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          keepMounted
        >
          {/* {user.isCommunityAdmin() && (
            <MenuItem
              onClick={() => {
                handleClose()
                history.push('/admin')
              }}
            >
              {t('nav.myOrganization')}
            </MenuItem>
          )} */}
          <MenuItem
            onClick={() => {
              history.push(`/users/${user.id}`)
              handleClose()
            }}
          >
            {t('nav.myProfile')}
          </MenuItem>
          {/* <MenuItem
            onClick={() => {
              history.push(`/users/${user.id}/manage_conversations`)
              handleClose()
            }}
          >
            {t('nav.myConversations')}
          </MenuItem>
          {user.membershipUsers.length > 0 && (
            <MenuItem
              onClick={() => {
                history.push('/memberships')
                handleClose()
              }}
            >
              My Membership
            </MenuItem>
          )} */}
          <MenuItem onClick={handleLogout}>{t('auth.signout')}</MenuItem>
        </Menu>
      </Grid>
    </Grid>
  )
})
