import React, { Component } from 'react'
import { Divider, Button } from '@material-ui/core'

import { User } from '../../../../getters/userGetters'
import Avatar from '../../../users/Avatar'

import { ListItem, ListItemSecondaryAction, ListItemText, ListItemAvatar } from '@material-ui/core'

class List extends Component<FIXME, FIXME> {
  state = { visible: true }

  handleClick = () => {
    const { removeAdmin, item: user, itemProps: partnerId } = this.props,
      { id: userId } = user

    removeAdmin({ userId, partnerId })
  }

  render() {
    const { item: user } = this.props

    return (
      <>
        <ListItem>
          <ListItemAvatar>
            <Avatar profileImage={user && user.profileImage} hasProfileImage={user && user.hasProfileImage} size={'thumb'} />
          </ListItemAvatar>
          <ListItemText primary={User(user).fullName} />
          <ListItemSecondaryAction>
            <Button onClick={this.handleClick} type='submit' color='primary' variant='contained'>
              Remove
            </Button>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider variant='inset' component='li' />
      </>
    )
  }
}

export default List
