import * as types from '../actions/actionTypes'
import { assign } from 'lodash'
import initialState from './initialState'

export const topicNotifications = (state = initialState.topicNotifications, action) => {
  switch (action.type) {
    case types.LOAD_CONVERSATION_NOTIFICATIONS_FAILED:
      return assign({}, state, {
        error: action.data,
        loading: false
      })

    case types.CONVERSATION_NOTIFICATIONS_LOADING:
      return assign({}, state, {
        error: null,
        loading: true,
        list: {}
      })

    case types.LOAD_CONVERSATION_NOTIFICATIONS_SUCCESS:
      return assign({}, state, {
        error: null,
        loading: false,
        list: assign({}, action.topicNotifications)
      })

    default:
      return state
  }
}

export default topicNotifications
