import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { flow } from 'lodash'
import DOMPurify from 'dompurify'
import { isStateTreeNode } from 'mobx-state-tree'
import FontIcon from '@material-ui/core/Icon'

import Avatar from '../users/Avatar.js'
import { connectTopicTheme } from '../../connectTheme.js'
import { capitalize } from '../../decorators/textTools'
import withTranslation from '../hocs/withTranslation'
import appAnalytics from '../../analytics'
import { createStyles, makeStyles } from '@material-ui/core'
import classnames from 'classnames'

const ConversationCard = (props) => {
  const classes = Styles()
  const {
    theme,
    t,
    rootClassName = 'col-xs-12 col-sm-4',
    conversation: {
      slug,
      title,
      host,
      neighborhood,
      id,
      city,
      stateLevel,
      full,
      virtualEventTypeSingular,
      guestPolicy,
      registrationClosed,
      isVirtual,
      featured,
      timeZoneAbbr,
      dateLocally,
      startsAtLocally,
      dayLocally
    },
    conversation
  } = props

  const buildInitials = () => {
    const { firstName = ' ', lastName = ' ' } = host
    if (!host) return ''
    return `${firstName.charAt(0)}${lastName.charAt(0)}`
  }

  const initials = isStateTreeNode(host) ? (host as any)?.initials() : buildInitials()

  const avatar = host.hasProfileImage ? <Avatar {...host} size='primary' /> : <div className='avatar-initials'>{initials}</div>

  const conversationPath = `/${slug}/conversations/${id}`

  const privateConversation = guestPolicy === 'private'

  const titleSize = () => {
    if (title.length > 20) return 'header-28 header-24-xs'
    else return 'header-28'
  }

  const isFullRibbon =
    full || (registrationClosed && !privateConversation) ? (
      <div className='card-ribbon'>
        <span>{t('conversation.isFull')}</span>
      </div>
    ) : null

  const privateConversationRibbon =
    guestPolicy === 'private' ? (
      <div className='card-ribbon'>
        <span>{t('conversation.isPrivate')}</span>
      </div>
    ) : null

  const trackEvent = () =>
    appAnalytics.event({
      category: 'Conversation',
      action: 'Conversation Card Cicked',
      nextPath: conversationPath,
      featured: !!featured
    })

  return (
    <div className={`${rootClassName} conversationCard`}>
      <Link to={conversationPath} onClick={trackEvent}>
        <div className={`${slug} card`}>
          <div
            className='card-top'
            style={{
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText
            }}
          >
            {isFullRibbon}

            {privateConversationRibbon}

            <div className='row card-date-row middle-xs no-margin lightly-darkened-pane header'>
              <div className='header-18 header-16-xs date'>
                <span className='xs-block header-16 header-14-sm'>{dayLocally}</span> {capitalize(dateLocally)}
              </div>
              <div className='header-18 header-16-xs time'>
                {startsAtLocally} <span className='header-12'>{timeZoneAbbr}</span>
              </div>
            </div>
            <div className='title'>
              <div className={`col-xs center-xs white-text ${titleSize()}`}>{title}</div>
            </div>
            <div className='card-profile-image-container'>{avatar}</div>
          </div>
          <div className='card-bottom center-xs'>
            <div className='row'>
              <div
                className='col-xs center-xs card-hosting-row no-gutters'
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(t('conversation.isHosting', { name: host.firstName }))
                }}
              />
            </div>
            <div className='row'>
              <div className='col-xs center-xs'>
                <div className='row no-gutters'>
                  <div className='col-xs-12 thin-gutters reg-bottom'>
                    <div className='box slim-box-padding'>
                      {isVirtual ? (
                        <>
                          <i className={classnames('fas fa-video thin-right inline-block', classes.icon)} />
                          <p className={classnames('no-margin inline-block ', classes.location)}>{t('conversation.online')}</p>
                        </>
                      ) : (
                        <>
                          <i className={classnames('fas fa-map-marker-alt thin-right inline-block', classes.icon)} />
                          <p className={classnames('no-margin inline-block ', classes.location)} style={{ color: 'red !important' }}>
                            {city}, {stateLevel}
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default flow(connectTopicTheme, withTranslation)(ConversationCard)

const Styles = makeStyles((theme) =>
  createStyles({
    icon: {
      color: '#939598'
    },
    location: {
      color: '#939598'
    }
  })
)
