import { types, Instance } from 'mobx-state-tree'
import TopicConversationsApi from '../../../api/topics/ConversationsApi'
import ConversationsApi from '../../../api/ConversationsApi'
import TopicBase from './TopicBase'
import Conversation from '../Conversation'

export const TopicConversations = types
  .compose(
    'TopicConversations',
    TopicBase,
    types.model({
      featuredConversations: types.maybeNull(types.boolean),
      featuredConversationsTitle: types.maybeNull(types.string),
      conversations: types.optional(types.array(Conversation), []),
      featuredConversationsList: types.optional(types.array(Conversation), []),
      hasMoreConversations: types.optional(types.boolean, true),
      hasMoreFeaturedConversations: types.optional(types.boolean, true)
    })
  )
  .actions((self) => ({
    setConversations: (conversations: any) => {
      self.conversations = conversations
    },
    setHasMoreConversations: (val: boolean) => {
      self.hasMoreConversations = val
    },
    setFeaturedConversations: (conversations: any) => {
      self.featuredConversationsList = conversations
    },
    setHasMoreFeaturedConversations: (val: boolean) => {
      self.hasMoreFeaturedConversations = val
    }
  }))
  .actions((self) => ({
    loadConversations: (args: any, { add = false }: { add?: boolean } = {}) => {
      if (self.isLoading('conversations')) return

      self.startLoading('conversations')
      return TopicConversationsApi.getAll({ slug: self.slug, ...args })
        .then(({ response: { ok }, json }) => {
          self.stopLoading('conversations')
          if (json.length === 0) {
            self.setHasMoreConversations(false)
          } else {
            if (ok) {
              if (add) {
                self.setConversations([...self.conversations, ...json])
              } else {
                self.setConversations(json)
              }
            }
          }
        })
        .catch(() => {
          self.stopLoading('conversations')
        })
    },
    loadFeaturedConversations: (args: any, { add = false }: { add?: boolean } = {}) => {
      const listName = 'featuredConversationsList'
      if (self.isLoading(listName)) return

      self.startLoading(listName)
      return TopicConversationsApi.getAll({ slug: self.slug, ...args })
        .then(({ response: { ok }, json }) => {
          self.stopLoading(listName)
          if (json.length === 0) {
            self.setHasMoreFeaturedConversations(false)
          } else {
            if (ok) {
              if (add) {
                self.setFeaturedConversations([...self.featuredConversationsList, ...json])
              } else {
                self.setFeaturedConversations(json)
              }
            }
          }
        })
        .catch(() => {
          self.stopLoading(listName)
        })
    },
    createConversation: (data: any) => {
      return ConversationsApi.create({ topic_id: self.id, ...data }).then(({ response: { ok }, json }) => {
        if (ok) {
          self.showDialog('createdConversation', { step: 1, conversation: json })
          exposeConversationCreatePixel()
          return { data: json }
        } else {
          // throw new SubmissionError(res.json)
        }
      })
    },
    createSynthConversation: (data: any) => {
      return ConversationsApi.createSynth({ topic_id: self.id, ...data }).then(({ response: { ok }, json }) => {
        if (ok) {
          return { data: json }
        } else {
          // throw new SubmissionError(res.json)
        }
      })
    }
  }))

function exposeConversationCreatePixel() {
  if (process.env.NODE_ENV !== 'production') return
  window.fbq('trackSingleCustom', '1904101133142530', 'ConversationCreate')
}

export interface ITopicConversations extends Instance<typeof TopicConversations> {}
export default TopicConversations
