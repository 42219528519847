import { assign, merge } from 'lodash'
import initialState from './initialState'
import { LOADING_USER_ACTIONS, LOAD_USER_ACTIONS_SUCCESS, CREATE_USER_ACTION_SUCCESS, UPDATE_USER_ACTION_SUCCESS } from '../actions/actionTypes'

export const userActions = (state = initialState.userActions, action) => {
  switch (action.type) {
    case LOADING_USER_ACTIONS:
      return assign({}, state, {
        loading: true,
        error: null
      })

    case LOAD_USER_ACTIONS_SUCCESS:
      return assign({}, state, {
        loading: false,
        error: null,
        list: assign({}, action.userActions)
      })

    case CREATE_USER_ACTION_SUCCESS:
      return assign({}, state, {
        loading: false,
        error: null,
        list: assign({}, state.list, action.userActions)
      })

    case UPDATE_USER_ACTION_SUCCESS:
      return assign({}, state, {
        loading: false,
        error: null,
        list: merge(state.list, action.userActions)
      })

    default:
      return state
  }
}

export default userActions
