import React, { Component } from 'react'

class SponsorShow extends Component {
  render() {
    const { sponsor = {}, onClick } = this.props

    return (
      <div className='col-xs-2 relative fat-bottom' onClick={() => onClick(sponsor)} title={sponsor.logoLink}>
        <img src={sponsor.logo.url} className='max-width' alt='logo' />
      </div>
    )
  }
}

export default SponsorShow
