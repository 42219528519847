import { Grid, IconButton, Tooltip, Link as ExternalLink } from '@material-ui/core'
import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos'
import { ITopic } from '../../../../store/models/Topic'
import appAnalytics from '../../../../analytics'
import { IStore } from '../../../../store/models'
import { inject } from 'mobx-react'
import { observer } from 'mobx-react'
import { flow } from 'lodash'
import { IUser } from '../../../../store/models/User'
import { Link } from 'react-router-dom'

interface Props {
  user?: IUser
  topic: ITopic
}

const TopicActions = ({ topic, history, location, user }: Props & RouteComponentProps) => {
  return (
    <Grid container direction='row' justify='space-around' alignItems='center'>
      <Tooltip title='Edit'>
        <Link
          to={`/admin/topics/${topic.slug}/edit`}
          onClick={
            topic.isTemplate
              ? (e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  if (
                    (window as FIXME).confirm(
                      'This Topic is marked as Template and should only be edited if the template needs to be updated. Otherwise, please use the Duplicate action to create a copy of the Topic and edit the copy. Click OK if you want to edit this template, otherwise click Cancel to return to the Topic list'
                    )
                  ) {
                    window.open(`/admin/topics/${topic.slug}/edit`)
                  }
                }
              : undefined
          }
        >
          <IconButton
            size='small'
            onClick={() => {
              appAnalytics.event({
                category: 'Admin',
                action: 'Edit Topic Icon Clicked'
              })
            }}
          >
            <i className='fas fa-pen opacity-8'></i>
          </IconButton>
        </Link>
      </Tooltip>
      <Tooltip title='Create a new conversation'>
        <Link to={`/${topic.slug}/new`}>
          <IconButton
            size='small'
            onClick={() => {
              appAnalytics.event({
                category: 'Admin',
                action: 'Create a new conversation'
              })
            }}
          >
            <i className='fal fa-layer-plus opacity-8'></i>
          </IconButton>
        </Link>
      </Tooltip>
      <Tooltip title='Log a prior conversation'>
        <Link to={`/${topic.slug}/old`}>
          <IconButton
            size='small'
            onClick={() => {
              appAnalytics.event({
                category: 'Admin',
                action: 'Log a prior conversation'
              })
            }}
          >
            <i className='fal fa-history opacity-8'></i>
          </IconButton>
        </Link>
      </Tooltip>
      {topic.hostGuideUrl && topic.hostGuideUrl !== 'null' ? (
        <Tooltip title='Host Guide'>
          <ExternalLink target={'blank'} underline={'none'} href={(topic.hostGuideUrl && topic.hostGuideUrl.replace(/dl=0/gi, 'dl=1')) || '#'}>
            <IconButton
              size='small'
              onClick={() => {
                appAnalytics.event({
                  category: 'Admin',
                  action: 'Download Topic Host Guide Icon Clicked'
                })
              }}
            >
              <i className='fal fa-book-open opacity-8' />
            </IconButton>
          </ExternalLink>
        </Tooltip>
      ) : (
        <IconButton size='small' disabled>
          <i className='fal fa-book-open opacity-8' />
        </IconButton>
      )}
      {topic.surveyLink && topic.surveyLink !== 'null' ? (
        <Tooltip title='Survey Result'>
          <ExternalLink target={'blank'} underline={'none'} href={(topic.surveyLink && topic.surveyLink.replace(/dl=0/gi, 'dl=1')) || '#'}>
            <IconButton
              size='small'
              onClick={() => {
                appAnalytics.event({
                  category: 'Admin',
                  action: 'Download Topic Survey Result Icon Clicked'
                })
              }}
            >
              <i className='fas fa-clipboard-list opacity-8' />
            </IconButton>
          </ExternalLink>
        </Tooltip>
      ) : (
        <IconButton size='small' disabled>
          <i className='fas fa-clipboard-list opacity-8' />
        </IconButton>
      )}

      {user?.isSuperAdmin() && (
        <Tooltip title='Duplicate'>
          <Link
            to={{
              pathname: '/admin/topics/new',
              state: {
                topic,
                from: location.pathname
              }
            }}
          >
            <IconButton
              size='small'
              onClick={() => {
                appAnalytics.event({
                  category: 'Admin',
                  action: 'Duplicate Topic Icon Clicked'
                })
              }}
            >
              <AddToPhotosIcon className='opacity-8' />
            </IconButton>
          </Link>
        </Tooltip>
      )}
    </Grid>
  )
}

export default flow(
  observer,
  inject<FIXME, FIXME>(
    ({
      mst: {
        auth: { user }
      }
    }: {
      mst: IStore
    }) => {
      return {
        user
      }
    }
  ),
  withRouter
)(TopicActions)
