import React, { useEffect } from 'react'
import TopicsSummary from './TopicsSummary'
import ConversationsSummary from './ConversationsSummary'
import { inject, observer } from 'mobx-react'
import { flow } from 'lodash'
import { IStore } from '../../../../store/models'
import { withRouter, RouteComponentProps } from 'react-router'
import { IUser } from '../../../../store/models/User'
import PartnerSelector from './PartnerSelector'
import AdminMetaTags from '../../../shared/AdminMetaTags'
import PartnersSummary from '../Partners/PartnersSummary'
interface Props {
  user: IUser
  loaded?: boolean
  selectedPartnerId?: number
  selectPartner(val?: number)
}

const AdminDashboard = ({ user, loaded, selectPartner, selectedPartnerId }: Props & RouteComponentProps) => {
  useEffect(() => {
    return () => {
      selectPartner(undefined)
    }
  }, [selectPartner])

  const showPartnerList = loaded && !selectedPartnerId && user?.isPartnerAdmin() && user.partnerSlugs && user.partnerSlugs.length > 1

  return (
    <>
      <AdminMetaTags title='Dashboard' />

      <PartnerSelector />
      <ConversationsSummary />
      <TopicsSummary />
      {showPartnerList && <PartnersSummary />}
    </>
  )
}

export default flow(
  withRouter,
  observer,
  inject<FIXME, FIXME>(
    ({
      mst: {
        auth: { user, loaded },
        partners: { selectPartner, selectedPartnerId }
      }
    }: {
      mst: IStore
    }) => {
      return {
        user,
        loaded,
        selectPartner,
        selectedPartnerId
      }
    }
  )
)(AdminDashboard)
