import React, { Component, useContext, useEffect, useMemo } from 'react'

import { observer } from 'mobx-react'
import { useAuth, useParamTopic } from '../../Providers'
// import { ShowCourse } from '../../lms_courses/ShowCourse'
import { AppBar, Button, Container, Grid, Paper, Theme, Typography, createStyles, makeStyles } from '@material-ui/core'
import { useParams } from 'react-router'
import { IPartner, ITrack } from '../../../store'
import LoadingAnimation from '../../shared/LoadingAnimation'

const PayWall = observer(({ children }: FIXME) => {
  const { topic, partner } = useParamTopic()
  const { user, loaded } = useAuth()
  // const params = useParams()
  const tracks = partner?.tracks
  const track = tracks && tracks[0]
  const rawmembershipUsers = user?.membershipUsers
  const memberUsers: FIXME[] = useMemo(() => rawmembershipUsers || [], [rawmembershipUsers])
  const userTrackIds = useMemo(() => (memberUsers && memberUsers?.map((mu) => mu.trackId)) || [], [memberUsers])
  const isTrackMember = useMemo(() => userTrackIds.includes(track?.id), [track, userTrackIds])
  // console.log({ isTrackMember })

  // console.log('TopicVideos', { topic, params, partner, isTrackMember })
  useEffect(() => {
    topic && topic.shouldLoad('topicLmsCourses', topic.loadTopicLmsCourses)
  }, [topic])
  useEffect(() => {
    user && user.shouldLoad('userLmsItems', user.loadUserLmsItems)
  }, [user])

  if (!topic || !loaded || !topic?.loadedIds?.includes('topicLmsCourses') || (user && (!user?.loadedIds?.includes('userLmsItems') || !user?.loadedIds?.includes('userLmsItems')))) {
    return <LoadingAnimation />
  }

  return isTrackMember ? (
    <>{children}</>
  ) : (
    <Container maxWidth='xl' style={{ paddingTop: '2rem' }}>
      <Typography variant='h3' align='center'>
        {topic?.topicLmsCourse?.name}
      </Typography>
      <Typography align='center'>{topic?.topicLmsCourse?.description}</Typography>
      <MembershipPane track={track} partner={partner} />
    </Container>
  )
})

export default PayWall

const MembershipPane = ({ partner, track }: { partner?: IPartner; track?: ITrack }) => {
  const { user } = useAuth()
  const classes = useStyles()

  return (
    <AppBar color='inherit' position='relative'>
      <div className={classes.appBar}>
        <Paper className={classes.paper}>
          <Typography variant='h5' align='center' className='reg-bottom'>
            {track?.header || 'Begin Your Journey'}
          </Typography>
          <Typography variant='body2' align='center' component='p' className='reg-bottom'>
            {track?.description ||
              "The Inclusivv Membership community is full of people like you — those who believe in the power of intimate conversations to bring about real and lasting change. To protect the integrity of this community, this page is for members only. But don't worry! It's easy to join, and we'd be happy to have you."}
          </Typography>
          <Grid container justify='center' spacing={3} className='reg-top'>
            {!user && (
              <Grid item>
                <Typography variant='subtitle2' component='p' className='reg-bottom'>
                  {track?.loginHeaderText || 'Already a Member?'}
                </Typography>
                <Button variant='contained' color='primary' className='reg-bottom' onClick={() => partner?.showDialog('login')}>
                  Log In Now
                </Button>
              </Grid>
            )}
            <Grid item>
              <Typography variant='subtitle2' component='p' className='reg-bottom'>
                {track?.joinCtaText || 'Not a Member Yet?'}
              </Typography>
              <Button variant='outlined' className='reg-bottom' href='https://inclusivv.co/membership'>
                {track?.joinCtaButton || 'Become a Member'}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </AppBar>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      // top: 'auto',
      // bottom: 0,
      // height: '48vh',
      padding: '4rem',
      background: 'linear-gradient(76.7deg, #EE413E 0%, #B32D73 55.76%, #5D3A92 100%)',
      boxShadow: '0px -2px 16px rgba(0, 0, 0, 0.1), 0px -2px 13px rgba(0, 0, 0, 0.25)'
    },
    paper: {
      maxWidth: '685px',
      textAlign: 'center',
      padding: '2rem',
      margin: 'auto auto',
      borderRadius: '24px'
    },
    backdrop: {
      zIndex: 1207,
      color: '#fff'
    }
  })
)
