import * as types from '../actions/actionTypes'
import { assign } from 'lodash'
import initialState from './initialState'

export const actions = (state = initialState.actions, action) => {
  switch (action.type) {
    case types.LOAD_ACTIONS_SUCCESS:
      return assign({}, state, {
        error: null,
        loading: false,
        list: assign({}, action.actions)
      })

    case types.LOAD_ACTIONS_FAILED:
      return assign({}, state, {
        error: action.data,
        loading: false
      })

    case types.CREATE_ACTIONS_SUCCESS:
      return assign({}, state, {
        error: null,
        loading: false,
        list: assign({}, state.list, action.actions)
      })

    case types.ACTIONS_LOADING:
      return assign({}, state, {
        error: null,
        loading: true
      })

    case types.DELETE_ACTIONS_SUCCESS:
      return assign({}, state, {
        loading: false,
        error: null,
        list: assign({}, state.list, action.actions)
      })

    default:
      return state
  }
}

export default actions
