import React, { useEffect } from 'react'
import { List as MaterialList } from '@material-ui/core'

import InvitationListItem from './InvitationListItem'
import { observer } from 'mobx-react'
import LoadingAnimation from '../../../shared/LoadingAnimation'

const List = ({ partner, t }: FIXME) => {
  useEffect(() => {
    partner && partner.shouldLoad('adminInvitations', partner.loadAdminInvitations)
  }, [partner])
  const loading = partner?.loadingIds?.includes('adminInvitations')
  if (!partner || loading) return <LoadingAnimation />
  const EmptyMessage = () => {
    return (
      <div>
        <p className='italic'>{'No Admins'}</p>
      </div>
    )
  }
  return (
    <MaterialList>
      {loading && partner?.adminInvitations.length === 0 && <EmptyMessage />}
      {partner?.admins &&
        partner.adminInvitations.map((invite) => <InvitationListItem removeAdmin={() => partner.deleteAdminInvitation(invite.id)} key={invite.id} item={invite} />)}
    </MaterialList>
  )
}

export default observer(List)
