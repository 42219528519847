import { get } from './utils'
import queryString from 'query-string'

class ConversationsTableApi {
  static getAll(args) {
    const defaultParams = {
      page: 0,
      site_name: global.siteName
    }
    const params = queryString.stringify({ ...defaultParams, ...args }, { arrayFormat: 'bracket' })

    return get(`/admin/conversations?${params}`)
  }
}

export default ConversationsTableApi
