import { createSelector, createStructuredSelector } from 'reselect'
import { sortBy } from 'lodash'

import { getConversations, getLoadingConversations } from './conversationGetters'

const getFilteredConversations = createSelector(
  getConversations,
  conversations => {
    return sortByNearestStartDate(conversations)
  }
)

function sortByNearestStartDate(conversations) {
  return sortBy(conversations, [
    function(o) {
      return o.startsAt
    }
  ])
}

export default createStructuredSelector({
  items: getFilteredConversations,
  itemsLoading: getLoadingConversations
})
