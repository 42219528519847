import React, { useState } from 'react'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import DataTable, { ValueLabel, IColData } from '../../../shared/DataTableFilterable'
import moment from 'moment'
import { flow, startCase } from 'lodash'
import ConversationActions from '../partials/ConversationActions'
import { inject, observer } from 'mobx-react'
import { IStore, IUser, IConversation } from '../../../../store/models'
import { Chip, IconButton, Container } from '@material-ui/core'
import { IFilterable } from '../../../../store/models/helpers/Filterable'
import Dialog from '../../../DialogWrapper'
const ConversationsTable = ({ loading, items, filterable, index, user }: Props & InjectedProps & RouteComponentProps) => {
  const columnData: IColData[] = [
    { name: 'title', title: 'Title' },
    { name: 'startsAt', title: 'Date of Conversation', width: 150, sortable: true },
    { name: 'time', title: 'Time', width: 110 },
    { name: 'attendeeCount', title: 'Registered Guests', align: 'center', width: 120, icon: 'info-circle', sortable: true },
    // { name: 'attendeeCount', title: 'Registered Guests', align: 'center', width: 120, sortable: true },
    { name: 'maxAttendees', title: 'Maximum Attendees', align: 'center', width: 120, icon: 'info-circle', hint: 'Guests and Hosts', sortable: true },
    { name: 'guestsFull', title: ' ', width: 80 },
    { name: 'host', title: 'Host Name' },
    { name: 'venueType', title: 'Venue', sortable: true },
    { name: 'topic', title: 'Topic', width: 250 },
    { name: 'hostNotes', title: 'Notes' },
    { name: 'actions', title: 'Actions', width: 220, align: 'center' }
  ]

  if (index === 1) {
    columnData.splice(5, 0, { name: 'attendedCount', align: 'right', title: 'Attendees', width: 120, icon: 'info-circle', hint: 'Guests and Hosts who clicked join link' })
  }

  const [hostNotes, setHostNotes] = useState(undefined)

  return (
    <>
      {' '}
      <DataTable
        height={'calc(100vh / 3)'}
        data={items}
        columns={columnData}
        loading={loading}
        filterable={filterable}
        noDataMsg='No results found.'
        rightColumns={['actions']}
        hideFilter
        getCellValue={(row: any, columnName: string) => {
          switch (columnName) {
            case 'title':
              return (
                <ValueLabel
                  value={
                    <Link rel='noopener' title={row.title} to={`/${row.slug}/conversations/${row.id}`} target='blank' color='primary'>
                      {row.title}
                    </Link>
                  }
                />
              )
            case 'topic':
              return (
                <ValueLabel
                  value={
                    <Link rel='noopener' title={row.title} to={`/admin/topics/${row.slug}/edit`} target='blank' color='primary'>
                      {row.title}
                    </Link>
                  }
                />
              )
            case 'startsAt':
              return <ValueLabel value={row.startsAt && moment(row.startsAt).format('L')} />
            case 'time':
              return <ValueLabel value={row.startsAt && moment(row.startsAt).format('LT')} />
            case 'attendeeCount':
              return <ValueLabel value={row.guestCount} />
            case 'guestsFull':
              return <ValueLabel value={row.attendeeCount === row.maxAttendees ? <Chip size='small' label='Full' /> : ' '} />
            case 'attendedCount':
              return <ValueLabel value={row.attendedCount} />
            case 'host':
              return <ValueLabel value={row.host && `${row.host.firstName} ${row.host.lastName}`} />
            case 'venueType':
              return <ValueLabel value={row.venueType === 'virtual' ? 'Online' : row.venueType === 'other' ? 'Office / Other' : startCase(row.venueType)} />
            case 'hostNotes':
              return (
                row.hostNotes && (
                  <IconButton size='small' onClick={() => setHostNotes(row.hostNotes)}>
                    <i className='fas fa-sticky-note opacity-8'></i>
                  </IconButton>
                )
              )
            case 'actions':
              return <ValueLabel value={<ConversationActions conversation={row} />} />
            default:
              return <ValueLabel value={row[columnName]} />
          }
        }}
      />
      <Dialog
        open={!!hostNotes}
        dialog={{
          closeDialog: () => {
            setHostNotes(undefined)
          }
        }}
        title={'Conversation Host Notes'}
      >
        <Container>
          <div className='fat-top fat-bottom'>{hostNotes}</div>
        </Container>
      </Dialog>
    </>
  )
}

interface Props {
  index: number
}
interface InjectedProps {
  items: IConversation[]
  loading: boolean
  filterable: IFilterable
  selectedPartnerId?: number
  user?: IUser
}
export default flow(
  observer,
  inject<InjectedProps, Props>(({ mst: { conversations, auth } }: { mst: IStore }) => {
    return {
      user: auth.user,
      filterable: conversations,
      items: conversations.list,
      loading: conversations.loadingIds.includes('conversations_table')
    }
  }),
  withRouter
)(ConversationsTable)
