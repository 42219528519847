import React from 'react'
import MetaTags from 'react-meta-tags'
import { capitalize } from '../../decorators/textTools'

interface Props {
  title: string
  pageType?: string
  slug?: string
}

const AdminMetaTags = ({ title, pageType, slug }: Props) => {
  pageType = pageType ? ` - ${pageType}` : ''
  slug = slug ? ` - ${slug}` : ''

  return (
    <MetaTags>
      <title>{`${capitalize(window.siteTitle)} Admin - ${title}${pageType}${slug}`}</title>
    </MetaTags>
  )
}

export default AdminMetaTags
