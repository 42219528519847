import React from 'react'
import Button from '@material-ui/core/Button'
import { useForm } from 'react-hook-form'
import { AvatarControl } from '../utils'
import { useContext } from 'react'
import { AuthContext } from '../Providers/AuthProvider'
import { useTranslation } from 'react-i18next'

const ProfileImageUploader = ({ onUpload }: { onUpload?() }) => {
  const { handleSubmit, formState, control } = useForm({ mode: 'onBlur' })

  const { isDirty, isSubmitting } = formState
  const { t } = useTranslation()

  const { user } = useContext(AuthContext)
  const onSave = (data: any) => {
    user?.updateProfile(data).then(() => onUpload && onUpload())
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSave)}>
        <div className='inline-block'>
          <AvatarControl name='profileImage' control={control} avatarSize={'primary'} profileImage={user?.profileImage} hasProfileImage={user?.hasProfileImage} />
        </div>

        <br />
        <br />
        <br />

        <Button disabled={!isDirty || isSubmitting} type='submit' variant='contained' color='secondary'>
          {t('common.addPhoto')}
        </Button>
      </form>
    </div>
  )
}

export default ProfileImageUploader
