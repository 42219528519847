import { types, Instance, getRoot } from 'mobx-state-tree'
import { MetaImage } from '../base/BaseObjects'
import PartnerBase from './PartnerBase'
import PartnerTopics from './PartnerTopics'
import PartnerConversations from './PartnerConversations'
import { Alertable } from '../helpers/Alertable'
import { includes } from 'lodash'
import PartnerAdmins from './PartnerAdmins'
import partnersApi from '../../../api/PartnersApi'
import { snakify } from '../../../decorators/textTools'
import PartnerAdminInvitations from './PartnerAdminInvitations'
import Sectionables from '../helpers/Sectionables'
import Track from '../Track'
import { RootAccessable } from '../helpers/RootAccessable'

const SsoInfo = types.model('SsoInfo', {
  id: types.identifierNumber,
  metadata: types.string,
  login: types.string,
  domain: types.string
})

const Domain = types.model('Domain', {
  id: types.identifierNumber,
  domain: types.string
})

export const Partner = types
  .compose(
    'Partner',
    PartnerBase,
    PartnerTopics,
    PartnerConversations,
    Alertable,
    PartnerAdmins,
    PartnerAdminInvitations,
    Sectionables,
    RootAccessable,
    types.model({
      organizationId: types.maybeNull(types.number),
      about: types.maybeNull(types.string),
      facebookLink: types.maybeNull(types.string),
      instagramLink: types.maybeNull(types.string),
      introduction: types.maybeNull(types.string),
      language: types.maybeNull(types.string),
      locale: types.maybeNull(types.string),
      location: types.maybeNull(types.string),
      metaDescription: types.maybeNull(types.string),
      metaTitle: types.maybeNull(types.string),
      twitterLink: types.maybeNull(types.string),
      website: types.maybeNull(types.string),
      hasMetaImage: types.maybe(types.boolean),
      featuredConversations: types.maybeNull(types.boolean),
      featuredConversationsTitle: types.maybeNull(types.string),
      featuredTopicHeader: types.maybeNull(types.string),
      upcomingConversationHeader: types.maybeNull(types.string),
      featuredConversationHeader: types.maybeNull(types.string),
      supportedByHeader: types.maybeNull(types.string),
      googleTagId: types.maybeNull(types.string),
      profileImage: MetaImage,
      backgroundImage: MetaImage,
      metaImage: MetaImage,
      regionCoordinates: types.optional(types.array(types.number), []),
      ssoInfos: types.optional(types.array(SsoInfo), []),
      domains: types.optional(types.array(Domain), []),
      tracks: types.optional(types.array(Track), []),
      timezones: types.optional(types.array(types.string), []),
      userConnections: types.maybeNull(types.boolean),
      churnzeroSync: types.maybeNull(types.boolean),
      allowDocumentUpload: types.maybeNull(types.boolean),
      isJourney: types.maybeNull(types.boolean),
      enableSurveyScoring: types.maybeNull(types.boolean),
      allowVideos: types.maybeNull(types.boolean),
      logoImage: types.maybe(MetaImage),
      privateLabelEmailHeaderImage: types.maybeNull(MetaImage),
      privateLabel: types.maybeNull(types.boolean),
      maxTopics: types.maybeNull(types.number),
      topicsCount: types.maybeNull(types.number)
    })
  )
  .views((self) => ({
    get canEdit() {
      const { user } = self.getAuth()
      return user && (user.isSuperAdmin() || (user.isPartnerAdmin() && includes(user.partnerSlugs, self.slug)))
    }
  }))
  .actions((self) => ({
    sectionableType: () => 'Partner',
    updatePartner: (data, withImage = false) => {
      return partnersApi.update({ ...snakify(data), id: self.id }, withImage).then(({ response: { ok }, json }) => {
        if (ok) {
          self.updateMe(json)
          self.showAlert('Partner successfully updated')
          return { data: json }
        } else {
          self.showAlert('Error')
          return { error: json }
        }
      })
    }
  }))

export interface IDomain extends Instance<typeof Domain> {}
export interface ISsoInfo extends Instance<typeof SsoInfo> {}
export interface IPartner extends Instance<typeof Partner> {}
export default Partner
