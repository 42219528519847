import React from 'react'
import { camelCase, flow } from 'lodash'
import { observer, inject } from 'mobx-react'
import { connectTopicTheme } from '../../connectTheme'
import { IConversation, IStore, IStory, ITopic, IUser } from '../../store'
import withTranslation, { WithTranslation } from '../hocs/withTranslation'

import { TwitterShareButton, FacebookShareButton } from 'react-share'
import { Typography, createStyles, makeStyles, Grid, GridSpacing, Avatar, GridJustification } from '@material-ui/core'
import classnames from 'classnames'
import appAnalytics from '../../analytics'

interface Props {
  conversation?: IConversation
  topic?: ITopic
  story?: IStory
  theme?: any
  style: React.CSSProperties | undefined
  buttonContainerStyle: React.CSSProperties | undefined
  gridSizes?: any
  color?: string
  labelColor?: string
  align?: string
  className?: string
  iconClassNames?: string
  justify?: GridJustification
  longLabel?: boolean
  noLabel?: boolean
  spacing?: GridSpacing
  hideShareLabel?: boolean
}
interface InjectedProps {
  userLoaded: boolean
  user?: IUser
  count?: number | boolean
}

const SocialMediaButton = ({ children, gridSizes = { xs: 12, md: 12, lg: 1 }, style }: any) => {
  const classes = Styles()
  return (
    <Grid item {...gridSizes} className={classes.linkContainer} style={style}>
      <Avatar color='primary' className={classes.linkAvatar}>
        {children}
      </Avatar>
    </Grid>
  )
}

const CustomButton = observer(({ onClick, children, iconClassNames, color = '#ffffff', align = 'center', labelColor = '#ffffff' }: any) => {
  const classes = Styles()
  return (
    <button onClick={onClick} className={classnames('react-share__ShareButton', classes.button)} style={{ textAlign: align }}>
      <i className={classnames(classes.icon, iconClassNames)} style={{ color }} />
    </button>
  )
})

const ShareStory = ({
  story,
  t,
  userLoaded,
  style,
  gridSizes = { xs: 3 },
  color = '#ffffff',
  labelColor = '#ffffff',
  align,
  longLabel = false,
  buttonContainerStyle = undefined,
  noLabel = false,
  spacing = 0,
  iconClassNames,
  className,
  hideShareLabel,
  justify = undefined
}: Props & InjectedProps & WithTranslation) => {
  const classes = Styles()

  if (!story /*|| (userLoaded && !conversation.isAttending())*/) {
    return <></>
  }
  const content = story.content
  const url = story.storyLink
  const hashtag = camelCase(story?.slug)
  const headline = story?.headline
  return (
    <Grid container alignItems='center' justify={justify}>
      {!hideShareLabel && (
        <Grid item>
          <Typography variant='body1'>Share</Typography>
        </Grid>
      )}
      <Grid item>
        <Grid justify='center' container spacing={spacing} style={style} className={className}>
          <SocialMediaButton gridSizes={gridSizes} style={buttonContainerStyle}>
            <FacebookShareButton
              onClick={(e) => {
                appAnalytics.event({
                  category: 'Story',
                  action: 'Share on Facebook'
                })
                e.preventDefault()
                e.stopPropagation()
              }}
              className={classes.button}
              url={url}
              hashtag={hashtag}
              quote={content}
              style={{ textAlign: align as any }}
            >
              <i className={classnames('fab fa-facebook', classes.icon, iconClassNames)} style={{ color }} />
              {!noLabel && (
                <Typography component='span' classes={{ root: classes.label }} style={{ color: labelColor }}>
                  {t(longLabel ? 'socialMedia.shareOnFacebookLong' : 'socialMedia.shareOnFacebook')}
                </Typography>
              )}
            </FacebookShareButton>
          </SocialMediaButton>

          <SocialMediaButton gridSizes={gridSizes} style={buttonContainerStyle}>
            <CustomButton
              align={align}
              color={color}
              labelColor={labelColor}
              onClick={(e) => {
                appAnalytics.event({
                  category: 'Story',
                  action: 'Share on LinkedIn'
                })
                window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${url}`, '_blank', 'width=600,height=600')
                e.preventDefault()
                e.stopPropagation()
              }}
              iconClassNames={`fab fa-linkedin-in ${iconClassNames}`}
            >
              {!noLabel && t(longLabel ? 'socialMedia.shareOnLinkedInLong' : 'socialMedia.shareOnLinkedIn')}
            </CustomButton>
          </SocialMediaButton>
          <SocialMediaButton gridSizes={gridSizes} style={buttonContainerStyle}>
            <TwitterShareButton
              onClick={(e) => {
                appAnalytics.event({
                  category: 'Story',
                  action: 'Share on Twitter'
                })
                e.preventDefault()
                e.stopPropagation()
              }}
              className={classes.button}
              url={url}
              style={{ textAlign: align as any }}
              // hashtags={[hashtag]}
              title={headline}
            >
              <i className={classnames('fab fa-twitter', classes.icon, iconClassNames)} style={{ color }} />
              {!noLabel && (
                <Typography component='span' classes={{ root: classes.label }} style={{ color: labelColor }}>
                  {t(longLabel ? 'socialMedia.shareOnTwitterLong' : 'socialMedia.shareOnTwitter')}
                </Typography>
              )}
            </TwitterShareButton>
          </SocialMediaButton>
          <SocialMediaButton gridSizes={gridSizes} style={buttonContainerStyle}>
            <CustomButton
              align={align}
              color={color}
              labelColor={labelColor}
              onClick={(e) => {
                navigator.clipboard.writeText(url)
                story.showAlert(t('socialMedia.copiedLink'))
                e.preventDefault()
                e.stopPropagation()
              }}
              iconClassNames={`fas fa-link ${iconClassNames}`}
            >
              {!noLabel && t(longLabel ? 'socialMedia.copyLinkLong' : 'socialMedia.copyLink')}
            </CustomButton>
          </SocialMediaButton>
        </Grid>
      </Grid>
    </Grid>
  )
}

const Styles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: '2em'
    },
    header: {
      fontWeight: 'normal',
      fontSize: '32px'
    },
    linkContainer: {
      margin: '0 auto'
    },
    label: {
      fontFamily: 'avenir, sans-serif !important',
      color: '#ffffff',
      fontSize: '18px !important',
      whiteSpace: 'nowrap',
      fontWeight: 400,
      alignItems: 'flex-end'
    },
    linkAvatar: {
      backgroundColor: '#ffffff',
      margin: '0 auto'
    },
    icon: {
      margin: '0 auto',
      fontSize: '1.3em',
      alignItems: 'flex-end',
      color: '#ffffff',
      alignSelf: 'center'
    },
    button: {
      backgroundColor: 'transparent',
      border: 'none',
      padding: '0',
      font: 'inherit',
      color: 'inherit',
      cursor: 'pointer',
      outline: 'none',
      '&:hover': {
        textDecoration: 'underline !important'
      }
    }
  })
)

export default flow(
  connectTopicTheme,
  observer,
  inject<InjectedProps, Props>(
    ({
      mst: {
        auth: { user, loaded: userLoaded }
      }
    }: {
      mst: IStore
    }) => {
      user && user.shouldLoad('userConversations', user.userConversations)

      return {
        user,
        userLoaded,
        count: user?.userConversations.length
      }
    }
  ),
  withTranslation
)(ShareStory)
