import React from 'react'
import { flow } from 'lodash'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'

import ConversationListCard from '../../conversations/ListCard'
import { connectTopicTheme } from '../../../connectTheme'
import withTranslation from '../../hocs/withTranslation'
import LoadingAnimation from '../../shared/LoadingAnimation'
import { inject, observer } from 'mobx-react'
import { IStore } from '../../../store'
import moment from 'moment-timezone'

const ConversationsList = (props: any) => {
  const { user, conversations, isHost, loadingList, t } = props

  if (loadingList) {
    return <LoadingAnimation size={40} />
  }

  const attendButton = () => {
    if (!window.coco) {
      return (
        <div className='col-xs-12 col-xs-6 thin-top reg-top-xs'>
          <Link to={'/topics'}>
            <Button color='primary' fullWidth={true} className='btn-xl' variant='contained'>
              {t('conversation.attendFirst')}
            </Button>
          </Link>
        </div>
      )
    }
  }

  const Placeholder = () => {
    return (
      <div className='row no-gutters black-text huge-bottom'>
        <div className='col-xs-12 no-gutters'>
          <div className='dashed-border lightest-grey-border card-padding card-padding-xs'>
            <h2 className='header-24 header-20-sm flat-top line-height-reg flat-bottom align-center'>
              {window.coco ? t('conversation.haventHostedConvo') : t('conversation.haventHosted')}
            </h2>
            {attendButton()}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      {user?.userConversations.length === 0 ? (
        <Placeholder />
      ) : (
        <>
          {!!conversations.length && (
            <div className='huge-bottom'>
              {[...conversations]
                .sort((b, a) => moment(a.startsAt).diff(moment(b.startsAt)))
                .map((conversation) => (
                  <ConversationListCard conversation={conversation} isHost={isHost} key={conversation.id} />
                ))}
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default flow(
  connectTopicTheme,
  withTranslation,
  observer,
  inject<any, any>(({ mst }: { mst: IStore }, ownProps) => {
    const {
      auth: { user },
      conversations,
      conversations: { allUpcomingConversations }
    } = mst

    if (user) {
      conversations.shouldLoad('myConversationsList', conversations.loadMyConversations)
      conversations.shouldLoad('hostedList', conversations.loadHostedConversations)
    }

    return {
      user,
      conversations: allUpcomingConversations,
      listCount: allUpcomingConversations.length,
      loadingList: conversations.loadingIds.includes('myConversationsList') || conversations.loadingIds.includes('hostedList')
    }
  })
)(ConversationsList)
