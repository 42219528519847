import { post } from './utils'

class TestEmailsApi {
  static create(data) {
    return post(`/test_emails`, {
      body: JSON.stringify(data)
    })
  }
}

export default TestEmailsApi
