import React from 'react'
import classes from '../../../../stylesheets/admin/GraphToolTip.module.scss'
import { Grid } from '@material-ui/core'
interface Props {
  input: any
}

const BarGraphToolTip = ({ input }: Props) => {
  const { indexValue, color, value, id } = input

  return (
    <div className={`${classes.barContainer}`}>
      <div className={`${classes.header} bold thin-bottom`}>{indexValue}</div>
      <Grid container>
        <Grid item xs={9}>
          <Grid container>
            <Grid item xs={2}>
              <div className={classes.colorSwatch} style={{ backgroundColor: color }}></div>
            </Grid>
            <Grid item xs={10} className={classes.colorSwatchLabel}>
              {id}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3} className={classes.colorSwatchData}>
          <Grid container>
            <Grid item xs={12} className='align-right'>
              {value}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default BarGraphToolTip
