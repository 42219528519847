import { Button, Typography } from '@material-ui/core'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import Avatar from '../users/Avatar'

const AvatarUploader = ({
  value,
  onChange,
  avatarSize = 'primary',
  profileImage,
  rounded = true,
  hasProfileImage,
  position,
  accept = '*/*',
  placeHolder,
  className = ''
}: FIXME) => {
  const [selected, setselected] = useState<any>(undefined)
  const renderPreview = () => {
    if (value && value !== 'null') {
      return (
        <div className={`avatar avatar-image-${avatarSize}`}>
          <div className={`resize-to-fill resize-to-fill-${avatarSize} ${position}-margin`}>
            {/* <img src={preview} alt='preview' /> */}
            {value && <MediaFilePreview file={value} rounded={rounded} className={className} />}
          </div>
        </div>
      )
    } else {
      return <Avatar size={avatarSize} profileImage={profileImage} hasProfileImage={hasProfileImage} placeHolder={placeHolder} />
    }
  }

  return (
    <div>
      <div className='reg-bottom'>{renderPreview()}</div>
      <Button variant='contained' component='label'>
        Choose File
        <input
          type='file'
          accept={accept}
          onChange={(e: any) => {
            setselected(e.target.files[0])
            onChange(e.target.files[0])
          }}
          hidden
        />
      </Button>
      <Typography variant='caption'>&nbsp;&nbsp;{selected ? selected?.name : 'No file chosen'}</Typography>
    </div>
  )
}

export const MediaFilePreview = ({ file, rounded = true, className = '' }) => {
  if (!file) {
    return <></>
  }
  let url = file?.url
  if (!url) {
    const urlHelper = window.URL || window.webkitURL || window.mozURL
    url = urlHelper.createObjectURL(file)
  }
  const ext = url.split(/[#?]/)[0].split('.').pop().trim().toLowerCase()
  const videoExt = ['webm', 'mov', 'mp4']
  const imageExt = ['jpg', 'png', 'gif', 'jpeg']
  const isVideo = file && ((file?.type && file.type.match('video.*')) || videoExt.includes(ext))
  const isImage = file && ((file?.type && file.type.match('image.*')) || imageExt.includes(ext))

  return (
    <>
      {isImage && <ImageFilePreview file={file} rounded={rounded} className={className} />}
      {isVideo && <VideoFilePreview file={file} className={className} />}
      {file && !isImage && !isVideo && <ImageFilePreview file={file} rounded={rounded} className={className} />}
    </>
  )
}

export const ImageFilePreview = observer(({ file, rounded = true, className = '' }) => {
  if (file?.url) {
    return (
      <img
        src={file.url}
        alt='preview'
        style={{ margin: '0 auto', ...(rounded ? { borderRadius: '.5em' } : { borderRadius: 'unset' }) }}
        className={`img-responsive ${className}`}
      />
    )
  }
  const url = window.URL || window.webkitURL || window.mozURL

  const preview = url.createObjectURL(file)
  return (
    <img src={preview} alt='preview' style={{ margin: '0 auto', ...(rounded ? { borderRadius: '.5em' } : { borderRadius: 'unset' }) }} className={className || 'img-responsive'} />
  )
})

export const VideoFilePreview = ({ file, className = '' }) => {
  return (
    <video controls className={`img-responsive ${className}`}>
      <source src={file?.url || URL.createObjectURL(file)} />
    </video>
  )
}

export default AvatarUploader
