import React from 'react'
import { Tabs, Tab } from '@material-ui/core'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
  className?: any
  wide?: boolean
  noBottom?: boolean
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, className = '', wide = false, noBottom, ...other } = props

  return value === index ? (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      className={`${className} ${wide ? '' : 'row'} center-xs ${noBottom ? undefined : 'huge-bottom'}`}
      {...other}
    >
      <div className={`col-xs-12 start-xs fat-top no-gutters ${wide ? '' : 'form-xl'} `}>{value === index && children}</div>
    </div>
  ) : null
}

function a11yProps(index: any) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`
  }
}

interface ITab {
  label: string
  content: JSX.Element
  wide?: boolean
  noBottom?: boolean
}
export default function AppTabs({
  tabs = [],
  index = undefined,
  defaultTab = 0,
  style = {},
  noBottom = false,
  contentStyle,
  onChange
}: {
  index?: number
  tabs: ITab[]
  defaultTab?: number
  style?: React.CSSProperties
  noBottom?: boolean
  contentStyle?: React.CSSProperties
  onChange?(tabIndex: number): void
}) {
  const [value, setValue] = React.useState(index !== undefined ? index : defaultTab)

  const handleChange = (event: any, newValue: number) => {
    onChange ? onChange(newValue) : setValue(newValue)
  }

  return (
    <>
      <div {...{ style }}>
        <Tabs variant='scrollable' scrollButtons='auto' value={index !== undefined ? index : value} onChange={handleChange} aria-label='nav tabs'>
          {tabs.map((tab, i) => (
            <Tab key={`header-${tab.label}`} label={tab.label} {...a11yProps(i)} />
          ))}
        </Tabs>
      </div>
      <div style={contentStyle}>
        {tabs.map((tab, i) => (
          <TabPanel
            key={`content-${tab.label}`}
            value={index !== undefined ? index : value}
            index={i}
            wide={tab.wide}
            className={noBottom || tab.noBottom ? undefined : 'hugest-bottom-padding'}
            noBottom={noBottom || tab.noBottom}
          >
            {tab.content}
          </TabPanel>
        ))}
      </div>
    </>
  )
}
