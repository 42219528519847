import React from 'react'
import { withRouter } from 'react-router'

import Navigation from './navigation/index'
import Footer from './Footer'
import CookieFooter from '../shared/CookieFooter'
import withTranslation from '../hocs/withTranslation'
import { observer, inject } from 'mobx-react'
import { IStore } from '../../store/models'
import { AppBar, Typography } from '@material-ui/core'
import { flow } from 'lodash'
import { IUser } from '../../store/models/User'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import AlertProvider from '../Providers/AlertProvider'
import DialogProvider from '../Providers/DialogProvider'
import AuthProvider from '../Providers/AuthProvider'
import AppLayoutProvider from '../Providers/AppLayoutProvider'

const AppLayout = ({ isMasquerading, children, location: { pathname } }: Props & InjectedProps) => {
  const classes = useStyles()
  const isAdminView = pathname.match(/^\/admin$/g) || pathname.match(/^\/admin\//g)
  // const isHomePage = pathname.match(/^\/$/g) || ''

  return (
    <AppLayoutProvider>
      <AuthProvider>
        <AlertProvider>
          <DialogProvider>
            <div className={classes.root}>
              <div className={classes.rootContent}>
                <AppBar color='inherit' position='fixed' className={classes.appHeader}>
                  <Navigation />
                </AppBar>

                <main className={classes.content}>{children}</main>

                {!isAdminView && (
                  <AppBar color='inherit' position='relative' className={classes.appFooter}>
                    <Footer />
                  </AppBar>
                )}
                <CookieFooter />
                {isMasquerading &&
                  [classes.masqueradeTop, classes.masqueradeBottom].map((barClass) => (
                    <AppBar key={barClass} color='inherit' position='fixed' className={classnames(barClass, classes.masqueradeBar)}>
                      <Typography variant='body2' component='p' color='textPrimary'>
                        <i className='far fa-theater-masks opacity-8 thin-right'></i>
                        Masquerading
                        <i className='far fa-theater-masks opacity-8 thin-left'></i>
                      </Typography>
                    </AppBar>
                  ))}
              </div>
            </div>
          </DialogProvider>
        </AlertProvider>
      </AuthProvider>
    </AppLayoutProvider>
  )
}

interface Props {
  children: any
  history: any
  location: any
  match: any
  changeLanguage: any
  t: any
}
interface InjectedProps {
  isMasquerading?: boolean
  user?: IUser
}

export default flow(
  withRouter,
  withTranslation,
  observer,
  inject<InjectedProps, Props>(({ mst }: { mst: IStore }, ownProps) => {
    const { auth, dialogs, alertMessages } = mst
    return {
      user: auth.user,
      isMasquerading: auth.isMasquerading()
    }
  })
)(AppLayout)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    appHeader: {
      zIndex: theme.zIndex.drawer + 3
    },
    masqueradeBar: {
      zIndex: theme.zIndex.drawer + 4,
      backgroundColor: 'rgba(255,0,0,.7)',
      textAlign: 'center'
    },
    masqueradeTop: {
      bottom: 'auto',
      top: 0
    },
    masqueradeBottom: {
      top: 'auto',
      bottom: 0,
      padding: '.25em'
    },
    appFooter: {
      zIndex: theme.zIndex.drawer + 3
    },
    content: {
      flexGrow: 1,
      // overflow: 'auto',
      marginTop: '4.8em',
      minHeight: 'calc(100vh - 8.8rem)'
    },
    rootContent: {
      '@media (max-width:600px)': {
        overflow: 'hidden'
      },
      width: '100vw',
      overflow: 'auto'
    }
  })
)
