import React from 'react'
import AddToCalendar from '../utils/react-add-to-calendar'
import { createStyles, makeStyles } from '@material-ui/core'
import { capitalize } from '../../decorators/textTools'
import withTranslation, { WithTranslation } from '../hocs/withTranslation'
import { IConversation } from '../../store'
import classnames from 'classnames'

interface Props {
  conversation?: IConversation
}
const CalendarDropdown = ({ conversation, t }: Props & WithTranslation) => {
  if (!conversation) return <></>
  const classes = Styles()

  const { title, userVirtualLink, guestInstructions, formattedAddress, isVirtual, startsAt, endsAt, brandSingular, virtualEventTypeSingular } = conversation

  const buildTitle = () => {
    if (isVirtual) {
      return `${title} ${capitalize(virtualEventTypeSingular)}`
    } else {
      return `${title} ${brandSingular}`
    }
  }

  const buildDescription = () => {
    if (isVirtual && !!guestInstructions) {
      return `<strong>${title} ${capitalize(virtualEventTypeSingular)} ${t(
        'conversation.calLink'
      )}</strong><br/><a href=${userVirtualLink} target="_blank">${userVirtualLink}</a><br/><br/><strong>${t('conversation.calJoinInstructions')}</strong><br/>${guestInstructions}`
    }
    if (isVirtual && !guestInstructions) {
      return `<strong>${title} ${capitalize(virtualEventTypeSingular)} ${t('conversation.calLink')}</strong><br/><a href=${userVirtualLink} target="_blank">${userVirtualLink}</a>`
    } else {
      return t('conversation.calAttending', { title, brandSingular })
    }
  }

  const buildLocation = () => {
    if (isVirtual) return `${capitalize(t('conversation.calLink'))} ${userVirtualLink}`
    else return formattedAddress
  }

  const event = {
    title: buildTitle(),
    location: buildLocation(),
    startTime: startsAt,
    endTime: endsAt,
    description: buildDescription()
  }

  return (
    <AddToCalendar
      optionsOpen={true}
      event={event}
      buttonLabel={t('conversation.addToCalendar')}
      useFontAwesomeIcons={true}
      rootClass={classnames('react-add-to-calendar', classes.root)}
      buttonIconClass={classnames('react-add-to-calendar__icon--', classes.icon)}
      buttonWrapperClass={classnames('react-add-to-calendar__wrapper', classes.buttonWrapper)}
      dropdownClass={classnames('react-add-to-calendar__dropdown', classes.dropdown)}
      buttonClassOpen={classnames('react-add-to-calendar__button--light', classes.buttonOpen)}
      buttonClassClosed={classnames('react-add-to-calendar__button', classes.buttonClosed)}
    />
  )
}

const Styles = makeStyles((theme) =>
  createStyles({
    root: {
      fontFamily: 'Avenir',
      color: '#737374',
      minWidth: '11em'
    },
    icon: {},
    buttonWrapper: {
      fontFamily: 'Avenir',
      fontSize: '22px',
      lineHeight: '24px',
      color: '#0099C1 !important'
    },
    buttonOpen: {},
    buttonClosed: {
      fontWeight: 500,
      fontSize: '13px',
      lineHeight: '22px'
    },
    dropdown: {
      '& li': {
        fontSize: '1.2em !important',
        alignItems: 'flex-end',
        fontFamily: 'avenir, sans-serif !important',
        fontWeight: 400,
        whiteSpace: 'nowrap',
        marginBottom: '.7em !important',
        paddingLeft: '.7em',
        '& i': {
          color: '#737374 !important',
          fontSize: '1.4em'
        },
        '& :hover': {
          textDecoration: 'underline'
        }
      }
    }
  })
)

export default withTranslation(CalendarDropdown)
