import surveyQuestionsApi from '../../../api/SurveyQuestionsApi'
import { types, Instance, getRoot } from 'mobx-state-tree'
import { Alertable } from '../helpers/Alertable'
import TopicBase from './TopicBase'
import { IStore } from '..'
import { camelify, snakify } from '../../../decorators/textTools'
import Editable from '../helpers/Editable'

const AnswerOption = types.model({
  answer: types.maybeNull(types.string),
  value: types.maybeNull(types.number),
  max: types.maybeNull(types.number),
  weight: types.maybeNull(types.number)
})
const SurveyQuestion = types
  .compose(
    'SurveyQuestion',
    Alertable,
    Editable,
    types.model({
      id: types.identifierNumber,

      topicId: types.number,
      description: types.maybeNull(types.string),
      question: types.maybeNull(types.string),
      questionType: types.maybeNull(types.string),
      options: types.maybeNull(types.array(AnswerOption))
    })
  )
  .actions((self) => ({
    updateSurveyQuestion: (formData) => {
      return surveyQuestionsApi.update(snakify(formData)).then(({ response: { ok, status, statusText }, json }) => {
        if (ok) {
          self.showAlert('Survey question updated!')
          self.updateMe(camelify(json))
          return { savedData: json }
        }
      })
    }
  }))

export const TopicSurveyQuestions = types
  .compose(
    'TopicSurveyQuestions',
    TopicBase,

    types.model({
      slug: types.string,
      surveyQuestions: types.optional(types.array(SurveyQuestion), [])
    })
  )
  .actions((self) => ({
    removeSurveyQuestion: (id: number) => {
      self.surveyQuestions.replace(self.surveyQuestions.filter((sq) => sq.id !== id))
    },
    addToSurveyQuestions: (json) => {
      self.surveyQuestions.push(json)
    },
    setSurveyQuestions(val: any) {
      self.surveyQuestions = val
    }
  }))
  .actions((self) => ({
    loadSurveyQuestions() {
      const loadName = 'surveyQuestions'

      if (self.isLoading(loadName) || self.isLoaded(loadName)) return Promise.resolve()
      self.startLoading(loadName)
      return surveyQuestionsApi.getAll(self.slug).then(({ response: { ok }, json }) => {
        if (ok) {
          self.setSurveyQuestions(json)
        }
        self.stopLoading(loadName)
      })
    },
    deleteQuestion: (id, { confirm = true } = {}) => {
      if (confirm) {
        self.showDialog('deleteSurveyQuestion', { id, topicId: self.id })
      } else {
        return surveyQuestionsApi.destroy(id).then((res) => {
          const { ok } = res.response

          if (ok) {
            self.removeSurveyQuestion(id)
            self.showAlert('Question removed')
          }
        })
      }
    },
    createSurveyQuestion: (data) => {
      return surveyQuestionsApi.create({ ...data, topic_id: self.id }).then(({ response: { ok, status, statusText }, json }) => {
        if (ok) {
          self.addToSurveyQuestions(json)
          self.showAlert('Survey question created!')
        }
      })
    },
    createSurveyQuestionCsvExport: (surveyQuestionId: number) => {
      const { downloads }: IStore = getRoot(self)
      return downloads.createCsvExport('survey_question', { surveyQuestionId }, { download: true })
    }
  }))

export interface ISurveyQuestion extends Instance<typeof SurveyQuestion> {}
export interface ITopicSurveyQuestions extends Instance<typeof TopicSurveyQuestions> {}
export interface AnswerOption extends Instance<typeof AnswerOption> {}
export default TopicSurveyQuestions
