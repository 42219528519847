import { types, Instance } from 'mobx-state-tree'
import ConversationBase from './ConversationBase'

import { Alertable } from '../helpers/Alertable'
import { Dialogable } from '../helpers/Dialogable'
import { MetaImage } from '../base/BaseObjects'
import PendingInvitedGuestsApi from '../../../api/conversations/pendingInvitedGuestsApi'
import Editable from '../helpers/Editable'

const ConversationInvite = types
  .compose(
    'ConversationInvite',
    Alertable,
    Dialogable,
    Editable,
    types.model({
      id: types.identifierNumber,
      conversationId: types.number,
      profileImage: types.maybe(MetaImage),
      hasProfileImage: types.maybeNull(types.boolean),
      firstName: types.maybeNull(types.string),
      lastName: types.maybeNull(types.string),
      recipientEmail: types.maybeNull(types.string),
      sentAt: types.maybeNull(types.string),
      createdAt: types.maybeNull(types.string),
      updatedAt: types.maybeNull(types.string)
    })
  )
  .actions((self) => ({
    fullName: () => `${self.firstName} ${self.lastName}`,
    resendInvite: ({ showDialog = true, message = undefined } = {}) => {
      if (showDialog) {
        self.showDialog('emailReinvite', { conversationId: self.conversationId, emails: self.recipientEmail })
        return Promise.resolve()
      }
      return PendingInvitedGuestsApi.resendInvite(self.conversationId, self.id, message).then(({ response: { ok }, json }) => {
        if (ok) {
          self.updateMe(json)
          self.showAlert('Resent invite')
        }
      })
    }
  }))

const ConversationInvites = types
  .compose(
    'ConversationInvites',
    ConversationBase,
    Dialogable,
    types.model({
      pendingInvite: types.optional(types.array(ConversationInvite), [])
    })
  )
  .actions((self) => ({
    setPendingInvite(val: any) {
      self.pendingInvite.replace(val)
    }
  }))
  .actions((self) => ({
    loadPendingInvite: () => {
      const listName = 'pendingInvite'
      if (self.isLoading(listName)) return

      self.startLoading(listName)
      return PendingInvitedGuestsApi.getAll(self.id).then(({ response: { ok }, json }) => {
        if (ok) {
          self.setPendingInvite(json)
        }
        self.stopLoading(listName)
      })
    },
    resendAllPendingInvite: ({ showDialog = true, message = undefined } = {}) => {
      if (showDialog) {
        self.showDialog('emailReinvite', { conversationId: self.id, conversation: self, emails: self.pendingInvite.map(({ recipientEmail }) => recipientEmail).join(', ') })
        return Promise.resolve({})
      }
      return PendingInvitedGuestsApi.resendAllInvite(self.id, message).then(({ response: { ok }, json }) => {
        if (ok) {
          self.setPendingInvite(json)
          self.showAlert('Resent invite')
        }
        return {}
      })
    }
  }))

export interface IConversationInvites extends Instance<typeof ConversationInvites> {}
export default ConversationInvites
