import React, { Component } from 'react'

import { flow } from 'lodash'
import { inject, observer } from 'mobx-react'
import { IStore, IUser } from '../../../../store/models'
import DataTable, { IColData } from '../../../shared/DataTableFilterable'
import { ITopic } from '../../../../store/models/Topic'
import { IFilterable } from '../../../../store/models/helpers/Filterable'
import TopicCellValue from '../partials/TopicCellValue'

class TopicsTable extends Component<InjectedProps> {
  render() {
    const { filterable, items, loading } = this.props

    const columnData: IColData[] = [
      { name: 'id', title: 'ID', width: 90, sortable: true, align: 'right' },
      { name: 'createdAt', title: 'Created Date', width: 140, sortable: true, align: 'right' },
      { name: 'title', title: 'Conversation Topic', width: 250, sortable: true },
      { name: 'active', title: 'Status', width: 100 },
      { name: 'startsAt', title: 'Start Date', width: 100, sortable: true, align: 'right' },
      { name: 'endsAt', title: 'End Date', width: 100, sortable: true, align: 'right' },
      ...(this.props.user?.isSuperAdmin() ? ([{ name: 'isTemplate', title: 'Template', width: 80, align: 'center', sortable: true }] as IColData[]) : []),
      { name: 'partnerName', title: 'Customer', minWidth: 200, sortable: true },
      { name: 'conversationsCount', title: 'Conversations', width: 120, sortable: true, align: 'right' },
      { name: 'language', title: 'Language', minWidth: 200 },
      { name: 'slug', title: 'Slug', minWidth: 250, sortable: true },
      { name: 'actions', title: 'Actions', width: 220, align: 'center' }
    ]

    return (
      <DataTable
        data={items}
        columns={columnData}
        loading={loading}
        filterable={filterable}
        rightColumns={['actions']}
        height={'calc(100vh - 29em)'}
        searchPlaceholder='Search (Conversation Topic, Customer, Slug)'
        getCellValue={TopicCellValue}
      />
    )
  }
}

interface InjectedProps {
  filterable: IFilterable
  items: ITopic[]
  loading: boolean
  user?: IUser
}

export default flow(
  observer,
  inject<InjectedProps, null>(
    ({
      mst: {
        topics,
        auth: { user }
      }
    }: {
      mst: IStore
    }) => {
      return {
        user,
        filterable: topics,
        items: topics.list,
        loading: topics.loadingIds.includes('topics_table')
      }
    }
  )
)(TopicsTable)
