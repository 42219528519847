import { deleteApi, post, put } from './utils'

class LmsCoursesApi {
  static create(data) {
    return post(`/lms_courses`, {
      body: JSON.stringify({ lmsCourseData: data })
    })
  }

  static update(data) {
    return put(`/lms_courses/${data.id}`, {
      body: JSON.stringify({ lmsCourseData: data })
    })
  }

  static destroy(data) {
    return deleteApi(`/lms_courses/${data.id}`)
  }
}

export default LmsCoursesApi
