import { Card, Checkbox, FormControlLabel } from '@material-ui/core'
import React, { ReactElement } from 'react'
import { IConversation } from '../../../../store/models/Conversation'
import SectionsEdit from '../../../sections/EditMst'
import { observer } from 'mobx-react'
import Sections from '../../../sections/Sections'

interface IProps {
  conversation: IConversation
}

const Sponsors = ({ conversation }: IProps): ReactElement => {
  return (
    <>
      <SectionsEdit sectionable={conversation} type='Conversation' />
      <section className={'xtra-fat-top'}>
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e, checked) => conversation.updateConversation({ showTopicSponsors: checked }, { success: 'Updated Topic sponsor visibility' })}
              checked={!!conversation.showTopicSponsors}
              className='centered-checkbox'
            />
          }
          label={'Show Topic Sponsors'}
        />
      </section>
      {conversation.showTopicSponsors && <Sections sectionable={conversation.topic} showSectionHeader={false} />}
    </>
  )
}

export default observer(Sponsors)
