import fetch from 'isomorphic-fetch'
import appAnalytics from '../analytics/index'
import { toFormData } from './FormData'
import { get, deleteApi, post, put } from './utils'

class UsersApi {
  static getAll() {
    return get(`/users`, { noContentTypeJson: true })
  }

  static get(id) {
    return get(`/users/${id}`, { noContentTypeJson: true })
  }

  static create(formData, data) {
    return post(`/users`, {
      body: JSON.stringify({
        user: formData,
        site_name: global.siteName,
        locale: data.locale
      })
    }).then((d) => {
      appAnalytics.event({
        category: 'User',
        action: 'Created User'
      })
      return d
    })
  }

  static update(data) {
    const formData = toFormData('user', data)

    return put(`/users/${data.id}`, { noContentTypeJson: true, body: formData }).then((d) => {
      return d
    })
  }

  static destroy(id) {
    return deleteApi(`/users/${id}`, { noContentTypeJson: true })
  }

  static verifyUniqueEmail(formData) {
    return post(`/verify_unique_email`, {
      noToken: true,
      body: JSON.stringify({ user: formData })
    })
  }

  static verifySsoEmail(formData) {
    return post(`/verify_sso_email`, {
      noToken: true,
      body: JSON.stringify({ user: formData })
    })
  }

  static getLocationFromIP(ip) {
    return fetch(`https://api.ipstack.com/${ip}?access_key=${process.env.REACT_APP_IP_STACK_KEY}`, {
      method: 'GET'
    })
      .then((response) => response.json().then((json) => ({ json, response })))
      .catch((error) => {
        return error
      })
  }

  static masquerade(id) {
    return get(`/users/${id}/masquerade`)
  }
}

export default UsersApi
