import React from 'react'
import { Typography } from '@material-ui/core'
import { flow } from 'lodash'
import { Button } from '@material-ui/core'

import withTranslation, { WithTranslation } from '../../hocs/withTranslation'
import Dialog from '../../DialogWrapper'
import { TextFieldControl } from '../../utils'
import { observer } from 'mobx-react'
import { useForm } from 'react-hook-form'
import { IDialog } from '../../../store/models/Dialog.js'
import { inject } from 'mobx-react'
import { IStore, ITopic, IUser } from '../../../store'
import FontIcon from '@material-ui/core/Icon'

interface Props {
  dialog: IDialog
  topic: ITopic
  open: boolean
}
interface InjectedProps {
  user?: IUser
}

const InviteHostDialog = ({ user, dialog, t, topic, open }: Props & InjectedProps & WithTranslation) => {
  const { handleSubmit, errors, formState, control } = useForm({ mode: 'onBlur', shouldUnregister: false })

  if (!user) {
    return <></>
  }

  const { isSubmitting } = formState

  const handleSave = handleSubmit((data: any, e) => {
    topic.inviteHost(data).then(dialog.closeDialog)
  })

  return (
    <Dialog open={open} dialog={dialog} name='topicInvitHost' title='Invite Hosts'>
      <div>
        <section className='pane minimal-grey-pane thin-reg-pane big-top-padding big-bottom-padding'>
          <div className='fat-bottom'>
            <div className='row reg-bottom'>
              <div className='col-xs-12 center-xs'>
                <FontIcon className='fa fa-envelope-o header-36' />
              </div>
            </div>
            <div className='row reg-bottom'>
              <div className='col-xs-12 center-xs'>
                <Typography variant='h6'>Send Invitations by Email</Typography>
              </div>
            </div>
          </div>
        </section>
        <section className='pane thin-reg-pane big-top-padding big-bottom-padding'>
          <form onSubmit={handleSave}>
            <div className='xtra-fat-bottom'>
              <div className='row reg-bottom'>
                <div className='col-xs-12'>
                  <Typography variant='body2'>Send invitations by email to the people you’d like to host your conversations.</Typography>
                </div>
              </div>
              <div className='row reg-bottom'>
                <div className='col-xs-12'>
                  <TextFieldControl
                    label='To'
                    name='to'
                    control={control}
                    error={errors && errors.message}
                    helperText='Enter valid email addresses separated by commas.'
                    variant='outlined'
                  />
                </div>
              </div>
              <div className='row reg-bottom'>
                <div className='col-xs-12'>
                  <TextFieldControl
                    label='Optional Message'
                    variant='outlined'
                    name='message'
                    control={control}
                    error={errors && errors.message}
                    multiline={true}
                    rows={2}
                    rowsMax={4}
                    helperText='We’ll send this message along with a direct link to create a new conversation for this topic and other helpful resources. '
                  />
                </div>
              </div>
            </div>

            <div className='row center-xs'>
              <div className='col-xs-12'>
                <Button disabled={isSubmitting} type='submit' className='reg-bottom xtra-fat-top' color='secondary' variant='contained' fullWidth>
                  Send Invitation
                </Button>
              </div>
            </div>
          </form>
        </section>
      </div>
    </Dialog>
  )
}

export default flow(
  observer,
  inject<InjectedProps, Props>(
    ({
      mst: {
        auth: { user }
        // topics: { list }
      }
    }: {
      mst: IStore
    }) => {
      return {
        user
        // topic: list.find(t => t.id === topicId)
      }
    }
  ),
  withTranslation
)(InviteHostDialog)
