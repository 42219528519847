import { assign } from 'lodash'

import { LOADING_DINNERS_TABLE, LOAD_DINNERS_TABLE_SUCCESS, LOAD_DINNERS_TABLE_FAILED } from '../actions/actionTypes.js'
import initialState from './initialState'

export const conversationsTable = (state = initialState.conversationsTable, action) => {
  switch (action.type) {
    case LOADING_DINNERS_TABLE:
      return assign({}, state, {
        loadingConversationsTable: true,
        errorConversationsTable: null
      })

    case LOAD_DINNERS_TABLE_SUCCESS:
      return assign({}, state, {
        loadingConversationsTable: false,
        errorConversationsTable: null,
        tableData: assign({}, state.tableData, action.data)
      })

    case LOAD_DINNERS_TABLE_FAILED:
      return assign({}, state, {
        loadingConversationsTable: false,
        errorConversationsTable: action.data
      })

    default:
      return state
  }
}

export default conversationsTable
