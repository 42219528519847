import React, { useEffect } from 'react'
import { Button, Grid, Typography } from '@material-ui/core'
import { pick, pickBy, identity, camelCase } from 'lodash'
import moment from 'moment'
import { observer, inject } from 'mobx-react'
import { useForm, FormProvider } from 'react-hook-form'

import { camelify, capitalize, snakify } from '../../../decorators/textTools'
import withTranslation from '../../hocs/withTranslation'
import { TextFieldControl, RadioGroupControl, AddressAutocompleteControl, CheckboxControl, SelectControl, DateControl, TimeControl } from '../../utils'
import { required, http, maxDailyCoAttendance, isValidDate, handleStartEndAt, minAttendance, isValidTime } from '../../validations/fieldLevelValidations'
import { IStore } from '../../../store/models'
import TimeZoneApi from '../../../api/TimeZoneApi'
import { Prompt } from 'react-router'
import { UNSAVED_FORM_WARNING } from '../../utils/constants'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { useAuth } from '../../Providers'
import { useTranslation } from 'react-i18next'
import HostAssign from './HostAssign/HostAssign'
// eslint-disable-next-line complexity
const ConversationForm = observer(({ conversation, onSave }: FIXME) => {
  const { user } = useAuth()
  const { t } = useTranslation()
  const formMethods = useForm({ mode: 'onChange', shouldUnregister: false })
  const { handleSubmit, errors, formState, control, watch, setValue, clearErrors, setError, reset, trigger } = formMethods

  const { isDirty, isSubmitting, dirtyFields } = formState

  const displayError = (error) => {
    if (error) {
      for (const fieldName in error) {
        setError(camelCase(fieldName), {
          type: 'manual',
          message: error[fieldName]
        })
      }
    }
  }

  const handleSave = handleSubmit((data: any, e) => {
    const { privateEvent, location, suitableDiets, attendeeCount, ...rest } = pick(data, Object.keys(pickBy(dirtyFields, identity)))
    const updateData = {
      ...camelify(location),
      ...(suitableDiets ? { suitableDiets: snakify(suitableDiets) } : {}),
      ...rest,
      max_attendees: attendeeCount,
      attendeeCount,
      ...handleStartEndAt(data),
      ...(privateEvent === undefined ? {} : { guestPolicy: privateEvent ? 'approval_only' : 'public' })
    }

    conversation
      .updateConversation(updateData)
      .then(() => {
        reset(data)
        onSave && onSave()
      })
      .catch(displayError)
  })

  const location = watch('location')

  const getCurrentVal = (name) => {
    return watch(name) === undefined ? conversation[name] : watch(name)
  }
  const isVirtual = getCurrentVal('venueType') === 'virtual'

  const isHomeConversation = getCurrentVal('venueType') === 'home'
  const isRestaurantConversation = getCurrentVal('venueType') === 'restaurant'
  const isOtherConversation = getCurrentVal('venueType') === 'other'

  useEffect(() => {
    if (conversation && location && location !== conversation.city) {
      setValue('city', location.city, { shouldDirty: true })
      setValue('placeId', location.place_id, { shouldDirty: true })
    }
  }, [dirtyFields.location, conversation, location, setValue])

  const handleLocation = (val) => {
    if (val) {
      if (isRestaurantConversation || isOtherConversation) {
        const venue_name = val?.data?.structured_formatting?.main_text
        const city = val?.data?.structured_formatting?.secondary_text
        venue_name && setValue('venueName', venue_name, { shouldDirty: true })
        city && setValue('city', city, { shouldDirty: true })
      }

      if (isVirtual || isHomeConversation) {
        setValue('city', val.name, { shouldDirty: true })
        setValue('placeId', val.value || '', { shouldDirty: true })
      }

      val?.value &&
        TimeZoneApi.getByPlaceId(val.value)
          .then(({ timeZoneId }) => {
            setValue('timezone', timeZoneId, { shouldDirty: true })
          })
          .catch((err) => {
            console.error({ err })
          })
    }
  }

  const attendanceValidator = { required, minAttendance }

  const currentTimezone = watch('timezone') || conversation?.timezone
  const isFormValid = Object.keys(errors).length === 0

  const minDateSynth = undefined
  const maxDateSynth = moment().format('yyyy-MM-DD')

  const min = minDateSynth
  const max = maxDateSynth
  const topic = conversation.topic

  return (
    <div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSave}>
            <HostAssign />
            <div>
              <div className='label'>{t('conversation.venueTypeLabel')}</div>
              <RadioGroupControl
                name='venueType'
                control={control}
                defaultValue={conversation ? conversation.venueType : ''}
                options={[
                  {
                    label: t('conversation.virtualTypeLabel', {
                      eventType: capitalize(conversation.virtualEventTypeSingular)
                    }),
                    // className: `hide ${(topic.virtualConversations || conversation?.venueType === 'virtual') && 'block'}`,
                    value: 'virtual'
                  },
                  {
                    label: t('conversation.restaurantTypeLabel'),
                    // className: `hide ${(topic.restaurantConversations || conversation?.venueType === 'restaurant') && 'block'}`,
                    value: 'restaurant'
                  },
                  {
                    label: t('conversation.homeTypeLabel'),
                    // className: `hide ${(topic.homeConversations || conversation?.venueType === 'home') && 'block'}`,
                    value: 'home'
                  },
                  {
                    label: t('common.other'),
                    // className: `hide ${(topic.otherConversations || conversation?.venueType === 'other') && 'block'}`,
                    value: 'other'
                  }
                ]}
              />
            </div>

            <TextFieldControl name='city' defaultValue={conversation.city || ' '} control={control} className='hide' />
            <TextFieldControl name='placeId' defaultValue={conversation.placeId || ' '} control={control} className='hide' />

            <>
              <div className='fat-top'>
                <AddressAutocompleteControl
                  types={['(regions)']}
                  name='location'
                  control={control}
                  label={'What city was this hosted in?'}
                  defaultValue={conversation.city || ''}
                  className='reg-bottom'
                  onChange={handleLocation}
                  required
                  rules={{ validate: required }}
                />

                <div className='reg-top'>
                  <TextFieldControl
                    name='attendeeCount'
                    control={control}
                    label={'Total Registered'}
                    value='8'
                    type='number'
                    defaultValue={(conversation && conversation.attendeeCount?.toString()) || ''}
                    rules={{ validate: attendanceValidator }}
                    error={errors && errors.attendeeCount}
                    className='reg-bottom'
                  />
                </div>

                <div className='reg-top'>
                  <TextFieldControl
                    name='attendedCount'
                    control={control}
                    label={'Total Attended'}
                    value='8'
                    type='number'
                    defaultValue={(conversation && conversation.attendedCount?.toString()) || ''}
                    rules={{ validate: attendanceValidator }}
                    error={errors && errors.attendedCount}
                    className='reg-bottom'
                  />
                </div>
              </div>
            </>

            <div className='fat-top'>
              <div className='label reg-top'>{t('common.date')}</div>
              <DateControl
                name='startsAtDate'
                control={control}
                error={errors && errors.startsAtDate}
                defaultValue={conversation ? moment(conversation.startsAt) : moment().subtract(1, 'days')}
                // dateProps={{
                //   inputProps: {
                //     max: moment().format('yyyy-MM-DD')
                //   }
                // }}
                rules={{
                  validate: (tm) => {
                    return !isValidDate(topic.startsAt, topic.endsAt)(moment(tm)) ? 'Invalid date' : undefined
                  }
                }}
                dateProps={{
                  inputProps: {
                    min: topic.startsAt && moment().isBefore(moment(topic.startsAt)) ? topic.startsAt : moment().format('yyyy-MM-DD'),
                    max: topic.endsAt ? topic.endsAt : undefined
                  }
                }}
              />

              <div className='label reg-top'>{t('common.startTime')}</div>

              <TimeControl
                name='startsAt'
                error={errors && errors.startsAt}
                control={control}
                rules={{ validate: (startsAtVal) => (!isValidTime(moment(startsAtVal), moment(watch('endsAt'))) ? t('convos.validTime') : undefined) }}
                defaultValue={conversation && moment.tz(conversation.startsAt, currentTimezone)}
                onChange={(val: any) => {
                  if (!val) return
                  trigger('endsAt')
                  setValue('startsAt', val, { shouldDirty: true })
                }}
              />

              <div className='label reg-top'>{t('common.endTime')}</div>
              <TimeControl
                name='endsAt'
                control={control}
                defaultValue={conversation && moment.tz(conversation.endsAt, currentTimezone)}
                rules={{ validate: (endsAtVal) => (!isValidTime(moment(watch('startsAt')), moment(endsAtVal)) ? t('convos.validTime') : undefined) }}
                error={errors && errors.endsAt}
                onChange={(val: any) => {
                  if (!val) return
                  trigger('startsAt')
                  setValue('endsAt', val, { shouldDirty: true })
                }}
              />

              <SelectControl
                name='timezone'
                label={t('common.timeZone')}
                className='reg-top'
                control={control}
                defaultValue={conversation && conversation.timezone}
                error={errors && errors.timezone}
                options={moment.tz.names().map((value) => ({ label: value, value }))}
              />
            </div>

            <Grid container justify='space-between' className='reg-bottom xtra-fat-top'>
              <Grid item>
                <Button disabled={!isDirty || isSubmitting || !isFormValid} type='submit' color='secondary' variant='contained'>
                  Save Changes
                </Button>
              </Grid>
              <Grid item>
                {conversation.canDelete() && (
                  <Button onClick={() => conversation.deleteConversation()} variant='contained' style={{ color: '#EE413E' }}>
                    Delete
                  </Button>
                )}
              </Grid>
            </Grid>
          </form>
          <Prompt when={isDirty || isSubmitting} message={UNSAVED_FORM_WARNING} />
        </FormProvider>
      </MuiPickersUtilsProvider>
    </div>
  )
})

// export default inject<FIXME, FIXME>(
//   ({
//     mst: {
//       auth: { user },
//       dialogs: { showDialog }
//     }
//   }: {
//     mst: IStore
//   }) => {
//     return { user, showDialog }
//   }
// )(observer(withTranslation(ConversationForm)))
export default ConversationForm
