import React from 'react'
import { isEmpty, assign } from 'lodash'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { Button, ThemeProvider } from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import { IStore } from '../../store/models'
import { CocoThemeProvider, DefaultTheme, DefaultThemeProvider } from '../../themes'

const ButtonIfLoggedIn = ({
  label,
  linkTo,
  onClick,
  user,
  showDialog,
  replace,
  authSuccess,
  hosting,
  className,
  history,
  location,
  color = window.coco ? undefined : 'primary',
  // redirectOnSuccess,
  staticContext,
  ...custom
}: FIXME) => {
  if (isEmpty(label)) throw Error('ButtonIfLoggedIn expects a label')
  // if (isEmpty(linkTo) && isEmpty(onClick) && isEmpty(redirectOnSuccess)) console.warn('note ButtonIfLoggedIn being called without redirectOnSuccess, linkTo or onClick')

  const newProps = assign(
    {},
    {
      label,
      fullWidth: true,
      color,
      className: className || 'btn-xl'
    },
    custom
  )

  if (onClick) {
    return (
      <DefaultThemeProvider>
        <Button variant='contained' {...newProps} onClick={onClick} color={color}>
          {newProps.label}
        </Button>
      </DefaultThemeProvider>
    )
  }

  return (
    <DefaultThemeProvider>
      <Link to={linkTo}>
        <Button variant='contained' {...newProps} color={color}>
          {newProps.label}
        </Button>
      </Link>
    </DefaultThemeProvider>
  )
}

export default inject<FIXME, FIXME>(
  ({
    mst: {
      auth: { user },
      dialogs: { showDialog }
    }
  }: {
    mst: IStore
  }) => {
    return {
      user,
      showDialog
    }
  }
)(observer(withRouter(ButtonIfLoggedIn)))
