import queryString from 'query-string'
import { get } from '../utils'

class TopicConversationsApi {
  static getAll({ slug, coordinates, ...args }) {
    if (coordinates) params += `&coordinates[]=${coordinates.latitude}&coordinates[]=${coordinates.longitude}`
    const defaultParams = { page: 0, site_name: global.siteName }
    const params = queryString.stringify({ ...defaultParams, ...args })
    return get(`/topics/${slug}/conversations?${params}`)
  }
}

export default TopicConversationsApi
