import { get, put, post } from './utils'
import queryString from 'query-string'

class TracksApi {
  getBySlug(slug) {
    return get(`/tracks/${slug}`)
  }
  get(id) {
    return get(`/tracks/${id}`)
  }
  getByPartnerId(partnerId) {
    return get(`/partners/${partnerId}/tracks/`)
  }
  getAll() {
    return get('/tracks')
  }
  joinMembership(token: string, joinConversation?: string) {
    return put('/tracks/join', { body: JSON.stringify({ token, joinConversation }) })
  }
  addConversation(partnerId: FIXME, trackId: FIXME, conversationId: FIXME) {
    return put(`/partners/${partnerId}/tracks/${trackId}/add_conversation`, { body: JSON.stringify({ conversationId }) })
  }
  addMembership(track_id: number, data: FIXME) {
    return post('/memberships', { body: JSON.stringify({ ...data, track_id, deal_name: 'NA', hubspot_deal_id: 'NA' }) })
  }
  removeConversation(partnerId: FIXME, trackId: FIXME, conversationId: FIXME) {
    return put(`/partners/${partnerId}/tracks/${trackId}/remove_conversation`, { body: JSON.stringify({ conversationId }) })
  }
  loadHsMembership() {
    return get('/tracks/load_membership')
  }
  getAllMembershipsTable(args) {
    const defaultParams = { page: 0, site_name: window.siteName }
    const params = queryString.stringify({ ...defaultParams, ...args })

    return get(`/admin/memberships?${params}`)
  }
}

export default new TracksApi()
