import { types, Instance } from 'mobx-state-tree'
import UserInvitationRequestsApi from '../../../api/users/UserInviationRequestsApi'
import { LoadableStatus, Alertable } from '../helpers'
import { UserBase } from './UserBase'

export const UserInvitationRequest = types.model('UserInvitationRequest', {
  accepted: types.maybeNull(types.boolean),
  acceptedAt: types.maybeNull(types.string),
  topicId: types.maybeNull(types.number),
  createdAt: types.maybeNull(types.string),
  denied: types.maybeNull(types.boolean),
  deniedAt: types.maybeNull(types.string),
  conversationId: types.maybeNull(types.number),
  general: types.maybeNull(types.boolean),
  id: types.identifierNumber,
  message: types.maybeNull(types.string),
  pending: types.maybeNull(types.boolean),
  specific: types.maybeNull(types.boolean),
  updatedAt: types.maybeNull(types.string),
  userId: types.maybeNull(types.number)
})

export const UserInvitationRequests = types
  .compose(
    'UserInvitationRequests',
    Alertable,
    UserBase,
    LoadableStatus,
    types.model({
      userInvitationRequests: types.array(UserInvitationRequest)
    })
  )
  .actions((self) => ({
    setUserInvitationRequests: (val) => {
      self.userInvitationRequests.replace(val)
    }
  }))
  .actions((self) => ({
    loadUserInvitationRequests: ({ reload = false } = {}) => {
      const listName = 'userInvitationRequests'
      if (self.isLoading(listName) || (!reload && self.isLoaded(listName))) return

      self.startLoading(listName)
      return UserInvitationRequestsApi.getAll(self.id).then(({ response: { ok, /*status, */ statusText }, json }) => {
        if (ok) {
          self.setUserInvitationRequests(json)
        } else {
          self.showAlert(statusText)
        }
        self.stopLoading(listName)
      })
    }
  }))

export interface IUserInvitationRequest extends Instance<typeof UserInvitationRequest> {}
export interface IUserInvitationRequests extends Instance<typeof UserInvitationRequests> {}
export default UserInvitationRequests
