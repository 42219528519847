import { Grid, IconButton, Tooltip } from '@material-ui/core'
import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import appAnalytics from '../../../../analytics'
import { IPartner } from '../../../../store/models/Partner'
import { Link } from 'react-router-dom'
import { IOrganization } from '../../../../store/models/Organization/Organization'
import { observer } from 'mobx-react'
import { flow } from 'lodash'
interface Props {
  partner: IPartner
  itemAction?(partner: IPartner): any
}

const TopicActions = ({ partner, itemAction }: Props & RouteComponentProps) => {
  return (
    <Grid container direction='row' justify='space-around' alignItems='center'>
      {itemAction ? (
        itemAction(partner)
      ) : (
        <Tooltip title='Edit'>
          <Link to={`/admin/partners/${partner.slug}/edit`}>
            <IconButton size='small'>
              <i className='fas fa-pen opacity-8'></i>
            </IconButton>
          </Link>
        </Tooltip>
      )}
    </Grid>
  )
}

export default flow(observer, withRouter)(TopicActions)
