import React, { useState } from 'react'
import { Link, withRouter, RouteComponentProps } from 'react-router-dom'

import { flow } from 'lodash'
import { inject, observer } from 'mobx-react'
import { IStore, ITopic } from '../../../../store/models'
import DataTable, { IColData, ValueLabel } from '../../../shared/DataTableFilterable'
import { IUser } from '../../../../store/models/User'
import { IFilterable } from '../../../../store/models/helpers/Filterable'
import { Button, Grid } from '@material-ui/core'

import TopicSubscribersActions from '../partials/TopicSubscribersActions'
import { ITopicSubscriber, ISurveyAnswer } from '../../../../store/models/TopicSubscribers'

interface IProps {
  topic: ITopic
}
const TopicSubscribersTable = (props: InjectedProps & IProps & RouteComponentProps) => {
  const { user, filterable, items, loading, topic, history, questions = [] } = props
  const [exported, setExported] = useState(false)
  const [exporting, setExporting] = useState(false)
  const columnData: IColData[] = [
    // { name: 'id', title: 'Sub ID', width: 110, sortable: true },
    { name: 'id', title: 'User ID', width: 110, sortable: true },
    { name: 'firstName', title: 'First Name', width: 150, sortable: true },
    { name: 'lastName', title: 'Last Name', width: 150, sortable: true },
    ...(user?.isPartnerAdmin() ? [{ name: 'profile', title: 'Profile', width: 150 }] : []),
    { name: 'email', title: 'Email', width: 250, sortable: true },
    { name: 'city', title: 'City', width: 150, sortable: true },
    { name: 'stateLevel', title: 'State', width: 150, sortable: true },
    { name: 'country', title: 'Country', width: 220, sortable: true },
    ...questions.map((q, i) => ({ name: q.id.toString(), title: `Q${(parseInt(i, 10) + 1).toString()}`, width: 150 })),
    { name: 'score', title: 'Score', width: 220, align: 'center' },
    { name: 'actions', title: 'Actions', width: 220, align: 'center' }
  ]
  return (
    <div>
      <div className='header-24 bold fat-bottom fat-left'>
        <Grid container justify='space-between'>
          <Grid item>Subscribers</Grid>
          <Grid item>
            <div className='reg-right-padding'>
              <Button
                variant='contained'
                disabled={exporting}
                startIcon={<i className='fal fa-file-export' />}
                onClick={() => {
                  setExporting(true)
                  topic.createTopicSubscribeCsvExport(topic.id).then(() => {
                    setExported(true)
                    setExporting(false)
                  })
                }}
              >
                Export Data to CSV
              </Button>
              {exported && (
                <Button
                  className='reg-left'
                  startIcon={<i className='fas fa-cloud-download-alt' />}
                  onClick={() => {
                    history.push('/admin/analytics/downloads')
                  }}
                >
                  My Downloads
                </Button>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
      <DataTable
        data={items}
        columns={columnData}
        loading={loading}
        filterable={filterable}
        rightColumns={['actions']}
        height={'calc(100vh - 31em)'}
        searchPlaceholder='Search (First Name, Last Name, Email, City, State)'
        getCellValue={(row: ITopicSubscriber, columnName: string) => {
          const surveyAnswer = row.surveyAnswers.filter((a) => a.surveyQuestionId === parseInt(columnName, 10))[0]
          const answer = surveyAnswer?.answer || 'n/a'
          const score = row.surveyAnswers.reduce((acc, a: ISurveyAnswer | null) => acc + (a?.value || 0), 0)
          switch (columnName) {
            case 'score':
              return <ValueLabel value={score} />
            case 'profile':
              return (
                <ValueLabel
                  value={
                    <Link rel='noopener' title='User Profile' to={`/users/${row.userId}`} target='blank' color='primary'>
                      {`/users/${row.id}`}
                    </Link>
                  }
                />
              )
            case columnName.match(/^[0-999999]+$/)?.input:
              return <ValueLabel title={surveyAnswer?.question || 'n/a'} value={answer} />
            case 'actions':
              return <TopicSubscribersActions subscriber={row} />
            default:
              return <ValueLabel value={row[columnName]} />
          }
        }}
      />
    </div>
  )
}

interface InjectedProps {
  filterable: IFilterable
  items: ITopicSubscriber[]
  loading: boolean
  user?: IUser
  questions?: any
  surveyQuestionsLoading: boolean
}

export default flow(
  observer,
  inject<InjectedProps, IProps>(
    (
      {
        mst: {
          auth: { user },
          topic_subscribers,
          topics
        }
      }: {
        mst: IStore
      },
      ownProps
    ) => {
      const { topic } = ownProps
      topic && topic.shouldLoad('surveyQuestions', topic.loadSurveyQuestions)
      return {
        user,
        filterable: topic_subscribers,
        items: topic_subscribers.list,
        loading: topic_subscribers.loadingIds.includes('users_table'),
        questions: topic.surveyQuestions,
        surveyQuestionsLoading: topics.loadingIds.includes('surveyQuestions')
      }
    }
  ),
  withRouter
)(TopicSubscribersTable)
