import { createStore, applyMiddleware, compose } from 'redux'
import { createLogger } from 'redux-logger'
import thunkMiddleware from 'redux-thunk'
import { routerMiddleware } from 'connected-react-router'
import history from '../components/browserHistory'
import createRootReducer from '../reducers'

const SHOW_REDUX_LOG = false

const loggerMiddleware = createLogger()

const composeEnhancers = process.env.NODE_ENV === 'production' ? compose : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const configureStore = (preloadedState) =>
  createStore(
    createRootReducer(history),
    preloadedState,
    composeEnhancers(applyMiddleware(routerMiddleware(history), thunkMiddleware, ...(SHOW_REDUX_LOG ? [loggerMiddleware] : [])))
  )

export default configureStore
