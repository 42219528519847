import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Container, createStyles, Grid, makeStyles, Paper, Theme, Typography } from '@material-ui/core'
import { observer } from 'mobx-react'
import { IMembershipUser, IMembershipUserBase } from '../../../../store/models/User/MembershipUser'
import { ITrack } from '../../../../store'
import { get } from '../../../../api/utils'
import { useParams } from 'react-router'
import DataTable, { IColData, ValueLabel } from '../../../shared/DataTableFilterable'
import LoadingAnimation from '../../../shared/LoadingAnimation'
import CommunityTable from '../CommunityTable'
import SummaryStats from '../../../admin/v2/partials/SummaryStats'

interface Props {
  membership: IMembershipUser
  track: ITrack
}

interface LeadershipProps {
  details: {
    total_active_members?: string
    attended_4_or_more_sessions?: string
    taken_4_or_more_actions?: string
  }
  details_by_conversation: {
    conversation?: string
    registrations?: number
    attendees?: number
    attendance?: number
  }[]
}

export const Leaderboard = observer(({ membership, track }: Props) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<LeadershipProps>({ details: {}, details_by_conversation: [] })
  const { action, membership_id } = useParams<{ action: string; membership_id?: string }>()

  useEffect(() => {
    setLoading(true)
    get(`/memberships/${membership_id}/leaderboard`).then(({ json, response: { ok } }) => {
      if (ok) {
        setData(json)
      }
      setLoading(false)
    })
  }, [membership_id])

  if (loading) {
    return <LoadingAnimation />
  }

  return (
    <div style={{ marginTop: '1rem' }}>
      <div>
        <Typography variant='h6' className='reg-bottom reg-top'>
          Leaderboard Dashboard
        </Typography>
        <Grid container direction='column' alignItems='flex-start' spacing={3}>
          <Grid item className={classes.grid}>
            <LeadershipDetail membership={membership} track={track} data={data} />
          </Grid>
        </Grid>
      </div>
      <div>
        <Typography variant='h6' className='reg-bottom reg-top'>
          Member Activity
        </Typography>
        <Paper className={classes.section}>
          <CommunityTable membership={membership} track={track} hideConnection={true} />
        </Paper>
      </div>
    </div>
  )
})
export default Leaderboard

const AnalyticValue = ({ name, value }) => {
  const classes = useStyles()
  return (
    <Grid item xs={12} sm={4}>
      <Paper variant='outlined' className={classes.dataPaper}>
        <Typography variant='subtitle1'>{name}</Typography>
        <Typography variant='h2'>{value}</Typography>
      </Paper>
    </Grid>
  )
}

const LeadershipDetail = ({ membership, track, data }: Props & { data: LeadershipProps }) => {
  const classes = useStyles()
  return (
    <Container className={classes.section}>
      <div className='reg-left reg-bottom-padding'>
        <SummaryStats
          bottomDivider={false}
          data={[
            { title: 'Total Active Members', value: data.details.total_active_members },
            { title: 'Attended 4 or more sessions', value: data.details.attended_4_or_more_sessions },
            { title: 'Taken 4 or more Actions', value: data.details.taken_4_or_more_actions }
          ]}
        />
      </div>
    </Container>
  )
}

const LeadershipDetailByConversation = ({ membership, track, data }: Props & { data: LeadershipProps }) => {
  const classes = useStyles()
  const columnData: IColData[] = [
    { name: 'conversation', title: 'Conversation' },
    { name: 'registrations', title: 'Registrations', width: 130, align: 'right' },
    { name: 'attendees', title: 'Attendees', width: 130, align: 'right' },
    { name: 'attendance', title: 'Attendance', width: 130, align: 'right' }
  ]
  const totalReg = data.details_by_conversation.reduce((tot, val) => {
    return val.registrations ? tot + val.registrations : tot
  }, 0)
  const totalAtten = data.details_by_conversation.reduce((tot, val) => {
    return val.attendees ? tot + val.attendees : tot
  }, 0)
  return (
    <Container className={classes.section}>
      <Grid container justify='flex-end' alignItems='flex-end' alignContent='flex-end' className='reg-top'>
        <Grid item style={{ width: 'calc(100% - 450px)', textAlign: 'left' }}>
          Total
        </Grid>
        <Grid item style={{ width: 140, textAlign: 'right' }}>
          {totalReg}
        </Grid>
        <Grid item style={{ width: 140, textAlign: 'right' }}>
          {totalAtten}
        </Grid>
        <Grid item style={{ width: 140, textAlign: 'right' }}>
          {((totalAtten / totalReg) * 100.0).toFixed(1)}%
        </Grid>
      </Grid>
      {/* <div className='reg-left reg-bottom-padding'> */}
      <DataTable
        data={data.details_by_conversation}
        columns={columnData}
        loading={false}
        filterable={membership}
        rightColumns={['actions']}
        // height={'calc(100vh - 46.5em)'}
        // height={'20em'}
        noDataMsg='None'
        hidePaging
        hideSearch
        getCellValue={(row: any, columnName: string) => {
          const row_user = row.user
          switch (columnName) {
            case 'attendance':
              return <ValueLabel value={`${row.attendance.toFixed(1)}%`} />

            default:
              return <ValueLabel value={row[columnName]} />
          }
        }}
      />
      {/* </div> */}
    </Container>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      // backgroundColor: '#00ff00',
      width: '100%'
    },
    dataPaper: {
      padding: theme.spacing(2),
      borderRadius: '16px',
      maxWidth: '10rem'
      // minHeight: '6rem'
    },
    section: {
      // marginTop: '2rem',
      paddingTop: '.25rem'
      // minHeight: '10rem'
      // backgroundColor: '#ff0000',
      // width: '100%'
    }
  })
)
