import { createSelector, createStructuredSelector } from 'reselect'

export const getPasswordResets = (state) => state.passwordResets
export const getUsePasswordResetToken = (state, props) => props.match.params.token

export const getPasswordResetsCreateMessage = createSelector(getPasswordResets, (passwordResets) => passwordResets.createMessage)
export const getPasswordResetsUseMessage = createSelector(getPasswordResets, (passwordResets) => passwordResets.useMessage)

export const getCreatePasswordResetProps = createStructuredSelector({
  message: getPasswordResetsCreateMessage
})

export const getUsePasswordResetProps = createStructuredSelector({
  token: getUsePasswordResetToken,
  message: getPasswordResetsUseMessage
})
