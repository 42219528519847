import React, { useEffect, useState } from 'react'
import { Button, Card, CircularProgress, FormControlLabel, InputLabel, Switch } from '@material-ui/core'

import withTranslation from '../hocs/withTranslation'
import { withRouter } from 'react-router'
import { useForm } from 'react-hook-form'
import { getSnapshot, isStateTreeNode } from 'mobx-state-tree'
import { SwitchControl, WysiwygControl } from '../utils'
import { observer } from 'mobx-react'
import { flow, sortBy } from 'lodash'
import { ITopic } from '../../store'
interface Props {
  topic: ITopic
  notification: any
  t: any
  onSave: any
  dynamicTags: true
}

const TopicNotificationsContentForm = (props: Props) => {
  const { handleSubmit, errors, formState, control, watch, reset } = useForm({ mode: 'onBlur' })
  const { isDirty, isSubmitting } = formState
  const { notification, topic, t, onSave, dynamicTags } = props
  const [inProgress, setInProgress] = useState(false)

  useEffect(() => {
    notification?.setDirty(isDirty)
  }, [notification, isDirty])

  const handleSave = handleSubmit((data: any, e) => {
    const updateData = { ...(isStateTreeNode(notification) ? getSnapshot(notification) : notification || {}), ...data }

    notification?.updateTopicNotification(updateData).then(({ data: updatedData }) => {
      reset(updatedData, {
        errors: false, // errors will not be reset
        dirtyFields: false, // dirtyFields will not be reset
        isDirty: false, // dirty will not be reset
        isSubmitted: true,
        touched: false,
        isValid: true,
        submitCount: true
      })
      onSave && onSave()
    })
  })

  const currentActiveState = watch('active') !== undefined ? watch('active') : notification?.active

  return (
    <Card key={notification.id} className='huge-bottom no-shadow'>
      <form onSubmit={handleSave}>
        <div className='row no-gutters fat-top'>
          <WysiwygControl name='content' dynamicTags={false} control={control} defaultValue={notification?.content || '<p></p>'} error={errors && errors.content} />
        </div>

        <div className='row no-gutters reg-top thin-left'>
          <div className='col-xs-4 no-gutters thin-top'>
            <FormControlLabel
              disabled={inProgress}
              control={
                <Switch
                  checked={notification?.active}
                  onChange={(e) =>
                    notification?.updateTopicNotification(
                      { active: e.currentTarget.checked },
                      { dirty: notification.dirty, alert: `Email ${e.currentTarget.checked ? 'activated' : 'disabled'}` }
                    )
                  }
                />
              }
              label={
                inProgress ? (
                  <CircularProgress size='small' />
                ) : (
                  <InputLabel>{currentActiveState ? t('convoNotifications.activated') : t('convoNotifications.notActivated')}</InputLabel>
                )
              }
            />
          </div>

          <div className='col-xs-8 no-gutters end-xs'>
            <Button
              onClick={() =>
                topic.sendTestEmail({
                  name: notification.name,
                  testSubject: 'testSubject',
                  testContent: 'testContent'
                })
              }
              variant='text'
            >
              {t('convoNotifications.sendTestEmail')}
            </Button>
            <Button disabled={!isDirty || isSubmitting} type='submit' className='reg-left' color='secondary' variant='contained'>
              {t('common.save')}
            </Button>
          </div>
        </div>
      </form>
    </Card>
  )
}
export default flow(observer, withTranslation, withRouter)(TopicNotificationsContentForm)
