import React, { useEffect, useState } from 'react'
import { flow, startCase } from 'lodash'

import Dialog from '../../../DialogWrapper'
import { inject, observer } from 'mobx-react'
import { Button, Chip, Grid, IconButton, Paper, Tooltip, Link as MuiLink, makeStyles, createStyles, Theme } from '@material-ui/core'
import DataTable, { IColData, ValueLabel } from '../../../shared/DataTableFilterable'
import { Link, withRouter, RouteComponentProps } from 'react-router-dom'
import moment from 'moment'
import { IConversation, IFilterable, IStore, ITrack, IUser } from '../../../../store'
import { Label } from '@material-ui/icons'
import { useForm } from 'react-hook-form'
import { MapRegionControl, TextFieldControl, SelectControl, WysiwygControl, localOptions, FileButtonControl, SwitchControl, DateTimeControl } from '../../../utils'
import * as validations from '../../../validations/fieldLevelValidations'
import OwnerAssign from './OwnerAssign'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      backgroundColor: '#C4E6D8',
      color: '#212121'
    }
  })
)

const AddMembershipDialog = ({ track, loading, items, filterable }: Props & InjectedProps & RouteComponentProps) => {
  const { handleSubmit, errors, formState, control, watch, setError, reset, clearErrors, setValue } = useForm({ mode: 'onBlur' })
  const { isDirty, isSubmitting, dirtyFields, isValid } = formState
  const classes = useStyles()

  const [open, setopen] = useState(false)

  const handleSave = handleSubmit((data: any, e) => {
    track.createMembership(data).then(() => {
      setopen(false)
    })
    // membership?.updateMembership({ ...data }).then(() => {
    // onClose && onClose()
    // })
  })

  // useEffect(() => {
  //   if (track && track.partnerId) {
  //     filterable.setPartnerId(track.partnerId)
  //   }
  //   return () => {
  //     filterable.setPartnerId(undefined)
  //   }
  // }, [filterable, track])

  const onClose = () => setopen(false)

  return (
    <>
      <Button variant='contained' color='primary' size='small' startIcon={<i className='fas fa-plus'></i>} onClick={() => setopen(true)}>
        Add Membership
      </Button>
      <Dialog dialog={{ closeDialog: () => setopen(false) }} open={open} name='addMembershipDialog' maxWidth='md' title={'Manage Membership Subscription'}>
        <section className='pane big-top-padding big-bottom-padding'>
          <div className='row reg-bottom'>
            <div className='col-xs'>
              <Paper elevation={0}>
                <form onSubmit={handleSave}>
                  <TextFieldControl autoFocus name='companyName' control={control} error={errors && errors.companyName} label={'Company Name'} className='reg-bottom' />

                  <TextFieldControl
                    name='seatLimit'
                    control={control}
                    defaultValue={1}
                    error={errors && errors.seatLimit}
                    label={'Seat Limit'}
                    rules={{ validate: { min: validations.minNumber(1) } }}
                    // className='reg-bottom'
                    type='number'
                  />

                  <DateTimeControl
                    name='startsAt'
                    control={control}
                    defaultValue={moment()}
                    error={errors && errors.startsAt}
                    label={'Start date'}
                    className='reg-bottom'
                    dateProps={{ timeFormat: false, closeOnSelect: true }}
                  />
                  <div className='reg-bottom'>
                    <DateTimeControl
                      name='endsAt'
                      control={control}
                      defaultValue={moment().add(1, 'year')}
                      error={errors && errors.endsAt}
                      label={'End date'}
                      dateProps={{ timeFormat: false, closeOnSelect: true }}
                    />
                  </div>

                  <div className='reg-bottom'>
                    <TextFieldControl
                      name='amount'
                      defaultValue={'0'}
                      control={control}
                      error={errors && errors.amount}
                      label={'Amount'}
                      rules={{ validate: { min: validations.minNumber(0) } }}
                      type='number'
                    />
                  </div>
                  <div className='reg-bottom'>
                    <DateTimeControl
                      name='dealClosedAt'
                      control={control}
                      defaultValue={moment()}
                      error={errors && errors.dealClosedAt}
                      label={'Deal Closed'}
                      dateProps={{ timeFormat: false, closeOnSelect: true }}
                    />
                  </div>

                  <div className='reg-bottom'>
                    <SelectControl
                      name='active'
                      label='Status'
                      control={control}
                      error={errors && errors.active}
                      defaultValue='true'
                      className='reg-bottom'
                      required
                      rules={{ validate: validations.required }}
                      options={[
                        { value: 'true', label: 'Active' },
                        { value: 'false', label: 'Inactive' }
                      ]}
                    />
                  </div>
                  <div className='reg-bottom'>
                    <TextFieldControl name='owner' control={control} error={errors && errors.owner} type='hidden' style={{ display: 'none' }} />
                    <OwnerAssign onSelect={(u) => u?.id && setValue('owner', u.id)} />
                  </div>
                  <div className='giant-top'>
                    <Button variant='contained' className='reg-right' onClick={() => onClose && onClose()}>
                      Cancel
                    </Button>

                    <Button disabled={!isDirty || isSubmitting || !isValid || !watch('owner')} type='submit' color='primary' variant='contained'>
                      {'Update'}
                    </Button>
                  </div>
                </form>
              </Paper>
            </div>
          </div>
        </section>
      </Dialog>
    </>
  )
}

interface Props {
  track: ITrack
}
interface InjectedProps {
  items: IConversation[]
  loading: boolean
  filterable: IFilterable
  user?: IUser
}

export default flow(
  observer,
  inject<InjectedProps, Props>(({ mst: { conversations, auth } }: { mst: IStore }) => {
    return {
      user: auth.user,
      filterable: conversations,
      items: conversations.list,
      loading: conversations.loadingIds.includes('conversations_table')
    }
  }),
  withRouter
)(AddMembershipDialog)
