import React from 'react'
import { withRouter } from 'react-router'
import TopicForm from './Form'

const NewTopicPage = (props) => {
  const {
    copy,
    submit,
    onDirty,
    history,
    onSubmit,
    location: { state: { from = '/admin' } = {} }
  } = props
  return (
    <div>
      <div className='row'>
        <div className='col-xs-12 form-xl'>
          <TopicForm
            page='new'
            copy={copy}
            submit={submit}
            onDirty={onDirty}
            onSave={(data) => {
              history.push(`/admin/topics/${data.slug}/edit`)
            }}
            onSubmit={onSubmit}
          />
        </div>
      </div>
    </div>
  )
}

export default withRouter(NewTopicPage)
