import React, { useState, useEffect } from 'react'
import { DialogTitle } from '@material-ui/core'
import { flow } from 'lodash'
import FontIcon from '@material-ui/core/Icon'
import RsvpQuestion from '../../topics/partials/RsvpQuestion'
import withTranslation, { WithTranslation } from '../../hocs/withTranslation'
import Dialog from '../../DialogWrapper'
import { observer } from 'mobx-react'
import { IStore } from '../../../store'
import { IUser } from '../../../store/models/User'
import { inject } from 'mobx-react'
import { IDialog } from '../../../store/models/Dialog'

interface Props {
  dialog: IDialog
  onDone?: any
  onCancel?: any
}
interface InjectedProps {
  user: IUser
}
type Answer = {
  surveyQuestionId: number
  answer: string
  topicId: number
  value?: number
}

const SurveyQuestionDialog = ({ user, dialog, onDone, t }: Props & InjectedProps & WithTranslation) => {
  const [stepIndex, setStepIndex] = useState(0)
  const [completed, setCompleted] = useState(false)
  const [answers, setAnswers] = useState<Answer[]>([])
  const { data } = dialog
  // console.log({ dialog })
  const { questions, conversation, nextAction, topic } = data as any
  const questionCount = questions.length
  const currentQuestion = questions[stepIndex]

  const handleNext = (values) => {
    if (values.answer) {
      const answer = {
        surveyQuestionId: currentQuestion.id,
        answer: values.answer,
        value: values.value,
        topicId: currentQuestion.topicId,
        conversationId: conversation.id
      }
      setAnswers([...answers, answer])
    }

    if (stepIndex < questionCount - 1) {
      setStepIndex((prevStep) => prevStep + 1)
    } else {
      setCompleted(true)
      setStepIndex(0)
    }
  }

  const handleBack = () => {
    if (stepIndex > 0) {
      setStepIndex((prevStep) => prevStep - 1)
    }
  }

  useEffect(() => {
    if (completed) {
      dialog.closeDialog()
      setCompleted(false)
      topic?.saveSurveyAnswers(answers).then(() => nextAction && nextAction())
    }
  }, [completed, nextAction, answers, topic, dialog])

  return (
    <Dialog dialog={dialog} name='surveyQuestion' type='thin'>
      <DialogTitle disableTypography>
        {t('conversation.questionNumber', {
          stepIndex: stepIndex + 1,
          questionCount
        })}
      </DialogTitle>

      <section className='pane thin-reg-pane'>
        <div className='row reg-bottom'>
          <div className='col-xs-12 center-xs'>
            <div className='box'>
              <FontIcon className='fal fa-question-circle header-48' />
            </div>
          </div>
        </div>
        <RsvpQuestion question={currentQuestion} handleNext={handleNext} handleBack={handleBack} onCancel={dialog.closeDialog} />
      </section>
    </Dialog>
  )
}

export default flow(
  observer,
  inject<FIXME, FIXME>(
    ({
      mst: {
        auth: { user }
      }
    }: {
      mst: IStore
    }) => {
      return {
        user
      }
    }
  ),

  withTranslation
)(SurveyQuestionDialog)
