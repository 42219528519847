import appAnalytics from '../analytics'
import { post, put } from './utils'

class ConversationInvitationsApi {
  static create(values) {
    return post(`/conversation_invitations`, {
      body: JSON.stringify(values)
    }).then((d) => {
      appAnalytics.event({
        category: 'Conversation',
        action: 'Invited'
      })
      return d
    })
  }

  static redeemApprovalToken(token) {
    return put(`/conversation_invitations/redeem_approval_token`, {
      body: JSON.stringify(token)
    }).then((d) => {
      appAnalytics.event({
        category: 'Conversation',
        action: 'Accept Invitation'
      })
      return d
    })
  }

  static verifyApprovalToken(token) {
    return put(`/conversation_invitations/verify_approval_token`, {
      body: JSON.stringify(token)
    }).then((d) => {
      appAnalytics.event({
        category: 'Conversation',
        action: 'Verify Invitation'
      })
      return d
    })
  }
}

export default ConversationInvitationsApi
