import React from 'react'

import { flow } from 'lodash'

import { observer } from 'mobx-react'
import { Grid, makeStyles, Theme, createStyles, Container, Hidden } from '@material-ui/core'
import Form from './Form'
import ParamTopicProvider from '../Providers/ParamTopicProvider'
import { withRouter, RouteComponentProps } from 'react-router'
import ParamConversationProvider from '../Providers/ParamConversationProvider'

const StoryCollection = (props: RouteComponentProps) => {
  const classes = Styles()
  return (
    <ParamTopicProvider>
      <ParamConversationProvider>
        <div className={classes.root}>
          <div className={classes.main}>
            <Grid container>
              <Grid item sm={12} md={6} className={classes.left}>
                <Container>
                  <Form />
                </Container>
              </Grid>
              <Grid item sm={12} md={6} className={classes.right}>
                <Grid container justify='space-between' spacing={4}>
                  <Grid item sm={4} md={12}>
                    <img className={`img-responsive ${classes.image}`} src={'https://civicdinners-manual-storage.s3.amazonaws.com/story/stories-fb-rounded.png'} />
                  </Grid>
                  <Hidden xsDown>
                    <Grid item sm={4} md={12}>
                      <img className={`img-responsive ${classes.image}`} src={'https://civicdinners-manual-storage.s3.amazonaws.com/story/stories-screenshot-rounded.png'} />
                    </Grid>
                    <Grid item sm={4} md={12}>
                      <img className={`img-responsive ${classes.image}`} src={'https://civicdinners-manual-storage.s3.amazonaws.com/story/stories-business-rounded.png'} />
                    </Grid>
                  </Hidden>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </ParamConversationProvider>
    </ParamTopicProvider>
  )
}
const Styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '0 0',
      width: '100%',
      backgroundColor: '#F6F7F9'
    },
    main: {
      margin: '0 auto',
      maxWidth: '1200px'
    },
    left: {
      margin: '0 auto'
    },
    right: {
      margin: '3.4em 0',
      padding: '0 2em'
    },
    image: {
      // borderRadius: '1.3em'
    }
  })
)
export default flow(observer, withRouter)(StoryCollection)
