import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { Button, createStyles, Divider, makeStyles, Theme, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { AuthContext, ParamTopicContext } from '../../../Providers'
import { StoriesContext } from '../../../StoryCollection/contexts'
import { StoryList } from '../../../StoryCollection/StoryList'
import { DefaultThemeProvider } from '../../../../themes'
import { useHistory, useParams } from 'react-router'
import { observer } from 'mobx-react'
import { sortBy } from 'lodash'

interface Props {}

export const Stories = observer(
  (props: Props): ReactElement => {
    const { topic } = useContext(ParamTopicContext)
    const { user } = useContext(AuthContext)

    const { t } = useTranslation()
    const classes = Styles()

    const [page, setpage] = useState(0)
    const pageSize = 3
    const history = useHistory()
    const { action, action_id } = useParams<{ action: string; action_id: string }>()
    const storiesRef = React.useRef<HTMLInputElement>(null)
    const active = action && action.toLocaleLowerCase() === 'stories'
    const topicStories = topic?.topicStories
    const story = active && action_id ? topicStories?.find((s) => s.id.toString() === action_id) : undefined

    useEffect(() => {
      if (topic) {
        topic.shouldLoad('topicStories', topic.loadTopicStories)
        topic.loadMyConversations()
      }
    }, [topic])

    useEffect(() => {
      if (topic && active && action_id && topicStories) {
        story?.show()
      }
    }, [story, action_id, topic, active, topicStories])

    useEffect(() => {
      if (storiesRef && active) {
        storiesRef?.current?.scrollIntoView()
      }
    }, [storiesRef, active])

    if (!topic || !topic.storiesEnabled) return <></>
    const attendedConvo = topic.attendedTopic()
    const hostedConversation = topic.getAllPastConversations()?.find((c) => c.topicId === topic.id)
    const canAdd = hostedConversation || (user?.loadedIds.includes('userConversations') && attendedConvo?.pastConversation())
    if (!canAdd && topic?.topicStories.length === 0) return <></>

    return (
      <div className={classes.root} ref={storiesRef}>
        <DefaultThemeProvider>
          <section className='pane clear-pane xtra-fat-pane fat-pane-xs'>
            <div className={classes.section}>
              <Typography variant='h5' align='center'>
                {t('story.whatPeopleAreSayingAbout', { topic_title: topic?.title })}
              </Typography>
              <Typography variant='body2' align='center' style={{ maxWidth: '45em', margin: '1em auto' }}>
                {t('story.listSubheader')}
              </Typography>
              {canAdd && (
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => history.push(`/${topic?.slug}/conversations/${attendedConvo?.conversationId || hostedConversation?.id}/story`)}
                >
                  {t('story.shareYourStory')}
                </Button>
              )}
              <Divider className='secondary' />
              <StoriesContext.Provider
                value={{
                  stories:
                    sortBy(topic?.topicStories, (s) => s.createdAt)
                      .reverse()
                      .slice(0, page + pageSize) || []
                }}
              >
                <StoryList />
              </StoriesContext.Provider>
            </div>
            <div className={classes.loadMore}>
              {topic && topic.topicStories.length > page + pageSize && (
                <Button variant='contained' onClick={() => setpage(page + pageSize)}>
                  Load More
                </Button>
              )}
            </div>
          </section>
        </DefaultThemeProvider>
      </div>
    )
  }
)

const Styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // backgroundColor: '#F6F7F9'
    },
    section: {
      maxWidth: '50em',
      margin: '0 auto',
      textAlign: 'center'
    },
    loadMore: {
      margin: '1em auto',
      textAlign: 'center'
    }
  })
)
