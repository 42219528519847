import React, { useContext, useState } from 'react'
import Dialog from '../DialogWrapper'
import { flow, map, range, pick, pickBy, identity } from 'lodash'
import withTranslation, { WithTranslation } from '../hocs/withTranslation'
import { withRouter } from 'react-router'
import { IDialog } from '../../store/models/Dialog'
import { TextFieldControl, NpsControlGroup } from '../utils'
import { required } from '../validations/fieldLevelValidations'
import { useForm } from 'react-hook-form'
import { Button, createStyles, DialogActions, Grid, makeStyles, Typography } from '@material-ui/core'
import palette from '../../themes/palette'
import { inject, observer } from 'mobx-react'
import { IStore } from '../../store'
import * as validations from '../validations/fieldLevelValidations'

interface InjectedProps {
  saveNps(val): Promise<any>
  attendeeSurveyLink?: string
  showDialog(val: any, data: any)
}
interface Props {
  dialog: IDialog
  history: any
}

const NpsUserDialog = ({ saveNps, dialog, t, i18n, history, showDialog }: Props & InjectedProps & WithTranslation) => {
  const { handleSubmit, errors, formState, control } = useForm({ mode: 'onBlur', shouldUnregister: false })

  const handleSave = handleSubmit(({ first_name, last_name, email }: any, e) => {
    showDialog('nps', {
      ...dialog.data,
      first_name,
      last_name,
      email
    })
  })

  const classes = Styles()

  return (
    <Dialog dialog={dialog} name='npsUser' closeIcon={true}>
      <form onSubmit={handleSave}>
        <section className={classes.headerContainer}>
          <Typography variant='subtitle2'>Basic Info</Typography>
        </section>
        <section className={classes.actionContainer}>
          <Grid container spacing={1}>
            <Grid item container spacing={3}>
              <Grid item xs={6}>
                <TextFieldControl label='First Name' name='first_name' control={control} required />
              </Grid>
              <Grid item xs={6}>
                <TextFieldControl label='Last Name' name='last_name' control={control} required />
              </Grid>
            </Grid>

            <Grid item xs={12} className='fat-top'>
              <TextFieldControl
                label='Email'
                name='email'
                error={errors?.email}
                control={control}
                required
                rules={{ validate: { required: validations.required, email: validations.email } }}
              />
            </Grid>
          </Grid>
        </section>

        <DialogActions>
          <Button type='submit' variant='contained' color='primary'>
            Next
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

const Styles = makeStyles((theme) =>
  createStyles({
    root: {},
    headerContainer: {
      padding: '.5rem',
      textAlign: 'center'
    },
    label: {
      marginTop: '1rem'
    },
    bodyContainer: {
      textAlign: 'center',
      padding: '2rem .5rem'
    },
    nps: {
      padding: '1rem 1rem 0',
      position: 'relative'
    },
    npsKey1: {
      position: 'absolute',
      left: '2.4rem',
      bottom: '-1rem'
    },
    npsKey2: {
      position: 'absolute',
      right: '2.4rem',
      bottom: '-1rem'
    },
    actionContainer: { backgroundColor: palette.grey100, padding: '2rem 3rem' },
    submit: {},
    actionContainer2: { padding: '3rem', textAlign: 'center' },
    bodyContainer2: { backgroundColor: palette.grey100, textAlign: 'center', padding: '2rem 6rem' },
    imgContainer: { width: '56px', textAlign: 'center', margin: '0 auto 1rem' }
  })
)

export default flow(
  observer,
  inject<InjectedProps, null>(
    ({
      mst: {
        nps: { saveNps },
        dialogs: { showDialog }
      }
    }: {
      mst: IStore
    }) => {
      return {
        saveNps,
        showDialog
      }
    }
  ),
  withTranslation
)(NpsUserDialog)
