import React from 'react'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import { ILikable } from '../../../store/models/Auth/Likable'
import { Link } from 'react-router-dom'
import { palette } from '../../../themes'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: '36ch',
      backgroundColor: theme.palette.background.paper
    },
    inline: {
      display: 'inline'
    },
    divider: {
      backgroundColor: palette.grey300
    }
  })
)

export default function FavCard({ item }: { item: ILikable }) {
  const classes = useStyles()

  return (
    <>
      <Link to={item.url || '/'}>
        <ListItem alignItems='flex-start' button>
          <ListItemAvatar>
            <Avatar style={{ ...(item.themeColor ? { backgroundColor: item.themeColor as any } : {}) }} alt={item.name || ''} src={item.icon?.primary?.url || ''} />
          </ListItemAvatar>
          <ListItemText
            primary={item.name}
            secondary={
              <React.Fragment>
                {item.partnerName && (
                  <Typography component='span' variant='body2' className={classes.inline} color='textPrimary'>
                    {item.partnerName}
                  </Typography>
                )}
                {item.partnerName && item.cardBanner && ' - '}
                {item.cardBanner}
                {item.content && (
                  <>
                    <br />
                    <small>{item.content}</small>
                  </>
                )}
              </React.Fragment>
            }
          />
        </ListItem>
      </Link>
      <Divider variant='inset' classes={{ root: classes.divider }} />
    </>
  )
}
