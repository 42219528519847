import { SubmissionError } from 'redux-form'
import { get } from 'lodash'
import i18n from 'i18next'

import * as types from './actionTypes'
import usersApi from '../api/UsersApi'
import { showAlertMessage } from './alertMessageActions'
import { closeDialog } from './dialogActions'
import UnsubscribeApi from '../api/UnsubcribeApi'
import { logOutUser } from './sessionActions'
import { history } from '../components/browserHistory'
import mst from '../store/models'

export const Loading = () => ({
  type: types.USERS_LOADING
})

export const loadUsersSuccess = (json) => ({
  type: types.LOAD_USERS_SUCCESS,
  ...json
})

export const loadUsersFailed = (json) => ({
  type: types.LOAD_USERS_FAILED,
  data: json
})

export const loadUserSuccess = (json) => ({
  type: types.LOAD_USER_SUCCESS,
  data: json
})

export const updateUserFailed = (json) => ({
  type: types.UPDATE_USER_FAILED,
  data: json
})

export const updateUserSuccess = (json) => ({
  type: types.UPDATE_USER_SUCCESS,
  data: json
})

export const deleteUserSuccess = (id) => ({
  type: types.DELETE_USER_SUCCESS,
  data: id
})

export function loadUser(id) {
  return function (dispatch) {
    dispatch(Loading())

    return usersApi
      .get(id)
      .then((response) => {
        if (response.response.ok) {
          dispatch(loadUserSuccess(response.json))
        } else {
          dispatch(
            loadUsersFailed({
              status: response.response.status,
              statusText: response.response.statusText
            })
          )
        }
      })
      .catch((error) => {
        throw error
      })
  }
}

// For sign up form step 2
export function updateSignUpUser(formData) {
  return function (dispatch, getState) {
    return usersApi
      .update(formData)
      .then((response) => {
        if (response.response.ok) {
          dispatch(updateUserSuccess(response))
          // dispatch(showAlertMessage({ message: i18n.t('user.accountCreated') }))
          // dispatch(closeDialog("signupStepTwo"))
          mst.dialogs.closeDialog('signupStepTwo')
          mst.auth.reduxSetUser(response.json.user)
          mst.alertMessages.showAlert(i18n.t('user.accountCreated'))

          // HACK we want this to run because this action is called on Sign-Up Part II
          // TODO move this into a separate action that is also called by sessionActions.loginSuccess
          const onAuthSuccess = get(getState(), 'router.locationBeforeTransitions.state.authSuccess')
          const currentUser = response.json.user
          if (onAuthSuccess) dispatch(onAuthSuccess(currentUser.id))
        } else {
          dispatch(updateUserFailed(response))
          throw new SubmissionError(response)
        }
      })
      .catch((error) => {
        throw error
      })
  }
}

export function updateUser(formData) {
  return function (dispatch, getState) {
    return usersApi
      .update(formData)
      .then((response) => {
        if (response.response.ok) {
          dispatch(updateUserSuccess(response))
          dispatch(showAlertMessage({ message: i18n.t('user.accountUpdated') }))
          dispatch(closeDialog())

          // HACK we want this to run because this action is called on Sign-Up Part II
          // TODO might could remove this
          const onAuthSuccess = get(getState(), 'router.locationBeforeTransitions.state.authSuccess')
          const currentUser = response.json.user
          if (onAuthSuccess) dispatch(onAuthSuccess(currentUser.id))
        } else {
          dispatch(updateUserFailed(response))
          throw new SubmissionError(response)
        }
      })
      .catch((error) => {
        throw error
      })
  }
}

export function updateUserProfile(formData) {
  return function (dispatch, getState) {
    return usersApi
      .update(formData)
      .then((response) => {
        if (response.response.ok) {
          dispatch(updateUserSuccess(response))
          history.push(`/users/${response.json.user.id}`)
          dispatch(showAlertMessage({ message: i18n.t('user.accountUpdated') }))
        } else {
          dispatch(updateUserFailed(response))
          throw new SubmissionError(response)
        }
      })
      .catch((error) => {
        throw error
      })
  }
}

// doesn't close the modal
export function updateProfileImage(formData) {
  return function (dispatch, getState) {
    return usersApi
      .update(formData)
      .then((response) => {
        if (response.response.ok) {
          dispatch(updateUserSuccess(response))
        } else {
          dispatch(updateUserFailed(response))
          throw new SubmissionError(response)
        }
      })
      .catch((error) => {
        throw error
      })
  }
}

export function deleteUser(id) {
  return function (dispatch) {
    return usersApi
      .destroy(id)
      .then((response) => {
        if (response.response.ok) {
          dispatch(deleteUserSuccess(id))

          dispatch(closeDialog())

          dispatch(logOutUser(i18n.t('user.accountDeleted')))

          history.push('/')
        }
      })
      .catch((error) => {
        throw error
      })
  }
}

export function unsubscribeUser() {
  return function (dispatch) {
    return UnsubscribeApi.create()
      .then((res) => {
        const { ok } = res.response
        if (ok) {
          console.log('success')
        }
      })
      .catch((error) => {
        throw error
      })
  }
}
