import { get, put } from '../utils'

class pendingInvitedGuestsApi {
  static getAll(id) {
    return get(`/conversations/${id}/pending_invited_guests`)
  }

  static resendInvite(conversation_id, invite_id, message) {
    return put(`/conversations/${conversation_id}/pending_invited_guests/${invite_id}/resend`, {
      body: JSON.stringify({ message })
    })
  }

  static resendAllInvite(conversation_id, message) {
    return put(`/conversations/${conversation_id}/pending_invited_guests/resend_all`, {
      body: JSON.stringify({ message })
    })
  }
}

export default pendingInvitedGuestsApi
