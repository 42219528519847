import { assign } from 'lodash'

import {
  LOADING_DINNERS,
  LOAD_DINNERS_SUCCESS,
  LOAD_DINNERS_FAILED,
  LOADING_DINNER,
  LOAD_DINNER_SUCCESS,
  LOAD_NEW_DINNERS_SUCCESS,
  DELETE_DINNER_SUCCESS,
  CONVERSATION_DINNERS_LOADING,
  LOAD_CONVERSATION_DINNERS_FAILED,
  LOAD_CONVERSATION_DINNERS_SUCCESS,
  LOAD_NEW_CONVERSATION_DINNERS_SUCCESS,
  LOAD_PARTNER_DINNERS_SUCCESS,
  LOAD_NEW_PARTNER_DINNERS_SUCCESS,
  LOAD_PARTNER_DINNERS_FAILED,
  PARTNER_DINNERS_LOADING
} from '../actions/actionTypes'
import initialState from './initialState'

export const conversations = (state = initialState.conversations, action) => {
  switch (action.type) {
    case LOADING_DINNERS:
      return assign({}, state, {
        loadingConversations: true
      })

    case LOAD_DINNERS_SUCCESS:
      return assign({}, state, {
        loadingConversations: false,
        errorConversations: null,
        list: assign({}, state.list, action.conversations)
      })

    case LOAD_DINNERS_FAILED:
      return assign({}, state, {
        loadingConversations: false,
        errorConversations: action.data
      })

    case LOAD_NEW_DINNERS_SUCCESS:
      return assign({}, state, {
        loadingConversations: false,
        errorConversations: null,
        list: assign({}, action.conversations)
      })

    case LOADING_DINNER:
      return assign({}, state, {
        loadingConversation: true
      })

    case LOAD_DINNER_SUCCESS:
      return assign({}, state, {
        loadingConversation: false,
        errorConversation: null,
        list: assign({}, state.list, action.conversations)
      })

    case DELETE_DINNER_SUCCESS:
      const conversationId = action.data
      return assign({}, state, delete state.list[conversationId])

    case LOAD_NEW_CONVERSATION_DINNERS_SUCCESS:
      return assign({}, state, {
        errorTopicConversations: null,
        loadingTopicConversations: false,
        list: assign({}, action.conversations)
      })

    case LOAD_CONVERSATION_DINNERS_SUCCESS:
      return assign({}, state, {
        errorTopicConversations: null,
        loadingTopicConversations: false,
        list: assign({}, state.list, action.conversations)
      })

    case CONVERSATION_DINNERS_LOADING:
      return assign({}, state, {
        errorTopicConversations: null,
        loadingTopicConversations: true
      })

    case LOAD_CONVERSATION_DINNERS_FAILED:
      return assign({}, state, {
        errorTopicConversations: state.data,
        loadingTopicConversations: false
      })

    case LOAD_PARTNER_DINNERS_SUCCESS:
      return assign({}, state, {
        errorPartnerConversations: null,
        loadingPartnerConversations: false,
        list: assign({}, state.list, action.conversations)
      })

    case LOAD_NEW_PARTNER_DINNERS_SUCCESS:
      return assign({}, state, {
        errorPartnerConversations: null,
        loadingPartnerConversations: false,
        list: assign({}, action.conversations)
      })

    case PARTNER_DINNERS_LOADING:
      return assign({}, state, {
        errorPartnerConversations: null,
        loadingPartnerConversations: true
      })

    case LOAD_PARTNER_DINNERS_FAILED:
      return assign({}, state, {
        errorPartnerConversations: state.data,
        loadingPartnerConversations: false
      })

    default:
      return state
  }
}

export default conversations
