import React, { useEffect } from 'react'
import { Route, Redirect, Switch, useParams } from 'react-router'

import UserPage from '../components/users/Show'
import EditUserPage from '../components/users/Edit'
import LogInPage from '../components/users/Login/LoginPage'
import SignUpPage from '../components/users/Signup/Signup1Page'
import PartnerPage from '../components/partners/Show'
import PartnerTrackPage from '../components/partners/Journey'
import NewPartnerPage from '../components/partners/New'
import EditPartnerPage from '../components/partners/Edit'
import TrackPage from '../components/tracks/Show/Show'
import TopicPage from '../components/topics/Show'
import TopicsPage from '../components/topics/Index'
import NewTopicPage from '../components/topics/New'
import ConversationsPage from '../components/conversations/Index'
import ConversationPage from '../components/conversations/Show'
import NewConversationPage from '../components/conversations/New'
import FetchConversationWrapper from '../components/conversations/FetchWrapper'
import { UserIsSpecificPartnerAdminForPartner } from '../components/auth/authWrappers'
import UsePasswordResetPage from '../components/password-resets/UsePage'
import TermsOfService from '../components/pages/TermsOfService'
import CookiePolicy from '../components/pages/cookiePolicy'
import PrivacyPolicy from '../components/pages/privacyPolicy'
import DPA from '../components/pages/DPA'
import MSSA from '../components/pages/MSSA'
import MyConversationsPage from '../components/users/MyConversations'
import MyFavPage from '../components/users/MyConversations/MyFavs'
import DownloadsPage from '../components/users/downloads/Index'
import Memberships from '../components/users/MyMembership'
import UnsubscribePage from '../components/unsubscribe/Show'
import defaultMetaTags from '../components/hocs/defaultMetaTags'
import AppContainer from '../components/AppContainer'
import mstReduxBridge from '../components/hocs/mstReduxBridge'
import Admin from '../components/admin/v2'
import LoggedInOnly, { PageWithProviders } from './LoggedInOnly'
import { SlugPage } from './SlugPage'
import ParamTrackProvider from '../components/Providers/ParamTrackProvider'
import ParamPartnerProvider from '../components/Providers/ParamPartnerProvider'
import LoadingAnimation from '../components/shared/LoadingAnimation'
import ZoomConnected from '../components/zoom/ZoomConnected'

const SpecificPartnerAdminOnlyForPartner = UserIsSpecificPartnerAdminForPartner((props) => props.children)

const NewTopic = (props) => (
  <LoggedInOnly>
    <NewTopicPage {...props} />
  </LoggedInOnly>
)

const TopicConversation = mstReduxBridge((props) => (
  <FetchConversationWrapper {...props}>
    <ConversationPage {...props} />
  </FetchConversationWrapper>
))

const NewTopicConversation = (props) => (
  <LoggedInOnly>
    <NewConversationPage {...props} />
  </LoggedInOnly>
)

const NewPartner = (props) => (
  <LoggedInOnly>
    <NewPartnerPage {...props} />
  </LoggedInOnly>
)

const EditPartner = (props) => (
  <SpecificPartnerAdminOnlyForPartner {...props}>
    <EditPartnerPage {...props} />
  </SpecificPartnerAdminOnlyForPartner>
)

const Downloads = (props) => (
  <LoggedInOnly>
    <DownloadsPage {...props} />
  </LoggedInOnly>
)

const LoadProfile = () => {
  const { token, action } = useParams<{ token: string; action: string }>()
  useEffect(() => {
    if (token) {
      localStorage.setItem('jwt', token)
      if (action === 'new') {
        localStorage.setItem('signup', 'true')
      }
      location.href = '/'
    }
  }, [token, action])
  return <LoadingAnimation />
}

export const getRoutes = (store) => {
  return (
    <AppContainer>
      <Switch>
        <Route exact path='/' component={LogInPage} />
        <Route exact path='/zoom/connected' component={ZoomConnected} />
        <Route exact path='/load_profile/:token/:action?' component={LoadProfile} />
        <Route exact path='/terms-of-service' component={TermsOfService} />
        <Route exact path='/cookie_policy' component={defaultMetaTags(CookiePolicy)} />
        <Route exact path='/privacy_policy' component={defaultMetaTags(PrivacyPolicy)} />
        <Route exact path='/data-processing-agreement' component={DPA} />
        <Route exact path='/master-software-and-services-agreement' component={MSSA} />
        <Redirect from='/data_policy' to='/privacy_policy' />
        <Redirect from='/facebook' to='/us' />
        <Route exact path='/unsubscribe' component={UnsubscribePage} />
        <Route exact path='/password_resets/:token' component={UsePasswordResetPage} />
        <Route exact path='/users/:id' component={defaultMetaTags(UserPage)} />
        <Route exact path='/users/:id/edit' component={EditUserPage} />
        <Route exact path='/users/:id/manage_conversations' component={MyConversationsPage} />
        <Route exact path='/myconversations' component={MyConversationsPage} />
        <Route exact path='/favorite' component={MyFavPage} />
        <Route exact path='/downloads' component={Downloads} />
        <Route path='/memberships/:action?/:membership_id?/:tab?' component={Memberships} />
        <Route exact path='/login' component={LogInPage} />
        <Route exact path='/sso/login' component={LogInPage} />
        <Route exact path='/join' component={SignUpPage} />
        <Route exact path='/topics' component={defaultMetaTags(TopicsPage)} />
        <Route exact path='/topics/new' component={NewTopic} />
        <Route
          from='/how-to-host'
          component={() => {
            window.location.href = 'https://inclusivv.co/how-to-host'
            return null
          }}
        />
        <Route
          from='/membership'
          component={() => {
            window.location.href = 'https://inclusivv.co/membership'
            return null
          }}
        />

        <Route path='/admin/:page?' component={Admin} />

        <Route path='/topics/:topicId/conversations/:conversationId' component={defaultMetaTags(ConversationPage)} exact />

        <Route path='/partners/new' component={defaultMetaTags(NewPartner)} exact />
        <Route path='/partners/:slug' component={defaultMetaTags(PartnerPage)} exact />
        <Route path='/:slug/journey/:token?/:action?' component={defaultMetaTags(PageWithProviders(PartnerTrackPage, [ParamPartnerProvider]))} exact />
        <Route path='/partners/:slug/edit/:tab?' component={defaultMetaTags(EditPartner)} exact />
        <Route exact path='/conversations' component={defaultMetaTags(ConversationsPage)} />
        <Route path='/conversations/:locationSlug' component={defaultMetaTags(ConversationsPage)} exact />
        <Route path='/topic/:slug/conversations/new' component={NewTopicConversation} exact />
        <Route path='/topic/:slug/conversations/:conversationId/a/:action?' component={TopicConversation} exact />
        <Route path='/topic/:slug/conversations/:action?' component={TopicPage} exact />
        <Route path='/tracks/:slug' component={PageWithProviders(TrackPage, [ParamTrackProvider])} exact />
        <Route path='/:slug' component={SlugPage} />
      </Switch>
    </AppContainer>
  )
}

export default getRoutes
