import { createSelector } from 'reselect'

const getUserConversationsList = createSelector(
  getUserConversationsRaw,
  ud => ud.list
)

function getUserConversationsRaw(state) {
  return state.userConversations
}

export const getUserConversations = createSelector(
  getUserConversationsList,
  userConversations => userConversations
)
