import React, { Component } from 'react'
import FontIcon from '@material-ui/core/Icon'
import { DialogTitle } from '@material-ui/core'

import withTranslation from '../hocs/withTranslation'

export class InvitationDialogSuccess extends Component {
  render() {
    const { t } = this.props
    return (
      <div>
        <DialogTitle disableTypography>{t('invitation.success')}</DialogTitle>

        <section className='pane reg-pane'>
          <div className='row reg-bottom'>
            <div className='col-xs-12 center-xs'>
              <div className='box'>
                <FontIcon className='fal fa-paper-plane header-36' />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-xs-12 center-xs'>
              <h1 className='flat header-24'>{t('invitation.confirm')}</h1>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default withTranslation(InvitationDialogSuccess)
