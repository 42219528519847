import React, { useEffect } from 'react'
import { withRouter } from 'react-router'
import TopicForm from './Form'
import HostingEdit from './HostingEdit'
import SectionsEdit from '../sections/EditMst'
import DocumentsEdit from '../documents/Edit'
import LmsCoursesEdit from '../lms_courses/Edit'
import TopicsNotificationsEdit from '../topic-notifications/Edit'
import ActionsEdit from '../actions/Edit'
import SocialMediaEdit from '../social-media/Form'
import SurveyQuestionsEdit from '../survey-questions/Edit'
import AppTabs from '../shared/AppTabs'
import { inject, observer } from 'mobx-react'
import { flow, startCase } from 'lodash'
import { IStore } from '../../store/models'
import { Typography } from '@material-ui/core'
import TopicConversations from './TopicConversations'
import TopicSubscribers from './TopicSubscribers'

const EditTopicPage = (props: FIXME) => {
  const {
    topic,
    location: { state: { from = '/admin' } = {} },
    match: {
      params: { tab }
    },
    history,
    submit,
    onTabChange,
    onDirty
  } = props

  const tab_names = ['', 'hosting', 'documents', 'lms_courses', 'emails', 'social', 'actions', 'questions', 'sponsors', 'conversations', 'subscribers']
  let activeTab = tab_names.indexOf(tab)
  activeTab = activeTab === -1 ? 0 : activeTab
  useEffect(() => {
    topic && onTabChange && onTabChange(activeTab)
    return () => {
      topic && onTabChange && onTabChange(0)
    }
  }, [topic, onTabChange, activeTab])

  if (!topic) return null

  return (
    <AppTabs
      index={activeTab}
      onChange={(tabIndex) => {
        history.push(`/admin/topics/${topic.slug}/edit/t/${tab_names[tabIndex]}`, { from })
      }}
      tabs={[
        {
          label: 'General',
          content: (
            <>
              <div className='header-24 bold fat-bottom'>General Details</div>
              <TopicForm
                submit={submit}
                topic={topic}
                page='edit'
                onDirty={onDirty}
                onSave={(data) => {
                  topic.setDirty(false)
                  history.replace(`/admin/topics/${data.slug}/edit`, { from })
                }}
              />
            </>
          )
        },
        ...(topic && !topic.isTemplate
          ? [
              {
                label: 'Hosting',
                content: (
                  <>
                    <div className='header-24 bold fat-bottom'>Hosting</div>
                    <HostingEdit />
                  </>
                )
              }
            ]
          : []),
        {
          label: 'Documents',
          content: (
            <>
              <div className='header-24 bold fat-bottom'>Documents</div>
              <DocumentsEdit documentable={topic} type='Topic' />
            </>
          )
        },
        ...(topic && topic?.allowVideos
          ? [
              {
                label: 'Videos',
                content: (
                  <>
                    <div className='header-24 bold fat-bottom'>Videos</div>
                    <LmsCoursesEdit topic={topic} />
                  </>
                )
              }
            ]
          : []),
        {
          label: 'Emails',
          content: (
            <>
              <div className='header-24 bold fat-bottom'>Emails</div>
              <TopicsNotificationsEdit topic={topic} />
            </>
          )
        },
        {
          label: 'Social & SEO',
          content: (
            <>
              <div className='header-24 bold fat-bottom'>Social Media & SEO</div>
              <SocialMediaEdit item={topic} onSave={topic.updateTopic} />
            </>
          )
        },

        {
          label: 'Take Action',
          content: (
            <>
              <Typography variant='h5' className='fat-bottom'>
                Take Action
              </Typography>
              <ActionsEdit topic={topic} />
            </>
          )
        },

        {
          label: 'RSVP Questions',
          content: (
            <>
              <div className='header-24 bold fat-bottom'>RSVP Questions</div>
              <SurveyQuestionsEdit topic={topic} />
            </>
          )
        },
        {
          label: 'Sponsors',
          content: (
            <>
              <div className='header-24 bold fat-bottom'>Sponsors</div>
              <SectionsEdit sectionable={topic} type='Topic' />
            </>
          )
        },
        {
          label: 'Conversations',
          wide: true,
          noBottom: true,
          content: (
            <div>
              <div className='header-24 bold fat-bottom fat-left'>Conversations</div>
              <TopicConversations topic={topic} />
            </div>
          )
        },
        {
          label: 'Subscribers',
          wide: true,
          noBottom: true,
          content: (
            <>
              <TopicSubscribers topic={topic} />
            </>
          )
        }
      ]}
      contentStyle={{ height: 'calc(100vh - 17em)', overflowY: 'auto' }}
    />
  )
}

export default flow(
  observer,
  inject<FIXME, FIXME>(
    (
      {
        mst: { topics }
      }: {
        mst: IStore
      },
      {
        match: {
          params: { slug }
        }
      }
    ) => {
      const topic = topics.list.find((d) => d.slug === slug)
      !topic && topics.shouldLoad(slug, () => topics.loadBySlug(slug))
      topic && topic.shouldLoad('topicLmsCourses', topic.loadTopicLmsCourses)

      return {
        topic,
        topicLoading: topics.loadingIds.includes(slug)
      }
    }
  ),
  withRouter
)(EditTopicPage)
