import * as types from './actionTypes'
import AuthApi from '../api/AuthApi'
// import { showDialog } from './dialogActions.js'
import { loadUserInvitationRequests } from './ConversationInvitationRequestActions.js'
import { loggedInBootIntercom, assignFullStoryId, logOutUser } from './sessionActions'
import mst from '../store/models'

export function currentUserStart() {
  return { type: types.LOAD_CURRENT_USER_START }
}

export function currentUserSuccess(user) {
  return (dispatch) => {
    dispatch({
      type: types.LOAD_CURRENT_USER_SUCCESS,
      user
    })
    // dispatch(renderSignUpStepTwo(user))
  }
}

export function removeCurrentUserSuccess() {
  return { type: types.REMOVE_CURRENT_USER_SUCCESS }
}

export function getCurrentUser() {
  return function (dispatch) {
    dispatch(currentUserStart())

    return AuthApi.getCurrentUser()
      .then((res) => {
        const { ok } = res.response
        const { user } = res.json

        if (ok && user) {
          dispatch(currentUserSuccess(user))
          dispatch(loadUserInvitationRequests(user.id))
          assignFullStoryId(user)
          loggedInBootIntercom(user)
          mst.auth.reduxSetUser(user)
        } else {
          dispatch(logOutUser())
        }
      })
      .catch((error) => {
        throw error
      })
  }
}

// function renderSignUpStepTwo(user) {
//   return (dispatch) => {
//     if (user.phoneNumber && user.placeId) return

//     dispatch(showDialog('signupStepTwo'))
//   }
// }
