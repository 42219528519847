import React from 'react'
import { inject, observer } from 'mobx-react'
import { flow } from 'lodash'
import { IStore } from '../../store'
import { useContext } from 'react'
import { IAuthUser } from '../../store/models/Auth'

const AuthProvider = ({
  children,
  user,
  loaded,
  signupUser,
  loginWithFacebook,
  loginWithGoogle,
  logOutUser,
  logInUser,
  passwordReset,
  showSignup,
  showLogin,
  userConversationIds,
  attendedConversationIds,
  favorite,
  unfavorite
}: Props & InjectedProps) => {
  return (
    <AuthContext.Provider
      value={{
        user,
        loaded,
        attendedConversationIds,
        userConversationIds,
        signupUser,
        loginWithFacebook,
        loginWithGoogle,
        logOutUser,
        logInUser,
        passwordReset,
        showSignup,
        showLogin,
        favorite,
        unfavorite
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

interface Props {
  children?: React.ReactNode | React.ReactNode[]
}
interface InjectedProps {
  user: IAuthUser | undefined
  userConversationIds: number[]
  attendedConversationIds: number[]
  loaded: boolean
  signupUser(val: FIXME): Promise<any>
  loginWithFacebook(val: FIXME): Promise<any>
  loginWithGoogle(val: FIXME): Promise<any>
  logOutUser(message?: string): void
  passwordReset(data?: any): Promise<any>
  logInUser(creds?: any, data?: any): Promise<any>
  showLogin(): void
  showSignup(): void
  favorite(id: number, type: string): Promise<any>
  unfavorite(id): Promise<any>
}

export const AuthContext = React.createContext<Props & InjectedProps>({
  user: undefined as IAuthUser | undefined,
  loaded: false,
  userConversationIds: [],
  attendedConversationIds: [],
  signupUser: (val: FIXME) => {
    return Promise.resolve()
  },
  loginWithFacebook: (val: any) => {
    return Promise.resolve()
  },
  loginWithGoogle: (val: any) => {
    return Promise.resolve()
  },
  logOutUser: (message?: string) => {
    //  do nothing
  },
  logInUser: (creds?: any, data?: any) => {
    return Promise.resolve()
  },
  passwordReset: (data?: any) => {
    return Promise.resolve()
  },
  showLogin: () => {
    //
  },
  showSignup: () => {
    //
  },
  favorite: (id: number, type: string) => {
    //
    return Promise.resolve()
  },
  unfavorite: (id) => {
    //
    return Promise.resolve()
  }
})

export const useAuth = () => useContext(AuthContext)

export default flow(
  observer,
  inject<InjectedProps, Props>(({ mst }: { mst: IStore }, ownProps) => {
    const {
      auth: { user, loaded, signupUser, loginWithFacebook, loginWithGoogle, logOutUser, logInUser, passwordReset, favorite, unfavorite },
      dialogs
    } = mst

    return {
      user,
      loaded,
      signupUser,
      loginWithFacebook,
      loginWithGoogle,
      logOutUser,
      logInUser,
      passwordReset,
      favorite,
      unfavorite,
      userConversationIds: user?.userConversations.map((uc) => uc.conversationId) || [],
      attendedConversationIds: user?.userConversations.filter((uc) => uc.attended).map((uc) => uc.conversationId) || [],
      showLogin: () => dialogs.showDialog('login'),
      showSignup: () => dialogs.showDialog('signup')
    }
  })
)(AuthProvider)
