import { Box, Button, Grid, IconButton, LinearProgress, LinearProgressProps, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from '@material-ui/core'
import React, { useEffect, useMemo } from 'react'
import { ITopicLmsCourse, ITopicLmsItem } from '../../store/models/Topic/TopicLmsCourses'
import VideoPlayer from './VideoPlayer'
import { observer } from 'mobx-react'
import { useAuth, useParamTopic } from '../Providers'
import DialogWrapper, { DialogTitle } from '../DialogWrapper'
import { useHistory, useLocation, useParams } from 'react-router'
import queryString from 'query-string'

interface Props {
  // lmsCourse: ITopicLmsCourse
}

export const ShowCourse = observer((props: Props) => {
  const { topic } = useParamTopic()
  const { user } = useAuth()
  const [selectedVideo, setSelectedVideo] = React.useState<ITopicLmsItem | undefined>(undefined)
  const [autoPlayDone, setAutoPlayDone] = React.useState<boolean>(false)
  // const lmsItems = topic?.topicLmsCourse?.lmsItems || []
  const lmsItems = useMemo(() => topic?.topicLmsCourse?.lmsItems || [], [topic?.topicLmsCourse?.lmsItems])
  const lmsItemIds = Array.from(lmsItems)?.map((lmsItem) => lmsItem.id)
  const useLmsItems = user?.userLmsItems.filter((uLmsItem) => uLmsItem.lmsItemId && lmsItemIds.includes(uLmsItem.lmsItemId))
  // const params = useParams()
  const history = useHistory()
  const { search } = useLocation()
  // console.log({ location, params })
  const onEnded = (progress) => {
    // console.log({ progress })
    return selectedVideo?.createUserLmsItem({ progress }).then(() => {
      user?.loadUserLmsItems({ reload: true })
    })
  }
  const userLmsLoading = user?.loadingIds?.includes('userLmsItems')

  const selectedUserLmsItem = selectedVideo && useLmsItems?.find((uLmsItem) => uLmsItem.lmsItemId === selectedVideo.id)
  useEffect(() => {
    // console.log('*')
    if (lmsItems.length > 0 && user?.loadedIds?.includes('userLmsItems') && topic?.loadedIds?.includes('topicLmsCourses')) {
      // console.log('**')
      const query = queryString.parse(location?.search)
      if (lmsItems.length > 0 && query && query.video) {
        // console.log('***')
        const video = lmsItems.find((lmsItem) => lmsItem.id.toString() === query.video)
        // console.log('***>', video)
        if (video && !selectedVideo && !autoPlayDone) {
          // console.log('set selected')
          setSelectedVideo(video)
          setAutoPlayDone(true)
          // }
          // if (video && selectedVideo) {
          //   console.log('clear path', selectedVideo)
          // history.push({
          //   pathname: location.pathname
          // })
        }
      }
    }
  }, [search, lmsItems, useLmsItems, user, topic, selectedVideo, autoPlayDone])
  useEffect(() => {
    topic && topic.shouldLoad('topicLmsCourses', topic.loadTopicLmsCourses)
  }, [topic])
  useEffect(() => {
    user && user.shouldLoad('userLmsItems', user.loadUserLmsItems)
  }, [user])

  // console.log({ userLmsLoading })
  return (
    <div className='fat-bottom' style={{ maxWidth: '30rem', margin: 'auto' }}>
      <List>
        {/* {topic?.topicLmsCourse && topic.topicLmsCourse.lmsItems.map((lmsItem) => <ShowLmsListItem lmsItem={lmsItem} key={lmsItem.id} />)} */}
        {topic?.topicLmsCourse &&
          topic.topicLmsCourse.lmsItems.map((lmsItem) => {
            // useLmsItems
            const userLmsItem = useLmsItems?.find((uLmsItem) => uLmsItem.lmsItemId === lmsItem.id)
            return (
              <React.Fragment key={lmsItem.id}>
                <ListItem alignItems='flex-start'>
                  <ListItemText primary={lmsItem.name} secondary={lmsItem.description} />
                  <ListItemSecondaryAction>
                    <IconButton edge='end' aria-label='play' onClick={() => setSelectedVideo(lmsItem)}>
                      <i className='fas fa-play'></i>
                    </IconButton>
                    <IconButton edge='end' aria-label='open' onClick={() => history.push(`/${topic.slug}/videos/${lmsItem.id}`)}>
                      <i className='fas fa-external-link-alt'></i>
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <div style={{ width: '100%' }}>
                  {/* <LinearProgressWithLabel value={userLmsItem?.progress || 0} valueBuffer={userLmsLoading ? 0 : undefined} /> */}
                  <LinearProgress variant='buffer' value={userLmsLoading ? 0 : userLmsItem?.progress || 0} valueBuffer={userLmsLoading ? 0 : 100} />
                </div>
              </React.Fragment>
            )
          })}
      </List>
      {selectedVideo?.file?.url && (
        <DialogWrapper
          open={!!(selectedVideo && selectedVideo?.file?.url)}
          dialog={{
            closeDialog: () => {
              setSelectedVideo(undefined)
            }
          }}
          // title={selectedVideo?.name}
          // type='reg'
          maxWidth='xl'
        >
          <DialogTitle>{selectedVideo?.name}</DialogTitle>
          <div className='row center-xs'>
            <div className='col-xs-12'>
              {selectedVideo?.file?.url && (
                <VideoPlayer
                  videoLink={selectedVideo?.file?.url}
                  onEnded={onEnded}
                  startAt={selectedUserLmsItem?.progress || 0}
                  onStop={(progress) => {
                    selectedVideo?.createUserLmsItem({ progress }).then(() => {
                      user?.loadUserLmsItems({ reload: true })
                    })
                    setSelectedVideo(undefined)
                  }}
                />
              )}
            </div>
          </div>
        </DialogWrapper>
      )}
    </div>
  )
})

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box display='flex' alignItems='center'>
      <Box width='100%' mr={1}>
        <LinearProgress variant='determinate' {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant='body2' color='textSecondary'>{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  )
}
