import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Container, createStyles, Grid, makeStyles, Paper, Theme, Typography } from '@material-ui/core'
import { observer } from 'mobx-react'
import { IMembershipUser, IMembershipUserBase } from '../../../../store/models/User/MembershipUser'
import { ITrack } from '../../../../store'
import { get } from '../../../../api/utils'
import { useParams } from 'react-router'
import DataTable, { IColData, ValueLabel } from '../../../shared/DataTableFilterable'
import LoadingAnimation from '../../../shared/LoadingAnimation'
import SummaryStats from '../../../admin/v2/partials/SummaryStats'

interface Props {
  membership: IMembershipUser
  track: ITrack
}

interface ActionActivityProps {
  action_name: string
  attendees: string
  actions_completed: string
}
interface ActionsActivityDetailProps {
  conversation?: string
  activities: ActionActivityProps[]
  percent_total?: number
}
interface ActionsProps {
  details: {
    total_attendees_taking_actions?: string
    total_actions_taken?: string
    actions_completed_percentage?: string
  }
  actions_activity_details: ActionsActivityDetailProps[]
}

export const Actions = observer(({ membership, track }: Props) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<ActionsProps>({ details: {}, actions_activity_details: [] })
  const { action, membership_id } = useParams<{ action: string; membership_id?: string }>()

  useEffect(() => {
    setLoading(true)
    get(`/memberships/${membership_id}/actions`).then(({ json, response: { ok } }) => {
      if (ok) {
        setData(json)
      }
      setLoading(false)
    })
  }, [membership_id])

  if (loading) {
    return <LoadingAnimation />
  }

  return (
    <div style={{ marginTop: '1rem' }}>
      <div>
        <Typography variant='h6' className='reg-bottom reg-top'>
          Actions Dashboard
        </Typography>
        <Grid container direction='column' alignItems='flex-start' spacing={3}>
          <Grid item className={classes.grid}>
            <ActionsDetail membership={membership} track={track} data={data} />
          </Grid>
        </Grid>
      </div>
      <div>
        <Typography variant='h6' className='reg-bottom reg-top'>
          Actions Activity Details
        </Typography>
        <hr />
        <Grid container direction='column' spacing={1}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={8}>
                Conversation
              </Grid>
              <Grid item xs={2}>
                Attendees
              </Grid>
              <Grid item xs={2}>
                Actions Completed
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.grid}>
            <ActionsDetailByConversation membership={membership} track={track} data={data} />
          </Grid>
        </Grid>
      </div>
    </div>
  )
})
export default Actions

const AnalyticValue = ({ name, value }) => {
  const classes = useStyles()
  return (
    <Grid item xs={12} sm={4}>
      <Paper variant='outlined' className={classes.dataPaper}>
        <Typography variant='subtitle1'>{name}</Typography>
        <Typography variant='h5' align='center'>
          {value}
        </Typography>
      </Paper>
    </Grid>
  )
}

const ActionsDetail = ({ membership, track, data }: Props & { data: ActionsProps }) => {
  const classes = useStyles()
  return (
    <Container className={classes.section}>
      <div className='reg-left reg-bottom-padding'>
        <SummaryStats
          bottomDivider={false}
          data={[
            { title: 'Total Attendees Taking Action', value: data.details.total_attendees_taking_actions },
            { title: 'Total Actions Taken', value: data.details.total_actions_taken },
            { title: 'Actions Completed', value: `${data.details.actions_completed_percentage}%` }
          ]}
        />
      </div>
    </Container>
  )
}

const ActionsDetailByConversation = ({ membership, track, data }: Props & { data: ActionsProps }) => {
  const classes = useStyles()

  return (
    <Container className={classes.section}>
      <Grid container spacing={3}>
        <Grid item>
          <Grid container spacing={3}>
            {data?.actions_activity_details &&
              data.actions_activity_details.map((actions_activity_detail) => <ActionByConversation key={actions_activity_detail.conversation} data={actions_activity_detail} />)}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

const ActionByConversation = ({ data }: { data: ActionsActivityDetailProps }) => {
  const classes = useStyles()

  return (
    <Grid item xs={12}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <strong>{data.conversation}</strong>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            {data.activities.map((act) => (
              <ActionActivity key={act.action_name} data={act} />
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={11}>
              Percent of Total
            </Grid>
            <Grid item xs={1}>
              {data.percent_total}%
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
const ActionActivity = ({ data }: { data: ActionActivityProps }) => {
  const classes = useStyles()

  return (
    <Grid item xs={12}>
      <Grid container spacing={1}>
        <Grid item xs={8}>
          {data.action_name}
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'center' }}>
          {data.attendees}
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'center' }}>
          {data.actions_completed}
        </Grid>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      width: '100%'
    },
    dataPaper: {
      padding: theme.spacing(2),
      borderRadius: '16px',
      maxWidth: '10rem'
    },
    section: {
      paddingTop: '.25rem'
    }
  })
)
