import React, { useContext, useEffect } from 'react'
import { observer } from 'mobx-react'

const AppLayoutProvider = observer(({ children }: Props) => {
  const [logoUrl, setLogoUrl] = React.useState<string | undefined>(undefined)
  const [logoLink, setLogoLink] = React.useState<string | undefined>(undefined)
  const [showLogo, setShowLogo] = React.useState(false)
  const changeLogo = (url: string, link: string) => {
    setLogoUrl(url)
    setLogoLink(link)
    setShowLogo(true)
  }
  useEffect(() => {
    setTimeout(() => {
      if (!showLogo) {
        setLogoUrl('https://civicdinners-manual-storage.s3.amazonaws.com/inclusivv-logo-transition.png')
        setLogoLink('https://www.inclusivv.co/')
        setShowLogo(true)
      }
    }, 2000)
  }, [showLogo])
  return <AppLayoutContext.Provider value={{ logoLink, logoUrl, changeLogo }}>{children}</AppLayoutContext.Provider>
})

interface Props {
  children?: React.ReactNode | React.ReactNode[]
  slug?: string
}

export const AppLayoutContext = React.createContext({
  changeLogo: (url: string, link: string) => {
    // Logo change
  },
  logoUrl: undefined as string | undefined,
  logoLink: undefined as string | undefined
})
export const useAppLayout = () => useContext(AppLayoutContext)
export default AppLayoutProvider
