import React, { useEffect } from 'react'
import { List as MaterialList } from '@material-ui/core'

import ListItem from './ListItem'
import { observer } from 'mobx-react'
import LoadingAnimation from '../../../shared/LoadingAnimation'

const List = ({ partner }: FIXME) => {
  useEffect(() => {
    partner && partner.shouldLoad('admins', partner.loadAdmins)
  }, [partner])
  const loading = partner?.loadingIds?.includes('adminInvitations')
  if (!partner || loading) return <LoadingAnimation />
  const EmptyMessage = () => {
    return (
      <div>
        <p className='italic'>{'No Admins'}</p>
      </div>
    )
  }

  return (
    <MaterialList>
      {loading && partner?.admins.length === 0 && <EmptyMessage />}
      {partner?.admins && partner.admins.map((admin) => <ListItem removeAdmin={({ userId }) => partner.deleteAdmin(userId)} key={admin.id} item={admin} />)}
    </MaterialList>
  )
}

export default observer(List)
