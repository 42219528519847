import { post, put } from './utils'

class InvitationsApi {
  static create(values) {
    return post(`/invitations`, {
      body: JSON.stringify(values)
    })
  }

  static redeem(data) {
    return put(`/invitations/redeem`, {
      body: JSON.stringify(data)
    })
  }
}

export default InvitationsApi
