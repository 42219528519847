import { post } from './utils'

class MessageGuestsApi {
  static create(data) {
    return post(`/conversations/${data.conversationId}/messages`, {
      body: JSON.stringify(data)
    })
  }
}

export default MessageGuestsApi
