import { types, Instance, getParent } from 'mobx-state-tree'
import User from '.'
import { Alertable } from '../helpers/Alertable'
// import { Alertable } from '..'
import MembershipApi from '../../../api/MembershipApi'
import { removeNullStringValue } from '../utils'
import { Filterable } from '../helpers/Filterable'
import { LoadableStatus } from '../helpers/LoadableStatus'
import Editable from '../helpers/Editable'
import { Dialogable } from '../helpers/Dialogable'
import Invite from '../helpers/Invite'
import { RootAccessable } from '../helpers/RootAccessable'

export const MembershipUserBase = types
  .compose(
    'MembershipUserBase',
    Alertable,
    Editable,
    Dialogable,
    // LoadableStatus,
    types.model({
      id: types.identifierNumber,
      dealName: types.string,
      membershipId: types.number,
      active: types.boolean,
      owner: types.maybeNull(types.boolean),
      role: types.optional(types.string, 'user'),
      token: types.maybeNull(types.string),
      status: types.maybeNull(types.string),
      price: types.maybeNull(types.string),
      slug: types.maybeNull(types.string),
      startsAt: types.maybeNull(types.string),
      endsAt: types.maybeNull(types.string),
      email: types.maybeNull(types.string),
      createdAt: types.maybeNull(types.string),
      companyName: types.maybeNull(types.string),
      trackId: types.maybeNull(types.number),
      seatLimit: types.maybeNull(types.number),
      membersCount: types.maybeNull(types.number),
      invitedMembersCount: types.maybeNull(types.number),
      storiesCount: types.maybeNull(types.number),
      actionsCompletedCount: types.maybeNull(types.number),
      attendedConversationCount: types.maybeNull(types.number),
      membershipType: types.maybeNull(types.string),
      user: types.maybe(User)
    })
  )
  .actions((self) => ({
    updateStatus: (val) => {
      self.status = val
    }
  }))
  .actions((self) => ({
    isOwner: () => {
      return ['owner'].includes(self.role)
    },
    isManager: () => {
      return ['owner', 'manager'].includes(self.role)
    },
    showInviteMemberDialog: () => {
      self.showDialog('emailInviteMember', { membershipUser: self })
    },
    sendInviteEmail: (emails, message) => {
      return MembershipApi.inviteMembers(self.membershipId, self.id, emails, message).then(({ response: { ok }, json }) => {
        if (ok) {
          self.showAlert('Invitation Sent')
        } else {
          self.showAlert('Error Sending Invitation')
        }
      })
    },
    inviteLink: () => {
      return `${window.location.host}/${self.slug}/journey/${self.token}`
    },
    inviteAndJoinAllLink: () => {
      return `${window.location.host}/${self.slug}/journey/${self.token}/all`
    },
    inviteAndJoinLink: (id) => {
      return `${window.location.host}/${self.slug}/journey/${self.token}/${id}`
    },
    removeMembership: () => {
      return MembershipApi.removeMembership(self.membershipId, self.id).then(({ response: { ok }, json }) => {
        if (ok) {
          self.updateStatus('removed')
          self.showAlert(`Removed ${self.user?.fullName}`)
          // const parent: any = getParent(self, 2)
          // parent.removeFromList(self.id)
        } else {
          self.showAlert('Error removing')
        }
      })
    },
    unremoveMembership: () => {
      return MembershipApi.unremoveMembership(self.membershipId, self.id).then(({ response: { ok }, json }) => {
        if (ok) {
          self.updateStatus('active')
          self.showAlert(`Added ${self.user?.fullName}`)
          // const parent: any = getParent(self, 2)
          // parent.removeFromList(self.id)
        } else {
          self.showAlert('Error Adding')
        }
      })
    },
    updateRole: (newRole) => {
      return MembershipApi.updateRole(self.membershipId, self.id, newRole).then(({ response: { ok }, json }) => {
        if (ok) {
          self.updateMe(json)
          self.showAlert(`Role updated to ${self.role}`)
        } else {
          self.showAlert('Error updating role')
        }
      })
    },
    updateMembershipUserType: (membershipType) => {
      return MembershipApi.updateMembershipUserType(self.membershipId, self.id, membershipType).then(({ response: { ok }, json }) => {
        if (ok) {
          self.updateMe(json)
          self.showAlert('Membership type updated')
        } else {
          self.showAlert('Error updating membership type')
        }
      })
    }
  }))

const MemberInvite = types.compose(
  'MemberInvite',
  Invite,

  types.model({
    id: types.identifierNumber
  })
)

export const MembershipUser = types
  .compose(
    'MembershipUser',
    MembershipUserBase,
    Filterable,
    // Alertable,
    RootAccessable,
    LoadableStatus,
    types.model({
      fullList: types.array(MembershipUserBase),
      pendingList: types.array(MemberInvite),
      listLoaded: types.optional(types.boolean, false),
      listLoading: types.optional(types.boolean, false)
    })
  )
  .actions((self) => ({
    setList: (data: any[]) => {
      removeNullStringValue(data)
      self.fullList.replace(data)
    },
    setPendingList: (data: any[]) => {
      // removeNullStringValue(data)
      self.pendingList.replace(data)
    },
    removeFromList: (id) => {
      self.fullList.replace(self.fullList.filter((mu) => mu.id !== id))
    }
  }))
  .actions((self) => ({
    loadFullList: (reload = false) => {
      const listName = 'fullList'
      if (self.isLoading(listName) || (!reload && self.isLoaded(listName))) {
        return
      }
      self.listLoaded = true
      self.startLoading(listName)
      return MembershipApi.getFullList(self.membershipId).then(({ response: { ok }, json }) => {
        self.stopLoading(listName)
        if (ok) {
          self.setList(json)
        }
      })
    },
    loadPendingList: (reload = false) => {
      const listName = 'pendingList'
      if (self.isLoading(listName) || (!reload && self.isLoaded(listName))) {
        return
      }
      self.listLoaded = true
      self.startLoading(listName)
      return MembershipApi.getPendingList(self.membershipId).then(({ response: { ok }, json }) => {
        self.stopLoading(listName)
        if (ok) {
          self.setPendingList(json)
        }
      })
    },
    downloadCsv: () => {
      const downloads = self.getDownloads()
      return downloads.createCsvExport('membership_user', { membershipId: self.membershipId }, { download: true })
    }
  }))
  .actions((self) => ({
    loadFilter: () => {
      // self.loadPendingList()
      return Promise.all([self.loadPendingList(true), self.loadFullList(true)])
    },
    activeList: () => {
      return self.fullList.filter((item) => item.status === 'active')
    },
    activeListCount: () => {
      return self.fullList.filter((item) => item.status === 'active').length
    }
  }))

export interface IMembershipUserBase extends Instance<typeof MembershipUserBase> {}
export interface IMemberInvite extends Instance<typeof MemberInvite> {}
export interface IMembershipUser extends Instance<typeof MembershipUser> {}
