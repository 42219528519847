import { toFormData } from './FormData'
import { deleteApi, post, put, get } from './utils'

class DocumentsApi {
  getAll(documentableType: string, documentableId: number) {
    return get(`/documents/${documentableType}/${documentableId}`)
  }

  create(documentableType: string, documentableId: number, data: any) {
    const formData = toFormData('document', data)
    return post(`/documents/${documentableType}/${documentableId}`, {
      noContentTypeJson: true,
      body: formData
    })
  }

  destroy(documentableType: string, documentableId: number, id: number) {
    return deleteApi(`/documents/${documentableType}/${documentableId}/${id}`)
  }

  assignGroup(documentableType: string, documentableId: number, documentIds: number[], groupId: string) {
    return put(`/documents/${documentableType}/${documentableId}/assign_group?ids=${documentIds.join(',')}&group_id=${groupId}`)
    // return put(`/documents/assign_group?ids=${documentIds.join(',')}&to=${groupId}`)
  }
}

export default new DocumentsApi()
