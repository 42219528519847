import React, { useEffect } from 'react'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import * as validations from '../../../validations/fieldLevelValidations'
import { useForm } from 'react-hook-form'
import { MapRegionControl, TextFieldControl, SelectControl, WysiwygControl, localOptions, FileButtonControl, SwitchControl } from '../../../utils'
import { camelCase } from 'lodash'
import classnames from 'classnames'
import i18next from 'i18next'
import { capitalize, snakify } from '../../../../decorators/textTools'
import { observer, inject } from 'mobx-react'
import { IStore } from '../../../../store/models'
import { Chip, Tooltip } from '@material-ui/core'
import palette from '../../../../themes/palette'

// eslint-disable-next-line complexity
const TrackForm = ({ partner, organizations, onSave, onDirty, submit, createPartner, onSubmit, user }: FIXME) => {
  const { handleSubmit, errors, formState, control, watch, setError, reset, clearErrors, setValue } = useForm({ mode: 'onBlur' })
  const { isDirty, isSubmitting, dirtyFields } = formState

  useEffect(() => {
    partner && partner.setDirty(isDirty)
    onDirty && onDirty(isDirty)
  }, [partner, isDirty, onDirty])

  const displayError = (error) => {
    if (error) {
      for (const fieldName in error) {
        setError(camelCase(fieldName), {
          type: 'manual',
          message: error[fieldName]
        })
      }
    }
  }

  const afterSave = async ({ data = undefined, error = undefined }: { error?: any; data?: any } = {}) => {
    onDirty && onDirty(false)
    if (error) {
      displayError(error)
    } else {
      data && reset()
      partner?.setDirty(false)
      onSave && onSave(data)
    }
    onSubmit && onSubmit(false)
    return data
  }

  function updateTrack(track) {
    const partnerTrack = partner?.tracks[0] ? { id: partner?.tracks[0]?.id } : {}
    const updateData = { tracks_attributes: [snakify({ ...track, ...partnerTrack })] }
    return partner && partner.updatePartner(updateData, dirtyFields?.profileImage || dirtyFields?.backgroundImage).then(afterSave)
  }

  const handleSave = handleSubmit(({ track, ...updateData }: any, e) => {
    clearErrors()
    onSubmit && onSubmit(true)
    updateTrack(track)
    // return true
  })

  // const handleSave = (e) => {
  //   e.preventDefault()
  //   console.log('handleSave')
  // }

  useEffect(() => {
    onDirty && onDirty(isDirty && !isSubmitting)
  }, [onDirty, isDirty, isSubmitting])

  useEffect(() => {
    if (isDirty && submit && !isSubmitting) {
      handleSave()
    }

    submit && onDirty && onDirty(false)
  }, [submit, isDirty, isSubmitting, handleSave, onDirty])

  const track = partner?.tracks[0]

  return (
    <section className='fat-left-padding fat-right-padding'>
      <Card className='xtra-huge-bottom no-shadow padding'>
        <div className='flex-row flex-center left-padding padding-left'>
          <form onSubmit={handleSave}>
            <TextFieldControl name='track.header' control={control} defaultValue={track?.header} error={errors && errors.track?.header} label={'Header'} className='reg-bottom' />
            <TextFieldControl
              name='track.description'
              control={control}
              defaultValue={track?.description}
              error={errors && errors.track?.description}
              label={'Description'}
              className='reg-bottom'
              multiline={true}
            />
            <TextFieldControl
              name='track.loginHeaderText'
              control={control}
              defaultValue={track?.loginHeaderText}
              error={errors && errors.track?.loginHeaderText}
              label={'Login Header Text'}
              className='reg-bottom'
              placeholder='Already a Member?'
            />
            <TextFieldControl
              name='track.joinCtaText'
              control={control}
              defaultValue={track?.joinCtaText}
              error={errors && errors.track?.joinCtaText}
              label={'Join CTA Text'}
              placeholder='Not a Member Yet?'
              className='reg-bottom'
            />
            <TextFieldControl
              name='track.joinCtaLink'
              control={control}
              defaultValue={track?.joinCtaLink}
              error={errors && errors.track?.joinCtaLink}
              label={'Join CTA Link'}
              className='reg-bottom'
              placeholder='https://inclusivv.co/membership'
            />
            <TextFieldControl
              name='track.joinCtaButton'
              control={control}
              defaultValue={track?.joinCtaButton}
              error={errors && errors.track?.joinCtaButton}
              label={'Join CTA Button'}
              className='reg-bottom'
              placeholder='Become a Member'
            />
            <Button disabled={!isDirty || isSubmitting} type='submit' className='giant-top' color='secondary' variant='contained'>
              Submit
            </Button>
          </form>
        </div>
      </Card>
    </section>
  )
}

export default inject<FIXME, FIXME>(
  ({
    mst: {
      auth: { user }
    }
  }: {
    mst: IStore
  }) => {
    return { user }
  }
)(observer(TrackForm))
