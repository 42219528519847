import React from 'react'
import Toolbar from '@material-ui/core/Toolbar'

import Logo from '../Logo'
import SessionNav from './SessionNav'
import MobileNav from './MobileNav'
import { inject, observer } from 'mobx-react'
import { IStore } from '../../../store/models'
import { flow } from 'lodash'
import { withRouter } from 'react-router'
import { IUser } from '../../../store/models/User'
import { Button, createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import NavDropdown from './NavDropdown'
import { ABOUT_LINKS, EXPLORE_LINKS, INCLUSIVV_FOR_LINKS, PRICING_LINKS } from './Links'
import { useTranslation } from 'react-i18next'

interface Props {
  user: IUser
  redirectToPartner: any
}

const Nav = ({ user, redirectToPartner }: Props) => {
  const classes = Styles()
  const { t } = useTranslation()
  return (
    <div>
      <Toolbar className='toolbar-nav user-toolbar'>
        <div className={`toolbar-nav-brand${window.coco ? '-coco' : ''} ${classes.logoContainer}`}>
          <Logo redirectToPartner={redirectToPartner} />
        </div>

        <div style={{ flexGrow: 1 }} />

        {/* {window.inclusivv && (
          <div className='toolbar-main-links'>
            <div className='toolbar-nav-link-container'>
              <NavDropdown label='nav.inclusivvFor' menus={INCLUSIVV_FOR_LINKS} />
            </div>
          </div>
        )}

        {window.inclusivv && (
          <div className='toolbar-main-links'>
            <div className='toolbar-nav-link-container'>
              <NavDropdown label='nav.explore' menus={EXPLORE_LINKS} />
            </div>
          </div>
        )}

        {window.inclusivv && (
          <div className='toolbar-main-links'>
            <div className='toolbar-nav-link-container'>
              <Button href={PRICING_LINKS} className={classes.navDropdown} classes={{ text: classes.buttonLabel }}>
                <Typography variant='subtitle2'>{t('nav.pricing')}</Typography>
              </Button>
            </div>
          </div>
        )}

        {window.inclusivv && (
          <div className='toolbar-main-links'>
            <div className='toolbar-nav-link-container'>
              <NavDropdown label='nav.about' menus={ABOUT_LINKS} />
            </div>
          </div>
        )} */}

        <SessionNav />

        <div className='toolbar-mobile-nav'>
          <MobileNav />
        </div>
      </Toolbar>
    </div>
  )
}

const Styles = makeStyles((theme: Theme) =>
  createStyles({
    logoContainer: {
      heigth: '100%'
    },
    buttonLabel: {
      padding: '6px 1em'
    },
    navDropdown: {
      '&:hover': {
        color: '#770000'
      }
    }
  })
)

export default flow(
  observer,
  inject<FIXME, FIXME>(
    (
      {
        mst: {
          auth: { user, loadDefaultPartner }
        }
      }: {
        mst: IStore
      },
      { history }
    ) => {
      return {
        user,
        redirectToPartner: () => {
          loadDefaultPartner().then((slug) => history.push(`/${slug}`))
        }
      }
    }
  ),
  withRouter
)(Nav)
